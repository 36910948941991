import React, {
  useMemo, useState, useContext,
} from 'react';
import classNames from 'classnames';
import { Tooltip } from 'react-tippy';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import * as Atlas from '../../../common/types/Atlas';
import EditableText from './EditableText';
import CommentReply from './CommentReply';
import useUpdateDiscussionCommentMutation from '../../../common/hooks/api/discussion-comments/useUpdateDiscussionCommentMutation';
import useDestroyDiscussionCommentMutation from '../../../common/hooks/api/discussion-comments/useDestroyDiscussionCommentMutation';
import useCreateDiscussionDiscussionCommentMutation from '../../../common/hooks/api/discussions/useCreateDiscussionDiscussionCommentMutation';
import { Line, LineEnd, LineWithDot } from '../styles/commentLines';
import * as dayjsLocale from '../../../common/utils/dayjs-locale';
import GroupContext from '../GroupContext';
import { isGroupAdministrator } from '../../../common/utils/group-permissions';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import DiscussionCommentReplyForm from './DiscussionCommentReplyForm';
import { Col } from '../../../common/components/Col';
import { Row } from '../../../common/components/Row';

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.updateLocale('en', dayjsLocale.EN);

interface DiscussionCommentProps {
  comment: Atlas.ExpandedDiscussionComment;
  replies: Atlas.ExpandedDiscussionComment[];
  isLocked?: Atlas.DiscussionLocked;
}

interface DiscussionCommentState {
  showAll: boolean;
  editing: boolean;
  deleted: boolean;
  replying: boolean;
  visibleRepliesCount: number;
  editFocused: boolean;
  replyFocused: boolean;
}

const DiscussionComment = (props: DiscussionCommentProps): JSX.Element | null => {
  const {
    comment,
    replies,
    isLocked,
  } = props;

  const [state, setState] = useState<DiscussionCommentState>({
    showAll: false,
    editing: false,
    deleted: false,
    replying: false,
    visibleRepliesCount: 2,
    editFocused: false,
    replyFocused: false,
  });

  const group = useContext(GroupContext);

  const currentUserQuery = useCurrentUserQuery();
  const currentUser = currentUserQuery.data;

  const updateDiscussionComment = useUpdateDiscussionCommentMutation({
    discussionCommentId: comment.id,
  });
  const destroyDiscussionComment = useDestroyDiscussionCommentMutation({
    discussionCommentId: comment.id,
  });
  const createDiscussionComment = useCreateDiscussionDiscussionCommentMutation({
    discussionId: comment.discussion_id,
  });

  const visibleReplies = useMemo(() => {
    const sortedReplies = replies.sort((a, b) => a.created_at.valueOf() - b.created_at.valueOf());

    const slicedReplies = state.showAll ? (
      sortedReplies
    ) : (
      sortedReplies.slice(state.visibleRepliesCount * -1)
    );

    return slicedReplies;
  }, [replies, state.showAll, state.visibleRepliesCount]);

  if (state.deleted) { return null; }

  const isOwnedByCurrentUser = !!currentUser && currentUser.id === comment.user_id;
  const isRightComment = !isOwnedByCurrentUser;
  const isEditable = isOwnedByCurrentUser;

  const toggleReplying = () => {
    if (createDiscussionComment.isLoading) { return; }

    setState((s) => ({ ...s, replying: !s.replying }));
  };

  const handleEditClick = () => {
    setState((s) => ({ ...s, editing: !s.editing }));
  };

  const canDelete = (() => {
    if (isLocked === 'locked') { return false; }
    if (!currentUser) { return false; }
    if (currentUser.admin === Atlas.AdministratorType.Super) { return true; }
    if (currentUser.id === comment.user_id) { return true; }
    if (!group?.group) { return false; }

    return isGroupAdministrator(group.group, currentUser.id, currentUser.organization_id);
  })();

  const handleDeleteClick = () => {
    if (!canDelete) { return; }

    if (!window.confirm(__('Are you sure you want to delete this comment?'))) { return; }

    setState((s) => ({ ...s, deleted: true }));
    destroyDiscussionComment.mutateAsync().catch(() => {
      setState((s) => ({ ...s, deleted: false }));
    });
  };

  const handleEditFormSubmit = async (commentBody: string) => {
    if (updateDiscussionComment.isLoading) { return; }

    await updateDiscussionComment.mutateAsync({ body: commentBody });
    setState((s) => ({ ...s, editing: false }));
  };

  const handleReplyFormSubmit = async (commentBody: string) => {
    if (createDiscussionComment.isLoading) { return; }

    await createDiscussionComment.mutateAsync({
      parent_id: comment.id,
      body: commentBody,
    }).then(() => {
      setState((s) => ({
        ...s, replying: false, visibleRepliesCount: s.visibleRepliesCount + 1,
      }));
    }, () => {
      setState((s) => ({ ...s, replying: false }));
    });
  };

  const handleShowAllClick = () => {
    setState((s) => ({ ...s, showAll: !s.showAll }));
  };

  const handleEditFocus = () => {
    setState((s) => ({ ...s, editFocused: true }));
  };

  const handleEditBlur = () => {
    setState((s) => ({ ...s, editFocused: false }));
  };

  const handleReplyFocus = () => {
    setState((s) => ({ ...s, replyFocused: true }));
  };

  const handleReplyBlur = () => {
    setState((s) => ({ ...s, replyFocused: false }));
  };

  return (
    <Row className={classNames('mx-n2  justify-content-start', { 'flex-row-reverse': !isRightComment })}>
      <Col className="px-2" xs="auto">
        <img
          alt={__('User profile picture')}
          src={comment.user.avatar.viewable_s3uri?.url || '/assets/img/discussions/display-picture-blue.png'}
          style={{ width: '3rem', height: '3rem' }}
          className="border rounded-circle overflow-hidden"
        />
      </Col>

      <Col className="px-2">
        <Row className={classNames('flex-column mt-1', { 'align-items-start': isRightComment, 'align-items-end': !isRightComment })}>
          <Col
            xs="auto"
            className={classNames({ 'd-flex flex-column align-items-end': isOwnedByCurrentUser })}
          >
            <div>
              <small className="px-3 text-secondary font-weight-bold">
                {`${comment.user.first_name} ${comment.user.last_name}`}
              </small>
              {comment.edited_at ? (
                <Tooltip
                  html={
                    <div>
                      <div>
                        {__('Created')} {dayjs(comment.created_at).format('LLLL')}
                      </div>
                      <div>
                        {__('Edited')} {dayjs(comment.edited_at).format('LLLL')}
                      </div>
                    </div>
                  }
                  size="small"
                  delay={500}
                >
                  <small>
                    {__('Edited')} {dayjs().to(dayjs(comment.edited_at))}
                  </small>
                </Tooltip>
              ) : (
                <Tooltip
                  title={dayjs(comment.created_at).format('LLLL')}
                  size="small"
                  delay={500}
                >
                  <small>
                    {dayjs().to(dayjs(comment.created_at))}
                  </small>
                </Tooltip>
              )}
            </div>

            {state.editing ? (
              <div
                className={`px-3 py-2 rounded-lg shadow-sm border ${state.editing && !state.editFocused ? 'border-warning' : 'border-light'}`}
                style={{ minWidth: '5rem' }}
              >
                <EditableText
                  discussionId={comment.discussion_id}
                  value={comment.body}
                  editing={state.editing}
                  onFocus={handleEditFocus}
                  onBlur={handleEditBlur}
                  onSubmit={handleEditFormSubmit}
                />
              </div>
            ) : (
              <div className={classNames('d-flex align-items-center mt-1', { 'flex-row-reverse': !isRightComment })}>
                {canDelete ? (
                  <Tooltip
                    title={__('Delete')}
                    position="top"
                    trigger="mouseenter"
                    followCursor
                    size="small"
                  >
                    <button type="button" className="btn p-0 bg-white text-dark mx-2" onClick={handleDeleteClick}>
                      <i className="fa fa-times-circle commentDeleteBtn" />
                    </button>
                  </Tooltip>
                ) : null}

                <div
                  className={`px-3 py-2 rounded-lg border border-light ${isOwnedByCurrentUser ? 'text-white bg-info' : 'bg-light'}`}
                  style={{ minWidth: '5rem' }}
                >
                  <EditableText
                    discussionId={comment.discussion_id}
                    value={comment.body}
                    editing={state.editing}
                    onFocus={handleEditFocus}
                    onBlur={handleEditBlur}
                    onSubmit={handleEditFormSubmit}
                  />
                </div>
              </div>
            )}

            <div className="px-4">
              <Row className={classNames('mx-n2 flex-nowrap', { 'flex-row-reverse': !isRightComment })}>
                {state.replying || visibleReplies.length ? (
                  <Col className="px-2" xs="auto">
                    <Line />
                  </Col>
                ) : null}

                {isLocked === 'unlocked' ? (
                  <Col className="px-2 mb-3" xs="auto">
                    <Row className="mx-n2 align-items-end">
                      <Col className="px-2" xs="auto">
                        <button type="button" className="btn p-0 bg-white text-dark" onClick={toggleReplying}>
                          <small>
                            {__('Reply')}
                          </small>
                        </button>
                      </Col>

                      {isEditable ? (
                        <Col className="px-2" xs="auto">
                          <button type="button" className="btn p-0 bg-white text-dark" onClick={handleEditClick}>
                            <small>
                              {__('Edit')}
                            </small>
                          </button>
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                ) : null}
              </Row>
            </div>
          </Col>

          <Col>
            <div className="px-4">
              {state.visibleRepliesCount < replies.length ? (
                <Row className={classNames('mx-n2 flex-nowrap', { 'flex-row-reverse': !isRightComment })}>
                  <Col className="px-2" xs="auto">
                    <Line />
                  </Col>

                  <Col className="px-2 mb-3" xs="auto">
                    <button type="button" className="bg-white border rounded-pill btn btn-secondary btn-small" onClick={handleShowAllClick}>
                      {state.showAll ? (
                        __('Hide comments')
                      ) : (
                        __('Show all comments')
                      )}
                    </button>
                  </Col>
                </Row>
              ) : null}

              {visibleReplies.map((reply) => (
                <Row key={reply.id} className={classNames('mx-n2 flex-nowrap', { 'flex-row-reverse': !isRightComment })}>
                  <Col className="px-2" xs="auto">
                    <LineWithDot />
                  </Col>

                  <Col className="px-2 mb-3" xs="auto">
                    <CommentReply
                      alignRight={isRightComment}
                      currentUser={currentUser}
                      reply={reply}
                      isLocked={isLocked}
                    />
                  </Col>
                </Row>
              ))}

              {visibleReplies.length || state.replying ? (
                <Row className={classNames('mx-n2 flex-nowrap', { 'flex-row-reverse': !isRightComment })}>
                  <Col className="px-2" xs="auto">
                    <LineEnd />
                  </Col>

                  <Col className="px-2" xs="auto">
                    {state.replying ? (
                      <div className="d-flex align-items-center mt-4">
                        <div style={{ width: '20rem' }}>
                          <DiscussionCommentReplyForm
                            autoFocus
                            discussionId={comment.discussion_id}
                            onSubmit={handleReplyFormSubmit}
                          />
                        </div>

                        <button type="button" className="btn bg-white" onClick={toggleReplying}>
                          <i className="fa fa-times-circle" />
                        </button>
                      </div>
                    ) : (
                      <div className="mt-3 d-flex">
                        {isLocked === 'unlocked' ? (
                          <button type="button" className="btn p-0 bg-white text-dark" onClick={toggleReplying}>
                            {__('Reply')}
                          </button>
                        ) : ''}
                      </div>
                    )}
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DiscussionComment;
