import React, { ComponentProps, useState } from 'react';
import MediaCaptureFormModal, { MediaAttachmentOption } from './media-capture-form/FormModal';
import VideoStreamRecorder from './VideoStreamRecorder';
import MediaCaptureModal from './MediaCaptureModal';
import MediaUploadModal from './file-uploader/MediaUploadModal';
import * as Atlas from '../../../../common/types/Atlas';

interface MediaCommentModalProps {
  initialOption?: MediaAttachmentOption;
  onStart: (commentArgs?: Partial<Atlas.Comment>) => Promise<Atlas.CommentID>;
  onClose: () => void;
}

type MediaCommentModalState = {
  stage: 'form';
  loading: boolean;
} | {
  stage: 'capture';
  vsr: VideoStreamRecorder;
} | {
  stage: 'file-upload';
  file: File;
};

const MediaCommentModal: React.FC<MediaCommentModalProps> = (props) => {
  const { initialOption, onClose, onStart } = props;

  const [state, setState] = useState<MediaCommentModalState>({
    stage: 'form',
    loading: false,
  });

  switch (state.stage) {
    case 'form': {
      const handleSelect: ComponentProps<typeof MediaCaptureFormModal>['onStart'] = (streams) => {
        setState({
          stage: 'form',
          loading: true,
        });

        try {
          const leftStream = new MediaStream(streams.leftStream.getTracks().map((t) => t.clone()));
          const rightStream = streams.rightStream ? new MediaStream(streams.rightStream.getTracks().map((t) => t.clone())) : null;

          onStart().then(async (commentId) => {
            const vsr = (() => {
              if (rightStream) {
                return new VideoStreamRecorder({ commentId }, [leftStream, rightStream]);
              } else {
                return new VideoStreamRecorder({ commentId }, [leftStream]);
              }
            })();

            setState({
              stage: 'capture',
              vsr,
            });
          }, () => {
            setState({
              stage: 'form',
              loading: false,
            });
          });
        } catch {
          setState({
            stage: 'form',
            loading: false,
          });
        }
      };

      const handleClose: ComponentProps<typeof MediaCaptureFormModal>['onClose'] = () => {
        onClose();
      };

      const handleFileSelect = (file: File) => {
        setState({
          stage: 'file-upload',
          file,
        });
      };

      return (
        <MediaCaptureFormModal
          initialOption={initialOption}
          disabled={state.loading}
          onStart={handleSelect}
          onClose={handleClose}
          onFileSelect={handleFileSelect}
        />
      );
    }

    case 'capture': {
      return (
        <MediaCaptureModal
          vsr={state.vsr}
          onClose={onClose}
          onStart={onStart}
        />
      );
    }

    case 'file-upload': {
      const handleBackClick = () => {
        setState({
          stage: 'form',
          loading: false,
        });
      };

      const handleCloseClick = () => {
        onClose();
      };

      return (
        <MediaUploadModal
          file={state.file}
          onBackClick={handleBackClick}
          onCloseClick={handleCloseClick}
          onStart={onStart}
        />
      );
    }

    default: return (
      <div>
        {__('Something went wrong.')}
      </div>
    );
  }
};

export default MediaCommentModal;
