import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';

const CopyButton = (props: {
  content: string | (() => string);
  className?: string;
  children: React.ReactNode;
}) => {
  const { content, className, children } = props;

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) { return  undefined; }

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const handleCopyClick = () => {
    const toCopy = (() => {
      switch (typeof content) {
        case 'string': return content;
        case 'function': return content();
        default: return '';
      }
    })();

    if (copy(toCopy)) {
      setCopied(true);
    }
  };

  return (
    <button type="button" className={className} onClick={handleCopyClick}>
      {copied ? (
        __('Copied!')
      ) : (
        children
      )}
    </button>
  );
};

export default CopyButton;
