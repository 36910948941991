import { round } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { Modal, ModalBox, ModalHeader } from '../../../common/components/TailwindModal';
import * as Atlas from '../../../common/types/Atlas';
import { JwPlayerProvider } from '../../jwplayer/JwPlayer';
import VideoEditorCropper from '../../video-editor/v2/VideoEditorCropper';
import VideoEditorPlayer from '../../video-editor/v2/VideoEditorPlayer';

interface EditTimestampModalProps {
  isOpen: boolean;
  videoId: Atlas.VideoID;
  initialStart?: number;
  initialDuration?: number;
  onCancel: () => void;
  onSave: (args: {
    start: number;
    duration: number;
  }) => Promise<void>;
}

const EditTimestampModalContent = (props: EditTimestampModalProps) => {
  const {
    videoId,
    initialStart,
    initialDuration,
    onCancel,
    onSave,
  } = props;

  const [state, setState] = useState<{
    status: 'idle' | 'saving' | 'error';
    values: [number, number];
  }>(() => {
    const timeStart = typeof initialStart === 'number' ? initialStart : 0;
    const timeEnd = typeof initialDuration === 'number' ? (timeStart + initialDuration) : Infinity;

    return {
      status: 'idle',
      values: [timeStart / 1000, timeEnd / 1000],
    };
  });

  const validCropValues = (() => {
    const [start, end] = state.values;
    if (!Number.isFinite(start)) { return false; }
    if (!Number.isFinite(end)) { return false; }
    return true;
  })();

  const handleCropChange = useCallback<React.ComponentProps<typeof VideoEditorCropper>['onChange']>((cb) => {
    setState((s) => ({
      ...s,
      ...cb({
        values: s.values,
      }),
    }));
  }, []);

  const handleSaveClick = () => {
    if (state.status === 'saving') { return; }

    setState((s) => ({ ...s, status: 'saving' }));

    const [timeStart, timeEnd] = state.values;
    const start = round(timeStart * 1000);
    const duration = round((timeEnd - timeStart) * 1000);

    void onSave({
      start,
      duration,
    }).then(() => {
      setState((s) => ({ ...s, status: 'idle' }));
    }, () => {
      setState((s) => ({ ...s, status: 'error' }));
    });
  };

  return (
    <>
      <JwPlayerProvider value={{ id: `video-editor-${videoId}` }}>
        <div className="tw-mb-2">
          <VideoEditorPlayer
            videoId={videoId}
          />
        </div>

        <div className="tw-mb-2">
          <VideoEditorCropper
            videoId={videoId}
            values={state.values}
            onChange={handleCropChange}
          />
        </div>
      </JwPlayerProvider>

      <div className="tw-mt-5">
        {state.status === 'error' ? (
          <div className="tw-alert tw-mb-2">
            <span className="tw-text-base">
              <i className="fa fa-exclamation-circle tw-text-xl" />
              {__('Oops! Timestamp failed to save. Please try again.')}
            </span>
          </div>
        ) : null}

        <div className="tw-flex tw-gap-3 tw-justify-between">
          <button type="button" className="tw-btn" onClick={onCancel}>
            {__('Cancel')}
          </button>

          <button
            type="button"
            disabled={!validCropValues}
            className="tw-btn tw-btn-info"
            onClick={handleSaveClick}
          >
            {state.status === 'saving' ? <span className="tw-loading" /> : null}
            {__('Save')}
          </button>
        </div>
      </div>
    </>
  );
};

const EditTimestampModal = (props: EditTimestampModalProps): JSX.Element => {
  const {
    isOpen, onCancel,
  } = props;

  return (
    <Modal isOpen={isOpen} className="tw-modal-bottom md:tw-modal-middle">
      <ModalBox className="tw-max-w-screen-md">
        <ModalHeader onClose={onCancel}>
          {__('Edit Timestamp')}
        </ModalHeader>

        <EditTimestampModalContent {...props} />
      </ModalBox>
    </Modal>
  );
};

export default EditTimestampModal;
