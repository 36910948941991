import * as Atlas from "../../../common/types/Atlas";

export const validateMultipleChoiceFormAnswer = (formElement: Atlas.FormElement, formAnswer: Partial<Atlas.FormAnswer>): boolean => {
  const { type, required } = formElement.field;
  if (!('multiple_choice' in type)) { throw 'Form answer does not match form element'; }
  const value = formAnswer.value as Atlas.FormAnswerMultipleChoiceValue;

  if (type.multiple_choice) {
    return !!value.length || !required;
  } else if (Array.isArray(value)) {
    return required ? value.length === 1 : value.length <= 1;
  }

  return !!value;
};

export const validateRatingFormAnswer = (formElement: Atlas.FormElement, formAnswer: Partial<Atlas.FormAnswer>): boolean => {
  const { type, required } = formElement.field;
  if (!('rating' in type)) { throw 'Form answer does not match form element'; }
  const value = formAnswer.value;
  return Number.isInteger(value) || !required;
};

export const validateTextFormAnswer = (formElement: Atlas.FormElement, formAnswer: Partial<Atlas.FormAnswer>): boolean => {
  const { type, required } = formElement.field;
  if (!('text' in type)) { throw 'Form answer does not match form element'; }

  const value = formAnswer.value;
  return !!value || !required;
};

export const validateUrlFormAnswer = (formElement: Atlas.FormElement, formAnswer: Partial<Atlas.FormAnswer>): boolean => {
  const { type, required } = formElement.field;
  if (!('url' in type)) { throw 'Form answer does not match form element'; }

  const value = formAnswer.value as Atlas.FormAnswerUrlValue;
  return !!value || !required;
};

export const validateCounterFormAnswer = (formElement: Atlas.FormElement, formAnswer: Partial<Atlas.FormAnswer>): boolean => {
  const { type, required } = formElement.field;
  if (!('counter' in type)) { throw 'Form answer does not match form element'; }

  const value = formAnswer.counter_value;
  if (!value) { return !required; }
  return value.every(cv => cv.choice && cv.clicks);
};

export const validateTimerFormAnswer = (formElement: Atlas.FormElement, formAnswer: Partial<Atlas.FormAnswer>): boolean => {
  const { type, required } = formElement.field;
  if (!('timer' in type)) { throw 'Form answer does not match form element'; }

  const value = formAnswer.timer_value;
  if (!value) { return !required; }
  return value.every(tv => tv.choice && tv.intervals);
};

export const validateAttachmentFormAnswer = (formElement: Atlas.FormElement, formAnswer: Partial<Atlas.FormAnswer>): boolean => {
  const { type, required } = formElement.field;
  if (!('attachment' in type)) { throw 'Form answer does not match form element'; }
  return Number.isInteger(formAnswer.attachment_value) || !required;
};

export const validateReflectionFormAnswer = (formElement: Atlas.FormElement, formAnswer: Partial<Atlas.FormAnswer>): boolean => {
  const { type, required } = formElement.field;
  if (!('reflection' in type)) { throw 'Form answer does not match form element'; }
  return Number.isInteger(formAnswer.reflection_value) || !required;
};

const getFormElementValidator = (fe: Atlas.FormElement) => {
  const formFieldType = Object.keys(fe.field.type)[0];

  switch (formFieldType) {
    case 'multiple_choice':
      return (fa: Atlas.FormAnswer) => validateMultipleChoiceFormAnswer(fe, fa);
    case 'rating':
      return (fa: Atlas.FormAnswer) => validateRatingFormAnswer(fe, fa);
    case 'text':
      return (fa: Atlas.FormAnswer) => validateTextFormAnswer(fe, fa);
    case 'url':
      return (fa: Atlas.FormAnswer) => validateUrlFormAnswer(fe, fa);
    case 'counter':
      return (fa: Atlas.FormAnswer) => validateCounterFormAnswer(fe, fa);
    case 'timer':
      return (fa: Atlas.FormAnswer) => validateTimerFormAnswer(fe, fa);
    case 'attachment':
      return (fa: Atlas.FormAnswer) => validateAttachmentFormAnswer(fe, fa);
    case 'reflection':
      return (fa: Atlas.FormAnswer) => validateReflectionFormAnswer(fe, fa);
  }

  return () => false;
};

export const validateAssignmentSubmission = (assignment: Atlas.Assignment, submission: Partial<Atlas.AssignmentSubmission>): Record<Atlas.FormFieldName, boolean> => {
  const results: Record<Atlas.FormFieldName, boolean> = {};

  const answers = submission.answers || [];

  assignment.questions.forEach(fe => {
    const fa = answers.find(fa => fa.name === fe.field.name);
    if (fa) {
      results[fe.field.name] = getFormElementValidator(fe)(fa);
    } else {
      results[fe.field.name] = !fe.field.required;
    }
  });

  return results;
};
