import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Modal, ModalBox, ModalHeader } from '../../../common/components/TailwindModal';
import * as Atlas from '../../../common/types/Atlas';
import { withQueryClient } from '../../../common/hooks/withQueryClient';
import AttachmentName from '../../../common/components/AttachmentName';
import ReflectionName from '../../../common/components/ReflectionName';
import GroupAssignmentSubmission from './AssignmentSubmission';

const joinWords = (...words: string[]) => words.filter(Boolean).join(' ');

const truncateWithEllipsis = (text: string | null, maxLength: number): string | null => {
  if (!text) return text;
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

interface SubmissionListItemProps {
  submission: Atlas.ExpandedAssignmentSubmission;
  questions: Atlas.FormElement[];
  onViewClick: () => void;
}

const SubmissionListItem = (props: SubmissionListItemProps): JSX.Element => {

  const userName = joinWords(props.submission?.first_name ?? '', props.submission?.last_name ?? '');

  const firstQuestion = props.questions[0];
  const firstQuestionType = Object.keys(firstQuestion.field.type)[0];

  const firstQuestionName = firstQuestion.field.name;
  const firstQuestionLabel = firstQuestion.field.label;
  const truncatedQuestionLabel = truncateWithEllipsis(firstQuestionLabel, 20);
  const firstAnswer = props.submission.answers.find(answer => answer.name === firstQuestionName);

  const questionPreview = (() => {
    switch (firstQuestionType) {
      case 'rating': {
        if (firstAnswer) {
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {firstQuestion.field.label}:&nbsp;
              </span>
              {firstAnswer.value}
            </div>
          )
        } else {
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {firstQuestion.field.label}:&nbsp;
              </span>
              <span>
                {__("No rating given")}
              </span>
            </div> 
          )
        }
      }

      case 'multiple_choice': {
        if (firstAnswer) {
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {firstQuestion.field.label}:&nbsp;
              </span>
              {Array.isArray(firstAnswer.value) 
                ? firstAnswer.value.join(', ') 
                : firstAnswer.value}
            </div>
          )
        } else {
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {firstQuestion.field.label}:&nbsp;
              </span>
              <span>
                {__("No choice given")}
              </span>
            </div> 
          )
        }
      }

      case 'text': {
        if (firstAnswer) {
          
          const truncatedAnswer = truncateWithEllipsis(firstAnswer.value !== null ? String(firstAnswer.value) : null, 45);
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {truncatedQuestionLabel}:&nbsp;
              </span>
              {truncatedAnswer} 
            </div>
          )
        } else {
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {firstQuestion.field.label}:&nbsp;
              </span>
              <span>
                {__("No answer given")}
              </span>
            </div> 
          )
        }
      }

      case 'attachment': {
        if (firstAnswer && firstAnswer.attachment_value) {
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {firstQuestion.field.label}:&nbsp;
              </span>
               <AttachmentName attachmentId={firstAnswer.attachment_value}/>
            </div>
          )
        } else {
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {firstQuestion.field.label}:&nbsp;
              </span>
              <span>
                {__("No file uploaded")}
              </span>
            </div> 
          )
        }
      }

      case 'reflection': {
        if (firstAnswer && firstAnswer.reflection_value) {
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {firstQuestion.field.label}:&nbsp;
              </span>
              <ReflectionName reflectionId={firstAnswer.reflection_value} />
            </div>
          )
        } else {
          return (
            <div className="tw-flex tw-flex-row">
              <span className="tw-text-gray-500">
                {firstQuestion.field.label}:&nbsp;
              </span>
              <span>
                {__("No reflection chosen")}
              </span>
            </div> 
          )
        }
      }
      default:
        return null;
    }
  })();

  return (
    <>
      <div className="tw-flex tw-justify-between tw-mb-4">
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-flex-row tw-mx-2 tw-mb-1">
            <span className="tw-text-gray-500">
              {__("From:")}&nbsp;
            </span>
            <span>{userName}</span>
          </div>
          <div className="tw-flex tw-flex-row tw-mx-2 tw-mb-1">
            <span className="tw-text-gray-500">
              {__("Submitted at:")}&nbsp;
            </span> 
            <span>
              {moment(props.submission.created_at).format('l')}
            </span>
          </div>
          <div className="tw-flex tw-flex-row tw-mx-2 tw-mb-1">
            <span className="tw-text-gray-500">
              {__("Status:")}&nbsp;
            </span>     
            {props.submission.complete === 'complete' ? (
              <span>{__("Complete")}</span>
            ) : (
              <span>{__("Submitted")}</span>
            )}
          </div>
          <div className="tw-mx-2">
            {questionPreview}
          </div>
        </div>
        <div>
          <button 
          type="button" 
          onClick={() => props.onViewClick()}
          className={classNames('tw-btn tw-btn-sm')}>
          {__('View')}
        </button>
        </div>
      </div>
    </>
  )
}

interface SubmissionsListProps {
  submissions: Atlas.ExpandedAssignmentSubmission[];
  questions: Atlas.FormElement[];
  onViewClick: (submission: Atlas.ExpandedAssignmentSubmission) => void;
}

const SubmissionsList = (props: SubmissionsListProps): JSX.Element => {

  return (
    <>
      {props.submissions.map((submission,index) => (
        <div key={submission.id}>
          <div className="tw-divider"/>
          <SubmissionListItem
            submission={submission}
            questions={props.questions}
            onViewClick={() => props.onViewClick(submission)}
          />
        </div>
      ))}
    </>
  )
}

interface SubmissionsFiltersProps {
  setFilter: React.Dispatch<React.SetStateAction<'all' | 'mine'>>;
  selectedFilter: 'all' | 'mine';
}

const SubmissionsFilters = (props: SubmissionsFiltersProps) => {
  return (
    <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-mb-4">
      <button 
        type="button" 
        onClick={() => props.setFilter('all')}
        className={classNames(`tw-btn tw-btn-sm ${props.selectedFilter === 'all' ? 'tw-btn-primary' : ''}`)}>
        {__('All')}
      </button>
      <button 
        type="button"
        onClick={() => props.setFilter('mine')}
        className={classNames(`tw-btn tw-btn-sm ${props.selectedFilter === 'mine' ? 'tw-btn-primary' : ''}`)}>
        {__('Mine')}
      </button>
    </div>
  );
}

interface AssignmentSubmissionsModalProps{
  group: Atlas.Group;
  assignment: Atlas.Assignment;
  submissions: Atlas.ExpandedAssignmentSubmission[];
  userId?: Atlas.UserID;
  isAssessor: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmitted: () => void;
}

const AssignmentSubmissionsModal = ({
  group,
  assignment,
  submissions,
  userId,
  isAssessor,
  isOpen,
  onClose,
  onSubmitted
}: AssignmentSubmissionsModalProps): JSX.Element => {

  const [filter, setFilter] = useState<'all' | 'mine'>('all');
  const [selectedSubmission, setSelectedSubmission] = useState<Atlas.ExpandedAssignmentSubmission | null>(null);
  const [view, setView] = useState<'index' | 'detail'>('index');

  const setIndexView = () => {
    setSelectedSubmission(null);
    setView('index')
  };
  const setDetailView = (submission: Atlas.ExpandedAssignmentSubmission) => {
    setSelectedSubmission(submission);
    setView('detail');
  }

  const filteredSubmissions: Atlas.ExpandedAssignmentSubmission[] = filter === 'mine' 
    ? submissions.filter(sub => sub.user_id === userId) 
    : submissions;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBox className="tw-max-w-xl tw-bg-gray-50">
        <ModalHeader onClose={onClose}>
          {view === 'index' ? (
            __('Submissions') 
          ): ( 
            <button
              className="tw-btn tw-btn-sm"
              onClick={() => setIndexView()}>
              <i className="fa fa-chevron-left tw-mr-1" />
              {__("Back")}
            </button>
          )}
        </ModalHeader>
        {view === 'index' ? (
          <>
            {isAssessor && 
              <SubmissionsFilters 
                setFilter={setFilter}
                selectedFilter={filter}
              />}
            <SubmissionsList 
              questions={assignment.questions}
              submissions={filteredSubmissions}
              onViewClick={setDetailView}
            /> 
          </>
        ) : ( 
          <>
            {selectedSubmission &&
            <GroupAssignmentSubmission
              group={group}
              assignment={assignment}
              initialSubmission={selectedSubmission}
              onCompleted={onSubmitted}
            />}
          </>
        )}
      </ModalBox>
    </Modal>
  );
};

export default withQueryClient(AssignmentSubmissionsModal);