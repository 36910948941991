import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createGroupAssignment } from '../../../../../common/api/groups/assignments';
import GroupAssignmentBuilder from '../../../../../modules/group-assignment/GroupAssignmentBuilder';
import * as Atlas from '../../../../../common/types/Atlas';
import { getAssignmentsBasePath } from '../../../../../common/utils/getAssignmentsBasePath';
import useCurrentUserQuery from '../../../../../common/hooks/api/useCurrentUserQuery';
import Spinner from '../../../../../common/components/Spinner';

interface NewGroupAssignmentPageProps {
  saving: boolean;
}

const NewGroupAssignmentPage = (): JSX.Element => {
  const params = useParams<{ groupId: string; }>();
  const groupId = Number(params.groupId);

  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);

  const userQuery = useCurrentUserQuery();

  const isSuperAdmin = userQuery.data && userQuery.data.admin === Atlas.AdministratorType.Super;

  const navigate = useNavigate();

  const [state, setState] = useState<NewGroupAssignmentPageProps>({
    saving: false
  });

  const handleSaveClick = async (assignment: Partial<Atlas.Assignment> & { name: Atlas.AssignmentName; }) => {
    setState(s => ({ ...s, saving: true }));

    await createGroupAssignment({
      params: { groupId },
      body: { assignment }
    });

    navigate(`/${basePath}/${groupId}/assignments`);
  };

  if (userQuery.isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-5">
        <Spinner color="info" />
      </div>
    );
  }

  return (
    <GroupAssignmentBuilder
      groupId={groupId}
      saving={state.saving}
      isSuperAdmin={isSuperAdmin}
      onSaveClick={handleSaveClick} />
  );
};

export default NewGroupAssignmentPage;
