import React from 'react';
import * as Atlas from '../../../../../common/types/Atlas';
import CommentResourceLinkGroup from './CommentResourceLinkGroup';
import CommentResourceLinkReflection from './CommentResourceLinkReflection';
import CommentResourceLinkInaccessible from './CommentResourceLinkInaccesible';

const CommentResourceLink: React.FC<{
  resourceLink: Atlas.ExpandedCommentResourceLink;
}> = (props) => {
  const { resourceLink } = props;

  if ('group' in resourceLink) {
    return (
      <CommentResourceLinkGroup
        name={resourceLink.group.name}
        type={resourceLink.group.type}
        iconUrl={resourceLink.group.logo?.viewable_s3uri?.url}
        href={`/groups/${resourceLink.group.id}`}
      />
    );
  }

  if ('pathway_group' in resourceLink) {
    return (
      <CommentResourceLinkGroup
        name={resourceLink.pathway_group.name}
        type={resourceLink.pathway_group.type}
        iconUrl={resourceLink.pathway_group.logo?.viewable_s3uri?.url}
        href={`/guides/${resourceLink.pathway_group.id}`}
      />
    );
  }

  if ('guide_group' in resourceLink) {
    return (
      <CommentResourceLinkGroup
        name={resourceLink.guide_group.name}
        type={resourceLink.guide_group.type}
        iconUrl={resourceLink.guide_group.logo?.viewable_s3uri?.url}
        href={`/guides/${resourceLink.guide_group.id}`}
      />
    );
  }

  if ('reflection' in resourceLink) {
    return (
      <CommentResourceLinkReflection
        name={resourceLink.reflection.name}
        iconUrl={resourceLink.reflection.thumbnail_s3uri?.url}
        href={`/reflections/${resourceLink.reflection.id}`}
      />
    );
  }

  if ('inaccessible' in resourceLink) {
    return (
      <CommentResourceLinkInaccessible
        resourceLink={resourceLink.inaccessible}
      />
    );
  }

  return null;
};

export default CommentResourceLink;
