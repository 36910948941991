import * as Atlas from '../../types/Atlas';
import { parseModelObjectArray } from '../api-parser';
import ApiError from '../error';
import { PaginationParams, formDataRoute, jsonHeaders, searchParams } from '../routes';

export type FindAllFormDataParams = PaginationParams & {
  form_id?: Atlas.FormID;
  reflection_id?: Atlas.ReflectionID;
  view?: 'expanded';
};

export interface FindAllFormDataArguments {
  params?: FindAllFormDataParams;
}

export interface FindAllFormDataResponse {
  data: Atlas.FormData[];
}

export const findAllFormData = async (
  args?: FindAllFormDataArguments
): Promise<FindAllFormDataResponse> => {
  const { params = {} } = args || {};

  const url = [
    formDataRoute(),
    searchParams({
      ...params
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'findAllFormData');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.FormData>(body);

  return { data };
};
