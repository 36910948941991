import React from 'react';
import { FormID, FormJob, JobStage, ReflectionID } from '../../common/types/Atlas';
import useFindReflectionFormQuery from '../../common/hooks/api/reflections/useFindReflectionFormQuery';
import { isActiveFormJobForReflection } from '../../common/utils/form-job.utils';

export const FormJobStatus: React.FC<{
  job: FormJob;
}> = ({
  job,
}) => {
    switch (job.stage) {
      case JobStage.DependencyError:
      case JobStage.Error:
      case JobStage.Aborted: return (
        <div className="tw-flex tw-items-center tw-gap-1">
          <i className="fa fa-exclamation-circle" />

          <span>
            {__('Video analysis failed')}
          </span>
        </div>
      );

      case JobStage.Acknowledged:
      case JobStage.Pending:
      case JobStage.Processing: return (
        <div className="tw-space-y-1">
          <div className="tw-flex tw-items-center tw-gap-1">
            <i className="fa fa-circle-o-notch fa-spin" />

            <span>
              {__('Analysing video')}
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <progress value={Math.max(5, job.progress)} max={100} className="tw-progress tw-progress-info" />
            <div className="tw-text-xs tw-font-semibold">
              {`${job.progress}%`}
            </div>
          </div>
        </div>
      );

      case JobStage.Completed: return (
        <div className="tw-space-y-1">
          <div className="tw-flex tw-items-center tw-gap-1">
            <i className="fa fa-circle-o-notch fa-spin" />

            <span>
              {__('Analysing video')}
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <progress value={Math.max(5, job.progress)} max={100} className="tw-progress tw-progress-info" />
            <div className="tw-text-xs tw-font-semibold">
              {`${job.progress}%`}
            </div>
          </div>
        </div>
      );
    }
  };

export const FormJobsList: React.FC<{
  jobs: FormJob[];
}> = ({
  jobs,
}) => (
  <div className="tw-shadow-inner tw-p-4 tw-rounded-xl tw-bg-base-300 tw-space-y-4 tw-border-base-400">
    {jobs.map((job) => (
      <FormJobStatus key={job.id} job={job} />
    ))}
  </div>
);

export const ActiveFormJobsList: React.FC<{
  formId: FormID;
  reflectionId: ReflectionID,
}> = ({
  formId,
  reflectionId,
}) => {
    const formQuery = useFindReflectionFormQuery({
      params: {
        formId,
        reflectionId,
      },
    }, { refetchInterval: 30000 });

    switch (formQuery.status) {
      case 'loading': return null;

      case 'success':
        const jobs = formQuery.data.form_jobs.filter((job) => (
          isActiveFormJobForReflection(job, reflectionId)
        ));

        if (jobs.length === 0) {
          return null;
        }

        return <FormJobsList jobs={jobs} />

      case 'error':
      default:
        return null;
    }
  };
