import { useQuery, UseQueryResult } from 'react-query';
import { findGroupAssignments, FindGroupAssignmentsParams, FindGroupAssignmentsResponse } from '../../../api/groups/assignments';

const useFindGroupAssignmentsQuery = (
  params: FindGroupAssignmentsParams,
  options?: { enabled?: boolean; },
): UseQueryResult<FindGroupAssignmentsResponse> => (
  useQuery(
    ['groups', params.groupId, 'assignments'], 
    () => findGroupAssignments({ params }),
    { ...options },
  )
);

export default useFindGroupAssignmentsQuery;
