import React, { useState } from 'react';
import Collapse from '@kunukn/react-collapse';
import * as Atlas from '../../../common/types/Atlas';
import { Tooltip } from 'react-tippy';
import { DiscussionToolbarOptionsButton, DiscussionToolbarButton } from '../styles/toolbar';
import { Row } from '../../../common/components/Row';
import { Col } from '../../../common/components/Col';

interface HeaderToolBarProps {
  onOptionsClick: () => void;
  onLockClick: () => void;
  onRenameClick: () => void;
  onDeleteClick: () => void;
  onSubscribeClick: () => void;
  checkPermissions: boolean;
  locked: Atlas.DiscussionLocked;
}

const HeaderToolBar = (props: HeaderToolBarProps): JSX.Element => {
  const {
    onOptionsClick,
    onLockClick,
    onRenameClick,
    onDeleteClick,
    onSubscribeClick,
    checkPermissions,
    locked
  } = props;

  const [state, setState] = useState({
    showToolbar: false,
  });

  const handleOptionsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onOptionsClick();
  };

  const handleLockClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onLockClick();
  };

  const handleRenameClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onRenameClick();
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onDeleteClick();
  };
  const handleSubscribeClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onSubscribeClick();
  };

  const toggleToolbar = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setState((s) => ({ ...s, showToolbar: !s.showToolbar }))
  };


  return (
    <div className="d-flex align-items-center">
      <Collapse
        overflowOnExpanded
        isOpen={state.showToolbar}
        style={{
          transition: '300ms',
        }}
      >
        <div
          className="rounded-pill px-3 py-1 bg-info"
          style={{ backgroundImage: 'linear-gradient(0deg, rgba(255,255,255,.75), rgba(255,255,255,.75))' }}
        >
          <Row className="mx-n2">
            <Col className="px-2">
              <Tooltip
                title={__('Notification Level')}
                position="top"
                trigger="mouseenter"
                followCursor={true}
                size="small"
              >
                <DiscussionToolbarOptionsButton role="button" href="#" title={__('Notification level settings')} onClick={handleOptionsClick}>
                  <i className="fa fa-bell" />
                </DiscussionToolbarOptionsButton>
              </Tooltip>
            </Col>

            <Col className="px-2">
              <Tooltip
                title={__('Subscribe')}
                position="top"
                trigger="mouseenter"
                followCursor={true}
                size="small"
              >
                <DiscussionToolbarOptionsButton role="button" href="#" onClick={handleSubscribeClick}>
                  <i className="fa fa-user-plus" />
                </DiscussionToolbarOptionsButton>
              </Tooltip>
            </Col>

            {checkPermissions ? (
              <>
                <Col className="px-2">
                  <Tooltip
                    title="Lock"
                    position="top"
                    trigger="mouseenter"
                    followCursor={true}
                    size="small"
                  >
                    <DiscussionToolbarOptionsButton role="button" href="#" onClick={handleLockClick}>
                      {locked === "unlocked" ? <i className="fa fa-unlock" /> : <i className="fa fa-lock" />}
                    </DiscussionToolbarOptionsButton>
                  </Tooltip>
                </Col>

                <Col className="px-2">
                  <Tooltip
                    title={__('Rename discussion')}
                    position="top"
                    trigger="mouseenter"
                    followCursor={true}
                    size="small"
                  >
                    <DiscussionToolbarOptionsButton role="button" href="#" title={__('Rename discussion')} onClick={handleRenameClick}>
                      <i className="fa fa-edit" />
                    </DiscussionToolbarOptionsButton>
                  </Tooltip>
                </Col>

                <Col className="px-2">
                  <Tooltip
                    title={__('Delete')}
                    position="top"
                    trigger="mouseenter"
                    followCursor={true}
                    size="small"
                  >
                    <a role="button" href="#" className="text-danger" onClick={handleDeleteClick}>
                      <i className="fa fa-trash trash" />
                    </a>
                  </Tooltip>
                </Col>
              </>
            ) : null}
          </Row>
        </div>
      </Collapse>

      <Tooltip
        title="Settings"
        position="top"
        trigger="mouseenter"
        followCursor={true}
        size="small"
      >
        <DiscussionToolbarButton className="px-2" role="button" href="#" data-testid="toggle-toolbar" onClick={toggleToolbar}>
          <i className="fa fa-cog fa-lg" />
        </DiscussionToolbarButton>
      </Tooltip>
    </div>
  );
};

export default HeaderToolBar;
