import React from 'react';
import * as Atlas from '../../common/types/Atlas';
import PlaylistItem from './playlist-item';

interface VideoEditorPlaylistProps {
  videos: Atlas.Video[];
  videoOrder: Atlas.VideoID[];
  onEditVideoClick: (videoId: Atlas.VideoID) => void;
}

const VideoEditorPlaylist = (props: VideoEditorPlaylistProps): JSX.Element => {
  const { videos, videoOrder, onEditVideoClick } = props;

  const orderedVideos = [...videos].sort((a, b) => {
    const aIndex = videoOrder.indexOf(a.id);
    const bIndex = videoOrder.indexOf(b.id);

    if (aIndex === -1) { return 1; }
    if (bIndex === -1) { return -1; }

    return aIndex - bIndex;
  });

  return (
    <div className="tw-space-y-3">
      {orderedVideos.map((video, index) => (
        <PlaylistItem
          key={video.id}
          video={video}
          position={index}
          lastPosition={orderedVideos.length - 1}
          onEditClick={() => onEditVideoClick(video.id)}
        />
      ))}
    </div>
  );
};

export default VideoEditorPlaylist;
