import React, { ReactNode } from 'react';
import { Text } from 'recharts';
import { truncate } from '../../../common/utils/utils';

export const pieLegendFormatter = (value: any): ReactNode => {
  if (typeof value === 'string') {
    return (
      <Text>
        {truncate(value, { maxLength: 60 })}
      </Text>
    );
  }

  return (
    <Text>
      {value}
    </Text>
  );
};

export const lineLegendFormatter = (value: any): ReactNode => {
  if (typeof value === 'string') {
    return (
      <Text>
        {truncate(value, { maxLength: 120 })}
      </Text>
    );
  }

  return (
    <Text>
      {value}
    </Text>
  );
};
