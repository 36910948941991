import * as Atlas from '../../../types/Atlas';
import { jsonHeaders, Metadata, PaginationParams, searchParams } from '../../routes';

export const searchGroupUsersRoute = ({ groupId }: { groupId: Atlas.GroupID }): string => `/groups/${groupId}/users`;

interface SearchGroupUsersArgs {
  params: PaginationParams & {
    groupId: Atlas.GroupID;
    search: string;
  };
}

export const searchGroupUsers = (args: SearchGroupUsersArgs): Promise<{ data: Atlas.UserInGroup[], metadata: Metadata }> => {
  const { groupId, ...params } = args?.params || {};

  const url = [
    searchGroupUsersRoute({ groupId }),
    searchParams({ ...params })
  ].join('?');

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] searchGroupUsers failed`; }
    return r.json();
  });
};
