/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import { cva, cx, VariantProps } from 'cva';
import React, { forwardRef } from 'react';

export const button = cva([
  'btn',
], {
  variants: {
    color: {
      primary: 'btn-primary',
      secondary: 'btn-secondary',
      success: 'btn-success',
      info: 'btn-info',
      warning: 'btn-warning',
      danger: 'btn-danger',
      link: 'btn-link',
      light: '',
    },
    size: {
      sm: 'btn-small',
      small: 'btn-small',
      lg: 'btn-large',
      large: 'btn-large',
    },
    active: {
      true: 'active',
    },
    block: {
      true: 'btn-block',
    },
    disabled: {
      true: 'disabled',
    },
  },
  defaultVariants: {
    color: 'secondary',
  },
});

export type ButtonVariantProps = VariantProps<typeof button>;

type ButtonProps =
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps & ButtonVariantProps & { tag?: 'button' | 'a'; href?: string; target?: string; }>(({
  active,
  block,
  className,
  color,
  disabled,
  size,
  tag = 'button',
  href,
  target,
  ...props
}, ref) => {
  switch (tag) {
    case 'a': return (
      // @ts-expect-error: this adds backwards compatibility
      <a
        {...props}
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        href={href}
        target={target}
        className={cx(className, button({
          active,
          block,
          color,
          disabled,
          size,
        }))}
      />
    );

    default: return (
      <button
        type="button"
        {...props}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        disabled={disabled}
        className={cx(className, button({
          active,
          block,
          color,
          disabled,
          size,
        }))}
      />
    );
  }
});
