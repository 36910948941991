import React from 'react';
import useReflectionVideosQuery from '../../../common/hooks/api/useReflectionVideosQuery';
import * as Atlas from '../../../common/types/Atlas';
import useFindReflectionQuery from '../../../common/hooks/api/reflections/useFindReflectionQuery';
import VideoCommentForm from './VideoCommentForm';
import VideoComments from './VideoComments';
import useVideoPlayer from '../../jwplayer/useVideoPlayer';
import { JwPlayerScope } from '../../jwplayer/JwPlayerScope';
import Spinner from '../../../common/components/Spinner';

interface ReflectionCommentsProps {
  reflectionId: Atlas.ReflectionID;
  isEmbedded?: boolean
}

const ReflectionComments = (props: ReflectionCommentsProps): JSX.Element => {
  const { reflectionId, isEmbedded } = props;

  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const videosQuery = useReflectionVideosQuery({ reflectionId, per_page: -1 });

  const videoPlayer = useVideoPlayer();

  const renderLoading = () => (
    <div className="tw-text-center tw-py-5">
      <Spinner color="info" />
    </div>
  );

  const renderError = () => (
    <div className="tw-text-center tw-py-5 tw-text-xl tw-text-gray-600">
      {__('Oops! Something went wrong.')}
    </div>
  );

  switch (reflectionQuery.status) {
    case 'success': break;
    case 'loading': return renderLoading();
    default: return renderError();
  }

  switch (videosQuery.status) {
    case 'success': break;
    case 'loading': return renderLoading();
    default: return renderError();
  }

  const reflection = reflectionQuery.data.data;
  const videos = videosQuery.data.data;

  const orderedVideos = [...videos].sort((a, b) => {
    const aIndex = reflection.video_ids.indexOf(a.id);
    const bIndex = reflection.video_ids.indexOf(b.id);

    if (aIndex === -1) { return 1; }
    if (bIndex === -1) { return -1; }

    return aIndex - bIndex;
  });

  const activeVideoId = videoPlayer?.videoId ?? orderedVideos[0]?.id;

  const canAddComments = reflection.comment_mode === Atlas.CommentMode.Normal;

  return (
    <JwPlayerScope>
      <div className="tw-flex tw-flex-col tw-gap-2 xs:tw-gap-4 tw-h-full">
        <div>
          {canAddComments && activeVideoId ? (
            <VideoCommentForm
              videoId={activeVideoId}
            />
          ) : null}
        </div>

        <div className={`tw-comments-container tw-h-0 tw-overflow-scroll tw-rounded-xl tw-bg-base-300 tw-border tw-border-base-400 tw-shadow-inner tw-p-2 xs:tw-p-4 tw-min-h-[130px] ${isEmbedded ? "tw-flex-1 tw-max-h-[400px] tw-relative" : "tw-grow"}`}>
          {orderedVideos.map((video, index) => {
            const handleVideoNameClick = () => {
              videoPlayer?.play(video.id);
            };

            return (
              <section key={video.id} className="tw-mb-2">
                <div className="tw-flex tw-justify-between tw-items-baseline tw-gap-4 tw-mb-3 tw-border-b tw-border-base-400 tw-pb-2">
                  <button
                    type="button"
                    className="tw-font-semibold tw-pr-1 tw-truncate tw-cursor-pointer hover:tw-underline tw-text-xs xs:tw-text-sm tw-text-slate-600"
                    onClick={handleVideoNameClick}
                  >
                    {video.description || (
                      <div>{__('Untitled Video')}</div>
                    )}
                  </button>

                  {orderedVideos.length > 1 ? (
                    <p className="tw-whitespace-nowrap tw-text-slate-400 tw-text-xs">
                      {`${index + 1} ${__('of')} ${orderedVideos.length}`}
                    </p>
                  ) : null}
                </div>

                <VideoComments
                  readOnly={!canAddComments}
                  videoId={video.id}
                  ownerId={reflection.user_id}
                  isEmbedded={isEmbedded}
                />
              </section>
            );
          })}
        </div>
      </div>
    </JwPlayerScope>
  );
};

export default ReflectionComments;
