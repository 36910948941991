/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useCreateVideoMutation from '../../../common/hooks/api/videos/useCreateVideoMutation';
import * as Atlas from '../../../common/types/Atlas';
import useHermesUploader from './use-hermes-uploader';
import RouterPrompt from '../../../common/components/RouterPrompt';

const renderProgress = (percent: number) => (
  <div className="tw-flex-grow">
    <p className="tw-text-center tw-mb-2">
      {`${percent}% ${__('uploaded')}`}
    </p>

    <progress className="tw-progress tw-progress-info" value={percent} max={100} />
  </div>
);

type VideoUploaderProps = {
  reflectionId?: Atlas.ReflectionID;
  commentId?: Atlas.CommentID;
}

const VideoUploader = (props: VideoUploaderProps): JSX.Element => {
  const { reflectionId, commentId } = props;

  const [uploading, setUploading] = useState(false);

  const createVideoMutation = useCreateVideoMutation();
  const [uploaderState, uploader] = useHermesUploader();

  const onDrop = useCallback(([file]: File[]) => {
    if (!file) { return; }
    if (uploading) { return; }

    setUploading(true);

    createVideoMutation.mutateAsync({
      body: {
        description: file.name,
        reflectionId,
        commentId,
      },
    }).then(async ({ data: video, metadata }) => {
      const mediaKey = video.channels[0].media_key;
      if (!mediaKey) { return; }

      const { hermes } = metadata;
      await uploader({ mediaKey, file, uploadUrl: `${hermes.upload_uri}/` });
    }).finally(() => {
      setUploading(false);
    });
  }, [createVideoMutation, reflectionId, uploader, uploading]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: uploading,
    maxFiles: 1,
    accept: {
      'video/*': [],
      'audio/*': [],
    },
  });

  return (
    <div className="tw-p-4 tw-bg-white tw-shadow tw-rounded-xl">
      <RouterPrompt
        when={uploading}
        message={__('Your upload will be cancelled if you leave this page.')}
      />

      <div className="tw-border-4 tw-border-base-200 tw-border-dashed tw-px-4 tw-py-6 tw-flex tw-justify-center tw-items-center tw-rounded-box" {...getRootProps()}>
        <input {...getInputProps()} />

        {(() => {
          switch (uploaderState.status) {
            case 'uploading': {
              return renderProgress(uploaderState.progress);
            }

            default: {
              if (uploading) {
                return renderProgress(0);
              }

              if (isDragActive) {
                return __('Drop the video here');
              }

              return (
                <>
                  <button type="button" id="video-uploader" className="tw-link tw-link-primary tw-no-underline">
                    {__('Upload video')}
                  </button>
                  &nbsp;
                  <span>
                    {__('or drop a video here to upload')}
                  </span>
                </>
              );
            }
          }
        })()}
      </div>
    </div>
  );
};

export default VideoUploader;
