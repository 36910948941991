import React, { useEffect, useState } from 'react';
import Pagination from '../../../common/components/Pagination';
import useReflectionsQuery from '../../../common/hooks/api/useReflectionsQuery';
import { ReflectionID, UserID } from '../../../common/types/Atlas';

interface ReflectionListProps {
  userId: UserID;
  selected?: ReflectionID;
  onReflectionSelect: (reflectionId: ReflectionID) => Promise<void>;
}

const ReflectionList = (props: ReflectionListProps): JSX.Element => {
  const { userId, selected, onReflectionSelect } = props;

  const [state, setState] = useState({
    page: 1,
    perPage: 5,
    totalResults: 5,
    totalPages: 1,
  });

  const reflectionsQuery = useReflectionsQuery({
    page: state.page,
    per_page: state.perPage,
    userId,
  });

  const totalPages = reflectionsQuery.data?.metadata.total_pages ?? state.totalPages;
  const totalResults = reflectionsQuery.data?.data.length ?? state.totalResults;

  useEffect(() => {
    setState((s) => ({ ...s, totalPages }));
  }, [totalPages]);

  useEffect(() => {
    setState((s) => ({ ...s, totalResults }));
  }, [totalResults]);

  const handlePageChange = (page: number) => {
    setState((s) => ({ ...s, page }));
  };

  return (
    <>
      {(() => {
        switch (reflectionsQuery.status) {
          case 'idle':
          case 'loading': {
            return (
              <div className="tw-mb-4 tw-space-y-2">
                {new Array(state.totalResults).fill(null).map((_, i) => (
                  <div key={i}>
                    <button type="button" className="tw-btn tw-btn-block tw-btn-sm" disabled>
                      &nbsp;
                    </button>
                  </div>
                ))}
              </div>
            );
          }

          case 'success': {
            const reflections = reflectionsQuery.data.data;

            return (
              <div className="tw-mb-4 tw-space-y-2">
                {reflections.map((reflection) => (
                  <div key={reflection.id}>
                    <button
                      type="button"
                      className="tw-btn tw-btn-block tw-btn-sm tw-btn-ghost tw-justify-between"
                      onClick={() => onReflectionSelect(reflection.id)}
                    >
                      <div>
                        {reflection.name ? (
                          reflection.name
                        ) : (
                          <span className="tw-italic">
                            {__('Untitled reflection')}
                          </span>
                        )}
                      </div>

                      <div className="tw-btn tw-btn-primary tw-btn-xs">
                        {selected === reflection.id ? <span className="tw-loading" /> : null}
                        {__('Select')}
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            );
          }

          default: return (
            <div>
              {__('Oops! Something went wrong.')}
            </div>
          );
        }
      })()}

      <Pagination
        page={state.page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default ReflectionList;
