import React, { useImperativeHandle, useState } from 'react';
import { updateDiscussion } from '../../common/api/discussions/[discussionId]';
import { Card } from '../../common/components/BSCard';
import { CardBody } from '../../common/components/BSCardBody';
import { CardHeader } from '../../common/components/BSCardHeader';
import { Button } from '../../common/components/Button';
import Spinner from '../../common/components/Spinner';
import * as Atlas from '../../common/types/Atlas';

export interface NotificationLevelProps {
  initialValue?: Atlas.NotificationLevel;
  initialHidden?: boolean;
  groupId: Atlas.GroupID;
  discussionId: Atlas.DiscussionID;
}

interface NotificationLevelState {
  notificationLevel: Atlas.NotificationLevel;
  hidden: boolean;
  saving: boolean;
  helpExpanded: boolean;
}

export interface NotificationLevelRef {
  toggleVisibility: () => void;
};

const NotificationLevel = React.forwardRef<NotificationLevelRef, NotificationLevelProps>((props, ref): JSX.Element | null => {
  const [state, setState] = useState<NotificationLevelState>({
    notificationLevel: props.initialValue || Atlas.NotificationLevel.All,
    hidden: props.initialHidden ?? true,
    saving: false,
    helpExpanded: false,
  });

  useImperativeHandle(ref, () => ({
    toggleVisibility() {
      setState(s => ({ ...s, hidden: !s.hidden }));
    }
  }));

  if (state.hidden) { return null; }

  const notificationLevels = [{
    value: Atlas.NotificationLevel.All,
    label: __('Loud'),
    description: __('All comments.')
  }, {
    value: Atlas.NotificationLevel.OrganizationOnly,
    label: __('Medium'),
    description: __('Comments from other users in their organization, but not from users in other organizations.')
  }, {
    value: Atlas.NotificationLevel.Minimal,
    label: __('Quiet'),
    description: __('None')
  }];

  const handleCloseClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (state.saving) { return; }
    setState(s => ({ ...s, hidden: true }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (state.saving) { return; }

    const notificationLevel = event.currentTarget.value as Atlas.NotificationLevel,
          prevNotificationLevel = state.notificationLevel;

    setState(s => ({ ...s, notificationLevel, saving: true }));
    updateDiscussion({
      params: { discussionId: props.discussionId },
      body: { notification_level: notificationLevel }
    }).then(({ data: discussion }) => {
      setState(s => ({ ...s, saving: false, notificationLevel: discussion.notification_level }));
    }, () => {
      setState(s => ({ ...s, saving: false, notificationLevel: prevNotificationLevel }));
    });
  };

  const handleHelpClick = () => {
    setState(s => ({ ...s, helpExpanded: !s.helpExpanded }));
  };

  return (
    <Card className="mb-3">
      <CardHeader className="d-flex justify-content-between">
        <span>{__('Notification Level')}</span>

        <a className="red-text" onClick={handleCloseClick}>
          <i className="fa fa-times" />
        </a>
      </CardHeader>

      <CardBody>
        <div>
          <select value={state.notificationLevel} onChange={handleSelectChange}>
            {notificationLevels.map(nl => (
              <option key={nl.value} value={nl.value}>{nl.label}</option>
            ))}
          </select>

          {state.saving ? (
            <Spinner size="sm" color="info" className="ml-2" />
          ) : null}
        </div>

        <div>
          <Button onClick={handleHelpClick}>
            {__('Help')}
          </Button>

          {state.helpExpanded ? (
            <div className="border-top mt-3 pt-3">
              <ul>
                {notificationLevels.map(nl => (
                  <li key={nl.value}>{nl.label} - {nl.description}</li>
                ))}
              </ul>
              <div>
                {__('(Unless someone replies to them.)')}
              </div>
            </div>
          ) : null}
        </div>
      </CardBody>
    </Card>
  );
});

export default NotificationLevel;
