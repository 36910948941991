import React from 'react';
import { CondensedReflection } from '../../../../../common/types/Atlas';
import SearchResultButton from './common/search-result-button';

interface SearchReflectionResultProps {
  reflection: CondensedReflection;
  onClick: () => void;
}

const SearchReflectionResult = (props: SearchReflectionResultProps) => {
  const { reflection, onClick } = props;

  const avatarSrc = reflection.thumbnail_s3uri?.url || '/assets/img/video_placeholder.svg';

  return (
    <SearchResultButton onClick={onClick}>
      <div className="tw-rounded-lg tw-w-16 tw-aspect-video tw-overflow-hidden tw-flex-shrink-0">
        <img src={avatarSrc} alt={__('Reflection thumbnail')} className="tw-w-full tw-h-full tw-object-cover" />
      </div>

      <div className="tw-text-left">
        <div className="tw-text-sm tw-opacity-60">
          {__('Reflection')}
        </div>
        <div>
          {reflection.name}
        </div>
      </div>
    </SearchResultButton>
  );
};

export default SearchReflectionResult;
