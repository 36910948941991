export const colors: string[] = [
  '#0074cc',
  '#5bb75b',
  '#faa732',
  '#49afcd',
  '#da4f49',
];

export const darkColor = '#283238';

export const pickColor = (n: number): string => {
  return colors[n % colors.length];
};

const extendedColors: string[] = [
  '#0074cc',
  '#da4f49',
  '#faa732',
  '#5bb75b',
  '#9b59b6',
  '#1abc9c',
  '#34495e',
  '#f1c40f',
];

export const pickExtendedColor = (n: number): string => {
  return extendedColors[n % extendedColors.length];
};
