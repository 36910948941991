
import React, { useEffect, useMemo, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier,
  DragStartEvent,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import * as Atlas from '../../../common/types/Atlas';
import useReorderGroupPagesMutation from '../../../common/hooks/api/groups/useReorderGroupPagesMutation';

interface ListItemProps {
  isActive?: boolean;
  isDragging?: boolean;
  page: Atlas.Page;
}

const ListItem = (props: ListItemProps) => {
  const { isActive, isDragging, page } = props;

  return (
    <div
      className={`
      tw-border tw-rounded-lg tw-bg-white tw-shadow-sm
      tw-px-3 tw-py-2
      ${isDragging ? 'tw-shadow-md' : ''}
      ${isActive ? 'tw-opacity-20 tw-border-transparent' : ''}
      ${page.type === Atlas.PageType.Heading ? 'tw-mr-3' : 'tw-ml-3'}
    `}
    >
      <span className="tw-text-sm">
        {page.title}
      </span>
    </div>
  );
};

interface SortableItemProps {
  id: UniqueIdentifier;
  page: Atlas.Page;
}

const SortableItem = (props: SortableItemProps) => {
  const { id, page } = props;

  const {
    active,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isActive = id === active?.id;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="tw-px-4">
        <ListItem isActive={isActive} page={page} />
      </div>
    </div>
  );
};

interface SortablePageListProps {
  groupId: Atlas.GroupID;
  pages: Atlas.Page[];
}

const SortablePageList = (props: SortablePageListProps) => {
  const { groupId, pages } = props;

  const sortedPages = useMemo(() => (
    [...pages].sort((a, b) => a.page_no - b.page_no)
  ), [pages]);

  const [sortedIds, setSortedIds] = useState(sortedPages.map((page) => page.id));

  useEffect(() => {
    setSortedIds(sortedPages.map((page) => page.id));
  }, [sortedPages]);

  const reorderGroupPages = useReorderGroupPagesMutation({ groupId });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;

    setActiveId(active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = sortedIds.findIndex((pageId) => pageId === active.id);
      const newIndex = sortedIds.findIndex((pageId) => pageId === over.id);
      const pageIds = arrayMove(sortedIds, oldIndex, newIndex);

      setSortedIds(pageIds);

      reorderGroupPages.mutateAsync({
        pageIds,
      }).catch(() => {
        setSortedIds(sortedPages.map((page) => page.id));
      });
    }

    setActiveId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={sortedIds}
        strategy={verticalListSortingStrategy}
      >
        {sortedIds.map((pageId) => {
          const page = sortedPages.find(({ id }) => id === pageId);
          if (!page) { return null; }

          return (
            <SortableItem
              key={page.id}
              id={page.id}
              page={page}
            />
          );
        })}
      </SortableContext>

      <DragOverlay>
        {activeId ? (
          (() => {
            const activePage = pages.find(({ id }) => id === activeId);
            if (!activePage) { return null; }

            return (
              <ListItem isDragging page={activePage} />
            );
          })()
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default SortablePageList;
