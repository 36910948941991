import { useQuery, UseQueryResult } from 'react-query';
import { findReflectionShares, FindReflectionSharesParams, FindReflectionSharesResponse } from '../../../api/reflections/shares';

const useFindReflectionSharesQuery = ({
  reflectionId,
  ...params
}: FindReflectionSharesParams): UseQueryResult<FindReflectionSharesResponse> => (
  useQuery(['reflections', reflectionId, 'shares'], () => findReflectionShares({ params: { reflectionId, ...params } }))
);

export default useFindReflectionSharesQuery;
