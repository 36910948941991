import React, { useState } from 'react';
import * as Atlas from '../../../../common/types/Atlas';

interface EachShareProps {
  share: Atlas.ExpandedShare;
  onDestroy?: () => Promise<void>;
}

const userThumbPlaceholder = '/assets/img/reflections/display-picture-blue.png';
const groupThumbPlaceholder = '/assets/img/reflections/display-picture-group-blue.png';

const EachShare = (props: EachShareProps): JSX.Element | null => {
  const { share, onDestroy } = props;

  const [destroying, setDestroying] = useState(false);

  const handleUnshareClick = onDestroy ? (() => {
    if (destroying) { return; }
    if (!window.confirm(__('Are you sure you want to remove this share?'))) { return; }

    setDestroying(true);
    onDestroy().catch(() => {
      setDestroying(false);
    });
  }) : undefined;

  const {
    thumb,
    type,
    name,
    parentName,
  } = (() => {
    if (share.sharee_user) {
      return {
        thumb: share.sharee_user.avatar.viewable_s3uri?.url ?? userThumbPlaceholder,
        name: `${share.sharee_user.first_name} ${share.sharee_user.last_name}`,
        type: __('User'),
        parentName: share.sharee_user.organization_name,
      };
    }

    if (share.sharee_group) {
      return {
        thumb: share.sharee_group.logo?.viewable_s3uri?.url ?? groupThumbPlaceholder,
        name: `${share.sharee_group.name} ${share.sharee_group.users_count ?? ''}`,
        type: share.sharee_group.is_pathway ? __('Pathway') : __('Group'),
        parentName: undefined,
      };
    }

    if (share.sharee_assignment) {
      return {
        thumb: groupThumbPlaceholder,
        name: share.sharee_assignment.name || '',
        type: __('Assignment'),
        parentName: share.sharee_assignment.group_name,
      }
    }

    return {
      thumb: userThumbPlaceholder,
      name: __('Unknown'),
      type: undefined,
      parentName: undefined,
    };
  })();

  return (
    <div
      data-testid="testEachShare"
      className={`tw-grid tw-grid-cols-[auto_1.2fr_0.8fr_1fr_auto] tw-gap-2 tw-items-center ${destroying ? 'tw-opacity-50' : ''}`}
    >
      <div className="tw-avatar">
        <div className="tw-w-10 tw-rounded-full">
          <img alt={name} src={thumb} />
        </div>
      </div>

      <div className="tw-truncate">
        {name}
      </div>

      <div className="tw-truncate">
        {type}
      </div>

      <div className="tw-truncate">
        {parentName}
      </div>

      <div
        className={type === 'Assignment' ? 'tw-tooltip tw-tooltip-top tw-tooltip-secondary' : ''}
        {...(type === 'Assignment' ? { 'data-tip': __('You will need to delete the assignment submission to unshare this reflection.') } : {})}
      >
        {handleUnshareClick ? (
          <button
            type="button"
            className="tw-btn tw-btn-xs tw-btn-block"
            onClick={handleUnshareClick}
            disabled={type === 'Assignment'}
          >
            {__('Unshare')}
          </button>
       
        ) : null}
      </div>
    </div>
  );
};

export default EachShare;
