import { useQuery, UseQueryResult } from 'react-query';
import { findDiscussion, FindDiscussionParams, FindDiscussionResponse } from '../../../api/discussions/[discussionId]';

const useFindDiscussionQuery = (
  params: FindDiscussionParams,
  options?: { enabled?: boolean; },
): UseQueryResult<FindDiscussionResponse> => (
  useQuery(
    ['discussions', params.discussionId], 
    () => findDiscussion({ params }),
    { ...options },
  )
);

export default useFindDiscussionQuery;
