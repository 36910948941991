import React, { useState } from 'react';
import { Modal, ModalBox } from '../../../../../common/components/TailwindModal';
import MediaCaptureForm from '../../../../screen-capture/MediaCaptureForm';
import { prepareStreamsForVideo } from '../utils';
import FileDropzone from '../../../../FileDropzone';
import isSafari from '../../../../../common/utils/is-safari';

export enum MediaAttachmentOption {
  Video,
  ScreenCapture,
  VideoAndScreenCapture,
  Audio,
  Upload,
}

interface MediaCaptureFormModalProps {
  initialOption?: MediaAttachmentOption;
  disabled?: boolean;
  onClose: () => void;
  onStart: (streams: {
    leftStream: MediaStream;
    rightStream: MediaStream | null;
  }) => void;
  onFileSelect: (file: File) => void;
}

const MediaCaptureFormModal: React.FC<MediaCaptureFormModalProps> = (props) => {
  const { initialOption = MediaAttachmentOption.Video, disabled, onClose, onStart, onFileSelect } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [option, setOption] = useState<MediaAttachmentOption>(initialOption);

  const opts = [{
    icon: 'fa fa-fw fa-lg fa-video-camera',
    label: __('Video'),
    value: MediaAttachmentOption.Video,
  }, {
    icon: 'fa fa-fw fa-lg fa-microphone',
    label: __('Audio'),
    value: MediaAttachmentOption.Audio,
  }, {
    icon: 'fa fa-fw fa-lg fa-video-camera',
    label: __('Video and screen capture'),
    value: MediaAttachmentOption.VideoAndScreenCapture,
  }, {
    icon: 'fa fa-fw fa-lg fa-desktop',
    label: __('Screen capture'),
    value: MediaAttachmentOption.ScreenCapture,
  }, {
    icon: 'fa fa-fw fa-lg fa-cloud-upload',
    label: __('Video upload'),
    value: MediaAttachmentOption.Upload,
  }];

  const selected = opts.find((opt) => opt.value === option) || opts[0];

  return (
    <Modal isOpen onClose={onClose}>
      <ModalBox>
        <div className="tw-flex tw-items-center tw-gap-4 tw-mb-4">
          <div className={`tw-dropdown ${dropdownOpen ? 'tw-dropdown-open' : ''} tw-flex-1`}>
            <button type="button" disabled={disabled} className="tw-select tw-select-bordered tw-items-center tw-gap-2 tw-w-full" onClick={() => setDropdownOpen((o) => !o)}>
              <i className={selected.icon} />
              {selected.label}
            </button>

            <div className="tw-dropdown-content tw-iris-dropdown !tw-w-full">
              <div className="tw-iris-dropdown-box !tw-w-full">
                <ul>
                  {opts.map((opt) => (
                    <li key={opt.value}>
                      <button
                        type="button"
                        disabled={disabled}
                        onClick={() => {
                          setOption(opt.value);
                          setDropdownOpen(false);
                        }}
                      >
                        <i className={`${opt.icon} tw-mr-2`} />
                        {opt.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="tw-btn tw-btn-ghost tw-btn-sm tw-btn-square tw-text-lg"
            disabled={disabled}
            onClick={() => onClose()}
          >
            &#x2715;
          </button>
        </div>

        {isSafari() ? (
          <div className="tw-alert tw-alert-error tw-mb-4">
            {__('Your browser is not be compatible. Please use Edge or Chrome.')}
          </div>
        ) : null}

        {(() => {
          switch (option) {
            case MediaAttachmentOption.Video: {
              return (
                <MediaCaptureForm audio video>
                  {({ userStream, displayStream }) => {
                    const handleStartClick = () => {
                      const { leftStream, rightStream } = prepareStreamsForVideo({ userStream, displayStream });
                      if (!leftStream) { return; }
                      onStart({ leftStream, rightStream });
                    };

                    return (
                      <>
                        <hr className="tw-my-4" />

                        <div className="tw-flex tw-justify-end">
                          <button type="button" disabled={disabled} className="tw-btn tw-btn-success" onClick={handleStartClick}>
                            {__('Start recording')}
                          </button>
                        </div>
                      </>
                    );
                  }}
                </MediaCaptureForm>
              );
            }

            case MediaAttachmentOption.ScreenCapture: {
              return (
                <MediaCaptureForm audio desktop>
                  {({ userStream, displayStream }) => {
                    const handleStartClick = () => {
                      const { leftStream, rightStream } = prepareStreamsForVideo({ userStream, displayStream });
                      if (!leftStream) { return; }
                      onStart({ leftStream, rightStream });
                    };

                    return (
                      <>
                        <hr className="tw-my-4" />

                        <div className="tw-flex tw-justify-end">
                          <button type="button" disabled={disabled} className="tw-btn tw-btn-success" onClick={handleStartClick}>
                            {__('Start recording')}
                          </button>
                        </div>
                      </>
                    );
                  }}
                </MediaCaptureForm>
              );
            }

            case MediaAttachmentOption.VideoAndScreenCapture: {
              return (
                <MediaCaptureForm audio video desktop>
                  {({ userStream, displayStream }) => {
                    const handleStartClick = () => {
                      const { leftStream, rightStream } = prepareStreamsForVideo({ userStream, displayStream });
                      if (!leftStream) { return; }
                      onStart({ leftStream, rightStream });
                    };

                    return (
                      <>
                        <hr className="tw-my-4" />

                        <div className="tw-flex tw-justify-end">
                          <button type="button" disabled={disabled} className="tw-btn tw-btn-success" onClick={handleStartClick}>
                            {__('Start recording')}
                          </button>
                        </div>
                      </>
                    );
                  }}
                </MediaCaptureForm>
              );
            }

            case MediaAttachmentOption.Audio: {
              return (
                <MediaCaptureForm audio>
                  {({ userStream, displayStream }) => {
                    const handleStartClick = () => {
                      const { leftStream, rightStream } = prepareStreamsForVideo({ userStream, displayStream });
                      if (!leftStream) { return; }
                      onStart({ leftStream, rightStream });
                    };

                    return (
                      <>
                        <hr className="tw-my-4" />

                        <div className="tw-flex tw-justify-end">
                          <button type="button" disabled={disabled} className="tw-btn tw-btn-success" onClick={handleStartClick}>
                            {__('Start recording')}
                          </button>
                        </div>
                      </>
                    );
                  }}
                </MediaCaptureForm>
              );
            }

            case MediaAttachmentOption.Upload: {
              return (
                <FileDropzone accept={{ 'audio/*': [], 'video/*': [] }} disabled={disabled} onDrop={(file) => onFileSelect(file)}>
                  {(({ isDragActive, open }) => {
                    if (isDragActive) {
                      return (
                        <div className={`tw-border-4 tw-border-dashed tw-rounded-box tw-h-40 tw-grid tw-place-content-center ${isDragActive ? 'tw-border-primary' : ''}`}>
                          <div className="tw-font-semibold">
                            {__('Drop here to attach')}
                          </div>
                        </div>
                      );
                    }

                    return (
                      <div className="tw-border-4 tw-border-dashed tw-rounded-box tw-h-40 tw-grid tw-place-content-center">
                        <div className="tw-flex tw-items-center tw-gap-4">
                          <button type="button" disabled={disabled} className="tw-btn tw-btn-primary" onClick={() => open()}>
                            {__('Select file')}
                          </button>
                          <span className="tw-font-semibold">
                            {__('or drag and drop the file')}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </FileDropzone>
              );
            }

            default: return null;
          }
        })()}
      </ModalBox>
    </Modal>
  );
};

export default MediaCaptureFormModal;
