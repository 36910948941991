import moment from 'moment';
import React from 'react';
import FormAnswer from '../../reflections/FormAnswer';
import * as Atlas from '../../../common/types/Atlas';
import { formElementIcon } from '../../form-builder/form-element-utils';
import { portraitPlaceholderUrl } from '../../../common/utils/placeholders';
import Spinner from '../../../common/components/Spinner';

interface ExpandedAnswerProps {
  formElement?: Atlas.FormElement;
  expandedAnswer: Atlas.ExpandedAnswer;
  hideQuestion?: boolean;
}

const ExpandedAnswer = (props: ExpandedAnswerProps): JSX.Element => {
  const { formElement, expandedAnswer, hideQuestion } = props;
  const { reflection } = expandedAnswer;

  const renderIcon = (url?: string) => (
    <div className="tw-avatar">
      <div className="tw-w-7 tw-rounded-full">
        <img src={url || 'https://s3-eu-west-1.amazonaws.com/atlas-ruby/portrait_placeholder.png'} />
      </div>
    </div>
  );

  const icon = formElement && formElementIcon(formElement);

  return (
    <div className="tw-grid tw-grid-cols-12 tw-border-b tw-pb-3 tw-mb-4">
      <div className="tw-col-span-12 lg:tw-col-span-8 tw-mb-3">
        {formElement ? (
          <div className="tw-px-3">
            {hideQuestion ? null : (
              <h5 className="tw-mt-0 tw-mb-3">
                {icon ? (
                  <i className={`${icon} tw-mr-3`} />
                ) : null}
                {formElement.field.label}
              </h5>
            )}
            <FormAnswer
              user={expandedAnswer.respondent}
              formElement={formElement}
              formAnswer={expandedAnswer.answer}
              aiGenerated={!!expandedAnswer.ai_model_id}
            />
          </div>
        ) : (
          <div className="tw-h-full tw-flex tw-items-center tw-justify-center">
            <Spinner size="sm" color="info" />
          </div>
        )}
      </div>

      <div className="tw-col-span-12 lg:tw-col-span-4 tw-mb-3">
        <div className="tw-rounded-xl tw-shadow tw-bg-base-100 tw-p-2">
          <table className="tw-mb-0">
            <tbody>
              <tr>
                <td className="tw-align-middle tw-text-center tw-p-1">
                  <img src={reflection.thumbnail_s3uri?.url} className="tw-w-7 tw-rounded" />
                </td>
                <td className="tw-align-middle tw-px-1">
                  {reflection.name}
                </td>
              </tr>

              <tr>
                <td className="tw-align-middle tw-text-center tw-p-1">
                  {renderIcon(reflection.user?.avatar_s3uri?.url)}
                </td>
                <td className="tw-align-middle tw-px-1">
                  {reflection.user?.first_name}
                  {' '}
                  {reflection.user?.last_name}
                </td>
              </tr>

              <tr>
                <td className="tw-align-middle tw-text-center tw-p-1">
                  <i className="fa fa-calendar fa-lg" />
                </td>
                <td className="tw-align-middle tw-px-1">
                  {moment(expandedAnswer.updated_at).format('LLL')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExpandedAnswer;
