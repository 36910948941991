import React, { useEffect, useState } from 'react';
import copy from 'clipboard-copy';
import * as Atlas from '../../../../common/types/Atlas';

interface ReflectionEmbedCodeProps {
  reflectionId: Atlas.ReflectionID;
}

enum EmbedType {
  Single = 'single',
  Dual = 'dual',
}

const ReflectionEmbedCode = (props: ReflectionEmbedCodeProps) => {
  const { reflectionId } = props;

  const [state, setState] = useState({
    type: EmbedType.Single,
    width: '560',
    copied: false,
  });

  useEffect(() => {
    if (!state.copied) { return undefined; }

    const timeout = setTimeout(() => {
      setState((s) => ({
        ...s,
        copied: false,
      }));
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [state.copied]);

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setState((s) => ({ ...s, width: value }));
  };

  const handleEmbedTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case EmbedType.Single:
      case EmbedType.Dual: break;
      default: return;
    }

    setState((s) => ({ ...s, type: value }));
  };

  const width = state.width ? Number(state.width) : 0;

  const height = (() => {
    const aspectRatio = (() => {
      switch (state.type) {
        case EmbedType.Dual: return 9 / 32;
        default: return 9 / 16;
      }
    })();

    const controlPadding = 35;
    const roundedHeight = Math.round((width * aspectRatio) * 10) / 10;

    return roundedHeight + controlPadding;
  })();

  const src = `${window.location.protocol}//${window.location.host}/reflections/${reflectionId}/embed`;
  const embedCode = `<iframe width="${width}" height="${height}" frameborder="0" src="${src}"></iframe>`;

  const handleCopyClick = () => {
    void copy(embedCode).then(() => {
      setState((s) => ({ ...s, copied: true }));
    });
  };

  return (
    <section>
      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Width')}
      </p>

      <div className="tw-form-control tw-mb-2">
        <label className="!tw-join">
          <input
            type="number"
            className="!tw-input !tw-input-bordered tw-shadow-none !tw-outline-none !tw-m-0 !tw-join-item"
            min={0}
            value={state.width}
            onChange={handleWidthChange}
          />

          <span className="!tw-bg-base-200 !tw-join-item">
            {__('pixels')}
          </span>
        </label>
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Embed Code')}
      </p>

      <div className="tw-form-control tw-mb-4">
        <label className="tw-flex tw-items-center tw-gap-3 tw-mb-1">
          <input type="radio" name="radio-10" className="!tw-radio !tw-radio-info !tw-outline-none !tw-m-0" value={EmbedType.Single} checked={state.type === EmbedType.Single} onChange={handleEmbedTypeChange} />
          <span className="tw-label-text tw-text-base">
            {__('Optimise for single video')}
          </span>
        </label>

        <label className="tw-flex tw-items-center tw-gap-3 tw-mb-0">
          <input type="radio" name="radio-10" className="!tw-radio !tw-radio-info !tw-outline-none !tw-m-0" value={EmbedType.Dual} checked={state.type === EmbedType.Dual} onChange={handleEmbedTypeChange} />
          <span className="tw-label-text tw-text-base">
            {__('Optimise for dual video')}
          </span>
        </label>
      </div>

      <div>
        <code className="tw-block tw-whitespace-pre-line tw-overflow-auto tw-mb-2">
          {embedCode}
        </code>

        <div className="tw-flex tw-gap-3">
          <button type="button" className="tw-btn tw-btn-sm" onClick={handleCopyClick}>
            <i className="fa fa-copy" />
            &nbsp;
            {state.copied ? (
              __('Copied!')
            ) : (
              __('Copy')
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ReflectionEmbedCode;
