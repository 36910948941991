import { useQuery, UseQueryResult } from 'react-query';
import { findGroupAttachments, FindGroupAttachmentsParams, FindGroupAttachmentsResponse } from '../../../api/groups/attachments';

const useFindGroupAttachmentsQuery = (
  params: FindGroupAttachmentsParams,
  options?: { enabled?: boolean; },
): UseQueryResult<FindGroupAttachmentsResponse> => (
  useQuery(
    ['groups', params.groupId, 'attachments', ], 
    () => findGroupAttachments({ params }),
    { ...options },
  )
);

export default useFindGroupAttachmentsQuery;
