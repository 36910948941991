import * as Atlas from "../types/Atlas";

type FormJson = Atlas.Form & {
  created_at: string;
  updated_at: string;
}

export const parseFormJson = (json: FormJson): Atlas.Form => {
  return {
    ...json,
    created_at: new Date(json.created_at),
    updated_at: new Date(json.updated_at),
  };
};
