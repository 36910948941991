import styled from '@emotion/styled'

export const DiscussionToolbarOptionsButton = styled.a`
  color: rgba(42, 55, 143, .8);

  &:hover {
    color: rgba(42, 55, 143, 1);
  }
`;

export const DiscussionToolbarButton = styled.a`
  color: #343a40;

  &:hover {
    color: #ffc500;
  }
`;
