export const VideoChannel = {
  Left: 1,
  Right: 2,
};

export const ScreenCaptureMediaSource = {
  Webcam: 1,
  Microphone: 2,
};

// An enumeration of the possible recordings we allow for a screen capture.
export const RecordingType = {
  // Desktop-only recording with the microphone as the only audio source.
  Desktop: 1,
  // Desktop-only recording using the microphone *and* the system audio as audio sources.
  DesktopWithSystemAudio: 2,
  // Webcam-only recording using the microphone as the only audio source.
  Webcam: 3,
  // A dual-view media.
  // Video sources: [Desktop, Webcam]
  // Audio sources: [Microphone] (carried by the webcam video stream).
  DesktopAndWebcam: 4,
  // The most complex type, and also a dual-view media.
  // Video sources: [Desktop, Webcam]
  // Audio sources: [Microphone, SystemAudio] (each one carried by the associated video stream).
  DesktopAndWebcamWithSystemAudio: 5,
};

// Derives the number of required 'VideoChannel's out of the input 'RecordingType'.
export function requiredChannels(recordingType) {
  switch (recordingType) {
    case RecordingType.Desktop: return 1;
    case RecordingType.DesktopWithSystemAudio: return 1;
    case RecordingType.Webcam: return 1;
    case RecordingType.DesktopAndWebcam: return 2;
    case RecordingType.DesktopAndWebcamWithSystemAudio: return 2;
  }
}

export function isSystemAudioBeingRecorded(recordingType) {
  switch (recordingType) {
    case RecordingType.Desktop: return false;
    case RecordingType.DesktopWithSystemAudio: return true;
    case RecordingType.Webcam: return false;
    case RecordingType.DesktopAndWebcam: return false;
    case RecordingType.DesktopAndWebcamWithSystemAudio: return true;
  }
}

export function isDesktopBeingRecorded(recordingType) {
  switch (recordingType) {
    case RecordingType.Desktop: return true;
    case RecordingType.DesktopWithSystemAudio: return true;
    case RecordingType.Webcam: return false;
    case RecordingType.DesktopAndWebcam: return true;
    case RecordingType.DesktopAndWebcamWithSystemAudio: return true;
  }
}

export function isWebcamBeingRecorded(recordingType) {
  switch (recordingType) {
    case RecordingType.Desktop: return false;
    case RecordingType.DesktopWithSystemAudio: return false;
    case RecordingType.Webcam: return true;
    case RecordingType.DesktopAndWebcam: return true;
    case RecordingType.DesktopAndWebcamWithSystemAudio: return true;
  }
}

// A 'RecordingContext' gathers under a common umbrella the trifecta <videoChannel,recorder,stream>
// which are all essential components of a successful screen capture.
export function RecordingContext(videoChannel, mediaRecorder, mediaStream) {
  this.mediaRecorder = mediaRecorder;
  this.mediaStream = mediaStream;
  this.videoChannel = videoChannel;
}

export function RecordManager(ctx, uploader) {
  this.mediaRecorder = ctx.mediaRecorder;
  this.mediaStream = ctx.mediaStream;
  this.videoChannel = ctx.videoChannel;
  this.uploader = uploader;
  // True if the relevant media has been successfully uploaded on S3.
  this.videoPartUploaded = false;

  this.stop = function () {
    // Stop any potentially-started tracks.
    this.mediaStream.getTracks().forEach((track) => {
      if (track != undefined && track != null) track.stop();
    });
  };
}
