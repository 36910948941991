import React, { useEffect } from 'react';
import classNames from 'classnames';
import useFindAiModelsQuery from '../../../common/hooks/api/ai-models/useFindAiModelsQuery';

const AiModelSelect: React.FC<{
  autoSelect?: boolean;
  className?: string;
  name?: string
  value?: string | number | null;
  defaultOnly?: boolean;
  onChange: (aiModel: number | undefined) => void;
}> = (props) => {
  const { autoSelect, className, name, value, onChange, defaultOnly } = props;

  const aiModelsQuery = useFindAiModelsQuery();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    if (value) {
      onChange(Number(value));
    } else {
      onChange(undefined);
    }
  };

  const aiModels = aiModelsQuery.data?.data ?? [];

  useEffect(() => {
    if (!autoSelect) { return; }

    const [aiModel] = aiModels;
    if (!aiModel) { return; }

    onChange(aiModel.id);
  }, [aiModels, autoSelect]);

  if (aiModelsQuery.isLoading) {
    return (
      <select
        className={classNames(className, 'tw-italic')}
        disabled
      >
        <option selected>{__('Loading...')}</option>
      </select>
    );
  }

  if (defaultOnly) {
    const [defaultAiModel] = aiModels;

    return (
      <select
        className={className}
        name={name}
        value={value ?? ''}
        onChange={handleChange}
      >
        <option value="">{__('Disabled')}</option>
        {defaultAiModel ? (
          <option value={defaultAiModel.id}>
            {__('Enabled')}
          </option>
        ) : null}
      </select>
    );
  }

  return (
    <select
      className={className}
      name={name}
      value={value ?? ''}
      onChange={handleChange}
    >
      <option value="">{__('Disabled')}</option>
      {aiModels.map((aiModel) => (
        <option key={aiModel.id} value={aiModel.id}>
          {aiModel.name}
        </option>
      ))}
    </select>
  );
};

export default AiModelSelect;
