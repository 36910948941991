import * as Atlas from "../types/Atlas";

export const submissionHasAnswers = (answers: Atlas.FormAnswer[]): boolean => {
  return answers.some(answer => {
    const { value, attachment_value, reflection_value } = answer;

    if (typeof value === 'string') {
      return value.trim() !== '';
    }

    if (Array.isArray(value)) {
      return value.length > 0 && value.some(item => item.trim?.() !== '');
    }

    if (typeof value === 'number') {
      return !isNaN(value);
    }

    if (attachment_value !== null && attachment_value !== undefined) {
      return true;
    }

    if (reflection_value !== null && reflection_value !== undefined) {
      return true;
    }

    return !!value;
  });
};