import { useMutation } from 'react-query';
import { destroyAttachment, DestroyAttachmentArguments, DestroyAttachmentResponse } from '../../../api/attachments/[attachmentId]';
import { FindReflectionAttachmentsResponse } from '../../../api/reflections/attachments';
import { queryClient } from '../../withQueryClient';

const useDestroyAttachmentMutation = () => {
  const mutation = useMutation<DestroyAttachmentResponse, unknown, DestroyAttachmentArguments, unknown>(
    destroyAttachment,
    {
      onSuccess: async (result) => {
        const attachment = result.data;

        if (attachment.reflection_id) {
          await queryClient.invalidateQueries<FindReflectionAttachmentsResponse>(['reflections', attachment.reflection_id, 'attachments']);
        }
      },
    },
  );

  return mutation;
};

export default useDestroyAttachmentMutation;
