import React from "react";
import { OptionProps, components, SingleValueProps } from "react-select";
import * as Atlas from "../../types/Atlas";
import { portraitPlaceholderUrl } from '../../utils/placeholders';

export interface FormUserOption {
  value: number;
  formUser: Atlas.FormUser;
}

export const FormUserSelectOption = (props: OptionProps<FormUserOption, false>): JSX.Element => {
  const { formUser } = props.data as FormUserOption;

  const url = formUser.avatar_s3uri?.url ?? portraitPlaceholderUrl;

  const userFullname = [formUser.first_name, formUser.last_name].filter(Boolean).join(' ');

  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <img src={url} className="mr-2 p-0 thumbnail rounded-circle" style={{ height: '1.6rem', width: '1.6rem' }} />
        <span>{userFullname}</span>
      </div>
    </components.Option>
  );
};

export const FormUserSelectSingleValue = (props: SingleValueProps<FormUserOption>): JSX.Element => {
  const { formUser } = props.data;

  const url = formUser.avatar_s3uri?.url ?? portraitPlaceholderUrl;

  const userFullname = [formUser.first_name, formUser.last_name].filter(Boolean).join(' ');

  return (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center">
        <img src={url} className="mr-2 p-0 thumbnail rounded-circle" style={{ height: '1.6rem', width: '1.6rem' }} />
        <span>{userFullname}</span>
      </div>
    </components.SingleValue>
  );
};

const selectComponents = {
  Option: FormUserSelectOption,
  SingleValue: FormUserSelectSingleValue
};

export { selectComponents };
