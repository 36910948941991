import { isEqual } from 'lodash-es';
import React, { useState } from 'react';
import useFindReflectionThumbnailsQuery from '../../../../common/hooks/api/reflections/useFindReflectionThumbnailsQuery';
import useUpdateReflectionMutation from '../../../../common/hooks/api/reflections/useUpdateReflectionMutation';
import * as Atlas from '../../../../common/types/Atlas';
import Spinner from '../../../../common/components/Spinner';

interface ReflectionThumbnailPickerProps {
  reflectionId: Atlas.ReflectionID;
  onCancel: () => void;
  onUpdated: () => void;
}

const ReflectionThumbnailPicker = (props: ReflectionThumbnailPickerProps) => {
  const { reflectionId, onCancel, onUpdated } = props;

  const thumbnailsQuery = useFindReflectionThumbnailsQuery({ reflectionId, per_page: -1 });
  const updateReflection = useUpdateReflectionMutation();

  const [selected, setSelected] = useState<Atlas.ThumbnailIdentity | null>(null);
  const isSelected = (identity: Atlas.ThumbnailIdentity) => isEqual(identity, selected);

  const handleCancelClick = () => {
    onCancel();
  };

  const handleUpdateClick = () => {
    if (!selected) { return; }

    void updateReflection.mutateAsync({
      params: { reflectionId },
      body: {
        thumbnail: selected,
      },
    }).then(() => {
      onUpdated();
    });
  };

  return (
    <div>
      {(() => {
        switch (thumbnailsQuery.status) {
          case 'loading': return (
            <div className="tw-text-center tw-py-4">
              <Spinner color="info" />
            </div>
          );

          case 'success': {
            const thumbnails = thumbnailsQuery.data.data;

            if (thumbnails.length === 0) {
              return (
                <div className="tw-text-center tw-py-3 tw-text-lg tw-text-base-300">
                  {__('No thumbnails available.')}
                </div>
              );
            }

            return (
              <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                {thumbnails.map((thumbnail) => {
                  const key = [
                    thumbnail.thumbnail.video_id,
                    thumbnail.thumbnail.channel_type,
                    thumbnail.thumbnail.thumbnail_index,
                    thumbnail.thumbnail.filter,
                  ].join('-');

                  const handleClick = () => {
                    setSelected(thumbnail.thumbnail);
                  };

                  return (
                    <button
                      key={key}
                      type="button"
                      className={`tw-group tw-rounded tw-overflow-hidden tw-bg-black ${isSelected(thumbnail.thumbnail) ? 'tw-outline tw-outline-4 tw-outline-offset-2 tw-outline-info' : ''}`}
                      onClick={handleClick}
                    >
                      <img
                        alt={__('Reflection thumbnail')}
                        className="tw-transition-opacity group-hover:tw-opacity-80"
                        src={thumbnail.s3uri.url}
                      />
                    </button>
                  );
                })}
              </div>
            );
          }

          default: return (
            <div className="tw-text-center tw-py-4">
              {__('Oops! Something went wrong.')}
            </div>
          );
        }
      })()}

      <div className="tw-flex tw-justify-end tw-gap-2 tw-mt-4">
        <button
          type="button"
          className="tw-btn tw-btn-link"
          onClick={handleCancelClick}
        >
          {__('Cancel')}
        </button>

        <button
          type="button"
          disabled={!selected}
          className="tw-btn tw-btn-success"
          onClick={handleUpdateClick}
        >
          {updateReflection.isLoading ? <span className="tw-loading" /> : null}
          {__('Update')}
        </button>
      </div>
    </div>
  );
};

export default ReflectionThumbnailPicker;
