import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';

export type CommentFieldValues = {
  body: string;
  synchronicity: Atlas.CommentSynchronicity;
};

const CommentFormFields: React.FC<{
  values: CommentFieldValues;
  onChange: (values: CommentFieldValues) => void;
}> = (props) => {
  const { values, onChange } = props;

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.currentTarget;

    onChange({
      ...values,
      [name]: value,
    });
  };

  const handleSynchronicityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.CommentSynchronicity.Pause:
      case Atlas.CommentSynchronicity.Simultaneous: {
        onChange({
          ...values,
          synchronicity: value,
        });
        break;
      }
    }
  };

  const commentBody = values.body;
  const commentSync = values.synchronicity;

  return (
    <>
      <div className="tw-form-control">
        <textarea
          className="tw-textarea tw-textarea-bordered tw-w-full tw-text-base"
          placeholder={__('Type your comment')}
          name="body"
          value={commentBody}
          onChange={handleCommentChange}
        />
      </div>

      <div className="tw-form-control tw-mb-4 tw-space-y-2 tw-mt-4">
        <label className="tw-flex tw-gap-3 tw-mb-0">
          <input
            type="radio"
            className="!tw-radio !tw-radio-info !tw-outline-none !tw-m-0"
            name="synchronicity"
            value={Atlas.CommentSynchronicity.Pause}
            checked={commentSync === Atlas.CommentSynchronicity.Pause}
            onChange={handleSynchronicityChange}
          />
          <div>
            <div className="tw-font-semibold tw-text-base">
              {__('Pause')}
            </div>
            <div>
              {__('Pause the reflection video when this media comment is played.')}
            </div>
          </div>
        </label>

        <label className="tw-flex tw-gap-3 tw-mb-0">
          <input
            type="radio"
            className="!tw-radio !tw-radio-info !tw-outline-none !tw-m-0"
            name="synchronicity"
            value={Atlas.CommentSynchronicity.Simultaneous}
            checked={commentSync === Atlas.CommentSynchronicity.Simultaneous}
            onChange={handleSynchronicityChange}
          />
          <div>
            <div className="tw-font-semibold tw-text-base">
              {__('Simultaneous')}
            </div>
            <div>
              {__('Play media comment simultaneously with the reflection video.')}
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

export default CommentFormFields;
