import { useEffect } from 'react';
import { useDebounce } from 'react-use';
import { formOverview } from '../../common/api/forms';
import * as Atlas from '../../common/types/Atlas';
import { FormReportingFilter } from './types';

interface DataFetcherProps {
  formId: Atlas.FormID;
  filter: FormReportingFilter;
  onLoading(filter: FormReportingFilter): void;
  onLoaded(filter: FormReportingFilter, data: Atlas.FormFieldOverview[]): void;
  onUnmount(filterId: string): void;
}

const DataFetcher = (props: DataFetcherProps): null => {
  const { formId, filter } = props;

  const respondentUserId = filter.respondentUserId || undefined;
  const reflectionId = filter.reflectionId || undefined;
  const reflectionUserId = filter.reflectionUserId || undefined;

  useDebounce(async () => {
    props.onLoading(filter);
    await formOverview({
      params: {
        formId, respondentUserId, reflectionId, reflectionUserId, perPage: 50,
      },
    }).then(({ data }) => {
      props.onLoaded(filter, data);
    });
  }, 1000, [formId, respondentUserId, reflectionId, reflectionUserId]);

  useEffect(() => () => {
    props.onUnmount(filter.id);
  }, [filter.id]);

  return null;
};

export default DataFetcher;
