import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormsList from './FormsList';
import { formsRoute, searchParams, generateFetchHeaders } from '../../../common/api/routes';

const SharedFormsList = (props) => {
  const [state, setState] = useState({
    forms: [],
    formsLoading: true,
    page: 1,
    perPage: 10,
  });

  useEffect(() => {
    setState((s) => ({ ...s, formsLoading: true }));

    const myFormsUrl = [
      formsRoute(), searchParams({
        filter: 'shared',
        page: state.page,
        per_page: state.perPage,
      }),
    ].join('?');

    fetch(myFormsUrl, {
      credentials: 'same-origin',
      headers: generateFetchHeaders(),
    }).then((r) => r.json()).then((forms) => {
      setState((s) => ({ ...s, forms }));
    }).finally(() => {
      setState((s) => ({ ...s, formsLoading: false }));
    });
  }, [state.page, state.perPage]);

  const handleBackClick = !state.formsLoading && state.page !== 1 ? (() => {
    setState((s) => ({ ...s, page: Math.max(s.page - 1, 1) }));
  }) : undefined;

  const handleNextClick = !state.formsLoading && state.forms.length === state.perPage ? (() => {
    setState((s) => ({ ...s, page: s.page + 1 }));
  }) : undefined;

  return (
    <FormsList
      {...props}
      forms={state.forms}
      loading={props.loading || state.formsLoading}
      onBackClick={handleBackClick}
      onNextClick={handleNextClick}
    />
  );
};

SharedFormsList.propTypes = {
  loading: PropTypes.bool,
};

export default SharedFormsList;
