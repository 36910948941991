import React, { useState } from 'react';
import { withQueryClient } from '../../../../common/hooks/withQueryClient';
import useFindMilestonesQuery from '../hooks/api/useFindMilestonesQuery';
import MilestonesListHeader from './MilestonesListHeader';
import MilestonesList from './MilestonesList';
import useDeleteMilestone from '../hooks/useDeleteMilestone';
import Spinner from '../../../../common/components/Spinner';
import MilestonesListSortable from './MilestonesListSortable';

interface MilestonesListContainerProps {
  groupId: number;
}

const MilestonesListContainer = (props: MilestonesListContainerProps): JSX.Element => {
  const [sorting, setSorting] = useState(false);

  const toggleSorting = () => {
    setSorting((s) => !s);
  }

  const milestonesQuery = useFindMilestonesQuery(props.groupId);
  const { handleDelete, isError, error } = useDeleteMilestone(props.groupId);

  const renderMilestonesList = () => {
    switch (milestonesQuery.status) {
      case 'loading':
      case 'idle': {
        return (
          <div className="tw-flex tw-items-center tw-justify-center tw-my-5">
            <Spinner color="info" />
          </div>
        );
      }
      case 'success': {
        return milestonesQuery.data.data.length > 0 ? (
          <div className="tw-flex tw-flex-col">
            {sorting ? (
              <MilestonesListSortable
                milestones={milestonesQuery.data.data}
                groupId={props.groupId}
                onDeleteClick={handleDelete}
            /> 
            ) : (
              <MilestonesList
                milestones={milestonesQuery.data.data}
                groupId={props.groupId}
                onDeleteClick={handleDelete}
              /> 
            ) }
          </div>
        ) : (
          <div className="tw-flex tw-flex-col">
            <h1 className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
              {__('No milestones')}
            </h1>
          </div>
        );
      }
      case 'error':
      default: {
        return (
          <div className="tw-flex tw-flex-col">
            <h1 className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
              {__('There was an issue fetching milestones')}
            </h1>
          </div>
        )
      }
    }
  };

  return(
    <>
      <MilestonesListHeader
        milestonesCount={milestonesQuery.data ? milestonesQuery.data.data.length : null}
        groupId={props.groupId}
        sorting={sorting}
        toggleSorting={toggleSorting}
      />
      {isError && (
        <div className="tw-flex tw-flex-col tw-my-5">
          <div className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
            {error}
          </div>
        </div>
      )}
      <div className="tw-flex tw-flex-col">
        {renderMilestonesList()}
      </div>
    </>
  );
};

export default withQueryClient(MilestonesListContainer);
