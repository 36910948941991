import { inRange } from '../../utils/utils';
import { jsonHeaders } from '../routes';
import ApiError from '../error';

export type FeatureToggle =
  'disable_desktop_uploads' |
  'download_subtitles_toggle' |
  'reflection_embed' |
  'auto_delete' |
  'video_anonymised_deface' |
  'ai_forms' |
  'transcribe_video' |
  "advanced_forms_options";

export const findFeatureToggleRoute = (args: {
  featureToggle: FeatureToggle;
}): string => `/api/v0/feature_toggles/${args.featureToggle}`;

export interface FindFeatureToggleParams {
  featureToggle: FeatureToggle;
}

export interface FindFeatureToggleArguments {
  params: FindFeatureToggleParams;
}

export interface FindFeatureToggleResponse {
  enabled: boolean;
}

export const findFeatureToggle = async (
  args: FindFeatureToggleArguments,
): Promise<FindFeatureToggleResponse> => {
  const findFeatureToggleUrl = findFeatureToggleRoute({
    featureToggle: args.params.featureToggle,
  });

  const response = await fetch(findFeatureToggleUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findFeatureToggle');
  }

  const body = await response.json();

  return body;
};
