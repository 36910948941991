import React from 'react';
import Spinner from '../../../common/components/Spinner';
import useFindVideoPlaylistsQuery from '../../../common/hooks/api/videos/useFindVideoPlaylistsQuery';
import * as Atlas from '../../../common/types/Atlas';
import JwPlayer, {
  JwPlayerPlaylistItem,
} from '../../jwplayer/JwPlayer';
import { defaultJwplayerConfig } from '../../../common/utils/jwplayer-defaults';

const defaultConfig = {
  ...defaultJwplayerConfig,
  displaytitle: false,
  displaydescription: false,
  allowFullscreen: false,
  preload: 'metadata',
};

interface VideoEditorPlayerProps {
  videoId: Atlas.VideoID;
}

const VideoEditorPlayer = (props: VideoEditorPlayerProps) => {
  const { videoId } = props;

  const playlistsQuery = useFindVideoPlaylistsQuery(videoId);

  switch (playlistsQuery.status) {
    case 'loading':
    case 'idle': {
      return (
        <div className="tw-bg-black tw-aspect-w-16 tw-aspect-h-9">
          <div className="tw-grid tw-place-items-center">
            <Spinner color="light" />
          </div>
        </div>
      );
    }

    case 'success': {
      const playlists = playlistsQuery.data.data;

      return (
        <div className="tw-bg-black tw-aspect-w-16 tw-aspect-h-9">
          <JwPlayer
            configuration={defaultConfig}
            playlist={playlists as unknown as JwPlayerPlaylistItem[]}
          />
        </div>
      );
    }

    case 'error':
    default: {
      return (
        <div className="tw-bg-black tw-aspect-video tw-grid tw-place-items-center tw-text-white">
          {__('Oops! Something went wrong.')}
        </div>
      );
    }
  }
};

export default VideoEditorPlayer;
