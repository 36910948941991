import { useQuery, UseQueryResult } from 'react-query';
import { findReflections, FindReflectionsParams, FindReflectionsResponse } from '../../api/reflections';

const useReflectionsQuery = (
  params: FindReflectionsParams = {},
): UseQueryResult<FindReflectionsResponse> => useQuery(
  ['reflections', params],
  () => findReflections({ params }),
  { staleTime: 60000 },
);

export default useReflectionsQuery;
