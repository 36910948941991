import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import axios, { CancelTokenSource } from 'axios';

type HermesUploaderState = {
  status: 'idle';
} | {
  status: 'uploading';
  progress: number;
  cancelToken: CancelTokenSource;
} | {
  status: 'success',
} | {
  status: 'error',
};

type HermesUploader = (args: {
  mediaKey: string;
  file: File;
  uploadUrl: string;
}) => Promise<{
  status: 'success',
} | {
  status: 'error',
}>;

const useHermesUploader = (): [HermesUploaderState, HermesUploader] => {
  const [state, setState] = useState<HermesUploaderState>({
    status: 'idle',
  });

  const currentCancelToken = (() => {
    if (state.status !== 'uploading') { return null; }
    return state.cancelToken;
  })();

  useEffect(() => {
    if (!currentCancelToken) { return undefined; }
    return () => { currentCancelToken.cancel(); };
  }, [currentCancelToken]);

  const uploader = useCallback<HermesUploader>(async (args) => {
    const formData = new FormData();
    formData.append('media_key', args.mediaKey);
    formData.append('files[]', args.file);

    const cancelToken = axios.CancelToken.source();

    setState({
      status: 'uploading',
      progress: 0,
      cancelToken,
    });

    return await axios.post(args.uploadUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      cancelToken: cancelToken.token,
      onUploadProgress(progressEvent) {
        const totalLength = (() => {
          if (progressEvent.lengthComputable) { return progressEvent.total ?? 0; }
          return 0;
        })();

        const progress = Math.round((progressEvent.loaded * 100) / totalLength);

        setState({
          status: 'uploading',
          progress,
          cancelToken,
        });
      },
    }).then(() => {
      const nextState = { status: 'success' } as const;
      setState(nextState);
      return nextState;
    }, () => {
      const nextState = { status: 'error' } as const;
      setState(nextState);
      return nextState;
    });
  }, []);

  return useMemo(() => [state, uploader], [state, uploader]);
};

export default useHermesUploader;
