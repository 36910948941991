import React from 'react';
import PropTypes from 'prop-types';

const FormMultipleChoiceElement = props => {
  const { formAnswer } = props;
  const value = formAnswer.value ? (
    Array.isArray(formAnswer.value) ? formAnswer.value : [formAnswer.value]
  ) : [];

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-1">
      {value.map(choice => (
        <div key={choice} className="tw-bg-info tw-p-1 tw-px-2 tw-text-base-100 tw-rounded-lg">
          {choice}
        </div>
      ))}
    </div>
  );
};

FormMultipleChoiceElement.propTypes = {
  formElement: PropTypes.object.isRequired
};

export default FormMultipleChoiceElement;
