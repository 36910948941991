import React, { useMemo } from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import useSearchSharableQuery from '../../../../common/hooks/api/useSearchSharableQuery';
import { SearchResultButton } from '../../../search/global-search/search-result/list-item';
import { portraitPlaceholderUrl } from '../../../../common/utils/placeholders';
import { SearchSharableResult } from '../../../../common/api/search/legacy';

interface SearchResultsProps {
  searchQuery: string;
  excludeUserIds: Array<Atlas.UserID>;
  excludeGroupIds: Array<Atlas.GroupID>;
  scope?: 'User' | 'Group';
  onSelect: (result: SearchSharableResult) => void,
  reflectionId: Atlas.ReflectionID;
}

const SearchResults = (props: SearchResultsProps): JSX.Element => {
  const {
    searchQuery,
    excludeUserIds,
    excludeGroupIds,
    scope,
    onSelect,
    reflectionId,
  } = props;

  const searchSharableQuery = useSearchSharableQuery({
    query: searchQuery,
    excludeUserIds,
    excludeGroupIds,
    scope,
    reflectionId,
  });

  const handleSelectResult = (result: SearchSharableResult) => () => {
    onSelect(result);
  };

  return (
    <div className="tw-bg-base-100 tw-rounded-lg tw-shadow-lg tw-p-1 tw-border tw-border-base-200 tw-space-y-1 tw-h-full tw-overflow-y-auto">
      {(() => {
        switch (searchSharableQuery.status) {
          case 'success': {
            const results = searchSharableQuery.data.data;

            if (results.length === 0) {
              return (
                <div className="tw-p-3 tw-flex tw-text-center tw-text-accent">
                  {__('No matches found')}
                </div>
              );
            }

            return (
              <div>
                {results.map((result) => {
                  switch (result.model) {
                    case 'User': {
                      const user = result.data;
                      const avatarSrc = user.avatar_s3uri?.url || portraitPlaceholderUrl;

                      return (
                        <SearchResultButton key={`user-${user.id}`} onClick={handleSelectResult(result)}>
                          <div className="tw-avatar">
                            <div className="tw-rounded-xl tw-border tw-w-10">
                              <img src={avatarSrc} alt={__('User avatar')} />
                            </div>
                          </div>

                          <div className="tw-flex tw-flex-col tw-text-left">
                            <div className="tw-text-sm tw-opacity-60">
                              {__('User')}
                            </div>
                            <div>
                              {`${user.first_name} ${user.last_name}`}
                            </div>
                            <div className="tw-flex tw-items-center tw-gap-1">
                              <i className="fa fa-institution tw-text-xs" />
                              <span className="tw-text-sm">
                                {user.organization.name}
                              </span>
                            </div>
                          </div>
                        </SearchResultButton>
                      );
                    }

                    case 'Group': {
                      const group = result.data;
                      const avatarSrc = group.avatar_s3uri?.url || '/assets/img/reflections/display-picture-group-blue.png';

                      return (
                        <SearchResultButton key={`group-${group.id}`} onClick={handleSelectResult(result)}>
                          <div className="tw-avatar">
                            <div className="tw-rounded-xl tw-border tw-w-10">
                              <img src={avatarSrc} alt={__('Group avatar')} />
                            </div>
                          </div>

                          <div className="tw-flex tw-flex-col tw-text-left">
                            <div className="tw-text-sm tw-opacity-60">
                              {__('Group')}
                            </div>
                            <div>
                              {group.name}
                            </div>
                          </div>
                        </SearchResultButton>
                      );
                    }

                    default: return null;
                  }
                })}
              </div>
            );
          }

          case 'loading': {
            return (
              <div className="tw-px-3 tw-py-2 tw-text-center">
                <i className="fa fa-circle-o-notch fa-spin tw-text-2xl tw-text-info" />
              </div>
            );
          }

          default: {
            return (
              <div className="tw-px-3 tw-py-2 tw-text-center">
                {__('Oops! Something went wrong.')}
              </div>
            );
          }
        }
      })()}
    </div>
  );
};

export default SearchResults;
