import { useMutation, UseMutationResult } from 'react-query';
import {
  reorderMilestones,
  ReorderMilestonesBody,
  ReorderMilestonesParams,
  ReorderMilestonesResponse
} from '../../api/index';
import { queryClient } from '../../../../../common/hooks/withQueryClient';

const useReorderMilestonesMutation = (
  params: ReorderMilestonesParams,
): UseMutationResult<ReorderMilestonesResponse, unknown, ReorderMilestonesBody, unknown> => {
  const mutation = useMutation(
    (body: ReorderMilestonesBody) => reorderMilestones({ params, body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['groups', params.groupId, 'milestones']);
      },
    },
  );

  return mutation;
};

export default useReorderMilestonesMutation;
