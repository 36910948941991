import React, { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { getNavigationType } from './utils';
import { NavigationType } from './types';

type LinkProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const Link: React.FC<LinkProps> = ({
  href = '',
  className,
  children,
}) => {
  const navigationType = useMemo(() => getNavigationType(href), [href]);

  switch (navigationType) {
    case NavigationType.ReactRouter: return (
      <ReactRouterLink
        to={href}
        className={className}
      >
        {children}
      </ReactRouterLink>
    );

    case NavigationType.NativeInternal: return (
      <a
        href={href}
        target="_self"
        className={className}
      >
        {children}
      </a>
    );

    case NavigationType.NativeExternal:
    default: return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={className}
      >
        {children}
      </a>
    );
  }
};
