import React, { useRef, useState } from 'react';
import FormDetails from './builder/FormDetails';
import FormElements from './builder/FormElements';
import FormActions from './builder/FormActions';
import FormPreview from './FormPreview';
import * as Atlas from '../../common/types/Atlas';

interface FormBuilderProps {
  isNewForm?: boolean;
  form: Partial<Atlas.Form>;
  loading: boolean;
  saving: boolean;
  isFormValid: boolean;
  isChanged: boolean;
  saved: boolean;
  error?: boolean;
  onChange: (changes: DeepPartial<Atlas.Form>) => void;
  onCancel: () => void;
  onSave?: (() => Promise<void> | undefined) | null
}

const FormBuilder = (props: FormBuilderProps): JSX.Element => {
  const {
    form,
    isNewForm,
    isChanged,
    loading,
    saving,
    saved,
    error,
    onChange: handleChange,
    onCancel: handleCancel,
    onSave,
  } = props;

  const formRef = useRef<HTMLFormElement>(null);

  const [mode, setMode] = useState('edit');

  const isEditMode = mode === 'edit';
  const isPreviewMode = mode === 'preview';

  const handleEdit = isEditMode ? undefined : () => {
    setMode('edit');
  };

  const handlePreview = isPreviewMode ? undefined : () => {
    if (!formRef.current?.reportValidity()) { return; }

    setMode('preview');
  };

  const handleSave = () => {
    if (!formRef.current?.reportValidity()) { return; }

    onSave?.();
  };

  return (
    <form ref={formRef} onSubmit={(e) => { e.preventDefault(); }}>
      {(() => {
        switch (mode) {
          case 'preview': {
            return (
              <div className="tw-mx-auto tw-my-5 tw-max-w-xl">
                <div className="tw-card tw-bg-base-100 tw-mb-4">
                  <div className="tw-card-body">
                    <div className="tw-card-title tw-font-light">
                      {form.name}
                    </div>

                    {form.instructions ? (
                      <p className="tw-mt-2">
                        {form.instructions}
                      </p>
                    ) : null}
                  </div>
                </div>

                <FormPreview form={form} />

                {form.copyright ? (
                  <div className="tw-text-sm tw-text-gray-600 tw-my-4 tw-text-right">
                    <i className="fa fa-copyright" />
                    &nbsp;
                    {form.copyright}
                  </div>
                ) : null}
              </div>
            );
          }

          case 'edit':
          default: {
            return (
              <>
                <div className="tw-mb-4">
                  <FormDetails
                    form={form}
                    loading={loading}
                    onChange={handleChange}
                    isNewForm={isNewForm}
                  />
                </div>

                <div className="tw-mb-4">
                  <FormElements
                    form={form}
                    loading={loading}
                    onChange={handleChange}
                  />
                </div>
              </>
            );
          }
        }
      })()}

      <FormActions
        form={form}
        isChanged={isChanged}
        loading={loading}
        saving={saving}
        saved={saved}
        error={!!error}
        onEdit={handleEdit}
        onPreview={handlePreview}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </form>
  );
};

export default FormBuilder;
