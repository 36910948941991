import classNames from 'classnames';
import moment from 'moment';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from '../../common/components/BSDropdown';
import { interpolate } from '../../common/utils/utils';
import * as Atlas from '../../common/types/Atlas';
import { useLocation } from 'react-router-dom';
import { getAssignmentsBasePath } from '../../common/utils/getAssignmentsBasePath';
import { Badge } from '../../common/components/Badge';

interface GroupAssignmentRowProps {
  assignment: Atlas.ExpandedAssignment;
  isAssessor?: boolean;
  isSuperAdmin?: boolean;
  i: number;
  onCloneClick(assignment: Atlas.ExpandedAssignment): void;
  onDeleteClick(assignment: Atlas.ExpandedAssignment): void;
}

const GroupAssignmentRow = (props: GroupAssignmentRowProps): JSX.Element => {
  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);
  const { assignment, isAssessor, i } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const ref = useRef(null);
  useLayoutEffect(() => {
    if (!ref.current || !dropdownOpen) { return; }
    $(ref.current).tooltip({ container: "body" });

    return () => {
      if (!ref.current) { return; }
      $(ref.current).tooltip('destroy');
    };
  });

  const handleCloneAssignmentClick = () => {
    props.onCloneClick(assignment);
  };

  const handleDeleteAssignmentClick = () => {
    props.onDeleteClick(assignment);
  };

  return (
    <div className={classNames('px-3 py-2 mb-2 d-flex tw-iris-element-box align-items-center justify-content-between', { 'bg-light': i % 2 !== 0 })}>
      <h4 className="my-0">
        <div className="tw-font-semibold">
          {assignment.name}
        </div>
        {assignment.due_date ? (
          <small className="mt-1" title={moment(assignment.due_date).format('LLLL')}>
            Due {moment(assignment.due_date).fromNow()}
          </small>
        ) : null}
      </h4>

      <div className="d-flex align-items-center">
        {assignment.submissions ? (
          <Badge color="info" className="mx-3">
            {interpolate(
              n__('%n submission', '%n submissions', assignment.submissions),
              assignment.submissions
            )}
          </Badge>
        ) : null}

        {isAssessor && basePath !== 'unity_pathways' ? (
          <Dropdown className="btn-group" isOpen={dropdownOpen} toggle={() => setDropdownOpen(o => !o)}>
            <Link className="btn" to={`/${basePath}/${assignment.group_id}/assignments/${assignment.id}`}>
              {__('Open')}
            </Link>

            <DropdownToggle>
              <i className="fa fa-caret-down" />
            </DropdownToggle>
            <DropdownMenu right>
              {assignment.submissions === 0 || props.isSuperAdmin ? (
                <Link style={{ boxSizing: 'border-box' }} className="dropdown-item" to={`/${basePath}/${assignment.group_id}/assignments/${assignment.id}/edit`}>
                  {__('Edit')}
                </Link>
              ) : (
                <div ref={ref} title={__('You cannot edit assignments that already have submissions')}>
                  <DropdownItem disabled>
                    {__('Edit')}
                  </DropdownItem>
                </div>
              )}
              <DropdownItem onClick={handleCloneAssignmentClick}>
                {__('Clone')}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem className="text-danger" onClick={handleDeleteAssignmentClick}>
                {__('Delete')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <Link
            className="tw-btn"
            to={(() => {
              const baseUrl = `/${basePath}/${assignment.group_id}/assignments/${assignment.id}`;
              return !isAssessor && assignment.submissions === 0 ? `${baseUrl}/submissions/new` : baseUrl;
            })()}>
            {__('Open')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default GroupAssignmentRow;
