import React, { useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import useFindDiscussionQuery from '../../../common/hooks/api/discussions/useFindDiscussionQuery';
import useUpdateDiscussionMutation from '../../../common/hooks/api/discussions/useUpdateDiscussionMutation';
import Spinner from '../../../common/components/Spinner';

interface DiscussionTitleFormProps {
  discussionId: Atlas.DiscussionID;
  onSaved: () => void;
}

interface DiscussionTitleFormState {
  title?: string;
}

const DiscussionTitleForm = (props: DiscussionTitleFormProps): JSX.Element => {
  const { discussionId, onSaved } = props;

  const discussionQuery = useFindDiscussionQuery({ discussionId });
  const updateDiscussion = useUpdateDiscussionMutation({ discussionId });
  const discussion = discussionQuery.data?.data

  const [state, setState] = useState<DiscussionTitleFormState>({});

  if (discussionQuery.isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner color="info" />
      </div>
    );
  }

  if (!discussion) {
    return (
      <div>
        {__('Oops! Something went wrong.')}
      </div>
    );
  }

  const isSaving = updateDiscussion.isLoading;

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSaving) { return; }

    const { value } = event.currentTarget;

    setState((s) => ({ ...s, title: value }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (isSaving) { return; }

    if (state.title) {
      updateDiscussion.mutateAsync({
        title: state.title,
      }).then(() => {
        onSaved();
      }, () => {
        setState((s) => ({ ...s, title: undefined }));
      });
    } else {
      onSaved();
    }
  };

  const title = state.title ?? discussion.title;

  return (
    <form onSubmit={handleSubmit}>
      <label className="tw-mb-4">
        <div className="tw-text-lg tw-font-semibold tw-mb-1">
          {__('Discussion title')}
        </div>

        <input
          required
          className="tw-input tw-input-bordered tw-w-full"
          value={title}
          onChange={handleTitleChange}
        />
      </label>

      <div className="tw-flex tw-justify-end">
        <button type="submit" className="tw-btn tw-btn-success" disabled={isSaving}>
          {isSaving ? <span className="tw-loading" /> : null}
          {__('Save')}
        </button>
      </div>
    </form>
  );
};

export default DiscussionTitleForm;
