import React, { useState, useCallback, useContext } from 'react';
import ReflectionPicker from './ReflectionPicker';

import AssignmentSubmissionContext from '../AssignmentSubmissionContext';
import * as Atlas from '../../../../common/types/Atlas';
import { reflectionRoute } from '../../../../common/api/routes';
import ReflectionThumbnail from '../../../../common/components/ReflectionThumbnail';
import { Button } from '../../../../common/components/Button';

export interface ReflectionFormAnswer {
  name: string;
  reflection_value?: Atlas.ReflectionID;
}

interface ReflectionQuestionProps {
  readOnly?: boolean;
  question: Atlas.FormElement;
  answer?: ReflectionFormAnswer;
  showValidation?: boolean;
  onAnswerChange?(fa: ReflectionFormAnswer): void;
}

interface ReflectionQuestionState {
  open: boolean;
}

const ReflectionQuestion = (props: ReflectionQuestionProps): JSX.Element => {
  const { readOnly, question, answer, showValidation } = props;

  const [state, setState] = useState<ReflectionQuestionState>({ open: false });

  const handleSelectClick = () => {
    if (readOnly) { return; }

    setState(s => ({ ...s, open: true }));
  };

  const handleClose = useCallback(() => {
    if (readOnly) { return; }

    setState(s => ({ ...s, open: false }));
  }, []);

  const handleReflectionSelect = (reflection: Atlas.CondensedReflection) => {
    if (readOnly) { return; }

    handleClose();

    if (props.onAnswerChange) {
      props.onAnswerChange({
        name: question.field.name,
        ...answer,
        reflection_value: reflection.id
      });
    }
  };

  const reflectionId = answer?.reflection_value;
  const showError = showValidation && question.field.required && !reflectionId;

  return (
    <div>
      {reflectionId ? (
        <div className="tw-mb-3" style={{ width: '20rem' }}>
          <ReflectionThumbnail reflectionId={reflectionId} />
        </div>
      ) : (
        <div className={`tw-mb-2 tw-p-1 tw-flex ${!reflectionId && question.field.required && showValidation ? 'tw-border tw-border-error/40 tw-ring-1 tw-ring-error/40 tw-rounded-lg' : ''}`}>
          <span className="text-muted">
            {__('No reflection chosen')}
          </span>
        </div>
      )}

      {readOnly ? null : (
        <React.Fragment>
          <Button color="primary" onClick={handleSelectClick}>
            <i className="icon-film mr-2" />
            {answer?.reflection_value ? __('Change Reflection') : __('Select Reflection')}
          </Button>

          <ReflectionPicker
            filter="mine"
            open={state.open}
            onClose={handleClose}
            onReflectionSelect={handleReflectionSelect} />
        </React.Fragment>
      )}
    </div>
  )
};

export default ReflectionQuestion;
