import React from 'react';
import * as Atlas from "../types/Atlas";

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

export function isDefined<T>(t: T | undefined): t is T {
  return t !== undefined;
}

export function isFilled<T>(t: T | null): t is T {
  return t !== null;
}

export function isNotEmpty<T>(t: T | undefined | null | void | ''): t is T {
  return t !== undefined && t !== null && t !== '';
}

export const clamp = (min: number, n: number, max: number): number => Math.min(Math.max(n, min), max);

export const mergeIntervals = (intervals: Array<[number, number]>): Array<[number, number]> => {
  if (!intervals.length) { return intervals; }

  const result = [intervals[0]];

  intervals.reduce((top, curr) => {
    const [tStart, tEnd] = top,
      [cStart, cEnd] = curr;

    if (tEnd >= cStart) {
      let temp: [number, number];
      if (cEnd >= tEnd) {
        temp = [tStart, cEnd];
      } else {
        temp = [tStart, tEnd];
      }
      result.splice(-1, 1, temp);
      return temp;
    }

    result.push(curr);

    return curr;
  }, result[0]);

  return result;
};

export const truncate = (str: string, args?: { maxLength?: number; wordBreak?: boolean; }): string => {
  const { maxLength = 100, wordBreak = false } = args || {};

  if (str.length <= maxLength) { return str; }

  let trimmed = str.substring(0, maxLength);

  if (!wordBreak) {
    const nextTrimmed = trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' ')));
    if (nextTrimmed) { trimmed = nextTrimmed; }
  }

  return `${trimmed}...`;
};

export const secondsToHours = (s: Atlas.Seconds): {
  hours: number;
  minutes: number;
  seconds: number;
} => {
  let seconds = s;
  const hours = Math.floor(s / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);

  return { hours, minutes, seconds };
};

export const formatSeconds = (s: Atlas.Seconds): string => {
  const { hours, minutes, seconds } = secondsToHours(s);


  return [
    hours ? `${hours}h` : null,
    minutes ? `${minutes}m` : null,
    seconds ? `${seconds}s` : null,
  ].filter(isFilled).join(' ');
};

export const secondsToTimestamp = (s: Atlas.Seconds, hideZeroHours = false): string => {
  const { hours, minutes, seconds } = secondsToHours(s);

  const fm = (n: number) => `0${n}`.slice(-2);

  if (hideZeroHours && !hours) {
    return [fm(minutes), fm(seconds)].join(':');
  }

  return [fm(hours), fm(minutes), fm(seconds)].join(':');
};

export const msToTimestamp = (ms: Atlas.Milliseconds, hideZeroHours?: boolean): string => (
  secondsToTimestamp(Math.round(ms / 1000), hideZeroHours)
);

export const interpolate = (str: string, values: number | string | Array<number | string>): string => {
  const valueParser = (value: number | string | undefined) => {
    switch (typeof value) {
      case 'number':
        return String(value);
      default:
        return value || '';
    }
  };

  const replacer = (() => {
    if (!Array.isArray(values)) {
      return () => valueParser(values);
    }

    const arr = [...values];
    return () => valueParser(arr.shift());
  })();

  return str.replace(/%n/g, replacer);
};

export const pageNumbers = (args: {
  page: number;
  totalPages: number;
  range?: number;
}): number[] => {
  const { page, totalPages } = args,
    range = Math.min(args.range || 5, totalPages);

  const maxPage = totalPages - range + 1,
    middlePage = page - Math.round(range / 2) + 1,
    start = clamp(middlePage, 1, maxPage);

  return Array<null>(range).fill(null).map((_, i) => i + start);
}

export const debounce = <T extends any[]>(func: (...args: T) => void, wait: number, immediate?: boolean): (...args: T) => void => {
  let timeout: NodeJS.Timeout | null;

  return function (this: unknown, ...args: T) {
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };

    const callNow = immediate && !timeout;

    if (timeout) { clearTimeout(timeout); }
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(this, args);
  };
}

export const inRange = (number: number, start: number, end: number): boolean => {
  return start <= number && number < end;
};

export const interpolateWithJSX = (
  template: string,
  jsx: React.ReactNode,
  variable = '%s',
) => {
  const [firstSubstr, ...otherSubstrs] = template.split(variable);

  return [
    firstSubstr,
    ...otherSubstrs.reduce<Array<typeof template | typeof jsx>>((arr, substr, i) => [
      ...arr,
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={i}>
        {jsx}
      </React.Fragment>,
      substr,
    ], []),
  ];
};

export const isEnum = <T extends Record<string, string | number>>(
  value: any,
  tsEnum: T,
): value is T[keyof T] => {
  return Object.values(tsEnum).includes(value);
};
