import React from 'react';
import * as Atlas from '../../common/types/Atlas';

export type VideoEditorState = {
  status: 'idle';
} | {
  status: 'editing';
  videoId: Atlas.VideoID;
};

export interface VideoPlaylistState {
  editor: VideoEditorState;
  editable: boolean;
}

export interface VideoEditorActions {
  editVideo: (videoId: Atlas.VideoID) => void;
  reset: () => void;
}

const VideoEditorContext = React.createContext<VideoPlaylistState & VideoEditorActions>({
  editor: {
    status: 'idle',
  },
  editable: false,
  editVideo: () => { },
  reset: () => { },
});

export default VideoEditorContext;
