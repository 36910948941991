import { useMutation, UseMutationResult } from 'react-query';
import { updateDiscussionComment, UpdateDiscussionCommentBody, UpdateDiscussionCommentParams, UpdateDiscussionCommentResponse } from '../../../api/discussion-comments/[discussionCommentId]';
import { queryClient } from '../../withQueryClient';

const useUpdateDiscussionCommentMutation = (
  params: UpdateDiscussionCommentParams,
): UseMutationResult<UpdateDiscussionCommentResponse, unknown, UpdateDiscussionCommentBody, unknown> => {
  const mutation = useMutation((body: UpdateDiscussionCommentBody) => {
    return updateDiscussionComment({ params, body });
  }, {
    onSuccess: (result) => {
      const discussionComment = result.data;
      queryClient.invalidateQueries(['discussions', discussionComment.discussion_id, 'comments']);
    },
  });

  return mutation;
};

export default useUpdateDiscussionCommentMutation;
