import React, { useState } from 'react';
import { Button } from '../../../common/components/Button';
import Spinner from '../../../common/components/Spinner';
import useFindDiscussionQuery from '../../../common/hooks/api/discussions/useFindDiscussionQuery';
import useWatchDiscussionMutation from '../../../common/hooks/api/discussions/useWatchDiscussionMutation';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import { useCurrentUser } from '../../../common/hooks/withCurrentUser';
import * as Atlas from '../../../common/types/Atlas';

interface DiscussionSubscribeFormProps {
  discussionId: Atlas.DiscussionID;
}

interface DiscussionSubscribeFormState {
  watchStatus?: Atlas.WatchStatus;
}

const DiscussionSubscribeForm = (props: DiscussionSubscribeFormProps): JSX.Element => {
  const { discussionId } = props;

  const discussionQuery = useFindDiscussionQuery({ discussionId });
  const updateWatchDiscussion = useWatchDiscussionMutation({ discussionId });

  const [state, setState] = useState<DiscussionSubscribeFormState>({});

  const currentUserQuery = useCurrentUserQuery();
  const user = currentUserQuery.data;

  if (discussionQuery.isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner color="info" />
      </div>
    );
  }

  const discussion = discussionQuery.data?.data;

  if (!discussion) {
    return (
      <div>
        {__('Oops! Something went wrong.')}
      </div>
    );
  }

  const handleWatchToggle = (watchStatus: Atlas.WatchStatus) => () => {
    if (updateWatchDiscussion.isLoading) { return; }

    setState((s) => ({ ...s, watchStatus }));

    updateWatchDiscussion.mutateAsync({
      watch: watchStatus,
    }).catch(() => {
      setState((s) => ({ ...s, watchStatus: undefined }));
    });
  };

  const watchStatus = state.watchStatus ?? (() => {
    if (discussionQuery.isLoading) { return; }
    if (!user) { return; }

    const isWatching = discussion.watchers.includes(user.id);
    return isWatching ? Atlas.WatchStatus.Watch : Atlas.WatchStatus.Unwatch;
  })();

  return (
    <div className="d-flex justify-content-center py-3">
      {(() => {
        switch (watchStatus) {
          case Atlas.WatchStatus.Unwatch: {
            return (
              <Button data-testid="toggle-subscribe-button" onClick={handleWatchToggle(Atlas.WatchStatus.Watch)}>
                {__('Subscribe')}
              </Button>
            );
          }

          case Atlas.WatchStatus.Watch: {
            return (
              <Button data-testid="toggle-subscribe-button" onClick={handleWatchToggle(Atlas.WatchStatus.Unwatch)}>
                {__('Unsubscribe')}
              </Button>
            );
          }

          default: return null;
        }
      })()}

      {updateWatchDiscussion.isLoading? (
        <Spinner size="sm" color="info" className="ml-2" />
      ) : null}
    </div>
  );
};

export default DiscussionSubscribeForm;
