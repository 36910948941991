import React, { useState } from 'react';
import * as Atlas from '../../common/types/Atlas';
import useFindReflectionQuery from '../../common/hooks/api/reflections/useFindReflectionQuery';
import ReflectionAttachedForms from './ReflectionAttachedForms';
import useFindReflectionFormsQuery from '../../common/hooks/api/reflections/useFindReflectionFormsQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import ReflectionAttachableForms from './ReflectionAttachableForms';
import useAssociateReflectionFormMutation from '../../common/hooks/api/reflections/useAssociateReflectionFormMutation';

enum Page {
  Index,
  Add,
}

const ReflectionAttachedFormsWrapper: React.FC<{
  reflectionId: Atlas.ReflectionID;
}> = (props) => {
  const { reflectionId } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const basePath = location.pathname.startsWith('/lti') ? '/lti/reflections' : '/reflections';

  const [page, setPage] = useState(Page.Index);

  const query = useFindReflectionFormsQuery({
    params: { reflectionId },
  });

  const associateForm = useAssociateReflectionFormMutation();

  const forms = query.data ?? [];
  const formsLoading = query.isLoading || associateForm.isLoading;

  switch (page) {
    case Page.Index: {
      const handleAddFormClick = () => {
        setPage(Page.Add);
      };

      const handleFormClick = (form: Atlas.Form) => {
        navigate(`${basePath}/${reflectionId}/forms/${form.id}`);
      };

      return (
        <ReflectionAttachedForms
          forms={forms}
          loading={formsLoading}
          onAddFormClick={handleAddFormClick}
          onFormClick={handleFormClick}
        />
      );
    }

    case Page.Add: {
      const handleAttachableFormsClick = (
        form: Atlas.Form,
      ) => {
        associateForm.mutateAsync({
          params: {
            reflectionId,
            formId: form.id,
          },
        }).then(() => {
          navigate(`${basePath}/${reflectionId}/forms/${form.id}`);
        });
      };

      return (
        <ReflectionAttachableForms
          // @ts-ignore
          loading={formsLoading}
          onBack={() => setPage(Page.Index)}
          onFormClick={handleAttachableFormsClick}
        />
      );
    }
  }
};

const ReflectionFormsWrapper: React.FC<{
  reflectionId: Atlas.ReflectionID;
}> = ({
  reflectionId,
}) => {
    const reflectionQuery = useFindReflectionQuery(reflectionId);
    const formsEnabled = reflectionQuery.data?.data.form_mode !== Atlas.FormMode.Disabled;

    if (!formsEnabled) {
      return (
        <div className="tw-alert tw-bg-white tw-shadow tw-text-gray-600">
          {__('Forms are disabled for this reflection.')}
        </div>
      );
    }

    return (
      <ReflectionAttachedFormsWrapper
        reflectionId={reflectionId}
      />
    );
  };

export default ReflectionFormsWrapper;
