import React, {
  PropsWithChildren, useEffect, useRef, useState,
} from 'react';
import { Link, NavLink } from 'react-router-dom';

interface DynamicMenuItemProps {
  id: string;
  hide: boolean;
  observer: IntersectionObserver | null;
}

const DynamicMenuItem = (props: PropsWithChildren<DynamicMenuItemProps>) => {
  const {
    children, id, hide, observer,
  } = props;

  const itemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!observer) { return undefined; }

    const itemElement = itemRef.current;
    if (!itemElement) { return undefined; }

    observer.observe(itemElement);
    return () => {
      observer.unobserve(itemElement);
    };
  }, [observer]);

  return (
    <li ref={itemRef} data-id={id} className={hide ? 'tw-invisible' : ''}>
      {children}
    </li>
  );
};

interface DynamicMenuProps {
  defaultButtonClassName: string;
  items: Array<{
    id: string;
    label: string;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    activeClassName?: string;
    pendingClassName?: string;
    href?: string;
    hasSubPaths?: boolean;
  }>;
}

const DynamicMenu = (props: DynamicMenuProps) => {
  const { defaultButtonClassName, items } = props;

  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const [hiddenItems, setHiddenItems] = useState<Set<string>>(new Set());

  const track = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: track.current,
      threshold: 1,
    };

    const trackObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target as HTMLElement;

        const { id } = target.dataset;
        if (!id) { return; }

        setHiddenItems((set) => {
          const clonedSet = new Set(set);
          if (entry.isIntersecting) {
            clonedSet.delete(id);
          } else {
            clonedSet.add(id);
          }
          return clonedSet;
        });
      });
    }, options);

    setObserver(trackObserver);

    return () => {
      trackObserver.disconnect();
    };
  }, []);

  return (
    <div className="tw-flex tw-justify-between tw-gap-2">
      <div ref={track} className="tw-overflow-hidden">
        <ul className="tw-flex tw-gap-2">
          {items.map((item) => (
            <DynamicMenuItem
              key={item.id}
              id={item.id}
              observer={observer}
              hide={hiddenItems.has(item.id)}
            >
              {item.href ? (
                <NavLink
                  to={item.href}
                  className={({ isActive, isPending }) => {
                    if (isPending) { return item.pendingClassName; }
                    if (isActive) { return item.activeClassName; }

                    return item.className ?? defaultButtonClassName;
                  }}
                  end={!item.hasSubPaths}
                >
                  {item.label}
                </NavLink>
              ) : (
                <button
                  type="button"
                  className={item.className ?? defaultButtonClassName}
                  onClick={item.onClick}
                >
                  {item.label}
                </button>
              )}
            </DynamicMenuItem>
          ))}
        </ul>
      </div>

      <div className={`tw-flex-none tw-dropdown tw-dropdown-end ${hiddenItems.size === 0 ? 'tw-invisible' : ''}`}>
        <label tabIndex={0} className={defaultButtonClassName}>
          {__('More')}
          &nbsp;
          <i className="fa fa-caret-down" />
        </label>

        <div
          className="tw-dropdown-content tw-iris-dropdown"
        >
          <div className="tw-iris-dropdown-box">
            <ul tabIndex={0}>
              {items.map((item) => {
                if (!hiddenItems.has(item.id)) { return undefined; }

                const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                  event.currentTarget.blur();
                  item.onClick?.(event);
                };

                return (
                  <li key={item.id}>
                    {item.href ? (
                      <Link
                        to={item.href}
                        className={item.className ?? defaultButtonClassName}
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <button type="button" onClick={handleClick}>
                        {item.label}
                      </button>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicMenu;
