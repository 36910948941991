import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import useFindAttachmentQuery from '../hooks/api/attachments/useFindAttachmentQuery';
import * as Atlas from '../types/Atlas';
import { Alert } from './BSAlert';
import Spinner from './Spinner';

interface AttachmentLinkProps {
  attachmentId: Atlas.AttachmentID;
  loadImmediately?: boolean;
}

const AttachmentLink = (props: AttachmentLinkProps): JSX.Element => {
  const {
    attachmentId,
    loadImmediately = false,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersection(ref, {});
  const visible = entry?.isIntersecting ?? false;

  const [enabled, setEnabled] = useState(loadImmediately || visible);
  useEffect(() => {
    if (visible) {
      setEnabled(true);
    }
  }, [visible]);

  const attachmentQuery = useFindAttachmentQuery(attachmentId, {
    staleTime: 60000,
    enabled,
  });

  return (
    <div ref={ref} className="overflow-hidden">
      {(() => {
        switch (attachmentQuery.status) {
          case 'idle':
          case 'loading': {
            return (
              <div className="bg-light px-3 py-2">
                <Spinner size="sm" color="info" />
              </div>
            );
          }

          case 'error': {
            return (
              <Alert color="warning" className="mb-0 d-flex align-items-center">
                <i className="fa fa-exclamation-triangle fa-lg mr-3" />
                {__('You do not have permission to view this attachment')}
              </Alert>
            )
          }

          case 'success': {
            const { data: attachment } = attachmentQuery.data;

            return (
              <div className="text-truncate bg-light px-3 py-2">
                <a href={attachment.media_s3uri?.url} target="_blank" rel="noreferrer" download>
                  <i className="icon-download-alt mr-2" />
                  {attachment.name}
                </a>
              </div>
            )
          }
        }
      })()}
    </div>
  );
};

export default AttachmentLink;
