import * as Atlas from "../types/Atlas";

type ExpandedAssignmentSubmission = Atlas.ExpandedAssignmentSubmission & {
  created_at: string;
  updated_at: string;
}

export const parseAssignmentSubmissionJson = (json: ExpandedAssignmentSubmission): Atlas.ExpandedAssignmentSubmission => {
  return {
    ...json,
    created_at: new Date(json.created_at),
    updated_at: new Date(json.updated_at),
  };
};
