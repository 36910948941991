import { useQuery, UseQueryResult } from 'react-query';
import { findReflectionThumbnails, FindReflectionThumbnailsParams, FindReflectionThumbnailsResponse } from '../../../api/reflections/[reflectionId]/thumbnails';

const useFindReflectionThumbnailsQuery = (
  params: FindReflectionThumbnailsParams,
  options?: { staleTime?: number; enabled?: boolean; },
): UseQueryResult<FindReflectionThumbnailsResponse> => (
  useQuery(
    ['reflections', params.reflectionId, 'thumbnails'],
    () => findReflectionThumbnails({ params }),
    options,
  )
);

export default useFindReflectionThumbnailsQuery;
