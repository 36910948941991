import React, { useEffect, useState } from 'react';
import VolumeMeter from './VolumeMeter';

type AudioLevelProps = {
  stream?: MediaStream;
  className?: string;
};

const audioLevelParts = 20;

const AudioLevelBar: React.FC<AudioLevelProps> = (props) => {
  const { stream, className = '' } = props;

  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (!stream) { return; }

    try {
      const meter = new VolumeMeter(stream);

      meter.start((level) => {
        setVolume(Math.round(level / (100 / audioLevelParts)));
      });

      return () => {
        meter.stop();
        setVolume(0);
      };
    } catch {
      setVolume(0);
    }
  }, [stream]);

  const volumeColour = (() => {
    if (volume >= (audioLevelParts * 0.8)) {
      return 'tw-progress-error';
    }

    return 'tw-progress-info';
  })();

  return (
    <progress
      className={`tw-progress tw-rounded ${volumeColour} ${className}`}
      value={volume}
      max={audioLevelParts}
    />
  );
};

export default AudioLevelBar;
