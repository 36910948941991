import { useMutation } from 'react-query';
import { UpdateCommentResponse, UpdateCommentArguments, updateComment, FindCommentResponse } from '../../../api/comments/[commentId]';
import { FindVideoCommentsResponse } from '../../../api/videos/comments';
import { queryClient } from '../../withQueryClient';

const useUpdateCommentMutation = () => {
  const mutation = useMutation<UpdateCommentResponse, unknown, UpdateCommentArguments, unknown>(
    updateComment,
    {
      onSuccess: async (result) => {
        const comment = result.data;

        queryClient.setQueryData<FindCommentResponse>(['comments', comment.id], { data: comment });

        if (comment.video_id) {
          await queryClient.invalidateQueries<FindVideoCommentsResponse>(['videos', comment.video_id, 'comments']);
        }
      },
    },
  );

  return mutation;
};

export default useUpdateCommentMutation;
