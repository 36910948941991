import { useMutation, UseMutationResult } from 'react-query';
import { CreateAssignmentSubmissionArgs, createAssignmentSubmission } from '../../../api/assignments/assignment-submissions';
import { queryClient } from '../../../../common/hooks/withQueryClient';
import * as Atlas from '../../../../common/types/Atlas';

type CreateAssignmentSubmissionResponse = {
  data: Atlas.ExpandedAssignmentSubmission;
};

type UseCreateAssignmentSubmissionMutationResult = UseMutationResult<
  CreateAssignmentSubmissionResponse,
  unknown,
  CreateAssignmentSubmissionArgs
>;

const useCreateAssignmentSubmissionMutation = (
  assignmentId: Atlas.AssignmentID
): UseCreateAssignmentSubmissionMutationResult => {

  const mutation = useMutation(createAssignmentSubmission, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assignments', assignmentId, 'submissions']);
    }
  });

  return mutation
};

export default useCreateAssignmentSubmissionMutation;