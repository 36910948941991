import { useMutation } from 'react-query';
import { transcribeVideo, TranscribeVideoArguments, TranscribeVideoResponse } from '../../../api/videos/[videoId]/transcribe';

const useTranscribeVideoMutation = () => {
  const mutation = useMutation<TranscribeVideoResponse, unknown, TranscribeVideoArguments, unknown>(
    transcribeVideo,
  );

  return mutation;
};

export default useTranscribeVideoMutation;
