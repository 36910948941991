import { useCallback, useState, useEffect, useRef } from 'react';

import axios, { CancelTokenSource } from 'axios';
import * as Atlas from '../../../../common/types/Atlas';
import {
  createAttachment,
  destroyAttachment
} from '../../../../common/api/attachments';

interface UploaderProps {
  autoUpload?: boolean;
  onAttachmentCreated?: (attachment: Atlas.Attachment) => void;
  onUploadFailed?: () => void;
  onUploadComplete?: () => void;
}

interface UploaderState {
  creatingAttachment?: boolean;
  files?: FileList | null;
  uploading?: boolean;
  cancelToken?: CancelTokenSource;
  progress?: number;
}

const useUploader = (opts?: UploaderProps): UploaderState & {
  clearSelection: { (): void; },
  selectFile: { (): void; },
  uploadFile: { (): Promise<Atlas.Attachment>; },
  deleteAttachment: { (id: Atlas.AttachmentID): Promise<void>; }
} => {
  const { autoUpload, onAttachmentCreated, onUploadComplete, onUploadFailed } = opts || {};

  const inputRef = useRef<HTMLInputElement>(),
    [state, setState] = useState<UploaderState>({});

  const clearSelection = useCallback(() => {
    if (inputRef.current) { inputRef.current.value = ''; }
    setState(s => ({ ...s, files: undefined }));
  }, []);

  const selectFile = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const uploadFile = useCallback(async () => {
    if (state.uploading) { throw new Error('Already uploading'); }
    if (!state.cancelToken) { throw new Error('Cancellation token missing'); }

    if (!state.files) { throw new Error('No files selected'); }
    const file = state.files[0];
    if (!file) { throw new Error('No files selected'); }

    setState(s => ({
      ...s,
      progress: 0,
      uploading: true,
      uploaded: false
    }));

    return createAttachment({ body: { name: file.name } }).then(response => {
      const { attachment } = response;
      setState(s => ({ ...s, attachment }));

      onAttachmentCreated?.(attachment);

      return response;
    }).then((response) => {
      const { attachment } = response;
      if (!attachment.media_key) { throw new Error('Missing media key'); }

      const formData = new FormData();
      formData.append('media_key', attachment.media_key)
      formData.append('files[]', file);

      const { hermes } = response.metadata;

      return axios.post(`${hermes.upload_uri}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        cancelToken: state.cancelToken?.token,
        onUploadProgress(progressEvent) {
          const totalLength = (() => {
            if (progressEvent.lengthComputable) { return progressEvent.total ?? 0; }
            return 0;
          })();

          const progress = Math.round((progressEvent.loaded * 100) / totalLength);
          setState(s => ({ ...s, progress: Math.max(progress, s.progress ?? 0) }));
        }
      }).then(() => {
        setState(s => ({
          ...s,
          progress: 100,
          uploading: false,
          uploaded: true,
          files: undefined
        }));

        onUploadComplete?.();

        return attachment;
      }, (e) => {
        onUploadFailed?.();
        throw e;
      });
    });
  }, [state.uploading, state.cancelToken, state.files, createAttachment, onAttachmentCreated, onUploadComplete, onUploadFailed]);

  useEffect(() => {
    const $input = document.createElement('input');
    $input.setAttribute('type', 'file');
    $input.addEventListener('change', event => {
      const { files } = event.currentTarget as HTMLInputElement;
      setState(s => ({ ...s, files }));
    });

    inputRef.current = $input;
    return () => {
      $input.remove();
    };
  }, []);

  useEffect(() => {
    if (!autoUpload || !state.files) { return; }
    uploadFile();
  }, [autoUpload, state.files, uploadFile]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    setState(s => ({ ...s, cancelToken }));
    return () => { cancelToken.cancel(); };
  }, []);

  useEffect(() => {
    if (!state.uploading) { return; }

    const cb = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', cb);
    return () => {
      window.removeEventListener('beforeunload', cb);
    }
  }, [state.uploading]);


  const deleteAttachment = useCallback((attachmentId: Atlas.AttachmentID) => {
    return destroyAttachment({ params: { attachmentId } });
  }, []);

  return {
    ...state,
    clearSelection,
    selectFile,
    uploadFile,
    deleteAttachment
  };
};

export default useUploader;
