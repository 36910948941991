import { nanoid } from 'nanoid';
import { allFormElementOptions } from '../form-builder/builder/formElementOptions';

export const formElementOptions = [
  allFormElementOptions.text,
  allFormElementOptions.multiple_choice,
  allFormElementOptions.rating,
  allFormElementOptions.attachment,
  allFormElementOptions.reflection
];

export const formElementOptionsMap = formElementOptions.reduce((acc, feo) => {
  acc[feo.type] = feo;

  return acc;
}, {});

export const generateFormElement = type => ({
  field: {
    name: nanoid(),
    has_notes: true,
    required: true,
    link: 'unlinked',
    ...formElementOptionsMap[type].defaultValue
  }
});

export default formElementOptions;
