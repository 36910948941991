/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useCallback, useEffect, useMemo, useReducer, useRef,
} from 'react';
import { Range } from 'react-range';
import { clamp, round, throttle } from 'lodash-es';
import {
  BeforePlayEvent,
  BufferChangeEvent,
  MetadataEvent,
  SeekEvent,
  TimeEvent,
  useJwPlayer,
} from '../../jwplayer/JwPlayer';
import { secondsToTimestamp } from '../../../common/utils/utils';
import { VideoID } from '../../../common/types/Atlas';
import useFindVideoSpritesQuery from '../../../common/hooks/api/videos/useFindVideoSpritesQuery';

const getProgress = (args: {
  min: number;
  max: number;
  values: number[];
}) => {
  const { values, min, max } = args;

  const progress = values
    .slice(0).sort((a, b) => a - b).map((value) => ((value - min) / (max - min)) * 100);

  const startPercent = `${progress[0]}%`;
  const endPercent = `${100 - progress[1]}%`;

  return [startPercent, endPercent];
};

const step = 0.01;
const precision = 2;

interface VideoEditorCropperState {
  values: [number, number];
}

interface VideoEditorCropperProps {
  sliderSprites?: {
    medium?: string;
  };
  values: [number, number];
  onChange: (cb: (s: VideoEditorCropperState) => VideoEditorCropperState) => void;
}

const VideoEditorCropper = (props: VideoEditorCropperProps) => {
  const {
    values, onChange, sliderSprites,
  } = props;

  const [duration, setDuration] = useReducer((
    _: number | undefined,
    num: number | undefined,
  ) => {
    if (typeof num !== 'number') { return undefined; }

    return round(num, precision);
  }, undefined);

  const refState = useRef<VideoEditorCropperState>({ values });

  const setState = useCallback((cb: (prev: VideoEditorCropperState) => VideoEditorCropperState) => {
    refState.current = cb(refState.current);

    onChange((s) => {
      const nextState = cb(s);

      refState.current = nextState;
      return nextState;
    });
  }, [onChange]);

  useEffect(() => {
    if (typeof duration !== 'number') { return; }

    setState((s) => ({
      ...s,
      values: [
        Math.min(s.values[0], duration),
        Math.min(s.values[1], duration),
      ],
    }));
  }, [duration, setState]);

  const onBufferChange = useCallback<BufferChangeEvent>((event) => {
    setDuration(event.duration);
  }, [setDuration]);

  const onMetadata = useCallback<MetadataEvent>((event, player) => {
    const nextDuration = player.getDuration();
    if (!nextDuration) { return; }

    setDuration(nextDuration);
  }, [setDuration]);

  const runThrottled = useMemo(() => {
    const func = (cb: () => void) => {
      cb();
    };

    return throttle(func, 200);
  }, []);

  const onBeforePlay = useCallback<BeforePlayEvent>((player) => {
    const [start, end] = refState.current.values;

    const position = player.getPosition();
    if (position >= end || position < start) {
      runThrottled(() => {
        player.seek(start);
      });
    }
  }, [runThrottled]);

  const onSeekOrTime = useCallback<SeekEvent & TimeEvent>((event, player) => {
    const { position } = event;
    const [start, end] = refState.current.values;

    if (position < start || end < position) {
      runThrottled(() => {
        player.seek(clamp(position, start, end));
        player.pause();
      });
    }
  }, [runThrottled]);

  const player = useJwPlayer({
    onBufferChange,
    onMetadata,
    onBeforePlay,
    onTime: onSeekOrTime,
    onSeek: onSeekOrTime,
  });

  const jwplayer = player?.player;

  const seek = useMemo(() => {
    if (!jwplayer) { return undefined; }

    const playerSeek = (position: number) => {
      runThrottled(() => {
        jwplayer.seek(position);
        jwplayer.pause();
      });
    };

    return playerSeek;
  }, [jwplayer, runThrottled]);

  const [rawStart, rawEnd] = values;
  const range = useMemo(() => {
    if (!duration) {
      return {
        min: 0, max: 1, values: [0, 1],
      };
    }

    return {
      min: 0,
      max: duration,
      values: [
        Math.max(rawStart, 0),
        Math.min(rawEnd, duration),
      ],
    };
  }, [duration, rawStart, rawEnd]);

  const handleRangeChange = (nextValues: number[]) => {
    const [start, end] = range.values;
    const [nextStart, nextEnd] = nextValues;
    const startChanged = start !== nextStart;
    const endChanged = end !== nextEnd;

    if (!startChanged && !endChanged) { return; }

    jwplayer?.pause();
    setState((s) => ({ ...s, values: [nextStart, nextEnd] }));

    if (seek) {
      if (startChanged && !endChanged) {
        seek(nextStart);
      } else if (endChanged && !startChanged) {
        seek(nextEnd);
      } else if (jwplayer) {
        seek(clamp(jwplayer.getPosition(), nextStart, nextEnd));
      }
    }
  };

  return (
    <div>
      <div className="tw-flex tw-gap-2 tw-mb-2">
        {(() => {
          switch (player?.status) {
            case 'buffering':
            case 'playing': {
              const handleClick = () => {
                player.player?.pause();
              };

              return (
                <button type="button" className="tw-btn tw-btn-square tw-flex-none" onClick={handleClick}>
                  <i className="fa fa-pause" />
                </button>
              );
            }

            default: {
              const handleClick = () => {
                player?.player?.play();
              };

              return (
                <button type="button" className="tw-btn tw-btn-square tw-flex-none" onClick={handleClick}>
                  <i className="fa fa-play" />
                </button>
              );
            }
          }
        })()}

        <div className="tw-flex-1 tw-bg-base-200 tw-rounded-lg tw-px-2 tw-relative tw-overflow-hidden">
          <Range
            draggableTrack
            disabled={!duration}
            values={range.values}
            step={step}
            min={range.min}
            max={range.max}
            onChange={handleRangeChange}
            renderTrack={({ props, children }) => {
              const [left, right] = getProgress({
                values: range.values,
                min: range.min,
                max: range.max,
              });

              return (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  className={`tw-flex tw-h-full tw-w-full ${typeof duration !== 'number' ? 'tw-invisible' : ''}`}
                  style={props.style}
                >
                  <div
                    ref={props.ref}
                    className="tw-self-center tw-h-full tw-w-full"
                  >
                    <div
                      className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-info"
                      style={{ clipPath: `inset(0% ${right} 0% ${left})` }}
                    >
                      {sliderSprites?.medium ? (
                        <img
                          alt={__('Video thumbnails')}
                          src={sliderSprites.medium}
                          className="tw-w-full tw-h-full"
                        />
                      ) : null}
                    </div>
                    <div
                      className="tw-absolute tw-top-0 tw-bottom-0 tw-border-2 tw-border-info"
                      style={{ left, right }}
                    />

                    {children}
                  </div>
                </div>
              );
            }}
            renderThumb={({ props }) => (
              <div
                {...props}
                className={`tw-w-4 tw-h-full tw-rounded-lg tw-bg-white tw-shadow tw-border-2 tw-border-info tw-grid tw-place-items-center tw-text-base-300 hover:tw-text-base-content ${typeof duration !== 'number' ? 'tw-invisible' : ''}`}
              >
                <i className="fa fa-ellipsis-v" />
              </div>
            )}
          />
        </div>
      </div>

      <output className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-px-3 tw-py-2 tw-border tw-rounded-lg">
        <span className="tw-text-left">
          <div className="tw-text-sm tw-text-base-300 tw-font-bold">
            {__('Start')}
          </div>
          <div className={`tw-text-xl ${!duration ? 'tw-invisible' : ''}`}>
            {secondsToTimestamp(range.values[0], range.max < 360)}
          </div>
        </span>
        <div>
          <i className="fa fa-arrows-h fa-lg tw-text-base-200" />
        </div>
        <span className="tw-text-right">
          <div className="tw-text-sm tw-text-base-300 tw-font-bold">
            {__('End')}
          </div>
          <div className={`tw-text-xl ${!duration ? 'tw-invisible' : ''}`}>
            {secondsToTimestamp(range.values[1], range.max < 360)}
          </div>
        </span>
      </output>
    </div>
  );
};

type VideoEditorCropperContainerProps = VideoEditorCropperProps & {
  videoId: VideoID;
};

const VideoEditorCropperContainer = (props: VideoEditorCropperContainerProps) => {
  const {
    videoId, values, onChange,
  } = props;

  const videoSpritesQuery = useFindVideoSpritesQuery(videoId);
  const sliderSprites = (() => {
    switch (videoSpritesQuery.status) {
      case 'success': {
        const sprites = videoSpritesQuery.data.data;
        const small = sprites.find((sprite) => sprite.s3uri.path.includes('-small'))?.s3uri.url;
        const medium = sprites.find((sprite) => sprite.s3uri.path.includes('-medium'))?.s3uri.url;
        const large = sprites.find((sprite) => sprite.s3uri.path.includes('-large'))?.s3uri.url;

        return {
          small, medium, large,
        };
      }

      default: return {
        small: undefined,
        medium: undefined,
        large: undefined,
      };
    }
  })();

  return (
    <VideoEditorCropper
      sliderSprites={sliderSprites}
      values={values}
      onChange={onChange}
    />
  );
};

export default VideoEditorCropperContainer;
