import { disassociateReflectionFormRoute, generateFetchHeaders } from '../../../../../routes';
import ApiError from '../../../../../error';
import { FormID, ReflectionID } from '../../../../../../types/Atlas';

export interface DisassociateReflectionFormArguments {
  params: {
    reflectionId: ReflectionID;
    formId: FormID;
  };
}

export type DisassociateReflectionFormResponse = void;

export const disassociateReflectionForm = async (
  args: DisassociateReflectionFormArguments,
): Promise<DisassociateReflectionFormResponse> => {
  const url = disassociateReflectionFormRoute({
    reflectionId: args.params.reflectionId,
    formId: args.params.formId,
  });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'disassociateReflectionForm');
  }
};
