import React from 'react';
import CopyButton from '../../../../common/components/CopyButton';
import * as Atlas from '../../../../common/types/Atlas';
import useVideoPlayer from '../../../jwplayer/useVideoPlayer';

interface ReflectionShareButtonsProps {
  reflectionId: Atlas.ReflectionID;
}

const ReflectionShareButtons = (props: ReflectionShareButtonsProps) => {
  const { reflectionId } = props;

  const player = useVideoPlayer();

  const getLinkToReflection = () => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/reflections/${reflectionId}`;
  };

  const getLinkToCurrentTime = player ? (() => {
    if (!player) { return ''; }

    const { videoId } = player;
    const startAt = Math.floor(player.getPosition());

    return `${getLinkToReflection()}?video_id=${videoId}&start_at=${startAt}`;
  }) : null;

  return (
    <section className="tw-flex tw-flex-col tw-gap-2">
      <div>
        <CopyButton
          content={getLinkToReflection}
          className="tw-btn tw-btn-sm tw-btn-block"
        >
          {__('Copy link to reflection')}
        </CopyButton>
      </div>

      {getLinkToCurrentTime ? (
        <div>
          <CopyButton
            content={getLinkToCurrentTime}
            className="tw-btn tw-btn-sm tw-btn-block"
          >
            {__('Copy link to current time in reflection')}
          </CopyButton>
        </div>
      ) : null}
    </section>
  );
};

export default ReflectionShareButtons;
