import React, { useState, useEffect } from 'react';
import * as Atlas from '../../../../common/types/Atlas';

interface ReflectionOtherModesFormProps {
  attachmentMode: Atlas.AttachmentMode;
  commentMode: Atlas.CommentMode;
  formMode: Atlas.FormMode;
  onSubmit: (modes: {
    attachmentMode: Atlas.AttachmentMode;
    commentMode: Atlas.CommentMode;
    formMode: Atlas.FormMode;
  }) => Promise<void>;
}

const ReflectionOtherModesForm = (props: ReflectionOtherModesFormProps) => {
  const {
    attachmentMode, commentMode, formMode, onSubmit,
  } = props;

  const [nextAttachmentMode, setNextAttachmentMode] = useState(attachmentMode);
  const [nextCommentMode, setNextCommentMode] = useState(commentMode);
  const [nextFormMode, setNextFormMode] = useState(formMode);

  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setNextAttachmentMode(attachmentMode);
  }, [attachmentMode]);

  useEffect(() => {
    setNextCommentMode(commentMode);
  }, [commentMode]);

  const handleAttachmentModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.AttachmentMode.Normal:
      case Atlas.AttachmentMode.Disabled: break;
      default: return;
    }

    setNextAttachmentMode(value);
  };

  const handleCommentModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.CommentMode.Normal:
      case Atlas.CommentMode.Disabled: break;
      default: return;
    }

    setNextCommentMode(value);
  };

  const handleFormModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.FormMode.Normal:
      case Atlas.FormMode.Disabled: break;
      default: return;
    }

    setNextFormMode(value);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (updating) { return; }

    setUpdating(true);
    onSubmit({
      attachmentMode: nextAttachmentMode,
      commentMode: nextCommentMode,
      formMode: nextFormMode,
    }).finally(() => {
      setUpdating(false);
    });
  };

  const formDisabled = (
    nextAttachmentMode === attachmentMode
  ) && (
    nextCommentMode === commentMode
  ) && (
    nextFormMode === formMode
  );

  return (
    <form onSubmit={handleFormSubmit}>
      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Attachment Mode')}
      </p>

      <div className="tw-mb-2">
        <select
          className="tw-select tw-select-sm tw-select-bordered tw-w-56"
          value={nextAttachmentMode}
          onChange={handleAttachmentModeChange}
        >
          <option value={Atlas.AttachmentMode.Normal}>{__('Normal')}</option>
          <option value={Atlas.AttachmentMode.Disabled}>{__('Disabled')}</option>
        </select>
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Comment Mode')}
      </p>

      <div className="tw-mb-2">
        <select
          className="tw-select tw-select-sm tw-select-bordered tw-w-56"
          value={nextCommentMode}
          onChange={handleCommentModeChange}
        >
          <option value={Atlas.CommentMode.Normal}>{__('Normal')}</option>
          <option value={Atlas.CommentMode.Disabled}>{__('Disabled')}</option>
        </select>
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Insight Mode')}
      </p>

      <div className="tw-mb-2">
        <select
          className="tw-select tw-select-sm tw-select-bordered tw-w-56"
          value={nextFormMode}
          onChange={handleFormModeChange}
        >
          <option value={Atlas.FormMode.Normal}>{__('Normal')}</option>
          <option value={Atlas.FormMode.Disabled}>{__('Disabled')}</option>
        </select>
      </div>

      <div className="tw-flex tw-justify-end tw-mt-3">
        <button
          type="submit"
          className="tw-btn tw-btn-sm tw-btn-success"
          disabled={formDisabled}
        >
          {updating ? <span className="tw-loading" /> : null}
          {__('Update Privacy Settings')}
        </button>
      </div>
    </form>
  );
};

export default ReflectionOtherModesForm;
