/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MyFormsList from './attachable-forms/MyFormsList';
import SharedFormsList from './attachable-forms/SharedFormsList';

const ReflectionAttachableForms = (props) => {
  const [activeForms, setActiveForms] = useState('mine');

  const handleBackClick = () => {
    props.onBack();
  };

  const handleTabClick = (filter) => () => {
    setActiveForms(filter);
  };

  const $forms = (() => {
    switch (activeForms) {
      case 'mine':
        return <MyFormsList {...props} />;
      case 'shared':
        return <SharedFormsList {...props} />;
      default: return null;
    }
  })();

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 xs:tw-gap-4 tw-h-full">
      <div className="tw-flex tw-items-start tw-justify-between">
        <button
          type="button"
          className="tw-btn tw-btn-sm tw-btn-white tw-shadow"
          onClick={handleBackClick}
        >
          <i className="fa fa-chevron-left tw-text-xs" />
          &nbsp;
          {__('Back')}
        </button>

        <div className="tw-flex tw-gap-2">
          <button
            type="button"
            className={`tw-btn tw-btn-sm ${activeForms === 'mine' ? 'tw-btn-primary' : 'tw-btn-white'}`}
            onClick={handleTabClick('mine')}
          >
            {__('My Insights')}
          </button>

          <button
            type="button"
            className={`tw-btn tw-btn-sm ${activeForms === 'shared' ? 'tw-btn-primary' : 'tw-btn-white'}`}
            onClick={handleTabClick('shared')}
          >
            {__('Shared Insights')}
          </button>
        </div>
      </div>

      <div className="tw-grow tw-overflow-scroll tw-h-0 tw-rounded-xl tw-bg-base-300 tw-border tw-border-base-400 tw-shadow-inner tw-p-2 xs:tw-p-4 tw-min-h-[130px]">
        {$forms}
      </div>
    </div>
  );
};

ReflectionAttachableForms.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default ReflectionAttachableForms;
