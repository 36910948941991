/* eslint-disable import/prefer-default-export */
import { Modifier } from 'react-popper';

export const sameWidthModifier: Modifier<'sameWidth'> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn({ state }) {
    return {
      ...state,
      styles: {
        ...state.styles,
        popper: {
          ...state.styles.popper,
          width: `${state.rects.reference.width}px`,
        },
      },
    };
  },
  effect({ state }) {
    const { width } = state.elements.reference.getBoundingClientRect();

    // eslint-disable-next-line no-param-reassign
    state.elements.popper.style.width = `${width}px`;
  },
};
