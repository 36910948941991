import React from 'react';
import { withQueryClient } from '../../../common/hooks/withQueryClient';
import { IridiumProvider } from '../../jwplayer/useIridium';
import ReflectionContent from './ReflectionContent';
import ScreenCapture from './ScreenCapture';
import ReflectionStoreProvider from './store/ReflectionStoreProvider'; // Make sure to import ReflectionStoreProvider

const ReflectionContentEmbed = (props: { reflectionId: number }) => {
  const { reflectionId } = props;

  return (
    <IridiumProvider>
      <ReflectionStoreProvider> {/* Wrap ReflectionContent with ReflectionStoreProvider */}
        <ReflectionContent reflectionId={reflectionId} />
        <ScreenCapture reflectionId={reflectionId} />
      </ReflectionStoreProvider>
    </IridiumProvider>
  );
};

export default withQueryClient(ReflectionContentEmbed);
