import { ExpandedAnswer, Form, FormFieldName, FormFieldOverview, FormID, FormReflection, FormUser, ReflectionID, UserID } from '../../types/Atlas';
import { parseFormJson } from '../../utils/form-parser';
import { jsonHeaders, Metadata, PaginationParams, searchParams } from '../routes';

export const formRoute = ({ formId }: { formId: FormID }): string => `/forms/${formId}`;
export const formOverviewRoute = ({ formId }: { formId: FormID }): string => `/forms/${formId}/overview`;
export const formAnswersRoute = ({ formId }: { formId: FormID }): string => `/forms/${formId}/answers`;
export const formRespondentsRoute = ({ formId }: { formId: FormID }): string => `/forms/${formId}/respondents`;
export const formReflectionsRoute = ({ formId }: { formId: FormID }): string => `/forms/${formId}/reflections`;
export const formReflectionOwnersRoute = ({ formId }: { formId: FormID }): string => `/forms/${formId}/reflection_owners`;
export const formCsvRoute = ({ formId }: { formId: FormID }): string => `/forms/${formId}/csv`;

interface FormCsvPathArgs {
  params: {
    formId: FormID;
    fieldName?: FormFieldName,
    respondentUserId?: UserID;
    reflectionId?: ReflectionID;
    reflectionUserId?: UserID;
  };
}

export const formCsvPath = (args: FormCsvPathArgs): string => {
  const { formId, ...params } = args.params

  return [
    formCsvRoute({ formId }),
    searchParams({
      field_name: params.fieldName,
      respondent_user_id: params.respondentUserId,
      reflection_id: params.reflectionId,
      reflection_user_id: params.reflectionUserId,
    }),
  ].join('?');
};

export interface FindFormArgs {
  params: {
    formId: FormID;
  };
}

export interface FindFormResponse {
  data: Form
}

export const findForm = (args: FindFormArgs): Promise<FindFormResponse> => {
  const { formId } = args.params;

  const formUrl = [
    formRoute({ formId })
  ].join('?');

  return fetch(formUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] findForm failed`; }
    return r.json();
  }).then(data => ({
    data: parseFormJson(data)
  }));
};

interface FormOverviewArgs {
  params: {
    formId: FormID;
    respondentUserId?: UserID;
    reflectionId?: ReflectionID;
    reflectionUserId?: UserID;
    page?: number;
    perPage?: number;
  };
}

export const formOverview = (args: FormOverviewArgs): Promise<{ data: FormFieldOverview[]; metadata: Metadata }> => {
  const { formId, ...params } = args.params;

  const formOverviewUrl = [
    formOverviewRoute({ formId }),
    searchParams({
      respondent_user_id: params.respondentUserId,
      reflection_id: params.reflectionId,
      reflection_user_id: params.reflectionUserId,
      page: params.page,
      per_page: params.perPage
    })
  ].join('?');

  return fetch(formOverviewUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] formOverview failed`; }
    return r.json();
  });
};

interface FormAnswersArgs {
  params: {
    formId: FormID;
    respondentUserId?: UserID;
    reflectionId?: ReflectionID;
    reflectionUserId?: UserID;
    fieldName?: FormFieldName;
    page?: number;
    perPage?: number;
  };
}

export const formAnswers = (args: FormAnswersArgs): Promise<{ data: ExpandedAnswer[]; metadata: Metadata }> => {
  const { formId, ...params } = args.params;

  const formAnswersUrl = [
    formAnswersRoute({ formId }),
    searchParams({
      respondent_user_id: params.respondentUserId,
      reflection_id: params.reflectionId,
      reflection_user_id: params.reflectionUserId,
      field_name: params.fieldName,
      page: params.page,
      per_page: params.perPage
    })
  ].join('?');

  return fetch(formAnswersUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] formAnswers failed`; }
    return r.json();
  });
};

interface FormRespondentsArgs {
  params: {
    formId: FormID;
    search?: string;
    page?: number;
    perPage?: number;
  };
}

export const formRespondents = (args: FormRespondentsArgs): Promise<{ data: FormUser[]; metadata: Metadata }> => {
  const { formId, ...params } = args.params;

  const formRespondentsUrl = [
    formRespondentsRoute({ formId }),
    searchParams({
      search: params.search,
      page: params.page,
      per_page: params.perPage
    })
  ].join('?');

  return fetch(formRespondentsUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] formRespondents failed`; }
    return r.json();
  });
};

interface FormReflectionOwnersArgs {
  params: {
    formId: FormID;
    search?: string;
    page?: number;
    perPage?: number;
  };
}

export const formReflectionOwners = (args: FormReflectionOwnersArgs): Promise<{ data: FormUser[]; metadata: Metadata }> => {
  const { formId, ...params } = args.params;

  const formReflectionOwnersUrl = [
    formReflectionOwnersRoute({ formId }),
    searchParams({
      search: params.search,
      page: params.page,
      per_page: params.perPage
    })
  ].join('?');

  return fetch(formReflectionOwnersUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] formReflectionOwners failed`; }
    return r.json();
  });
};

interface FormReflectionsArgs {
  params: {
    formId: FormID;
    search?: string;
    page?: number;
    perPage?: number;
  };
}

export const formReflections = (args: FormReflectionsArgs): Promise<{ data: FormReflection[]; metadata: Metadata }> => {
  const { formId, ...params } = args.params;

  const formReflectionsUrl = [
    formReflectionsRoute({ formId }),
    searchParams({
      search: params.search,
      page: params.page,
      per_page: params.perPage
    })
  ].join('?');

  return fetch(formReflectionsUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] formReflections failed`; }
    return r.json();
  });
};
