import React, { useEffect, useState } from 'react';
import { Modal, ModalBox, ModalHeader } from '../../../../../common/components/TailwindModal';
import VideoStreamRecorder, { useVsrStatuses } from '../VideoStreamRecorder';
import * as Atlas from '../../../../../common/types/Atlas';
import useFindCommentQuery from '../../../../../common/hooks/api/comments/useFindCommentQuery';
import useUpdateCommentMutation from '../../../../../common/hooks/api/comments/useUpdateCommentMutation';

const CommentFormFields: React.FC<{
  commentId: Atlas.CommentID;
  setSubmitCallback: (cb: () => Promise<void>) => void;
}> = (props) => {
  const { commentId, setSubmitCallback } = props;

  const commentQuery = useFindCommentQuery({ commentId });
  const updateComment = useUpdateCommentMutation();

  const comment = commentQuery.data?.data;

  const [state, setState] = useState<{
    body?: string;
    synchronicity?: Atlas.CommentSynchronicity;
  }>({});

  useEffect(() => {
    setSubmitCallback(async () => {
      if (Object.keys(state).length === 0) { return; }

      await updateComment.mutateAsync({
        params: { commentId },
        body: state,
      });
    });
  }, [commentId, state]);

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;

    setState((s) => ({
      ...s,
      body: value,
    }));
  };

  const handleSynchronicityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.CommentSynchronicity.Pause:
      case Atlas.CommentSynchronicity.Simultaneous: {
        setState((s) => ({
          ...s,
          synchronicity: value,
        }));
        break;
      }
    }
  };

  const commentBody = state.body ?? comment?.body ?? '';
  const commentSync = state.synchronicity ?? comment?.synchronicity ?? Atlas.CommentSynchronicity.Pause;

  return (
    <>
      <div className="tw-form-control tw-mt-1">
        <textarea
          className="tw-textarea tw-textarea-bordered tw-w-full"
          placeholder="Type your comment"
          value={commentBody}
          onChange={handleCommentChange}
        />
      </div>

      {typeof comment?.start === 'number' ? (
        <div className="tw-form-control tw-mb-4 tw-space-y-2 tw-mt-4">
          <label className="tw-flex tw-gap-3 tw-mb-0">
            <input
              type="radio"
              className="!tw-radio !tw-radio-info !tw-outline-none !tw-m-0"
              name="synchronicity"
              value={Atlas.CommentSynchronicity.Pause}
              checked={commentSync === Atlas.CommentSynchronicity.Pause}
              onChange={handleSynchronicityChange}
            />
            <div>
              <div className="tw-font-semibold tw-text-base">
                {__('Pause')}
              </div>
              <div>
                {__('Pause the reflection video when this media comment is played.')}
              </div>
            </div>
          </label>

          <label className="tw-flex tw-gap-3 tw-mb-0">
            <input
              type="radio"
              className="!tw-radio !tw-radio-info !tw-outline-none !tw-m-0"
              name="synchronicity"
              value={Atlas.CommentSynchronicity.Simultaneous}
              checked={commentSync === Atlas.CommentSynchronicity.Simultaneous}
              onChange={handleSynchronicityChange}
            />
            <div>
              <div className="tw-font-semibold tw-text-base">
                {__('Simultaneous')}
              </div>
              <div>
                {__('Play media comment simultaneously with the reflection video.')}
              </div>
            </div>
          </label>
        </div>
      ) : null}
    </>
  );
};

const MediaUploadingModal: React.FC<{
  vsr: VideoStreamRecorder;
  onCancelClick: () => void;
  onDoneClick: () => void;
}> = (props) => {
  const {
    vsr,
    onCancelClick,
    onDoneClick,
  } = props;

  const statuses = useVsrStatuses(vsr);

  const leftStatus = statuses.left;
  const rightStatus = statuses.right;

  const [leftStart, setLeftStart] = useState(leftStatus.uploadProgress?.uploadedParts);
  if (leftStatus.uploadProgress && leftStart === undefined) {
    setLeftStart(leftStatus.uploadProgress.uploadedParts);
  }

  const [rightStart, setRightStart] = useState(rightStatus.uploadProgress?.uploadedParts);
  if (rightStatus.uploadProgress && rightStart === undefined) {
    setRightStart(rightStatus.uploadProgress.uploadedParts);
  }

  const leftProgress = leftStatus.uploadProgress?.uploadedParts ?? 0;
  const leftTotal = leftStatus.uploadProgress?.totalUploadParts;
  const leftTotalAdjusted = leftTotal ? (leftTotal + 1) : 0;

  const rightProgress = rightStatus.uploadProgress?.uploadedParts ?? 0;
  const rightTotal = rightStatus.uploadProgress?.totalUploadParts;
  const rightTotalAdjusted = rightTotal ? (rightTotal + 1) : 0;

  const offset = (leftStart ?? 0) + (rightStart ?? 0);
  const progress = (leftProgress + rightProgress) - offset;
  const total = (leftTotalAdjusted + rightTotalAdjusted) - offset;

  const status = (() => {
    const ranked = ['idle', 'uploaded', 'uploading', 'recording', 'error'];
    const rank = (k: string) => ranked.indexOf(k);

    return ranked[Math.max(rank(leftStatus.status), rank(rightStatus.status))];
  })();

  const [doneCallback, setDoneCallback] = useState<undefined | (() => Promise<void>)>();

  return (
    <Modal isOpen>
      <ModalBox>
        <ModalHeader>
          {(() => {
            switch (status) {
              case 'idle':
              case 'recording':
              case 'uploading': return (
                <span className="tw-flex tw-items-center tw-gap-2">
                  <i className="fa fa-circle-o-notch fa-spin" />
                  <span>
                    {__('Uploading')}
                  </span>
                </span>
              );

              case 'uploaded': return (
                <span className="tw-flex tw-items-center tw-gap-2">
                  <span>
                    {__('Uploaded')}
                  </span>
                </span>
              );

              case 'error': return (
                <span className="tw-flex tw-items-center tw-gap-2">
                  <i className="fa fa-circle-o-notch fa-spin" />
                  <span>
                    {__('Error')}
                  </span>
                </span>
              );
            }
          })()}
        </ModalHeader>

        {(() => {
          switch (status) {
            case 'idle':
            case 'recording':
            case 'uploading': {
              return (
                <p className="tw-text-sm tw-text-gray-600">
                  {__('Please wait whilst your recording is uploaded. Do not close this window.')}
                </p>
              );
            }
          }
        })()}

        <div className="tw-flex tw-flex-col tw-gap-4">
          <div>
            <progress className="tw-progress tw-progress-info h-1" value={progress} max={total} />
          </div>
        </div>

        <hr className="tw-my-4" />

        {'commentId' in vsr.scope ? (
          <div className="tw-mb-4">
            <CommentFormFields commentId={vsr.scope.commentId} setSubmitCallback={(cb) => setDoneCallback(() => cb)} />
          </div>
        ) : null}

        {(() => {
          switch (status) {
            case 'uploaded': {
              const handleDoneClick = () => {
                if (doneCallback) {
                  doneCallback().then(() => {
                    onDoneClick();
                  });
                } else {
                  onDoneClick();
                }
              };

              return (
                <div className="tw-flex tw-justify-start">
                  <button type="button" className="tw-btn tw-btn-block tw-btn-success" onClick={handleDoneClick}>
                    {__('Done')}
                  </button>
                </div>
              );
            }

            default: {
              const handleDeleteClick = () => {
                if (window.confirm(
                  __('Are you sure you want to cancel this upload and delete the recording?')
                )) {
                  onCancelClick();
                }
              };

              return (
                <div className="tw-flex tw-justify-end">
                  <button type="button" className="tw-link tw-link-hover tw-text-error tw-flex tw-gap-1 tw-items-center" onClick={handleDeleteClick}>
                    <i className="fa fa-trash" />
                    {__('Delete recording')}
                  </button>
                </div>
              );
            }
          }
        })()}
      </ModalBox>
    </Modal>
  );
};

export default MediaUploadingModal;
