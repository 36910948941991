import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FormTimerChoice from './FormTimerChoice';

const FormTimerElement = props => {
  const { formAnswer, onChange } = props,
        { field } = props.formElement,
        { choices } = field.type.timer;

  const timerValueMap =
    (formAnswer.timer_value || []).reduce((acc, timerValue) => {
      acc[timerValue.choice] = timerValue;
      return acc;
    }, {});

  const handleTimerValueChange = useCallback(tv => {
    onChange(fa => {
      const tvMap = (fa.timer_value || []).reduce((acc, timerValue) => {
        acc[timerValue.choice] = timerValue;
        return acc;
      }, {});

      tvMap[tv.choice] = tv;

      return {
        ...fa,
        timer_value: Object.values(tvMap)
      };
    });
  }, [onChange]);

  return (
    <div>
      {choices.map((choice, i) => {
        const timerValue = timerValueMap[choice] || { choice, intervals: [] };

        return (
          <FormTimerChoice key={i} timerValue={timerValue} onChange={handleTimerValueChange} />
        );
      })}
    </div>
  );
};

FormTimerElement.propTypes = {
  formAnswer: PropTypes.object.isRequired,
  formElement: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FormTimerElement;
