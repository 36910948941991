/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import useCreateGroupImageMutation from '../../../../common/hooks/api/groups/useCreateGroupImageMutation';
import { Group, ImageType } from '../../../../common/types/Atlas';
import useHermesUploader from '../../../video-editor/video-uploader/use-hermes-uploader';
import { btnBaseClass, btnColorClass } from '../shared';
import GroupLoadingLogo from './LoadingLogo';
import GroupLogoImage from './LogoImage';

interface GroupLogoProps {
  group: Group;
  editable?: boolean;
}

const GroupLogo = (props: GroupLogoProps) => {
  const { group, editable } = props;

  const [logoUploading, setLogoUploading] = useState(false);
  const [uploaderState, uploader] = useHermesUploader();
  const createGroupImageMutation = useCreateGroupImageMutation();

  const onLogoDrop = useCallback(([file]: File[]) => {
    if (!file) { return; }
    if (logoUploading) { return; }
    if (uploaderState.status === 'uploading') { return; }

    setLogoUploading(true);

    createGroupImageMutation.mutateAsync({
      params: { groupId: group.id },
      body: {
        type: ImageType.GroupLogo,
      },
    }).then(async ({ data: image, metadata }) => {
      const mediaKey = image.media_key;
      if (!mediaKey) { return; }

      const { hermes } = metadata;
      await uploader({ mediaKey, file, uploadUrl: `${hermes.upload_uri}/` });
    }).finally(() => {
      setLogoUploading(false);
    });
  }, [createGroupImageMutation, group.id, logoUploading, uploader, uploaderState.status]);

  const logoDropzone = useDropzone({
    onDrop: onLogoDrop,
    maxFiles: 1,
    noClick: true,
    accept: {
      'image/*': [],
    },
  });

  const $groupLogo = (() => {
    if (logoUploading) {
      return (
        <GroupLoadingLogo />
      );
    }

    return (
      <GroupLogoImage logo={group.logo} />
    );
  })();

  const $groupLogoButton = (() => {
    if (!editable) { return null; }

    const bannerBtnBaseClass = 'tw-text-2xs tw-absolute tw-bottom-0 tw-left-0 tw-right-0';

    if (logoDropzone.isDragActive) {
      return (
        <button
          type="button"
          className={`${btnBaseClass} ${bannerBtnBaseClass} tw-bg-success tw-text-success-content`}
          onClick={() => logoDropzone.open()}
        >
          {__('Drop image here to upload')}
        </button>
      );
    }

    return (
      <button
        type="button"
        className={`${btnBaseClass} ${btnColorClass} ${bannerBtnBaseClass}`}
        onClick={() => logoDropzone.open()}
      >
        {__('Click here to upload a logo')}
      </button>
    );
  })();

  return (
    <div className={`tw-rounded tw-bg-white tw-shadow tw-p-1 ${logoDropzone.isDragActive ? 'tw-outline tw-outline-success' : ''}`} {...logoDropzone.getRootProps()}>
      <input {...logoDropzone.getInputProps()} />
      <div className="h-100 tw-relative tw-rounded-sm tw-overflow-hidden">
        {$groupLogo}
        {$groupLogoButton}
      </div>
    </div>
  );
};

export default GroupLogo;
