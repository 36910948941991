import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import withCurrentUser from '../common/hooks/withCurrentUser';
import { withQueryClient } from '../common/hooks/withQueryClient';

const App = () => (
  <RouterProvider router={router} />
);

export default withQueryClient(withCurrentUser(App));
