import React, { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useState } from 'react';

import classNames from 'classnames';
import { debounce } from '../../common/utils/utils';
import Spinner from '../../common/components/Spinner';

const insertBetween = (oldVal: string, newVal: string, startPos: number, endPos: number) => {
  const len = oldVal.length;
  const before = oldVal.slice(0, startPos);
  const after = oldVal.slice(endPos + 1, len);

  return [before, newVal, after].join('');
};

enum KeyCode {
  BackSpace = 8,
  DownArrow = 40,
  Escape = 27,
  Tab = 9,
  UpArrow = 38
}

interface QueryResult {
  id: number;
  canonical_form: string;
  text: string;
  img_url?: string;
}

interface MentionModel {
  id: number;
  canonicalForm: string;
  startPos: number;
  endPos: number;
}

interface MentionState {
  loading?: boolean;

  mentions: MentionModel[];
  position: {
    top: number;
    left: number;
  };

  query: string;
  results?: QueryResult[];
  resultSelected?: number;
  startPos: number;
  status: Status;
  render: string;
}

enum Status {
  Idle,
  Capturing
}


interface MentionProps {
  delimiter: string;
  domNodes: {
    overlay: HTMLElement;
    textArea: HTMLTextAreaElement;
  }
  searchUrl: string;
  render(html: string): void;
  pick(value: string): void;
  focus(): void;
}

const Mention = React.forwardRef((props: MentionProps, ref): JSX.Element | null => {
  const { textArea } = props.domNodes;

  const [state, setState] = useState<MentionState>({
    position: { top: 0, left: 0 },
    mentions: [],
    startPos: 0,
    query: '',
    status: Status.Idle,
    render: ''
  });

  useLayoutEffect(() => {
    props.render(state.render);
  }, [state.render]);

  const handlePositionResults = useCallback(() => {
    const { bottom, left } = textArea.getBoundingClientRect();
    setState(s => ({ ...s, position: { top: bottom, left } }));
  }, [textArea]);

  useLayoutEffect(() => {
    handlePositionResults();
  }, [state.results]);

  const performQuery = useCallback(debounce((query: string) => {
    const url = `${props.searchUrl}&query=${query}`;

    fetch(url).then(r => {
      if (r.status !== 200) {
        throw new Error('fetch failed');
      }

      return r.json();
    }).then(results => {
      setState(s => ({ ...s, results, loading: false, resultSelected: undefined }));
    });
  }, 1000), [props.searchUrl]);

  useEffect(() => {
    if (state.status !== Status.Capturing) { return; }

    setState(s => ({ ...s, loading: true }));
    performQuery(state.query);
  }, [state.query, state.status, performQuery]);

  const reset = () => {
    setState(s => ({
      ...s,
      status: Status.Idle,
      query: '',
      results: undefined,
      resultSelected: undefined,
    }))
  };

  const renderMentions = (value: string, mentions: MentionModel[]) => {
    const template = (mention: MentionModel) => (
      `<a class="discussion-mention" data-user-id="${mention.id}">${mention.canonicalForm}</a>`
    );

    const mentionsHtml = mentions.reduce<string>((html, mention) => {
      return html.replace(new RegExp(mention.canonicalForm, 'g'), template(mention));
    }, value);

    return mentionsHtml;
  };

  const updateMentions = (newValue: string, newMention: MentionModel | null, between: Function, shift: Function) => {
    const withoutOverlap = (mention: MentionModel) => !between(mention.startPos, mention.endPos);
    const withNewOffset = (mention: MentionModel) => shift(mention.startPos, mention.endPos, mention)

    const shiftedMentions = state.mentions.filter(withoutOverlap).map<MentionModel>(withNewOffset);

    const newMentions =
      !newMention ? shiftedMentions : [newMention, ...shiftedMentions];

    setState(s => ({
      ...s,
      mentions: newMentions,
      render: renderMentions(newValue, newMentions)
    }));
  };

  const handleBackSpace = (position: number) => {
    const between = (startPos: number, endPos: number) => {
      if (position === 0) { return false; }
      return position > startPos && (position - 1) <= endPos;
    };

    const shift = (startPos: number, endPos: number, mention: MentionModel) => {
      if ((position !== 0) && (position <= startPos)) {
        return {
          ...mention,
          startPos: startPos -1,
          endPos: endPos - 1
        };
      } else {
        return mention;
      }
    };

    const newValue = textArea.value;
    updateMentions(newValue, null, between, shift);
  };

  const handleCut = (cutEvent: { startPos: number, endPos: number }) => {
    const realStartPos = Math.min(cutEvent.startPos, cutEvent.endPos);
    const realEndPos = Math.max(cutEvent.startPos, cutEvent.endPos);
    const valueLength = realEndPos - realStartPos;
    const between = (startPos: number, endPos: number) =>
      realStartPos > startPos && realStartPos <= endPos;

    const shift = (startPos: number, endPos: number, mention: MentionModel) => {
      if ((realStartPos !== 0) && (realStartPos <= startPos)) {
        return {
          ...mention,
          startPos: startPos - valueLength,
          endPos: endPos - valueLength
        };
      } else {
        return mention;
      }
    };

    const newValue = textArea.value;
    updateMentions(newValue, null, between, shift);
    props.pick(newValue);
    reset();
  };

  const handleArrow = (shift: { (n: number): number }): void => {
    if (!state.resultSelected) {
      setState(s => ({
        ...s,
        resultSelected: s.results?.[0]?.id
      }));
    } else {
      const i = state.results?.findIndex(r => r.id === state.resultSelected);
      if (i === undefined) { return; }
      const result = state.results?.[shift(i)];
      if (result) {
        setState(s => ({
          ...s,
          resultSelected: result.id
        }));
      }
    }
  };

  const handleEscape = () => {
    reset();
  };

  const handleOtherKeys = (position: number) => {
    const between = (startPos: number, endPos: number) =>
      position > startPos && position <= endPos;

    const shift = (startPos: number, endPos: number, mention: MentionModel) => {
      if (position <= startPos) {
        return {
          ...mention,
          startPos: startPos + 1,
          endPos: endPos + 1
        };
      } else {
        return mention;
      }
    };

    const newValue = textArea.value;
    updateMentions(newValue, null, between, shift);
  };

  const handlePaste = (pasteEvent: { startPos: number, value: string }) => {
    const valueLength = pasteEvent.value.length;
    const between = (startPos: number, endPos: number) =>
      pasteEvent.startPos > startPos && pasteEvent.startPos <= endPos;

    const shift = (startPos: number, endPos: number, mention: MentionModel) => {
      if (pasteEvent.startPos <= startPos) {
        return {
          ...mention,
          startPos: startPos + valueLength,
          endPos: endPos + valueLength
        };
      } else {
        return mention;
      }
    }

    const newValue = textArea.value;

    updateMentions(newValue, null, between, shift);
    props.pick(newValue);
    reset();
  };

  const handlePick = (result: QueryResult) => {
    const value = textArea.value;
    const canonicalFormLength = result.canonical_form.length;
    const newMention: MentionModel = {
      id: result.id,
      canonicalForm: result.canonical_form,
      startPos: state.startPos,
      endPos: state.startPos + canonicalFormLength - 1
    };
    const between = (startPos: number, endPos: number) =>
      newMention.startPos > startPos && newMention.startPos <= endPos;
    const shift = (startPos: number, endPos: number, mention: MentionModel) => {
      if (newMention.startPos < startPos) {
        return {
          ...mention,
          startPos: startPos + canonicalFormLength,
          endPos: endPos + canonicalFormLength
        };
      } else {
        return mention;
      }
    };

    const newValue = insertBetween(value, `${result.canonical_form} `, state.startPos, state.startPos + state.query.length - 1);

    updateMentions(newValue, newMention, between, shift);
    props.pick(newValue);
    props.focus();
    reset();
  };

  const handleQuery = () => {
    const value = textArea.value;
    const query = value.slice(state.startPos, textArea.selectionStart);
    setState(s => ({ ...s, query }));
  };

  const handleTab = () => {
    if (state.results && state.resultSelected) {
      const result = state.results.find(r => r.id === state.resultSelected);
      if (!result) { return; }
      handlePick(result);
    } else if (state.results) {
      handlePick(state.results[0]);
    }
  };

  const startCapturing = () => {
    setState(s => ({
      ...s,
      startPos: textArea.selectionStart,
      status: Status.Capturing
    }));
  };

  useImperativeHandle(ref, () => ({
    cut(cutEvent: { startPos: number, endPos: number }) {
      handleCut(cutEvent);
    },
    keyPress(keyPressEvent: { startPos: number, keyCode: number }) {
      const { startPos, keyCode } = keyPressEvent;

      if (keyCode === props.delimiter.charCodeAt(0)) {
        startCapturing();
        return;
      }

      switch (state.status) {
        case (Status.Idle): {
          switch (keyPressEvent.keyCode) {
            case (KeyCode.BackSpace): {
              handleBackSpace(startPos);
              return;
            }
            default: {
              handleOtherKeys(startPos);
              return;
            }
          }
        }

        case (Status.Capturing): {
          const currentPos = textArea.selectionStart;
          const currentLength = state.query.length;
          const nextIndex = state.startPos + currentLength + 1;
          const outOfBounds = currentPos <= state.startPos || currentPos > nextIndex;

          if (outOfBounds) {
            reset();
            return;
          } else {
            switch (keyPressEvent.keyCode) {
              case (KeyCode.Tab): {
                handleTab();
                return;
              }
              case (KeyCode.Escape): {
                handleEscape();
                return;
              }
              case (KeyCode.UpArrow): {
                handleArrow(n => n - 1);
                return;
              }
              case (KeyCode.DownArrow): {
                handleArrow(n => n + 1);
                return;
              }
              default: {
                handleQuery();
                return;
              }
            }
          }
        }
      }
    },
    paste(pasteEvent: { startPos: number, value: string }) {
      handlePaste(pasteEvent);
    },
    positionResults() {
      handlePositionResults();
    }
  }));

  if (state.status === Status.Idle) { return null; }

  const results = state.results || [];

  return (
    <div
      className="discussion-mention-results-container"
      style={{
        top: `${state.position.top}px`,
        left: `${state.position.left}px`
      }}>

      <ul className="discussion-mention-results">
        {state.loading ? (
          <li>
            <div className="d-flex justify-content-center p-1">
              <Spinner size="sm" color="info" />
            </div>
          </li>
        ) : !results.length ? (
          <li>
            <div className="text-muted text-center p-1">
              {__('No users found')}
            </div>
          </li>
        ) : results.map(result => (
          <li key={result.id} className={classNames({ 'discussion-mention-result-selected': state.resultSelected === result.id })} onClick={() => handlePick(result)}>
            {result.img_url ? (
              <img src={result.img_url} />
            ): null}

            <span>
              {result.text}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
});

export default Mention;
