import React from 'react';
import {
  Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import * as Atlas from '../../../common/types/Atlas';
import { colors } from '../../../common/utils/colors';
import { FormReportingFilter } from '../types';
import { truncate } from '../../../common/utils/utils';

interface CounterFormFieldStatsProps {
  field: Atlas.FormField;
  data: Array<{
    filter: FormReportingFilter;
    overview: Atlas.FormFieldOverview;
  }>;
}

const CounterFormFieldStats = (props: CounterFormFieldStatsProps): JSX.Element => {
  const { field } = props;

  const choices = new Set<string>();

  const data = props.data.reduce<Array<{
    filterName: string;
  } & Record<string, number | string>>>((acc, datum) => {
    if ('counter' in datum.overview.stats) {
      acc.push({
        filterName: datum.filter.name,
        ...datum.overview.stats.counter.reduce<Record<string, number>>((acc, choiceStats) => {
          choices.add(choiceStats.choice);
          acc[choiceStats.choice] = choiceStats.count;

          return acc;
        }, {}),
      });
    }

    return acc;
  }, []);

  return (
    <div>
      <p className="tw-text-gray-600">{__('Counter')}</p>
      <p className="tw-mt-0 tw-mb-4 tw-font-bold tw-text-lg">{truncate(field.label, { maxLength: 500 })}</p>

      <hr className="tw-my-4" />

      <ResponsiveContainer height={200} width="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="filterName" name={__('Filter')} />
          <YAxis allowDecimals={false} type="number" name={__('Count')} />
          <Tooltip />
          <Legend formatter={(value: string) => truncate(value, { maxLength: 120 })} />
          {[...choices].map((choice, i) => (
            <Bar key={choice} dataKey={choice} name={choice} fill={colors[i % colors.length]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CounterFormFieldStats;
