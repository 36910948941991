import React from "react";
import { components, OptionProps, SingleValueProps } from "react-select";
import * as Atlas from "../../types/Atlas";
import { AspectRatio } from "../AspectRatio";

export interface FormReflectionOption {
  value: Atlas.ReflectionID;
  formReflection: Atlas.FormReflection;
}

export const FormReflectionSelectOption = (props: OptionProps<FormReflectionOption, false>): JSX.Element => {
  const { formReflection } = props.data as FormReflectionOption;

  const url = formReflection.thumbnail_s3uri?.url ?? '/assets/img/reflections/thumbnail_placeholder.png';

  const userFullname = [formReflection.user?.first_name, formReflection.user?.last_name].filter(Boolean).join(' ');

  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <div className="mr-2" style={{ width: '2rem'}}>
          <AspectRatio ratio="16/9">
            <img src={url} className="p-0 thumbnail rounded" />
          </AspectRatio>
        </div>
        <div>
          <div>{formReflection.name}</div>
          {userFullname ? (
            <small className="text-muted">
              {userFullname}
            </small>
          ) : null}
        </div>
      </div>
    </components.Option>
  );
};

export const FormReflectionSelectSingleValue = (props: SingleValueProps<FormReflectionOption>): JSX.Element => {
  const { formReflection } = props.data;

  const url = formReflection.thumbnail_s3uri?.url ?? '/assets/img/reflections/thumbnail_placeholder.png';

  const userFullname = [formReflection.user?.first_name, formReflection.user?.last_name].filter(Boolean).join(' ');

  return (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center">
        <div className="mr-2" style={{ width: '2rem'}}>
          <AspectRatio ratio="16/9">
            <img src={url} className="p-0 thumbnail rounded" />
          </AspectRatio>
        </div>
        <div>
          <div>{formReflection.name}</div>
          {userFullname ? (
            <small className="text-muted">
              {userFullname}
            </small>
          ) : null}
        </div>
      </div>
    </components.SingleValue>
  );
};

const selectComponents = {
  Option: FormReflectionSelectOption,
  SingleValue: FormReflectionSelectSingleValue
};

export { selectComponents };
