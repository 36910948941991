import { useState, useCallback } from 'react';
import useCreateVideoCaptionMutation from '../../../common/hooks/api/videos/useCreateVideoCaptionMutation';
import useFilePicker from '../../../common/hooks/useFilePicker';
import useHermesUploader from '../../../common/hooks/useHermesUploader';
import { queryClient } from '../../../common/hooks/withQueryClient';
import * as Atlas from '../../../common/types/Atlas';

type VideoCaptionUploaderState = {
  status: 'idle';
} | {
  status: 'creating';
} | {
  status: 'uploading';
} | {
  status: 'success';
} | {
  status: 'failed';
};

export type VideoCaptionUploaderResult = {
  status: 'creating';
  cancelUpload: () => void;
} | {
  status: 'uploading';
  progress: number;
  cancelUpload: () => void;
} | {
  status: 'idle' | 'success' | 'failed';
  uploadFile: (args: { file: File; name?: string; }) => void;
};

const useVideoCaptionUploader = (videoId: Atlas.VideoID): VideoCaptionUploaderResult => {
  const [state, setState] = useState<VideoCaptionUploaderState>({
    status: 'idle',
  });

  const createVideoCaption = useCreateVideoCaptionMutation();
  const uploader = useHermesUploader();

  const { upload, stopUpload } = uploader;
  const uploadFile = useCallback((args: { file: File; name?: string; }) => {
    const { file, name } = args;

    setState({ status: 'creating' });
    createVideoCaption.mutateAsync({
      params: { videoId },
      body: { name: name ?? file.name },
    }).then(async ({ data: attachment, metadata }) => {
      if (!attachment.media_key) { return; }
      const { hermes } = metadata;

      setState({ status: 'uploading' });
      await upload(attachment.media_key, file, `${hermes.upload_uri}/`);
      setState({ status: 'success' });

      if (attachment.reflection_id) {
        void queryClient.invalidateQueries(['reflections', attachment.reflection_id, 'playlist']);
      }
    }).catch(() => {
      setState({ status: 'failed' });
    });
  }, [createVideoCaption, videoId, upload]);

  const cancelUpload = useCallback(() => {
    createVideoCaption.reset();
    stopUpload();
  }, [createVideoCaption, stopUpload]);

  switch (state.status) {
    case 'creating': {
      return {
        status: state.status,
        cancelUpload,
      };
    }

    case 'uploading': {
      return {
        status: state.status,
        progress: uploader.status === 'uploading' ? uploader.progress : 0,
        cancelUpload,
      };
    }

    default: {
      return {
        status: state.status,
        uploadFile,
      };
    }
  }
};

export default useVideoCaptionUploader;
