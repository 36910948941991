import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody } from '../../../common/components/BSPopover';
import TimelineSegments from '../TimelineSegments';

const timeInVideoToVideoSegment = (timeInVideo) => ({
  video_id: timeInVideo.video_id,
  start: timeInVideo.time,
  duration: 0,
});

const FormCounterAnswerChoice = (props) => {
  const { counterValue } = props;

  const [popoverTarget, setPopoverTarget] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleToggle = () => {
    setPopoverOpen((open) => !open);
  };

  return (
    <div>
      <button ref={setPopoverTarget} type="button" className="tw-btn tw-btn-xs tw-whitespace-nowrap tw-mb-2" onClick={handleToggle}>
        <span>
          {counterValue.choice}
        </span>
        &nbsp;
        <div className="tw-badge tw-badge-sm tw-badge-info">
          {counterValue.clicks.length}
        </div>
      </button>

      <Popover target={popoverTarget} placement="bottom" isOpen={popoverOpen}>
        <PopoverBody>
          <TimelineSegments
            timeline={counterValue.clicks.map(timeInVideoToVideoSegment)}
          />
        </PopoverBody>
      </Popover>
    </div>
  );
};

FormCounterAnswerChoice.propTypes = {
  counterValue: PropTypes.object.isRequired,
};

const FormCounterAnswer = (props) => {
  const { formAnswer } = props;

  const counterValue = formAnswer.counter_value || [];

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-1">
      {counterValue.map((cv, i) => (
        <FormCounterAnswerChoice key={i} counterValue={cv} />
      ))}
    </div>
  );
};

FormCounterAnswer.propTypes = {
  formAnswer: PropTypes.object.isRequired,
};

export default FormCounterAnswer;
