import React from 'react';

// UI
import MultipleChoiceQuestion, { MultipleChoiceFormAnswer } from './questions/MultipleChoiceQuestion';
import RatingQuestion, { RatingFormAnswer } from './questions/RatingQuestion';
import TextQuestion, { TextFormAnswer } from './questions/TextQuestion';
import ReflectionQuestion, { ReflectionFormAnswer } from './questions/ReflectionQuestion';
import AttachmentQuestion, { AttachmentFormAnswer } from './questions/AttachmentQuestion';
import * as Atlas from '../../../common/types/Atlas';

type FormAnswerLike =
  AttachmentFormAnswer |
  MultipleChoiceFormAnswer |
  RatingFormAnswer |
  ReflectionFormAnswer |
  TextFormAnswer;

interface AssignmentQuestionProps {
  readOnly?: boolean;
  question: Atlas.FormElement;
  answer?: FormAnswerLike;
  saving?: boolean;
  saved?: boolean;
  showValidation?: boolean;
  onInit?(fa: FormAnswerLike): void;
  onAnswerChange?(fa: FormAnswerLike): void;
}

const AssignmentQuestion = (props: AssignmentQuestionProps): JSX.Element => {
  const { field } = props.question,
        type = Object.keys(field.type)[0];

  const $question = (() => {
    switch (type) {
      case 'multiple_choice': {
        const answer = props.answer as MultipleChoiceFormAnswer | undefined;
        return (
          <MultipleChoiceQuestion
            readOnly={props.readOnly}
            question={props.question}
            answer={answer}
            showValidation={props.showValidation}
            onAnswerChange={props.onAnswerChange}
          />
        );
      }

      case 'rating': {
        const answer = props.answer as RatingFormAnswer | undefined;
        return <RatingQuestion {...props} answer={answer} />;
      }

      case 'text': {
        const answer = props.answer as TextFormAnswer | undefined;
        return (
          <TextQuestion
            readOnly={props.readOnly}
            question={props.question}
            answer={answer}
            showValidation={props.showValidation}
            onAnswerChange={props.onAnswerChange}
          />
        );
      }

      case 'reflection': {
        const answer = props.answer as ReflectionFormAnswer | undefined;
        return (
          <ReflectionQuestion
            readOnly={props.readOnly}
            question={props.question}
            answer={answer}
            showValidation={props.showValidation}
            onAnswerChange={props.onAnswerChange}
          />
        );
      }

      case 'attachment': {
        const answer = props.answer as AttachmentFormAnswer | undefined;
        return (
          <AttachmentQuestion
            readOnly={props.readOnly}
            question={props.question}
            answer={answer}
            showValidation={props.showValidation}
            onAnswerChange={props.onAnswerChange}
          />
        );
      }

      default:
        return null;
    }
  })();

  return (
    <div>
      <div className="mb-2">
        <div className="d-flex justify-content-between tw-gap-2">
          <div>
            <h4 className="my-0 !tw-text-lg">
              {field.label}
            </h4>
            {field.instructions ? (
            <p className="!tw-mb-0">{field.instructions}</p>
            ) : null}
          </div>
          <div className="tw-flex tw-flex-col tw-shrink-0 tw-items-end">
            {field.required ? (
              <div>
                <small className="text-muted font-italic">* {__('Required')}</small>
              </div>
            ) : null}

            {!props.readOnly && props.saving ? (
              <div>
                <small className="text-muted">{__('Saving draft')}...</small>
              </div>
            ) : null}
            {!props.readOnly && props.saved ? (
              <div>
                <small className="text-muted">{__('Draft saved')}</small>
                <i className="fa fa-check text-muted tw-ml-1"></i>
              </div>
            ) :null}
          </div>

        </div>
      </div>


      <div className="tw-divider tw-my-2"></div>

      <div>
        {$question}
      </div>
    </div>
  );
};

export default AssignmentQuestion;
