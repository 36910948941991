import { useMutation } from 'react-query';
import { arrayMoveImmutable as move } from 'array-move';
import { FindReflectionResponse, FindReflectionsResponse } from '../../../api/reflections';
import { reorderReflectionVideo, ReorderReflectionVideoArguments, ReorderReflectionVideoResponse } from '../../../api/videos/[videoId]/reorder';
import { queryClient } from '../../withQueryClient';
import * as Atlas from '../../../types/Atlas';
import ApiError from '../../../api/error';
import { FindReflectionPlaylistResponse } from '../../../api/reflections/[reflectionId]/playlist';

interface Context {
  previousReflectionData?: FindReflectionResponse;
}

const useReorderVideoMutation = () => {
  const mutation = useMutation<
    ReorderReflectionVideoResponse,
    ApiError,
    ReorderReflectionVideoArguments,
    Context
  >(
    reorderReflectionVideo,
    {
      onMutate: async (args) => {
        await queryClient.cancelQueries(['reflections', args.params.reflectionId]);

        const nextContext: Context = {};

        const previousReflectionData = queryClient.getQueryData<FindReflectionResponse>(['reflections', args.params.reflectionId]);
        if (previousReflectionData) {
          const reflection = previousReflectionData.data;
          const from = reflection.video_ids.indexOf(args.params.videoId);
          const nextVideoIds = move(reflection.video_ids, from, args.body.to);
          const nextReflection: Atlas.ExpandedReflection = {
            ...reflection,
            video_ids: nextVideoIds,
          };

          queryClient.setQueryData<FindReflectionResponse>(['reflections', args.params.reflectionId], { data: nextReflection });

          nextContext.previousReflectionData = previousReflectionData;
        }

        return nextContext;
      },
      onError: (_error, args, context) => {
        if (context?.previousReflectionData) {
          queryClient.setQueryData<FindReflectionResponse>(['reflections', args.params.reflectionId], context.previousReflectionData);
        }

        void queryClient.invalidateQueries<FindReflectionsResponse>(['reflections', args.params.reflectionId], { exact: true });
        void queryClient.invalidateQueries<FindReflectionPlaylistResponse>(['reflections', args.params.reflectionId, 'playlist']);
      },
      onSuccess: (result) => {
        const reflection = result.data;

        void queryClient.invalidateQueries<FindReflectionsResponse>(['reflections', reflection.id], { exact: true });
        void queryClient.invalidateQueries<FindReflectionPlaylistResponse>(['reflections', reflection.id, 'playlist']);
      },
    },
  );

  return mutation;
};

export default useReorderVideoMutation;
