import React from 'react';
import { useParams } from 'react-router-dom';
import { ReflectionFilesWrapper } from '../../../../modules/reflections/reflection-files/ReflectionFiles';

const ReflectionFilesPage = (): JSX.Element => {
  const params = useParams<{ reflectionId: string; }>();
  const reflectionId = Number(params.reflectionId);

  return (
    <ReflectionFilesWrapper
      reflectionId={reflectionId}
    />
  );
};

export default ReflectionFilesPage;
