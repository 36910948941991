import React, { useState } from 'react';
import Spinner from '../../../common/components/Spinner';
import useVideoCommentsQuery from '../../../common/hooks/api/useVideoCommentsQuery';
import * as Atlas from '../../../common/types/Atlas';
import VideoComment from './VideoComment';

interface VideoCommentsProps {
  videoId: Atlas.VideoID;
  readOnly?: boolean;
  ownerId?: Atlas.UserID;
  isEmbedded?: boolean;
}

const VideoComments = (props: VideoCommentsProps) => {
  const { videoId, readOnly, ownerId, isEmbedded } = props;

  const [pollComments, setPollComments] = useState(false);
  const commentsQuery = useVideoCommentsQuery({
    videoId,
    per_page: -1,
  }, {
    refetchInterval: pollComments ? 60000 : undefined,
  });

  const comments = commentsQuery.data?.data ?? [];
  const commentExistsWithNoMedia = comments.some((comment) => comment.type === Atlas.CommentType.Media && !comment.video_media);

  if (!pollComments && commentExistsWithNoMedia) {
    setPollComments(true);
  } else if (pollComments && !commentExistsWithNoMedia) {
    setPollComments(false);
  }

  return (
    <div>
      {(() => {
        switch (commentsQuery.status) {
          case 'loading': return (
            <div className="tw-text-center">
              <Spinner color="info" />
            </div>
          );

          case 'success': {
            const paired = (() => {
              const comments = commentsQuery.data?.data?.filter((comment) => (
                !comment.parent_id
              )).sort((a, b) => (
                (a.start ?? Infinity) - (b.start ?? Infinity)
              )) ?? [];

              const replies = commentsQuery.data?.data?.filter((comment) => (
                !!comment.parent_id
              )).sort((a, b) => (
                a.created_at.valueOf() - b.created_at.valueOf()
              )) ?? [];

              return comments.map((comment) => ({
                comment,
                replies: replies.filter((reply) => reply.parent_id === comment.id),
              }));
            })();

            return (
              <>
                <div className="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4 tw-peer">
                  {paired.map(({ comment, replies }) => (
                    <VideoComment
                      key={comment.id}
                      comment={comment}
                      replies={replies}
                      readOnly={readOnly}
                      ownerId={ownerId}
                      isEmbedded={isEmbedded}
                    />
                  ))}
                </div>

                <div className="tw-hidden peer-empty:tw-block tw-text-gray-600">
                  {__('There are no comments for this video.')}
                </div>
              </>
            );
          }

          default: return (
            <div>{__('Oops! Something went wrong.')}</div>
          );
        }
      })()}
    </div>
  );
};

export default VideoComments;
