import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import DeletePageButton from '../buttons/DeletePageButton';
import PublishPageButton from '../buttons/PublishButton';
import RenamePageButton from '../buttons/RenamePageButton';

const GroupMenuHeading: React.FC<{
  page: Atlas.Page;
  editable?: boolean;
}> = (props) => {
  const { page, editable } = props;

  return (
    <div className="tw-flex tw-items-center">
      <p
        className={`
          tw-flex-1
          tw-px-4 tw-py-2 tw-text-sm tw-font-semibold
          ${page.publish === Atlas.PublishStatus.Unpublished ? 'tw-italic' : ''}
        `}
      >
        {page.title}
      </p>

      {editable ? (
        <div className="px-1">
          <div className="tw-dropdown tw-dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="tw-btn tw-btn-ghost tw-text-sm tw-px-3 tw-py-2 tw-block tw-min-h-0 tw-h-auto tw-text-start tw-font-normal"
            >
              <i className="fa fa-cog" />
            </div>

            <ul
              tabIndex={0}
              className="tw-dropdown-content tw-z-50 tw-menu tw-p-2 p-_ tw-bg-base-100 tw-rounded-box tw-shadow tw-w-36">
              <li>
                <RenamePageButton page={page} />
              </li>
              <li>
                <PublishPageButton page={page} />
              </li>
              <li>
                <DeletePageButton page={page} />
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GroupMenuHeading;
