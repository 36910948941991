import { useMutation, UseMutationResult } from 'react-query';
import { createGroupImage, CreateGroupImageArguments, CreateGroupImageResponse } from '../../../api/groups/[groupId]/images';
import { queryClient } from '../../withQueryClient';

const useCreateGroupImageMutation = (): UseMutationResult<CreateGroupImageResponse, unknown, CreateGroupImageArguments, unknown> => useMutation(
  createGroupImage,
  {
    onSuccess: (result) => {
      const image = result.data;
      queryClient.setQueryData(['images', image.id], result);

      if (image.group_id) {
        void queryClient.invalidateQueries(['groups', image.group_id], { exact: true });
      }
    },
  },
);

export default useCreateGroupImageMutation;
