import React, { useCallback, useState } from 'react';
import * as Atlas from '../../common/types/Atlas';
import AttachmentFormFieldStats from './stats/AttachmentFormFieldStats';
import CounterFormFieldStats from './stats/CounterFormFieldStats';
import MultipleChoiceFormFieldStats from './stats/MultipleChoiceFormFieldStats';
import RatingFormFieldStats from './stats/RatingFormFieldStats';
import ReflectionFormFieldStats from './stats/ReflectionFormFieldStats';
import TextFormFieldStats from './stats/TextFormFieldStats';
import TimerFormFieldStats from './stats/TimerFormFieldStats';
import UrlFormFieldStats from './stats/UrlFormFieldStats';
import { FormReportingFilter } from './types';
import DataFetcher from './DataFetcher';
import Spinner from '../../common/components/Spinner';

interface FormOverviewState {
  overviews: Record<string, {
    loading: boolean;
    data?: Atlas.FormFieldOverview[]
  }>;
  overviewsLoading: boolean;
}

interface FormOverviewProps {
  formId: Atlas.FormID;
  form?: Atlas.Form;
  filters: FormReportingFilter[];
}

const renderStats = (
  fe: Atlas.FormElement,
  data: Array<{
    filter: FormReportingFilter;
    overview: Atlas.FormFieldOverview;
  }>,
) => {
  const { field } = fe;
  const { type } = field;

  if ('text' in type) {
    return <TextFormFieldStats field={field} data={data} />;
  } if ('rating' in type) {
    return <RatingFormFieldStats field={field} data={data} />;
  } if ('multiple_choice' in type) {
    return <MultipleChoiceFormFieldStats field={field} data={data} />;
  } if ('counter' in type) {
    return <CounterFormFieldStats field={field} data={data} />;
  } if ('timer' in type) {
    return <TimerFormFieldStats field={field} data={data} />;
  } if ('attachment' in type) {
    return <AttachmentFormFieldStats field={field} data={data} />;
  } if ('url' in type) {
    return <UrlFormFieldStats field={field} data={data} />;
  } if ('reflection' in type) {
    return <ReflectionFormFieldStats field={field} data={data} />;
  }
};

const FormOverview = (props: FormOverviewProps): JSX.Element => {
  const { formId, form, filters } = props;

  const [state, setState] = useState<FormOverviewState>({
    overviews: {},
    overviewsLoading: false,
  });

  const handleFilterDataLoading = useCallback((filter: FormReportingFilter) => {
    setState((s) => {
      const nextOverviews = { ...s.overviews };
      const prevData = nextOverviews[filter.id]?.data;
      nextOverviews[filter.id] = { loading: true, data: prevData };
      return { ...s, overviews: nextOverviews };
    });
  }, []);

  const handleFilterDataLoaded = useCallback((filter: FormReportingFilter, data: Atlas.FormFieldOverview[]) => {
    setState((s) => {
      const nextOverviews = { ...s.overviews };
      nextOverviews[filter.id] = { loading: false, data };
      return { ...s, overviews: nextOverviews };
    });
  }, []);

  const handleFilterUnmount = useCallback((filterId: string) => {
    setState((s) => {
      const nextOverviews = { ...s.overviews };
      delete nextOverviews[filterId];
      return { ...s, overviews: nextOverviews };
    });
  }, []);

  const isLoading = filters.some((filter) => state.overviews[filter.id]?.loading);

  return (
    <div>
      {filters.map((filter) => (
        <DataFetcher
          key={filter.id}
          formId={formId}
          filter={filter}
          onLoading={handleFilterDataLoading}
          onLoaded={handleFilterDataLoaded}
          onUnmount={handleFilterUnmount}
        />
      ))}

      {form ? form.elements.map((fe) => {
        const data = Object.entries(state.overviews).reduce<Array<{
          filter: FormReportingFilter;
          overview: Atlas.FormFieldOverview;
        }>>((acc, [filterId, overviews]) => {
          overviews.data?.forEach((overview) => {
            if (overview.field.name !== fe.field.name) { return; }
            const filter = filters.find((f) => f.id === filterId);
            if (!filter) { return; }

            acc.push({ filter, overview });
          });

          return acc;
        }, []);

        const $stats = renderStats(fe, data);
        if (!$stats) { return null; }

        return (
          <div key={fe.field.name} className={`tw-mb-4 tw-rounded-xl tw-shadow tw-bg-base-100 tw-p-4 tw-transition-opacity ${isLoading ? 'tw-opacity-50' : 'tw-opacity-100'}`}>
            {$stats}
          </div>
        );
      }) : (
        <div className="tw-flex tw-items-center tw-justify-center tw-py-5">
          <Spinner color="info" />
        </div>
      )}
    </div>
  );
};

export default FormOverview;
