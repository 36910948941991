import React, { useCallback, useMemo, RefObject } from 'react';
import {
  MentionsInput,
  Mention,
  SuggestionDataItem,
  DataFunc,
} from 'react-mentions';
import { debounce } from 'ts-debounce';
import { findDiscussionUsers } from '../../../common/api/discussions/[discussionId]/users';
import { Col } from '../../../common/components/Col';
import { Row } from '../../../common/components/Row';
import * as Atlas from '../../../common/types/Atlas';
import { portraitPlaceholderUrl } from '../../../common/utils/placeholders';
import { mentionStyle } from '../styles/mentions';

interface DiscussionMentionProps {
  autoFocus?: boolean;
  discussionId: Atlas.DiscussionID;
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isLocked?: Atlas.DiscussionLocked;
}

interface EmojiProps {
  emojiRef?: RefObject<HTMLInputElement>;
}

interface UserSuggestionDataItem extends SuggestionDataItem {
  display: string;
  user: Atlas.DiscussionMentionUser;
}

const DiscussionMention = React.forwardRef<HTMLInputElement, DiscussionMentionProps & EmojiProps>((props, ref: any): JSX.Element => {
  const {
    discussionId, value, onChange, onFocus, onBlur, emojiRef, isLocked, autoFocus,
  } = props;

  const handleChange = (event: { target: { value: string } }) => {
    const { value } = event.target;
    onChange(value);
  };

  const searchUsers = useCallback((
    search: string,
    callback: (results: SuggestionDataItem[]) => void,
  ) => {
    findDiscussionUsers({ params: { discussionId, search } }).then((response) => {
      const results: UserSuggestionDataItem[] = response.data.map((user) => ({
        id: user.id,
        display: `@${user.first_name}`,
        user,
      }));

      callback(results);
    }, () => {
      callback([]);
    });
  }, [discussionId]);

  const debouncedSearchUsers = useMemo(() => (
    debounce(searchUsers, 500) as unknown as DataFunc
  ), [searchUsers]);

  const renderSuggestion = (result: SuggestionDataItem) => {
    const { user } = result as unknown as UserSuggestionDataItem;

    return (
      <Row className="mx-n1 align-items-center">
        <Col className="px-1" xs="auto">
          <img
            src={user.avatar.viewable_s3uri?.url ?? portraitPlaceholderUrl}
            className="border rounded-circle"
            style={{
              height: '2rem', width: '2rem', margin: 0, maxWidth: 'none',
            }}
          />
        </Col>
        <Col className="px-1">
          <span>
            {user.first_name}
            {' '}
            {user.last_name}
          </span>
        </Col>
      </Row>
    );
  };

  return (
    <MentionsInput
      autoFocus={autoFocus}
      placeholder={isLocked === "locked" ? __("This discussion is locked") : __("Enter a message here")}
      className="input-block"
      id="discussionInputField"
      inputRef={emojiRef}
      style={mentionStyle}
      value={value}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      allowSpaceInQuery
      disabled={isLocked === 'locked'}
    >
      <Mention
        trigger="@"
        data={debouncedSearchUsers}
        markup={'<a class="discussion-mention" data-user-id="__id__">__display__</a>'}
        renderSuggestion={renderSuggestion}
      />
    </MentionsInput>
  );
});

export default DiscussionMention;
