import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// UI
import { Slider } from '../../../common/components';

// Utils
import { clamp } from '../../../common/utils/utils';

const numberOrMiddle = (maybeN, min, max) => (
  Number.isInteger(maybeN) ? maybeN : Math.floor((min + max) / 2)
);

const FormRatingElement = props => {
  const { formAnswer, onChange } = props,
    { field } = props.formElement;

  const {
    max_label: maxLabel,
    max_ordinal: maxOrdinal,
    min_label: minLabel,
    min_ordinal: minOrdinal
  } = field.type.rating;

  useEffect(() => {
    if (Number.isInteger(formAnswer.value)) { return; }

    onChange(s => {
      const value = numberOrMiddle(s.value, minOrdinal, maxOrdinal);

      return {
        ...s,
        value: clamp(minOrdinal, value, maxOrdinal)
      };
    });
  }, [formAnswer.value, minOrdinal, maxOrdinal]);

  const handleRatingChange = event => {
    const { value } = event.currentTarget;

    onChange(s => ({ ...s, value: Number(value) }));
  };

  const value = numberOrMiddle(formAnswer.value, minOrdinal, maxOrdinal);

  const steps = new Array(maxOrdinal - minOrdinal + 1).fill(null).map((_, n) => n + minOrdinal)

  return (
    <div>
      <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mb-2">
        <div className="tw-text-left tw-truncate">{minLabel}</div>
        <div className="tw-text-right tw-truncate">{maxLabel}</div>
      </div>

      <div>
        <div className="tw-flex tw-justify-between tw-px-3 tw-h-4">
          {steps.map((step) => (
            <div key={step} className="tw-relative">
              <small className="tw-absolute tw-left-0 -tw-translate-x-1/2">
                {step}
              </small>
            </div>
          ))}
        </div>

        <Slider
          type="range"
          min={minOrdinal}
          max={maxOrdinal}
          value={value}
          onChange={handleRatingChange}
        />
      </div>
    </div>
  );
};

FormRatingElement.propTypes = {
  formAnswer: PropTypes.object.isRequired,
  formElement: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FormRatingElement;
