import React from 'react';

// UI
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import AxisTick from '../AxisTick';
import { colors } from '../../../../common/utils/colors';
import * as Atlas from '../../../../common/types/Atlas';

interface Payload {
  name: string;
  value: number;
}

interface FormMultipleChoiceBarChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

const FormMultipleChoiceBarChart = (props: FormMultipleChoiceBarChartProps): JSX.Element => {
  const { formElement, formAnswers = [] } = props;

  if (!('multiple_choice' in formElement.field.type)) {
    throw 'FormFieldType is incompatible with MultipleChoiceFormFieldType';
  }

  const choices = formElement.field.type.multiple_choice.choices.reduce<Record<string, Payload>>((acc, v) => {
    acc[v] = { name: v, value: 0 };
    return acc;
  }, {});

  formAnswers.forEach((fa) => {
    const value = fa.value as string | string[];

    if (Array.isArray(value)) {
      value.forEach((v) => {
        choices[v] ||= { name: v, value: 0 };
        choices[v].value += 1;
      });
      return;
    }

    choices[value] ||= { name: value, value: 0 };
    choices[value].value += 1;
  });

  const data = Object.values(choices);

  return (
    <div>
      <ResponsiveContainer height={200} width="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" name={__('Choice')} interval={0} tick={<AxisTick />} />
          <YAxis allowDecimals={false} type="number" name={__('Count')}>
            <Label angle={-90} value={__('Count')} position="insideLeft" offset={20} style={{ textAnchor: 'middle' }} />
          </YAxis>

          <Tooltip />

          <Bar dataKey="value" name={__('Count')} fill={colors[3]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FormMultipleChoiceBarChart;
