import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { findDiscussionComments, FindDiscussionCommentsParams, FindDiscussionCommentsResponse } from '../../../api/discussions/[discussionId]/comments';

const useFindDiscussionDiscussionCommentsInfiniteQuery = (
  params: Omit<FindDiscussionCommentsParams, 'page'>,
): UseInfiniteQueryResult<FindDiscussionCommentsResponse> => (
  useInfiniteQuery(
    ['discussions', params.discussionId, 'comments', params],
    ({ pageParam = 1 }) => findDiscussionComments({ params: { ...params, page: pageParam } }),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.metadata.current_page + 1;
        if (nextPage > lastPage.metadata.total_pages) { return undefined; }
        return nextPage;
      },
      getPreviousPageParam: (firstPage) => {
        const nextPage = firstPage.metadata.current_page - 1;
        if (nextPage < 1) { return undefined; }
        return nextPage;
      }
    }
  )
);

export default useFindDiscussionDiscussionCommentsInfiniteQuery;
