/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const cardImgVariant = cva('', {
  variants: {
    top: {
      true: 'card-img-top',
    },
    bottom: {
      true: 'card-img-bottom',
    },
  },
});

export type CardImgVariantProps = VariantProps<typeof cardImgVariant>;

type DivProps =
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const CardImg = ({
  top,
  bottom,
  className,
  ...props
}: CardImgVariantProps & DivProps) => (
  <img
    {...props}
    className={cx(className, cardImgVariant({ top, bottom }))}
  />
);
