import React, { useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import CaptionUploaderProgressModal from './uploader-progress-modal';

interface CaptionUploadButtonProps {
  videoId: Atlas.VideoID;
}

const CaptionUploadButton = (props: CaptionUploadButtonProps): JSX.Element => {
  const { videoId } = props;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => setModalOpen(true)}
      >
        <i className="fa fa-cc fa-fw tw-mr-1" />
        {__('Upload Captions')}
      </button>

      {modalOpen ? (
        <CaptionUploaderProgressModal
          videoId={videoId}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export default CaptionUploadButton;
