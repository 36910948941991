import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../../api-parser';
import ApiError from '../../error';
import {
  generateFetchHeaders, jsonHeaders, Metadata, PaginationParams, searchParams,
} from '../../routes';

export const videoCommentsRoute = (args: {
  videoId: Atlas.VideoID
}): string => `/api/v0/videos/${args.videoId}/comments`;

export type FindVideoCommentsParams = {
  videoId: Atlas.VideoID;
} & PaginationParams;

export interface FindVideoCommentsArguments {
  params: FindVideoCommentsParams;
}

export interface FindVideoCommentsResponse {
  data: Atlas.ExpandedComment[];
  metadata: Metadata;
}

export const findVideoComments = async (
  args: FindVideoCommentsArguments,
): Promise<FindVideoCommentsResponse> => {
  const { params } = args;

  const url = [
    videoCommentsRoute({
      videoId: params.videoId,
    }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findVideoComments');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.ExpandedComment>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface CreateVideoCommentParams {
  videoId: Atlas.ReflectionID;
}

export type CreateVideoCommentBody = Required<Pick<Atlas.Comment, 'body'>>
  & Partial<Pick<Atlas.Comment, 'type' | 'start' | 'duration' | 'parent_id' | 'synchronicity' | 'resource_links' | 'privacy'>>;

export interface CreateVideoCommentArgs {
  params: CreateVideoCommentParams;
  body: CreateVideoCommentBody;
}

export interface CreateVideoCommentResponse {
  data: Atlas.Comment;
}

export const createVideoComment = async (
  args: CreateVideoCommentArgs,
): Promise<CreateVideoCommentResponse> => {
  const { videoId } = args.params;

  const url = videoCommentsRoute({ videoId });

  const r = await fetch(url, {
    method: 'POST',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(r.status, 200, 300)) {
    throw new ApiError(r.status, 'createVideoComment');
  }

  const body = await r.json() as { data: object; };
  const data = parseModelObject<Atlas.Comment>(body.data);

  return { data };
};
