import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Popover, PopoverBody } from '../../../common/components/BSPopover';

import VideoTimeRangeSelector from './VideoTimeRangeSelector';
import TimelineSegments from '../TimelineSegments';
import { renderTimestamp } from '../../../common/components/timerTimestamp';
import { mergeIntervals } from '../../../common/utils/utils';
import useRandomId from '../../hooks/useRandomId';

const videoSegmentToTimeInterval = (videoSegment) => ({
  start: {
    video_id: videoSegment.video_id,
    time: videoSegment.start,
  },
  end: {
    video_id: videoSegment.video_id,
    time: videoSegment.start + videoSegment.duration,
  },
  total: videoSegment.duration,
});

const timeIntervalToVideoSegment = (timeInterval) => ({
  video_id: timeInterval.start.video_id,
  start: timeInterval.start.time,
  duration: timeInterval.end.time - timeInterval.start.time,
});

const mergeTimeIntervals = (intervals) => {
  const gtis = intervals.reduce((acc, ti) => {
    if (!acc[ti.start.video_id]) { acc[ti.start.video_id] = []; }

    acc[ti.start.video_id].push(ti);

    return acc;
  }, {});

  return Object.entries(gtis).reduce((acc, [videoId, tis]) => [
    ...acc,
    ...mergeIntervals(
      tis.map((ti) => [ti.start.time, ti.end.time]).sort(([a], [b]) => a - b),
    ).map(([start, end]) => {
      const video_id = Number(videoId);

      return {
        start: { video_id, time: start },
        end: { video_id, time: end },
        total: end - start,
      };
    }),
  ], []);
};

const FormTimerChoice = (props) => {
  const { timerValue, onChange } = props;
  const { choice, intervals: timeIntervals = [] } = timerValue;

  const [currentTimeInterval, setCurrentTimeInterval] = useState(null);
  const id = useRandomId();

  const [popoverTarget, setPopoverTarget] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleTimeRangeTick = useCallback((vs) => {
    const ti = videoSegmentToTimeInterval(vs);
    setCurrentTimeInterval(ti);
  }, []);

  const handleTimeRangeSelect = useCallback((videoSegment) => {
    const timeInterval = videoSegmentToTimeInterval(videoSegment);

    const intervals = mergeTimeIntervals([
      ...timeIntervals,
      timeInterval,
    ]);

    setCurrentTimeInterval(null);
    onChange({ choice, intervals });
  }, [choice, timeIntervals, onChange]);

  useEffect(() => {
    if (!timeIntervals.length && popoverOpen) {
      setPopoverOpen(false);
    }
  }, [timeIntervals, popoverOpen]);

  const handleToggle = () => {
    if (!timeIntervals.length) { return; }

    setPopoverOpen((open) => !open);
  };

  const handleTimelineChange = (timeline) => {
    const intervals = timeline.map(videoSegmentToTimeInterval);

    onChange({ choice, intervals });
  };

  const currentTimeIntervals = (() => {
    if (currentTimeInterval) {
      return mergeTimeIntervals([
        ...timeIntervals, currentTimeInterval,
      ]);
    }

    return timeIntervals;
  })();

  return (
    <div className="tw-flex tw-mb-3">
      <div className="tw-flex tw-items-center tw-flex-1">
        {choice}
      </div>

      <div className="tw-flex tw-items-center tw-gap-2">
        {currentTimeIntervals.length ? (
          <>
            <div
              ref={setPopoverTarget}
              id={id}
              role="button"
              tabIndex={0}
              className="tw-badge tw-badge-info"
              onClick={handleToggle}
            >
              {renderTimestamp(
                currentTimeIntervals
                  .map((ti) => ti.total)
                  .reduce((a, b) => a + b, 0),
              )}
            </div>

            <Popover target={popoverTarget} placement="left" isOpen={popoverOpen}>
              <PopoverBody>
                <TimelineSegments
                  timeline={timeIntervals.map(timeIntervalToVideoSegment)}
                  onChange={handleTimelineChange}
                />
              </PopoverBody>
            </Popover>
          </>
        ) : null}

        <VideoTimeRangeSelector
          className="tw-flex-grow"
          onTick={handleTimeRangeTick}
          onSelect={handleTimeRangeSelect}
        >
          {__('Start Timer')}
        </VideoTimeRangeSelector>
      </div>
    </div>
  );
};

FormTimerChoice.propTypes = {
  timerValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormTimerChoice;
