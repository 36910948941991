import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObjectArray } from '../../../api-parser';
import ApiError from '../../../error';
import { jsonHeaders, Metadata, PaginationParams, searchParams } from '../../../routes';

export const groupFormsRoute = (args: {
  groupId: Atlas.GroupID;
}): string => (
  `/api/v0/groups/${args.groupId}/forms`
);

export type FindGroupFormsParams = PaginationParams & {
  groupId: Atlas.GroupID;
}

export interface FindGroupFormsArguments {
  params: FindGroupFormsParams;
}

export interface FindGroupFormsResponse {
  data: Atlas.Form[];
  metadata: Metadata;
}

export const findGroupForms = async (
  args: FindGroupFormsArguments
): Promise<FindGroupFormsResponse> => {
  const { groupId, ...params } = args.params;

  const url = [
    groupFormsRoute({ groupId }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findGroupForms');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.Form>(body.data);
  const metadata = body.metadata as Metadata;
  
  return { data, metadata };
};