import { useQuery, UseQueryResult } from 'react-query';
import { findExpandedAssignmentSubmissions } from '../../../api/assignments/assignment-submissions';
import * as Atlas from '../../../types/Atlas';
import { Metadata } from '../../../api/routes';

const useFindAssignmentSubmissionsQuery = (
  assignmentId: Atlas.AssignmentID,
  complete?: Atlas.SubmissionComplete,
  userId?: Atlas.UserID,
  page?: number,
  perPage?: number,

): UseQueryResult<{data: Atlas.ExpandedAssignmentSubmission[], metadata: Metadata }> => (
  useQuery(
    ['assignments', assignmentId, 'submissions', { complete, userId, page, perPage }],
    () => findExpandedAssignmentSubmissions({
      params: {
        assignmentId, 
        userId,
        complete,
        page,
        per_page: perPage
      } 
    })
  )
);

export default useFindAssignmentSubmissionsQuery;
