import React, { useState } from 'react';

import {
  reflectionsRoute,
  generateFetchHeaders,
} from '../../../common/api/routes';
import * as Atlas from '../../../common/types/Atlas';
import Spinner from '../../../common/components/Spinner';
import { Button } from '../../../common/components/Button';
import { Alert } from '../../../common/components/BSAlert';

interface BookingFormProps {
  title: string;
  lessonCodeRequired?: boolean;
  onSaved?: (args: { id: Atlas.ReflectionID }) => void;
}

interface BookingFormState {
  saving?: boolean;
  error?: boolean;
  booking: {
    reflection_name: string;
    reflection_lesson_code: string;
    reflection_room: string;
    reflection_description: string;
  }
}

const BookingForm = (props: BookingFormProps): JSX.Element => {
  const { title, lessonCodeRequired } = props;

  const [state, setState] = useState<BookingFormState>({
    booking: {
      reflection_name: '',
      reflection_lesson_code: '',
      reflection_room: '',
      reflection_description: '',
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (state.saving) { return; }

    const { name, value } = event.currentTarget;

    setState((s) => ({
      ...s,
      error: false,
      booking: {
        ...s.booking,
        [name]: value,
      },
    }));
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (state.saving) { return; }

    setState((s) => ({ ...s, saving: true }));
    fetch(reflectionsRoute(), {
      method: 'POST',
      credentials: 'same-origin',
      headers: generateFetchHeaders(),
      body: JSON.stringify({ booking_form: state.booking }),
    }).then((r) => {
      if (r.status !== 200) {
        throw new Error('form-data-save-failed');
      }

      return r.json();
    }).then((reflection) => {
      props.onSaved?.(reflection);
    }, () => {
      setState((s) => ({ ...s, saving: false, error: true }));
    });
  };

  const handleErrorDismiss = () => {
    setState((s) => ({ ...s, error: false }));
  };

  const inputStyles: React.CSSProperties = {
    boxSizing: 'border-box',
    height: '50px',
    width: '100%',
    borderRadius: '8px',
    borderColor: 'none',
    border: 'none',
    marginTop: '10px',
    backgroundColor: 'white',

  };

  return (
    <div style={{ backgroundColor: '#F8F8FB' }}>
      <h4
        className="text-center"
        style={{
          fontWeight: 'bold',
          color: '#363565',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        {title}
      </h4>

      <form onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label>
            <input
              autoFocus
              required
              type="text"
              name="reflection_name"
              value={state.booking.reflection_name}
              onChange={handleInputChange}
              style={inputStyles}
              placeholder={__('Reflection Title')}
              className="form-control"
            />
          </label>
        </div>

        <div className="form-group">
          <label>
            <input
              required={lessonCodeRequired}
              type="text"
              name="reflection_lesson_code"
              value={state.booking.reflection_lesson_code}
              onChange={handleInputChange}
              style={inputStyles}
              placeholder={__('Lesson Code')}
              className="form-control"
            />
          </label>
        </div>

        <div className="form-group">
          <label>
            <input
              type="text"
              name="reflection_room"
              value={state.booking.reflection_room}
              onChange={handleInputChange}
              style={inputStyles}
              placeholder={__('Room Number')}
              className="form-control"
            />
          </label>
        </div>

        <div className="form-group">
          <label>
            <input
              type="text"
              name="reflection_description"
              value={state.booking.reflection_description}
              onChange={handleInputChange}
              style={inputStyles}
              placeholder={__('Additional Details')}
              className="form-control"
            />
          </label>
        </div>

        {state.error ? (
          <Alert color="danger" className="mb-2 alert-dismissible">
            <button type="button" className="close" onClick={handleErrorDismiss}>
              <span>×</span>
            </button>

            <small>
              {__('Oops! Something went wrong.')}
            </small>
          </Alert>
        ) : null}

        <div className="d-flex align-items-center justify-content-end">
          <Button type="submit" block size="large" disabled={state.saving} color="success" className="mt-2">
            {state.saving ? (
              <Spinner size="sm" color="white" className="mx-1" />
            ) : (
              <div>{__('Create')}</div>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BookingForm;
