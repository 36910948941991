import React from 'react';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import * as Atlas from '../../common/types/Atlas';
import { colors, darkColor } from '../../common/utils/colors';

interface RatingsRadarChartProps {
  form: Atlas.Form;
  formData: Atlas.FormData[];
  currentUser?: Atlas.User;
}

interface FormRatingElement {
  formElement: Atlas.FormElement;
  rating: Atlas.RatingFormField;
}

interface GroupedRating {
  minOrdinal: number;
  maxOrdinal: number;
  formRatings: FormRatingElement[];
}

const RatingsRadarChart = (props: RatingsRadarChartProps): JSX.Element => {
  const { form, formData, currentUser } = props;

  const answers = formData.map((fd) => fd.answers).reduce((a, b) => [...a, ...b], []);

  const formRatings = form.elements.reduce<Array<FormRatingElement>>((acc, formElement) => {
    if (!('rating' in formElement.field.type)) { return acc; }

    return [
      ...acc,
      { formElement, rating: formElement.field.type.rating },
    ];
  }, []);

  const groupedFormRatings = Object.values(formRatings.reduce<Record<string, GroupedRating>>((acc, formRating) => {
    const { min_ordinal: minOrdinal, max_ordinal: maxOrdinal } = formRating.rating;

    const key = `${minOrdinal}-${maxOrdinal}`;
    acc[key] ||= { minOrdinal, maxOrdinal, formRatings: [] };
    acc[key].formRatings.push(formRating);

    return acc;
  }, {}));

  const groupedData = groupedFormRatings.map(({ minOrdinal, maxOrdinal, formRatings }) => {
    const data = formRatings.map((rating) => {
      const filteredAnswers = answers.filter((answer) => (
        (answer.name === rating.formElement.field.name)
      ));

      const mine = (() => {
        const values = filteredAnswers.filter((answer) => answer.user_id === currentUser?.id).reduce<number[]>((acc, answer) => {
          if (typeof answer.value !== 'number') { return acc; }
          return [...acc, answer.value];
        }, []);

        const sum = values.reduce((a, b) => a + b, 0);
        const avg = (sum / values.length) || 0;

        return avg;
      })();

      const global = (() => {
        const values = filteredAnswers.reduce<number[]>((acc, answer) => {
          if (typeof answer.value !== 'number') { return acc; }
          return [...acc, answer.value];
        }, []);

        const sum = values.reduce((a, b) => a + b, 0);
        const avg = (sum / values.length) || 0;

        return avg;
      })();

      return {
        name: rating.formElement.field.name,
        label: rating.formElement.field.label,
        mine,
        global,
      };
    });

    return {
      minOrdinal,
      maxOrdinal,
      data,
    };
  });

  return (
    <div>
      {groupedData.length === 0 ? (
        <p className="tw-text-center tw-text-base-300">
          {__('This Insight has no rating questions.')}
        </p>
      ) : null}

      {groupedData.map(({ minOrdinal, maxOrdinal, data }) => (
        <div key={`${minOrdinal}-${maxOrdinal}`} className="tw-my-5">
          <ResponsiveContainer width="100%" height={400}>
            <RadarChart data={data}>
              <PolarGrid gridType="circle" />
              {data.length ? (
                <PolarAngleAxis dataKey="label" fontSize="80%" />
              ) : null}
              <PolarRadiusAxis angle={30} domain={[minOrdinal, maxOrdinal]} stroke={darkColor} fontSize="80%" />
              <Radar
                name={__('Your response (avg)')}
                dataKey="mine"
                stroke={colors[0]}
                strokeWidth={2}
                fill={colors[0]}
                fillOpacity={0.2}
                dot={{ r: 2, fillOpacity: 1 }}
                activeDot={{ r: 4, fillOpacity: 1, stroke: colors[0] }}
              />
              <Radar
                name={__('Global (avg)')}
                dataKey="global"
                stroke={colors[1]}
                strokeWidth={2}
                fill={colors[1]}
                fillOpacity={0.2}
                dot={{ r: 2, fillOpacity: 1 }}
                activeDot={{ r: 4, fillOpacity: 1, stroke: colors[1] }}
              />
              <Legend />
              <Tooltip formatter={(num: number) => Math.round(num * 100) / 100} />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      ))}
    </div>
  );
};

export default RatingsRadarChart;
