import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../common/components/Spinner';

const FormPrivacy = (props) => {
  const [state, setState] = useState({});

  const privacy = state.privacy || props.privacy;
  const isValueChanged = privacy !== props.privacy;

  const canSetPrivacy = !!props.onChange;

  const handlePrivacyChange = (event) => {
    if (state.updating) { return; }

    const { value } = event.currentTarget;
    setState((s) => ({ ...s, privacy: value, updated: false }));
  };

  const handleCancelClick = () => {
    if (state.updating) { return; }
    props.onCancel();
  };

  const handleUpdateClick = async () => {
    if (state.updating || !isValueChanged) { return; }

    setState((s) => ({ ...s, updating: true }));
    await props.onChange(privacy);
    setState((s) => ({
      ...s, privacy: null, updating: false, updated: true,
    }));
  };

  return (
    <div>
      <p className="tw-font-bold tw-text-base tw-text-success tw-py-2 tw-mb-2 tw-border-b">
        {canSetPrivacy ? __('Set response privacy') : __('Response privacy')}
      </p>

      {canSetPrivacy ? (
        <>
          <div className="tw-mb-4">
            <label className="radio">
              <input type="radio" name="answer_privacy" checked={privacy === 'visible'} value="visible" onChange={handlePrivacyChange} />
              <span className="tw-font-bold">{__('Visible')}</span>
            </label>

            <p>
              {__('All users should be able to see each others responses.')}
            </p>
          </div>

          <div className="tw-mb-4">
            <label className="radio">
              <input type="radio" name="answer_privacy" checked={privacy === 'hidden'} value="hidden" onChange={handlePrivacyChange} />
              <span className="tw-font-bold">{__('Hidden')}</span>
            </label>

            <p>
              {__('Only I should be able to see responses from other users.')}
            </p>
          </div>

          <div className="tw-flex tw-items-center tw-justify-between">
            <button
              type="button"
              className="tw-btn tw-btn-sm"
              onClick={handleCancelClick}
              disabled={state.updating}
            >
              {state.updated ? __('Close') : __('Cancel')}
            </button>

            {state.updated ? (
              <p className="tw-mx-3 tw-text-success">
                {__('Updated')}
              </p>
            ) : state.updating ? (
              <span className="tw-mx-3 tw-px-4">
                <Spinner size="sm" color="info" />
              </span>
            ) : (
              <button
                type="button"
                className="tw-btn tw-btn-sm tw-btn-primary"
                onClick={handleUpdateClick}
                disabled={!isValueChanged}
              >
                {__('Update')}
              </button>
            )}
          </div>
        </>
      ) : (
        <p>
          {privacy === 'visible' ? (
            __('All responses are visible to other users.')
          ) : (
            __('Only the person that added this form can see responses from other users.')
          )}
        </p>
      )}
    </div>
  );
};

FormPrivacy.propTypes = {
  privacy: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default FormPrivacy;
