import React, { useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import useFindDiscussionQuery from '../../../common/hooks/api/discussions/useFindDiscussionQuery';
import useUpdateDiscussionMutation from '../../../common/hooks/api/discussions/useUpdateDiscussionMutation';
import Spinner from '../../../common/components/Spinner';

const options = [{
  value: Atlas.DiscussionLocked.Unlocked,
  label: __('Unlocked'),
  description: __('Users can comment and reply in this discussion.'),
  icon: 'fa fa-unlock',
}, {
  value: Atlas.DiscussionLocked.Locked,
  label: __('Locked'),
  description: __('Nobody can create comments or replies.'),
  icon: 'fa fa-lock',
}];

interface DiscussionLockedFormProps {
  discussionId: Atlas.DiscussionID;
}

interface DiscussionLockedFormState {
  locked?: Atlas.DiscussionLocked;
}

const DiscussionLockedForm = (props: DiscussionLockedFormProps): JSX.Element => {
  const { discussionId } = props;

  const discussionQuery = useFindDiscussionQuery({ discussionId });
  const updateDiscussion = useUpdateDiscussionMutation({ discussionId });
  const discussion = discussionQuery.data?.data

  const [state, setState] = useState<DiscussionLockedFormState>({});

  if (discussionQuery.isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner color="info" />
      </div>
    );
  }

  if (!discussion) {
    return (
      <div>
        Oops! Something went wrong.
      </div>
    );
  }

  const updateDiscussionLocked = (nextLocked: Atlas.DiscussionLocked) => {
    setState((s) => ({ ...s, locked: nextLocked }));

    updateDiscussion.mutateAsync({
      locked: nextLocked,
    }).catch(() => {
      setState((s) => ({ ...s, locked: undefined }));
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (updateDiscussion.isLoading) { return; }

    const nextLocked = event.currentTarget.value;

    switch (nextLocked) {
      case Atlas.DiscussionLocked.Locked:
      case Atlas.DiscussionLocked.Unlocked: {
        break;
      }

      default: return;
    }

    updateDiscussionLocked(nextLocked);
  };

  const handleOptionClick = (nextLocked: Atlas.DiscussionLocked) => () => {
    updateDiscussionLocked(nextLocked);
  };

  const locked = state.locked ?? discussion.locked;

  return (
    <div>
      <div className="d-flex justify-content-center my-3">
        <select
          data-testid="discussion-locked"
          value={locked}
          onChange={handleSelectChange}
        >
          {options.map((nl) => (
            <option key={nl.value} value={nl.value}>{nl.label}</option>
          ))}
        </select>

        {updateDiscussion.isLoading ? (
          <Spinner size="sm" color="info" className="ml-2" />
        ) : null}
      </div>

      <div>
        <div className="py-3 modalHelp">
          <ul className="d-flex helpList">
            {options.map((nl) => (
              <li key={nl.value} style={{ cursor: 'pointer' }} onClick={handleOptionClick(nl.value)}>
                <div className="eachHelpMessage">
                  <i className={nl.icon} />
                  <h4 className="my-2">
                    {nl.label}
                  </h4>
                </div>
                <p className="my-0 px-3">
                  {nl.description}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DiscussionLockedForm;
