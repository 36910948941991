import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from '../../../common/components/BSDropdown';
import * as Atlas from '../../../common/types/Atlas';
import moment from 'moment';
import GroupUserSelect from '../../groups/components/GroupUserSelect';
import { ActionMeta, OnChangeValue } from 'react-select';
import { selectClassNames } from '../../../common/components/Select';
import { Link, useLocation } from 'react-router-dom';
import { getAssignmentsBasePath } from '../../../common/utils/getAssignmentsBasePath';
import Select from 'react-select';
import Spinner from '../../../common/components/Spinner';
import { Row } from '../../../common/components/Row';
import { Col } from '../../../common/components/Col';
import { Badge } from '../../../common/components/Badge';
import AttachmentName from '../../../common/components/AttachmentName';
import ReflectionName from '../../../common/components/ReflectionName';
import { submissionHasReflectionOrAttachment } from '../../../common/utils/submission-delete-prompt';

type StatusOption = {
  label: string;
  value: string;
};

interface UserIdOption {
  label: string;
  value: Atlas.UserID;
}

interface SubmissionCompleteOption {
  label: string;
  value: Atlas.SubmissionComplete;
}

interface DeleteSubmissionPromptOptions {
  prompt?: String;
  typeToConfirm?: boolean;
  selectedSubmissionId?: Atlas.AssignmentSubmissionID;
}

interface AssessorGroupAssignmentSubmissionsProps {
  group: Atlas.Group;
  assignment: Atlas.Assignment;
  submissions: Atlas.ExpandedAssignmentSubmission[];
  submissionsLoading: boolean;
  totalSubmissions: number;
  filter: {
    userId?: UserIdOption;
    complete?: SubmissionCompleteOption;
  };
  onFilterChange(name: string, value: StatusOption | UserIdOption | SubmissionCompleteOption): void;
  onDeleteSubmission(options: DeleteSubmissionPromptOptions): void;
}

const submissionCompleteOptions: SubmissionCompleteOption[] = [
  { label: __('Complete'), value: Atlas.SubmissionComplete.Complete },
  { label: __('Submitted'), value: Atlas.SubmissionComplete.Incomplete }
];

const truncateWithEllipsis = (text: string | null, maxLength: number): string | null => {
  if (!text) return text;
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const AssessorGroupAssignmentSubmissions = (props: AssessorGroupAssignmentSubmissionsProps): JSX.Element => {
  const { group, assignment, submissions, submissionsLoading } = props;

  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);

  const handleUserChange = (value: OnChangeValue<UserIdOption, false>, action: ActionMeta<UserIdOption>) => {
    const { name } = action;
    if (!name) { return; }
    if (!value) { return; }

    props.onFilterChange(name, value);
  };

  const handleStatusChange = (value: OnChangeValue<StatusOption, false>, action: ActionMeta<StatusOption>) => {
    const { name } = action;
    if (!name) { return; }
    if (!value) { return; }

    props.onFilterChange(name, value);
  };

  const handleDeleteSubmissionClick = (submission: Atlas.AssignmentSubmission) => () => {
    let deleteOptions: DeleteSubmissionPromptOptions

    if (submissionHasReflectionOrAttachment(submission.answers)) {
      deleteOptions = {
        prompt: "If this submission has been resubmitted by another user, their resubmission can lose visibility of any reflections and attachments included in this submission.",
        typeToConfirm: true,
        selectedSubmissionId: submission.id
      }
    } else {
      deleteOptions = {
        typeToConfirm: false,
        selectedSubmissionId: submission.id
      }
    }
    props.onDeleteSubmission(deleteOptions);
  };

  const hideStatus = assignment.completion === Atlas.AssignmentCompletion.Auto;
  const showGrade = submissions.some((submission) => !!submission.grade);

  const firstQuestion = assignment.questions[0];
  const firstQuestionType = Object.keys(firstQuestion.field.type)[0];

  const firstQuestionName = firstQuestion.field.name;
  const firstQuestionLabel = firstQuestion.field.label;
  const truncatedQuestionLabel = truncateWithEllipsis(firstQuestionLabel, 30);

  return (
    <div className="tw-iris-element-box">
      <Row>
        <Col className="tw-mb-4 tw-flex tw-items-center">
          {Number.isInteger(props.totalSubmissions) ? (
            <span className="tw-mx-3 text-muted">
              {props.totalSubmissions} {props.totalSubmissions === 1 ? __('submission') : __('submissions')}
            </span>
          ) : null}
        </Col>

        <Col className="tw-mb-4">
          <div className="tw-flex tw-justify-end tw-gap-2">
            <a
              href={`/groups/${group.id}/assignments/${assignment.id}/download_submissions.csv`}
              download
              className="tw-btn tw-btn-sm"
            >
              <span className="tw-mr-1">{__('CSV')}</span>
              <i className="fa fa-download" />
            </a>

            <Link className="tw-btn tw-btn-success tw-btn-sm" to={`/${basePath}/${assignment.group_id}/assignments/${assignment.id}/submissions/new`}>
              {__('New Submission')}
            </Link>
          </div>
        </Col>
      </Row>

      <Row>
        <Col />

        <Col>
          <h6 className="tw-my-0">{__('User')}</h6>
          <GroupUserSelect placeholder={__('All')} isClearable groupId={group.id} name="userId" value={props.filter.userId} onChange={handleUserChange} />
        </Col>

        <Col>
          <h6 className="tw-my-0">{__('Status')}</h6>
          <Select classNames={selectClassNames} placeholder={__('All')} isClearable name="complete" options={submissionCompleteOptions} value={props.filter.complete} onChange={handleStatusChange} />
        </Col>
      </Row>

      <table className="table tw-ml-0">
        <thead>
          <tr>
            <th>{__('Submitted by')}</th>
            <th>{__('Date')}</th>
            {showGrade ? (
              <th>{__('Grade')}</th>
            ) : null}
            {hideStatus ? null : (
              <th>{__('Status')}</th>
            )}
            <th>{truncatedQuestionLabel}</th>
            <th style={{ width: '1px' }} />
          </tr>
        </thead>

        <tbody>
          {submissionsLoading ? (
            <tr>
              <td colSpan={5} className="tw-text-center">
                <Spinner color="info" />
              </td>
            </tr>
          ) : !submissions.length ? (
            <tr>
              <td colSpan={5} className="tw-text-center text-muted">
                {__('No submissions were found')}
              </td>
            </tr>
          ) : submissions.map(submission => {
            const firstAnswer = submission.answers.find(answer => answer.name === firstQuestionName);

            const firstQuestionAnswer = (() => {
              switch (firstQuestionType) {
                case 'rating':
                  return (
                    firstAnswer
                      ? firstAnswer.value
                      : __("No rating given")
                  );
                case 'text':
                  return (
                    firstAnswer
                      ? truncateWithEllipsis(firstAnswer.value !== null ? String(firstAnswer.value) : null, 30)
                      : __("No answer given")
                    )
                case 'attachment':
                  return (
                    firstAnswer?.attachment_value
                      ? <AttachmentName attachmentId={firstAnswer.attachment_value}/>
                      : __("No file uploaded")
                  )
                case 'reflection':
                  return (
                    firstAnswer?.reflection_value
                      ? <ReflectionName reflectionId={firstAnswer.reflection_value} />
                      : __("No reflection chosen")
                  )
                case 'multiple_choice':
                  return (
                    firstAnswer
                      ? (Array.isArray(firstAnswer.value) ? firstAnswer.value.join(', ') : firstAnswer.value)
                      : __("No choice given")
                  )
              }
            })

            return (
              <tr key={submission.id}>
                <td>
                  {[submission.first_name, submission.last_name].filter(Boolean).join(' ')}
                </td>
                <td>
                  {moment(submission.created_at).format('l')}
                </td>
                {showGrade ? (
                  <td>
                    {submission.grade}
                  </td>
                ) : null}
                {hideStatus ? null : (
                  <td>
                    {
                      submission.complete === 'complete' ? (
                        <Badge color="success">
                          {__('Complete')}
                        </Badge>
                      ) : submission.complete === 'incomplete' ? (
                        <Badge color="info">
                          {__('Submitted')}
                        </Badge>
                      ) : null
                    }
                  </td>
                )}
                <td>{firstQuestionAnswer()}</td>
                <td>
                  <UncontrolledDropdown className="btn-group">
                    <Link className="btn" to={`/${basePath}/${assignment.group_id}/assignments/${submission.assignment_id}/submissions/${submission.id}`}>
                      {__('View')}
                    </Link>

                    <DropdownToggle>
                      <i className="fa fa-caret-down" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem className="text-danger" onClick={handleDeleteSubmissionClick(submission)}>
                        {__('Delete')}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AssessorGroupAssignmentSubmissions;
