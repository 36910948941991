import React, { useState } from 'react';
import * as Atlas from '../../../../common/types/Atlas';

interface ReflectionDetailsFormProps {
  reflection: Atlas.ExpandedReflection;
  readOnly?: boolean;
  onSubmit: (changes: Pick<Atlas.Reflection, 'name' | 'description' | 'room'>) => Promise<void>;
}

const ReflectionDetailsForm = (props: ReflectionDetailsFormProps) => {
  const { reflection, readOnly, onSubmit } = props;

  const [state, setState] = useState({
    name: reflection.name,
    description: reflection.description,
    room: reflection.room,
    updating: false,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;

    switch (name) {
      case 'name':
      case 'description':
      case 'room': break;
      default: return;
    }

    setState((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (state.updating) { return; }

    setState((s) => ({ ...s, updating: true }));
    void onSubmit({
      name: state.name,
      description: state.description,
      room: state.room,
    }).finally(() => {
      setState((s) => ({ ...s, updating: false }));
    });
  };

  const ownerName = reflection.user ? `${reflection.user.first_name} ${reflection.user.last_name}` : '';

  return (
    <form onSubmit={handleSubmit}>
      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Owner')}
      </p>

      <div className="tw-mb-2">
        <input
          className="tw-input tw-input-sm tw-input-bordered tw-w-full"
          readOnly
          value={ownerName}
        />
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Title')}
      </p>

      <div className="tw-mb-2">
        <input
          className="tw-input tw-input-sm tw-input-bordered tw-w-full"
          name="name"
          readOnly={readOnly}
          value={state.name}
          onChange={handleInputChange}
        />
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Details')}
      </p>

      <div className="tw-mb-2">
        <textarea
          className="tw-textarea tw-textarea-sm tw-textarea-bordered tw-w-full"
          name="description"
          readOnly={readOnly}
          value={state.description}
          onChange={handleInputChange}
        />
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Room')}
      </p>

      <div className="tw-mb-2">
        <input
          className="tw-input tw-input-sm tw-input-bordered tw-w-full"
          name="room"
          readOnly={readOnly}
          value={state.room}
          onChange={handleInputChange}
        />
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Date')}
      </p>

      <div className="tw-mb-2">
        <input
          className="tw-input tw-input-sm tw-input-bordered tw-w-full"
          readOnly
          value={reflection.created_at.toDateString()}
        />
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Organization')}
      </p>

      <div className="tw-mb-2">
        <input
          className="tw-input tw-input-sm tw-input-bordered tw-w-full"
          readOnly
          value={reflection.organization?.name ?? ''}
        />
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Lesson Code')}
      </p>

      <div className="tw-mb-2">
        <input
          className="tw-input tw-input-sm tw-input-bordered tw-w-full"
          readOnly
          value={reflection.lesson_code ?? ''}
        />
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Device ID')}
      </p>

      <div className="tw-mb-2">
        <input
          className="tw-input tw-input-sm tw-input-bordered tw-w-full"
          readOnly
          value={reflection.booking?.device_id ?? ''}
        />
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Reflection ID')}
      </p>

      <div className="tw-mb-2">
        <input
          className="tw-input tw-input-sm tw-input-bordered tw-w-full"
          readOnly
          value={reflection.id}
        />
      </div>

      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Comments')}
      </p>

      <div className="tw-mb-2">
        <input
          className="tw-input tw-input-sm tw-input-bordered tw-w-full"
          readOnly
          value={reflection.comment_count}
        />
      </div>

      {!readOnly ? (
        <div className="tw-flex tw-justify-end tw-mt-3">
          <button
            type="submit"
            className="tw-btn tw-btn-sm tw-btn-success"
            data-id="update-reflection-details"
          >
            {state.updating ? <span className="tw-loading" /> : null}
            {__('Update Details')}
          </button>
        </div>
      ) : null}
    </form>
  );
};

export default ReflectionDetailsForm;
