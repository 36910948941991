import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { findReflections, FindReflectionsArguments, FindReflectionsResponse } from '../../../api/reflections';

const useFindReflectionsInfiniteQuery = (
  args: FindReflectionsArguments,
  options?: { staleTime?: number; enabled?: boolean; },
): UseInfiniteQueryResult<FindReflectionsResponse> => (
  useInfiniteQuery(
    ['reflections', 'infinite', args],
    (context) => findReflections({
      params: {
        ...args.params,
        page: context.pageParam,
      },
    }),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.metadata.current_page + 1;
        if (nextPage > lastPage.metadata.total_pages) { return null; }
        return nextPage;
      },
      staleTime: 60000,
      ...options,
    },
  )
);

export default useFindReflectionsInfiniteQuery;
