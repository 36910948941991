import { useMutation, UseMutationResult } from 'react-query';
import { queryClient } from '../../withQueryClient';
import { updateReflectionFormAssociation, UpdateReflectionFormAssociationArguments, UpdateReflectionFormAssociationResponse } from '../../../api/reflections/[reflectionId]';

const useUpdateReflectionFormAssociationMutation = (): UseMutationResult<
UpdateReflectionFormAssociationResponse, unknown, UpdateReflectionFormAssociationArguments, unknown
> => {
  const mutation = useMutation(updateReflectionFormAssociation, {
    onSuccess: async (_, args) => {
      await queryClient.invalidateQueries(['reflections', args.params.reflectionId], { exact: true });
      queryClient.invalidateQueries(['reflections', args.params.reflectionId, 'forms']);
    },
  });

  return mutation;
};

export default useUpdateReflectionFormAssociationMutation;
