import { useQuery, UseQueryResult } from 'react-query';
import { findReflectionPlaylist, FindReflectionPlaylistResponse } from '../../../api/reflections/[reflectionId]/playlist';
import * as Atlas from '../../../types/Atlas';

const useFindReflectionPlaylistQuery = (
  reflectionId: Atlas.ReflectionID,
  options?: { staleTime?: number; enabled?: boolean; },
): UseQueryResult<FindReflectionPlaylistResponse> => (
  useQuery(
    ['reflections', reflectionId, 'playlist'],
    () => findReflectionPlaylist({ params: { reflectionId } }),
    options,
  )
);

export default useFindReflectionPlaylistQuery;
