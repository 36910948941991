import React, { useRef, useEffect, useMemo } from 'react';
import * as Atlas from '../../../../common/types/Atlas';

type ChangeChoicesFunc = (choices: Atlas.Choice[]) => Atlas.Choice[];

interface FormCounterElementProps {
  formElement: Atlas.FormElement;
  isEditingLimited?: boolean;
  onChange: (changes: { field: Partial<Atlas.FormField> }) => void;
}

const FormCounterElement = (props: FormCounterElementProps): JSX.Element | null => {
  const { formElement, isEditingLimited, onChange } = props;
  const { field } = formElement;

  const choices = useMemo(() => {
    if (!('counter' in field.type)) {
      return [];
    }

    return field.type.counter.choices;
  }, [field.type]);

  const choicesWithPlaceholder = useMemo(() => [...choices, ''], [choices]);

  const choiceInputsRef = useRef<HTMLInputElement[]>([]);
  useEffect(() => {
    choiceInputsRef.current = choiceInputsRef.current.slice(0, choicesWithPlaceholder.length);
  }, [choicesWithPlaceholder]);

  if (!('counter' in field.type)) {
    return null;
  }

  const { counter } = field.type;

  const changeChoices = (func: ChangeChoicesFunc) => {
    if (!onChange) { return; }

    onChange({
      field: {
        ...field,
        type: {
          ...field.type,
          counter: {
            ...counter,
            choices: func(counter.choices),
          },
        },
      },
    });
  };

  const removeChoice = (i: number) => {
    changeChoices((prevChoices) => {
      const nextChoices = [...prevChoices];
      nextChoices.splice(i, 1);
      return nextChoices;
    });
  };

  const handleChoiceChange = (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    changeChoices((prevChoices) => {
      const nextChoices = [...prevChoices];
      nextChoices[i] = value;
      return nextChoices;
    });
  };

  const handleChoiceBlur = (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value) { return; }

    removeChoice(i);
  };

  const handleChoiceRemove = (i: number) => () => {
    removeChoice(i);
  };

  const handleChoiceKeyPress = (i: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') { return; }

    const nextInput = choiceInputsRef.current[i + 1];
    if (!nextInput) { return; }

    nextInput.focus();
  };

  return (
    <div className="tw-text-base">
      <div className="tw-mb-2 tw-font-semibold">
        {__('Counter Choices')}
      </div>

      <div className="tw-flex tw-flex-col tw-gap-2">
        {choicesWithPlaceholder.map((c, i) => {
          const isPlaceholder = choices.length === i;

          return (
            <div key={i} className={isPlaceholder ? '' : 'tw-join'}>
              <input
                ref={(el) => { if (el) { choiceInputsRef.current[i] = el; } }}
                required={i === 0}
                className="tw-input tw-input-bordered tw-input-sm tw-join-item"
                placeholder={`${__('Enter a choice')}...`}
                name={`choice-${i}`}
                value={c}
                disabled={isEditingLimited}
                onChange={handleChoiceChange(i)}
                onBlur={handleChoiceBlur(i)}
                onKeyPress={handleChoiceKeyPress(i)}
              />
              {isPlaceholder ? null : (
                <button
                  type="button"
                  className="tw-btn tw-btn-square tw-btn-sm tw-join-item"
                  disabled={isEditingLimited}
                  onClick={handleChoiceRemove(i)}
                >
                  <i className="icon-remove" />
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormCounterElement;
