import React from 'react';
import PropTypes from 'prop-types';

import { Stack, StackRow, StackCol } from '../../../common/components/Stack';

const FormMultipleChoiceElement = (props) => {
  const { formAnswer, onChange } = props;
  const { field } = props.formElement;
  const { choices, allow_multiple: allowMultiple } = field.type.multiple_choice;

  const handleChoiceClick = (choice) => () => {
    onChange((s) => {
      let value;

      if (allowMultiple) {
        const values = s.value || [];

        if (values.includes(choice)) {
          value = values.filter((c) => c !== choice);
        } else {
          value = [...values, choice];
        }
      } else {
        value = s.value === choice ? undefined : choice;
      }

      return { ...s, value };
    });
  };

  const value = formAnswer.value || [];
  const isSelected = (choice) => value.includes(choice);

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      {choices.map((choice, i) => (
        <button key={i} type="button" className={`tw-bg-base-200 tw-p-1 tw-rounded ${isSelected(choice) ? 'tw-bg-primary/20' : ''}`} onClick={handleChoiceClick(choice)}>
          {choice}
        </button>
      ))}
    </div>
  );
};

FormMultipleChoiceElement.propTypes = {
  formAnswer: PropTypes.object.isRequired,
  formElement: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormMultipleChoiceElement;
