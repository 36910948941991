import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalBox, ModalHeader } from '../../../../../common/components/TailwindModal';
import VideoStreamPlayer from '../../../../screen-capture/VideoStreamPlayer';
import AudioLevelBar from '../../../../screen-capture/AudioLevelBar';
import { streamHasVideo } from '../utils';
import VideoStreamRecorder from '../VideoStreamRecorder';
import { msToTimestamp } from '../../../../../common/utils/utils';

const renderAudio = (stream: MediaStream) => {
  const audioTrack: MediaStreamTrack | undefined = stream.getAudioTracks()[0];
  if (!audioTrack) { return null; }

  return (
    <div className="tw-grid sm:tw-grid-cols-3 tw-gap-x-4 tw-gap-y-2">
      <div className="tw-font-bold tw-text-sm tw-truncate">
        {audioTrack.label}
      </div>

      <div className="sm:tw-col-span-2 tw-flex tw-items-center tw-gap-1">
        <i className="fa fa-volume-up tw-text-gray-600" />

        <AudioLevelBar
          stream={stream}
          className="tw-h-2 tw-bg-gray-200"
        />
      </div>
    </div>
  );
};

const MediaRecordingModal: React.FC<{
  vsr: VideoStreamRecorder;
  onStopClick: () => void;
}> = (props) => {
  const { vsr, onStopClick } = props;

  const recordStart = useMemo(() => new Date(), []);
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(Date.now() - recordStart.valueOf());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [recordStart]);

  const [leftStream, rightStream] = vsr.streams;

  const stopDisabled = duration < 3000;
  const handleStopClick = () => {
    if (stopDisabled) { return; }
    onStopClick();
  };

  const leftVideoStream = streamHasVideo(leftStream) ? leftStream : undefined;
  const rightVideoStream = (rightStream && streamHasVideo(rightStream)) ? rightStream : undefined;

  return (
    <Modal isOpen>
      <ModalBox>
        <div className="tw-flex tw-justify-between tw-items-baseline">
          <ModalHeader>
            <span className="tw-flex tw-items-center tw-gap-2">
              <i className="fa fa-circle tw-text-base tw-text-red-700 tw-animate-pulse" />
              <span>
                {__('Recording')}
              </span>
            </span>
          </ModalHeader>

          <div className="tw-badge">
            {msToTimestamp(duration, true)}
          </div>
        </div>

        <div className="tw-flex tw-flex-col tw-gap-4">
          {leftVideoStream || rightVideoStream ? (
            <VideoStreamPlayer
              leftStream={leftVideoStream}
              rightStream={rightVideoStream}
            />
          ) : null}

          {leftStream ? renderAudio(leftStream) : null}
          {rightStream ? renderAudio(rightStream) : null}
        </div>

        <hr className="tw-my-4" />

        <div className="tw-flex tw-justify-end">
          <button
            type="button"
            disabled={stopDisabled}
            className="tw-btn tw-btn-block"
            onClick={handleStopClick}
          >
            {__('Stop recording')}
          </button>
        </div>
      </ModalBox>
    </Modal>
  );
};

export default MediaRecordingModal;
