import React, { useRef, useLayoutEffect, useImperativeHandle } from 'react';
import classNames from 'classnames';

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  className?: string;
  onClose: { (): void; };
  children?: React.ReactNode;
}

export interface ModalRef {
  close: () => void;
}

export const Modal = React.forwardRef<ModalRef, ModalProps>((props, ref): JSX.Element => {
  const { open, onClose, className, children, ...otherProps } = props;

  const modal = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!modal.current) { return; }

    $(modal.current).modal(open ? 'show' : 'hide');
    if (!open) { return; }

    if (!onClose) { return; }

    $(modal.current).on('hidden', onClose);
    return () => {
      if (!modal.current) { return; }
      $(modal.current).off('hidden', onClose);
    };
  }, [open, onClose]);

  useImperativeHandle(ref, () => ({
    close: () => {
      if (!modal.current) { return; }
      $(modal.current).modal('hide');
    },
  }), []);

  return (
    <div {...otherProps} className={classNames('modal fade', className)} ref={modal}>
      {children}
    </div>
  );
});

interface ModalComponentProps extends React.HTMLAttributes<HTMLDivElement> {}

export const ModalHeader = React.forwardRef<HTMLDivElement, ModalComponentProps>((props, ref) => {
  const { className, children, ...otherProps } = props;

  return (
    <div {...otherProps} ref={ref} className={classNames('modal-header', className)}>
      {children}
    </div>
  );
});

export const ModalBody = React.forwardRef<HTMLDivElement, ModalComponentProps>((props, ref) => {
  const { className, children, ...otherProps } = props;

  return (
    <div {...otherProps} ref={ref} className={classNames('modal-body', className)}>
      {children}
    </div>
  );
});

export const ModalFooter = React.forwardRef<HTMLDivElement, ModalComponentProps>((props, ref) => {
  const { className, children, ...otherProps } = props;

  return (
    <div {...otherProps} ref={ref} className={classNames('modal-footer', className)}>
      {children}
    </div>
  );
});