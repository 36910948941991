import * as Atlas from '../types/Atlas';

export const isGroupAdministrator = (
  groupInfo: Atlas.GroupInfo,
  userId: Atlas.UserID,
  userOrgId: Atlas.OrganizationID,
): boolean => (
  groupInfo.administrators.user_ids.includes(userId)
  || groupInfo.administrators.group_ids.includes(userOrgId)
);

export const isGroupWriter = (
  groupInfo: Atlas.GroupInfo,
  userId: Atlas.UserID,
  userOrgId: Atlas.OrganizationID,
): boolean => (
  groupInfo.writers.user_ids.includes(userId)
  || groupInfo.writers.group_ids.includes(userOrgId)
);

export const isGroupVisibleMember = (
  groupInfo: Atlas.GroupInfo,
  userId: Atlas.UserID,
): boolean => groupInfo.visible_members.user_ids.includes(userId);

export const getUsersGroupMemberRole = (
  groupInfo: Atlas.GroupInfo,
  userId: Atlas.UserID,
  userOrgId: Atlas.OrganizationID,
): Atlas.MemberRole => {
  if (isGroupAdministrator(groupInfo, userId, userOrgId)) { return Atlas.MemberRole.Admin; }
  if (isGroupWriter(groupInfo, userId, userOrgId)) { return Atlas.MemberRole.Contributor; }
  return Atlas.MemberRole.Standard;
};

export const userHasRole = (
  userMemberRole: Atlas.MemberRole,
  requiredMemberRole: Atlas.MemberRole,
): boolean => {
  const roles = [Atlas.MemberRole.Standard, Atlas.MemberRole.Contributor, Atlas.MemberRole.Admin];
  const userRank = roles.indexOf(userMemberRole);
  const requiredRank = roles.indexOf(requiredMemberRole);

  return userRank >= requiredRank;
};
