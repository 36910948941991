import React, {
  useState, useRef, useLayoutEffect, useMemo,
} from 'react';
import Collapse from '@kunukn/react-collapse';
import * as Atlas from '../../common/types/Atlas';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, ModalRef,
} from '../../common/components/Modal';
import NotificationLevelForm from './forms/NotificationLevelForm';
import DiscussionLockedForm from './forms/DiscussionLockedForm';
import HeaderToolBar from './components/HeadersToolbar';
import DiscussionCommentForm from './components/DiscussionCommentForm';
import DiscussionSubscribeForm from './forms/DiscussionSubscribeForm';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import useDestroyDiscussionMutation from '../../common/hooks/api/discussions/useDestroyDiscussionMutation';
import useCreateDiscussionDiscussionCommentMutation from '../../common/hooks/api/discussions/useCreateDiscussionDiscussionCommentMutation';
import DiscussionComment from './components/Comment';
import DiscussionModal from './DiscussionModal';
import { DiscussionToolbarButton } from './styles/toolbar';
import useFindDiscussionDiscussionCommentsInfiniteQuery from '../../common/hooks/api/discussions/useFindDiscussionDiscussionCommentsInfiniteQuery';
import { DecoratedExpandedDiscussionComment } from '../../common/api/discussions/[discussionId]/comments';
import DiscussionTitleForm from './forms/DiscussionTitleForm';
import Spinner from '../../common/components/Spinner';
import { Button } from '../../common/components/Button';

interface DiscussionProps {
  initiallyOpen?: boolean;
  discussion: Atlas.Discussion;
  checkPermissions: boolean;
  discussionAction?: Atlas.DiscussionCommentAction;
}

enum ModalKind {
  Expanded,
  Locked,
  NotificationLevel,
  Subscribe,
  Rename,
}

const Discussion = (props: DiscussionProps): JSX.Element => {
  const {
    initiallyOpen,
    discussion,
    checkPermissions,
    discussionAction,
  } = props;

  const [autoScroll, setAutoScroll] = useState(true);
  const [discussionOpen, setDiscussionOpen] = useState(initiallyOpen ?? false);
  const [openModal, setOpenModal] = useState<ModalKind | undefined>();

  const discussionId = discussion.id;
  const destroyDiscussion = useDestroyDiscussionMutation({ discussionId });

  const discussionCommentsQuery = useFindDiscussionDiscussionCommentsInfiniteQuery({
    discussionId,
    per_page: 7,
  });

  const createDiscussionComment = useCreateDiscussionDiscussionCommentMutation({ discussionId });

  const commentListRef = useRef<HTMLDivElement>(null);
  const chatBottomRef = useRef<HTMLDivElement>(null);
  const activeModal = useRef<ModalRef>(null);

  useLayoutEffect(() => {
    if (!autoScroll) { return; }
    if (!commentListRef.current) { return; }
    if (!chatBottomRef.current) { return; }

    commentListRef.current.scrollTo({
      top: chatBottomRef.current.offsetTop,
      behavior: 'smooth',
    });
  }, [autoScroll, discussionCommentsQuery.isFetching]);

  const handleCommentListScroll = (event: React.MouseEvent<HTMLInputElement>) => {
    const { offsetHeight, scrollTop, scrollHeight } = event.currentTarget;

    const canFetchNextPage = discussionCommentsQuery.hasNextPage && !discussionCommentsQuery.isFetching;
    if (scrollTop < 200 && discussionOpen && canFetchNextPage) {
      discussionCommentsQuery.fetchNextPage();
    }

    const scrolledToBottom = (offsetHeight + scrollTop) >= scrollHeight;
    setAutoScroll(scrolledToBottom);
  };

  const handleCommentSubmit = async (commentBody: string) => {
    await createDiscussionComment.mutateAsync({ body: commentBody, action: discussionAction });
    setAutoScroll(true);
  };

  const handleDeleteDiscussionClick = async () => {
    if (!window.confirm(__('Are you sure you want to delete this discussion?'))) { return; }

    await destroyDiscussion.mutateAsync();
  };

  const toggleDiscussionOpen = () => {
    setDiscussionOpen((o) => !o);
  };

  const toggleModal = (kind: ModalKind) => {
    setOpenModal((prev) => prev === kind ? undefined : kind);
  };

  const toggleNotificationLevelModal = () => { toggleModal(ModalKind.NotificationLevel); };
  const toggleSubscribeModal = () => { toggleModal(ModalKind.Subscribe); };
  const toggleExpandedModal = () => { toggleModal(ModalKind.Expanded); };
  const toggleLockedModal = () => { toggleModal(ModalKind.Locked); };
  const toggleRenameModal = () => { toggleModal(ModalKind.Rename); }

  const exitModalsFromX = () => {
    activeModal.current?.close();
  };

  const handleCloseModalClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    exitModalsFromX();
  };

  const handleModalClose = () => {
    setOpenModal(undefined);
  };

  const pages = useMemo(() => {
    return discussionCommentsQuery.data?.pages || [];
  }, [discussionCommentsQuery.data?.pages]);

  const commentCount = pages[pages.length - 1]?.metadata.total_entries || 0;

  const comments = useMemo(() => {
    return pages.reduce<DecoratedExpandedDiscussionComment[]>((acc, page) => ([
      ...acc,
      ...page.data
    ]), []).sort((a, b) => a.created_at.valueOf() - b.created_at.valueOf());
  }, [pages]);

  return (
    <div className={`border tw-bg-white tw-rounded-xl tw-shadow`}>
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-between">
            <span className="font-weight-bold">
              {discussion.title}
            </span>

            <small className="text-muted d-none d-md-block ml-3">
              {typeof commentCount === 'number' ? (
                <>
                  {commentCount}
                  {' '}
                  {n__('comment', 'comments', commentCount)}
                </>
              ) : null}
            </small>
          </div>

          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center mr-3">
              <HeaderToolBar
                onOptionsClick={toggleNotificationLevelModal}
                onLockClick={toggleLockedModal}
                onRenameClick={toggleRenameModal}
                onDeleteClick={handleDeleteDiscussionClick}
                onSubscribeClick={toggleSubscribeModal}
                checkPermissions={checkPermissions}
                locked={discussion.locked}
              />

              <Tooltip
                title="Fullscreen"
                position="top"
                trigger="mouseenter"
                followCursor
                size="small"
              >
                <div className="d-none d-md-block">
                  <DiscussionToolbarButton className="px-2" role="button" href="#" onClick={toggleExpandedModal}>
                    <i className="fa fa-expand" />
                  </DiscussionToolbarButton>
                </div>
              </Tooltip>
            </div>

            <div style={{ width: '6rem' }}>
              {discussionOpen ? (
                <button className="btn btn-small btn-block btn-secondary font-weight-bold py-1" onClick={toggleDiscussionOpen}>
                  {__('Close')}
                </button>
              ) : (
                <button className="btn btn-small btn-block btn-info font-weight-bold py-1" onClick={toggleDiscussionOpen}>
                  {__('Open')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {openModal === ModalKind.Expanded ? (
        <DiscussionModal discussion={discussion} onClose={handleModalClose} />
      ) : null}

      {openModal === ModalKind.NotificationLevel ? (
        <Modal ref={activeModal} open onClose={toggleNotificationLevelModal}>
          <ModalHeader className="d-flex">
            <div className="flex-fill">{__('Discussion Notification Level')}</div>
            <a href="#" onClick={handleCloseModalClick}>
              <i className="fa fa-times fa-lg" />
            </a>
          </ModalHeader>
          <ModalBody>
            <NotificationLevelForm
              discussionId={discussion.id}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={exitModalsFromX}>{__('Cancel')}</Button>
          </ModalFooter>
        </Modal>
      ) : null}

      {openModal === ModalKind.Subscribe ? (
        <Modal ref={activeModal} open onClose={toggleSubscribeModal}>
          <ModalHeader className="d-flex">
            <div className="flex-fill">{__('Subscribe to this discussion')}</div>
            <a href="#" onClick={handleCloseModalClick}>
              <i className="fa fa-times fa-lg" />
            </a>
          </ModalHeader>
          <ModalBody>
            <DiscussionSubscribeForm
              discussionId={discussion.id}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={exitModalsFromX}>{__('Cancel')}</Button>
          </ModalFooter>
        </Modal>
      ) : null}

      {openModal === ModalKind.Locked ? (
        <Modal ref={activeModal} open onClose={toggleLockedModal}>
          <ModalHeader className="d-flex">
            <div className="flex-fill">{__('Discussion Locked')}</div>
            <a href="#" onClick={handleCloseModalClick}>
              <i className="fa fa-times fa-lg" />
            </a>
          </ModalHeader>
          <ModalBody>
            <DiscussionLockedForm
              discussionId={discussion.id}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={exitModalsFromX}>{__('Cancel')}</Button>
          </ModalFooter>
        </Modal>
      ) : null}

      {openModal === ModalKind.Rename ? (
        <Modal ref={activeModal} open onClose={handleModalClose}>
          <ModalHeader className="d-flex">
            <div className="flex-fill">{__('Rename Discussion')}</div>
            <a href="#" onClick={handleCloseModalClick}>
              <i className="fa fa-times fa-lg" />
            </a>
          </ModalHeader>
          <ModalBody>
            <DiscussionTitleForm
              discussionId={discussion.id}
              onSaved={() => {
                exitModalsFromX()
                handleModalClose()
              }}
            />
          </ModalBody>
        </Modal>
      ) : null}

      <Collapse
        overflowOnExpanded
        isOpen={discussionOpen}
        className=""
        style={{
          height: '400px',
          transition: '500ms',
        }}
      >
        <div className="px-3">
          <hr className="my-0" />
        </div>

        <div
          ref={commentListRef}
          className="p-3 overflow-auto position-relative d-flex flex-column"
          style={{ maxHeight: '25rem', minHeight: '10rem' }}
          onScroll={handleCommentListScroll}
        >
          {discussionCommentsQuery.hasNextPage ? (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-secondary"
                disabled={discussionCommentsQuery.isFetchingNextPage}
                onClick={() => discussionCommentsQuery.fetchNextPage()}
              >
                {__('Load more comments')}
              </button>
            </div>
          ) : null}

          {discussionCommentsQuery.isFetchingNextPage ? (
            <div className="d-flex justify-content-center py-5">
              <Spinner color="info" />
            </div>
          ) : null}

          {comments.length > 0 ? (
            <div className="flex-fill">
              {comments.map((comment) => (
                <div key={comment.id} className="my-4">
                  <DiscussionComment
                    comment={comment}
                    replies={comment.replies}
                    isLocked={discussion.locked}
                  />
                </div>
              ))}

              <div ref={chatBottomRef} />
            </div>
          ) : (
            <div className="flex-fill d-flex align-items-center justify-content-center p-4">
              <div className="text-muted">
                {__('No comments')}
              </div>
            </div>
          )}
        </div>

        <div className="p-3">
          <DiscussionCommentForm
            discussionId={discussion.id}
            onSubmit={handleCommentSubmit}
            isLocked={discussion.locked}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default Discussion;
