import React from 'react';
import useFindGroupsInfiniteQuery from '../../../../../common/hooks/api/groups/useFindGroupsInfiniteQuery';
import useSearchValue from '../../../hooks/useSearchValue';
import { ResourceSearchResult } from '../../common/types';
import SearchResultsError from './common/search-results-error';
import SearchResultsLoader from './common/search-results-loader';
import SearchResultsSuccess from './common/search-results-success';
import { FindGroupsParams } from '../../../../../common/api/groups';

const GroupSearchResults: React.FC<{
  query: string;
  filter?: FindGroupsParams['filter'];
  onResultClick: (result: ResourceSearchResult) => void;
}> = (props) => {
  const {
    query,
    filter,
    onResultClick,
  } = props;

  const search = useSearchValue(query);

  const searchQuery = useFindGroupsInfiniteQuery({
    params: { search: query, filter },
  }, {
    enabled: search.enabled,
    staleTime: 60000,
  });

  switch (searchQuery.status) {
    case 'success': {
      const results = searchQuery.data.pages.reduce<ResourceSearchResult[]>((acc, data) => [
        ...acc,
        ...data.data.map<ResourceSearchResult>((group) => ({
          type: 'group',
          data: group,
        })),
      ], []);

      return (
        <SearchResultsSuccess
          results={results}
          isLoading={searchQuery.isFetching}
          hasMoreResults={searchQuery.hasNextPage}
          onLoadMoreClick={searchQuery.fetchNextPage}
          onResultClick={onResultClick}
        />
      );
    }

    case 'error': return (
      <SearchResultsError />
    );

    default: return (
      <SearchResultsLoader />
    );
  }
};

export default GroupSearchResults;
