import React from 'react';
import { useImage } from 'react-image';
import { isPresent } from '../utils/utils';

type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  fallbackSrc: string;
};

const ImageWithFallback = (props: ImageProps) => {
  const { src: originalSrc, fallbackSrc, ...otherProps } = props;
  const { src } = useImage({
    srcList: [originalSrc, fallbackSrc].filter(isPresent),
    useSuspense: false,
  });

  // eslint-disable-next-line jsx-a11y/alt-text, react/jsx-props-no-spreading
  return <img {...otherProps} src={src} />;
};

export default ImageWithFallback;
