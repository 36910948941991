/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useState } from 'react';
import useSearchInfiniteQuery from '../../../../common/hooks/api/search/useSearchInfiniteQuery';
import SearchCompletion from '../search-result/completion';
import LoadMoreButton from './load-more-button';
import renderSearchResult from './render-search-result';
import Spinner from '../../../../common/components/Spinner';

interface GlobalSearchResultsProps {
  query: string;
  onQueryChange: (query: string) => void;
  excludeUsers?: boolean;
  excludeDevices?: boolean;
  excludeGroups?: boolean;
  excludeOrganizations?: boolean;
  excludeReflections?: boolean;
  hideCompletions?: boolean;
}

const GlobalSearchResults = (props: GlobalSearchResultsProps): JSX.Element => {
  const {
    query,
    onQueryChange,
    excludeUsers = false,
    excludeDevices = false,
    excludeGroups = false,
    excludeOrganizations = false,
    excludeReflections = false,
    hideCompletions = false,
  } = props;

  const [state, setState] = useState({
    query,
    enabled: true,
  });

  useEffect(() => {
    setState((s) => {
      if (s.query === query) { return s; }

      return {
        query,
        enabled: false,
      };
    });
  }, [query]);

  useEffect(() => {
    if (state.enabled) { return undefined; }

    const timeout = setTimeout(() => {
      setState((s) => ({ ...s, enabled: true }));
    }, 500);

    return () => clearTimeout(timeout);
  }, [state.enabled, state.query]);

  const validQuery = state.query.length > 1;
  const searchEnabled = state.enabled && validQuery;

  const searchQuery = useSearchInfiniteQuery({
    query: state.query,
    excludeUsers,
    excludeDevices,
    excludeGroups,
    excludeOrganizations,
    excludeReflections,
    includeTrashed: true,
  }, {
    enabled: searchEnabled,
    cacheTime: 15000,
    staleTime: Infinity,
  });

  const { fetchNextPage } = searchQuery;
  const loadNextPage = useCallback(() => {
    void fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="tw-bg-base-100 tw-rounded-lg tw-shadow-lg tw-p-1 tw-border tw-border-base-200 tw-space-y-1 tw-h-full tw-overflow-y-auto">
      {(() => {
        switch (searchQuery.status) {
          case 'success': {
            const [firstPage] = searchQuery.data.pages;

            if (firstPage.metadata.total_entries === 0) {
              return (
                <div className="tw-p-3 tw-flex tw-justify-center tw-items-center tw-text-accent">
                  {__('No matches found')}
                </div>
              );
            }

            const handleCompletionClick = (completion: string) => () => {
              onQueryChange(completion);
            };

            return searchQuery.data.pages.map((page) => {
              const { results, completions } = page.data;
              const currentPage = page.metadata.current_page;

              const showCompletions = !hideCompletions && currentPage === 1;

              return (
                <React.Fragment key={currentPage}>
                  {showCompletions ? completions.filter((completion) => (
                    completion !== query
                  )).slice(0, 3).map((completion) => (
                    <SearchCompletion
                      key={`completion-${completion}`}
                      completion={completion}
                      onClick={handleCompletionClick(completion)}
                    />
                  )) : null}

                  {results.map((result) => renderSearchResult(result))}
                </React.Fragment>
              );
            });
          }

          case 'error': {
            return (
              <div className="tw-p-3 tw-flex tw-justify-center tw-items-center tw-text-accent">
                {__('Oops! Something went wrong.')}
              </div>
            );
          }

          case 'loading': {
            return (
              <div className="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center">
                <Spinner color="primary" />
              </div>
            );
          }

          default: {
            if (!validQuery) {
              return (
                <div className="tw-p-3 tw-flex tw-justify-center tw-items-center tw-text-accent">
                  {__('Please enter 2 or more characters')}
                </div>
              );
            }

            return (
              <div className="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center">
                <Spinner color="primary" />
              </div>
            );
          }
        }
      })()}

      {searchQuery.hasNextPage ? (
        <LoadMoreButton
          isLoading={searchQuery.isFetching}
          onLoadMore={loadNextPage}
        />
      ) : null}
    </div>
  );
};

export default GlobalSearchResults;
