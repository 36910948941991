import * as Atlas from '../../common/types/Atlas';

const requiredFieldValidator = (formElement: Atlas.FormElement, formAnswers: Atlas.FormAnswer[]) => {
  return formAnswers.some((fa) => fa.name === formElement.field.name);
};

export const validateFormData = (form: Atlas.Form, formData: Atlas.FormData[]) => {
  const answers = formData.reduce<Atlas.FormAnswer[]>((acc, fd) => [...acc, ...fd.answers], []);

  return form.elements.every((fe) => {
    const validators = [];

    if (fe.field.required) {
      validators.push(requiredFieldValidator);
    }

    return validators.every((validator) => validator(fe, answers));
  });
};
