import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import SearchDeviceResult from '../search-result/device-result';
import SearchGroupResult from '../search-result/group-result';
import SearchReflectionResult from '../search-result/reflection-result';
import SearchUserResult from '../search-result/user-result';

const renderSearchResult = (result: Atlas.SearchResult): JSX.Element | null => {
  if ('user' in result) {
    return (
      <SearchUserResult key={`user-${result.user.id}`} user={result.user} />
    );
  }

  if ('device' in result) {
    return (
      <SearchDeviceResult key={`device-${result.device.id}`} device={result.device} />
    );
  }

  if ('group' in result) {
    return (
      <SearchGroupResult key={`group-${result.group.id}`} group={result.group} />
    );
  }

  if ('reflection' in result) {
    return (
      <SearchReflectionResult key={`reflection-${result.reflection.id}`} reflection={result.reflection} />
    );
  }

  return null;
};

export default renderSearchResult;
