import { useMutation, UseMutationResult } from 'react-query';
import { UpdateAssignmentSubmissionArgs, updateAssignmentSubmssion } from '../../../api/assignments/assignment-submissions';
import { queryClient } from '../../withQueryClient';
import * as Atlas from '../../../types/Atlas';

type UpdateAssignmentSubmissionResponse = {
  data: Atlas.ExpandedAssignmentSubmission;
};

type UseCreateAssignmentSubmissionMutationResult = UseMutationResult<
  UpdateAssignmentSubmissionResponse,
  unknown,
  UpdateAssignmentSubmissionArgs
>;

const useUpdateAssignmentSubmissionMutation = (
  assignmentId: Atlas.AssignmentID,
  assignmentSubmissionId?: Atlas.AssignmentSubmissionID,
): UseCreateAssignmentSubmissionMutationResult => {

  const mutation = useMutation(updateAssignmentSubmssion, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assignments', assignmentId, 'submissions' ]);
    }
  });

  return mutation
};

export default useUpdateAssignmentSubmissionMutation;