import React, { useState } from 'react';
import { Modal, ModalBox, ModalHeader } from '../../../../../common/components/TailwindModal';
import useDestroyCommentMutation from '../../../../../common/hooks/api/comments/useDestroyCommentMutation';
import useCreateVideoMutation from '../../../../../common/hooks/api/videos/useCreateVideoMutation';
import useDestroyVideoMutation from '../../../../../common/hooks/api/videos/useDestroyVideoMutation';
import * as Atlas from '../../../../../common/types/Atlas';
import useHermesUploader from '../../../../video-editor/video-uploader/use-hermes-uploader';
import CommentFormFields, { CommentFieldValues } from '../CommentFormFields';
import RouterPrompt from '../../../../../common/components/RouterPrompt';

const FileSelectedModal: React.FC<{
  file: File;
  onBackClick: () => void;
  onCloseClick: () => void;
  onUploadClick: (args: Partial<Atlas.Comment>) => void;
}> = (props) => {
  const {
    file,
    onBackClick,
    onCloseClick,
    onUploadClick,
  } = props;

  const [commentValues, setCommentValues] = useState<CommentFieldValues>({
    body: '',
    synchronicity: Atlas.CommentSynchronicity.Pause,
  });

  const handleCloseClick = () => {
    onCloseClick();
  };

  const handleBackClick = () => {
    onBackClick();
  };

  const handleUploadClick = () => {
    onUploadClick(commentValues);
  };

  const $icon = (() => {
    const [mimeType] = file.type.split('/');

    switch (mimeType) {
      case 'audio': return <i className="fa fa-microphone" />;
      case 'video': return <i className="fa fa-video-camera" />;
      default: return <i className="fa fa-file" />;
    }
  })();

  return (
    <Modal isOpen>
      <ModalBox>
        <ModalHeader onClose={handleCloseClick}>
          {__('Upload media')}
        </ModalHeader>

        <div className="tw-flex tw-items-center tw-gap-4 tw-px-4 tw-py-2 tw-bg-base-200 tw-rounded-xl tw-text-base tw-mb-2">
          {$icon}
          <span className="tw-font-bold">
            {file.name}
          </span>
        </div>

        <CommentFormFields
          values={commentValues}
          onChange={setCommentValues}
        />

        <div className="tw-flex tw-justify-between tw-mt-6">
          <button type="button" className="tw-btn" onClick={handleBackClick}>
            {__('Back')}
          </button>

          <button type="button" className="tw-btn tw-btn-success" onClick={handleUploadClick}>
            {__('Upload')}
          </button>
        </div>
      </ModalBox>
    </Modal>
  );
};

const FileUploadingModal: React.FC<{
  percentUploaded: number;
  onCancelClick: () => void;
}> = (props) => {
  const {
    percentUploaded,
    onCancelClick,
  } = props;

  const handleCancelClick = () => {
    onCancelClick();
  };

  return (
    <Modal isOpen>
      <ModalBox>
        <ModalHeader>
          <span className="tw-flex tw-items-center tw-gap-2">
            <i className="fa fa-circle-o-notch fa-spin" />
            <span>
              {__('Uploading media')}
            </span>
          </span>
        </ModalHeader>

        <div className="tw-py-2">
          <p className="tw-text-center tw-font-semibold tw-py-4">
            {`${percentUploaded}% ${__('uploaded')}`}
          </p>

          <progress className="tw-block tw-progress tw-progress-info" value={percentUploaded} max={100} />
        </div>

        <div className="tw-flex tw-justify-end tw-mt-6">
          <button type="button" className="tw-link tw-link-hover tw-text-error tw-flex tw-gap-1 tw-items-center" onClick={handleCancelClick}>
            <i className="fa fa-close" />
            {__('Cancel upload')}
          </button>
        </div>
      </ModalBox>
    </Modal>
  );
};

const FileUploadedModal: React.FC<{
  onDoneClick: () => void;
}> = (props) => {
  const {
    onDoneClick,
  } = props;

  const handleDoneClick = () => {
    onDoneClick();
  };

  return (
    <Modal isOpen>
      <ModalBox>
        <div className="tw-py-10 tw-space-y-2">
          <p className="tw-text-center tw-font-semibold tw-text-lg">
            {__('Comment created and upload completed.')}
          </p>

          <p className="tw-text-center tw-text-gray-600 tw-text-base">
            {__('You may now close this window.')}
          </p>
        </div>

        <button type="button" className="tw-btn tw-btn-block tw-btn-success" onClick={handleDoneClick}>
          {__('Done')}
        </button>
      </ModalBox>
    </Modal>
  );
};

const MediaUploadModal: React.FC<{
  file: File;
  onStart: (args: Partial<Atlas.Comment>) => Promise<Atlas.CommentID>;
  onBackClick: () => void;
  onCloseClick: () => void;
}> = (props) => {
  const {
    file,
    onStart,
    onBackClick,
    onCloseClick,
  } = props;

  const [state, setState] = useState<{
    status: 'selected';
  } | {
    status: 'uploading';
  } | {
    status: 'uploaded';
  }>({ status: 'selected' });

  const createVideo = useCreateVideoMutation();
  const destroyVideo = useDestroyVideoMutation();
  const destroyComment = useDestroyCommentMutation();
  const [uploaderState, uploader] = useHermesUploader();

  const render = () => {
    switch (state.status) {
      case 'selected': {
        const handleUploadClick = async (commentArgs: Partial<Atlas.Comment>) => {
          let commentId: Atlas.CommentID | undefined;
          let video: Atlas.Video | undefined;

          try {
            setState({ status: 'uploading' });

            commentId = await onStart(commentArgs);

            const createVideoResponse = await createVideo.mutateAsync({
              body: {
                description: file.name,
                commentId,
              },
            });

            video = createVideoResponse.data;
            const metadata = createVideoResponse.metadata;

            const mediaKey = video.channels[0].media_key;
            if (!mediaKey) { return; }

            const { hermes } = metadata;
            const uploadResult = await uploader({ mediaKey, file, uploadUrl: `${hermes.upload_uri}/` });

            if (uploadResult.status !== 'success') {
              throw new Error('Upload was not successful');
            }

            setState({ status: 'uploaded' });
          } catch {
            setState({ status: 'selected' });

            if (video) {
              destroyVideo.mutate({ params: { videoId: video.id } });
            }

            if (commentId) {
              destroyComment.mutate({ params: { commentId } });
            }
          }
        };

        return (
          <FileSelectedModal
            file={file}
            onBackClick={onBackClick}
            onCloseClick={onCloseClick}
            onUploadClick={handleUploadClick}
          />
        );
      }

      case 'uploading': {
        const handleCancelClick = () => {
          if (!window.confirm(__('Are you sure you want to cancel this upload?'))) {
            return;
          }

          setState({ status: 'selected' });

          if (uploaderState.status === 'uploading') {
            uploaderState.cancelToken.cancel();
          }
        };

        const percent = (() => {
          switch (uploaderState.status) {
            case 'idle': return 0;
            case 'uploading': return uploaderState.progress;
            case 'success': return 100;
            case 'error': return 0;
          }
        })();

        return (
          <FileUploadingModal
            percentUploaded={percent}
            onCancelClick={handleCancelClick}
          />
        );
      }

      case 'uploaded': {
        const handleDoneClick = () => {
          onCloseClick();
        };

        return (
          <FileUploadedModal
            onDoneClick={handleDoneClick}
          />
        );
      }
    }
  };

  return (
    <>
      <RouterPrompt
        when={uploaderState.status !== 'success'}
        message={__('Your upload will be cancelled if you leave this page.')}
      />

      <RouterPrompt
        when={uploaderState.status === 'success'}
        message={__('You have unsaved changes on this page.')}
      />

      {render()}
    </>
  );
};

export default MediaUploadModal;
