import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders, jsonHeaders, Metadata, PaginationParams, searchParams } from '../../routes';

export const reflectionAttachmentsRoute = (args: {
  reflectionId: Atlas.ReflectionID
}): string => `/api/v0/reflections/${args.reflectionId}/attachments`;

export type FindReflectionAttachmentsParams = {
  reflectionId: Atlas.ReflectionID;
} & PaginationParams;

export interface FindReflectionAttachmentsArguments {
  params: FindReflectionAttachmentsParams;
}

export interface FindReflectionAttachmentsResponse {
  data: Atlas.Attachment[];
  metadata: Metadata;
}

export const findReflectionAttachments = async (
  args: FindReflectionAttachmentsArguments,
): Promise<FindReflectionAttachmentsResponse> => {
  const { params } = args;

  const url = [
    reflectionAttachmentsRoute({
      reflectionId: params.reflectionId,
    }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findReflectionAttachments');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.Attachment>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface CreateReflectionAttachmentParams {
  reflectionId: Atlas.ReflectionID;
}

export type CreateReflectionAttachmentBody = Pick<Atlas.Attachment, 'name'>;

export interface CreateReflectionAttachmentArgs {
  params: CreateReflectionAttachmentParams;
  body: CreateReflectionAttachmentBody;
}

export interface CreateReflectionAttachmentResponse {
  data: Atlas.Attachment;
  metadata: Metadata;
}

export const createReflectionAttachment = async (
  args: CreateReflectionAttachmentArgs,
): Promise<CreateReflectionAttachmentResponse> => {
  const { reflectionId } = args.params;

  const url = reflectionAttachmentsRoute({ reflectionId });

  const r = await fetch(url, {
    method: 'POST',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(r.status, 200, 300)) {
    throw new ApiError(r.status, 'createReflectionAttachment');
  }

  const body = await r.json() as { data: object; metadata: object; };
  const data = parseModelObject<Atlas.Attachment>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};
