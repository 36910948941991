/*global $,__,alert,console,enums,module*/

module.exports = function (selector) {
    "use strict";

    // Find DOM elements and data attributes
    var privateSelect = $(selector).find(".private input"),
        privateSelected = $(selector).find(".private .select2-choices"),
        privateTags = $(selector).find(".private").data("tags"),
        publicSelect = $(selector).find(".public input"),
        publicTags = $(selector).find(".public").data("tags"),
        tags_access = $(selector).find(".tags").data("public-tags-access"),
        reflectionID = $(selector).find(".tags").data("reflection-id"),
        tagType = { public: "public", private: "private" },
        editable = $(selector).find(".tags").data("editable");

    // Creates select2 config based on a tag type e.g. "private"
    function baseConfig(tagType, tags) {
        return {
            placeholder: __("Add " + tagType + " tag"),
            dropdownCss: { "z-index": 100000 },
            tags: true,
            tokenSeparators: [","],
            ajax: {
                url: "/reflections/" + reflectionID + "/tags",
                dataType: "json",
                cache: true,
                quietMillis: 250,
                data: formatQuery,
                results: formatResults,
            },
            initSelection: function (_, callback) {
                var data = [];
                $(tags).each(function () {
                    data.push({ id: this, text: this });
                });
                callback(data);
            }
        };
    }

    // Formats results from our backend into a format select2 expects
    function formatResults(data) {
        return { results: data };
    }

    // Formats query from select2 into something our backend expects
    function formatQuery(term) {
        return { q: term };
    }

    // Creates tag path based on tag type.
    function tagPath() {
        return "/reflections/" + reflectionID + "/tags/";
    }

    function tagTypeParam (type) {
        return "?type=" + type;
    }

    // Add tag to reflection and UI.
    function addTag(type, tag) {
        $.ajax({
            url: tagPath() + tagTypeParam(type),
            type: enums.ajax.POST,
            data: { tag: tag },
            dataType: enums.ajax.JSON
        }).success(function () {
            saveFeedback(type, true);
        }).error(function (data) {
            saveFeedback(type, false);
        });
    }

    // Add public tag to reflection and UI.
    function addPublicTag(tag) {
        addTag(tagType.public, tag);
    }

    // Add private tag to reflection and UI.
    function addPrivateTag(tag) {
        addTag(tagType.private, tag);
    }

    // Remove tag to reflection and UI.
    function removeTag(type, tag) {
        var path = tagPath() + tag.text + tagTypeParam(type);
        $.ajax({
            url: path,
            type: enums.ajax.DELETE,
            data: { tag: tag },
            dataType: enums.ajax.JSON
        }).success(function () {
            removeFeedback();
        }).error(function (data) {

            alert("Something went wrong");
        });
    }

    // animate feedback to the dom on tag save
    function saveFeedback(type, success) {
        var tagNumElem = $("#iris-reflection-card-" + reflectionID).find(".tag-num"),
            tagNum =  parseInt(tagNumElem.html()) + 1,
            element = type == "private" ? $(selector).find(".private .select2-choices") : $(selector).find(".public .select2-choices"),
            color = success ? "#dff0d8" : "#f2dede",
            tagLabel = $("#refelection-tag-label-" + reflectionID);
        tagLabel.show();
        element.effect("highlight", { color: color }, 1200);
        tagNumElem.html(tagNum);
    }

    // animate feedback to the dom on tag remove
    function removeFeedback() {
        var tagNumElem = $("#iris-reflection-card-" + reflectionID).find(".tag-num"),
            tagNum =  parseInt(tagNumElem.html()) - 1,
            tagLabel = $("#refelection-tag-label-" + reflectionID);
        if (tagNum == 0) {
          tagLabel.hide();
        }
        tagNumElem.html(tagNum);
    }

    // Remove public tag to reflection and UI.
    function removePublicTag(tag) {
        removeTag(tagType.public, tag);
    }

    // Remove private tag to reflection and UI.
    function removePrivateTag(tag) {
        removeTag(tagType.private, tag);
    }

    function allowedToEdit() {
        if ( editable || tags_access === "all" ) {
            return false;
        } else {
            return true;
        }
    }

    // Set up private select
    privateSelect.select2(baseConfig(tagType.private, privateTags));

    // Set up initial values
    privateSelect.select2("val", privateTags);

    privateSelect.on("change", function (evt) {
        if (!!evt.added && evt.added.text) {
            addPrivateTag(evt.added);
        } else if (!!evt.added && !evt.added.text) {
            $(selector).find(".private .select2-search-choice").last().remove();
        }

        if (!!evt.removed) {
            removePrivateTag(evt.removed);
        }
    });

    // Set up public select
    publicSelect.select2(baseConfig(tagType.public, publicTags));

    // Set up initial values
    publicSelect.select2("val", publicTags);

    // test if public tags should be editable
    publicSelect.select2("readonly", allowedToEdit());

    publicSelect.on("change", function (evt) {
        if (!!evt.added && evt.added.text) {
            addPublicTag(evt.added);
        } else if (!!evt.added && !evt.added.text) {
            $(selector).find(".public .select2-search-choice").last().remove();
        }

        if (!!evt.removed) {
            removePublicTag(evt.removed);
        }
    });

    // Close select when containing modal closes
    $(selector).on("hide", function () {
        privateSelect.select2("close");
        publicSelect.select2("close");
    });

    // Close select when lose focus
    $(selector).on("click", function () {
        publicSelect.select2("close");
        privateSelect.select2("close");
    });
};
