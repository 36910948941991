import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders, jsonHeaders } from '../../routes';

export const commentRoute = (args: {
  commentId: Atlas.CommentID,
}): string => `/api/v0/comments/${args.commentId}`;

export type FindCommentParams = {
  commentId: Atlas.CommentID;
};

export type FindCommentArguments = {
  params: FindCommentParams;
};

export type FindCommentResponse = {
  data: Atlas.Comment;
};

export const findComment = async (
  args: FindCommentArguments,
): Promise<FindCommentResponse> => {
  const { commentId } = args.params;

  const url = commentRoute({ commentId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findComment');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Comment>(body.data);

  return { data };
};

export interface UpdateCommentParams {
  commentId: Atlas.CommentID;
}

export type UpdateCommentBody = Partial<Atlas.Comment>;

export interface UpdateCommentArguments {
  params: UpdateCommentParams;
  body: UpdateCommentBody;
}

export interface UpdateCommentResponse {
  data: Atlas.ExpandedComment;
}

export const updateComment = async (
  args: UpdateCommentArguments,
): Promise<UpdateCommentResponse> => {
  const { commentId } = args.params;

  const url = commentRoute({ commentId });

  const response = await fetch(url, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updateComment');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.ExpandedComment>(body.data);

  return { data };
};
