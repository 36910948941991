import { useMutation, UseMutationResult } from 'react-query';
import { updateDiscussion, UpdateDiscussionBody, UpdateDiscussionParams, UpdateDiscussionResponse } from '../../../api/discussions/[discussionId]';
import { queryClient } from '../../withQueryClient';

const useUpdateDiscussionMutation = (
  params: UpdateDiscussionParams
): UseMutationResult<UpdateDiscussionResponse, unknown, UpdateDiscussionBody, unknown> => {
  const mutation = useMutation((body: UpdateDiscussionBody) => {
    return updateDiscussion({ params, body });
  }, {
    onSuccess: (result) => {
      const discussion = result.data;
      queryClient.setQueryData(['discussions', discussion.id], result);
      queryClient.invalidateQueries(['groups', discussion.group_id, 'discussions']);
    },
  });

  return mutation;
};

export default useUpdateDiscussionMutation;
