import { Placement } from '@popperjs/core';
import React, { useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import { useClickAway, useKeyPressEvent } from 'react-use';

export type DropdownProps = {
  target?: HTMLElement | null;
  children: React.ReactNode;
  placement?: Placement;
  close?: () => void;
};

export const DropdownOpen: React.FC<DropdownProps> = (props) => {
  const { target, children, close, placement } = props;

  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const popperRef = useRef<HTMLElement | null>(null);

  useClickAway(popperRef, (e) => {
    if (target?.contains(e.target as Element)) { return; }

    close?.();
  });

  useKeyPressEvent('Escape', () => {
    close?.();
  });

  const { styles, attributes } = usePopper(target, popperElement, {
    placement: placement ?? 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'preventOverflow',
        options: {
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  return (
    <div
      ref={(ref) => {
        setPopperElement(ref);
        popperRef.current = ref;
      }}
      className="tw-z-10"
      style={styles.popper}
      {...attributes.popper}
    >
      {children}
    </div>
  );
};
