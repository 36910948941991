import React from 'react';
import * as Atlas from '../../common/types/Atlas';
import Discussion from '../discussions/Discussion';
import { getUsersGroupMemberRole, userHasRole } from '../../common/utils/group-permissions';
import { withQueryClient } from '../../common/hooks/withQueryClient';
import useFindGroupQuery from '../../common/hooks/api/groups/useFindGroupQuery';
import useCurrentUserQuery from '../../common/hooks/api/useCurrentUserQuery';
import useFindDiscussionQuery from '../../common/hooks/api/discussions/useFindDiscussionQuery';
import withCurrentUser from '../../common/hooks/withCurrentUser';
import Spinner from '../../common/components/Spinner';
import { Button } from '../../common/components/Button';
import { Alert } from '../../common/components/BSAlert';

interface DiscussionSnippetProps {
  groupId: Atlas.GroupID;
  pageId: Atlas.PageID;
  discussionId: Atlas.DiscussionID;
  initiallyOpen?: boolean;
}

const DiscussionSnippet = (props: DiscussionSnippetProps): JSX.Element => {
  const { groupId, pageId, discussionId, initiallyOpen, } = props;

  const userQuery = useCurrentUserQuery();
  const groupQuery = useFindGroupQuery(groupId);
  const discussionQuery = useFindDiscussionQuery({ discussionId });

  const user = userQuery.data;
  const group = groupQuery.data?.data;
  const discussion = discussionQuery.data?.data;

  if (userQuery.isLoading || groupQuery.isLoading || discussionQuery.isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center p-5">
        <Spinner color="info" />
      </div>
    );
  }

  if (!user || !group || !discussion) {
    const handleRetryClick = () => {
      if (userQuery.isError) { userQuery.refetch(); }
      if (groupQuery.isError) { groupQuery.refetch(); }
      if (discussionQuery.isError) { discussionQuery.refetch(); }
    };

    return (
      <Alert color="warning">
        {__('Oops! Something went wrong.')}
        <Button className="ml-3" onClick={handleRetryClick}>
          Retry
        </Button>
      </Alert>
    );
  }

  if (discussion.delete_state) {
    return (
      <Alert color="danger">
        {__('This discussion has been deleted.')}
      </Alert>
    );
  }

  const canManageDiscussions = (() => {
    if (!user) { return false; }
    if (user.admin === Atlas.AdministratorType.Super) { return true; }

    if (!group) { return false; }

    const groupInfo = group.group;
    if (!groupInfo) { return false; }

    const userMemberRole = getUsersGroupMemberRole(groupInfo, user.id, user.organization_id);
    if (!userMemberRole) { return false; }

    const requiredMemberRole = groupInfo.discussions_cud_role;

    return userHasRole(userMemberRole, requiredMemberRole);
  })();

  return (
    <div className="mb-2">
      <Discussion
        initiallyOpen={initiallyOpen}
        discussion={discussion}
        checkPermissions={canManageDiscussions}
        discussionAction={{ attached_to_page: pageId }}
      />
    </div>
  );
};

export default withQueryClient(withCurrentUser(DiscussionSnippet));
