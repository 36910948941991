import React from 'react';

const className = 'tw-flex tw-items-center tw-gap-4 tw-px-3 tw-py-2 tw-w-full tw-rounded-xl tw-cursor-pointer hover:tw-bg-base-200';

interface SearchResultButtonProps {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const SearchResultButton = (props: SearchResultButtonProps) => {
  const { children, onClick } = props;

  return (
    <button type="button" className={`${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

interface SearchResultLinkProps {
  href: string;
  children: React.ReactNode;
}

export const SearchResultLink = (props: SearchResultLinkProps) => {
  const { href, children } = props;

  return (
    <a href={href} className={`${className} focus:tw-no-underline focus:tw-text-inherit hover:tw-no-underline hover:tw-text-inherit`}>
      {children}
    </a>
  );
};
