/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const cardVariant = cva('card');

export type CardVariantProps = VariantProps<typeof cardVariant>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Card = ({
  className,
  ...props
}: CardVariantProps & DivProps) => (
  <div
    {...props}
    className={cx(className, cardVariant())}
  />
);
