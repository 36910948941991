import React from 'react';
import styled from '@emotion/styled';

const StyledRecordingIcon = styled.span`
  @keyframes blink {
    from { opacity: 1; }
    to   { opacity: .1; }
  }

  animation: blink 1s infinite ease-in alternate;
  color: #dd1144;
  margin-right: .25rem;
`;

const RecordingIcon = (props: object) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledRecordingIcon {...props}>•</StyledRecordingIcon>
);

export default RecordingIcon;
