/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useMemo, useEffect, useCallback,
} from 'react';

import TimelineSegments from '../TimelineSegments';

import useRandomId from '../../hooks/useRandomId';
import useVideoPlayer from '../../jwplayer/useVideoPlayer';
import * as Atlas from '../../../common/types/Atlas';
import { Popover, PopoverBody } from '../../../common/components/BSPopover';

const timeInVideoToVideoSegment = (timeInVideo: Atlas.TimeInVideo) => ({
  video_id: timeInVideo.video_id,
  start: timeInVideo.time,
  duration: 0,
});

const videoSegmentToTimeInVideo = (videoSegment: Atlas.VideoSegment) => ({
  video_id: videoSegment.video_id,
  time: videoSegment.start,
});

interface FormCounterCountProps {
  counterValue: Atlas.CounterValue;
  onChange: (args: { choice: string; clicks: Atlas.TimeInVideo[]; }) => void;
  onIncrement?: (choice: string) => void;
}

const FormCounterCount = (props: FormCounterCountProps) => {
  const { counterValue, onChange, onIncrement } = props;
  const { choice } = counterValue;

  const id = useRandomId();

  const [popoverTarget, setPopoverTarget] = useState<HTMLDivElement | null>(null);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const clickCount = counterValue.clicks.length;

  useEffect(() => {
    if (!clickCount && popoverOpen) {
      setPopoverOpen(false);
    }
  }, [clickCount, popoverOpen]);

  const handleToggle = () => {
    if (!clickCount) { return; }

    setPopoverOpen((open) => !open);
  };

  const handleChoiceIncrement = () => {
    onIncrement?.(choice);
  };

  const handleChange = (timeline: Atlas.VideoSegment[]) => {
    const clicks = timeline.map(videoSegmentToTimeInVideo);

    onChange({ choice, clicks });
  };

  return (
    <div className="tw-flex tw-gap-2 tw-mb-3">
      <div className="tw-flex tw-items-center tw-flex-1">
        {choice}
      </div>

      <div className="tw-flex tw-items-center tw-gap-2">
        {clickCount ? (
          <>
            <div
              ref={setPopoverTarget}
              id={id}
              role="button"
              tabIndex={0}
              className="tw-badge tw-badge-info"
              onClick={handleToggle}
            >
              {clickCount}
            </div>

            <Popover target={popoverTarget} placement="left" isOpen={popoverOpen}>
              <PopoverBody>
                <TimelineSegments
                  timeline={counterValue.clicks.map(timeInVideoToVideoSegment)}
                  onChange={handleChange}
                />
              </PopoverBody>
            </Popover>
          </>
        ) : null}

        <button
          type="button"
          className="tw-btn tw-btn-sm tw-btn-square"
          onClick={handleChoiceIncrement}
        >
          <i className="icon-plus" />
        </button>
      </div>
    </div>
  );
};

interface FormCounterElementProps {
  formAnswer: Atlas.FormAnswer;
  formElement: Atlas.FormElement;
  onChange: (cb: (fa: Atlas.FormAnswer) => void) => void;
}

const FormCounterElement = (props: FormCounterElementProps): JSX.Element => {
  const { formAnswer, formElement, onChange } = props;
  const { field } = formElement;

  if (!('counter' in field.type)) {
    throw new Error('FormFieldType is incompatible with counter');
  }

  const { choices } = field.type.counter;

  const videoPlayer = useVideoPlayer();

  const getPlayerPosition = useCallback(() => {
    if (!videoPlayer) { return 0; }
    return Math.round(videoPlayer.getPosition() * 1000);
  }, [videoPlayer]);

  const counterValueMap = useMemo(() => (
    (formAnswer.counter_value || []).reduce<Record<string, Atlas.CounterValue>>((acc, cv) => {
      acc[cv.choice] = cv;
      return acc;
    }, {})
  ), [formAnswer.counter_value]);

  const handleChoiceIncrement = videoPlayer ? ((choice: string) => {
    const timeInVideo = {
      video_id: videoPlayer.videoId,
      time: getPlayerPosition(),
    };

    onChange((fa) => {
      const cvm = (fa.counter_value || []).reduce<Record<string, Atlas.CounterValue>>((acc, cv) => {
        acc[cv.choice] = cv;
        return acc;
      }, {});

      const clicks = [
        ...(cvm[choice] || {}).clicks || [],
        timeInVideo,
      ];

      cvm[choice] = { choice, clicks };

      return {
        ...fa,
        counter_value: Object.values(cvm),
      };
    });
  }) : undefined;

  const handleCounterValueChange = (cv: Atlas.CounterValue) => {
    onChange((fa) => {
      const cvm = (fa.counter_value || []).reduce<Record<string, Atlas.CounterValue>>((acc, cv) => {
        acc[cv.choice] = cv;
        return acc;
      }, {});

      cvm[cv.choice] = cv;

      return {
        ...fa,
        counter_value: Object.values(cvm),
      };
    });
  };

  return (
    <div>
      {choices.map((choice, i) => {
        const counterValue = counterValueMap[choice] || { choice, clicks: [] };

        return (
          <FormCounterCount
            key={i}
            counterValue={counterValue}
            onChange={handleCounterValueChange}
            onIncrement={handleChoiceIncrement}
          />
        );
      })}
    </div>
  );
};

export default FormCounterElement;
