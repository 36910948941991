import * as Atlas from '../../types/Atlas';
import { inRange } from '../../utils/utils';
import { parseModelObject } from '../api-parser';
import ApiError from '../error';
import { generateFetchHeaders } from '../routes';

export const commentsRoute = (): string => '/api/v0/comments';

export const commentRoute = (args: {
  commentId: Atlas.CommentID;
}): string => `/api/v0/comments/${args.commentId}`;

export interface DestroyCommentParams {
  commentId: Atlas.CommentID;
}

export interface DestroyCommentArguments {
  params: DestroyCommentParams;
}

export interface DestroyCommentResponse {
  data: Atlas.Comment;
}

export const destroyComment = async (
  args: DestroyCommentArguments,
): Promise<DestroyCommentResponse> => {
  const { commentId } = args.params;

  const url = commentRoute({ commentId });

  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'destroyComment');
  }

  const body = await response.json() as { data: object; };
  const data = parseModelObject<Atlas.Comment>(body.data);

  return { data };
};
