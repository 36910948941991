import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import * as Atlas from '../../common/types/Atlas';
import { PlayerStatus, useJwPlayer } from './JwPlayer';

type OnTime = (position: number) => void;
type GetPosition = () => number;
type GetDuration = () => number;
type Seek = (position: number) => void;
type Play = (videoId?: Atlas.VideoID, position?: number) => void;
type Pause = () => void;

interface UseVideoPlayerArgs {
  onTime?: OnTime;
}

interface VideoPlayerState {
  videoId: Atlas.VideoID;
  playlist: Atlas.VideoID[];
}

type UseVideoPlayer = {
  status?: PlayerStatus;
  getPosition: GetPosition;
  getDuration: GetDuration;
  seek: Seek;
  play: Play;
  pause: Pause;
} & VideoPlayerState;

const useVideoPlayer = (
  args?: UseVideoPlayerArgs,
): UseVideoPlayer | null => {
  const { onTime } = args ?? {};

  const [state, setState] = useState<VideoPlayerState | null>(null);

  const onJwTimeOrSeek = useMemo(() => {
    if (!onTime) { return undefined; }

    return (event: jwplayer.TimeParam | jwplayer.SeekParam) => {
      onTime(event.position);
    };
  }, [onTime]);

  const onPlaylistItem = useCallback((event: jwplayer.PlaylistItemParam) => {
    const item = event.item as Record<string, unknown> | undefined;
    const videoId = item?.video_id;

    if (typeof videoId === 'number') {
      setState((s) => ({
        videoId,
        playlist: s?.playlist ?? [],
      }));
    } else {
      setState(null);
    }
  }, []);

  const onPlaylist = useCallback((event: jwplayer.PlaylistParam) => {
    const playlist = event.playlist.map((item: { video_id: number }) => (
      item.video_id
    ));

    setState((s) => ({
      videoId: s?.videoId ?? playlist[0],
      playlist,
    }));
  }, []);

  const jwplayer = useJwPlayer({
    onPlaylistItem,
    onTime: onJwTimeOrSeek,
    onSeek: onJwTimeOrSeek,
    onPlaylist,
  });

  useEffect(() => {
    if (!jwplayer?.player) { return; }

    const item = jwplayer.player.getPlaylistItem() as Record<string, unknown> | undefined;
    if (!item) { return; }

    const videoId = item?.video_id;
    if (typeof videoId === 'number') {
      setState((s) => ({
        videoId,
        playlist: s?.playlist ?? [],
      }));
    } else {
      setState(null);
    }
  }, [jwplayer?.player]);

  return useMemo(() => {
    if (!state) { return null; }

    const getPosition = () => {
      if (!jwplayer?.player) { return 0; }

      return jwplayer.player.getPosition();
    };

    const getDuration = () => {
      if (!jwplayer?.player) { return 0; }

      return jwplayer.player.getDuration();
    };

    const seek = (position: number) => {
      if (!jwplayer?.player) { return; }

      jwplayer.player.seek(position);
    };

    const play = (videoId?: Atlas.VideoID, position?: number) => {
      const { player } = jwplayer ?? {};
      if (!player) { return; }

      if (!videoId) {
        player.play();
        return;
      }

      if (state.videoId === videoId) {
        if (typeof position === 'number') {
          player.seek(position);
        }
      } else {
        const playlist = player.getPlaylist();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const playlistItemIndex = playlist.findIndex((item) => item.video_id === videoId);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        player.playlistItem(playlistItemIndex).once('play', () => {
          if (typeof position === 'number') {
            player.seek(position);
          }
        });
      }
    };

    const pause = () => {
      const { player } = jwplayer ?? {};
      if (!player) { return; }

      player.pause();
    };

    return ({
      ...state,
      status: jwplayer?.status,
      getPosition,
      getDuration,
      seek,
      play,
      pause,
    });
  }, [jwplayer, state]);
};

export default useVideoPlayer;
