import { FormJob, JobStage, ReflectionID } from '../types/Atlas';

export const isActiveFormJob = (job: FormJob) => [
  JobStage.Pending,
  JobStage.Acknowledged,
  JobStage.Processing,
].includes(job.stage);

export const isFormJobForReflection = (job: FormJob, reflectionId: ReflectionID) => (
  job.input.reflection_id === reflectionId
);

export const isActiveFormJobForReflection = (job: FormJob, reflectionId: ReflectionID) => (
  isFormJobForReflection(job, reflectionId) && isActiveFormJob(job)
);
