/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const cardTextVariant = cva('card-text');

export type CardTextVariantProps = VariantProps<typeof cardTextVariant>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const CardText = ({
  className,
  ...props
}: CardTextVariantProps & DivProps) => (
  <div
    {...props}
    className={cx(className, cardTextVariant())}
  />
);
