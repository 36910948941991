import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from '../../../../common/components/BSDropdown';
import { cloneGroupAssignment, destroyGroupAssignment } from '../../../../common/api/groups/assignments';
import CurrentUserContext from '../../../../common/contexts/CurrentUserContext';
import useGroupQuery from '../../../../common/hooks/api/groups/useFindGroupQuery';
import useGroupAssignmentQuery from '../../../../common/hooks/api/groups/useFindGroupAssignmentQuery';
import { useLocation } from 'react-router-dom';
import { getAssignmentsBasePath } from '../../../../common/utils/getAssignmentsBasePath';
import Spinner from '../../../../common/components/Spinner';
import { Row } from '../../../../common/components/Row';
import { Col } from '../../../../common/components/Col';
import * as Atlas from '../../../../common/types/Atlas';

interface GroupAssignmentLayoutProps {
  children: React.ReactNode;
}

const GroupAssignmentLayout = (props: GroupAssignmentLayoutProps): JSX.Element => {
  const params = useParams<{
    groupId: string;
    assignmentId: string;
  }>();

  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);
  const groupId = Number(params.groupId);
  const assignmentId = Number(params.assignmentId);

  const groupQuery = useGroupQuery(groupId);
  const group = groupQuery.data?.data;

  const groupAssignmentQuery = useGroupAssignmentQuery(groupId, assignmentId);
  const assignment = groupAssignmentQuery.data?.data;

  const { user } = useContext(CurrentUserContext);

  const navigate = useNavigate();
  const [state, setState] = useState({ cloning: false, destroying: false, dropdownOpen: false });

  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (!ref.current || !state.dropdownOpen) { return; }
    $(ref.current).tooltip({ container: "body" });

    return () => {
      if (!ref.current) { return; }
      $(ref.current).tooltip('destroy');
    };
  });

  if (state.cloning || state.destroying) {
    return (
      <div className="d-flex align-items-center justify-content-center my-5">
        <Spinner color="info" />
      </div>
    );
  }

  const isAssessor = user && group && (
    group.group?.administrators.user_ids.includes(user.id) ||
    group.group?.assessors.user_ids.includes(user.id)
  );

  const handleCloneAssignmentClick = assignment ? (async () => {
    if (!confirm(__('Are you sure you want to clone this assignment?'))) { return; }

    setState(s => ({ ...s, cloning: true }));
    await cloneGroupAssignment({
      params: { groupId, assignmentId: assignment.id }
    }).then(({ data: newAssignment }) => {
      navigate(`/${basePath}/${newAssignment.group_id}/assignments/${newAssignment.id}/edit`);
    }, () => {
      setState(s => ({ ...s, cloning: false }));
    });
  }) : undefined;

  const handleDeleteAssignmentClick = assignment ? (async () => {
    if (!confirm(__('Are you sure you want to delete this assignment?'))) { return; }
    setState(s => ({ ...s, destroying: true }));

    await destroyGroupAssignment({
      params: { groupId, assignmentId: assignment.id }
    }).then(() => {
      navigate(`/${basePath}/${groupId}/assignments`);
    }, () => {
      setState(s => ({ ...s, destroying: false }));
    });
  }) : undefined;

  const toggleDropdown = () => {
    setState(s => ({ ...s, dropdownOpen: !s.dropdownOpen }));
  };

  return (
    <div>
      <Row>
        <Col style={{ boxSizing: 'border-box' }}>
          <div className="d-flex align-items-center justify-content-between tw-iris-element-box mb-3">
            <Link className="tw-btn tw-btn-sm" to={`/${basePath}/${groupId}/assignments`}>
              <i className="fa fa-chevron-left mr-2" />
              {__('Back')}
            </Link>

            <div className="d-flex align-items-center">
              <h5 className="text-muted font-weight-light mx-2 my-0">
                {assignment?.name}
              </h5>

              {isAssessor && assignment && basePath !== 'unity_pathways' ? (
                <Dropdown isOpen={state.dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle>
                    <i className="fa fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem header className="m-0">
                      {__('Manage Assignment')}
                    </DropdownItem>
                    {assignment.submissions === 0 || (user && user.admin === Atlas.AdministratorType.Super) ? (
                      <Link style={{ boxSizing: 'border-box' }} className="dropdown-item" to={`/${basePath}/${assignment.group_id}/assignments/${assignment.id}/edit`}>
                        {__('Edit')}
                      </Link>
                    ) : (
                      <div ref={ref} title={__('You cannot edit assignments that already have submissions')}>
                        <DropdownItem disabled>
                          {__('Edit')}
                        </DropdownItem>
                      </div>
                    )}
                    <DropdownItem onClick={handleCloneAssignmentClick}>
                      {__('Clone')}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem className="text-danger" onClick={handleDeleteAssignmentClick}>
                      {__('Delete')}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col style={{ boxSizing: 'border-box' }}>
          {props.children}
        </Col>
      </Row>
    </div>
  );
};

export default GroupAssignmentLayout;
