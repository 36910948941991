import React from 'react';
import * as Atlas from '../../../common/types/Atlas';
import ShareReflection from './ShareReflection';
import { Modal, ModalBox, ModalHeader } from '../../../common/components/TailwindModal';

interface ReflectionSharesProps {
  reflectionId: Atlas.ReflectionID;
  title: string;
  isOpen: boolean;
  toggle: () => void;
}

const ShareReflectionModal = (props: ReflectionSharesProps) => {
  const {
    reflectionId,
    title,
    isOpen,
    toggle,
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalBox className="tw-overflow-visible">
        <ModalHeader onClose={toggle}>
          {title}
        </ModalHeader>

        <ShareReflection reflectionId={reflectionId} />
      </ModalBox>
    </Modal>
  );
};

export default ShareReflectionModal;
