import { useMutation } from 'react-query';
import { createReflectionAttachment, CreateReflectionAttachmentArgs, CreateReflectionAttachmentResponse, FindReflectionAttachmentsResponse } from '../../../api/reflections/attachments';
import { queryClient } from '../../withQueryClient';

const useCreateReflectionAttachmentMutation = () => {
  const mutation = useMutation<CreateReflectionAttachmentResponse, unknown, CreateReflectionAttachmentArgs, unknown>(
    createReflectionAttachment,
    {
      onSuccess: async (result) => {
        const attachment = result.data;

        if (attachment.reflection_id) {
          await queryClient.invalidateQueries<FindReflectionAttachmentsResponse>(['reflections', attachment.reflection_id, 'attachments']);
        }
      },
    },
  );

  return mutation;
};

export default useCreateReflectionAttachmentMutation;
