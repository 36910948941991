import { useMutation, UseMutationResult } from 'react-query';
import { destroyMilestone, DestroyMilestoneArguments, DestroyMilestoneResponse } from '../../api/index';
import { queryClient } from '../../../../../common/hooks/withQueryClient';
import * as Atlas from '../../../../../common/types/Atlas';

type UseDestroyMilestoneMutationResult = 
  UseMutationResult<DestroyMilestoneResponse, unknown, DestroyMilestoneArguments>

const useDestroyMilestoneMutation = (
  groupId: Atlas.GroupID,
): UseDestroyMilestoneMutationResult => {
  const mutation = useMutation(destroyMilestone, {
    onSuccess: (data) => {
      const milestoneId = data.data.id;
      queryClient.invalidateQueries(['groups', groupId, 'milestones', milestoneId]);
      queryClient.invalidateQueries(['groups', groupId, 'milestones']);
    }
  });
  return mutation;
};

export default useDestroyMilestoneMutation;