/*global $,require*/

module.exports = function (node, reflectionID, i18nCtx) {
    "use strict";
    if (node !== undefined && node.video !== undefined) {
        var video = node.video;
        var domElement = $("#video-in-editor-" + node.video.id).find(".advanced-options-container");
        if (window.webpack !== undefined && domElement.length !== 0) {
            // Enable the help
            $("#video-in-editor-" + node.video.id).find(".advanced-options-help").tooltip({
                title: i18nCtx.help,
                placement: "top",
                trigger: "click hover"
            });

            var ref = { current: null };
            var props = {
                ref,
                isDual: node.dual,
                prepState: node.prepState,
                clipSource: node.clipSource,
                reflectionId: parseInt(reflectionID),
                videoId: video.id,
                preflightChecksPassed(passed) {
                    if (!passed) {
                        $("#video-in-editor-" + node.video.id).find(".advanced-button").tooltip({
                            title: i18nCtx.opNotAvailable,
                            container: "body"
                        });
                    }
                },
                notifySuccessfulOperation() {
                    $("#video-in-editor-" + node.video.id).find(".advanced-button").tooltip({
                        title: i18nCtx.opCompleted,
                        container: "body"
                    });
                    location.reload();
                },
                notifyFailedOperation(translatedError) {
                    $("#video-in-editor-" + node.video.id).find(".advanced-button").tooltip({
                        title: translatedError || __("The requested action couldn't be completed."),
                        container: "body"
                    });
                }
            };

            var React = webpack.React;
            var ReactDOMClient = webpack.ReactDOMClient;
            var component = React.createElement(window.webpack.reflections.AdvancedOptions, props);
            var root = ReactDOMClient.createRoot(domElement.get(0));
            root.render(component);

            return ref;
        }
    }
};
