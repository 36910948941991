import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders, jsonHeaders } from '../../routes';

export const discussionRoute = (args: {
  discussionId: Atlas.DiscussionID,
}): string => `/api/v0/discussions/${args.discussionId}`;

export interface FindDiscussionParams {
  discussionId: Atlas.DiscussionID;
}

export interface FindDiscussionArguments {
  params: FindDiscussionParams;
}

export interface FindDiscussionResponse {
  data: Atlas.Discussion;
}

export const findDiscussion = async (
  args: FindDiscussionArguments,
): Promise<FindDiscussionResponse> => {
  const { discussionId } = args.params;

  const url = discussionRoute({ discussionId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findDiscussion');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Discussion>(body.data);

  return { data };
};

export interface UpdateDiscussionParams {
  discussionId: Atlas.DiscussionID;
}

export type UpdateDiscussionBody = Partial<Pick<
  Atlas.Discussion,
  'title' | 'locked' | 'notification_level' | 'user_visibility'
>>;

export interface UpdateDiscussionArguments {
  params: UpdateDiscussionParams;
  body: UpdateDiscussionBody;
}

export interface UpdateDiscussionResponse {
  data: Atlas.Discussion;
}

export const updateDiscussion = async (
  args: UpdateDiscussionArguments,
): Promise<UpdateDiscussionResponse> => {
  const { discussionId } = args.params;

  const url = discussionRoute({ discussionId });

  const response = await fetch(url, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updateDiscussion');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Discussion>(body.data);

  return { data };
};

export interface DestroyDiscussionParams {
  discussionId: Atlas.DiscussionID;
}

export interface DestroyDiscussionArguments {
  params: DestroyDiscussionParams;
}

export interface DestroyDiscussionResponse {
  data: Atlas.Discussion;
}

export const destroyDiscussion = async (
  args: DestroyDiscussionArguments,
): Promise<DestroyDiscussionResponse> => {
  const { discussionId } = args.params;

  const url = discussionRoute({ discussionId });

  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'destroyDiscussion');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Discussion>(body.data);

  return { data };
};
