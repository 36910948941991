/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const badge = cva('badge', {
  variants: {
    color: {
      primary: 'badge-primary',
      secondary: 'badge-secondary',
      success: 'badge-success',
      danger: 'badge-danger',
      warning: 'badge-warning',
      info: 'badge-info',
      light: 'badge-light',
      dark: 'badge-dark',
    },
    pill: {
      true: 'badge-pill',
    },
  },
  defaultVariants: {
    color: 'secondary',
  },
});

export type BadgeVariantProps = VariantProps<typeof badge>;

type SpanProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

export const Badge = ({
  color,
  pill,
  className,
  ...props
}: BadgeVariantProps & SpanProps) => (
  <span
    {...props}
    className={cx(className, badge({
      color, pill,
    }))}
  />
);
