import { z } from 'zod';
import { generateFetchHeaders } from '../../../common/api/routes';
import ApiError from '../../../common/api/error';

const stsCredentials = z.object({
  AccessKeyId: z.string(),
  SecretAccessKey: z.string(),
  SessionToken: z.string(),
  Expiration: z.date({ coerce: true }),
});

export type StsCredentials = z.infer<typeof stsCredentials>;

export const getCredentials = async () => {
  const response = await fetch('/admin/reports_browser', {
    method: 'POST',
    headers: generateFetchHeaders(),
  });

  if (response.ok) {
    return stsCredentials.parse(await response.json());
  }

  throw new ApiError(response.status, 'getCredentials');
};
