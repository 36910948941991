import React from 'react';
import { ChartsData } from './types';
import MetricsLineChart from './metrics-line-chart';
import MetricsBarChart from './metrics-bar-chart';
import { MetricsCharts } from '../../common/types/Atlas';
import MetricsControls from './metrics-controls';

const renderChart = ({
  chartType,
  chartsData,
  dateStart,
  dateEnd,
}: {
  chartType: MetricsCharts;
  chartsData: ChartsData;
  dateStart?: string;
  dateEnd?: string;
}) => {
  switch (chartType) {
    case MetricsCharts.Aggregates:
      return (
        <MetricsBarChart
          chartsData={chartsData}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
      );

    case MetricsCharts.LineCharts:
      return (
        <MetricsLineChart
          chartsData={chartsData}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
      );

    default:
      return null;
  }
};

const MetricsDashboardView: React.FC<{
  chartType: MetricsCharts;
  chartsData: ChartsData;
  minDate: string;
  maxDate: string;
  dateStart?: string;
  dateEnd?: string;
  onDateStartChange: (date: string) => void;
  onDateEndChange: (date: string) => void;
  onChartTypeChange: (chartType: string) => void;
  onExportClick: () => void;
}> = ({
  chartType,
  chartsData,
  minDate,
  maxDate,
  dateStart,
  dateEnd,
  onDateStartChange,
  onDateEndChange,
  onChartTypeChange,
  onExportClick,
}) => (
  <div className="tw-flex tw-flex-col tw-gap-4">
    <MetricsControls
      minDate={minDate}
      maxDate={maxDate}
      dateStart={dateStart}
      dateEnd={dateEnd}
      chartType={chartType}
      onDateStartChange={onDateStartChange}
      onDateEndChange={onDateEndChange}
      onChartTypeChange={onChartTypeChange}
      onExportClick={onExportClick}
    />

    <div className="tw-iris-element-box tw-px-4 tw-py-6">
      {renderChart({
        chartType,
        chartsData,
        dateStart,
        dateEnd,
      })}
    </div>
  </div>
);

export default MetricsDashboardView;
