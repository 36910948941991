import React from 'react';
import useFindReflectionsInfiniteQuery from '../../../../../common/hooks/api/reflections/useFindReflectionsInfiniteQuery';
import useSearchValue from '../../../hooks/useSearchValue';
import { ResourceSearchResult } from '../../common/types';
import SearchResultsError from './common/search-results-error';
import SearchResultsLoader from './common/search-results-loader';
import SearchResultsSuccess from './common/search-results-success';

const ReflectionSearchResults: React.FC<{
  query: string;
  onResultClick: (result: ResourceSearchResult) => void;
}> = (props) => {
  const {
    query,
    onResultClick,
  } = props;

  const search = useSearchValue(query);

  const searchQuery = useFindReflectionsInfiniteQuery({
    params: { search: query },
  }, {
    enabled: search.enabled,
    staleTime: 60000,
  });

  switch (searchQuery.status) {
    case 'success': {
      const results = searchQuery.data.pages.reduce<ResourceSearchResult[]>((acc, data) => [
        ...acc,
        ...data.data.map<ResourceSearchResult>((reflection) => ({
          type: 'reflection',
          data: reflection,
        })),
      ], []);

      return (
        <SearchResultsSuccess
          results={results}
          isLoading={searchQuery.isFetching}
          hasMoreResults={searchQuery.hasNextPage}
          onLoadMoreClick={searchQuery.fetchNextPage}
          onResultClick={onResultClick}
        />
      );
    }

    case 'error': return (
      <SearchResultsError />
    );

    default: return (
      <SearchResultsLoader />
    );
  }
};

export default ReflectionSearchResults;
