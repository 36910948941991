import React, { useEffect, useState } from 'react';
import * as Atlas from '../../../../common/types/Atlas';

interface ReflectionEmbedModeFormProps {
  embedMode: Atlas.EmbedMode;
  onSubmit: (embedMode: Atlas.EmbedMode) => Promise<void>;
}

const ReflectionEmbedModeForm = (props: ReflectionEmbedModeFormProps) => {
  const { embedMode, onSubmit } = props;

  const [nextEmbedMode, setNextEmbedMode] = useState(embedMode);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setNextEmbedMode(embedMode);
  }, [embedMode]);

  const handleEmbedModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.EmbedMode.Enabled:
      case Atlas.EmbedMode.Disabled: break;
      default: return;
    }

    setNextEmbedMode(value);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (updating) { return; }

    setUpdating(true);
    onSubmit(nextEmbedMode).catch(() => {
      setNextEmbedMode(embedMode);
    }).finally(() => {
      setUpdating(false);
    });
  };

  const formDisabled = (
    nextEmbedMode === embedMode
  );

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="tw-mb-2">
        <select
          className="tw-select tw-select-sm tw-select-bordered"
          value={nextEmbedMode}
          onChange={handleEmbedModeChange}
        >
          <option value={Atlas.EmbedMode.Enabled}>{__('Enabled')}</option>
          <option value={Atlas.EmbedMode.Disabled}>{__('Disabled')}</option>
        </select>
      </div>

      <div className="tw-flex tw-justify-start tw-mt-3">
        <button
          type="submit"
          className="tw-btn tw-btn-sm tw-btn-success"
          disabled={formDisabled}
        >
          {updating ? <span className="tw-loading" /> : null}
          {__('Update Embed Mode')}
        </button>
      </div>
    </form>
  );
};

export default ReflectionEmbedModeForm;
