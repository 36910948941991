import { isPresent } from '../../../../common/utils/utils';

export const streamHasVideo = (stream: MediaStream) => stream.getVideoTracks().length !== 0;

export const streamHasAudio = (stream: MediaStream) => stream.getAudioTracks().length !== 0;

const prepareStream = (tracks: MediaStreamTrack[]) => {
  if (!tracks.length) { return null; }

  return new MediaStream(tracks);
};

export const prepareStreamsForVideo = ({
  userStream,
  displayStream,
}: {
  userStream?: MediaStream;
  displayStream?: MediaStream;
}) => {
  const userVideo: MediaStreamTrack | undefined = userStream?.getVideoTracks()[0];
  const userAudio: MediaStreamTrack | undefined = userStream?.getAudioTracks()[0];
  const displayVideo: MediaStreamTrack | undefined = displayStream?.getVideoTracks()[0];
  const displayAudio: MediaStreamTrack | undefined = displayStream?.getAudioTracks()[0];

  const leftVideoTrack = displayVideo ? displayVideo : userVideo;
  const leftAudioTrack = userAudio ? userAudio : displayAudio;
  const leftTracks = [leftVideoTrack, leftAudioTrack].filter(isPresent);

  const rightVideoTrack = displayVideo ? userVideo : null;
  const rightAudioTrack = userAudio ? displayAudio : null;
  const rightTracks = [rightVideoTrack, rightAudioTrack].filter(isPresent);

  const leftStream = prepareStream(leftTracks);
  const rightStream = prepareStream(rightTracks);

  return {
    leftStream: leftStream ? leftStream : rightStream,
    rightStream: leftStream ? rightStream : null,
  };
};

export const generateUuidv4 = () => {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (
    c
      ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
  ).toString(16));
};
