import { useQuery, UseQueryResult } from 'react-query';
import { findGroupForms, FindGroupFormsParams, FindGroupFormsResponse } from '../../../api/groups/[groupId]/forms';

const useFindGroupFormsQuery = (
  params: FindGroupFormsParams,
  options?: { enabled?: boolean; },
): UseQueryResult<FindGroupFormsResponse> => (
  useQuery(
    ['groups', params.groupId, 'forms', ], 
    () => findGroupForms({ params }),
    { ...options },
  )
);

export default useFindGroupFormsQuery;
