import { useQuery, UseQueryResult } from 'react-query';
import { findAllFormData, FindAllFormDataArguments, FindAllFormDataResponse } from '../../../api/form-datas';

const useFindAllFormDataQuery = (
  args: FindAllFormDataArguments,
): UseQueryResult<FindAllFormDataResponse> => (
  useQuery(['form_data', args], () => findAllFormData(args))
);

export default useFindAllFormDataQuery;
