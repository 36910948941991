import React, { useCallback, useState } from 'react';
import Select from 'react-select/async';
import { debounce } from 'ts-debounce';
import { formReflectionOwners, formReflections, formRespondents } from '../../common/api/forms';
import { FormReflectionOption, selectComponents as formReflectionSelectOption } from '../../common/components/select/FormReflection';
import { FormUserOption, selectComponents as formUserSelectComponents } from '../../common/components/select/FormUser';
import type * as Atlas from '../../common/types/Atlas';
import type { FormReportingFilter } from './types';

interface FormOverviewFiltersProps {
  formId: Atlas.FormID;
  filter: FormReportingFilter;
  onChange(filter: unknown): void;
}

interface FormOverviewFiltersCacheState {
  reflections: Record<Atlas.ReflectionID | string, FormReflectionOption>;
  users: Record<Atlas.UserID | string, FormUserOption>;
}

const FormOverviewFilters = (props: FormOverviewFiltersProps): JSX.Element => {
  const { formId, filter, onChange } = props;

  const { reflectionId, reflectionUserId, respondentUserId } = filter;

  const [cache, setCache] = useState<FormOverviewFiltersCacheState>({
    reflections: {},
    users: {},
  });

  const searchReflections = useCallback(debounce((search: string): Promise<FormReflectionOption[]> => formReflections({ params: { formId, search } }).then(({ data }) => data.map((fr) => ({
    value: fr.id,
    formReflection: fr,
  }))).then((options) => {
    setCache((c) => ({
      ...c,
      reflections: {
        ...c.reflections,
        ...options.reduce((acc, option) => ({
          ...acc,
          [option.value]: option,
        }), {}),
      },
    }));
    return options;
  }), 500), [formId]);

  const loadReflections = useCallback((search: string) => searchReflections(search).then((r) => r), [searchReflections]);

  const searchReflectionOwners = useCallback(debounce((search: string): Promise<FormUserOption[]> => formReflectionOwners({ params: { formId, search } }).then(({ data }) => data.map((fu) => ({
    value: fu.id,
    formUser: fu,
  }))).then((options) => {
    setCache((c) => ({
      ...c,
      users: {
        ...c.users,
        ...options.reduce((acc, option) => ({
          ...acc,
          [option.value]: option,
        }), {}),
      },
    }));
    return options;
  }), 500), [formId]);

  const loadReflectionOwners = useCallback((search: string) => searchReflectionOwners(search).then((users) => users), [searchReflectionOwners]);

  const searchRespondents = useCallback(debounce((search: string): Promise<FormUserOption[]> => formRespondents({ params: { formId, search } }).then(({ data }) => data.map((fu) => ({
    value: fu.id,
    formUser: fu,
  }))).then((options) => {
    setCache((c) => ({
      ...c,
      users: {
        ...c.users,
        ...options.reduce((acc, option) => ({
          ...acc,
          [option.value]: option,
        }), {}),
      },
    }));
    return options;
  }), 500), [formId]);

  const loadRespondents = useCallback((search: string) => searchRespondents(search).then((users) => users), [searchRespondents]);

  const handleReflectionChange = (option: FormReflectionOption | null) => {
    if (!option) {
      onChange({ ...filter, reflectionId: null });
      return;
    }

    onChange({ ...filter, reflectionId: option.value });
  };

  const handleReflectionOwnerChange = (option: FormUserOption | null) => {
    if (!option) {
      onChange({ ...filter, reflectionUserId: null });
      return;
    }

    onChange({ ...filter, reflectionUserId: option.value });
  };

  const handleRespondeeChange = (option: FormUserOption | null) => {
    if (!option) {
      onChange({ ...filter, respondentUserId: null });
      return;
    }

    onChange({ ...filter, respondentUserId: option.value });
  };

  const reflectionOption = cache.reflections[String(reflectionId)];
  const reflectionOwnerOption = cache.users[String(reflectionUserId)];
  const userOption = cache.users[String(respondentUserId)];

  return (
    <div>
      <label className="tw-mb-3">
        <h6 className="tw-mt-0 tw-mb-2 tw-font-bold tw-text-sm">
          {__('Reflection')}
        </h6>
        <Select
          defaultOptions
          cacheOptions
          isClearable
          placeholder={__('Filter by reflection')}
          noOptionsMessage={() => __('No reflections found')}
          loadOptions={loadReflections}
          components={formReflectionSelectOption}
          value={reflectionOption}
          onChange={handleReflectionChange}
        />
      </label>

      <label className="tw-mb-3">
        <h6 className="tw-mt-0 tw-mb-2 tw-font-bold tw-text-sm">
          {__('Reflection Owner')}
        </h6>
        <Select
          defaultOptions
          cacheOptions
          isClearable
          placeholder={__('Filter by reflection owner')}
          noOptionsMessage={() => __('No users found')}
          loadOptions={loadReflectionOwners}
          components={formUserSelectComponents}
          value={reflectionOwnerOption}
          onChange={handleReflectionOwnerChange}
        />
      </label>

      <label className="tw-mb-0">
        <h6 className="tw-mt-0 tw-mb-2 tw-font-bold tw-text-sm">
          {__('Respondee')}
        </h6>
        <Select
          defaultOptions
          cacheOptions
          isClearable
          placeholder={__('Filter by respondee')}
          noOptionsMessage={() => __('No users found')}
          loadOptions={loadRespondents}
          components={formUserSelectComponents}
          value={userOption}
          onChange={handleRespondeeChange}
        />
      </label>
    </div>
  );
};

export default FormOverviewFilters;
