import type { StateCreator } from 'zustand';

export enum ReflectionTab {
  Comments,
  Files,
  Forms,
  Videos,
}

export type ReflectionTabSlice = {
  activeTab: ReflectionTab;
  changeTab: (tab: ReflectionTab) => void;
};

export const createReflectionTabSlice: StateCreator<
  ReflectionTabSlice
> = (set) => ({
  activeTab: ReflectionTab.Comments,
  changeTab: (tab) => set({ activeTab: tab }),
});
