import { useMutation, UseMutationResult } from 'react-query';
import {
  updateImage, UpdateImageArguments, UpdateImageResponse,
} from '../../../api/images/[imageId]';
import { queryClient } from '../../withQueryClient';

const useUpdateImageMutation = (): UseMutationResult<UpdateImageResponse, unknown, UpdateImageArguments, unknown> => useMutation(
  updateImage,
  {
    onSuccess: (result) => {
      const image = result.data;
      queryClient.setQueryData(['images', image.id], result);

      if (image.group_id) {
        void queryClient.invalidateQueries(['groups', image.group_id], { exact: true });
      }
    },
  },
);

export default useUpdateImageMutation;
