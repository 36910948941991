export const isInternalUrl = (url: URL) => url.hostname === window.location.hostname;

export const buildInternalUrl = (url: string) => {
  if (!url.startsWith('http') && !url.startsWith('/')) {
    return null;
  }

  try {
    const linkUrl = new URL(url, window.location.origin);

    if (!isInternalUrl(linkUrl)) { return null; }

    return linkUrl;
  } catch (e) {
    return null;
  }
};
