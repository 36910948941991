import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../../../api-parser';
import ApiError from '../../../error';
import { PaginationParams, Metadata, searchParams, jsonHeaders, generateFetchHeaders } from '../../../routes';

export const discussionCommentsRoute = (args: {
  discussionId: Atlas.DiscussionID;
}): string => (
  `/api/v0/discussions/${args.discussionId}/comments`
);

export type FindDiscussionCommentsParams = PaginationParams & {
  discussionId: Atlas.DiscussionID;
}

export interface FindDiscussionCommentsArguments {
  params: FindDiscussionCommentsParams;
}

export interface DecoratedExpandedDiscussionComment extends Atlas.ExpandedDiscussionComment {
  replies: Atlas.ExpandedDiscussionComment[];
}

export interface FindDiscussionCommentsResponse {
  data: DecoratedExpandedDiscussionComment[];
  metadata: Metadata;
}

export const findDiscussionComments = async (
  args: FindDiscussionCommentsArguments,
): Promise<FindDiscussionCommentsResponse> => {
  const { discussionId, ...params } = args.params;

  const url = [
    discussionCommentsRoute({
      discussionId,
    }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findDiscussionComments');
  }

  const body = await response.json();
  const data = parseModelObjectArray<DecoratedExpandedDiscussionComment>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface CreateDiscussionCommentParams {
  discussionId: Atlas.DiscussionID;
}

export type CreateDiscussionCommentBody = Partial<Atlas.DiscussionComment> & {
  body: Atlas.CommentBody;
};

export interface CreateDiscussionCommentArguments {
  params: CreateDiscussionCommentParams;
  body: CreateDiscussionCommentBody;
}

export interface CreateDiscussionCommentResponse {
  data: Atlas.ExpandedDiscussionComment;
}

export const createDiscussionComment = async (
  args: CreateDiscussionCommentArguments,
) : Promise<CreateDiscussionCommentResponse> => {
  const { discussionId } = args.params;

  const url = discussionCommentsRoute({ discussionId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'createDiscussionComment');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.ExpandedDiscussionComment>(body.data);

  return { data };
};
