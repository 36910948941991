import React, { useEffect, useRef } from 'react';

interface VideoStreamPlayerProps {
  leftStream?: MediaStream;
  leftPlaceholder?: JSX.Element;
  leftOverlay?: JSX.Element;
  rightStream?: MediaStream;
  rightPlaceholder?: JSX.Element;
  rightOverlay?: JSX.Element;
}

const VideoStreamPlayer: React.FC<VideoStreamPlayerProps> = (props) => {
  const {
    leftStream,
    leftPlaceholder,
    leftOverlay,
    rightStream,
    rightPlaceholder,
    rightOverlay,
  } = props;

  const leftVideoRef = useRef<HTMLVideoElement>(null);
  const rightVideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = leftVideoRef.current;

    if (videoElement) {
      videoElement.srcObject = leftStream ?? null;
    }
  }, [leftStream]);

  useEffect(() => {
    const videoElement = rightVideoRef.current;

    if (videoElement) {
      videoElement.srcObject = rightStream ?? null;
    }
  }, [rightStream]);

  return (
    <div className="tw-grid tw-grid-flow-col tw-rounded tw-overflow-hidden tw-bg-black tw-text-white">
      {leftStream || leftPlaceholder ? (
        <div className="tw-aspect-w-16 tw-aspect-h-9">
          {leftStream ? (
            <>
              <div className="tw-grid tw-place-items-center">
                <i className="fa fa-circle-o-notch fa-spin tw-text-2xl" />
              </div>

              <video ref={leftVideoRef} autoPlay muted />

              {leftOverlay}
            </>
          ) : (
            leftPlaceholder
          )}
        </div>
      ) : null}

      {rightStream || rightPlaceholder ? (
        <div className="tw-aspect-w-16 tw-aspect-h-9">
          {rightStream ? (
            <>
              <div className="tw-grid tw-place-items-center">
                <i className="fa fa-circle-o-notch fa-spin tw-text-2xl" />
              </div>

              <video ref={rightVideoRef} autoPlay muted />

              {rightOverlay}
            </>
          ) : (
            rightPlaceholder
          )}
        </div>
      ) : null}
    </div>
  );
};

export default VideoStreamPlayer;
