/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useMemo } from 'react';
import CurrentUserContext from '../contexts/CurrentUserContext';
import * as Atlas from '../types/Atlas';
import useCurrentUserQuery from './api/useCurrentUserQuery';

interface UseCurrentUserState {
  user?: Atlas.User;
  userLoading: boolean;
  relaodUser?: () => void;

}

export const useCurrentUser = (): UseCurrentUserState => {
  const currentUser = useContext(CurrentUserContext);

  return currentUser;
};

function withCurrentUser<P>(Component: React.ComponentType<P>) {
  const WithCurrentUser: React.FC<P & JSX.IntrinsicAttributes> = (props) => {
    const currentUserQuery = useCurrentUserQuery();

    const value = useMemo(() => ({
      userLoading: currentUserQuery.isLoading,
      user: currentUserQuery.data,
    }), [currentUserQuery.data, currentUserQuery.isLoading]);

    return (
      <CurrentUserContext.Provider value={value}>
        <Component {...props} />
      </CurrentUserContext.Provider>
    );
  };

  return WithCurrentUser;
}

export default withCurrentUser;
