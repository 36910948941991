import * as Atlas from '../../../types/Atlas';
import ApiError from '../../error';
import { generateFetchHeaders } from '../../routes';

const completeFormDataRoute = (args: {
  formDataId: Atlas.FormDataID;
}): string => `/form_data/${args.formDataId}/complete`;

export interface CompleteFormDataParams {
  formDataId: Atlas.FormDataID;
}

export interface CompleteFormDataArguments {
  params: CompleteFormDataParams;
}

export const completeFormData = async (
  args: CompleteFormDataArguments,
): Promise<void> => {
  const {
    formDataId,
  } = args.params;

  const url = completeFormDataRoute({
    formDataId,
  });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'completeFormData');
  }
};
