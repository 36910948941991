import React from 'react';
import FormOverviewPage from './forms/[formId]/overview/page';
import GroupDiscussionsPage from './groups/[groupId]/discussions/page';
import GroupAssignmentsPage from './groups/[groupId]/assignments/page';
import NewGroupAssignmentPage from './groups/[groupId]/assignments/new/_page';
import EditGroupAssignmentPage from './groups/[groupId]/assignments/[assignmentId]/edit/_page';
import ShowGroupAssignmentPage from './groups/[groupId]/assignments/[assignmentId]/page';
import NewGroupAssignmentSubmissionPage from './groups/[groupId]/assignments/[assignmentId]/submissions/new/page';
import ShowGroupAssignmentSubmissionPage from './groups/[groupId]/assignments/[assignmentId]/submissions/[assignmentSubmissionId]/page';
import GroupMilestonesPage from './groups/[groupId]/milestones/page';
import NewGroupMilestonePage from  './groups/[groupId]/milestones/new/page';
import EditGroupMilestonePage from './groups/[groupId]/milestones/[milestoneId]/edit/page';
import { compileRoutes, RouteObjectWithNested } from './route.utils';
import ReflectionFilesPage from './reflections/[reflectionId]/files/page';
import ReflectionVideosPage from './reflections/[reflectionId]/videos/page';
import ReflectionFormsPage from './reflections/[reflectionId]/forms/page';
import ReflectionPage from './reflections/[reflectionId]/page';
import ReflectionLayout from './reflections/[reflectionId]/layout';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import ReflectionFormPage from './reflections/[reflectionId]/forms/[formId]/page';

const groupDiscussionsRoutes: RouteObjectWithNested[] = [
  {
    path: '/discussions',
    element: <GroupDiscussionsPage />,
  },
];

const groupAssignmentsRoutes: RouteObjectWithNested[] = [
  {
    path: '/assignments',
    element: <GroupAssignmentsPage />,
    routes: [
      {
        path: '/new',
        element: <NewGroupAssignmentPage />,
      },
      {
        path: '/:assignmentId',
        routes: [
          {
            path: '/',
            element: <ShowGroupAssignmentPage />,
          },
          {
            path: '/edit',
            element: <EditGroupAssignmentPage />,
          },
          {
            path: '/submissions',
            routes: [
              {
                path: '/new',
                element: <NewGroupAssignmentSubmissionPage />,
              },
              {
                path: '/:assignmentSubmissionId',
                element: <ShowGroupAssignmentSubmissionPage />,
              },
            ]
          },
        ],
      },
    ],
  },
];

const groupMilestonesRoutes: RouteObjectWithNested[] = [
  {
    path: '/milestones',
    element: <GroupMilestonesPage/>,
    routes: [
      {
        path: '/new',
        element: <NewGroupMilestonePage/>,
      },
      {
        path: '/:milestoneId',
        routes: [
          {
            path: '/edit',
            element: <EditGroupMilestonePage/>,
          },
        ]
      }
    ]
  }
];

const reflectionRoute: RouteObject = {
  path: '/:reflectionId',
  element: <ReflectionLayout />,
  children: [
    {
      index: true,
      element: <ReflectionPage />,
    },
    {
      path: 'files',
      element: <ReflectionFilesPage />,
    },
    {
      path: 'forms',
      element: <ReflectionFormsPage />,
    },
    {
      path: 'forms/:formId',
      element: <ReflectionFormPage />,
    },
    {
      path: 'videos',
      element: <ReflectionVideosPage />,
    },
  ],
};

const nestedRoutes: RouteObjectWithNested[] = [
  {
    path: '/forms/:formId/overview',
    element: <FormOverviewPage />,
  },
  {
    path: '/groups/:groupId',
    routes: [
      ...groupAssignmentsRoutes,
      ...groupDiscussionsRoutes,
    ],
  },
  {
    path: '/unity_pathways/:groupId',
    routes: [
      ...groupAssignmentsRoutes,
      ...groupDiscussionsRoutes,
    ],
  },
  {
    path: '/pathways_management/:containerId/templates/:groupId',
    routes: [
      ...groupAssignmentsRoutes,
      ...groupDiscussionsRoutes,
      ...groupMilestonesRoutes,
    ],
  },
  {
    path: '/reflections',
    routes: [
      reflectionRoute,
    ],
  },
  {
    path: '/lti/reflections',
    routes: [
      reflectionRoute,
    ],
  },
];

export const routes = compileRoutes(nestedRoutes);

export default createBrowserRouter(routes);
