import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Atlas from '../../../../../common/types/Atlas';
import ReflectionAttachedForm from '../../../../../modules/reflections/ReflectionAttachedForm';
import useFindReflectionQuery from '../../../../../common/hooks/api/reflections/useFindReflectionQuery';
import useFindReflectionFormQuery from '../../../../../common/hooks/api/reflections/useFindReflectionFormQuery';
import useDisassociateReflectionFormMutation from '../../../../../common/hooks/api/reflections/useDisassociateReflectionFormMutation';
import useUpdateReflectionFormAssociationMutation from '../../../../../common/hooks/api/reflections/useUpdateReflectionFormAssociationMutation';
import { createReflectionFormAiComments } from '../../../../../common/api/reflections/[reflectionId]/forms/[formId]';
import Spinner from '../../../../../common/components/Spinner';

const ReflectionFormWrapper: React.FC<{
  reflectionId: Atlas.ReflectionID;
  formId: Atlas.FormID;
  scrollToFormElement?: Atlas.FormFieldName;
}> = ({
  reflectionId,
  formId,
  scrollToFormElement,
}) => {
  const navigate = useNavigate();

  const reflectionQuery = useFindReflectionQuery(reflectionId);

  const updateFormAssociation = useUpdateReflectionFormAssociationMutation();
  const disassociateForm = useDisassociateReflectionFormMutation();

  const formQuery = useFindReflectionFormQuery({
    params: {
      reflectionId,
      formId,
    },
  }, {
    enabled: disassociateForm.isIdle,
  });

  if (reflectionQuery.isIdle || reflectionQuery.isError || formQuery.isIdle || formQuery.isError) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-2 xs:tw-gap-4 tw-h-full">
        <div className="tw-grow tw-overflow-scroll tw-h-0 tw-rounded-xl tw-bg-base-300 tw-border tw-border-base-400 tw-shadow-inner tw-p-2 xs:tw-p-4 tw-min-h-[130px]">
          <div className="tw-text-center">
            {__('Oops! Something went wrong.')}
          </div>
        </div>
      </div>
    )
  }

  if (reflectionQuery.isLoading || formQuery.isLoading) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-2 xs:tw-gap-4 tw-h-full">
        <div className="tw-grow tw-overflow-scroll tw-h-0 tw-rounded-xl tw-bg-base-300 tw-border tw-border-base-400 tw-shadow-inner tw-p-2 xs:tw-p-4 tw-min-h-[130px]">
          <div className="tw-text-center">
            <Spinner color="info" />
          </div>
        </div>
      </div>
    );
  }

  const reflection = reflectionQuery.data.data;
  const form = formQuery.data;

  const handleBack = () => {
    navigate(`/reflections/${reflectionId}/forms`);
  };

  const handlePrivacyChange = async (privacy: Atlas.AnswerPrivacy) => {
    await updateFormAssociation.mutateAsync({
      params: {
        reflectionId,
        formId,
      },
      body: {
        answer_privacy: privacy,
      },
    });
  };

  const handleRemoveClick = () => {
    disassociateForm.mutateAsync({
      params: {
        reflectionId,
        formId,
      },
    }).then(() => {
      navigate(`/reflections/${reflectionId}/forms`);
    });
  };

  const handleCreateAiComments = async () => {
    await createReflectionFormAiComments({
      params: {
        reflectionId,
        formId: form.id,
      },
    });

    void formQuery.refetch();
  };

  return (
    <ReflectionAttachedForm
      reflectionId={reflectionId}
      scrollToFormElement={scrollToFormElement}
      form={form}
      reflection={reflection}
      onBack={handleBack}
      onPrivacyChange={handlePrivacyChange}
      onRemoveClick={handleRemoveClick}
      onCreateAiComments={handleCreateAiComments}
    />
  );
};

const ReflectionFormPage = (): JSX.Element => {
  const params = useParams<{
    reflectionId: string;
    formId: string;
  }>();

  const [searchParams] = useSearchParams();

  const reflectionId = Number(params.reflectionId);
  const formId = Number(params.formId);

  const formFieldName = searchParams.get('form_field_name') ?? undefined;

  return (
    <ReflectionFormWrapper
      reflectionId={reflectionId}
      formId={formId}
      scrollToFormElement={formFieldName}
    />
  );
};

export default ReflectionFormPage;
