import { useMutation } from 'react-query';
import { FindReflectionsResponse } from '../../../api/reflections';
import { FindReflectionVideosResponse } from '../../../api/reflections/videos';
import { FindReflectionPlaylistResponse } from '../../../api/reflections/[reflectionId]/playlist';
import {
  destroyVideo, DestroyVideoArguments, DestroyVideoResponse, FindVideoResponse,
} from '../../../api/videos/[videoId]';
import { queryClient } from '../../withQueryClient';

const useDestroyVideoMutation = () => {
  const mutation = useMutation<DestroyVideoResponse, unknown, DestroyVideoArguments, unknown>(
    destroyVideo,
    {
      onSuccess: (result) => {
        const video = result.data;

        void queryClient.resetQueries<FindVideoResponse>(['videos', video.id]);

        if (video.reflection_id) {
          void queryClient.invalidateQueries<FindReflectionsResponse>(['reflections', video.reflection_id], { exact: true });
          void queryClient.invalidateQueries<FindReflectionPlaylistResponse>(['reflections', video.reflection_id, 'playlist']);
          void queryClient.invalidateQueries<FindReflectionVideosResponse>(['reflections', video.reflection_id, 'videos']);
        }
      },
    },
  );

  return mutation;
};

export default useDestroyVideoMutation;
