
/* The destination of a particular upload. */
export class UploadCredentials {
    // A media key which must always be supplied, in this context.
    readonly mediaKey: string

    // A generic notion of "user/id".
    readonly secretId: string

    // A generic notion of "password".
    readonly secretPassword: string

    constructor(secretId: string, secretPassword: string, mediaKey: string) {
      this.mediaKey = mediaKey;
      this.secretPassword = secretPassword;
      this.secretId = secretId;
    }
}

/* The destination of a particular upload. */
export interface UploadDestination {
    readonly destination: string
}

/* An abstract notion of an UploadId. */
export interface UploadId {
    readonly uploadId: string
}

/* A Chunk is a raw data-frame from the MediaStream API (i.e. a Blob). NOT to be confused with an
   UploadPart, which is guaranteed to be >= 5MB. */
export class Chunk {
    readonly rawBlob: Blob
    readonly producedOn: Date

    constructor(blob: Blob) {
        this.rawBlob = blob;
        this.producedOn = new Date();
    }
}

// An 'UploadPart' is a collection of chunks, such that each 'UploadPart' is at least 5MB.
export class UploadPart {
    readonly hash: string; // MD5 hash of the _underlying_ blob.
    partNumber: number;
    readonly blob: Blob;
    constructor(chunks: Array<Chunk>, partNumber: number) {
        this.blob = new Blob(chunks.map(c => c.rawBlob), { type: "video/webm" });
        this.hash = "FIXME: a hash";
        this.partNumber = partNumber;
    }
}

/* The UploadStats can be used to track interesting metrics about the upload. They are sent to users
   with the 'onProgress' callback, so that they can track the overall status of the upload. */
export class UploadStats {
    /* The number of raw blobs samples from the media device. These won't be sent
       directly, but rather "packaged" into 5MB 'UploadPart's. */
    totalRawMediaChunks: number
    totalUploadParts: number
    uploadedParts: number
    retransmittedParts: number
    inFlightParts: number

    constructor() {
        this.totalRawMediaChunks = 0;
        this.totalUploadParts = 0;
        this.uploadedParts = 0;
        this.retransmittedParts = 0;
        this.inFlightParts = 0;
    }
}

export interface UploadState {
    readonly mediaKey: string
    readonly uploadDestination: UploadDestination
    partNumberCounter: number
    uploadStats: UploadStats
    recordedChunks: Array<Chunk>
}

/* A callback which will be invoked when a new Chunk arrives from outside. */
export interface OnNewChunk {
    (newBlob: Blob): void
}

/* A callback which will be invoked right after starting the upload process. */
export interface OnStart {
    (): void
}

/* A callback which will be invoked before starting the upload process. */
export interface BeforeStart {
    (): Promise<UploadId>
}

/* A callback which will be invoked when a new UploadPart is fully uploaded remotely. */
export interface OnProgress {
    (stats: UploadStats): void
}

export enum UploadStatus { NotStarted = 1, InProgress, Stopped, Completed, Errored };
export enum S3Region {
        Us_east_1 = 1
      , Us_east_2
      , Us_west_1
      , Us_west_2
      , Ca_central_1
      , Ap_south_1
      , Ap_northeast_2
      , Ap_southeast_1
      , Ap_southeast_2
      , Ap_northeast_1
      , Eu_central_1
      , Eu_west_1
      , Eu_west_2
      , Sa_east_1
      , Cn_north_1 };
