import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import GroupMenuPage from './GroupMenuPage';
import GroupMenuLockedPage from './GroupMenuLockedPage';

const GroupMenuItem: React.FC<{
  page: Atlas.Page;
  editable?: boolean;
  locked?: boolean;
  showDisc?: boolean;
}> = (props) => {
  const { page, editable, locked, showDisc } = props;

  switch (page.type) {
    case Atlas.PageType.Marketing: {
      return <GroupMenuPage page={page} editable={editable} showDisc={showDisc} />;
    }

    case Atlas.PageType.Page:
    default: {
      if (locked) {
        return <GroupMenuLockedPage page={page} />;
      }

      return <GroupMenuPage page={page} editable={editable} showDisc={showDisc} />;
    }
  }
};

export default GroupMenuItem;
