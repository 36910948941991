import React, { useState } from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import useUpdateMilestoneMutation from '../hooks/api/useUpdateMilestoneMutation';
import {
  createEmptyButtonMilestone,
  createEmptyAttachmentMilestone,
  createEmptyDiscussionMilestone,
  createEmptyCompleteAssignmentMilestone,
  createEmptyReflectionMilestone,
  createEmptySubmitAssignmentMilestone,
  createEmptyPageMilestone,
  createEmptyGuideMilestone,
  createEmptyFormMilestone,
  createEmptyLinkReflectionMilestone,
  createFeedbackMilestone
} from '../utils/utils';
import MilestoneForm from './MilestoneForm';
import { withQueryClient } from '../../../../common/hooks/withQueryClient';

interface MilestoneDetails {
  name: Atlas.MilestoneName;
  description: Atlas.MilestoneDescription;
  notify: Atlas.MilestoneNotify;
  notification_text?: string | null;
  completesPathway: boolean;
}

interface EditMilestoneContainerProps {
  groupId: Atlas.GroupID;
  milestone: Atlas.Milestone;
}

const EditMilestoneContainer = ({ groupId, milestone }: EditMilestoneContainerProps): JSX.Element => {
  const [milestoneDetails, setMilestoneDetails] = useState<MilestoneDetails>({
    name: milestone.name,
    description: milestone.description,
    notify: milestone.notify,
    ...(milestone.notification_text !== null && {
      notification_text: milestone.notification_text,
    }),
    completesPathway: milestone.completes_pathway
  });
  const [milestoneType, setMilestoneType] = useState<Atlas.MilestoneType>(milestone.type);

  const mutation = useUpdateMilestoneMutation(groupId, milestone.id);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (mutation.isSuccess) { return; }

    const updatedMilestone = {
      name: milestoneDetails.name,
      description: milestoneDetails.description,
      type: milestoneType,
      notify: milestoneDetails.notify,
      notification_text: milestoneDetails.notification_text,
      completes_pathway: milestoneDetails.completesPathway,
      completed: milestone.completed,
    };

    await mutation.mutateAsync({
      params: { groupId: groupId, milestoneId: milestone.id },
      body: updatedMilestone,
    });

    setTimeout(() => {
      mutation.reset();
    }, 5000);
  };
  
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const selectedType = event.target.value;

    switch (selectedType) {
      case 'button':
        setMilestoneType(createEmptyButtonMilestone());
        break;
      case 'visit_page':
        setMilestoneType(createEmptyPageMilestone());
        break;
      case 'link_guide':
        setMilestoneType(createEmptyGuideMilestone());
        break;
      case 'link_reflection':
        setMilestoneType(createEmptyLinkReflectionMilestone());
        break;
      case 'watch_reflection':
        setMilestoneType(createEmptyReflectionMilestone());
        break;
      case 'submit_to_assignment':
        setMilestoneType(createEmptySubmitAssignmentMilestone());
        break;
      case 'complete_assignment':
        setMilestoneType(createEmptyCompleteAssignmentMilestone());
        break;
      case 'discuss':
        setMilestoneType(createEmptyDiscussionMilestone());
        break;
      case 'download_attachment':
        setMilestoneType(createEmptyAttachmentMilestone());
        break;
      case 'submit_insight':
        setMilestoneType(createEmptyFormMilestone());
        break;
      case 'submit_feedback':
        setMilestoneType(createFeedbackMilestone());
        break;
      default:
        break;
    }
  };

  return (
    <>
      <MilestoneForm
        groupId={groupId}
        milestoneDetails={milestoneDetails}
        setMilestoneDetails={setMilestoneDetails}
        milestoneType={milestoneType}
        setMilestoneType={setMilestoneType}
        error={mutation.isError ? __('An error occurred while updating the milestone.') : ''}
        success={mutation.isSuccess ? __('Milestone updated successfully!') : ''}
        isEditForm={true}
        handleSubmit={handleSubmit}
        handleTypeChange={handleTypeChange}
      />
    </>
  );
};

export default withQueryClient(EditMilestoneContainer);
