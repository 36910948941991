import React from 'react';
import GenericBuilderFormElements from './GenericBuilderFormElements';
import { formElementOptions, generateFormElement } from './formElementOptions';
import * as Atlas from '../../../common/types/Atlas';
import useFindFeatureToggleQuery from '../../../common/hooks/api/features/useFindFeatureToggleQuery';
import Spinner from '../../../common/components/Spinner';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';

interface FormElementsProps {
  loading: boolean;
  form: Partial<Atlas.Form>;
  onChange: (changes: { elements: Array<{ field: Partial<Atlas.FormField>; }> }) => void;
}

const FormElements = (props: FormElementsProps): JSX.Element => {
  const aiFormsEnabled = useFindFeatureToggleQuery({ featureToggle: 'ai_forms' }).data?.enabled ?? false;
  const { loading, form, onChange } = props;
  const lockOnComplete = form.notify_complete?.lock_on_complete ?? false;
  const formElements = (form && form.elements) || [];

  const handleOptionClick = (option: string) => () => {
    switch (option) {
      case 'text':
      case 'multiple_choice':
      case 'rating':
      case 'counter':
      case 'timer':
      case 'attachment':
      case 'reflection':
        break;
      default:
        return;
    }

    const nextFormElements = [...formElements, generateFormElement(option)];

    onChange({ elements: nextFormElements });
  };

  const handleFormElementsChange = (
    nextFormElements: Array<{ field: Partial<Atlas.FormField>; }>,
  ) => {
    onChange({ elements: nextFormElements });
  };

  const isSuperAdmin = useCurrentUserQuery().data?.admin === Atlas.AdministratorType.Super;

  const isEditingLimited = form.has_data && !isSuperAdmin;

  const $content = (() => {
    if (loading) {
      return (
        <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
          <Spinner color="info" />
        </div>
      );
    }

    if (formElements.length === 0) {
      return (
        <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
          <div className="tw-font-light tw-text-xl tw-p-4">
            {aiFormsEnabled ? (
              __('Add a question to start building your Insight')
            ) : (
              __('Add a question to start building your form')
            )}
          </div>
        </div>
      );
    }

    return (
      <GenericBuilderFormElements
        aiEnabled={!!form.ai_options}
        formElements={formElements}
        showRequiredOption={lockOnComplete}
        showAllowMultipleAnswersOption={lockOnComplete}
        onChange={handleFormElementsChange}
        isEditingLimited={isEditingLimited}
      />
    );
  })();

  return (
    <div className="tw-flex tw-flex-col lg:tw-flex-row-reverse tw-gap-4">
      <div className="tw-self-stretch tw-flex-1 tw-flex tw-flex-col">
        {(isEditingLimited) &&
          <div className="alert alert-info tw-rounded-lg">
            {__('This insight already has responses. Limited editing will be allowed to preserve the validity of the current answers.')}
          </div>
        }
        {(form.has_data && isSuperAdmin) &&
          <div className="alert alert-danger tw-rounded-lg">
            <span className="tw-font-semibold">{__('Warning for Super Administrators: ')}</span>
            {__('Editing forms with answers can affect data validity. Make changes cautiously and only when necessary.')}
          </div>
        }
        {$content}
      </div>

      <div className="lg:tw-w-72">
        <div className="tw-card tw-card-compact tw-bg-base-100 lg:tw-sticky lg:tw-top-4">
          <div className="tw-card-body">
            <div className="tw-card-title tw-flex tw-justify-between">
              {__('Questions')}

              <i className="icon-info-sign" title={__('Click a question to add it to your form')} />
            </div>

            {formElementOptions.map((feo) => (
              <div key={feo.name}>
                <button
                  type="button"
                  className="tw-btn tw-btn-primary tw-w-full tw-gap-1"
                  disabled={isEditingLimited}
                  onClick={handleOptionClick(feo.type)}
                >
                  <i className={feo.icon} />
                  <span>{feo.name}</span>
                </button>
                <div className="tw-text-sm tw-text-gray-600 tw-py-1">
                  {feo.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormElements;
