import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Col } from '../../../../../../common/components/Col';
import { Row } from '../../../../../../common/components/Row';
import useGroupAssignmentQuery from '../../../../../../common/hooks/api/groups/useFindGroupAssignmentQuery';
import { getAssignmentsBasePath } from '../../../../../../common/utils/getAssignmentsBasePath';

interface AssignmentSubmissionLayoutProps {
  children?: React.ReactNode;
}

const GroupAssignmentSubmissionLayout = (props: AssignmentSubmissionLayoutProps): JSX.Element => {
  const params = useParams<{
    groupId: string;
    assignmentId: string;
  }>();

  const loc = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);

  const groupId = Number(params.groupId);
  const assignmentId = Number(params.assignmentId);

  const backTo = new URLSearchParams(loc.search).get('back_to');

  const groupAssignmentQuery = useGroupAssignmentQuery(groupId, assignmentId);
  const assignment = groupAssignmentQuery.data?.data;

  return (
    <div>
      <Row>
        <Col style={{ boxSizing: 'border-box' }}>
          <div className="d-flex align-items-center justify-content-between tw-iris-element-box mb-3">
            {backTo ? (
              <a className="tw-btn tw-btn-sm" href={backTo}>
                <i className="fa fa-chevron-left mr-2" />
                {__('Back')}
              </a>
            ) : (
              <Link className="tw-btn tw-btn-sm" to={`/${basePath}/${groupId}/assignments/${assignmentId}`}>
                <i className="fa fa-chevron-left mr-2" />
                {__('Back')}
              </Link>
            )}

            <div className="d-flex align-items-center">
              <h5 className="text-muted font-weight-light mx-2 my-0">
                {assignment?.name}
              </h5>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col style={{ boxSizing: 'border-box' }}>
          {props.children}
        </Col>
      </Row>
    </div>
  );
};

export default GroupAssignmentSubmissionLayout;
