import { useEffect } from 'react';

type MouseEventType = 'mousemove' | 'mouseup';

const useMouseEvent = (
  type: MouseEventType,
  handler: (event: MouseEvent) => void,
  options?: {
    enabled?: boolean;
  },
) => {
  useEffect(() => {
    if (options?.enabled === false) { return undefined; }

    document.addEventListener(type, handler);

    return () => {
      document.removeEventListener(type, handler);
    };
  }, [handler, options?.enabled, type]);
};

export default useMouseEvent;
