import React, { useState } from 'react';

export const CommentInput: React.FC<{
  autoFocus?: boolean;
  commentBody: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  isSubmitting?: boolean;
  onChange: (comment: string) => void;
  onSubmit: () => void;
  placeholder?: string;
  required?: boolean;
}> = (props) => {
  const {
    autoFocus,
    inputRef,
    isSubmitting,
    onChange,
    onSubmit,
    required,
    placeholder = __('Write a reply'),
    commentBody,
  } = props;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSubmitting) { return; }

    const { value } = event.currentTarget;
    onChange(value);
  };

  const submitDisabled = required && commentBody.trim().length === 0;

  const handleSubmitClick = () => {
    if (submitDisabled) { return; }

    onSubmit();
  };

  return (
    <div className="tw-flex tw-gap-2">
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        className="tw-input tw-input-sm tw-rounded-xl tw-flex-1 tw-bg-base-200"
        placeholder={placeholder}
        value={commentBody}
        onChange={handleInputChange}
      />

      <button
        type="button"
        className="tw-btn tw-btn-success tw-btn-circle tw-btn-sm tw-text-xs"
        disabled={submitDisabled}
        onClick={handleSubmitClick}
      >
        {isSubmitting ? (
          <i className="fa fa-circle-o-notch fa-spin tw-text-white" />
        ) : (
          <i className="fa fa-paper-plane tw-text-white" />
        )}
      </button>
    </div>
  );
};

const CommentInputManager: React.FC<{
  autoFocus?: boolean;
  initialBody?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  onSubmit: (comment: string) => Promise<void>;
  placeholder?: string;
  required?: boolean;
}> = (props) => {
  const {
    autoFocus,
    initialBody = '',
    inputRef,
    onSubmit,
    placeholder,
    required,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState(initialBody);

  const handleSubmitClick = () => {
    if (submitting) { return; }

    setSubmitting(true);
    void onSubmit(comment.trim()).then(() => {
      setComment('');
    }).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <CommentInput
      autoFocus={autoFocus}
      commentBody={comment}
      inputRef={inputRef}
      isSubmitting={submitting}
      onChange={(val) => setComment(val)}
      onSubmit={handleSubmitClick}
      placeholder={placeholder}
      required={required}
    />
  );
};

export default CommentInputManager;
