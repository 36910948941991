import React from 'react';
import Spinner from '../../../../../../common/components/Spinner';

const SearchResultsLoader = () => (
  <div className="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center">
    <Spinner color="primary" />
  </div>
);

export default SearchResultsLoader;
