import React, { useEffect, useMemo } from 'react';
import { parseModelObject } from '../../../common/api/api-parser';
import { FindGroupResponse } from '../../../common/api/groups/[groupId]';
import { queryClient, withQueryClient } from '../../../common/hooks/withQueryClient';
import * as Atlas from '../../../common/types/Atlas';
import GroupSidebar from './GroupSidebar';

interface GroupSidebarEmbedProps {
  groupJson: string;
  previewMode?: boolean;
  disableSort?: boolean;
}

const GroupSidebarEmbed = (props: GroupSidebarEmbedProps): JSX.Element => {
  const { groupJson, previewMode, disableSort } = props;

  const group = useMemo(() => (
    parseModelObject<Atlas.Group>(JSON.parse(groupJson) as object)
  ), [groupJson]);

  useEffect(() => {
    queryClient.setQueryData<FindGroupResponse>(['groups', group.id], { data: group });
  }, [group]);

  return (
    <GroupSidebar group={group} previewMode={previewMode} disableSort={disableSort} />
  );
};

export default withQueryClient(GroupSidebarEmbed);
