import * as Atlas from '../../../../types/Atlas';
import { parseModelObjectArray } from '../../../api-parser';
import ApiError from '../../../error';
import { PaginationParams, reflectionFormsRoute, searchParams, jsonHeaders } from '../../../routes';

export type FindReflectionFormsArguments = {
  params: {
    reflectionId: Atlas.ReflectionID;
  } & PaginationParams;
};

export type FindReflectionFormsResponse = Atlas.Form[];

export const findReflectionForms =  async (
  args: FindReflectionFormsArguments,
) => {
  const { params } = args;

  const url = [
    reflectionFormsRoute({
      reflectionId: params.reflectionId,
    }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'findReflectionForms');
  }

  const body = await response.json();
  const data = parseModelObjectArray(body) as Atlas.Form[];

  return data;
};
