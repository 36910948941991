import { useMutation } from 'react-query';
import { downloadVideo, DownloadVideoArguments, DownloadVideoResponse } from '../../../api/videos/[videoId]/download';

const useDownloadVideoMutation = () => {
  const mutation = useMutation<DownloadVideoResponse, unknown, DownloadVideoArguments, unknown>(
    downloadVideo,
  );

  return mutation;
};

export default useDownloadVideoMutation;
