import { useMutation, UseMutationResult } from 'react-query';
import { createGroupDiscussion, CreateGroupDiscussionBody, CreateGroupDiscussionParams, CreateGroupDiscussionResponse } from '../../../api/groups/[groupId]/discussions';
import { queryClient } from '../../withQueryClient';

export const useCreateGroupDiscussionMutation = (
  params: CreateGroupDiscussionParams,
): UseMutationResult<CreateGroupDiscussionResponse, unknown, CreateGroupDiscussionBody, unknown> => {
  const mutation = useMutation((body: CreateGroupDiscussionBody) => {
    return createGroupDiscussion({ params, body });
  }, {
    onSuccess: (result) => {
      const discussion = result.data;
      queryClient.setQueryData(['discussions', discussion.id], result);
      queryClient.invalidateQueries(['groups', discussion.group_id, 'discussions']);
    },
  });

  return mutation;
};

export default useCreateGroupDiscussionMutation;
