import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../../../api-parser';
import ApiError from '../../../error';
import { generateFetchHeaders, Metadata } from '../../../routes';

const videoCaptionsRoute = (args: {
  videoId: Atlas.VideoID;
}): string => `/api/v0/videos/${args.videoId}/captions`;

export type FindVideoCaptionsArguments = {
  params: {
    videoId: Atlas.VideoID;
  };
};

export interface FindVideoCaptionsResponse {
  data: Atlas.Attachment[];
  metadata: Metadata;
}

export const findVideoCaptions = async (
  args: FindVideoCaptionsArguments
): Promise<FindVideoCaptionsResponse> => {
  const { params } = args;

  const url = videoCaptionsRoute({ videoId: params.videoId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findVideoCaptions');
  }

  const body = await response.json();
  const data = parseModelObjectArray(body.data) as Atlas.Attachment[];
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface CreateVideoCaptionParams {
  videoId: Atlas.VideoID;
}

export interface CreateVideoCaptionBody {
  name: Atlas.AttachmentName;
}

export interface CreateVideoCaptionArguments {
  params: CreateVideoCaptionParams;
  body: CreateVideoCaptionBody;
}

export interface CreateVideoCaptionResponse {
  data: Atlas.Attachment;
  metadata: Metadata;
}

export const createVideoCaption = async (
  args: CreateVideoCaptionArguments,
): Promise<CreateVideoCaptionResponse> => {
  const { videoId } = args.params;

  const url = videoCaptionsRoute({ videoId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      name: args.body.name,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'createVideoCaption');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Attachment>(body.data);
  const metadata = parseModelObject<Metadata>(body.metadata);

  return { data, metadata };
};
