import React from 'react';
import useUpdateVideoMutation from '../../../common/hooks/api/videos/useUpdateVideoMutation';
import * as Atlas from '../../../common/types/Atlas';
import { getVideoChannel } from './utils';

/**
 * Returns a generalised state string based on the prep_state of a video channel
 *
 * @param {Atlas.Channel | undefined} channel - The video channel to generalise the state for
 * @returns {'ok' | 'error' | 'missing'} A string indicating the state of the channel
 */
const generaliseState = (channel?: Atlas.Channel): 'ok' | 'error' | 'missing' => {
  switch (channel?.prep_state) {
    case Atlas.VideoPrepState.Ready:
    case Atlas.VideoPrepState.Transcoding:
    case Atlas.VideoPrepState.Uploading: {
      return 'ok';
    }

    case Atlas.VideoPrepState.Error:
    case Atlas.VideoPrepState.Undecryptable:
    case Atlas.VideoPrepState.Unsupported: {
      return 'error';
    }

    case Atlas.VideoPrepState.Waiting:
    default: {
      return 'missing';
    }
  }
};

const alertTextMap = {
  left: {
    error: __('Error processing left video channel.'),
    missing: __('Waiting for the left video to be uploaded.'),
  },
  right: {
    error: __('Error processing right video channel.'),
    missing: __('Waiting for the right video to be uploaded.'),
  },
};

interface DualVideoStatusAlertProps {
  video: Atlas.Video;
}

const DualVideoStatusAlert = (props: DualVideoStatusAlertProps) => {
  const { video } = props;

  const activeChannel = getVideoChannel(video);
  const updateVideo = useUpdateVideoMutation();

  if (!activeChannel) { return null; }

  if (activeChannel.type !== Atlas.ChannelType.VideoLeftRight) { return null; }
  if (activeChannel.prep_state !== Atlas.VideoPrepState.Waiting) { return null; }

  const leftSide = video.channels.find((c) => c.type === Atlas.ChannelType.VideoLeft);
  const rightSide = video.channels.find((c) => c.type === Atlas.ChannelType.VideoRight);

  const leftStatus = generaliseState(leftSide);
  const rightStatus = generaliseState(rightSide);

  if (leftStatus === rightStatus) { return null; }

  const variables = (() => {
    if (leftStatus === 'ok' && rightStatus !== 'ok') {
      // left side is ok, right is bad

      return {
        overrideChannel: Atlas.LeftOrRight.Left,
        alertText: alertTextMap.right[rightStatus],
        buttonText: __('Remove right part') as string,
      };
    }

    if (rightStatus === 'ok' && leftStatus !== 'ok') {
      // right side is ok, left is bad
      return {
        overrideChannel: Atlas.LeftOrRight.Right,
        alertText: alertTextMap.left[leftStatus],
        buttonText: __('Remove left part') as string,
      };
    }
  })();

  if (!variables) { return null; }

  const {
    overrideChannel,
    alertText,
    buttonText,
  } = variables;

  const handleClick = () => {
    if (updateVideo.isLoading) { return; }

    updateVideo.mutate({
      params: {
        videoId: video.id,
      },
      body: {
        override_channel: overrideChannel
      },
    });
  };

  return (
    <div className="tw-alert tw-alert-info tw-mb-4">
      {alertText}

      <button
        type="button"
        className="tw-btn tw-btn-sm"
        onClick={handleClick}
      >
        {updateVideo.isLoading ? <span className="tw-loading" /> : null}
        {buttonText}
      </button>
    </div>
  );
};

export default DualVideoStatusAlert;
