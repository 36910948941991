import { FormID, FormUser, UserID } from '../../../types/Atlas';
import { parseModelObjectArray, parseResponse } from '../../api-parser';
import { generateFetchHeaders, jsonHeaders, searchParams } from '../../routes';

const formReportersRoute = ({ formId }: { formId: FormID }): string => `/api/v0/forms/${formId}/reporters`;
const addFormReportersRoute = ({ formId }: { formId: FormID }): string => `/api/v0/forms/${formId}/reporters/add`;
const removeFormReportersRoute = ({ formId }: { formId: FormID }): string => `/api/v0/forms/${formId}/reporters/remove`;

interface FindFormReportersArgs {
  params: {
    formId: FormID;
  };
}

export const findFormReporters = async (
  args: FindFormReportersArgs,
): Promise<{ data: FormUser[]; }> => {
  const { formId } = args.params;

  const formReportersUrl = [
    formReportersRoute({ formId }),
  ].join('?');

  const response = await parseResponse<{
    data: Array<Record<string, unknown>>;
  }>(await fetch(formReportersUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  }));

  return {
    data: parseModelObjectArray<FormUser>(response.data),
  };
};

interface AddFormReporterArgs {
  params: {
    formId: FormID;
    userId: UserID;
  };
}

export const addFormReporter = async (args: AddFormReporterArgs): Promise<void> => {
  const { formId, ...params } = args.params;

  const addFormReportersUrl = [
    addFormReportersRoute({ formId }),
    searchParams({
      user_id: params.userId,
    }),
  ].join('?');

  await parseResponse(await fetch(addFormReportersUrl, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  }));
};

interface RemoveFormReporterArgs {
  params: {
    formId: FormID;
    userId: UserID;
  };
}

export const removeFormReporter = async (args: RemoveFormReporterArgs): Promise<void> => {
  const { formId, ...params } = args.params;

  const removeFormReportersUrl = [
    removeFormReportersRoute({ formId }),
    searchParams({
      user_id: params.userId,
    }),
  ].join('?');

  await parseResponse(await fetch(removeFormReportersUrl, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  }));
};
