import styled from '@emotion/styled';

export const StackCol = styled.div`
  padding: .125rem;
`;

export const StackRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -.125rem;
`;

export const Stack = styled.div``;
