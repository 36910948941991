import React, { useCallback, useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import {
  JwPlayerProvider,
} from '../../jwplayer/JwPlayer';
import VideoEditorPlayer from './VideoEditorPlayer';
import VideoEditorCropper from './VideoEditorCropper';
import ReflectionList from './ReflectionList';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import useClipVideoMutation from '../../../common/hooks/api/videos/useClipVideoMutation';
import QuickCreateReflectionForm from './QuickCreateReflectionForm';

interface VideoEditorProps {
  videoId: Atlas.VideoID;
  onCancel: () => void;
}

enum VideoEditorPage {
  CropVideo,
  SelectDestination,
}

const VideoEditor = (props: VideoEditorProps): JSX.Element => {
  const { videoId, onCancel } = props;

  const currentUserQuery = useCurrentUserQuery();
  const clipVideoMutation = useClipVideoMutation();

  const [state, setState] = useState<{
    page: VideoEditorPage;
    values: [number, number];
  }>({
    page: VideoEditorPage.CropVideo,
    values: [0, Infinity],
  });

  const handleCropChange = useCallback<React.ComponentProps<typeof VideoEditorCropper>['onChange']>((cb) => {
    setState((s) => ({
      ...s,
      ...cb({
        values: s.values,
      }),
    }));
  }, []);

  const validCropValues = (() => {
    const [start, end] = state.values;
    if (!Number.isFinite(start)) { return false; }
    if (!Number.isFinite(end)) { return false; }
    return true;
  })();

  switch (state.page) {
    case VideoEditorPage.CropVideo: {
      const handleCancel = () => {
        onCancel();
      };

      const handleNextClick = () => {
        setState((s) => ({ ...s, page: VideoEditorPage.SelectDestination }));
      };

      return (
        <>
          <JwPlayerProvider value={{ id: `video-editor-${videoId}` }}>
            <div className="tw-mb-2">
              <VideoEditorPlayer
                videoId={videoId}
              />
            </div>

            <div className="tw-mb-2">
              <VideoEditorCropper
                videoId={videoId}
                values={state.values}
                onChange={handleCropChange}
              />
            </div>
          </JwPlayerProvider>

          <div className="tw-flex tw-gap-3 tw-justify-between tw-mt-5">
            <button type="button" className="tw-btn" onClick={handleCancel}>
              {__('Cancel')}
            </button>

            <button type="button" disabled={!validCropValues} className="tw-btn tw-btn-info" onClick={handleNextClick}>
              {__('Next')}
            </button>
          </div>
        </>
      );
    }

    case VideoEditorPage.SelectDestination: {
      const handleBackClick = () => {
        setState((s) => ({ ...s, page: VideoEditorPage.CropVideo }));
      };

      const handleReflectionCreate = async (reflectionName: Atlas.ReflectionName) => {
        const response = await clipVideoMutation.mutateAsync({
          params: { videoId },
          body: {
            reflectionName,
            start: state.values[0],
            end: state.values[1],
          },
        });

        const newVideo = response.data;
        if (newVideo.reflection_id) {
          window.location.href = `/reflections/${newVideo.reflection_id}`;
        } else {
          onCancel();
        }
      };

      const handleReflectionSelect = async (reflectionId: Atlas.ReflectionID) => {
        const response = await clipVideoMutation.mutateAsync({
          params: { videoId },
          body: {
            reflectionId,
            start: state.values[0],
            end: state.values[1],
          },
        });

        const newVideo = response.data;
        if (newVideo.reflection_id) {
          window.location.href = `/reflections/${newVideo.reflection_id}`;
        } else {
          onCancel();
        }
      };

      const selected = (() => {
        if (!clipVideoMutation.variables) { return undefined; }
        if (!('reflectionId' in clipVideoMutation.variables.body)) { return undefined; }
        return clipVideoMutation.variables.body.reflectionId;
      })();

      return (
        <>
          <div className="tw-p-4 tw-mb-6 tw-bg-gray-50 tw-rounded-xl">
            <div className="tw-text-lg tw-text-center">
              {__('Where would you like to save your new video?')}
            </div>
          </div>

          <div className="tw-mb-4">
            <QuickCreateReflectionForm
              onCreate={handleReflectionCreate}
            />
          </div>

          {currentUserQuery.data ? (
            <>
              <div className="tw-divider">
                {__('OR')}
              </div>

              <ReflectionList
                userId={currentUserQuery.data.id}
                selected={selected}
                onReflectionSelect={handleReflectionSelect}
              />
            </>
          ) : null}

          <div className="tw-flex tw-gap-3 tw-justify-between tw-mt-5">
            <button type="button" className="tw-btn" onClick={handleBackClick}>
              {__('Back')}
            </button>
          </div>
        </>
      );
    }

    default: return (
      <div>{__('Oops! Something went wrong.')}</div>
    );
  }
};

export default VideoEditor;
