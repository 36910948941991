import { useMutation } from 'react-query';
import { FindReflectionVideosResponse } from '../../../api/reflections/videos';
import { FindReflectionPlaylistResponse } from '../../../api/reflections/[reflectionId]/playlist';
import { FindVideoResponse } from '../../../api/videos/[videoId]';
import { replicateVideo, ReplicateVideoArguments, ReplicateVideoResponse } from '../../../api/videos/[videoId]/replicate';
import { queryClient } from '../../withQueryClient';

const useReplicateVideoMutation = () => {
  const mutation = useMutation<ReplicateVideoResponse, unknown, ReplicateVideoArguments, unknown>(
    replicateVideo,
    {
      onSuccess: (result) => {
        const video = result.data;

        queryClient.setQueryData<FindVideoResponse>(['videos', video.id], { data: video });

        if (video.reflection_id) {
          void queryClient.invalidateQueries<FindReflectionPlaylistResponse>(['reflections', video.reflection_id, 'playlist']);
          void queryClient.invalidateQueries<FindReflectionVideosResponse>(['reflections', video.reflection_id, 'videos']);
        }
      },
    },
  );

  return mutation;
};

export default useReplicateVideoMutation;
