import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import { Modal, ModalBox } from '../../../common/components/TailwindModal';
import useDestroyAttachmentMutation from '../../../common/hooks/api/attachments/useDestroyAttachmentMutation';
import * as Atlas from '../../../common/types/Atlas';

dayjs.extend(relativeTime);

interface ReflectionFileProps {
  attachment: Atlas.Attachment;
}

const ReflectionFile = (props: ReflectionFileProps): JSX.Element => {
  const { attachment } = props;

  const [detailsOpen, setDetailsOpen] = useState(false);
  const destroyAttachmentMutation = useDestroyAttachmentMutation();

  const handleDetailsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.blur();
    setDetailsOpen(true);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.blur();
    if (!window.confirm(__('Are you sure you want to delete this file?'))) { return; }

    destroyAttachmentMutation.mutate({ params: { attachmentId: attachment.id } });
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
  };

  return (
    <div className="tw-p-2 tw-bg-white tw-shadow tw-rounded-xl">
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-grow tw-truncate tw-p-2">
          {attachment.media_s3uri?.url ? (
            <a download={attachment.name} href={attachment.media_s3uri.url} className="tw-link tw-link-hover tw-link-primary">
              {attachment.name}
            </a>
          ) : (
            <p>
              {attachment.name}
            </p>
          )}
        </div>

        <div className="tw-dropdown tw-dropdown-end">
          <label tabIndex={0} className="tw-btn tw-btn-sm tw-btn-ghost tw-mb-0">
            <i className="fa fa-ellipsis-v" />
          </label>
          <div className="tw-dropdown-content tw-iris-dropdown">
            <div className="tw-iris-dropdown-box">
              <ul tabIndex={0}>
                <li>
                  <button type="button" onClick={handleDetailsClick}>
                    {__('Details')}
                  </button>
                </li>
                <li>
                  <button type="button" onClick={handleDeleteClick}>
                    {__('Delete')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={detailsOpen} onClose={handleDetailsClose}>
        <ModalBox>
          <div className="tw-flex tw-justify-between tw-items-start tw-mb-4">
            <p className="tw-text-2xl tw-font-bold tw-text-primary">
              {__('File')}
            </p>

            <button type="button" className="tw-btn tw-btn-ghost tw-btn-sm tw-btn-square tw-text-lg" onClick={handleDetailsClose}>
              ✕
            </button>
          </div>

          <p className="tw-mt-2 tw-text-xl">
            {attachment.name}
          </p>

          {attachment.description ? (
            <p className="tw-mt-2">
              {attachment.description}
            </p>
          ) : null}

          <p className="tw-mt-2 tw-text-gray-600">
            {/* TODO i18n */}
            {attachment.user ? (
              `Uploaded ${dayjs(attachment.created_at).fromNow()} by ${attachment.user.first_name} ${attachment.user.last_name}`
            ) : (
              `Uploaded ${dayjs(attachment.created_at).fromNow()}`
            )}
          </p>
        </ModalBox>
      </Modal>
    </div>
  );
};

export default ReflectionFile;
