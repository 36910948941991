import axios, { CancelTokenSource } from 'axios';
import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import * as Atlas from '../types/Atlas';

type HermesUploaderState = {
  status: 'idle';
} | {
  status: 'uploading';
  progress: number;
  cancelTokenSource: CancelTokenSource;
} | {
  status: 'success';
} | {
  status: 'failed';
};

const useHermesUploader = () => {
  const [state, setState] = useState<HermesUploaderState>({
    status: 'idle',
  });

  const cancelToken = state.status === 'uploading' ? state.cancelTokenSource : null;

  const stopUpload = useCallback(() => {
    cancelToken?.cancel();
  }, [cancelToken]);

  useEffect(() => {
    if (!cancelToken) { return undefined; }

    return () => {
      cancelToken.cancel();
    };
  }, [cancelToken]);

  const upload = useCallback(async (
    mediaKey: Atlas.AvatarMasterKey,
    file: File,
    uploadUrl: string,
  ) => {
    const cancelTokenSource = axios.CancelToken.source();

    setState({
      status: 'uploading',
      progress: 0,
      cancelTokenSource,
    });

    const formData = new FormData();
    formData.append('media_key', mediaKey);
    formData.append('files[]', file);

    await axios.post(uploadUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      cancelToken: cancelTokenSource.token,
      onUploadProgress(progressEvent) {
        const totalLength = (() => {
          if (progressEvent.lengthComputable) { return progressEvent.total ?? 0; }
          return 0;
        })();

        const progress = Math.round((progressEvent.loaded * 100) / totalLength);

        setState((s) => {
          if (s.status !== 'uploading') { return s; }
          return { ...s, progress };
        });
      },
    }).then(() => {
      setState({ status: 'success' });
    }, () => {
      setState({ status: 'failed' });
    });
  }, []);

  const reset = useCallback(() => {
    setState({ status: 'idle' });
  }, []);

  return useMemo(() => ({
    ...state,
    upload,
    stopUpload,
    reset,
  }), [state, stopUpload, upload, reset]);
};

export default useHermesUploader;
