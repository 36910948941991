import { useMutation, UseMutationResult } from 'react-query';
import { queryClient } from '../../../../common/hooks/withQueryClient';
import {
  UpdateAssignmentSubmissionFeedbackArgs,
  updateAssignmentSubmissionFeedback,
  UpdateAssignmentSubmissionFeedbackResponse,
} from '../../../api/assignment-submissions';

type UseUpdateAssignmentSubmissionFeedbackResult =
  UseMutationResult<UpdateAssignmentSubmissionFeedbackResponse, unknown, UpdateAssignmentSubmissionFeedbackArgs>;

const useUpdateAssignmentSubmissionFeedbackMutation = (
): UseUpdateAssignmentSubmissionFeedbackResult => {
  const mutation = useMutation(updateAssignmentSubmissionFeedback, {
    onSuccess: (data) => {
      const assignmentId = data.data.assignment_id
      queryClient.invalidateQueries(['assignments', assignmentId, 'submissions' ]);
    }
  });

  return mutation;
};

export default useUpdateAssignmentSubmissionFeedbackMutation;