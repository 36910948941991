import React, { useCallback, useEffect, useState } from 'react';
import { findExpandedAssignmentSubmissions } from '../../common/api/assignments/assignment-submissions';
import { findGroup } from '../../common/api/groups/_legacy';
import { findGroupAssignment } from '../../common/api/groups/assignments';
import * as Atlas from '../../common/types/Atlas';
import withCurrentUser, { useCurrentUser } from '../../common/hooks/withCurrentUser';
import AssignmentDetails from '../group-assignment/submissions/AssignmentDetails';
import GroupAssignmentSubmission from '../group-assignment/submissions/AssignmentSubmission';
import { withQueryClient } from '../../common/hooks/withQueryClient';
import Spinner from '../../common/components/Spinner';
import { Alert } from '../../common/components/BSAlert';
import AssignmentSubmissionsModal from '../group-assignment/submissions/AssignmentSubmissionsModal';
import useFindAssignmentSubmissionsQuery from '../../common/hooks/api/assignments/useFindAssignmentSubmissionsQuery';

interface AssignmentSnippetProps {
  groupId: Atlas.GroupID;
  pageId: Atlas.PageID;
  assignmentId: Atlas.AssignmentID;
  readOnly?: boolean;
}

interface AssignmentSnippetState {
  group?: Atlas.Group;
  groupLoading: boolean;
  groupLoadingFailed?: boolean;
  assignment?: Atlas.Assignment;
  assignmentLoading: boolean;
  assignmentLoadingFailed?: boolean;
  assignmentSubmissions: Atlas.ExpandedAssignmentSubmission[];
  assignmentSubmissionsLoading: boolean;
  assignmentSubmissionsLoadingFailed?: boolean;
  draftSubmission?: Atlas.ExpandedAssignmentSubmission;
  submitted?: boolean;
  submissionKey: number;
}

const AssignmentSnippet = (props: AssignmentSnippetProps): JSX.Element => {
  const { groupId, pageId, assignmentId } = props;

  const { user } = useCurrentUser(),
        userId = user?.id;

  const [state, setState] = useState<AssignmentSnippetState>({
    groupLoading: true,
    assignmentLoading: true,
    assignmentSubmissionsLoading: true,
    assignmentSubmissions: [],
    submissionKey: 0,
  });

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setShowModal(true);
  }

  const handleModalClosed = () => {
    setShowModal(false);
  }

  const isUserAssessor = !!state.group && !!userId
    ? (
        state.group.group?.assessors?.user_ids.includes(userId) ||
        state.group.group?.administrators?.user_ids.includes(userId)
    ) : false;

  const assignmentBelongsToGroup = (assignmentGroupId: Atlas.GroupID) => {
    const pathname = location.pathname;

    if (pathname.includes('/groups/')) {
      return assignmentGroupId.toString() == pathname.split('/')[2];
    } else if (pathname.includes('/pathways_management/')) {
        return assignmentGroupId.toString() == pathname.split('/')[4];
    } else if (pathname.includes('/unity_pathways/')) {
      return assignmentGroupId.toString() == pathname.split('/')[2];
    }

    return false;
  }

  const loadGroup = useCallback(() => {
    if (!userId) { return; }
    setState(s => ({ ...s, groupLoading: true }));
    findGroup({ params: { groupId } }).then(({ data: group }) => {

      setState(s => ({...s, group, groupLoading: false, groupLoadingFailed: false }));
    }, () => {
      setState(s => ({ ...s, group: undefined, groupLoading: false, groupLoadingFailed: true }));
    });
  }, [groupId, userId]);

  const loadAssignment = useCallback(() => {
    setState(s => ({ ...s, assignmentLoading: true }));
    findGroupAssignment({ params: { groupId, assignmentId } }).then(({ data: assignment }) => {
      setState(s => ({ ...s, assignment, assignmentLoading: false, assignmentLoadingFailed: false }));
    }, () => {
      setState(s => ({ ...s, assignment: undefined, assignmentLoading: false, assignmentLoadingFailed: true }));
    });
  }, [groupId, assignmentId]);

  const loadAssignmentSubmissions = useCallback(() => {
    setState(s => ({ ...s, assignmentSubmissionsLoading: true }));
    findExpandedAssignmentSubmissions({
      params: { assignmentId, per_page: 50 }
    }).then(({ data }) => {
      const filteredData = data.filter(s => s.complete !== Atlas.SubmissionComplete.Draft)
      setState(s => ({
        ...s,
        assignmentSubmissions: filteredData,
        assignmentSubmissionsLoading: false,
        assignmentSubmissionsLoadingFailed: false
      }));
    }, () => {
      setState(s => ({
        ...s,
        assignmentSubmissions: [],
        assignmentSubmissionsLoading: false,
        assignmentSubmissionsLoadingFailed: true
      }));
    });
  }, [assignmentId]);

  useEffect(() => {
    loadGroup();
  }, [loadGroup]);

  useEffect(() => {
    loadAssignment();
  }, [loadAssignment]);

  useEffect(() => {
    loadAssignmentSubmissions();
  }, [loadAssignmentSubmissions]);

  const draftSubmissionsQuery = useFindAssignmentSubmissionsQuery(
    assignmentId,
    Atlas.SubmissionComplete.Draft,
  )

  if (state.groupLoading || state.assignmentLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center p-5">
        <Spinner color="info" />
      </div>
    );
  }

  const assignment = state.assignment;
  if (!assignment || state.groupLoadingFailed || state.assignmentLoadingFailed || state.assignmentSubmissionsLoadingFailed) {
    const handleRetryClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (state.groupLoadingFailed) { loadGroup(); }
      if (state.assignmentLoadingFailed) { loadAssignment(); }
      if (state.assignmentSubmissionsLoadingFailed) { loadAssignmentSubmissions(); }
    };

    return (
      <div className="d-flex align-items-center justify-content-center p-5">
        <Alert color="danger">
          {__('Something went wrong loading the assignment.')} <a href="#" onClick={handleRetryClick}>{__('Retry?')}</a>
        </Alert>
      </div>
    );
  }

  const handleSaved = () => {
    loadAssignmentSubmissions();
  }

  const handleSubmitted = async () => {
    loadAssignmentSubmissions();
    await draftSubmissionsQuery.refetch();
    setState(s => ({
      ...s, submitted: true,
      submissionKey: (s.submissionKey || 0) + 1
   }));
  };

  const handleFormInteraction = () => {
    setState(s => ({ ...s, submitted: false }));
  };

  return (
    <div className="">
      <div className="tw-mb-1">
        <AssignmentDetails assignment={assignment} />
      </div>

      <div className="tw-divider tw-mb-3 tw-mt-1"></div>
      {state.assignmentSubmissions.length && (assignmentBelongsToGroup(assignment.group_id)) ? (
        <React.Fragment>
          <button
            className="tw-btn tw-btn-success tw-mb-6 tw-mx-1"
            onClick={handleModalOpen}>
            {__('Submissions')} ({state.assignmentSubmissions.length})
          </button>
          {state.group && isUserAssessor !== undefined &&
          <AssignmentSubmissionsModal
            group={state.group}
            assignment={assignment}
            submissions={state.assignmentSubmissions}
            userId={userId}
            isAssessor={isUserAssessor}
            isOpen={showModal}
            onClose={handleModalClosed}
            onSubmitted={handleSaved}
          />}
        </React.Fragment>
      ) : null}

      {state.group && state.assignment ? (
        <GroupAssignmentSubmission
          key={state.submissionKey}
          hideDetails
          readOnly={props.readOnly}
          group={state.group}
          assignment={state.assignment}
          initialSubmission={draftSubmissionsQuery.data?.data?.[0]}
          onSubmitted={handleSubmitted}
          onFormInteraction={handleFormInteraction}
          pageId={pageId}/>
      ) : null}

      {state.submitted ? (
        <div className="tw-p-4 tw-bg-success tw-rounded-xl tw-mt-4">
          <h3 className="tw-text-base-100">
            <div className="tw-mb-2">{__("Submitted.")}</div>
            <div className="tw-text-sm">
              {__("You can review previous submissions by clicking on the 'Submissions' button at the top of the Assignment.")}
            </div>
          </h3>
        </div>
      ) : null}
    </div>
  );
};

export default withQueryClient(withCurrentUser(AssignmentSnippet));
