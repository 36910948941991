import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { findReflectionForm, FindReflectionFormArguments, FindReflectionFormResponse } from '../../../api/reflections/[reflectionId]/forms/[formId]';
import { useState } from 'react';
import { JobStage } from '../../../types/Atlas';

const useFindReflectionFormQuery = (
  args: FindReflectionFormArguments,
  opts?: {
    enabled?: boolean;
    refetchInterval?: number;
  },
): UseQueryResult<FindReflectionFormResponse> => {
  const { reflectionId, formId, ...otherParams } = args.params;

  const queryClient = useQueryClient();
  const [previousCompleteJobCount, setPreviousCompleteJobCount] = useState<number | undefined>();

  return (
    useQuery(
      ['reflections', reflectionId, 'forms', formId, otherParams],
      () => findReflectionForm(args),
      {
        onSuccess: (data) => {
          const completeJobCount = data.form_jobs.filter((job) => job.stage === JobStage.Completed).length;

          // Typically form jobs create form data. If the completed job count has increased, then there is most likely
          // new form data to fetch. Invalidating the query will fetch this data if it's depended on.
          if (
            (previousCompleteJobCount !== undefined)
            && (previousCompleteJobCount !== completeJobCount)
           ) {
            void queryClient.invalidateQueries(['form_data'])
          }

          setPreviousCompleteJobCount(completeJobCount);
        },
        ...opts,
      },
    )
  );
};

export default useFindReflectionFormQuery;
