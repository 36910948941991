import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObjectArray } from '../../../api-parser';
import ApiError from '../../../error';
import { jsonHeaders } from '../../../routes';

export const videoSpritesRoute = (args: {
  videoId: Atlas.VideoID;
}): string => `/api/v0/videos/${args.videoId}/sprites`;

export type FindVideoSpritesParams = {
  videoId: Atlas.VideoID;
};

export interface FindVideoSpritesArguments {
  params: FindVideoSpritesParams;
}

export interface FindVideoSpritesResponse {
  data: Atlas.VideoSpriteSheet[];
}

export const findVideoSprites = async (
  args: FindVideoSpritesArguments,
): Promise<FindVideoSpritesResponse> => {
  const { params } = args;

  const url = videoSpritesRoute({ videoId: params.videoId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findVideoSprites');
  }

  const body = await response.json() as { data: object[]; metadata: unknown; };
  const data = parseModelObjectArray<Atlas.VideoSpriteSheet>(body.data);

  return { data };
};
