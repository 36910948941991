import React, { useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import VideoEditor from '../../video-editor';
import DynamicMenu from '../DynamicMenu';
import ReflectionForms from '../ReflectionForms';
import useFindReflectionQuery from '../../../common/hooks/api/reflections/useFindReflectionQuery';
import ReflectionComments from '../reflection-comments/ReflectionComments';
import ReflectionFiles from '../reflection-files/ReflectionFiles';
import { isPresent } from '../../../common/utils/utils';
import useReflectionStore from './store/useReflectionStore';
import { ReflectionTab } from './store/reflection-tabs.store';
import { ReflectionModal } from './store/reflection-modals.store';
import { isUserOwnedReflection } from './utils/is-user-owned-reflection';
import useFeatureToggle from '../../../common/hooks/useFeatureToggle';
import useVideoPlayer from '../../jwplayer/useVideoPlayer';

interface ReflectionContentProps {
  reflectionId: Atlas.ReflectionID;
  focusMode?: boolean;
  toggleFocusMode?: () => void;
}

const ReflectionContent = (props: ReflectionContentProps) => {
  const { reflectionId, focusMode, toggleFocusMode } = props;

  const videoPlayer = useVideoPlayer();

  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const reflection = reflectionQuery.data?.data;

  const userOwned = reflection && isUserOwnedReflection(reflection);

  const reflectionEmbedToggleEnabled = useFeatureToggle('reflection_embed');

  const {
    activeTab, changeTab, changeModal,
  } = useReflectionStore((state) => ({
    activeTab: state.activeTab,
    changeTab: state.changeTab,
    changeModal: state.changeModal,
  }));

  // The tabs which have been shown previously are stored in this state.
  // All tabs in this state are mounted, but visually hidden if not active.
  // This preserves UI state.
  const [mountedTabs, setMountedTabs] = useState(new Set<ReflectionTab>([activeTab]));
  if (!mountedTabs.has(activeTab)) {
    setMountedTabs((s) => new Set(s).add(activeTab));
  }

  const mountAndChangeTab = (tab: ReflectionTab) => {
    setMountedTabs((s) => new Set(s).add(tab));
    changeTab(tab);
  };

  const formsEnabled = reflection?.form_mode !== Atlas.FormMode.Disabled;
  const filesEnabled = reflection?.attachment_mode !== Atlas.AttachmentMode.Disabled;

  const mainItems = [
    {
      id: 'comments',
      label: __('Comments'),
      className: activeTab === ReflectionTab.Comments ? 'tw-btn tw-btn-primary tw-rounded-full tw-btn-xs xs:tw-btn-sm' : undefined,
      onClick: () => mountAndChangeTab(ReflectionTab.Comments),
    },
    {
      id: 'videos',
      label: __('Videos'),
      className: activeTab === ReflectionTab.Videos ? 'tw-btn tw-btn-primary tw-rounded-full tw-btn-xs xs:tw-btn-sm' : undefined,
      onClick: () => mountAndChangeTab(ReflectionTab.Videos),
    },
    formsEnabled ? ({
      id: 'forms',
      label: __('Insights'),
      className: activeTab === ReflectionTab.Forms ? 'tw-btn tw-btn-primary tw-rounded-full tw-btn-xs xs:tw-btn-sm' : undefined,
      onClick: () => mountAndChangeTab(ReflectionTab.Forms),
    }) : undefined,
    {
      id: 'files',
      label: __('Files'),
      className: activeTab === ReflectionTab.Files ? 'tw-btn tw-btn-primary tw-rounded-full tw-btn-xs xs:tw-btn-sm' : undefined,
      onClick: () => mountAndChangeTab(ReflectionTab.Files),
    },
  ].filter(isPresent);

  const additionalItems = [
    userOwned ? {
      className: focusMode ? 'tw-hidden' : undefined,
      id: 'sharing',
      label: __('Sharing'),
      onClick: () => changeModal({ modal: ReflectionModal.Sharing }),
    } : undefined,
    userOwned && reflectionEmbedToggleEnabled ? {
      className: focusMode ? 'tw-hidden' : undefined,
      id: 'embed',
      label: __('Embed'),
      onClick: () => changeModal({ modal: ReflectionModal.Embed }),
    } : undefined,
    userOwned ? {
      className: focusMode ? 'tw-hidden' : undefined,
      id: 'privacy',
      label: __('Privacy'),
      onClick: () => changeModal({ modal: ReflectionModal.Privacy }),
    } : undefined,
    {
      className: focusMode ? 'tw-hidden' : undefined,
      id: 'details',
      label: __('Details'),
      onClick: () => changeModal({ modal: ReflectionModal.Details }),
    },
  ].filter(isPresent);

  const allItems = [
    ...mainItems,
    ...additionalItems,
  ];

  return (
    <div className="tw-flex tw-flex-col tw-h-full">
      <div className="lg:tw-hidden tw-flex tw-w-full tw-mb-2 xs:tw-mb-4">
        <div className="tw-flex-1 tw-w-[calc(100%-40px)] lg:tw-w-full">
          <DynamicMenu
            defaultButtonClassName={`tw-btn tw-btn-ghost tw-rounded-full tw-btn-xs xs:tw-btn-sm ${focusMode ? 'tw-text-base-100' : ''}`}
            items={allItems}
          />
        </div>
        {toggleFocusMode ? (
          <button type="button" className={`tw-btn tw-btn-xs xs:tw-btn-sm tw-btn-error tw-btn-circle lg:tw-hidden ${focusMode ? '' : 'tw-hidden'}`} onClick={toggleFocusMode}>
            <i className="fa fa-arrows-alt" />
          </button>
        ) : null}
      </div>

      <div className="tw-hidden lg:tw-block tw-mb-4">
        <DynamicMenu
          defaultButtonClassName={`tw-btn tw-btn-ghost tw-rounded-full tw-btn-sm ${focusMode ? 'tw-text-base-100' : ''}`}
          items={mainItems}
        />
      </div>

      {mountedTabs.has(ReflectionTab.Comments) || activeTab === ReflectionTab.Comments ? (
        <div className={`tw-h-full ${activeTab === ReflectionTab.Comments ? '' : 'tw-hidden'}`}>
          <ReflectionComments
            reflectionId={reflectionId}
          />
        </div>
      ) : null}

      {mountedTabs.has(ReflectionTab.Videos) || activeTab === ReflectionTab.Videos ? (
        <div className={`tw-h-full ${activeTab === ReflectionTab.Videos ? '' : 'tw-hidden'}`}>
          <VideoEditor
            reflectionId={reflectionId}
            onEditVideoClick={(videoId: Atlas.VideoID) => {
              videoPlayer?.pause();
              changeModal({ modal: ReflectionModal.EditVideo, videoId });
            }}
          />
        </div>
      ) : null}

      {mountedTabs.has(ReflectionTab.Forms) || activeTab === ReflectionTab.Forms ? (
        <div className={`tw-h-full ${activeTab === ReflectionTab.Forms ? '' : 'tw-hidden'}`}>
          {formsEnabled ? (
            <ReflectionForms
              reflectionId={reflectionId}
            />
          ) : (
            <div className="tw-alert tw-bg-white tw-shadow tw-text-gray-600">
              {__('Insights are disabled for this reflection.')}
            </div>
          )}
        </div>
      ) : null}

      {mountedTabs.has(ReflectionTab.Comments) || activeTab === ReflectionTab.Files ? (
        <div className={`tw-h-full ${activeTab === ReflectionTab.Files ? '' : 'tw-hidden'}`}>
          <ReflectionFiles
            reflectionId={reflectionId}
            filesEnabled={filesEnabled}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ReflectionContent;
