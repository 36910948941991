import React, { ChangeEvent, useEffect } from 'react';

// UI
import { Slider } from '../../../../common/components';

// Utils
import { clamp } from '../../../../common/utils/utils';
import * as Atlas from '../../../../common/types/Atlas';

const numberOrMiddle = (maybeN: number | undefined, min: number, max: number) => (
  Number.isInteger(maybeN) ? maybeN : Math.floor((min + max) / 2)
);

export interface RatingFormAnswer {
  name: string;
  value?: number;
}

interface RatingQuestionProps {
  readOnly?: boolean;
  question: Atlas.FormElement;
  answer?: RatingFormAnswer;
  onInit?(fa: RatingFormAnswer): void;
  onAnswerChange?(fa: RatingFormAnswer): void;
}

const RatingQuestion = (props: RatingQuestionProps): JSX.Element => {
  const { question, answer, readOnly } = props,
        { field } = question,
        { type } = field;

  if (!('rating' in field.type)) {
    throw 'FormFieldType is incompatible with RatingFormFieldType';
  }

  const {
    maxLabel = '',
    maxOrdinal = 0,
    minLabel = '',
    minOrdinal = 10
  } = (() => {
    if ('rating' in type) {
      const {
        max_label: maxLabel,
        max_ordinal: maxOrdinal,
        min_label: minLabel,
        min_ordinal: minOrdinal
      } = type.rating;

      return {
        maxLabel,
        maxOrdinal,
        minLabel,
        minOrdinal
      };
    }

    return {};
  })();

  const value = numberOrMiddle(answer?.value, minOrdinal, maxOrdinal);

  useEffect(() => {
    if (readOnly) { return; }
    if (value === undefined) { return; }
    if (value === answer?.value) { return; }

    if (props.onInit) {
      props.onInit({
        name: question.field.name,
        ...answer,
        value: clamp(minOrdinal, value, maxOrdinal)
      });
    }
  }, [readOnly, value, answer?.value, minOrdinal, maxOrdinal]);

  const handleRatingChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) { return; }

    if (props.onAnswerChange) {
      const { value } = event.currentTarget;

      props.onAnswerChange({
        name: question.field.name,
        ...answer,
        value: Number(value)
      });
    }
  };

  const steps = new Array(maxOrdinal - minOrdinal + 1).fill(null).map((_, n) => n + minOrdinal)

  return (
    <div>
      <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mb-2">
        <div className="tw-text-left tw-truncate">{minLabel}</div>
        <div className="tw-text-right tw-truncate">{maxLabel}</div>
      </div>

      <div>
        <div className="tw-flex tw-justify-between tw-px-3 tw-h-4">
          {steps.map((step) => (
            <div key={step} className="tw-relative">
              <small className="tw-absolute tw-left-0 -tw-translate-x-1/2">
                {step}
              </small>
            </div>
          ))}
        </div>

        <Slider
          required={question.field.required}
          readOnly={readOnly}
          type="range"
          min={minOrdinal}
          max={maxOrdinal}
          value={value}
          onChange={handleRatingChange} />
      </div>
    </div>
  );
};

export default RatingQuestion;
