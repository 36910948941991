import styled from '@emotion/styled';

export const LineWidth = 2;

export const Line = styled.div`
  height: 100%;
  border-left: ${LineWidth}px solid #ddd;
`;

export const LineWithDot = styled(Line)`
  position: relative;

  &::after {
    background: #fff;
    border-radius: 50%;
    border: 2px solid #5bc0de;
    content: '';
    display: block;
    height: .5rem;
    left: -${LineWidth / 2}px;
    position: absolute;
    top: 10%;
    transform: translate(-50%, 50%);
    width: .5rem;
  }
`;

export const LineEnd = styled(Line)`
  position: relative;

  &::after {
    background: #ddd;
    border-radius: 50%;
    bottom: 0;
    content: '';
    display: block;
    height: .5rem;
    left: -${LineWidth / 2}px;
    position: absolute;
    transform: translate(-50%, 50%);
    width: .5rem;
  }
`;
