import React from 'react';
import * as Atlas from '../../../common/types/Atlas';
import { Modal, ModalBox, ModalHeader } from '../../../common/components/TailwindModal';
import useFindVideoCaptionsQuery from '../../../common/hooks/api/videos/useFindVideoCaptionsQuery';

const renderDownloadLink = (name: string, url?: string) => {
  if (url) {
    return (
      <a
        className="tw-px-5 tw-py-3 hover:tw-bg-base-200 tw-rounded-xl tw-flex tw-items-center tw-justify-between tw-gap-4"
        href={url}
        download
      >
        <div className="tw-text-xl">
          {name}
        </div>

        <div
          className="tw-link tw-link-hover tw-link-primary tw-text-base tw-font-semibold"
        >
          {__('Download')}
        </div>
      </a>
    );
  }

  return (
    <div
      className="tw-px-5 tw-py-3"
    >
      <div className="tw-text-xl">
        {name}
      </div>
    </div>
  );
};

const VideoCaptionsList: React.FC<{
  videoId: Atlas.VideoID;
}> = (props) => {
  const { videoId } = props;

  const query = useFindVideoCaptionsQuery({ params: { videoId } });

  switch (query.status) {
    case 'idle':
    case 'loading': {
      return (
        <div className="tw-text-center tw-py-5">
          <i className="fa fa-circle-o-notch fa-spin tw-text-3xl" />
        </div>
      );
    }

    case 'success': {
      return (
        <ul>
          {renderDownloadLink(__('Comments'), `/videos/${videoId}/download_subtitles`)}

          {query.data.data.map((caption) => (
            <React.Fragment key={caption.id}>
              {renderDownloadLink(caption.name, caption.media_s3uri?.url)}
            </React.Fragment>
          ))}
        </ul>
      );
    }

    case 'error':
    default: return (
      <div className="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center">
        <p className="tw-text-lg tw-font-semibold">
          {__('Oops! Something went wrong.')}
        </p>

        <button
          type="button"
          className="tw-btn tw-btn-wide tw-btn-sm"
          onClick={() => query.refetch()}
        >
          {__('Retry')}
        </button>
      </div>
    )
  }
};

const VideoCaptionsModal: React.FC<{
  videoId: Atlas.VideoID;
  isOpen: boolean;
  onClose: () => void;
}> = (props): JSX.Element => {
  const { videoId, isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBox>
        <ModalHeader onClose={onClose}>
          {__('Captions')}
        </ModalHeader>

        <VideoCaptionsList videoId={videoId} />
      </ModalBox>
    </Modal>
  );
};

export default VideoCaptionsModal;
