import React from 'react';
import useCurrentUserQuery from '../../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../../common/types/Atlas';
import DeletePageButton from '../buttons/DeletePageButton';
import PublishPageButton from '../buttons/PublishButton';
import RenamePageButton from '../buttons/RenamePageButton';
import SanitizeButton from '../buttons/SanitizeButton';

const GroupMenuPage: React.FC<{
  page: Atlas.Page;
  editable?: boolean;
  showDisc?: boolean;
}> = (props) => {
  const { page, editable, showDisc } = props;

  const userQuery = useCurrentUserQuery();

  const isSuperAdmin = () => {
    if (userQuery.status !== 'success') { return false; }

    const user = userQuery.data;

    return user.admin === Atlas.AdministratorType.Super;
  };

  const url = `/groups/${page.group_id}/pages/${page.id}`;
  const isActivePage = window.location.pathname.startsWith(url);
  const isUnpublished = page.publish === Atlas.PublishStatus.Unpublished;

  return (
    <div className="tw-flex tw-items-center" data-testid="group-page-list-item">
      <a
        href={url}
        className={`
          tw-flex-1
          ${editable ? 'tw-rounded-r-lg' : ''}
          tw-btn tw-btn-ghost tw-px-4 tw-py-2 tw-block tw-rounded-none tw-min-h-0 tw-h-auto tw-text-start tw-font-normal
          tw-text-sm
          ${isActivePage ? 'tw-bg-primary !tw-text-base-100' : ''}
          ${isUnpublished ? 'tw-italic' : ''}
          tw-text-left rtl:tw-text-right
        `}
      >
        {showDisc ? (
          <span className="tw-text-xl tw-leading-[0] tw-mx-2">&bull;</span>
        ) : null}

        {isUnpublished ? (
          <i className="fa fa-eye-slash tw-mr-1 tw-text-error/80" />
        ) : null}

        {page.title}
      </a>

      {editable ? (
        <div className="px-1">
          <div className="tw-dropdown tw-dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="tw-btn tw-btn-ghost tw-text-sm tw-px-3 tw-py-2 tw-block tw-min-h-0 tw-h-auto tw-text-start tw-font-normal"
              data-testid="options-button"
            >
              <i className="fa fa-cog" />
            </div>

            <ul
              tabIndex={0}
              className="tw-dropdown-content tw-z-50 tw-menu tw-p-2 p-_ tw-bg-base-100 tw-rounded-box tw-shadow tw-w-36">
              <li>
                <a href={`/groups/${page.group_id}/pages/${page.id}/edit`}>
                  {__('Edit')}
                </a>
              </li>
              <li>
                <RenamePageButton page={page} />
              </li>
              <li>
                <PublishPageButton page={page} />
              </li>
              {isSuperAdmin() ? (
                <li>
                  <SanitizeButton page={page} />
                </li>
              ) : null}
              <li>
                <DeletePageButton page={page} />
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GroupMenuPage;
