import { useQuery, UseQueryResult } from 'react-query';
import { findReflection, FindReflectionResponse } from '../../../api/reflections';
import * as Atlas from '../../../types/Atlas';

const useFindReflectionQuery = (
  reflectionId: Atlas.ReflectionID,
  options?: { staleTime?: number; enabled?: boolean; },
): UseQueryResult<FindReflectionResponse> => (
  useQuery(
    ['reflections', reflectionId],
    () => findReflection({ params: { reflectionId } }),
    {
      staleTime: 60000,
      ...options,
    },
  )
);

export default useFindReflectionQuery;
