import { zip } from 'lodash-es';
import { createVideo, toUploadVideo, uploadedVideo } from '../../../../common/api/videos';
import { Channel, ChannelType, CommentID, DeviceType, VideoID } from '../../../../common/types/Atlas';
import * as StreamingUploader from '../../../../legacy/typescript/ts-s3-multi-part-upload/src';
import RecordManager from './RecordManager';
import { generateUuidv4 } from './utils';
import { destroyVideo } from '../../../../common/api/videos/[videoId]';
import { useEffect, useState } from 'react';
import { destroyComment } from '../../../../common/api/comments';
import { queryClient } from '../../../../common/hooks/withQueryClient';

const generateS3Credentials = async (mediaKey: string) => {
  const { data: stsCredentials } = await toUploadVideo({ params: { videoMasterKey: mediaKey } });

  const { bucket } = stsCredentials.s3_object;
  const s3Path = stsCredentials.s3_object.path;
  let key = mediaKey;

  const tempKey = stsCredentials.credentials.AccessKeyId as string;
  const tempSecret = stsCredentials.credentials.SecretAccessKey as string;
  const sessionToken = stsCredentials.credentials.SessionToken as string;

  const s3Bucket = new StreamingUploader.uploader.S3Bucket(bucket, stsCredentials.s3_object.endpoint);

  const uploadCredentials = new StreamingUploader.uploader.S3UploadCredentials(
    tempKey,
    sessionToken,
    tempSecret,
    key,
    s3Bucket,
    s3Path
  );

  return uploadCredentials;
};

const createRecordManager = async (recorder: MediaRecorder, channel: Channel, onStatusChange: RecordManager['onStatusChange'], onUploadProgress: StreamingUploader.types.OnProgress) => {
  const mediaKey = channel.media_key;
  if (!mediaKey) { throw new Error('Media key is missing'); }

  const s3Credentials = await generateS3Credentials(mediaKey);

  const uploader = new StreamingUploader.uploader.S3MultipartUploader(
    channel.type,
    s3Credentials,
    onUploadProgress,
  );

  return new RecordManager(recorder, uploader, onStatusChange);
}

type Streams = [MediaStream] | [MediaStream, MediaStream];

export type VideoStreamRecorderStatus = {
  status: RecordManager['status'];
  uploadProgress?: StreamingUploader.types.UploadStats;
};

type VideoStreamRecorderStatuses = {
  left: VideoStreamRecorderStatus;
  right: VideoStreamRecorderStatus;
};

export default class VideoStreamRecorder {
  scope: { commentId: CommentID; };
  streams: Streams;

  recordingContexts?: Array<{
    mediaKey: string;
    manager: RecordManager;
  }>;

  _statuses: VideoStreamRecorderStatuses = {
    left: {
      status: 'idle',
    },
    right: {
      status: 'idle',
    },
  };

  videoId?: VideoID;

  subscribers = {
    progress: new Set<(statuses: VideoStreamRecorderStatuses) => void>(),
  } as const;

  on(event: 'progress', cb: (statuses: VideoStreamRecorderStatuses) => void) {
    const eventSubscribers = this.subscribers[event];

    eventSubscribers.add(cb);
  }

  off(event: 'progress', cb: (statuses: VideoStreamRecorderStatuses) => void) {
    const eventSubscribers = this.subscribers[event];

    eventSubscribers.delete(cb);
  }

  get statuses() {
    return this._statuses;
  }

  set statuses(value: VideoStreamRecorderStatuses) {
    this._statuses = value;
    this.subscribers.progress.forEach((cb) => cb(value));
  }

  set leftStatus(nextStatus: RecordManager['status']) {
    this.statuses = {
      ...this.statuses,
      left: {
        ...this.statuses.left,
        status: nextStatus,
      }
    };
  }

  set leftProgress(nextProgress: StreamingUploader.types.UploadStats) {
    this.statuses = {
      ...this.statuses,
      left: {
        ...this.statuses.left,
        uploadProgress: nextProgress,
      },
    };
  }

  set rightStatus(nextStatus: RecordManager['status']) {
    this.statuses = {
      ...this.statuses,
      right: {
        ...this.statuses.right,
        status: nextStatus,
      }
    };
  }

  set rightProgress(nextProgress: StreamingUploader.types.UploadStats) {
    this.statuses = {
      ...this.statuses,
      right: {
        ...this.statuses.right,
        uploadProgress: nextProgress,
      },
    };
  }

  constructor(scope: { commentId: CommentID }, streams: Streams) {
    this.scope = scope;
    this.streams = streams;
  }

  async setupDual() {
    if (this.recordingContexts) { throw new Error('Already set up'); }

    const [leftStream, rightStream] = this.streams;
    if (!rightStream) { throw new Error('Right stream is missing'); }

    const video = await this.createVideo();

    const leftChannel = video.channels.find((channel) => channel.type === ChannelType.VideoLeft);
    if (!leftChannel) { throw new Error('Left channel not found'); }
    if (!leftChannel.media_key) { throw new Error('Left channel is missing the media key'); }

    const rightChannel = video.channels.find((channel) => channel.type === ChannelType.VideoRight);
    if (!rightChannel) { throw new Error('Right channel not found'); }
    if (!rightChannel.media_key) { throw new Error('Left channel is missing the media key'); }

    const leftRecorder = new MediaRecorder(leftStream);
    const rightRecorder = new MediaRecorder(rightStream);

    const leftManager = {
      mediaKey: leftChannel.media_key,
      manager: await createRecordManager(leftRecorder, leftChannel, (status) => {
        this.leftStatus = status;
      }, (stats) => {
        this.leftProgress = stats;
      }),
    };

    const rightManager = {
      mediaKey: rightChannel.media_key,
      manager: await createRecordManager(rightRecorder, rightChannel, (status) => {
        this.rightStatus = status;
      }, (stats) => {
        this.rightProgress = stats;
      }),
    };

    this.recordingContexts = [leftManager, rightManager];
    return this.recordingContexts;
  }

  async setupSingle() {
    if (this.recordingContexts) { throw new Error('Already set up'); }

    const [leftStream, rightStream] = this.streams;
    if (rightStream) { throw new Error('Right stream is missing'); }

    const video = await this.createVideo();

    const leftChannel = video.channels.find((channel) => channel.type === ChannelType.Video);
    if (!leftChannel) { throw new Error('Left channel not found'); }
    if (!leftChannel.media_key) { throw new Error('Left channel is missing the media key'); }

    const leftRecorder = new MediaRecorder(leftStream);

    const leftManager = {
      mediaKey: leftChannel.media_key,
      manager: await createRecordManager(leftRecorder, leftChannel, (status) => {
        this.leftStatus = status;
      }, (stats) => {
        this.leftProgress = stats;
      }),
    };

    this.recordingContexts = [leftManager];
    return this.recordingContexts;
  }

  get setup() {
    switch (this.streams.length) {
      case 1: return this.setupSingle;
      case 2: return this.setupDual;
      default: throw new Error('Unsupported stream count');
    }
  }

  async start() {
    if (!this.recordingContexts) { throw new Error('Setup required'); }

    await Promise.all(this.recordingContexts.map(async ({ mediaKey, manager }) => {
      await manager.start();

      await uploadedVideo({
        params: { videoMasterKey: mediaKey },
      });
    }));
  }

  stop() {
    this.recordingContexts?.forEach(({ manager }) => {
      manager.stop();
    });

    this.streams.forEach((stream) => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    });
  }

  async abort() {
    this.recordingContexts?.forEach(({ manager }) => {
      manager.abort();
    });

    this.streams.forEach((stream) => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    });

    if (this.videoId) {
      await destroyVideo({ params: { videoId: this.videoId } });
    }

    if ('commentId' in this.scope) {
      await destroyComment({ params: { commentId: this.scope.commentId } });
    }

    await queryClient.invalidateQueries(['videos']);
  }

  async createVideo() {
    const data = {
      ...this.scope,
      channels: this.streams.map(() => ({})),
      upload_source: { device: DeviceType.ScreenCapture },
    } as const;

    const response = await createVideo({
      body: data,
    });

    this.videoId = response.data.id;

    return response.data;
  }
}

export const useVsrStatuses = (vsr: VideoStreamRecorder) => {
  const [statuses, setStatuses] = useState<VideoStreamRecorder['statuses']>(vsr.statuses);

  useEffect(() => {
    const cb = (nextStatuses: VideoStreamRecorder['statuses']) => {
      setStatuses(nextStatuses);
    };

    vsr.on('progress', cb);
    cb(vsr.statuses);

    return () => {
      vsr.off('progress', cb);
    };
  }, [vsr]);

  return statuses;
};
