import { useMutation, UseMutationResult } from 'react-query';
import { queryClient } from '../../withQueryClient';
import { DisassociateReflectionFormArguments, DisassociateReflectionFormResponse, disassociateReflectionForm } from '../../../api/reflections/[reflectionId]/forms/[formId]/disassociate';

const useDisassociateReflectionFormMutation = (): UseMutationResult<
DisassociateReflectionFormResponse, unknown, DisassociateReflectionFormArguments, unknown
> => {
  const mutation = useMutation(disassociateReflectionForm, {
    onSuccess: async (_, args) => {
      await queryClient.invalidateQueries(['reflections', args.params.reflectionId, 'forms']);
    },
  });

  return mutation;
};

export default useDisassociateReflectionFormMutation;
