import * as Atlas from '../../common/types/Atlas';

export default (formElement: Atlas.FormElement): boolean => {
  if ('text' in formElement.field.type) {
    return !!formElement.field.label;
  }

  if ('multiple_choice' in formElement.field.type) {
    const { field: { label, type } } = formElement;
    const { multiple_choice: { choices = [] } } = type;

    return !!label && !!choices.length && choices.every(Boolean);
  }

  if ('rating' in formElement.field.type) {
    const { field: { label, type } } = formElement;
    const { rating } = type;

    return (
      !!label
      && Number.isInteger(rating.min_ordinal)
      && Number.isInteger(rating.max_ordinal)
      && typeof (rating.min_label) === 'string'
      && typeof (rating.max_label) === 'string'
    );
  }

  if ('counter' in formElement.field.type) {
    const { field: { label, type } } = formElement;
    const { counter: { choices = [] } } = type;

    return !!label && !!choices.length && choices.every(Boolean);
  }

  if ('timer' in formElement.field.type) {
    const { field: { label, type } } = formElement;
    const { timer: { choices = [] } } = type;

    return !!label && !!choices.length && choices.every(Boolean);
  }

  if ('attachment' in formElement.field.type) {
    return !!formElement.field.label;
  }

  if ('reflection' in formElement.field.type) {
    return !!formElement.field.label;
  }

  return false;
};
