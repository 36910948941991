export const mentionStyle = {
  control: {
    fontSize: '.875rem',
    fontWeight: 'normal',
  },

  input: {
    border: 'none',
    boxShadow: 'none',
  },

  '&multiLine': {
    highlighter: {
      padding: '.5rem',
      border: '1px solid transparent',
    },

    input: {
      padding: '.5rem',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },

    input: {
      padding: 1,
    },
  },

  suggestions: {
    marginTop: '1rem',

    list: {
      backgroundColor: 'white',
      boxShadow: '0px 1.5px 3px 1px #ededed',
      borderRadius: '.25rem',
      fontSize: 14,
      overflow: 'auto',
    },

    item: {
      padding: '.25rem .5rem',
      borderBottom: '.5px solid #94949426',
      '&focused': {
        backgroundColor: '#8ae2ff26',
      },
    },
  },
};
