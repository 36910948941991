import React, { useState } from 'react';

export type AdvancedSettingsFormChanges = {
  lockOnComplete?: boolean;
};

const AdvancedSettingsForm: React.FC<{
  lockOnComplete?: boolean;
  onSubmit: (changes: AdvancedSettingsFormChanges) => void;
}> = (props) => {
  const {
    lockOnComplete,
    onSubmit,
  } = props;

  const [state, setState] = useState<AdvancedSettingsFormChanges>({});

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    onSubmit(state);
  };

  const handleLockOnCompleteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    setState((prev) => ({
      ...prev,
      lockOnComplete: value === 'true',
    }));
  };

  const nextLockOnComplete = state.lockOnComplete ?? lockOnComplete ?? false;

  return (
    <form onSubmit={handleSubmit} className="tw-space-y-4">
      <label className="tw-block">
        <div className="tw-font-semibold tw-mb-1">
          {__('Lock questions after answering')}
        </div>

        <select
          className="tw-select tw-select-sm tw-select-bordered tw-block tw-w-full"
          name="lockOnComplete"
          value={nextLockOnComplete ? 'true' : 'false'}
          onChange={handleLockOnCompleteChange}
        >
          <option value="false">{__('Off')}</option>
          <option value="true">{__('On')}</option>
        </select>
      </label>

      <button type="submit" className="tw-btn">
        {__('Done')}
      </button>
    </form>
  );
};

export default AdvancedSettingsForm;
