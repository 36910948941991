import React, { useCallback, useEffect, useState } from 'react';
import AudioLevelBar from './AudioLevelBar';
import VideoStreamPlayer from './VideoStreamPlayer';
import { streamHasAudio } from '../reflections/reflection-comments/attachments/utils';

type GroupedDevices = {
  audioinput: MediaDeviceInfo[];
  videoinput: MediaDeviceInfo[];
}

type MediaCaptureFormState = {
  devices: GroupedDevices;

  videoInput?: string;
  audioInput?: string;

  desktopAudio: boolean;

  userMediaPermitted: boolean;
  userMediaError: boolean;
  displayPermitted: boolean;

  userStream?: MediaStream;
  displayStream?: MediaStream;
}

const groupDevices = (devices: MediaDeviceInfo[]): GroupedDevices => {
  return devices.reduce<GroupedDevices>((acc, device) => {
    switch (device.kind) {
      case 'audioinput':
      case 'videoinput': {
        acc[device.kind].push(device);
        return acc;
      }

      default: {
        return acc;
      }
    }
  }, {
    audioinput: [],
    videoinput: [],
  });
};

type MediaCaptureFormProps = AtLeastOne<{
  audio: true;
  video: true;
  desktop: true;
}> & {
  disabled?: boolean;
  children: (state: MediaCaptureFormState) => JSX.Element;
}

const videoConstraints = {
  aspectRatio: 16 / 9,
};

const MediaCaptureForm = (props: MediaCaptureFormProps) => {
  const { audio, video, desktop, disabled, children } = props;

  const [state, setState] = useState<MediaCaptureFormState>({
    devices: {
      audioinput: [],
      videoinput: [],
    },
    desktopAudio: false,
    userMediaPermitted: false,
    userMediaError: false,
    displayPermitted: false,
  });

  useEffect(() => {
    if (!state.userMediaPermitted) { return; }

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const groupedDevices = groupDevices(devices);

      setState((s) => ({
        ...s,
        devices: groupedDevices,
        audioInput: groupedDevices.audioinput[0]?.deviceId,
        videoInput: groupedDevices.videoinput[0]?.deviceId,
      }));
    });
  }, [state.userMediaPermitted]);

  const stopStream = useCallback((name: 'userStream' | 'displayStream') => {
    setState((s) => {
      if (!s[name]) { return s; }

      return {
        ...s,
        [name]: undefined,
      };
    });
  }, []);

  const requestUserMedia = useCallback(async (constraints: MediaStreamConstraints) => {
    try {
      setState((s) => ({ ...s, userMediaError: false }));

      const userStream = await navigator.mediaDevices.getUserMedia(constraints);

      userStream.getTracks().forEach((track) => {
        track.onended = () => { stopStream('userStream'); };
      });

      setState((s) => ({ ...s, userStream, userMediaPermitted: true }));
    } catch (e) {
      setState((s) => ({ ...s, userMediaError: true }));

      throw e;
    }
  }, []);

  const requestDisplayMedia = useCallback(async () => {
    const displayStream = await navigator.mediaDevices.getDisplayMedia({
      audio: true,
      video: true,
    });

    displayStream.getTracks().forEach((track) => {
      track.onended = () => { stopStream('displayStream'); };
    });

    setState((s) => ({ ...s, displayStream, displayPermitted: true }));
  }, []);

  useEffect(() => {
    if (!audio && !video) {
      stopStream('userStream');
      return;
    }

    requestUserMedia({
      audio: (audio && state.audioInput) ? ({ deviceId: state.audioInput }) : audio,
      video: (video && state.videoInput) ? ({ deviceId: state.videoInput, ...videoConstraints }) : video,
    }).catch(() => {
      stopStream('userStream');
    });
  }, [
    audio,
    video,
    state.audioInput,
    state.videoInput,
  ]);

  useEffect(() => {
    if (!desktop) {
      stopStream('displayStream');
      return;
    }

    requestDisplayMedia().catch(() => {
      stopStream('displayStream');
    });
  }, [desktop]);

  useEffect(() => {
    const { userStream } = state;

    if (!userStream) { return; }

    return () => {
      userStream.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [state.userStream]);

  useEffect(() => {
    const { displayStream } = state;

    if (!displayStream) { return; }

    return () => {
      displayStream.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [state.displayStream]);

  const handleVideoInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    setState((s) => ({ ...s, videoInput: value }));
  };

  const handleAudioInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    setState((s) => ({ ...s, audioInput: value }));
  };

  const renderDisplayOverlay = () => (
    <div className="tw-bg-black/50 tw-text-white tw-grid tw-place-items-center tw-transition-opacity tw-opacity-0 hover:tw-opacity-100">
      <button
        type="button"
        className="tw-btn tw-btn-accent tw-border tw-border-base-400"
        disabled={disabled}
        onClick={() => requestDisplayMedia()}
      >
        {__('Change screen')}
      </button>
    </div>
  );

  const renderDisplayPlaceholder = () => (
    <div className="tw-grid tw-place-items-center">
      <button
        type="button"
        className="tw-btn tw-btn-accent tw-border tw-border-base-400"
        disabled={disabled}
        onClick={() => requestDisplayMedia()}
      >
        {__('Select screen')}
      </button>
    </div>
  );

  const renderUserPlaceholder = () => (
    <div className="tw-grid tw-place-items-center">
      <span className="tw-text-xl tw-text-center">
        {__('No camera selected')}
      </span>
    </div>
  );

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {video && desktop ? (
        <VideoStreamPlayer
          leftStream={state.displayStream}
          rightStream={state.userStream}
          leftOverlay={renderDisplayOverlay()}
          leftPlaceholder={renderDisplayPlaceholder()}
          rightPlaceholder={renderUserPlaceholder()}
        />
      ) : null}

      {video && !desktop ? (
        <VideoStreamPlayer
          leftStream={state.userStream}
          leftPlaceholder={renderUserPlaceholder()}
        />
      ) : null}

      {!video && desktop ? (
        <VideoStreamPlayer
          leftStream={state.displayStream}
          leftOverlay={renderDisplayOverlay()}
          leftPlaceholder={renderDisplayPlaceholder()}
        />
      ) : null}

      {video ? (
        <div className="tw-form-control">
          <label className="tw-label tw-mb-0">
            <span className="tw-label-text tw-font-bold">
              {__('Camera')}
            </span>
          </label>

          <select
            className="tw-select tw-select-bordered tw-w-full"
            value={state.videoInput}
            onChange={handleVideoInputChange}
            disabled={disabled}
          >
            <option disabled={state.devices.videoinput.length !== 0} value="">
              {__('Pick a camera')}
            </option>

            {state.devices.videoinput.map((vd) => (
              <option key={vd.deviceId} value={vd.deviceId}>
                {vd.label}
              </option>
            ))}
          </select>

          {state.userMediaError && !state.userStream ? (
            <p className="tw-alert tw-alert-error tw-mt-1">
              {__('Failed to load video devices. Please check your browser permissions.')}
            </p>
          ) : null}
        </div>
      ) : null}

      {audio ? (
        <div className="tw-form-control">
          <label className="tw-label tw-mb-0">
            <span className="tw-label-text tw-font-bold">
              {__('Microphone')}
            </span>

            {state.audioInput ? (
              <div className="tw-w-1/3 tw-flex tw-items-center tw-gap-1">
                <i className="fa fa-volume-up tw-text-gray-600" />

                <AudioLevelBar
                  stream={state.userStream}
                  className="tw-h-2 tw-bg-gray-200"
                />
              </div>
            ) : null}
          </label>

          <select
            className="tw-select tw-select-bordered tw-w-full"
            value={state.audioInput}
            onChange={handleAudioInputChange}
            disabled={disabled}
          >
            {desktop && !video ? (
              <option value="">
                {__('Off')}
              </option>
            ) : (
              <option disabled={state.devices.audioinput.length !== 0} value="">
                {__('Pick a microphone')}
              </option>
            )}

            {state.devices.audioinput.map((ad) => (
              <option key={ad.deviceId} value={ad.deviceId}>
                {ad.label}
              </option>
            ))}
          </select>

          {state.userMediaError && !state.userStream ? (
            <p className="tw-alert tw-alert-error tw-mt-1">
              {__('Failed to load audio devices. Please check your browser permissions.')}
            </p>
          ) : null}
        </div>
      ) : null}

      {desktop ? (() => {
        const handleDesktopAudioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const { checked } = event.currentTarget;

          setState((s) => ({ ...s, desktopAudio: checked }));
        };

        const systemAudioError = state.displayStream && !streamHasAudio(state.displayStream);

        return (
          <div className="tw-form-control">
            <p className="tw-label">
              <span className="tw-label-text tw-font-bold">
                {__('Settings')}
              </span>
            </p>

            <label className="tw-label tw-cursor-pointer tw-justify-start tw-items-center tw-gap-3 tw-mb-0">
              <input
                type="checkbox"
                className="!tw-checkbox !tw-m-0"
                disabled={systemAudioError || disabled}
                checked={!systemAudioError && state.desktopAudio}
                onChange={handleDesktopAudioChange}
              />

              <div>
                <span className="tw-label-text">
                  {__('Record system audio')}
                </span>

                {systemAudioError ? (
                  <div className="tw-text-red-600 tw-font-semibold">
                    <i className="fa fa-warning tw-mr-2" />
                    {__('System audio not detected.')}
                  </div>
                ) : null}
              </div>
            </label>
          </div>
        );
      })() : null}


      {children(state)}
    </div>
  );
};

export default MediaCaptureForm;
