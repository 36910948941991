/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const cardHeaderVariant = cva('card-header');

export type CardHeaderVariantProps = VariantProps<typeof cardHeaderVariant>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const CardHeader = ({
  className,
  ...props
}: CardHeaderVariantProps & DivProps) => (
  <div
    {...props}
    className={cx(className, cardHeaderVariant())}
  />
);
