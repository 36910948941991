import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObjectArray } from '../../../api-parser';
import ApiError from '../../../error';
import {
  jsonHeaders, Metadata, PaginationParams, searchParams,
} from '../../../routes';

export const reflectionThumbnailsRoute = (args: {
  reflectionId: Atlas.ReflectionID;
}): string => `/api/v0/reflections/${args.reflectionId}/thumbnails`;

export type FindReflectionThumbnailsParams = {
  reflectionId: Atlas.ReflectionID;
} & PaginationParams;

export interface FindReflectionThumbnailsArguments {
  params: FindReflectionThumbnailsParams;
}

export interface FindReflectionThumbnailsResponse {
  data: Atlas.VideoThumbnail[];
  metadata: Metadata;
}

export const findReflectionThumbnails = async (
  args: FindReflectionThumbnailsArguments,
): Promise<FindReflectionThumbnailsResponse> => {
  const { params } = args;

  const url = [
    reflectionThumbnailsRoute({ reflectionId: params.reflectionId }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findReflections');
  }

  const body = await response.json() as { data: object[]; metadata: unknown; };
  const data = parseModelObjectArray<Atlas.VideoThumbnail>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};
