/* eslint-disable import/prefer-default-export */
import * as Atlas from '../../../common/types/Atlas';

const defaultChannelPriority = [
  Atlas.ChannelType.VideoLeftRight,
  Atlas.ChannelType.Video,
];

const overrideToType = (leftOrRight: Atlas.LeftOrRight) => {
  switch (leftOrRight) {
    case Atlas.LeftOrRight.Left:
      return Atlas.ChannelType.VideoLeft;
    case Atlas.LeftOrRight.Right:
      return Atlas.ChannelType.VideoRight;

    default: return null;
  }
};

export const getVideoChannel = (video: Atlas.Video): Atlas.Channel | undefined => {
  const channelPriority = (() => {
    if (video.override_channel) {
      const overrideType = overrideToType(video.override_channel);
      if (!overrideType) { return defaultChannelPriority; }

      return [overrideType, ...defaultChannelPriority];
    }

    return defaultChannelPriority;
  })();

  const sortedChannels = (() => {
    const prioritiesForSorting = [...channelPriority].reverse();

    return [...video.channels].sort((a, b) => (
      prioritiesForSorting.indexOf(b.type) - prioritiesForSorting.indexOf(a.type)
    ));
  })();

  return sortedChannels[0];
};
