import React from 'react';
import Spinner from '../../../common/components/Spinner';
import * as Atlas from '../../../common/types/Atlas';

interface FormActionsProps {
  loading: boolean;
  saving: boolean;
  saved: boolean;
  error: boolean;
  form: Partial<Atlas.Form>;
  isChanged: boolean;
  onCancel: () => void;
  onEdit?: () => void;
  onPreview?: () => void;
  onSave: () => void;
}

const FormActions = (props: FormActionsProps): JSX.Element => {
  const {
    loading,
    form,
    isChanged,
    saved,
    saving,
    error,
    onEdit: handleEdit,
    onPreview: handlePreview,
    onCancel: handleCancel,
    onSave,
  } = props;

  const handleSave = onSave;

  const cancelDisabled = saving;
  const previewDisabled = saving || loading;
  const saveDisabled = saved || saving || loading || !handleSave;

  const saveButtonText = (() => {
    if (saving) { return <Spinner size="xs" />; }
    if (saved) { return __('Saved!'); }
    if (error) { return __('Failed! Try again?'); }
    return __('Save');
  })();

  return (
    <div className="tw-card tw-card-compact tw-bg-base-100">
      <div className="tw-card-body">
        <div className="tw-flex tw-gap-3 tw-justify-between">
          <div>
            <button type="button" className="tw-btn" onClick={handleCancel} disabled={cancelDisabled}>
              {isChanged ? __('Cancel') : __('Back')}
            </button>
          </div>

          <div className="tw-flex tw-gap-3">
            {form.elements?.length && handleEdit ? (
              <button type="button" className="tw-btn tw-btn-primary" onClick={handleEdit}>
                {__('Edit')}
              </button>
            ) : null}

            {form.elements?.length && handlePreview ? (
              <button type="button" className="tw-btn tw-btn-info" onClick={handlePreview} disabled={previewDisabled}>
                {__('Preview')}
              </button>
            ) : null}

            {form.elements?.length ? (
              <button type="button" className={`tw-btn ${error ? 'tw-btn-danger' : 'tw-btn-success'}`} onClick={handleSave} disabled={saveDisabled}>
                {saveButtonText}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormActions;
