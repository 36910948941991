import React from 'react';

// UI
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import AxisTick from '../AxisTick';
import { colors } from '../../../../common/utils/colors';
import * as Atlas from '../../../../common/types/Atlas';

interface Payload {
  name: number;
  value: number;
}

interface FormRatingBarChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

const FormRatingBarChart = (props: FormRatingBarChartProps): JSX.Element => {
  const { formElement, formAnswers = [] } = props;

  if (!('rating' in formElement.field.type)) {
    throw 'FormFieldType is incompatible with RatingFormFieldType';
  }

  const {
    max_label, max_ordinal,
    min_label, min_ordinal,
  } = formElement.field.type.rating;

  const min = Number(min_ordinal);
  const max = Number(max_ordinal);

  const ratings: Record<string, Payload> = {};
  new Array((max - min) + 1).fill(0).forEach((_, i) => {
    const value = i + min;
    ratings[value] ||= { name: value, value: 0 };
  });

  formAnswers.forEach((fa) => {
    const value = fa.value as number;
    ratings[value] ||= { name: value, value: 0 };
    ratings[value].value += 1;
  });

  const data = Object.values(ratings);

  return (
    <div>
      <ResponsiveContainer height={200} width="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" name={__('Rating')} domain={[min_ordinal, max_ordinal]} padding={{ left: 20, right: 20 }} tick={<AxisTick />}>
            <Label value={min_label} position="insideBottomLeft" offset={0} />
            <Label value={max_label} position="insideBottomRight" offset={0} />
          </XAxis>

          <YAxis allowDecimals={false} type="number" name={__('Count')}>
            <Label angle={-90} value="Count" position="insideLeft" offset={20} style={{ textAnchor: 'middle' }} />
          </YAxis>

          <Tooltip />

          <Bar dataKey="value" name={__('Count')} fill={colors[1]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FormRatingBarChart;
