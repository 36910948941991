import React, { useState } from 'react';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import useCreateVideoCommentMutation from '../../../common/hooks/api/videos/useCreateVideoCommentMutation';
import * as Atlas from '../../../common/types/Atlas';
import { CommentInput } from './CommentInput';
import { MediaAttachmentOption } from './attachments/media-capture-form/FormModal';
import MediaCommentModal from './attachments/MediaCommentModal';
import NewCommentResourceLink from './video-comment-options/NewCommentResourceLink';
import { Dropdown } from '../../dropdown';
import { portraitPlaceholderUrl } from '../../../common/utils/placeholders';
import RouterPrompt from '../../../common/components/RouterPrompt';

interface VideoReplyInputProps {
  videoId: Atlas.VideoID;
  parentId: Atlas.CommentID;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const VideoReplyInput = (props: VideoReplyInputProps) => {
  const { videoId, parentId, inputRef } = props;

  const currentUser = useCurrentUserQuery();

  const createCommentMutation = useCreateVideoCommentMutation();

  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState('');

  const commentUnsaved = !!comment.length;

  const createComment = async (commentArgs?: Partial<Atlas.Comment>) => {
    const response = await createCommentMutation.mutateAsync({
      params: { videoId },
      body: {
        body: '',
        ...commentArgs,
        parent_id: parentId,
      },
    });

    return response.data;
  };

  const handleSubmit = () => {
    if (submitting) { return; }
    setSubmitting(true);

    void createComment({
      type: Atlas.CommentType.Text,
      body: comment,
    }).then(() => {
      setComment('');
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const handleCaptureStart = async (commentArgs?: Partial<Atlas.Comment>) => {
    const comment = await createComment({
      synchronicity: Atlas.CommentSynchronicity.Pause,
      ...commentArgs,
      type: Atlas.CommentType.Media,
    });

    return comment.id;
  };

  const [recordOption, setRecordOption] = useState<MediaAttachmentOption | null>(null);

  const opts = [{
    icon: 'fa fa-fw fa-lg fa-video-camera',
    label: __('Video'),
    value: MediaAttachmentOption.Video,
  }, {
    icon: 'fa fa-fw fa-lg fa-microphone',
    label: __('Audio'),
    value: MediaAttachmentOption.Audio,
  }, {
    icon: 'fa fa-fw fa-lg fa-video-camera',
    label: __('Video and screen capture'),
    value: MediaAttachmentOption.VideoAndScreenCapture,
  }, {
    icon: 'fa fa-fw fa-lg fa-desktop',
    label: __('Screen capture'),
    value: MediaAttachmentOption.ScreenCapture,
  }, {
    icon: 'fa fa-fw fa-lg fa-cloud-upload',
    label: __('Video upload'),
    value: MediaAttachmentOption.Upload,
  }];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownTarget, setDropdownTarget] = useState<HTMLElement | null>(null);

  const toggleDropdown = () => {
    setDropdownOpen((o) => !o);
  };

  return (
    <div className="tw-flex tw-gap-2">
      <RouterPrompt
        when={commentUnsaved}
        message={__('You have unsaved changes on this page.')}
      />

      <div className="tw-avatar">
        <div className="tw-w-6 tw-h-6 xs:tw-w-8 xs:tw-h-8 tw-rounded-xl tw-border">
          <img
            alt={__('Profile picture')}
            src={currentUser.data?.avatar?.viewable_s3uri?.url ?? portraitPlaceholderUrl}
          />
        </div>
      </div>

      <div className="tw-flex-grow tw-min-w-0">
        <CommentInput
          inputRef={inputRef}
          commentBody={comment}
          isSubmitting={submitting}
          onChange={(val) => setComment(val)}
          onSubmit={handleSubmit}
        />

        <div className="tw-flex tw-gap-2 tw-mt-1">
          <div>
            <button ref={setDropdownTarget} type="button" className="tw-btn tw-btn-xs tw-btn-ghost tw-gap-1" onClick={toggleDropdown}>
              <i className="fa fa-video-camera" />
              {__('Media')}
            </button>

            <Dropdown placement="bottom-start" target={dropdownTarget} open={dropdownOpen} close={() => setDropdownOpen(false)}>
              <div className="tw-iris-dropdown !tw-w-full">
                <div className="tw-iris-dropdown-box !tw-mt-0 !tw-w-full">
                  <ul>
                    {opts.map((opt) => (
                      <li key={opt.value}>
                        <button
                          type="button"
                          onClick={() => {
                            setRecordOption(opt.value);
                            setDropdownOpen(false);
                          }}
                        >
                          <i className={`${opt.icon} tw-mr-2`} />
                          {opt.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Dropdown>
          </div>

          <div className="tw-flex-auto">
            <NewCommentResourceLink
              className="tw-btn tw-btn-xs tw-btn-ghost tw-gap-1"
              initialBody={comment}
              onSave={async ({ body, resource_links }) => {
                await createComment({
                  type: Atlas.CommentType.Text,
                  synchronicity: Atlas.CommentSynchronicity.Simultaneous,
                  body: body?.trim() ?? '',
                  resource_links,
                });

                setComment('');
              }}
            >
              <i className="fa fa-link" />
              {__('Resource')}
            </NewCommentResourceLink>
          </div>
        </div>
      </div>

      {recordOption !== null ? (
        <MediaCommentModal
          initialOption={recordOption}
          onStart={handleCaptureStart}
          onClose={() => { setRecordOption(null); }}
        />
      ) : null}
    </div>
  );
};

export default VideoReplyInput;
