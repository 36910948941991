/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  useCallback, useState,
} from 'react';

import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from '../../common/components/BSDropdown';
import styled from '@emotion/styled';
import { renderDurationTimestamp } from '../../common/components/timerTimestamp';

import useVideoPlayer from '../jwplayer/useVideoPlayer';
import { VideoSegment } from '../../common/types/Atlas';

const OptionsDropdownToggle = styled(DropdownToggle)``;

interface TimelineSegmentsProps {
  timeline: VideoSegment[];
  onChange?: (timeline: VideoSegment[]) => void;
}

interface TimelineSegmentsState {
  position: number
}

const TimelineSegments = (props: TimelineSegmentsProps): JSX.Element => {
  const { timeline, onChange } = props;

  const [playerState, setPlayerState] = useState<TimelineSegmentsState>({
    position: 0,
  });

  const [dropdown, setDropdown] = useState<number | null>(null);

  const onTime = useCallback((position: number) => {
    setPlayerState((prev) => ({
      ...prev,
      position: Math.round(position * 1000),
    }));
  }, []);

  const player = useVideoPlayer({
    onTime,
  });

  const handleVideoSegmentRemove = (index: number) => (event: React.MouseEvent) => {
    event.stopPropagation();

    const nextTimeline = [...timeline];
    nextTimeline.splice(index, 1);

    onChange?.(nextTimeline);
  };

  const handlePlaySegmentClick = (videoSegment: VideoSegment) => () => {
    if (!player) { return; }

    const { video_id, start } = videoSegment;
    player.play(video_id, start / 1000);
  };

  const isPlayingSegment = (videoSegment: VideoSegment) => {
    if (!player) { return null; }
    if (player.videoId !== videoSegment.video_id) { return false; }
    if (playerState.position === undefined) { return false; }

    const { position } = playerState;
    const { start, duration } = videoSegment;
    const end = (start + duration);

    return position >= start && position < end;
  };

  const removeDisabled = !onChange;

  const handleDropdownClick = (i: number) => () => {
    setDropdown((d) => (d === i ? null : i));
  };

  return (
    <div className="tw-flex tw-flex-wrap">
      {timeline.map((videoSegment, i) => (
        <div key={i} className="tw-flex tw-items-center tw-gap-1 supports-[hover:hover]:tw-opacity-20 hover:tw-opacity-100">
          <button
            type="button"
            className={`tw-btn tw-btn-xs ${player ? '' : 'tw-pointer-events-none'}`}
            onClick={handlePlaySegmentClick(videoSegment)}
          >
            <span className={`${isPlayingSegment(videoSegment) ? 'tw-text-orange-500' : 'tw-text-green-700'}`}>
              <i className="icon-play-sign" />
            </span>
            &nbsp;
            {renderDurationTimestamp({
              start: videoSegment.start,
              end: videoSegment.start + videoSegment.duration,
            })}
          </button>

          {removeDisabled ? null : (
            <Dropdown isOpen={dropdown === i} toggle={handleDropdownClick(i)}>
              <OptionsDropdownToggle className="btn btn-small btn-link bg-transparent">
                <i className="fa fa-ellipsis-v" />
              </OptionsDropdownToggle>

              <DropdownMenu right>
                <DropdownItem className="text-danger" onClick={handleVideoSegmentRemove(i)}>
                  {__('Remove')}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      ))}
    </div>
  );
};

export default TimelineSegments;
