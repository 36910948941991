import { useQuery, UseQueryResult } from 'react-query';
import { findGroupAssignment, FindGroupAssignmentResponse } from '../../../api/groups/assignments';
import * as Atlas from '../../../types/Atlas';

const useFindGroupAssignmentQuery = (
  groupId: Atlas.GroupID,
  assignmentId: Atlas.AssignmentID,
  options?: { enabled?: boolean; },
): UseQueryResult<FindGroupAssignmentResponse> => (
  useQuery(
    ['groups', groupId, 'assignments', assignmentId], 
    () => findGroupAssignment({ params: { groupId, assignmentId } }),
    { ...options },
  )
);

export default useFindGroupAssignmentQuery;
