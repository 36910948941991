import React, { useEffect, useState } from 'react';
import { ClipVideoBody } from '../../../common/api/videos/[videoId]';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import useClipVideoMutation from '../../../common/hooks/api/videos/useClipVideoMutation';
import useVideoQuery from '../../../common/hooks/api/videos/useVideoQuery';
import * as Atlas from '../../../common/types/Atlas';
import { secondsToTimestamp } from '../../../common/utils/utils';
import useVideoPlayer from '../../jwplayer/useVideoPlayer';
import VideoClipperModal from './modal';

interface VideoClipperProps {
  onDone: () => void;
  onCancel: () => void;
  videoId: Atlas.VideoID;
}

interface VideoClipperState {
  start: number | null;
  end: number | null;
  modalOpen: boolean;
}

const VideoClipper = (props: VideoClipperProps) => {
  const { onDone, onCancel, videoId } = props;

  const videoPlayer = useVideoPlayer();

  const [state, setState] = useState<VideoClipperState>({
    start: null,
    end: null,
    modalOpen: false,
  });

  const videoQuery = useVideoQuery({ videoId });
  const clipVideoMutation = useClipVideoMutation();
  const currentUser = useCurrentUserQuery();
  const playerVideoId = videoPlayer?.videoId;

  useEffect(() => {
    setState({
      start: null,
      end: null,
      modalOpen: false,
    });
  }, [playerVideoId, videoId]);

  const handleStartClick = () => {
    if (!videoPlayer) { return; }

    const currentPosition = videoPlayer.getPosition();

    setState((s) => {
      if (s.end === null) {
        return {
          ...s,
          start: currentPosition,
        };
      }

      const end = Math.min(s.end, videoPlayer.getDuration());

      return {
        ...s,
        start: currentPosition,
        end: currentPosition > end ? null : end,
      };
    });
  };

  const handleEndClick = () => {
    if (!videoPlayer) { return; }

    const currentPosition = videoPlayer.getPosition();

    setState((s) => {
      if (s.start === null) {
        return {
          ...s,
          end: currentPosition,
        };
      }

      return {
        ...s,
        end: currentPosition === 0 ? null : currentPosition,
        start: currentPosition < s.start ? null : s.start,
      };
    });
  };

  const handleCancelClick = () => {
    onCancel();
  };

  const isValid = (() => {
    if (typeof state.start !== 'number') { return false; }
    if (typeof state.end !== 'number') { return false; }

    return state.start < state.end;
  })();

  const handleDoneClick = () => {
    if (!videoPlayer) { return; }
    if (!isValid) { return; }

    videoPlayer.pause();

    setState((s) => ({
      ...s,
      modalOpen: true,
    }));
  };

  const clipVideo = async (reqBody: ClipVideoBody) => {
    const video = videoQuery.data?.data;
    if (!video) { return; }

    if (clipVideoMutation.isLoading) { return; }

    const response = await clipVideoMutation.mutateAsync({
      params: { videoId },
      body: reqBody,
    });
    const newVideo = response.data;

    if (newVideo.reflection_id && newVideo.reflection_id !== video.reflection_id) {
      window.location.href = `/reflections/${newVideo.reflection_id}`;
    }

    setState((s) => ({
      ...s, start: null, end: null, modalOpen: false,
    }));
    onDone();
  };

  const handleNewReflectionSelect = async (reflectionName: Atlas.ReflectionName) => {
    if (typeof state.start !== 'number') { return; }
    if (typeof state.end !== 'number') { return; }

    await clipVideo({
      reflectionName,
      start: state.start,
      end: state.end,
    });
  };

  const handleReflectionSelect = async (reflectionId: Atlas.ReflectionID) => {
    if (typeof state.start !== 'number') { return; }
    if (typeof state.end !== 'number') { return; }

    await clipVideo({
      reflectionId,
      start: state.start,
      end: state.end,
    });
  };

  const handleClose = () => {
    setState((s) => ({ ...s, modalOpen: false }));
  };

  return (
    <>
      <div>
        <div className="tw-flex tw-space-x-4">
          <div className="tw-join tw-flex-1">
            <button type="button" className="tw-btn tw-flex-1 tw-join-item" onClick={handleStartClick}>
              {__('Start')}
              {typeof state.start === 'number' ? (
                `: ${secondsToTimestamp(state.start, true)}`
              ) : null}
            </button>
            <button type="button" className="tw-btn tw-flex-1 tw-join-item" onClick={handleEndClick}>
              {__('End')}
              {typeof state.end === 'number' ? (
                `: ${secondsToTimestamp(state.end, true)}`
              ) : null}
            </button>
          </div>

          <div className="tw-join">
            <button
              type="button"
              className="tw-btn tw-flex-1 tw-join-item"
              onClick={handleCancelClick}
            >
              <i className="fa fa-times" />
            </button>
            <button
              type="button"
              className="tw-btn tw-flex-1 tw-join-item"
              disabled={!isValid}
              onClick={handleDoneClick}
            >
              <i className="fa fa-check" />
            </button>
          </div>
        </div>
      </div>

      {currentUser.isSuccess ? (
        <VideoClipperModal
          userId={currentUser.data.id}
          isOpen={state.modalOpen}
          onClose={handleClose}
          onNewReflectionSelect={handleNewReflectionSelect}
          onReflectionSelect={handleReflectionSelect}
        />
      ) : null}
    </>
  );
};

export default VideoClipper;
