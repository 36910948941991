/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useCreateReflectionAttachmentMutation from '../../../common/hooks/api/reflections/useCreateReflectionAttachmentMutation';
import useReflectionAttachmentsQuery from '../../../common/hooks/api/useReflectionAttachmentsQuery';
import * as Atlas from '../../../common/types/Atlas';
import useHermesUploader from '../../video-editor/video-uploader/use-hermes-uploader';
import ReflectionFile from './ReflectionFile';
import useFindReflectionQuery from '../../../common/hooks/api/reflections/useFindReflectionQuery';
import Spinner from '../../../common/components/Spinner';

interface ReflectionFilesProps {
  reflectionId: Atlas.ReflectionID;
  filesEnabled?: boolean;
}

const ReflectionFiles = (props: ReflectionFilesProps): JSX.Element => {
  const { reflectionId, filesEnabled } = props;

  const reflectionAttachmentsQuery = useReflectionAttachmentsQuery({ reflectionId });

  const [uploading, setUploading] = useState(false);
  const createAttachmentMutation = useCreateReflectionAttachmentMutation();
  const [uploaderState, uploader] = useHermesUploader();

  const onDrop = useCallback(([file]: File[]) => {
    if (!file) { return; }
    if (uploading) { return; }

    setUploading(true);

    createAttachmentMutation.mutateAsync({
      params: {
        reflectionId,
      },
      body: {
        name: file.name,
      },
    }).then(async ({ data: attachment, metadata }) => {
      const mediaKey = attachment.media_key;
      if (!mediaKey) { return; }

      const { hermes } = metadata;
      await uploader({ mediaKey, file, uploadUrl: `${hermes.upload_uri}/` });
    }).finally(() => {
      void reflectionAttachmentsQuery.refetch();
      setUploading(false);
    });
  }, [createAttachmentMutation, reflectionAttachmentsQuery, reflectionId, uploader, uploading]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: uploading,
    maxFiles: 1,
  });

  const $dropzone = (() => {
    if (!filesEnabled) {
      return (
        <div className="tw-alert tw-bg-base-100 tw-shadow tw-text-gray-600">
          {__('New file uploads are disabled for this reflection.')}
        </div>
      );
    }

    return (
      <div className="tw-p-4 tw-bg-base-100 tw-shadow tw-rounded-xl">
        <div className="tw-border-4 tw-border-base-200 tw-border-dashed tw-px-4 tw-py-6 tw-flex tw-justify-center tw-items-center tw-rounded-box" {...getRootProps()}>
          <input {...getInputProps()} />

          {(() => {
            switch (uploaderState.status) {
              case 'uploading': {
                const progress = Math.max(uploaderState.progress, 10);

                return (
                  <progress className="tw-progress tw-progress-info" value={progress} max={100} />
                );
              }

              default: {
                if (uploading) {
                  return (
                    <progress className="tw-progress tw-progress-info" value={10} max={100} />
                  );
                }

                if (isDragActive) {
                  return __('Drop the file here');
                }

                return (
                  <>
                    <button
                      type="button"
                      className="tw-link tw-link-primary tw-no-underline"
                      data-id="reflection-file-upload-button"
                    >
                      {__('Upload file')}
                    </button>
                    &nbsp;
                    <span>
                      {__('or drop a file here to upload')}
                    </span>
                  </>
                );
              }
            }
          })()}
        </div>
      </div>
    );
  })();

  return (
    <div className="tw-flex tw-flex-col tw-gap-3 tw-h-full">
      <div className="tw-p-2 xs:tw-p-4 tw-grow tw-bg-base-300 tw-border tw-border-base-400 tw-shadow-inner tw-h-0 tw-overflow-scroll tw-rounded-xl tw-flex tw-flex-col tw-gap-2">
        {(() => {
          switch (reflectionAttachmentsQuery.status) {
            case 'loading': {
              return (
                <div className="tw-p-4 tw-grid tw-place-items-center">
                  <Spinner color="info" />
                </div>
              );
            }

            case 'success': {
              const attachments = reflectionAttachmentsQuery.data.data;

              return attachments.map((attachment) => (
                <ReflectionFile
                  key={attachment.id}
                  attachment={attachment}
                />
              ));
            }

            default: return null;
          }
        })()}
      </div>
      {$dropzone}
    </div>
  );
};

export const ReflectionFilesWrapper: React.FC<{
  reflectionId: Atlas.ReflectionID;
}> = ({
  reflectionId,
}) => {
  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const filesEnabled = reflectionQuery.data?.data.attachment_mode !== Atlas.AttachmentMode.Disabled;

  return (
    <ReflectionFiles
      reflectionId={reflectionId}
      filesEnabled={filesEnabled}
    />
  );
};

export default ReflectionFiles;
