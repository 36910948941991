import React from 'react';
import { CustomRow } from '../../../common/components/Grid';
import { truncate } from '../../../common/utils/utils';
import * as Atlas from '../../../common/types/Atlas';
import { FormReportingFilter } from '../types';
import { Col } from '../../../common/components/Col';

interface RatingFormFieldStatsProps {
  field: Atlas.FormField;
  data: Array<{
    filter: FormReportingFilter;
    overview: Atlas.FormFieldOverview;
  }>;
}

const RatingFormFieldStats = (props: RatingFormFieldStatsProps): JSX.Element => {
  const { field } = props;

  const data = props.data.reduce<Array<{
    filter: FormReportingFilter,
    stats: Atlas.RatingFormFieldStats;
  }>>((acc, datum) => {
    if ('rating' in datum.overview.stats) {
      acc.push({
        filter: datum.filter,
        stats: datum.overview.stats,
      });
    }

    return acc;
  }, []);

  return (
    <div>
      <p className="tw-text-gray-600">{__('Rating')}</p>
      <p className="tw-mt-0 tw-mb-4 tw-font-bold tw-text-lg">{truncate(field.label, { maxLength: 500 })}</p>

      <hr className="tw-my-4" />

      {data.map((datum) => (
        <CustomRow key={datum.filter.id} gutter=".25rem" className="tw-mt-4">
          {data.length > 1 ? (
            <Col xs={12}>
              <h6 className="my-0">{truncate(datum.filter.name)}</h6>
            </Col>
          ) : null}

          <Col xs={4}>
            <div className="tw-bg-base-200/50 tw-rounded-lg tw-text-center tw-p-3">
              <p className="tw-mt-0 tw-mb-2 tw-text-success tw-font-bold tw-text-2xl">{datum.stats.rating.min}</p>
              <p className="tw-my-0 tw-uppercase tw-text-gray-600 tw-font-bold">{__('Min')}</p>
            </div>
          </Col>

          <Col xs={4}>
            <div className="tw-bg-base-200/50 tw-rounded-lg tw-text-center tw-p-3">
              <p className="tw-mt-0 tw-mb-2 tw-text-success tw-font-bold tw-text-2xl">{(datum.stats.rating.total / (datum.stats.rating.count || 1)).toFixed(1)}</p>
              <p className="tw-my-0 tw-uppercase  tw-text-gray-600 tw-font-bold">{__('Avg')}</p>
            </div>
          </Col>

          <Col xs={4}>
            <div className="tw-bg-base-200/50 tw-rounded-lg tw-text-center tw-p-3">
              <p className="tw-mt-0 tw-mb-2 tw-text-success tw-font-bold tw-text-2xl">{datum.stats.rating.max}</p>
              <p className="tw-my-0 tw-uppercase tw-text-gray-600 tw-font-bold">{__('Max')}</p>
            </div>
          </Col>

          <Col xs={6}>
            <div className="tw-bg-base-200/50 tw-rounded-lg tw-text-center tw-p-3">
              <p className="tw-mt-0 tw-mb-2 tw-text-success tw-font-bold tw-text-2xl">{datum.stats.rating.count}</p>
              <p className="tw-my-0 tw-uppercase tw-text-gray-600 tw-font-bold">{__('Count')}</p>
            </div>
          </Col>

          <Col xs={6}>
            <div className="tw-bg-base-200/50 tw-rounded-lg tw-text-center tw-p-3">
              <p className="tw-mt-0 tw-mb-2 tw-text-success tw-font-bold tw-text-2xl">{datum.stats.rating.total}</p>
              <p className="tw-my-0 tw-uppercase tw-text-gray-600 tw-font-bold">{__('Total')}</p>
            </div>
          </Col>
        </CustomRow>
      ))}
    </div>
  );
};

export default RatingFormFieldStats;
