/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const row = cva([
  'row',
]);

export type RowVariantProps = VariantProps<typeof row>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Row = ({
  className,
  ...props
}: DivProps & RowVariantProps) => (
  <div
    {...props}
    className={cx(className, row())}
  />
);
