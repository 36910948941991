import * as Atlas from '../../types/Atlas';
import { inRange } from '../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../api-parser';
import ApiError from '../error';
import { generateFetchHeaders, jsonHeaders, Metadata, PaginationParams, searchParams } from '../routes';

export const groupsRoute = (): string => '/api/v0/groups';

export type FindGroupsParams = PaginationParams & {
  filter?: 'learner' | 'guide' | 'walkthru';
  search?: string;
  type?: Atlas.GroupType;
};

export interface FindGroupsArguments {
  params?: FindGroupsParams;
}

export interface FindGroupsResponse {
  data: Atlas.Group[];
  metadata: Metadata;
}

export const findGroups = async (
  args?: FindGroupsArguments
): Promise<FindGroupsResponse> => {
  const { params = {} } = args || {};

  const url = [
    groupsRoute(),
    searchParams({
      page: params.page,
      per_page: params.per_page,
      filter: params.filter,
      search: params.search,
      type: params.type,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findGroups');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.Group>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface CreateGroupBody {
  name: Atlas.GroupName;
  description?: Atlas.GroupDescription;
};

export interface CreateGroupArguments {
  body: CreateGroupBody;
}

export interface CreateGroupResponse {
  data: Atlas.Group;
}

export const createGroup = async (
  args: CreateGroupArguments,
): Promise<CreateGroupResponse> => {
  const url = groupsRoute();

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      name: args.body.name,
      description: args.body.description,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'createGroup');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Group>(body.data);

  return { data };
};
