import React from 'react';
import GroupHeader from './GroupHeader';
import useFindGroupQuery from '../../../common/hooks/api/groups/useFindGroupQuery';
import { withQueryClient } from '../../../common/hooks/withQueryClient';
import * as Atlas from '../../../common/types/Atlas';

interface GroupHeaderEmbedProps {
  groupId: Atlas.GroupID;
}

const GroupHeaderEmbed = (props: GroupHeaderEmbedProps): JSX.Element | null => {
  const { groupId } = props;

  // const group = useMemo(() => (
  //   parseModelObject<Atlas.Group>(JSON.parse(groupId) as object)
  // ), [groupJson]);

  // useEffect(() => {
  //   queryClient.setQueryData<FindGroupResponse>(['groups', group.id], { data: group });
  // }, [group]);

  const groupQuery = useFindGroupQuery(groupId);

  switch (groupQuery.status) {
    case 'success': return (
      <GroupHeader group={groupQuery.data.data} />
    );
    default: return null;
  }
};

export default withQueryClient(GroupHeaderEmbed);
