import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import useFindReflectionQuery from '../hooks/api/reflections/useFindReflectionQuery';
import * as Atlas from '../types/Atlas';
import { Alert } from './BSAlert';
import { Card } from './BSCard';
import { CardBody } from './BSCardBody';
import { CardImg } from './BSCardImg';
import { CardSubtitle } from './BSCardSubtitle';
import { CardText } from './BSCardText';
import { CardTitle } from './BSCardTitle';
import { Button } from './Button';
import Spinner from './Spinner';

interface ReflectionThumbnailProps {
  reflectionId: Atlas.ReflectionID;
  loadImmediately?: boolean;
}

const ReflectionThumbnail = (props: ReflectionThumbnailProps): JSX.Element => {
  const {
    reflectionId,
    loadImmediately = false,
  } = props;

  const ref = useRef<HTMLElement>(null);
  const entry = useIntersection(ref, {});
  const visible = entry?.isIntersecting ?? false;

  const [enabled, setEnabled] = useState(loadImmediately || visible);
  useEffect(() => {
    if (visible) {
      setEnabled(true);
    }
  }, [visible]);

  const reflectionQuery = useFindReflectionQuery(reflectionId, { enabled });

  const placeholder = '/assets/img/reflections/thumbnail_placeholder.png';

  return (
    <span ref={ref}>
      {(() => {
        switch (reflectionQuery.status) {
          case 'idle':
          case 'loading': {
            return (
              <Card>
                <CardBody className="text-center py-10">
                  <Spinner color="info" />
                </CardBody>
              </Card>
            );
          }

          case 'error': {
            return (
              <Alert color="warning" className="mb-0 d-flex align-items-center">
                <i className="fa fa-exclamation-triangle fa-lg mr-3" />
                {__('You do not have permission to view this reflection')}
              </Alert>
            );
          }

          case 'success': {
            const { data: reflection } = reflectionQuery.data;
            const { user } = reflection;

            return (
              <Card className="mb-3">
                <CardImg top width="100%" style={{ backgroundColor: '#e6e6e6' }} src={reflection.thumbnail_s3uri?.url || placeholder} />
                <CardBody>
                  <CardTitle className="h5 blue-text">{reflection.name}</CardTitle>
                  <CardSubtitle className="h6 mb-2 text-muted">
                    {user ? (
                      `${user.first_name} ${user.last_name}`
                    ) : __('Unknown user')}
                  </CardSubtitle>
                  <CardText>{reflection.description}</CardText>
                </CardBody>
              </Card>
            );
          }
        }
      })()}

      {reflectionQuery.isSuccess ? (
        <Button tag="a" target="_blank" color="primary" className="mr-2 text-white" href={`/reflections/${reflectionId}`}>
          <i className="icon-share mr-2" />
          {__('Open Reflection')}
        </Button>
      ) : null}
    </span>
  );
};

export default ReflectionThumbnail;
