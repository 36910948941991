import { useQuery, UseQueryResult } from 'react-query';
import { findMilestones, FindMilestonesResponse } from '../../api/index';
import * as Atlas from '../../../../../common/types/Atlas';

const useFindMilestonesQuery = (
  groupId: Atlas.GroupID,
  options?: { staleTime?: number; enabled?: boolean; },
): UseQueryResult<FindMilestonesResponse> => (
  useQuery(
    ['groups', groupId, 'milestones'],
    () => findMilestones({ params: { groupId } }),
    {
      staleTime: 60000,
      ...options,
    },
  )
);

export default useFindMilestonesQuery;
