import React, {
  useCallback, useEffect, useLayoutEffect, useReducer, useState,
} from 'react';
import ReactDOM from 'react-dom';
import { AudioTrackChangedEvent, AudioTracksEvent, useJwPlayer } from './JwPlayer';

interface Track {
  autoselect: boolean;
  defaulttrack: boolean;
  groupid: string;
  hlsjsIndex: number;
  language: string;
  name: string;
}

interface JwPlayerAudioChannelsState {
  currentTrack?: number;
  tracks: Track[];
}

const JwPlayerAudioChannels = () => {
  const [open, toggleOpen] = useReducer((o: boolean) => !o, false);
  const [state, setState] = useState<JwPlayerAudioChannelsState>({
    tracks: [],
  });

  const onAudioTracks = useCallback<AudioTracksEvent>((param) => {
    const { currentTrack, tracks } = param as unknown as {
      currentTrack: number;
      tracks: Track[];
    };

    setState((s) => ({
      ...s,
      currentTrack,
      tracks,
    }));
  }, []);

  const onAudioTrackChanged = useCallback<AudioTrackChangedEvent>((param) => {
    const { currentTrack, tracks } = param as unknown as {
      currentTrack: number;
      tracks: Track[];
    };

    setState((s) => ({
      ...s,
      currentTrack,
      tracks,
    }));
  }, []);

  const player = useJwPlayer({
    onAudioTracks,
    onAudioTrackChanged,
  });

  useEffect(() => {
    if (!player?.player) { return undefined; }

    const playerInstance = player.player;

    playerInstance.addButton(
      '',
      __('Select audio track'),
      toggleOpen,
      'show-audio-tracks',
      'audio-tracks-icon fa-player-icon',
    );

    return () => {
      playerInstance.removeButton('show-audio-tracks');
    };
  }, [player?.player]);

  if (!player) { return null; }
  if (!player.player) { return null; }

  const container = player.player.getContainer();
  if (!container) { return null; }

  return ReactDOM.createPortal(
    <div>
      {open ? (
        <div className="tracks-container clearfix">
          <button type="button" className="track-list-close pull-right" onClick={toggleOpen}>
            <i className="fa-player-icon close-icon" />
          </button>
          <div className="track-list">
            {state.tracks.length === 0 ? (
              <div className="track-list-empty">
                {__('This video does not contain selectable tracks')}
              </div>
            ) : state.tracks.map((track, i) => {
              const handleClick = () => {
                player.player?.setCurrentAudioTrack(i);
              };

              return (
                <button
                  key={i}
                  type="button"
                  className={`track ${state.currentTrack === i ? 'active' : ''}`}
                  onClick={handleClick}
                >
                  <i className="track-icon fa-player-icon" />
                  &nbsp;
                  {track.name}
                </button>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>,
    container,
  );
};

export default JwPlayerAudioChannels;
