import { useMutation, UseMutationResult } from 'react-query';
import { destroyReflectionShare, DestroyReflectionShareArgs, DestroyReflectionShareResponse } from '../../../api/reflections/shares';
import { queryClient } from '../../withQueryClient';

const useDestroyReflectionShareMutation = (): UseMutationResult<DestroyReflectionShareResponse, unknown, DestroyReflectionShareArgs, unknown> => {
  const mutation = useMutation(destroyReflectionShare, {
    onSuccess: async (result) => {
      const share = result.data;

      if (share.resource && 'reflections' in share.resource) {
        await queryClient.invalidateQueries(['reflections', share.resource.reflections, 'shares']);
      }
    },
  });

  return mutation;
};

export default useDestroyReflectionShareMutation;
