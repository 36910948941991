import React, { useEffect } from 'react';
import initScreenCapture from '../../../../app/assets/javascripts/screencapture/screencapture';
import { ReflectionID } from '../../../common/types/Atlas';

export const ScreenCaptureButton = () => (
  <a
    href="#screen-capture-modal"
    role="button"
    data-toggle="modal"
    className="tw-btn tw-btn-success tw-btn-sm hover:tw-text-base-200 hover:tw-no-underline focus:tw-text-base-200 focus:tw-no-underline capture-screen-btn"
  >
    {__('Screen Capture')}
  </a>
);

interface ScreenCaptureProps {
  reflectionId: ReflectionID;
}

const ScreenCapture = (props: ScreenCaptureProps) => {
  const { reflectionId } = props;

  useEffect(() => {
    initScreenCapture(reflectionId);
  }, [reflectionId]);

  return null;
};

export default ScreenCapture;
