import React, { useEffect, useState } from 'react';
import VideoStreamRecorder from './VideoStreamRecorder';
import MediaUploadingModal from './media-recorder/UploadingModal';
import MediaRecordingModal from './media-recorder/RecordingModal';
import * as Atlas from '../../../../common/types/Atlas';
import RouterPrompt from '../../../../common/components/RouterPrompt';

interface MediaCaptureModalProps {
  vsr: VideoStreamRecorder;
  onStart: () => Promise<Atlas.CommentID>;
  onClose: () => void;
}

type MediaCaptureModalState = {
  stage: 'record';
} | {
  stage: 'upload';
};

const MediaCaptureModal: React.FC<MediaCaptureModalProps> = (props) => {
  const { vsr, onClose } = props;

  const [state, setState] = useState<MediaCaptureModalState>({
    stage: 'record',
  });

  const isInProgress = state.stage === 'record' || state.stage === 'upload';

  useEffect(() => {
    if (!vsr) { return; }

    void vsr.setup().then(async () => {
      await vsr.start();
    });

    return () => {
      void vsr.stop();
    };
  }, [vsr]);

  const render = () => {
    switch (state.stage) {
      case 'record': {
        const handleStop = () => {
          vsr.stop();
          setState({ stage: 'upload' });
        };

        return (
          <MediaRecordingModal
            vsr={vsr}
            onStopClick={handleStop}
          />
        );
      }

      case 'upload': {
        const handleCancelClick = () => {
          onClose();
          vsr.abort();
        };

        const handleDoneClick = () => {
          onClose();
        };

        return (
          <MediaUploadingModal
            vsr={vsr}
            onCancelClick={handleCancelClick}
            onDoneClick={handleDoneClick}
          />
        );
      }

      default: return (
        <div>
          {__('Something went wrong.')}
        </div>
      );
    }
  };

  return (
    <>
      <RouterPrompt
        when={isInProgress}
        message={__('Your upload will be cancelled if you leave this page.')}
      />

      {render()}
    </>
  );
};

export default MediaCaptureModal;
