/*global document,require,window*/

import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

document.addEventListener("DOMContentLoaded", function () {
    "use strict";

    window.webpack = {};
    window.webpack.discussionMention = require("./legacy/discussion_mention.js");
    window.webpack.React = require("react");
    window.webpack.ReactDOMClient = require("react-dom/client");

    window.webpack.app = {};
    window.webpack.app.App = require("./pages/_app").default;

    window.webpack.discussions = {};
    window.webpack.discussions.Mention = require("./modules/discussions/Mention.tsx").default;

    window.webpack.groups = {};
    window.webpack.groups.NotificationLevel = require("./modules/groups/NotificationLevelEmbed.tsx").default;
    window.webpack.groups.PageProgress = require("./modules/groups/PageProgress.tsx").default;
    window.webpack.groups.AssignmentSnippet = require("./modules/groups/AssignmentSnippet.tsx").default;
    window.webpack.groups.DiscussionSnippet = require("./modules/groups/DiscussionSnippet.tsx").default;
    window.webpack.groups.GroupSidebarEmbed = require("./modules/groups/group-sidebar/GroupSidebarEmbed.tsx").default;
    window.webpack.groups.GroupHeaderEmbed = require("./modules/groups/group-header/GroupHeaderEmbed.tsx").default;

    window.webpack.reflections = {};
    window.webpack.reflections.ui = require("./legacy/reflections/ui.js");
    window.webpack.reflections.advancedOptions = require("./legacy/reflections/advanced_options.js");
    window.webpack.reflections.AdvancedOptions = require("./modules/reflections/advanced-options/AdvancedOptions.tsx").default;
    window.webpack.reflections.NewReflectionButton = require("./modules/reflections/new-reflection-button/NewReflectionButton.jsx").default;
    window.webpack.reflections.ReflectionForms = require("./modules/reflections/ReflectionForms.jsx").default;
    window.webpack.reflections.VideoEditor = require("./modules/video-editor/index.tsx").default;
    window.webpack.reflections.ShareReflectionButton = require("./modules/reflections/share/ShareReflectionButton.tsx").default;
    window.webpack.reflections.ShareReflection = require("./modules/reflections/share/ShareReflection.tsx").default;
    window.webpack.reflections.ReflectionShow = require("./modules/reflections/show/ReflectionShow.tsx").default;
    window.webpack.reflections.ReflectionContent = require("./modules/reflections/show/ReflectionContentEmbed.tsx").default;
    window.webpack.reflections.EmbeddedPlayer = require("./src/components/embedded-reflection-player/index.tsx").default;

    window.webpack.discussions.GroupDiscussionsList = require("./modules/discussions/GroupDiscussionsList.tsx").default;

    window.webpack.tags = {};
    window.webpack.tags.ui = require("./legacy/tags1/tags-ui.js");

    window.webpack.CustomEvent = require("custom-event");

    window.webpack.forms = {};
    window.webpack.forms.AddEditForm = require("./modules/form-builder/AddEditForm.tsx").default;
    window.webpack.forms.FormPreview = require("./modules/form-builder/FormPreview.jsx").default;

    window.webpack.StreamingUploader = require("./legacy/typescript/ts-s3-multi-part-upload/src/index.ts");
    window.webpack.EBML = require("./legacy/reflections/ts-ebml.js");

    window.webpack.admin = {};
    window.webpack.admin.MetricsDashboard = require("./modules/metrics/metrics-dashboard-container.tsx").default;
    window.webpack.admin.ReportsBrowser = require("./modules/reports-browser/reports-browser.tsx").default;

    window.webpack.search = {};
    window.webpack.search.GlobalSearch = require("./modules/search/global-search/embed").default;
});
