
/* Metadata management
   General idea: Due to the fact the EBML header /must/ appear at the front,
   we have to re-upload the first chunk at the very end, because only at
   the end we will know the final media duration.
*/

import { UploadPart, Chunk } from "./types" ;

// Due to the fact the EMBL library is provided "globally" as part of Athena's asset pipeline, we use it
// directly. Same applies for the AWS SDK.
declare var EBML:any;

export const repackFirstPartWithEbmlHeader =
    async (part: UploadPart, durationSeconds: number): Promise<UploadPart> => {
    var decoder = new EBML.Decoder();
    var reader = new EBML.Reader();
    reader.logging = false;
    reader.drop_default_duration = false;

    let buffer = await readAsArrayBuffer(part.blob);

    // See: https://github.com/legokichi/ts-ebml/issues/33
    let elms = decoder.decode(buffer)
    const validEmlType = ["m", "u", "i", "f", "s", "8", "b", "d"] // This is from elm type of the lib
    elms = elms?.filter((elm: {type: string;}) => validEmlType.includes(elm.type))

    elms.forEach((elm: any) => {
      reader.read(elm)
    })

    var refinedMetadataBuf = EBML.tools.makeMetadataSeekable(
      reader.metadatas,
      durationSeconds * 1_000, // Converts to milliseconds.
      reader.cues
    );
    var body = buffer.slice(reader.metadataSize);

    var blobWithMetadata =
        new Blob([refinedMetadataBuf, body], { type: part.blob.type });
    var part =
        new UploadPart([new Chunk(blobWithMetadata)], part.partNumber);
    // We do not set the eTag from the previous, as we will need to
    // re-upload this one at the very end.
    return part;

}

// Convert a 'Blob' into an 'ArrayBuffer'.
const readAsArrayBuffer = function(blob: Blob): Promise<ArrayBuffer> {
    return new Promise(function(resolve, reject) {
        var reader = new FileReader();
        reader.readAsArrayBuffer(blob);

        reader.onloadend = function() {
          resolve(reader.result as ArrayBuffer);
        };

        reader.onerror = function(ev) {
            reject(ev);
        };
    });
};
