import React, { useState } from 'react';
import { Button } from '../../../common/components/Button';
import * as Atlas from '../../../common/types/Atlas';
import { Modal, ModalBox, ModalHeader } from '../../../common/components/TailwindModal';
import useCreateAssignmentSubmissionMutation from '../../../common/hooks/api/assignments/useCreateAssignmentSubmissionMutation';
import useFindAssignmentSubmissionsQuery from '../../../common/hooks/api/assignments/useFindAssignmentSubmissionsQuery';
import { submissionHasAnswers } from '../../../common/utils/submission-has-answers';
import useDestroyAssignmentSubmissionMutation from '../../../common/hooks/api/assignments/useDestroyAssignmentSubmissionMutation';
import Spinner from '../../../common/components/Spinner';

const getNewSubmissionPath = (assignmentId: Atlas.AssignmentID) => {
  let pathname = window.location.pathname;
  const backTo = '?back_to=' + window.location.pathname;

  // Context 1: If the path contains "submissions/:submission_id", replace it with "submissions/new"
  if (pathname.match(/\/submissions\/\d+$/)) {
    return pathname.replace(/\/submissions\/\d+$/, `/submissions/new${backTo}`);
  }

  // Context 2: If the path contains "pages/:page_id", replace it with "assignments/:assignment_id/submissions/new"
  if (pathname.match(/\/pages\/\d+$/)) {
    return pathname.replace(/\/pages\/\d+$/, `/assignments/${assignmentId}/submissions/new${backTo}`);
  }

  return null;
}

interface AssignmentResubmissionHandlerProps {
  submission: Atlas.AssignmentSubmission;
}

const AssignmentResubmissionHandler = (props: AssignmentResubmissionHandlerProps): JSX.Element => {
  const { submission } = props;

  const [resubmitting, setResubmitting] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClosed = () => {
    setIsModalOpen(false);
  }

  const newSubmissionPath = getNewSubmissionPath(submission.assignment_id);

  const draftSubmissionsQuery = useFindAssignmentSubmissionsQuery(
    submission.assignment_id,
    Atlas.SubmissionComplete.Draft,
  );
  const createSubmissionMutation = useCreateAssignmentSubmissionMutation(submission.assignment_id);
  const deleteSubmissionMutation = useDestroyAssignmentSubmissionMutation(submission.assignment_id)

  const createResubmitionDraft = () => {
    return createSubmissionMutation.mutateAsync({
      params: {
        assignmentId: submission.assignment_id,
      },
      body: {
        submission: {
          answers: props.submission.answers,
          complete: Atlas.SubmissionComplete.Draft,
          original_submission_id: submission.id,
        },
      },
    });
  }

  const deleteSubmission = (submissionId: Atlas.AssignmentSubmissionID) => {
    return deleteSubmissionMutation.mutateAsync({
      params: {
        assignmentId: submission.assignment_id,
        assignmentSubmissionId: submissionId,
      }
    });
  }

  const handleResubmitClicked = async () => {
    if (draftSubmissionsQuery.isLoading || draftSubmissionsQuery.isError) return;

    const drafts = draftSubmissionsQuery.data?.data;

    if (!drafts) return;

    if (!props.submission.id) return;


    if (drafts.length == 0) {
      setResubmitting(true);

      await createResubmitionDraft();

      if (newSubmissionPath) {
        window.location.href = newSubmissionPath;
      }
    } else if ((drafts.length == 1 && !submissionHasAnswers(drafts[0].answers))) {
      // Draft exists but answers are empty - delete the empty draft and create a new one
      setResubmitting(true);

      await Promise.all([
        deleteSubmission(drafts[0].id),
        createResubmitionDraft()
      ]);

      if (newSubmissionPath) {
        window.location.href = newSubmissionPath;
      }
    } else if (drafts.length > 0) {
      setIsModalOpen(true);
    }
  }

  const handleDiscardAndContinue = async () => {
    const drafts = draftSubmissionsQuery.data?.data;

    if (!drafts) return;

    setResubmitting(true);

    // Delete current drafts and create a resubmission draft
    await Promise.all([
      ...drafts.map((draft) => deleteSubmission(draft.id)),
      createResubmitionDraft()
    ]);

    if (newSubmissionPath) {
        window.location.href = newSubmissionPath;
    }
  }

  return (
    <>
      <Button
        size="large"
        disabled={resubmitting}
        onClick={handleResubmitClicked}
      >
        {resubmitting ? (
          <span className="tw-flex tw-items-center">
          <Spinner color="info" size="sm" className="tw-mr-2" />
          {__("Processing...")}
        </span>
        ) : (
          __('Resubmit')
        )}
      </Button>

      <Modal isOpen={isModalOpen} onClose={handleModalClosed}>
        <ModalBox>
          <ModalHeader onClose={handleModalClosed}>
            {__("Existing draft submission found")}
          </ModalHeader>
            <div className="tw-text-lg">
              <p className="tw-mb-3">
                {__("You already have a draft submission in progress for this assignment. You can:")}
              </p>
              <div className="tw-mb-6">
                <ul className="tw-list-disc tw-space-y-3 tw-pl-6">
                  <li>
                    <span className="tw-font-semibold">
                      {__("Continue editing your draft")}
                    </span>
                    <p className="tw-text-base tw-text-gray-800 tw-mt-1">
                      {__("View your current draft where you can submit or discard it.")}
                    </p>
                  </li>
                  <li>
                    <span className="tw-font-semibold">{
                      __("Discard & continue resubmission")}
                    </span>
                    <p className="tw-text-base tw-text-gray-800 tw-mt-1">
                      {__("Delete your existing draft and create a new one pre-filled with the answers from this submission.")}
                    </p>
                  </li>
                  <li>
                    <span className="tw-font-semibold">
                      {__("Cancel resubmission")}
                    </span>
                    <p className="tw-text-base tw-text-gray-800 tw-mt-1">
                      {__("Keep your current draft and stop the resubmission process.")}
                    </p>
                  </li>
                </ul>
              </div>

              <div className="tw-flex tw-justify-between">
                <a
                  className={`tw-btn tw-btn-md tw-btn-success ${resubmitting ? "tw-opacity-75 tw-cursor-not-allowed tw-pointer-events-none" : ""}`}
                  href={resubmitting ? "#" : newSubmissionPath || "#"}>
                  {__("View draft")}
                </a>
                <button
                  className={`tw-btn tw-btn-md tw-btn-warning disabled:tw-opacity-75 disabled:tw-bg-warning`}
                  disabled={resubmitting}
                  onClick={handleDiscardAndContinue}>
                  {resubmitting ? (
                    <span className="tw-flex tw-items-center">
                      <Spinner color="info" size="sm" className="tw-mr-2" />
                      {__("Processing...")}
                    </span>
                  ) : (
                     __("Discard & continue")
                  )}
                </button>
                <button
                  className="tw-btn tw-btn-md"
                  disabled={resubmitting}
                  onClick={handleModalClosed}>
                  {__("Cancel")}
                </button>
              </div>

            </div>
        </ModalBox>

      </Modal>
    </>
  )
}

export default AssignmentResubmissionHandler;
