import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { formAnswers } from '../../common/api/forms';
import { Metadata } from '../../common/api/routes';
import Pagination from '../../common/components/Pagination';
import Spinner from '../../common/components/Spinner';
import * as Atlas from '../../common/types/Atlas';
import ExpandedAnswer from './answers/ExpandedAnswer';
import { FormReportingFilter } from './types';

interface FormResponsesListProps {
  formId: Atlas.FormID;
  form?: Atlas.Form;
  question?: string;
  filter: FormReportingFilter;
}

interface FormResponsesListState {
  answers: Atlas.ExpandedAnswer[];
  answersMetadata?: Metadata;
  answersLoading: boolean;
  page: number;
  totalPages: number;
}

const FormResponsesList = (props: FormResponsesListProps): JSX.Element => {
  const {
    formId, form, question, filter,
  } = props;

  const [state, setState] = useState<FormResponsesListState>({
    answers: [],
    answersLoading: true,
    page: 1,
    totalPages: 1,
  });

  const respondentUserId = filter.respondentUserId || undefined;
  const reflectionId = filter.reflectionId || undefined;
  const reflectionUserId = filter.reflectionUserId || undefined;

  const deps = [formId, respondentUserId, reflectionId, reflectionUserId, question, state.page];

  useEffect(() => {
    setState((s) => ({ ...s, answersLoading: true }));
  }, deps);

  useDebounce(() => {
    setState((s) => ({ ...s, answersLoading: true }));
    formAnswers({
      params: {
        formId,
        respondentUserId,
        reflectionId,
        reflectionUserId,
        fieldName: question,
        page: state.page,
      },
    }).then(({ data, metadata }) => {
      setState((s) => ({
        ...s,
        answers: data,
        answersMetadata: metadata,
        answersLoading: false,
        totalPages: metadata.total_pages,
        page: Math.min(metadata.total_pages, s.page),
      }));
    });
  }, 1000, deps);

  const handlePageChange = (page: number) => {
    setState((s) => ({ ...s, page }));
  };

  return (
    <div>
      {state.answers.length ? (
        <div className={`tw-transition-opacity ${state.answersLoading ? 'tw-opacity-50' : 'tw-opacity-100'}`}>
          {state.answers.map((ea, i) => {
            const key = [ea.answer.name, ea.created_at, ea.reflection.id, ea.respondent.id, i].join('-');
            const formElement = form?.elements?.find((fe) => fe.field.name === ea.answer.name);

            return (
              <ExpandedAnswer key={key} formElement={formElement} expandedAnswer={ea} />
            );
          })}
        </div>
      ) : state.answersLoading ? (
        <div className="tw-flex tw-items-center tw-justify-center tw-py-10">
          <Spinner color="info" />
        </div>
      ) : (
        <div className="tw-flex tw-items-center tw-justify-center tw-py-10">
          {__('No responses found')}
        </div>
      )}

      {state.totalPages > 1 ? (
        <Pagination page={state.page} totalPages={state.totalPages} onPageChange={handlePageChange} />
      ) : null}
    </div>
  );
};

export default FormResponsesList;
