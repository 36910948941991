import React from 'react';
import { SearchResultButton } from './list-item';

interface SearchCompletionProps {
  completion: string;
  onClick: () => void;
}

const SearchCompletion = (props: SearchCompletionProps) => {
  const { completion, onClick } = props;

  const handleClick = () => {
    onClick();
  };

  return (
    <SearchResultButton onClick={handleClick}>
      <div className="tw-rounded-xl tw-w-10 tw-aspect-video tw-overflow-hidden tw-flex tw-items-center tw-justify-center">
        <i className="fa fa-search fa-lg" />
      </div>

      <div>
        {completion}
      </div>
    </SearchResultButton>
  );
};

export default SearchCompletion;
