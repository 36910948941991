import React, { useEffect, useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import useFindReflectionQuery from '../../../common/hooks/api/reflections/useFindReflectionQuery';
import { withQueryClient } from '../../../common/hooks/withQueryClient';
import * as Atlas from '../../../common/types/Atlas';
import ReflectionContent from './ReflectionContent';
import ReflectionPlayer from './ReflectionPlayer';
import { JwPlayerProvider } from '../../jwplayer/JwPlayer';
import ReflectionAdditionalMenu from './ReflectionAdditionalMenu';
import ScreenCapture from './ScreenCapture';
import useReflectionStore from './store/useReflectionStore';
import { ReflectionTab } from './store/reflection-tabs.store';
import ReflectionStoreProvider from './store/ReflectionStoreProvider';
import ReflectionModals from './ReflectionModals';
import EditActiveVideoButton from './EditActiveVideoButton';
import { isUserOwnedReflection } from './utils/is-user-owned-reflection';
import { Outlet } from 'react-router-dom';
import ReflectionTabs from './ReflectionTabs';
import Spinner from '../../../common/components/Spinner';

interface ReflectionLayoutProps {
  reflectionId: Atlas.ReflectionID;
}

const id = 'channel1-player';

const ReflectionLayout = (props: ReflectionLayoutProps): JSX.Element => {
  const { reflectionId } = props;

  const [focusMode, setFocusMode] = useState(false);
  const reflectionQuery = useFindReflectionQuery(reflectionId);

  const editable = reflectionQuery.isSuccess && isUserOwnedReflection(reflectionQuery.data.data);

  const { changeTab } = useReflectionStore((state) => ({
    changeTab: state.changeTab,
  }));

  const toggleFocusMode = () => setFocusMode((on) => !on);

  useKeyPressEvent('Escape', () => {
    setFocusMode(false);
  });

  const renderReflectionName = () => {
    switch (reflectionQuery.status) {
      case 'loading': return <Spinner size="sm" color="info" />;
      case 'success': {
        const reflectionName = reflectionQuery.data.data.name;

        if (!reflectionName) {
          return (
            <em>
              {__('Untitled Reflection')}
            </em>
          );
        }

        return reflectionName;
      }
      default: return null;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    switch (params.get('intent')) {
      case 'desktop-upload':
      case 'screen-capture': {
        changeTab(ReflectionTab.Videos);
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (!reflectionQuery.isSuccess) { return; }
    const reflection = reflectionQuery.data.data;

    const noReadyVideos = reflection.videos.every((video) => {
      if (!video.video_life_cycle_stage) { return false; }

      return [
        Atlas.VideoLifeCycleStage.Provisional,
        Atlas.VideoLifeCycleStage.Processing,
      ].includes(video.video_life_cycle_stage);
    });

    if (noReadyVideos) {
      changeTab(ReflectionTab.Videos);
    }
  }, [reflectionQuery.isSuccess, reflectionQuery.data?.data]);

  const focusModeClassName = 'tw-fixed tw-z-20 tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-p-2 xs:tw-p-4 tw-h-full tw-overflow-auto tw-transition-all tw-duration-700 tw-bg-accent';

  return (
    <>
      <JwPlayerProvider value={{ id }}>
        <section className={`tw-h-full ${focusMode ? focusModeClassName : ''}`}>
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-2 xs:tw-gap-4 tw-h-full">
            <div className="tw-flex tw-flex-col tw-gap-2 xs:tw-gap-4 tw-grow tw-min-w-0">
              <ReflectionPlayer reflectionId={reflectionId} />

              <div className={`tw-flex tw-flex-col ${focusMode ? '' : 'tw-gap-4'}`}>
                <div className="tw-flex tw-gap-2 tw-items-center tw-bg-base-100 tw-p-2 lg:tw-p-4 tw-font-semibold tw-rounded-xl tw-shadow">
                  <div className="tw-flex-1 lg:tw-text-xl tw-truncate">
                    <div className="tw-truncate">
                      {renderReflectionName()}
                    </div>
                  </div>

                  {editable && !focusMode ? (
                    <EditActiveVideoButton className="tw-btn tw-btn-xs xs:tw-btn-sm tw-rounded-full">
                      <i className="fa fa-scissors tw-mr-1" />
                      {__('Edit')}
                    </EditActiveVideoButton>
                  ) : null}

                  <button type="button" className={`tw-btn tw-btn-sm tw-rounded-full tw-gap-1 tw-hidden lg:tw-flex ${focusMode ? 'tw-btn-error tw-text-white' : 'tw-btn-secondary'}`} onClick={toggleFocusMode}>
                    <i className="fa fa-arrows-alt" />
                    {focusMode ? (
                      __('Exit Focus Mode')
                    ) : (
                      __('Focus Mode')
                    )}
                  </button>
                  <button type="button" className={`tw-btn tw-btn-xs xs:tw-btn-sm tw-btn-circle lg:tw-hidden tw-btn-secondary ${focusMode ? 'tw-hidden' : ''}`} onClick={toggleFocusMode}>
                    <i className="fa fa-arrows-alt" />
                  </button>
                </div>
              </div>
              <div className="tw-hidden lg:tw-block tw-flex-1">
                <ReflectionAdditionalMenu reflectionId={reflectionId} focusMode={focusMode} />
              </div>
            </div>

            <div className="tw-h-full lg:tw-w-[min(40%,_32rem)]">
              <ReflectionTabs
                reflectionId={reflectionId}
                focusMode={focusMode}
                toggleFocusMode={toggleFocusMode}
              />
            </div>
          </div>
        </section>
      </JwPlayerProvider>

      <ScreenCapture reflectionId={reflectionId} />

      <ReflectionModals reflectionId={reflectionId} />
    </>
  );
};

const ReflectionLayoutWrapper: React.FC<ReflectionLayoutProps> = (props) => (
  <ReflectionStoreProvider>
    <ReflectionLayout {...props} />
  </ReflectionStoreProvider>
);

export default withQueryClient(ReflectionLayoutWrapper);
