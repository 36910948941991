import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders, searchParams, updateFormAssociationReflectionRoute } from '../../routes';

export const reflectionRoute = (args: {
  reflectionId: Atlas.ReflectionID,
}): string => `/api/v0/reflections/${args.reflectionId}`;

export interface UpdateReflectionParams {
  reflectionId: Atlas.ReflectionID;
}

type ReflectionRequiredFields = Pick<Atlas.Reflection, 'name' | 'description' | 'room' | 'playback_mode' | 'attachment_mode' | 'comment_mode' | 'form_mode' | 'embed_mode' | 'thumbnail'>;
type ReflectionOptionalFields = Nullable<Pick<Atlas.Reflection, 'expires_at'>>;

export type UpdateReflectionBody = Partial<ReflectionRequiredFields & ReflectionOptionalFields>;

export interface UpdateReflectionArguments {
  params: UpdateReflectionParams;
  body: UpdateReflectionBody;
}

export interface UpdateReflectionResponse {
  data: Atlas.Reflection;
}

export const updateReflection = async (
  args: UpdateReflectionArguments,
): Promise<UpdateReflectionResponse> => {
  const { reflectionId } = args.params;

  const url = reflectionRoute({ reflectionId });

  const response = await fetch(url, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updateReflection');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Reflection>(body.data);

  return { data };
};

export type UpdateReflectionFormAssociationArguments = {
  params: {
    reflectionId: Atlas.ReflectionID;
    formId: Atlas.FormID;
  };
  body: {
    answer_privacy: Atlas.AnswerPrivacy;
  };
};

export type UpdateReflectionFormAssociationResponse = void;

export const updateReflectionFormAssociation = async (
  args: UpdateReflectionFormAssociationArguments,
): Promise<UpdateReflectionFormAssociationResponse> => {
  const { reflectionId, formId } = args.params;

  const updateFormAssociationReflectionUrl = [
    updateFormAssociationReflectionRoute({ reflectionId }),
    searchParams({
      form_id: formId,
    }),
  ].join('?');

  const response = await fetch(updateFormAssociationReflectionUrl, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'updateReflectionFormAssociation');
  }
};
