import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders } from '../../routes';

export const imageRoute = (args: {
  imageId: Atlas.ImageID,
}): string => `/api/v0/images/${args.imageId}`;

export interface UpdateImageParams {
  imageId: Atlas.ImageID;
}

export interface UpdateImageBody {
  offset?: Atlas.Coordinates;
}

export interface UpdateImageArguments {
  params: UpdateImageParams;
  body: UpdateImageBody;
}

export interface UpdateImageResponse {
  data: Atlas.Image;
}

export const updateImage = async (
  args: UpdateImageArguments,
): Promise<UpdateImageResponse> => {
  const { imageId } = args.params;

  const url = imageRoute({ imageId });

  const response = await fetch(url, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      offset: args.body.offset,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updateImage');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Image>(body.data);

  return { data };
};
