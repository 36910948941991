import { RouteObject } from 'react-router-dom';
import { isPresent } from '../common/utils/utils';

export type RouteObjectWithNested = RouteObject & {
  routes?: RouteObjectWithNested[];
};

export const compileRoute = (route: RouteObjectWithNested, parentPath?: string): RouteObject[] => {
  const fullPath = route.path ?
    [parentPath, route.path].filter(isPresent).join('/').replace(/\/{2,}/g, '/') : parentPath;

  const { routes, ...routeObject } = route;

  const routeObjects: RouteObject[] = [];

  if (routeObject.element) {
    routeObjects.push({
      ...routeObject,
      path: fullPath,
    });
  }

  if (route.routes?.length) {
    routeObjects.push(...compileRoutes(route.routes, fullPath));
  }

  return routeObjects;
};

export const compileRoutes = (routes: RouteObjectWithNested[], path?: string): RouteObject[] => {
  return routes.flatMap((route) => compileRoute(route, path));
};
