import { useMutation } from 'react-query';
import { queryClient } from '../../withQueryClient';
import { completeFormData } from '../../../api/form-datas/[formDataId]';

const useCompleteFormDataMutation = () => {
  const mutation = useMutation(completeFormData, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['form_data']);
    },
  });

  return mutation;
};

export default useCompleteFormDataMutation;
