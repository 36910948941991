import { useMemo } from 'react';
import { useIridium } from './useIridium';
import useVideoPlayer from './useVideoPlayer';

type AthenaData = {
  discovery_kit_video_id?: number;
};

declare const athena: {
  data?: AthenaData;
};

const useVideoPosition = () => {
  const player = useVideoPlayer();
  const iridium = useIridium();

  return useMemo(() => {
    const getVideoId = (() => {
      if (player) { return () => player.videoId; }
      if (athena?.data?.discovery_kit_video_id) {
        const videoId = athena.data.discovery_kit_video_id;
        return () => videoId;
      }

      return null;
    })();

    const getPosition = (() => {
      if (player) { return () => Math.round(player.getPosition() * 1000); }
      if (iridium) { return () => Math.round(iridium.getPosition()); }
      return null;
    })();

    return {
      getVideoId,
      getPosition,
    };
  }, [iridium, player]);
};

export default useVideoPosition;
