import React, { useCallback } from 'react';
import useHermesUploader from '../../../common/hooks/useHermesUploader';
import { Attachment } from '../../../common/types/Atlas';
import useFilePicker from '../../../common/hooks/useFilePicker';
import useCreateAttachmentMutation from '../../../common/hooks/api/attachments/useCreateAttachmentMutation';
import classNames from 'classnames';

const useAttachmentUploader = ({
  onAttachmentCreated,
  onUploadComplete,
  autoReset,
  acceptFile = '*/*',
}: {
  onAttachmentCreated?: (attachment: Attachment) => void;
  onUploadComplete?: (attachment: Attachment) => void;
  autoReset?: boolean;
  acceptFile?: string;
} = {}) => {
  const uploader = useHermesUploader();

  const createAttachment = useCreateAttachmentMutation();

  const uploadAttachment = useCallback(async (files: FileList | null) => {
    if (!files) { return; }
    const [file] = files;
    if (!file) { return; }

    const { attachment, metadata } = await createAttachment.mutateAsync({
      body: { name: file.name },
    });

    const mediaKey = attachment.media_key;
    if (!mediaKey) { throw 'Media key missing'; }

    onAttachmentCreated?.(attachment);
    await uploader.upload(mediaKey, file, `${metadata.hermes.upload_uri}/`);
    onUploadComplete?.(attachment);

    if (autoReset) {
      uploader.reset();
    }
  }, [autoReset, uploader.upload, uploader.reset, onUploadComplete]);

  const selectFile = useFilePicker({
    accept: acceptFile,
    onPicked: uploadAttachment,
  });

  const isLoading = createAttachment.isLoading || uploader.status === 'uploading';
  return [{
    isLoading,
    uploader,
  }, selectFile] as const;
};

const AttachmentUploaderButton: React.FC<{
  onUploadComplete: (attachment: Attachment) => void;
  autoReset?: boolean;
  disabled?: boolean;
  acceptFile?: string;
}> = (props) => {
  const { autoReset, disabled, onUploadComplete, acceptFile } = props;

  const [{ isLoading, uploader }, selectFile] = useAttachmentUploader({
    autoReset,
    onUploadComplete,
    acceptFile,
  });

  switch (uploader.status) {
    case 'idle': {
      return (
        <button
          type="button"
          className="tw-btn tw-btn-sm"
          disabled={disabled || isLoading}
          onClick={() => selectFile()}
        >
          {isLoading ? <span className="tw-loading" /> : null}
          {__('Upload file')}
        </button>
      );
    }

    case 'uploading': {
      return (
        <div>{__('Uploading')} ({uploader.progress}%)</div>
      );
    }
    case 'success': {
      return (
        <div>{__('Upload complete.')}</div>
      );
    }
    case 'failed': {
      return (
        <div>{__('Upload failed.')}</div>
      );
    }
  }
};

export default AttachmentUploaderButton;
