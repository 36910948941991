import * as StreamingUploader from '../../../../legacy/typescript/ts-s3-multi-part-upload/src';

export type RecordManagerStatus = 'idle' | 'recording' | 'uploading' | 'uploaded' | 'error';

export default class RecordManager {
  recorder: MediaRecorder;
  uploader: StreamingUploader.uploader.S3MultipartUploader;
  sampleRate = 5000;

  context: {
    resolve?: (value: void | PromiseLike<void>) => void,
    reject?: (value: void | PromiseLike<void>) => void,
  } = {};

  _status: RecordManagerStatus = 'idle';

  onStatusChange: (status: RecordManagerStatus) => void;

  get status() {
    return this._status;
  }

  set status(nextStatus: RecordManagerStatus) {
    this._status = nextStatus;
    this.onStatusChange(nextStatus);
  }

  constructor(recorder: MediaRecorder, uploader: StreamingUploader.uploader.S3MultipartUploader, onStatusChange: (status: RecordManagerStatus) => void) {
    this.recorder = recorder;
    this.uploader = uploader;
    this.onStatusChange = onStatusChange;

    this.recorder.onstart = () => {
      this.status = 'recording';

      uploader.onStart();
    };

    this.recorder.ondataavailable = (blobEvent) => {
      uploader.onNewChunk(blobEvent.data);
    };

    this.recorder.onstop = () => {
      this.status = 'uploading';

      this.uploader.onStop().then(() => {
        this.status = 'uploaded';

        this.context.resolve?.();
      }).catch((e) => {
        this.status = 'error';
        this.context.reject?.();

        const streamingError = new StreamingUploader.uploader.StreamingUploaderError(e);
        this.uploader.onError(streamingError);
      });
    };
  }

  promise: Promise<void> | undefined;

  async start() {
    await this.uploader.beforeStart();

    this.promise = new Promise((res, rej) => {
      this.context.resolve = res;
      this.context.reject = rej;
    });

    this.recorder.start(this.sampleRate);

    return this.promise;
  }

  stop() {
    this.recorder.stop();
  }

  abort() {
    this.recorder.stop();
    this.uploader.abortUpload();
  }

  get isFinished() {
    return [
      StreamingUploader.types.UploadStatus.Stopped,
      StreamingUploader.types.UploadStatus.Completed,
      StreamingUploader.types.UploadStatus.Errored,
    ].includes(this.uploader.uploadStatus);
  }
}
