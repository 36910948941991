import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from '../../../common/components/Modal';
import useCreateGroupDiscussionMutation from '../../../common/hooks/api/groups/useCreateGroupDiscussionMutation';
import useFindGroupQuery from '../../../common/hooks/api/groups/useFindGroupQuery';
import useUpdateGroupDiscussionsCudRoleMutation from '../../../common/hooks/api/groups/useUpdateGroupDiscussionsCudRoleMutation';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../common/types/Atlas';
import { getUsersGroupMemberRole, userHasRole } from '../../../common/utils/group-permissions';
import AdminPermissionForm from '../forms/AdminPermissionForm';

interface DiscussionListControlsProps {
  groupId: Atlas.GroupID;
  className?: string;
}

const DiscussionListControls = (props: DiscussionListControlsProps): JSX.Element | null => {
  const { groupId, className } = props;

  const currentUserQuery = useCurrentUserQuery();
  const user = currentUserQuery.data;

  const groupQuery = useFindGroupQuery(groupId);
  const group = groupQuery.data?.data;

  const createGroupDiscussion = useCreateGroupDiscussionMutation({ groupId });
  const updateGroupDiscussionsCudRole = useUpdateGroupDiscussionsCudRoleMutation({ groupId });

  const [state, setState] = useState({
    newDiscussionModalOpen: false,
    newDiscussionTitle: '',
    submitting: false,
  });

  if (!group || !user) { return null; }

  const groupInfo = group.group;

  const canManageDiscussions = (() => {
    if (!groupInfo) { return false; }

    const userMemberRole = getUsersGroupMemberRole(groupInfo, user.id, user.organization_id);
    const requiredMemberRole = groupInfo.discussions_cud_role;

    return userHasRole(userMemberRole, requiredMemberRole);
  })();

  const canManageGroup = (() => {
    if (!groupInfo) { return false; }
    const userMemberRole = getUsersGroupMemberRole(groupInfo, user.id, user.organization_id);
    if (!userMemberRole) { return false; }

    const isGroupAdmin = groupInfo.administrators.user_ids.includes(user.id);
    return isGroupAdmin;
  })();

  if (!canManageDiscussions && !canManageGroup) { return null; }


  const handleAdminPermissionChange = async (memberRole: Atlas.MemberRole) => {
    await updateGroupDiscussionsCudRole.mutateAsync({
      role: memberRole,
    });
  };

  const toggleNewDiscussionModalOpen = () => {
    setState((s) => ({ ...s, newDiscussionModalOpen: !s.newDiscussionModalOpen }));
  };

  const handleNewDiscussionSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (state.submitting) { return; }
    if (!state.newDiscussionTitle) { return; }

    setState((s) => ({ ...s, submitting: true }));

    await createGroupDiscussion.mutateAsync({
      title: state.newDiscussionTitle,
    }).then(() => {
      setState((s) => ({
        ...s,
        newDiscussionModalOpen: false,
        newDiscussionTitle: '',
        submitting: false,
      }));
    }, () => {
      setState((s) => ({ ...s, submitting: false }));
    });
  };

  const handleNewDiscussionTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (state.submitting) { return; }

    const { value } = event.currentTarget;
    setState((s) => ({ ...s, newDiscussionTitle: value }));
  };

  return (
    <div className={`d-flex align-items-center ${className}`}>
      {canManageDiscussions ? (
        <>
          <button role="button" className="btn btn-primary" onClick={toggleNewDiscussionModalOpen}>
            {__('New Discussion')}
            {' +'}
          </button>

          <Modal className="toolbarModal" open={state.newDiscussionModalOpen} onClose={toggleNewDiscussionModalOpen}>
            <ModalHeader>
              {__('Create a new discussion')}
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleNewDiscussionSubmit}>
                <input
                  type="text"
                  value={state.newDiscussionTitle}
                  onChange={handleNewDiscussionTitleChange}
                  placeholder={__('Enter your title here...')}
                />
                <button type="submit">
                  {__('Create')}
                </button>
              </form>
            </ModalBody>
          </Modal>
        </>
      ) : null}

      {canManageGroup ? (
        <div className="ml-auto px-2">
          <AdminPermissionForm
            adminPermission={group.group?.discussions_cud_role}
            onChange={handleAdminPermissionChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DiscussionListControls;
