/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import useCreateGroupImageMutation from '../../../../common/hooks/api/groups/useCreateGroupImageMutation';
import * as Atlas from '../../../../common/types/Atlas';
import useHermesUploader from '../../../video-editor/video-uploader/use-hermes-uploader';
import { btnBaseClass, btnColorClass } from '../shared';
import GroupBannerImage from './BannerImage';
import GroupDefaultBanner from './DefaultBanner';
import GroupEditableBanner from './EditableBanner';
import GroupLoadingBanner from './LoadingBanner';

interface GroupBannerProps {
  group: Atlas.Group;
  editable?: boolean;
}

const GroupBanner = (props: GroupBannerProps) => {
  const { group, editable } = props;

  const [bannerUploading, setBannerUploading] = useState(false);
  const [uploaderState, uploader] = useHermesUploader();
  const createGroupImageMutation = useCreateGroupImageMutation();

  const onBannerDrop = useCallback(([file]: File[]) => {
    if (!file) { return; }
    if (bannerUploading) { return; }
    if (uploaderState.status === 'uploading') { return; }

    setBannerUploading(true);

    createGroupImageMutation.mutateAsync({
      params: { groupId: group.id },
      body: {
        type: Atlas.ImageType.GroupBanner,
      },
    }).then(async ({ data: image, metadata }) => {
      const mediaKey = image.media_key;
      if (!mediaKey) { return; }

      const { hermes } = metadata;
      await uploader({ mediaKey, file, uploadUrl: `${hermes.upload_uri}/` });
    }).finally(() => {
      setBannerUploading(false);
    });
  }, [bannerUploading, createGroupImageMutation, group.id, uploader, uploaderState.status]);

  const bannerDropzone = useDropzone({
    onDrop: onBannerDrop,
    maxFiles: 1,
    noClick: true,
    accept: {
      'image/*': [],
    },
  });

  const $groupBanner = (() => {
    if (bannerUploading) {
      return (
        <GroupLoadingBanner />
      );
    }

    if (!group.banner) {
      return (
        <GroupDefaultBanner />
      );
    }

    if (editable) {
      return (
        <GroupEditableBanner banner={group.banner} />
      );
    }

    return (
      <GroupBannerImage banner={group.banner} />
    );
  })();

  const $groupBannerButton = (() => {
    if (!editable) { return null; }

    const bannerBtnBaseClass = 'tw-text-sm tw-absolute tw-bottom-0 tw-left-0 tw-right-0';

    if (bannerDropzone.isDragActive) {
      return (
        <button
          type="button"
          className={`${btnBaseClass} ${bannerBtnBaseClass} tw-bg-success tw-text-success-content`}
          onClick={() => bannerDropzone.open()}
        >
          {__('Drop image here to upload')}
        </button>
      );
    }

    return (
      <button
        type="button"
        className={`${btnBaseClass} ${btnColorClass} ${bannerBtnBaseClass}`}
        onClick={() => bannerDropzone.open()}
      >
        {__('Click here to upload a banner')}
      </button>
    );
  })();

  return (
    <div className={`tw-overflow-hidden tw-rounded tw-shadow tw-bg-white ${bannerDropzone.isDragActive ? 'tw-outline tw-outline-success' : ''}`} {...bannerDropzone.getRootProps()}>
      <input {...bannerDropzone.getInputProps()} />
      {$groupBanner}
      {$groupBannerButton}
    </div>
  );
};

export default GroupBanner;
