import { useQuery, UseQueryResult } from 'react-query';
import { findVideo, FindVideoParams, FindVideoResponse } from '../../../api/videos/[videoId]';

const useFindVideoQuery = (
  params: FindVideoParams,
  options?: { staleTime?: number; enabled?: boolean; refetchInterval?: number; },
): UseQueryResult<FindVideoResponse> => {
  const { videoId, ...otherParams } = params;

  return useQuery(
    ['videos', videoId, otherParams],
    () => findVideo({ params }),
    options,
  );
};

export default useFindVideoQuery;
