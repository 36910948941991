import * as Atlas from '../../../../common/types/Atlas';
import { inRange } from '../../../../common/utils/utils';
import { parseModelObject, parseModelObjectArray } from '../../../../common/api/api-parser';
import ApiError from '../../../../common/api/error';
import { generateFetchHeaders, jsonHeaders } from '../../../../common/api/routes';

export const milestonesRoute = (args: {
  groupId: Atlas.GroupID
}): string => `/api/v0/groups/${args.groupId}/milestones`;

export const milestoneRoute = (args: {
  groupId: Atlas.GroupID,
  milestoneId: Atlas.MilestoneIdentifier
}): string => `/api/v0/groups/${args.groupId}/milestones/${args.milestoneId}`;

export interface FindMilestonesParams {
  groupId: Atlas.GroupID;
}

export interface FindMilestonesArguments {
  params: FindMilestonesParams;
}

export interface FindMilestonesResponse {
  data: Atlas.Milestone[];
}

export const findMilestones = async (
  args: FindMilestonesArguments,
): Promise<FindMilestonesResponse> => {
  const { groupId } = args.params;
  const url = milestonesRoute({ groupId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findMilestones');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.Milestone>(body.milestones);

  return { data };
}

export interface FindMilestoneParams {
  groupId: Atlas.GroupID;
  milestoneId: Atlas.MilestoneIdentifier;
}

export interface FindMilestoneArguments {
  params: FindMilestoneParams;
}

export interface FindMilestoneResponse {
  data: Atlas.Milestone;
}

export const findMilestone = async (
  args: FindMilestoneArguments,
): Promise<FindMilestoneResponse> => {
  const { groupId, milestoneId } = args.params;
  const url = milestoneRoute({ groupId, milestoneId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  })

  if (!inRange(response.status, 200, 300)) {
    if (response.status === 404) {
      throw new ApiError(response.status, 'Milestone not found');
    }
    throw new ApiError(response.status, 'findMilestone');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Milestone>(body.milestone);

  return { data };
}

export interface CreateMiletoneParams {
  groupId: Atlas.GroupID;
}

type CreateMilestoneBody = Omit<Atlas.Milestone, 'id'>;

export interface CreateMilestoneArguments {
  params: CreateMiletoneParams;
  body: CreateMilestoneBody;
}

export interface CreateMilestoneResponse {
  data: Atlas.Milestone;
}

export const createMilestone = async (
  args: CreateMilestoneArguments,
): Promise<CreateMilestoneResponse> => {
  const { groupId } = args.params;
  const url = milestonesRoute({ groupId });

  const response = await fetch(url, {
    method: 'POST',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'createMilestone');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Milestone>(body.milestone);

  return { data };
}

export interface UpdateMiletoneParams {
  groupId: Atlas.GroupID;
  milestoneId: Atlas.MilestoneIdentifier;
}

type UpdateMilestoneBody = Omit<Atlas.Milestone, 'id'>;

export interface UpdateMilestoneArguments {
  params: UpdateMiletoneParams;
  body: UpdateMilestoneBody;
}

export interface UpdateMilestoneResponse {
  data: Atlas.Milestone;
}

export const updateMilestone = async (
  args: UpdateMilestoneArguments,
): Promise<UpdateMilestoneResponse> => {
  const { groupId, milestoneId } = args.params;
  const url = milestoneRoute({ groupId, milestoneId });

  const response = await fetch(url, {
    method: 'PUT',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updateMilestone');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Milestone>(body.milestone);

  return { data };
}

export interface DestroyMilestoneParams {
  groupId: Atlas.GroupID;
  milestoneId: Atlas.MilestoneIdentifier;
}

export interface DestroyMilestoneArguments {
  params: DestroyMilestoneParams;
}

export interface DestroyMilestoneResponse {
  data: Atlas.Milestone;
}

export const destroyMilestone = async (
  args: DestroyMilestoneArguments,
): Promise<DestroyMilestoneResponse> => {
  const { groupId, milestoneId } = args.params;
  const url = milestoneRoute({ groupId, milestoneId });

  const response = await fetch(url, {
    method: 'DELETE',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'destroyMilestone');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Milestone>(body.milestone);

  return { data };
}

export const reorderMilestonesRoute = (args: {
  groupId: Atlas.GroupID;
}): string => `/api/v0/groups/${args.groupId}/milestones/reorder`;

export interface ReorderMilestonesParams {
  groupId: Atlas.GroupID;
}

export interface ReorderMilestonesBody {
  milestoneIds: Atlas.MilestoneIdentifier[];
}

export interface ReorderMilestonesArguments {
  params: ReorderMilestonesParams;
  body: ReorderMilestonesBody;
}

export type ReorderMilestonesResponse = void;

export const reorderMilestones = async (
  args: ReorderMilestonesArguments,
): Promise<ReorderMilestonesResponse> => {
  const { groupId } = args.params;

  const url = reorderMilestonesRoute({ groupId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      ordered_ids: args.body.milestoneIds,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'reorderMilestones');
  }

  return undefined;
}