import React from 'react';
import { useParams } from 'react-router-dom';
import NewMilestoneContainer from '../../../../../src/features/milestones/components/NewMilestoneContainer';

const NewGroupMilestonePage = (): JSX.Element => {
  const params = useParams<{ groupId: string; }>();
  const groupId = Number(params.groupId);

  return (
    <NewMilestoneContainer groupId={groupId} />
  );
};

export default NewGroupMilestonePage;
