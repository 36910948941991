import React from 'react';
import * as Atlas from '../../../common/types/Atlas';
import SharesList from './components/SharesList';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import useFindReflectionQuery from '../../../common/hooks/api/reflections/useFindReflectionQuery';

interface ShareReflectionListProps {
  reflectionId: Atlas.ReflectionID;
  onNewShareClick: () => void;
}

const ShareReflectionList = (props: ShareReflectionListProps): JSX.Element => {
  const { reflectionId, onNewShareClick } = props;

  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const reflection = reflectionQuery.data?.data;

  const userOwned = (() => {
    if (!reflection) { return false; }
    if (reflection.group_id) { return false; }

    return reflection.relationship === Atlas.Relationship.Owner;
  })();

  const { data: user } = useCurrentUserQuery();

  const sharingDisabled = user ? user.is_basic : true;

  const handleNewShareBtnClick = () => {
    if (sharingDisabled) { return; }

    onNewShareClick();
  };

  return (
    <div>
      {userOwned ? (
        <>
          {sharingDisabled ? null : (
            <button
              type="button"
              className="tw-btn tw-btn-success tw-btn-block tw-block tw-mb-6"
              onClick={handleNewShareBtnClick}
            >
              {__('Add New Share')}
            </button>
          )}

          <SharesList reflectionId={reflectionId} />
        </>
      ) : null}
    </div>
  );
};

export default ShareReflectionList;
