/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import NewReflectionForm from './NewReflectionForm';
import { useClickAway } from 'react-use';

const NewReflectionButton = (props) => {
  const { formProps = {} } = props;
  const [state, setState] = useState({
    desktopOpen: false,
    mobileOpen: false,
    source: '',
  });

  const dropdownRef = useRef(null);

  useClickAway(dropdownRef, () => {
    setState((s) => {
      if (!s.desktopOpen) { return s; }
      return { ...s, source: '', desktopOpen: false };
    });
  });

  const handleOpenClickFromDesktop = () => {
    setState((s) => {
      if (s.desktopOpen) {
        return { ...s, source: '', desktopOpen: false };
      }
      return { source: 'desktop', desktopOpen: true };
    });
  };

  const handleInputChange = (event) => {
    const { checked } = event.currentTarget;

    if (checked) {
      setState({ source: 'mobile', mobileOpen: true });
    } else {
      setState({ source: '', mobileOpen: false });
    }
  };

  return (
    <div>
      <div ref={dropdownRef} className={`tw-hidden sm:tw-block tw-dropdown tw-w-full ${state.desktopOpen ? 'tw-dropdown-open' : ''}`}>
        <button
          type="button"
          data-testid="desktop-new-reflection-btn"
          className="new-reflection tw-btn-success tw-w-full tw-h-[75px] tw-btn tw-text-lg tw-mb-4"
          onClick={handleOpenClickFromDesktop}
        >
          {__('New Reflection')}
        </button>

        <ul className="tw-dropdown-content tw-z-50 tw-menu tw-w-full tw-p-2 tw-shadow tw-rounded-box tw-top-0 tw-left-[231px] sm:tw-w-[260px] tw-border-2 tw-border-iris-teal " style={{ backgroundColor: '#F8F8FB' }}>
          <NewReflectionForm
            {...formProps}
            source={state.source}
            // The below key will cause the new reflection form to reset upon open/close
            key={state.desktopOpen}
          />
        </ul>
      </div>

      <label
        htmlFor="new-reflections-modal"
        data-testid="mobile-new-reflection-btn"
        className="new-reflection sm:tw-hidden  tw-bg-iris-teal tw-w-full tw-h-[75px] tw-btn tw-text-white tw-text-lg tw-mb-2"
      >
        {__('New Reflection')}
      </label>
      <input type="checkbox" id="new-reflections-modal" className="sm:tw-hidden tw-modal-toggle" value={state.mobileOpen} onChange={handleInputChange} />
      <div className="tw-modal tw-modal-bottom sm:tw-modal-middle">
        <div className="tw-modal-box">
          <NewReflectionForm
            {...formProps}
            source={state.source}
            // The below key will cause the new reflection form to reset upon open/close
            key={state.mobileOpen}
          />
          <div className="tw-modal-action">
            <label htmlFor="new-reflections-modal" className="tw-btn tw-w-full">Close</label>
          </div>
        </div>
      </div>
    </div>
  );
};

NewReflectionButton.propTypes = {
  formProps: PropTypes.object,
};

export default NewReflectionButton;
