/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const cardBodyVariant = cva('card-body');

export type CardBodyVariantProps = VariantProps<typeof cardBodyVariant>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const CardBody = ({
  className,
  ...props
}: CardBodyVariantProps & DivProps) => (
  <div
    {...props}
    className={cx(className, cardBodyVariant())}
  />
);
