import React, { useEffect, useState } from 'react';
import { Modal, ModalBox } from '../../../../common/components/TailwindModal';
import useUpdatePageMutation from '../../../../common/hooks/api/pages/useUpdatePageMutation';
import * as Atlas from '../../../../common/types/Atlas';

interface EditPageJsFilterFormProps {
  page: Atlas.Page;
  onCancel: () => void;
}

const EditPageJsFilterForm = (props: EditPageJsFilterFormProps) => {
  const { page, onCancel } = props;

  const [pageJsFilter, setPageJsFilter] = useState(page.js_filter);

  useEffect(() => {
    setPageJsFilter(page.js_filter);
  }, [page.js_filter]);

  const updatePage = useUpdatePageMutation({ pageId: page.id });

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (updatePage.isLoading) { return; }

    await updatePage.mutateAsync({
      jsFilter: pageJsFilter,
    });

    onCancel();
  };

  const handlePageJsFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.JSFilter.Disabled:
      case Atlas.JSFilter.Enabled: {
        break;
      }

      default: return;
    }

    setPageJsFilter(value);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="tw-form-control tw-w-full tw-mb-4">
        <select
          required
          className="tw-select tw-select-bordered tw-w-full"
          value={pageJsFilter}
          onChange={handlePageJsFilterChange}
          data-testid="page-sanitize-select"
        >
          <option value={Atlas.JSFilter.Enabled}>
            {__('Enabled')}
          </option>
          <option value={Atlas.JSFilter.Disabled}>
            {__('Disabled')}
          </option>
        </select>
      </div>

      <div className="tw-flex tw-justify-end tw-gap-2">
        <button
          type="button"
          className="tw-btn tw-btn-link"
          onClick={onCancel}
        >
          {__('Cancel')}
        </button>

        <button
          type="submit"
          className="tw-btn tw-btn-success"
          data-testid="page-sanitize-save-button"
        >
          {updatePage.isLoading ? <span className="tw-loading" /> : null}
          {__('Save')}
        </button>
      </div>
    </form>
  );
};

interface SanitizePageButtonProps {
  page: Atlas.Page;
}

const SanitizePageButton = (props: SanitizePageButtonProps) => {
  const { page } = props;

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((o) => !o);
  };

  return (
    <>
      <button type="button" onClick={toggle} data-testid="sanitize-page-button">
        {__('Sanitization')}
      </button>

      <Modal isOpen={open} onClose={toggle}>
        <ModalBox>
          <p className="tw-text-xl tw-font-bold tw-mb-2">
            {__('Page Sanitization')}
          </p>

          <p className="tw-mb-4">
            {__('Sanitization filters out potentially unsafe code from the page.')}
          </p>

          <EditPageJsFilterForm
            page={page}
            onCancel={toggle}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

export default SanitizePageButton;
