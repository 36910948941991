import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody } from '../../common/components/BSPopover';
import classNames from 'classnames';
import FormElement from './FormElement';
import FormPrivacy from './FormPrivacy';
import FormReporters from './FormReporters';
import {
  formDataRoute,
  formDatumRoute,
  reflectionCsvRoute,
  searchParams,
  generateFetchHeaders,
} from '../../common/api/routes';
import { useCurrentUser } from '../../common/hooks/withCurrentUser';
import useRandomId from '../hooks/useRandomId';
import RatingsRadarChart from './RatingsRadarChart';
import { updateFormAnswer } from '../../common/api/form-datas/[formDataId]/form-answers/[answerIndex]';
import { Modal, ModalBox, ModalHeader } from '../../common/components/TailwindModal';
import useFindAllFormDataQuery from '../../common/hooks/api/form-datas/useFindAllFormDataQuery';
import useCompleteFormDataMutation from '../../common/hooks/api/form-datas/useCompleteFormDataMutation';
import { validateFormData } from './validate-form-data';
import { ActiveFormJobsList } from './FormJobsList';
import { isActiveFormJobForReflection } from '../../common/utils/form-job.utils';

const persistForm = (form) => {
  const promise = (() => {
    const formDataId = form.id;
    const body = JSON.stringify({ form_data: form });

    if (formDataId) {
      return fetch(formDatumRoute({ formDataId }), {
        method: 'PUT',
        credentials: 'same-origin',
        headers: generateFetchHeaders(),
        body,
      });
    }

    return fetch(formDataRoute(), {
      method: 'POST',
      credentials: 'same-origin',
      headers: generateFetchHeaders(),
      body,
    });
  })();

  return promise.then((r) => {
    if (r.status !== 200) {
      throw new Error('form-data-save-failed');
    }

    return r.json();
  });
};

const Page = {
  Questions: 0,
  RatingsRadar: 1,
};

const ReflectionAttachedForm = (props) => {
  const {
    form, reflection, reflectionId, scrollToFormElement, onCreateAiComments,
  } = props;

  const scrollToRef = useRef();
  useEffect(() => {
    scrollToRef.current?.scrollIntoView();
  }, [scrollToFormElement]);

  const { user: currentUser } = useCurrentUser();

  const [page, setPage] = useState(Page.Questions);

  const [state, setState] = useState({
    formDatumChanged: false,
    formDatum: {
      form_id: form.id,
      reflection_id: reflectionId,
      answers: [],
    },
  });

  const resetFormDatum = useCallback(() => {
    setState((s) => ({
      ...s,
      formDatumChanged: false,
      formDatum: {
        form_id: form.id,
        reflection_id: reflectionId,
        answers: [],
      },
    }));
  }, [form.id, reflectionId]);

  const formDataQuery = useFindAllFormDataQuery({
    params: {
      form_id: form.id,
      reflection_id: reflectionId,
      view: 'expanded',
      per_page: 1000,
    },
  });
  const formData = formDataQuery.data?.data ?? [];

  const completeFormData = useCompleteFormDataMutation();

  useEffect(() => {
    if (state.formDatum.form_id === form.id) { return; }

    resetFormDatum();
  }, [state.formDatum.form_id, form.id, resetFormDatum]);

  const handleBackClick = (event) => {
    event.preventDefault();
    props.onBack();
  };

  const handleDownloadFormClick = () => {
    window.location.href = reflectionCsvRoute({
      reflectionId,
      formId: form.id,
    });
  };

  const handleRemoveFormClick = () => {
    props.onRemoveClick(form);
  };

  const saveForm = (nextFormDatum) => {
    if (nextFormDatum.id === state.formDatum.id) {
      setState((s) => ({
        ...s,
        formDatum: nextFormDatum,
        saving: true,
      }));

      return persistForm(nextFormDatum).then((formDatum) => {
        formDataQuery.refetch();

        setState((s) => ({
          ...s,
          formDatum,
          saving: false,
        }));
      });
    }

    return persistForm(nextFormDatum).then(() => {
      formDataQuery.refetch();
    });
  };

  const handleFormAnswerDelete = (formAnswer) => {
    if (state.saving) { return; }
    if (!window.confirm(__('Are you sure you want to delete this response?'))) { return; }

    const { _formDatumId } = formAnswer;

    const formDatum = (_formDatumId === state.formDatum.id)
      ? state.formDatum : formData.find((fd) => fd.id === _formDatumId);

    const answers = [...formDatum.answers];
    answers.splice(formAnswer._formAnswerIndex, 1);

    const nextFormDatum = {
      ...formDatum,
      answers,
    };

    return saveForm(nextFormDatum);
  };

  const handleFormAnswerSubmit = () => {
    if (state.saving) { return; }

    return (formAnswer) => {
      const nextFormDatum = {
        ...state.formDatum,
        answers: [
          ...state.formDatum.answers,
          formAnswer,
        ],
      };

      setState((s) => ({
        ...s,
        formDatum: nextFormDatum,
        saving: true,
      }));

      return persistForm(nextFormDatum).then((formDatum) => {
        setState((s) => ({
          ...s,
          formDatum,
          saving: false,
        }));
      });
    };
  };

  const handleFormAnswerEditSubmit = async (fa, integrityHash) => {
    if (state.saving) { return; }

    setState((s) => ({
      ...s,
      saving: true,
    }));

    await updateFormAnswer({
      params: {
        formDataId: fa._formDatumId,
        answerIndex: fa._formAnswerIndex,
      },
      body: {
        ...fa,
        integrity_hash: integrityHash,
      },
    }).then((response) => {
      const formDatum = response.data;

      if (fa._formDatumId === state.formDatum.id) {
        setState((s) => ({
          ...s,
          formDatum,
          saving: false,
        }));
      } else {
        formDataQuery.refetch();
      }
    });
  };

  const handleRadarClick = () => {
    setPage((prevPage) => (prevPage === Page.RatingsRadar ? Page.Questions : Page.RatingsRadar));
  };

  const [generateAiComments, setGenerateAiComments] = useState({ open: false });

  const createAiComments = () => {
    setGenerateAiComments({
      open: true,
      status: 'loading',
    });

    void onCreateAiComments().then(() => {
      setGenerateAiComments((s) => ({
        ...s,
        status: 'success',
      }));
    }, () => {
      setGenerateAiComments((s) => ({
        ...s,
        status: 'error',
      }));
    });
  };

  const handleGenerateAiCommentsClick = (event) => {
    event.currentTarget.blur();

    createAiComments();
  };

  const users = [
    ...formData.map((fd) => fd.user).filter(Boolean),
    { ...currentUser, avatar: { viewable_s3uri: { url: currentUser?.avatar_url } } },
  ];

  const formAnswers = [
    ...formData.filter((fd) => fd.id !== state.formDatum.id).map((fd) => (
      fd.answers.map((fa, i) => ({
        ...fa,
        _formDatumId: fd.id,
        _formAnswerIndex: i,
        _user: fd.user,
        _aiModel: fd.ai_model_id,
      }))
    )),
    state.formDatum.answers.map((fa, i) => ({
      ...fa,
      _formDatumId: state.formDatum.id,
      _formAnswerIndex: i,
      _user: users.find((u) => u.id === fa.user_id),
      _aiModel: state.formDatum.ai_model_id,
    })),
  ].reduce((acc, arr) => [
    ...acc, ...arr,
  ], []).reduce((acc, answer) => {
    if (!acc[answer.name]) { acc[answer.name] = []; }
    acc[answer.name].unshift(answer);
    return acc;
  }, {});

  const getFormAnswers = (fe) => formAnswers[fe.field.name] || [];

  const randId = useRandomId();
  const AiToggleId = `${randId}-ai`;
  const instructionsToggleId = `${randId}-instructions`;
  const reportersToggleId = `${randId}-reporters`;
  const privacyToggleId = `${randId}-privacy`;

  const [popoverTargetAI, setPopoverTargetAI] = useState(null);
  const [popoverTargetInstructions, setPopoverTargetInstructions] = useState(null);
  const [popoverTargetPrivacy, setPopoverTargetPrivacy] = useState(null);
  const [popoverTargetReporters, setPopoverTargetReporters] = useState(null);

  const [openPopover, setPopoverOpen] = useState(null);

  const handleToggle = (popover) => () => {
    setPopoverOpen((open) => (open === popover ? null : popover));
  };

  const formAssociation = reflection?.form_associations.find((fra) => (
    fra.form_id === form.id
  ));

  const answerPrivacy = formAssociation?.answer_privacy;
  const canSetPrivacy = formAssociation?.user_id === currentUser?.id;
  const handlePrivacyChange = canSetPrivacy ? (async (privacy) => {
    await props.onPrivacyChange(privacy);
  }) : undefined;

  const canDownloadCsv = (() => {
    if (!currentUser) { return false; }
    if (currentUser.admin === 'super') { return true; }
    if (formAssociation?.user_id === currentUser?.id) { return true; }

    return currentUser._id === form.user_id;
  })();

  const isFormOrReflectionOwner = [formAssociation?.user_id, reflection?.user_id].includes(currentUser?.id);

  const canRemove = [formAssociation?.user_id, reflection?.user_id].includes(currentUser?.id);

  const isAiForm = !!form.ai_options;
  const showAiOptions = isFormOrReflectionOwner && isAiForm;

  const currentUserFormData = formDataQuery.isSuccess ? [...formData.filter((fd) => fd.user_id === currentUser?.id), state.formDatum] : null;
  const currentUserHasAnswered = currentUserFormData?.some((fd) => fd.answers.length >= 1) ?? false;
  const isSubmitted = formDataQuery.isSuccess ? currentUserFormData.some((fd) => !!fd.completed_at) : null;

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 xs:tw-gap-4 tw-h-full">
      <div className="tw-mb-3 tw-flex tw-items-start tw-justify-between">
        <button
          type="button"
          className="tw-btn tw-btn-sm tw-btn-white tw-shadow"
          onClick={handleBackClick}
        >
          <i className="fa fa-chevron-left tw-text-xs" />
          &nbsp;
          {__('Back')}
        </button>

        <div className="tw-flex tw-gap-1">
          <div className="tw-join tw-rounded-lg">
            {showAiOptions ? (
              <>
                <button
                  ref={setPopoverTargetAI}
                  type="button"
                  onClick={handleToggle(AiToggleId)}
                  id={AiToggleId}
                  className={classNames('tw-btn tw-btn-sm tw-btn-white tw-join-item', { 'tw-btn-active': openPopover === AiToggleId })}
                >
                  <i className="fa fa-magic" />
                </button>
                <Popover placement="bottom" isOpen={openPopover === AiToggleId} target={popoverTargetAI}>
                  <PopoverBody>
                    <button
                      type="tw-btn"
                      className="tw-btn reflection-start-insight"
                      onClick={handleGenerateAiCommentsClick}
                    >
                      {__('Get AI responses')}
                    </button>
                  </PopoverBody>
                </Popover>
              </>
            ) : null}
            {form.instructions ? (
              <>
                <button
                  ref={setPopoverTargetInstructions}
                  type="button"
                  id={instructionsToggleId}
                  className={classNames('tw-btn tw-btn-sm tw-btn-white tw-join-item', { 'tw-btn-active': openPopover === instructionsToggleId })}
                  onClick={handleToggle(instructionsToggleId)}
                >
                  <i className="fa fa-info-circle" />
                </button>
                <Popover placement="bottom" isOpen={openPopover === instructionsToggleId} target={popoverTargetInstructions}>
                  <PopoverBody>
                    {form.instructions}
                  </PopoverBody>
                </Popover>
              </>
            ) : null}

            <button
              ref={setPopoverTargetPrivacy}
              type="button"
              id={privacyToggleId}
              disabled={!formAssociation}
              className={classNames('tw-btn tw-btn-sm tw-btn-white tw-join-item', { 'tw-btn-active': openPopover === privacyToggleId })}
              onClick={handleToggle(privacyToggleId)}
            >
              <i className={`fa ${answerPrivacy === 'hidden' ? 'fa-lock' : 'fa-unlock-alt'}`} />
            </button>

            {form.reporting === 'reporting' ? (
              <>
                <button
                  ref={setPopoverTargetReporters}
                  id={reportersToggleId}
                  type="button"
                  className={classNames('tw-btn tw-btn-sm tw-btn-white tw-join-item', { 'tw-btn-active': openPopover === reportersToggleId })}
                  onClick={handleToggle(reportersToggleId)}
                >
                  <i className="fa fa-user-secret" />
                </button>
                <Popover placement="bottom" isOpen={openPopover === reportersToggleId} target={popoverTargetReporters}>
                  <PopoverBody>
                    <FormReporters formId={form.id} />
                  </PopoverBody>
                </Popover>
              </>
            ) : null}

            <button
              type="button"
              className={classNames('tw-btn tw-btn-sm tw-btn-white tw-join-item', { 'tw-btn-active': page === Page.RatingsRadar })}
              onClick={handleRadarClick}
            >
              <i className="fa fa-bullseye" />
            </button>

            {canDownloadCsv ? (
              <button
                type="button"
                className="tw-btn tw-btn-sm tw-btn-white tw-join-item"
                onClick={handleDownloadFormClick}
              >
                <i className="fa fa-download" />
              </button>
            ) : null}

            {canRemove ? (
              <button
                type="button"
                className="tw-btn tw-btn-sm tw-btn-white tw-text-error tw-join-item"
                onClick={handleRemoveFormClick}
              >
                <i className="fa fa-times" />
              </button>
            ) : null}
          </div>

          {answerPrivacy ? (
            <Popover placement="bottom" isOpen={openPopover === privacyToggleId} target={popoverTargetPrivacy}>
              <PopoverBody>
                <FormPrivacy
                  privacy={answerPrivacy}
                  onCancel={handleToggle(privacyToggleId)}
                  onChange={handlePrivacyChange}
                />
              </PopoverBody>
            </Popover>
          ) : null}

          {showAiOptions ? (
            <Modal isOpen={generateAiComments.open} className="tw-modal-bottom md:tw-modal-middle">
              <ModalBox className="tw-max-w-screen-md">
                <ModalHeader onClose={() => setGenerateAiComments({ open: false })} />

                {(() => {
                  switch (generateAiComments.status) {
                    case 'loading': return (
                      <div className="tw-text-center tw-py-4">
                        <i className="fa fa-circle-o-notch fa-spin tw-text-info tw-text-3xl" />
                      </div>
                    );

                    case 'success': return (
                      <div className="tw-text-center tw-py-4">
                        <div className="tw-text-2xl">
                          {__('AI responses are being generated.')}
                        </div>

                        <div className="tw-text-gray-600 tw-mt-8">
                          {__('You may now close this window.')}
                        </div>
                      </div>
                    );

                    case 'error': return (
                      <div className="tw-text-center tw-py-4">
                        <div className="tw-text-2xl">
                          {__('Oops! Something went wrong.')}
                        </div>

                        <button className="tw-btn tw-btn-wide tw-mt-8" onClick={() => createAiComments()}>
                          {__('Retry')}
                        </button>
                      </div>
                    );
                  }
                })()}
              </ModalBox>
            </Modal>
          ) : null}
        </div>
      </div>

      <div className="tw-text-center tw-text-lg tw-font-bold">
        {form.name}
      </div>

      <div>
        {form.form_jobs.some((job) => (
          isActiveFormJobForReflection(job, reflectionId)
        )) ? (
          <ActiveFormJobsList formId={form.id} reflectionId={reflectionId} />
        ) : null}
      </div>

      <div className="tw-grow tw-overflow-auto tw-h-0 tw-rounded-xl tw-bg-base-300 tw-border tw-border-base-400 tw-shadow-inner tw-min-h-[130px]">
        {(() => {
          switch (page) {
            case Page.Questions: {
              const locksOnComplete = form.notify_complete?.lock_on_complete;

              const handleSubmitFormDataClick = () => {
                if (!window.confirm(__('Are you sure you want to submit your answers? This cannot be undone.'))) { return; }

                if (!currentUserFormData) { return; }
                const formDataId = currentUserFormData[0]?.id;
                if (!formDataId) { return; }

                const isValid = validateFormData(form, currentUserFormData);
                if (!isValid) {
                    alert(__('You have not responded to all required questions.'));
                    return;
                }

                completeFormData.mutate({ params: { formDataId } });
              };

              return (
                <>
                  <div className="tw-p-2 xs:tw-p-4">
                    {form.elements.map((fe) => {
                      if (fe.field.visible === false) {
                        return null;
                      }
                      const editingFormAnswer = (() => {
                        if (!state.editingFormAnswer) { return; }
                        if (state.editingFormAnswer.formElementName !== fe.field.name) { return; }

                        return state.editingFormAnswer;
                      })();

                      const isScrollTarget = scrollToFormElement === fe.field.name;

                      return (
                        <FormElement
                          key={fe.field.name}
                          ref={isScrollTarget ? scrollToRef : undefined}
                          defaultExpanded={isScrollTarget}
                          isCompleted={locksOnComplete && isSubmitted}
                          loading={formDataQuery.isLoading}
                          formElement={fe}
                          formAnswers={getFormAnswers(fe)}
                          editFormAnswer={editingFormAnswer?.formAnswer}
                          onSubmit={handleFormAnswerSubmit(fe)}
                          onEditSubmit={handleFormAnswerEditSubmit}
                          onFormAnswerDelete={handleFormAnswerDelete}
                        />
                      );
                    })}
                  </div>

                  {currentUserHasAnswered && locksOnComplete && isSubmitted === false ? (
                    <div className="tw-sticky tw-bottom-0 tw-left-0 tw-w-full">
                      <div className="tw-bg-base-100 tw-border-t tw-shadow tw-p-4">
                        <button type="button" className="tw-btn tw-btn-primary tw-w-full" onClick={handleSubmitFormDataClick}>
                          {completeFormData.isLoading ? <span className="tw-loading" /> : null}
                          {__('Submit Answers')}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            }

            case Page.RatingsRadar: {
              return (
                <div className="tw-iris-element-box tw-shadow">
                  <RatingsRadarChart
                    currentUser={currentUser}
                    form={form}
                    formData={[
                      ...formData,
                      state.formDatum,
                    ]}
                  />
                </div>
              );
            }

            default: return null;
          }
        })()}

        {form.copyright ? (
          <div className="tw-text-xs text-muted tw-my-4 tw-text-right">
            <i className="fa fa-copyright" />
            &nbsp;
            {form.copyright}
          </div>
        ) : null}
      </div>
    </div>
  );
};

ReflectionAttachedForm.propTypes = {
  form: PropTypes.object.isRequired,
  reflection: PropTypes.object,
  reflectionId: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onPrivacyChange: PropTypes.func.isRequired,
  onCreateAiComments: PropTypes.func.isRequired,
  scrollToFormElement: PropTypes.string,
};

export default ReflectionAttachedForm;
