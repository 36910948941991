import React, { useState, useEffect, useLayoutEffect, useCallback, useRef } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../../../common/components/Modal';

import classNames from 'classnames';

import { clamp, pageNumbers, debounce, truncate } from '../../../../common/utils/utils';
import * as Atlas from '../../../../common/types/Atlas';
import { findCondensedReflections } from '../../../../common/api/reflections/_legacy';
import { AspectRatio } from '../../../../common/components/AspectRatio';
import Spinner from '../../../../common/components/Spinner';
import { CardDeck } from '../../../../common/components/BSCardDeck';
import { Card } from '../../../../common/components/BSCard';
import { CardImg } from '../../../../common/components/BSCardImg';
import { CardBody } from '../../../../common/components/BSCardBody';
import { CardTitle } from '../../../../common/components/BSCardTitle';
import { CardSubtitle } from '../../../../common/components/BSCardSubtitle';
import { CardText } from '../../../../common/components/BSCardText';

interface ReflectionPickerProps {
  open?: boolean;
  filter?: 'mine';
  onReflectionSelect(r: Atlas.CondensedReflection): void;
  onClose(): void;
}

interface ReflectionPickerState {
  reflections: Atlas.CondensedReflection[];
  reflectionsLoading?: boolean;
  changingPage: boolean;
  totalPages: null | number;
  perPage: number;
  page: number;
  loadedPage?: number;
}

const ReflectionPicker = (props: ReflectionPickerProps): JSX.Element => {
  const [state, setState] = useState<ReflectionPickerState>({
    reflections: [],
    changingPage: true,
    totalPages: null,
    perPage: 12,
    page: 1
  });

  const loadReflections = useCallback(debounce((page: number, perPage: number, filter?: 'mine') => {
    setState(s => ({ ...s, reflectionsLoading: true }));

    findCondensedReflections({
      params: { page, per_page: perPage, filter }
    }).then(({ reflections, metadata }) => {
      setState(s => ({ ...s,
        reflections,
        loadedPage: Number(metadata.current_page),
        page: Number(metadata.current_page),
        totalPages: Number(metadata.total_pages),
        perPage: Number(metadata.per_page),
        changingPage: false,
        reflectionsLoading: false }));
    }, () => {
      setState(s => ({ ...s, error: true, loading: false }));
    });
  }, 500), []);

  useEffect(() => {
    if (!props.open) { return; }
    loadReflections(state.page, state.perPage, props.filter);
  }, [props.open, state.page, state.perPage, props.filter]);

  const handleSelect = (reflection: Atlas.CondensedReflection) => () => {
    props.onReflectionSelect(reflection);
  };

  const handlePageChange = (n: number) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setState(s => {
      if (s.reflectionsLoading) { return s; }
      return {
        ...s,
        changingPage: true,
        page: clamp(n, 1, s.totalPages ?? 0)
      };
    });
  }

  const bodyRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (!bodyRef.current) { return; }

    bodyRef.current.scrollTop = 0;
  }, [state.loadedPage, state.perPage]);

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ModalHeader className="d-flex align-items-center justify-content-between">
        <h4 className="green-text">
          {__('Select Reflection')}
        </h4>

        <button className="btn bg-transparent text-muted" title="Close" onClick={handleCloseClick}>
          <i className="icon-remove fa-lg" />
        </button>
      </ModalHeader>

      <ModalBody ref={bodyRef}>
        {state.changingPage ? (
          <div className="d-flex align-items-center justify-content-center h-100">
            <Spinner color="info" />
          </div>
        ) : (
          <CardDeck>
            {state.reflections.map(reflection => {
              const userName = [reflection.user?.first_name, reflection.user?.last_name].filter(Boolean).join(' ');

              return (
                <Card key={reflection.id} className="mb-3" style={{ flexBasis: '30%', cursor: 'pointer' }} onClick={handleSelect(reflection)}>
                  <AspectRatio ratio="16/9">
                    <CardImg top width="100%" style={{ backgroundColor: '#dedede' }} src={reflection.thumbnail_s3uri?.url || '/assets/img/reflections/thumbnail_placeholder.png'} />
                  </AspectRatio>
                  <CardBody>
                    {reflection.name ? (
                      <CardTitle className="h5 blue-text">
                        {truncate(reflection.name)}
                      </CardTitle>
                    ) : null}
                    {userName ? (
                      <CardSubtitle className="h6 mb-2 text-muted">
                        {truncate(userName)}
                      </CardSubtitle>
                    ) : null}
                    {reflection.description ? (
                      <CardText className="small">
                        {truncate(reflection.description)}
                      </CardText>
                    ) : null}
                  </CardBody>
                </Card>
              );
            })}
          </CardDeck>
        )}
      </ModalBody>

      {Number.isInteger(state.totalPages) ? (
        <ModalFooter>
          <div className="pagination pagination-centered my-0">
            <ul className="pagination mt-0">
              <li className={classNames('prev', { disabled: state.page === 1 })}>
                <a href="#" onClick={handlePageChange(state.page - 1)}>
                  {__('Previous')}
                </a>
              </li>

              {pageNumbers({ page: state.page, totalPages: state.totalPages ?? 1 }).map(n => (
                <li key={n} className={classNames({ active: n === state.page })}>
                  <a href="#" onClick={handlePageChange(n)}>
                    {n}
                  </a>
                </li>
              ))}

              <li className={classNames('next', { disabled: state.page === state.totalPages })}>
                <a href="#" onClick={handlePageChange(state.page + 1)}>
                  {__('Next')}
                </a>
              </li>
            </ul>
          </div>
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

export default ReflectionPicker;
