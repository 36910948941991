import { useQuery, UseQueryResult } from 'react-query';
import { findPage, FindPageResponse } from '../../../api/pages/[pageId]';
import * as Atlas from '../../../types/Atlas';

const useFindPageQuery = (
  pageId: Atlas.PageID,
  options?: { enabled?: boolean; }
): UseQueryResult<FindPageResponse> => (
  useQuery(
    ['pages', pageId], 
    () => findPage({ params: { pageId } }),
    { ...options }
  )
);

export default useFindPageQuery;
