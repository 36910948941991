import * as _ from 'lodash-es';
import * as Retrier from '@jsier/retrier';
import {
  RecordingType,
  VideoChannel,
  ScreenCaptureMediaSource,
  isSystemAudioBeingRecorded,
  isDesktopBeingRecorded,
  isWebcamBeingRecorded,
} from './types';
import { ScreenCapture_util } from './util';

export default function screencapture(reflectionId) {
  var actions = {},
    mediaConstraints = {
      low: {
        videoConstraints: {
            width:  853,
            height: 480,
            aspectRatio: 1.7777777778
        }
      },
      standard: {
        videoConstraints: {
            width:  1280,
            height: 720,
            aspectRatio: 1.7777777778
        }
      },
      recording: {}
    },
    elements = {
      startBtn: $(".screen-cap-btn"),
      stopBtn: $(".screen-cap-stop-btn"),
      checkWebcamAgainBtn: $(".check-webcam-again-btn"),
      uploadBtn: $(".screen-cap-upload-btn"),
      form: $("#video-editor-xhr-input"),
      modal: $("#screen-capture-modal"),
      recordingAbortedModal: $("#screen-capture-recording-aborted-modal"),
      recordingAbortedBtn: $(".screen-cap-recording-aborted-btn"),
      uploadReporter: $("#screen-capture-modal .progress-reporter"),
      videoLeftPreview: $("#screen-capture-modal video.left-preview"),
      videoRightPreview: $("#screen-capture-modal video.right-preview"),
      videoPlaceholder: $("#screen-capture-modal .placeholder"),
      uploadAdvice: $("#screen-capture-modal .upload-advice"),
      consolidatedParts: $(".screen-cap-progress-consolidated"),
      inFlightParts: $(".screen-cap-progress-in-flight"),
      settings: $("#screen-capture-modal .screen-cap-settings"),
      recordingAdvice: $("#screen-capture-modal .recording-advice"),
      audioOptions: $("#audio-options"),
      micSource: $("#mic-source"),
      webcamSource: $("#webcam-source"),
      videoOptions: $("#video-options"),
      videoQuality: $("#video-quality"),
      dismissModal: $(".close-screen-cap-modal"),
      toggleAdvanced: $(".advanced-screen-cap"),
      shareAudioAdvice: $(".shared-audio-advice"),
      selectors: [$("#webcam-source"), $("#mic-source")],
      devicePermissionWarning: $(
        "#screen-capture-modal .device-permission-warning"
      ),
      noDevicesWarning: $(
        "#screen-capture-modal .no-devices-warning"
      ),
      videoEditor: $("#video-editor"),
      uploadProgressModal: $("#screen-capture-upload-progress-modal"),
      finalisingLeftDiv: $("#screen-capture-upload-progress-modal .finalising-left"),
      finalisingRightDiv: $("#screen-capture-upload-progress-modal .finalising-right"),
      finalisingLeftSpan: $("#screen-capture-finalising-left"),
      finalisingRightSpan: $("#screen-capture-finalising-right"),
      retryUploadDiv: $("#screen-capture-retry-upload-div"),
      retryUploadBtn: $("#screen-capture-retry-upload"),
    },
    state = {
      reflectionId,
      selectedMediaConstraints: mediaConstraints.standard,
      recordingType: RecordingType.Desktop,
      leftRecordManager: null,
      rightRecordManager: null,
      isRecording: false,
      availableWebcams: [],
      availableMicrophones: [],
      retryUploadBtnShowed: false,
      uploadStats: {
        left: {
          uploaded: 0,
          total: 0,
          inFlight: 0,
          finalising: false
        },
        right: {
          uploaded: 0,
          total: 0,
          inFlight: 0,
          finalising: false
        }
      }
    },
    streams = {
      desktop: null,
      user: null,
    },
    util = ScreenCapture_util(state,actions,elements,streams);

  // HANDLERS

  // Switches audio source from mic -> mic + system
  actions.bindAudioSource = function() {
    elements.audioOptions.change(function() {
      if (this.value === "mic_and_system") {
        switch (state.recordingType) {
          case RecordingType.Desktop:
            state.recordingType = RecordingType.DesktopWithSystemAudio;
            break;
          case RecordingType.DesktopAndWebcam:
            state.recordingType = RecordingType.DesktopAndWebcamWithSystemAudio;
            break;
          default:
            break;
        }
      }
      actions.recordingWarningVisibility();
      actions.shareAudioAdviceVisibility();
    });

    elements.audioOptions.trigger('change');
  };

  // Cleans things up when the modal is closed
  actions.bindModalHidden = function() {
    elements.modal.on("hidden", function() {
      actions.cleanUp();
      actions.readyState();
    });
  };

  // Cleans things up when the modal is shown
  actions.bindModalShow = function() {
    elements.modal.on("shown", function() {
      actions.getDevices();
      actions.cleanUp();
      actions.resetProgress();
      actions.readyState();
    });
  };

  // Starts the actual recording
  actions.bindStart = function() {
    elements.startBtn.click(function(evt) {
      evt.preventDefault();
      var pleaseWait = __("Starting, please wait");
      $(this).find("span").text(pleaseWait);
      $(this).find("i").addClass('fa fa-refresh fa-spin tw-ml-2');
      $(this).prop("disabled", true);
      try {
        state.isRecording = true;
        actions.startStreams();
      } catch (err) {
        actions.cleanUp(); // make sure we don't leak
        actions.resetProgress();
        throw err;
      }
    });
  };

  // Stops the recording and resets the UI
  actions.bindStop = function(mediaRecorder, _videoChannel) {
    elements.stopBtn.click(function(evt) {
      state.isRecording = false;
      evt.preventDefault();
      mediaRecorder.stop();
      actions.readyState();
    });
  };

  // Switches recording quality
  actions.bindQualitySelect = function() {
    elements.videoQuality.change(function() {
      state.selectedMediaConstraints = mediaConstraints[this.value];
    });

    elements.videoQuality.trigger('change');
  };

  // Remove stop handler (necessary due to component lifecycle)
  actions.unBindStop = function() {
    elements.stopBtn.off("click");
  };

  actions.unBindCheckWebcamAgain = function () {
    elements.checkWebcamAgainBtn.off("click");
  };

  // ACTIONS

  actions.abortAllRecordings = function () {

    if(state.leftRecordManager)   { state.leftRecordManager.stop(); }
    if(state.righttRecordManager) { state.rightRecordManager.stop(); }

  }

  actions.resetEverything = function () {
    actions.closeModal();
    actions.abortAllRecordings();
    actions.cleanUp();
    actions.resetProgress();
    actions.resetRetryUploadBtn();
    actions.readyState();
    actions.disableWebcamSharing(true);
    elements.checkWebcamAgainBtn.fadeOut();
  };

  actions.bindModalDismiss = function() {
    elements.dismissModal.click(function() {
      if (confirm(__("Are you sure you want to cancel?"))) {
        actions.resetEverything();
      }
    });
  };

  // Frees resources to avoid leaks
  actions.cleanUp = function() {
    elements.videoLeftPreview[0].srcObject = null;
    elements.videoRightPreview[0].srcObject = null;
    var startMsg = __("Start Recording");
    elements.startBtn.find("span").text(startMsg);
    elements.startBtn.find("i").removeClass("fa fa-refresh fa-spin tw-ml-2");
    elements.startBtn.prop("disabled", false);
    state.isRecording = false;
  };

  // Completely resets the upload modal and its progress bars.
  actions.resetProgress = function () {
    elements.consolidatedParts.css("width", "0%");
    elements.inFlightParts.css("width", "0%");
    state.uploadStats.left.uploaded = 0;
    state.uploadStats.left.total = 0;
    state.uploadStats.left.inFlight = 0;
    state.uploadStats.left.finalising = false;
    state.uploadStats.right.uploaded = 0;
    state.uploadStats.right.total = 0;
    state.uploadStats.right.inFlight = 0;
    state.uploadStats.right.finalising = false;
    $(".screen-cap-upload-total-progress").text("0%");
    $("#screen-capture-upload-progress-modal .remaining-in-flight").text("");
    $("#screen-capture-upload-progress-modal .remaining-waiting").text("");
    elements.finalisingLeftSpan.text("");
    elements.finalisingLeftDiv.addClass("hide");
    elements.finalisingRightSpan.text("");
    elements.finalisingRightDiv.addClass("hide");

    // Reset the icons
    elements.finalisingLeftDiv.find("i").addClass("fa-refresh fa-spin");
    elements.finalisingLeftDiv.find("i").removeClass("fa-check");
    elements.finalisingRightDiv.find("i").addClass("fa-refresh fa-spin");
    elements.finalisingRightDiv.find("i").removeClass("fa-check");

  };

  // Closes modal programmatically
  actions.closeModal = function() {
    elements.modal.modal("hide");
  };

  // Shows a stream in the UI
  actions.displayPreview = function(stream, videoChannel) {
    switch (videoChannel) {
        case VideoChannel.Left:
          elements.videoLeftPreview[0].srcObject = stream;
          break;
        case VideoChannel.Right:
          elements.videoRightPreview[0].srcObject = stream;
          break;
    }
  };

  // aquire the desktop and user stream
  actions.aquireDesktopAndUserStream = function(callback) {
    navigator.mediaDevices
      .getDisplayMedia({
        video: state.selectedMediaConstraints.videoConstraints,
        audio: isSystemAudioBeingRecorded(state.recordingType)
      })
      .then(function(desktopStream) {
        navigator.mediaDevices
          .getUserMedia({
            video: isWebcamBeingRecorded(state.recordingType)
              ? util.resolveDeviceConstraint(elements.webcamSource)
              : false,
            audio: util.resolveDeviceConstraint(elements.micSource)
          }).then(function(userStream) {
            streams.desktop = desktopStream;
            streams.user = userStream;
            callback();
          }).catch(e => {
            switch(e.name) {
              case "NotAllowedError":
                actions.resetEverything();
                elements.recordingAbortedModal.modal("show");
                // Stop the MediaStream.
                if (desktopStream) {
                  desktopStream.getTracks().forEach(function(track) {
                    if (track != undefined && track != null) track.stop();
                  });
                }
                if (streams.user !== undefined && streams.user) {
                  userStream.getTracks().forEach(function(track) {
                    if (track != undefined && track != null) track.stop();
                  });
                }
                break;
              default:
                throw e;
            }
          });
      });
  };

  // aquire just the user stream
  actions.aquireUserStream = function(callback) {
    navigator.mediaDevices
      .getUserMedia({
        video: util.resolveDeviceConstraint(elements.webcamSource),
        audio: util.resolveDeviceConstraint(elements.micSource)
      })
      .then(function(userStream) {
        streams.user = userStream;
        callback();
      });
  };

  actions.startStreams = function() {
    if (isDesktopBeingRecorded(state.recordingType)) {
      actions.aquireDesktopAndUserStream(function() {
        actions.record();
      });
    } else {
      actions.aquireUserStream(function() {
        actions.record();
      });
    }
  };

  // Kicks off a recording
  actions.record = function() {
    return util.record();
  };

  // STATE MACHINE

  // Shows and hides the given elements
  actions.transition = function(opts) {
    opts.hide.forEach(function(elem) {
      elem.hide();
    });
    opts.show.forEach(function(elem) {
      elem.show();
    });
  };

  // Transition to the ready state
  actions.readyState = function() {
    actions.transition({
      hide: [
        elements.stopBtn,
        elements.uploadBtn,
        elements.uploadReporter,
        elements.videoLeftPreview,
        elements.videoRightPreview,
        elements.uploadAdvice,
        elements.recordingAdvice,
        elements.uploadProgressModal
      ],
      show: [
        elements.startBtn,
        elements.videoPlaceholder,
        elements.settings,
        elements.dismissModal
      ]
    });
  };

  // Transition to the recording state
  actions.recordingState = function() {
    actions.transition({
      hide: [
        elements.startBtn,
        elements.uploadBtn,
        elements.videoPlaceholder,
        elements.settings,
        elements.dismissModal
      ],
      show: _.union([elements.stopBtn, elements.recordingAdvice], actions.showPreviews())
    });
  };

  actions.showPreviews = function() {
      if (util.isDualView()) {
          return [elements.videoLeftPreview, elements.videoRightPreview];
      }
      return [elements.videoLeftPreview];
  };

  // Transition to the upload state
  actions.uploadState = function() {
    actions.transition({
      hide: [
        elements.startBtn,
        elements.stopBtn,
        elements.videoLeftPreview,
        elements.videoRightPreview,
        elements.videoPlaceholder,
        elements.recordingAdvice,
        elements.settings,
        elements.dismissModal,
        elements.modal
      ],
      show: [
        elements.uploadBtn,
        elements.uploadAdvice,
        elements.uploadProgressModal
      ]
    });
  };

  /* Warn the user when leaving the page, if a recording or an upload is in progress.
     The 'returnValue' doesn't really matter, as modern browsers won't allow displaying
     a custom message anyway (see here: https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup)
     Furthermore, we try to at least abort the S3 upload (if any), but we can't really do anything for the
     video part (if created), as it's not guaranteed that *any* action will be run inside this callback
     (see: https://stackoverflow.com/questions/20889640/do-something-after-the-user-clicks-leave-this-page-right-before-the-browser-is).
     Luckily for us, we have a plan B: the user can always delete the pending video part and we have a lifecycle
     action in the S3 buckets which will automatically delete pending S3 uploads after 24 hours.
  */
  actions.beforeNavigation = function() {
    window.addEventListener("beforeunload", function(event) {
      if (elements.modal.is(":visible") || elements.uploadProgressModal.is(":visible")) {
        event.returnValue = __("Have you completed your screen capture?");
      } else {
        actions.abortAllRecordings();
        return;
      }
    });
  };

  // dynamically update the settings visible on the dom and the media states
  actions.bindRecordingTypeChange = function() {
    elements.videoOptions.change(function() {
      var type = $(this).val();

      switch (type) {
        case "desktop":
          state.recordingType = RecordingType.Desktop;
          actions.webcamSourceVisibility(false);
          actions.audioOptionsVisibility(true);
          actions.recordingWarningVisibility();
          break;
        case "webcam":
          state.recordingType = RecordingType.Webcam;
          actions.webcamSourceVisibility(true);
          actions.audioOptionsVisibility(false);
          actions.recordingWarningVisibility();
          break;
        case "desktop_and_webcam":
          state.recordingType = RecordingType.DesktopAndWebcam;
          actions.webcamSourceVisibility(true);
          actions.audioOptionsVisibility(true);
          actions.recordingWarningVisibility();
          break;
      }
    });

    elements.videoOptions.trigger('change');
  };

  // toggle mixed recording / compatability
  actions.recordingWarningVisibility = function() {
    var mixedAudio =
        elements.audioOptions.val() == "mic_and_system" &&
        elements.audioOptions.is(":visible");

    if (mixedAudio) {
      elements.videoPlaceholder.find($(".recording-warnings")).fadeIn();
      elements.videoPlaceholder.find($(".compatability")).hide();
    } else {
      elements.videoPlaceholder.find($(".recording-warnings")).hide();
      elements.videoPlaceholder.find($(".compatability")).fadeIn();
    }
  };

  // toggle audio options visibility
  actions.audioOptionsVisibility = function(visible) {
    if (visible) {
      elements.audioOptions.closest($(".audio-options")).fadeIn();
    } else {
      elements.audioOptions.closest($(".audio-options")).hide();
    }
  };

  // toggle webcam source visability
  actions.webcamSourceVisibility = function(visible) {
    if (visible) {
      elements.webcamSource.closest($(".webcam-source")).fadeIn();
    } else {
      elements.webcamSource.closest($(".webcam-source")).hide();
    }
  };

  // toggle advanced settings visability
  actions.bindToggleshowAdvanced = function() {
    elements.toggleAdvanced.click(function() {
      var body = $(".screen-cap-settings").find(".advanced-body"),
        icon = $(".screen-cap-settings")
          .find(".advanced-screen-cap")
          .find("i");

      body.slideToggle();
      icon.toggleClass("fa-chevron-down fa-chevron-up");
    });
  };

  // toggle shared audio advice visability
  actions.shareAudioAdviceVisibility = function() {
    if (isSystemAudioBeingRecorded(state.recordingType)) {
      elements.shareAudioAdvice.fadeIn();
    } else {
      elements.shareAudioAdvice.hide();
    }
  };

  // grab the system devices and send them to a func
  actions.getDevices = function() {

    const options = { limit: 200
                    , delay: 3000
                    , stopRetryingIf: function(_error, _attempt) {
                      var willRetry = elements.modal.is(":visible") && !state.isRecording;
                      return !willRetry;
                      }
    };
    const retrier = new Retrier.Retrier(options);

    retrier.resolve(_attempt => new Promise((onSuccess, onFailure) => {

      // Short circuit this if we are recording.
      if (state.isRecording) onSuccess();

      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true
        })
        .then(function(stream) {
          actions.displayPermissionError(false);

          navigator.mediaDevices.enumerateDevices().then(function(devices) {
            actions.gotDevices(devices);

            actions.disableWebcamSharing(false);
            if (state.isRecording) onSuccess(); else onFailure({name: "GetDevicesWillRetry"});

          }).catch(function (e) {
            onFailure(e);
          });

          stream.getTracks().forEach(function(track) {
            track.stop();
          });

        })
        .catch(function(e) {
           // The switch below allows distinguishing the cases where users denied
           // permission explicitly vs the case where some other app is using the webcam.
           if (e != null && e != undefined) {
             switch (e.name) {
               case "NotAllowedError":
                 actions.displayPermissionError(true);
                 actions.disableWebcamSharing(true);
                 break;
               default:
                 actions.displayDevicesError(true);
             }
           }

           onFailure(e);

        });

        if (state.isRecording) onSuccess(); else onFailure({name: "GetDevicesWillRetry" });

    })).then(function () {
      actions.disableWebcamSharing(false);
    }).catch(_e => { return; }); // Nothing we can do at this point.

  };

  // show error if devices not allowed
  actions.displayPermissionError = function(show) {
    if (show) {
      elements.devicePermissionWarning.fadeIn();
    } else {
      elements.devicePermissionWarning.hide();
    }
  };

  actions.displayDevicesError = function(show) {
    if (show) {
      elements.noDevicesWarning.fadeIn();
    } else {
      elements.noDevicesWarning.hide();
    }
  };

  // watch for devices changes and update
  actions.bindDeviceChange = function() {
    navigator.mediaDevices.ondevicechange = function() {
      actions.getDevices();
    };
  };

  // Find all the sources (e.g. webcams or microphones) given an input 'ScreenCaptureMediaSource'.
  actions.enumerateSources = function(mediaSource, infos) {
    var sourceKind = "videoinput";
    var sources = [];

    switch (mediaSource) {
      case ScreenCaptureMediaSource.Webcam:
        sourceKind = "videoinput";
        break;
      case ScreenCaptureMediaSource.Microphone:
        sourceKind = "audioinput";
        break;
    }

    infos.forEach(function(device) {
      if (device.kind === sourceKind)
        if (sources.indexOf(device) === -1)
          sources.push(device);
    });

    return sources;
  };

  actions.setMediaSources = function (mediaSource, sources, beforeChange) {

    var oldSources = null;
    var domSelector = null;

    switch (mediaSource) {
      case ScreenCaptureMediaSource.Webcam:
        oldSources = state.availableWebcams;
        domSelector = elements.webcamSource;
        break;
      case ScreenCaptureMediaSource.Microphone:
        oldSources = state.availableMicrophones;
        domSelector = elements.micSource;
        break;
    }

    if (JSON.stringify(oldSources) != JSON.stringify(sources)) {

      if (ScreenCaptureMediaSource.Webcam     == mediaSource) { state.availableWebcams     = sources; }
      if (ScreenCaptureMediaSource.Microphone == mediaSource) { state.availableMicrophones = sources; }

      beforeChange();

      // Store the user's old preference
      var oldPreferenceValue = domSelector.val();
      var oldPreferenceLabel = domSelector.find("option[value=" + oldPreferenceValue + "]").attr("label");
      var oldPreferenceStillExists = false;

      // Nuke the old elements
      domSelector.find("option").remove().end();

      // Add the new elements
      sources.forEach(function(source) {
        var option = $("<option>");
        option.attr({
          value: source.deviceId,
          label: source.label ? source.label : __("Default device")
        });
        if (source.label == oldPreferenceLabel) oldPreferenceStillExists = true;
        option.html(source.label);
        domSelector.append(option);
      });

      // Try to restore the user's preference. If the old preference is not available anymore, pick
      // the first entry from the top.
      if (oldPreferenceValue != null && oldPreferenceStillExists)  domSelector.val(oldPreferenceValue).change();
      if (oldPreferenceValue != null && !oldPreferenceStillExists) domSelector.find("option:eq(0)").prop("selected", true);

    }
  };

  actions.setVideoSources = function (sources) {
    actions.setMediaSources(ScreenCaptureMediaSource.Webcam, sources, function () {
      actions.disableWebcamSharing(false);
      actions.displayDevicesError(false);
    });

  };

  actions.setAudioSources = function (sources) {
    actions.setMediaSources(ScreenCaptureMediaSource.Microphone, sources, function () { return; });
  };

  // interate through the system devices and update the selects in the dom
  actions.gotDevices = function(deviceInfos) {
    var webcams = actions.enumerateSources(ScreenCaptureMediaSource.Webcam, deviceInfos),
        mics    = actions.enumerateSources(ScreenCaptureMediaSource.Microphone, deviceInfos);

    // If no webcams are detected, handle this gracefully.
    if (webcams.length == 0) {
      actions.displayDevicesError(true);
      actions.disableWebcamSharing(true);

      // Try to re-enabled webcams

      const options = { limit: 200
                      , delay: 3000
                      , stopRetryingIf: function(_error, _attempt) {
                        var willRetry = elements.modal.is(":visible") && !state.isRecording;
                        return !willRetry;
                        }
      };
      const retrier = new Retrier.Retrier(options);

      retrier.resolve(attempt => new Promise((onSuccess, onFailure) => {

        if (attempt >= (options.limit - 1)) {
          // Display the "Check webcam again" button
          elements.checkWebcamAgainBtn.fadeIn();
        }

        navigator.mediaDevices.enumerateDevices().then(function(infos) {
          var allWebcams = actions.enumerateSources(ScreenCaptureMediaSource.Webcam, infos);
          if (allWebcams.length > 0)
            onSuccess(allWebcams);
          else
            onFailure();
        });

      }).then(function (cams) {
        actions.setVideoSources(cams);
      })).catch(_e => { return; }); // Nothing we can do.

    } else {
      actions.setVideoSources(webcams);
    }

    actions.setAudioSources(mics);

  };

  actions.bindRecordingAbortedBtn = function() {
    elements.recordingAbortedBtn.click(function(evt) {
      evt.preventDefault();
      elements.recordingAbortedModal.modal("hide");
      elements.recordingAbortedBtn.off("click");
    });
  };

  actions.bindCheckWebcamAgainBtn = function() {
    elements.checkWebcamAgainBtn.click(function(evt) {
      evt.preventDefault();

      navigator.mediaDevices.enumerateDevices().then(function(infos) {
          return actions.enumerateSources(ScreenCaptureMediaSource.Webcam, infos);
      }).then(function (cams) {
        if (cams.length > 0) actions.setVideoSources(cams);
      }).catch(_e => { return; }); // Nothing we can do.
    });

  };

  // By default, we disable the "Webcam" and "Desktop and webcam" options,
  // because video inputs might be blocked due to permission issues, and we do not want
  // to allow users so select these options.
  actions.disableWebcamSharing = function (doDisable) {

    $("#video-options").children().each(function (_ix, c) {
      if ($(c).val().includes("webcam")) {
        $(c).attr("disabled", doDisable);
      }
    });

    // Disable the "Advanced Settings", in case of problems.
    if (doDisable) {
      elements.toggleAdvanced.addClass("hide");
    } else {
      elements.toggleAdvanced.removeClass("hide");
    }

  };

  actions.showRetryUploadBtn = function (f) {
    // We need the conditional at this would fire simultaneously for both the left and right
    // uploaders for the dual case, but we need to trick the user into thinking this is a single
    // upload.
    if (!state.retryUploadBtnShowed) {
      actions.resetRetryUploadBtn();
      elements.retryUploadDiv.removeClass("hide");
      elements.retryUploadBtn.off();
      elements.retryUploadBtn.click(function () {
        elements.retryUploadBtn.find("i").removeClass();
        elements.retryUploadBtn.addClass("disabled");
        elements.retryUploadBtn.prop('disabled', true);
        elements.retryUploadBtn.find("i").addClass("fa fa-reflesh fa-spin");

        if (state.leftRecordManager != null)  f(state.leftRecordManager);
        if (state.rightRecordManager != null) f(state.rightRecordManager);

        // FIXME(adinapoli) We want to reset the retry button only under some circumstances.
        actions.resetRetryUploadBtn();
      });
    }
  };

  actions.hideRetryUploadBtn = function () {
    state.retryUploadBtnShowed = false;
    actions.resetRetryUploadBtn();
  };

  actions.resetRetryUploadBtn = function () {
    elements.retryUploadDiv.addClass("hide");
    elements.retryUploadBtn.find("i").removeClass();
    elements.retryUploadBtn.find("i").addClass("fa fa-exclamation-triangle");
    elements.retryUploadBtn.prop('disabled', false);
  };

  // INIT

  actions.disableWebcamSharing(true);
  actions.bindAudioSource();
  actions.bindModalHidden();
  actions.bindModalShow();
  actions.bindQualitySelect();
  actions.bindStart();
  actions.bindModalDismiss();
  actions.beforeNavigation();
  actions.bindRecordingTypeChange();
  actions.bindToggleshowAdvanced();
  actions.bindDeviceChange();
  actions.bindRecordingAbortedBtn();
  actions.bindCheckWebcamAgainBtn();
}
