/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { msToTimestamp } from '../../../common/utils/utils';

export const CommentTimestampButtonDropdown: React.FC<{
  timestamp: number | undefined;
  onUpdateClick: () => void;
  onRemoveClick: () => void;
}> = ({
  timestamp,
  onUpdateClick,
  onRemoveClick,
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((o) => !o);

  const handleClick = () => {
    if (typeof timestamp === 'number') {
      toggleOpen();
    } else {
      onUpdateClick();
    }
  };

  return (
    <div className={`tw-dropdown ${open ? 'tw-dropdown-open' : ''}`}>
      <button type="button" className="tw-btn tw-btn-xs tw-rounded-full tw-gap-1" onClick={handleClick}>
        <i className="fa fa-clock-o" />

        {typeof timestamp === 'number' ? (
          msToTimestamp(timestamp, true)
        ) : null}
      </button>

      {open ? (
        <div className="tw-dropdown-content tw-iris-dropdown">
          <div className="tw-iris-dropdown-box">
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(false);
                    onUpdateClick();
                  }}
                >
                  {__('Update timestamp')}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(false);
                    onRemoveClick();
                  }}
                >
                  {__('Remove timestamp')}
                </button>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};
