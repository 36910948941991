import { Aliases } from '@babel/types';
import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../../../api-parser';
import ApiError from '../../../error';
import { generateFetchHeaders, jsonHeaders, Metadata, PaginationParams, searchParams } from '../../../routes';

export const groupDiscussionsRoute = (args: {
  groupId: Atlas.GroupID;
}): string => (
  `/api/v0/groups/${args.groupId}/discussions`
);

export type FindGroupDiscussionsParams = PaginationParams & {
  groupId: Atlas.GroupID;
}

export interface FindGroupDiscussionsArguments {
  params: FindGroupDiscussionsParams;
}

export interface FindGroupDiscussionsResponse {
  data: Atlas.Discussion[];
  metadata: Metadata;
}

export const findGroupDiscussions = async (
  args: FindGroupDiscussionsArguments
): Promise<FindGroupDiscussionsResponse> => {
  const { groupId, ...params } = args.params;

  const url = [
    groupDiscussionsRoute({ groupId }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findGroupDiscussions');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.Discussion>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface CreateGroupDiscussionParams {
  groupId: Atlas.GroupID;
}

export interface CreateGroupDiscussionBody {
  title: Atlas.DiscussionTitle;
  locked?: Atlas.DiscussionLocked;
  notificationLevel?: Atlas.NotificationLevel;
  userVisibility?: Atlas.UserVisibility;
}

export interface CreateGroupDiscussionArguments {
  params: CreateGroupDiscussionParams;
  body: CreateGroupDiscussionBody;
}

export interface CreateGroupDiscussionResponse {
  data: Atlas.Discussion;
}

export const createGroupDiscussion = async (
  args: CreateGroupDiscussionArguments
): Promise<CreateGroupDiscussionResponse> => {
  const { groupId } = args.params;

  const url = groupDiscussionsRoute({ groupId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      title: args.body.title,
      locked: args.body.locked,
      notification_level: args.body.notificationLevel,
      user_visibility: args.body.userVisibility,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'createGroupDiscussion');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Discussion>(body.data);

  return { data };
};
