import React, {
  useEffect, useState, PropsWithChildren, useMemo,
} from 'react';

import RecordingIcon from '../RecordingIcon';
import * as Atlas from '../../../common/types/Atlas';
import useVideoPosition from '../../jwplayer/useVideoPosition';

interface VideoTimeRangeSelectorProps {
  onTick: (vs: Atlas.VideoSegment) => void;
  onSelect: (vs: Atlas.VideoSegment) => void;
  onRecordingStart: () => void;
  onRecordingEnd: () => void;
}

type VideoTimeRangeSelectorState = {
  status: 'idle';
  videoId?: never;
  startMs?: never;
} | {
  status: 'recording';
  videoId: number;
  startMs: number;
};

const VideoTimeRangeSelector = (
  props: PropsWithChildren<VideoTimeRangeSelectorProps>,
): JSX.Element | null => {
  const {
    onTick, onSelect, onRecordingStart, onRecordingEnd, children,
  } = props;

  const [state, setState] = useState<VideoTimeRangeSelectorState>({
    status: 'idle',
  });

  const { getVideoId, getPosition } = useVideoPosition();

  useEffect(() => {
    if (state.status !== 'recording') { return undefined; }
    if (!onTick) { return undefined; }
    if (!getVideoId) { return undefined; }
    if (!getPosition) { return undefined; }

    const interval = setInterval(() => {
      const start = state.startMs;
      const duration = getPosition() - start;

      const videoSegment = {
        video_id: getVideoId(),
        start,
        duration,
      };

      onTick(videoSegment);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getPosition, getVideoId, onTick, state.startMs, state.status]);

  switch (state.status) {
    case 'recording': {
      const handleButtonClick = () => {
        if (!getVideoId) { return; }
        if (!getPosition) { return; }

        const start = state.startMs;
        const duration = getPosition() - start;

        const videoSegment = {
          video_id: getVideoId(),
          start,
          duration,
        };

        setState({ status: 'idle' });
        onSelect(videoSegment);
        onRecordingEnd?.();
      };

      return (
        <div className="tw-flex tw-flex-grow">
          <button
            type="button"
            className="tw-btn tw-btn-xs tw-gap-2"
            disabled={!getPosition}
            onClick={handleButtonClick}
          >
            <RecordingIcon />
            <span>{__('Stop')}</span>
          </button>
        </div>
      );
    }

    case 'idle':
    default: {
      const handleButtonClick = () => {
        if (!getPosition) { return; }

        const currentPosition = getPosition();

        setState({
          status: 'recording',
          videoId: 123,
          startMs: currentPosition,
        });
        onRecordingStart?.();
      };

      return (
        <div className="tw-flex tw-flex-grow">
          <button
            type="button"
            className="tw-btn tw-btn-xs tw-gap-2"
            disabled={!getPosition}
            onClick={handleButtonClick}
          >
            <span>
              <i className="icon-time" />
            </span>
            <span>
              {children}
            </span>
          </button>
        </div>
      );
    }
  }
};

export default VideoTimeRangeSelector;
