import { Attachment, AttachmentID, AttachmentName } from '../../types/Atlas';
import { generateCsrfTokenHeader, generateFetchHeaders, Metadata, PaginationParams, searchParams } from '../routes';

export const attachmentsRoute = (): string  => '/attachments';
export const attachmentRoute = ({ attachmentId }: { attachmentId: AttachmentID }): string => `/attachments/${attachmentId}`;

interface FindAttachmentArgs {
  params?: PaginationParams;
}

export const findAttachments = (args: FindAttachmentArgs): Promise<{ attachments: Attachment[], metadata: Metadata }> => {
  const { params } = args || {};

  const attachmentsUrl = [
    attachmentsRoute(),
    searchParams({ ...params })
  ].join('?');

  return fetch(attachmentsUrl, {
    method: 'GET',
    credentials: 'same-origin'
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] findAttachments failed`; }
    return r.json();
  });
};

export interface CreateAttachmentArgs {
  body: {
    name: AttachmentName;
  };
}

export type CreateAttachmentResponse = {
  attachment: Attachment;
  metadata: Metadata;
}

export const createAttachment = (args: CreateAttachmentArgs): Promise<CreateAttachmentResponse> => {
  const { body } = args;

  return fetch(attachmentsRoute(), {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(body)
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] createAttachment failed`; }
    return r.json();
  });
};

interface DestroyAttachmentArgs {
  params: { attachmentId: AttachmentID; };
}

export const destroyAttachment = ({ params }: DestroyAttachmentArgs): Promise<void> => {
  const attachmentUrl = attachmentRoute({ attachmentId: params.attachmentId });

  return fetch(attachmentUrl, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: generateCsrfTokenHeader()
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] destroyAttachment failed`; }
  });
};
