import { useEffect, useState } from 'react';

const useSearchValue = (value: string, delay = 1000) => {
  const [currentValue, setCurrentValue] = useState(value);

  const enabled = value === currentValue;

  useEffect(() => {
    if (enabled) { return; }

    const timeout = setTimeout(() => {
      setCurrentValue(value)
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [enabled, delay, value]);

  return { value, enabled };
};

export default useSearchValue;
