import React from 'react';

// UI
import {
  CartesianGrid,
  DotProps,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// Utils
import { msToTimestamp } from '../../../../common/utils/utils';
import { pickColor } from '../../../../common/utils/colors';
import * as Atlas from '../../../../common/types/Atlas';
import useVideoPlayer from '../../../jwplayer/useVideoPlayer';

interface Payload {
  time: number;
  videoId: Atlas.VideoID;
}

interface FormTextLineChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

const FormTextLineChart = (props: FormTextLineChartProps): JSX.Element => {
  const { formAnswers = [] } = props;

  const videoPlayer = useVideoPlayer();

  const handleDotClick = (
    _: DotProps,
    event: React.MouseEvent<SVGCircleElement, MouseEvent> & { payload: Payload; },
  ) => {
    if (!videoPlayer) { return; }

    const { time, videoId } = event.payload;
    const currentVideoId = videoPlayer.videoId;

    if (currentVideoId === videoId) {
      videoPlayer.seek(time / 1000);
    } else {
      videoPlayer.play(videoId, time / 1000);
    }
  };

  const data: Record<Atlas.VideoID, Record<number, number>> = {};

  videoPlayer?.playlist.forEach((videoId) => {
    data[videoId] = {};
  });

  formAnswers.forEach((fa) => {
    fa.timeline?.forEach((tl) => {
      const vId = tl.video_id;
      data[vId] ||= {};

      const start = Math.floor(tl.start);
      const beforeStart = Math.max(start - 1, 0);

      data[vId][beforeStart] ||= 0;
      data[vId][start] ||= 0;
      data[vId][start] += 1;

      const end = Math.floor(tl.start + tl.duration);
      const afterEnd = Math.min(end + 1);

      data[vId][end] ||= 0;
      data[vId][afterEnd] ||= 0;
      data[vId][afterEnd] -= 1;
    });
  });

  const chartsData = Object.entries(data).map(([videoId, times]) => {
    const data: Array<{
      time: number;
      videoId: Atlas.VideoID;
    }> = [];

    Object.entries(times).reduce((prev, [time, count]) => {
      const next: {
        time: number;
        videoId: Atlas.VideoID;
      } & Record<string, number> = {
        ...prev,
        time: Number(time),
        videoId: Number(videoId),
      };

      next.count ||= 0;
      next.count += count;

      data.push(next);
      return next;
    }, {
      time: 0,
    });

    return { videoId, data };
  });

  return (
    <div>
      {chartsData.length ? (
        <div className="tw-text-center tw-py-4 px-3 tw-text-base-300">
          {__('There is no timeline data to show.')}
        </div>
      ) : chartsData.map(({ videoId, data }) => (
        data.length ? (
          <ResponsiveContainer height={200} width="100%" key={videoId}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" type="number" scale="time" name={__('Time')} tickFormatter={(n: number) => msToTimestamp(Number(n))} />

              <YAxis allowDecimals={false} type="number" name={__('Count')}>
                <Label angle={-90} value={__('Count')} position="insideLeft" offset={20} style={{ textAnchor: 'middle' }} />
              </YAxis>

              <Tooltip labelFormatter={(n) => msToTimestamp(Number(n))} />

              <Line
                dataKey="count"
                name={__('Count')}
                connectNulls
                stroke={pickColor(0)}
                activeDot={{ onClick: handleDotClick }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : null
      ))}
    </div>
  );
};

export default FormTextLineChart;
