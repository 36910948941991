import React, { useRef } from 'react';
import { ReflectionID } from '../types/Atlas';
import { useIntersection } from 'react-use';
import useFindReflectionQuery from '../hooks/api/reflections/useFindReflectionQuery';

const ReflectionName: React.FC<{
  reflectionId: ReflectionID;
  className?: string;
}> = ({
  reflectionId,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersection(ref, {});
  const visible = entry?.isIntersecting ?? false;

  const query = useFindReflectionQuery(reflectionId, { enabled: visible });

  switch (query.status) {
    case 'idle':
    case 'loading': return (
      <div className={className} ref={ref}>
        {__('Loading...')}
      </div>
    );

    case 'success': return (
      <div className={className} ref={ref}>
        {query.data.data.name}
      </div>
    );

    case 'error':
    default: return (
      <div className={className} ref={ref}>
        {__('Something went wrong.')}
      </div>
    );
  }
};

export default ReflectionName;
