import { useMutation } from 'react-query';
import { FindReflectionVideosResponse } from '../../../api/reflections/videos';
import { FindReflectionPlaylistResponse } from '../../../api/reflections/[reflectionId]/playlist';
import { FindVideoResponse } from '../../../api/videos/[videoId]';
import { swapVideo, SwapVideoArguments, SwapVideoResponse } from '../../../api/videos/[videoId]/swap';
import { queryClient } from '../../withQueryClient';

const useSwapVideoMutation = () => {
  const mutation = useMutation<SwapVideoResponse, unknown, SwapVideoArguments, unknown>(
    swapVideo,
    {
      onSuccess: (result) => {
        const video = result.data;

        queryClient.setQueryData<FindVideoResponse>(['videos', video.id], { data: video });

        if (video.reflection_id) {
          void queryClient.invalidateQueries<FindReflectionPlaylistResponse>(['reflections', video.reflection_id, 'playlist']);
          void queryClient.invalidateQueries<FindReflectionVideosResponse>(['reflections', video.reflection_id, 'videos']);
        }
      },
    },
  );

  return mutation;
};

export default useSwapVideoMutation;
