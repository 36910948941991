import React, { useState, useCallback, useMemo } from 'react';
import { OverlayContainer, Overlay } from '../../../common/components/Overlay';
import { useCurrentUser } from '../../../common/hooks/withCurrentUser';
import AssignmentSubmissionContext from './AssignmentSubmissionContext';
import AssignmentSubmissionForm from './AssignmentSubmissionForm';
import AssignmentSubmissionAssessorForm from './AssignmentSubmissionAssessorForm';
import * as Atlas from '../../../common/types/Atlas';
import AssignmentDetails from './AssignmentDetails';
import RouterPrompt from '../../../common/components/RouterPrompt';
import Spinner from '../../../common/components/Spinner';
import { debounce } from '../../../common/utils/utils';
import useUpdateAssignmentSubmissionMutation from '../../../common/hooks/api/assignments/useUpdateAssignmentSubmissionMutation';
import useCreateAssignmentSubmissionMutation from '../../../common/hooks/api/assignments/useCreateAssignmentSubmissionMutation';
import { submissionHasAnswers } from '../../../common/utils/submission-has-answers';
import AssignmentResubmissionHandler from './AssignmentResubmissionHandler';
import useUpdateAssignmentSubmissionFeedbackMutation from '../../../common/hooks/api/assignment-submissions/useUpdateAssignmentSubmissionFeedbackMutation';

const joinWords = (...words: string[]) => words.filter(Boolean).join(' ');

const asyncQueue = <T, R>(fn: (args: T, previousResult?: R) => Promise<R>) => {
  let lastPromise: Promise<R | undefined> = Promise.resolve(undefined);

  const invoke = async (args: T): Promise<R> => {
    const nextInvocation = lastPromise.then((previousResult) => fn(args, previousResult));
    lastPromise = nextInvocation.catch(() => undefined);
    return nextInvocation;
  };

  return invoke;
};

interface GroupAssignmentSubmissionProps {
  group: Atlas.Group;
  assignment: Atlas.Assignment;
  initialSubmission?: Atlas.AssignmentSubmission;
  onSubmitted?(): void;
  onCompleted?(s: Atlas.ExpandedAssignmentSubmission): void;
  onFormInteraction?: () => void;
  hideDetails?: boolean;
  readOnly?: boolean;
  pageId?: Atlas.PageID;
}

interface GroupAssignmentSubmissionState {
  submission: Partial<Atlas.ExpandedAssignmentSubmission>;
  isModified?: boolean;
  submitting?: boolean
  savingDraft?: boolean;
  savedDraft?: boolean;
  isAutoSaving?: boolean;
}

const GroupAssignmentSubmission = (props: GroupAssignmentSubmissionProps): JSX.Element => {
  const { group, assignment, readOnly } = props;

  const { user } = useCurrentUser();

  const isAssessor = user && group && (
    group.group?.administrators.user_ids.includes(user.id) ||
    group.group?.assessors.user_ids.includes(user.id)
  );

  const [state, setState] = useState<GroupAssignmentSubmissionState>({
    submission: props.initialSubmission || {
      answers: [],
      ...(props.pageId ? { page_id: props.pageId } : {})
    }
  });

  const hasAnswers = submissionHasAnswers(state.submission.answers || []);

  const updateSubmissionMutation = useUpdateAssignmentSubmissionMutation(props.assignment.id, state.submission.id || undefined);
  const createSubmissionMutation = useCreateAssignmentSubmissionMutation(props.assignment.id);
  const updateFeedbackMutation = useUpdateAssignmentSubmissionFeedbackMutation();

  const handleSubmissionInit = useCallback<React.ComponentProps<typeof AssignmentSubmissionForm>['onInit']>(func => {
    if (readOnly) { return; }
    setState(s => ({ ...s, submission: func(s.submission) }));
  }, [readOnly]);

  const handleSubmissionChange = useCallback(
    (
      func: (
        submission: Partial<Atlas.AssignmentSubmission>
      ) => Partial<Atlas.AssignmentSubmission>
    ) => {
    if (readOnly) { return; }

    props.onFormInteraction?.();

    setState((prevState) => {
      const updatedSubmission = func(prevState.submission);

      const newState = {
        ...prevState,
        submission: updatedSubmission,
        isAutoSaving: prevState.submission.complete === Atlas.SubmissionComplete.Draft,
        isModified: true,
      };

      if (!updatedSubmission.id || updatedSubmission.complete === Atlas.SubmissionComplete.Draft) {
        debounceAutoUpsert(updatedSubmission);
      }

      return newState;
    });

  }, [readOnly]);

  const autoCreateDraft = async (submission: Partial<Atlas.AssignmentSubmission>) => {
    const body = { ...submission, complete: Atlas.SubmissionComplete.Draft }
    const hasAnswers = submissionHasAnswers(submission.answers || []);

    setState(s => ({...s, savingDraft: hasAnswers, savedDraft: false}));

    const result = await createSubmissionMutation.mutateAsync({
      params: { assignmentId: assignment.id },
      body: { submission: body }
    });

    const createdDraft = result.data;

    setState((prevState) => ({
      ...prevState,
      submission: {
        ...prevState.submission,
        id: createdDraft.id,
        complete: createdDraft.complete}
      ,
      savingDraft: false,
      savedDraft: hasAnswers,
      isAutoSaving: false
    }))

    return result;
  };

  const autoUpdateDraft = async (submission: Partial<Atlas.AssignmentSubmission>) => {
    if (!submission.id) { throw new Error('Missing submission id'); }

    const body = { ...submission, complete: Atlas.SubmissionComplete.Draft }
    const hasAnswers = submissionHasAnswers(submission.answers || []);

    setState(s => ({...s, savingDraft: hasAnswers, savedDraft: false}));

    const result = await updateSubmissionMutation.mutateAsync({
      params: { assignmentId: assignment.id, assignmentSubmissionId: submission.id },
      body: { submission: body }
    });

    setState(s => ({...s, savingDraft: false, savedDraft: hasAnswers, isAutoSaving: false}));

    return { data: result.data };
  };

  const autoUpsert = asyncQueue<Partial<Atlas.AssignmentSubmission>, Atlas.AssignmentSubmissionID>(async (args: Partial<Atlas.AssignmentSubmission>, prev: number | undefined): Promise<number>  => {
    if (prev || args.id) {

      let submission = args;

      if (!args?.id) {
        submission = {...args, id: prev}
      }
      const result = await autoUpdateDraft(submission);
      return result.data.id
    }
    const result = await autoCreateDraft(args);
    return result.data.id;
  });

  const debounceAutoUpsert = useMemo(
    () =>
      debounce(async (submission: Partial<Atlas.AssignmentSubmission>) => {
        await autoUpsert(submission);
      }, 1000),
    [autoUpsert]
  )

  const createSubmission = async (submission: Partial<Atlas.AssignmentSubmission>, draft = false) => {
    const body = draft
      ? { ...submission, complete: Atlas.SubmissionComplete.Draft }
      : { ...submission, complete: Atlas.SubmissionComplete.Incomplete };

    const result = await createSubmissionMutation.mutateAsync({
      params: { assignmentId: assignment.id },
      body: { submission: body }
    });

    const createdSubmission = result.data;

    setState((prevState) => ({
      ...prevState,
      submission: {...prevState.submission, id: createdSubmission.id, complete: createdSubmission.complete},
      isAutoSaving: false
    }))

    return result;
  };

  const updateSubmission = async (submission: Partial<Atlas.AssignmentSubmission>) => {
    if (!submission.id) { throw new Error('Missing submission id'); }

    const result = await updateSubmissionMutation.mutateAsync({
      params: { assignmentId: assignment.id, assignmentSubmissionId: submission.id },
      body: { submission }
    });

    return { data: result.data };
  };

  const saveSubmission = (submission: Partial<Atlas.AssignmentSubmission>) => {
    return (submission.id ? updateSubmission(submission) : createSubmission(submission));
  };

  const saveFeedback = async (submission: Partial<Atlas.AssignmentSubmission>) => {
    if (!submission.id) { throw new Error('Missing submission id'); }

    const result = await updateFeedbackMutation.mutateAsync({
      params: {
        assignmentSubmissionId: submission.id,
      },
      body: { 
        page_id: submission.page_id,
        complete: Atlas.SubmissionComplete.Complete,
        grade: submission.grade,
        feedback: submission.feedback,
        notify_submitter: assignment.notify_submitter,
       }
    });

    return { data: result.data };
  }

  const handleSubmit = state.submission.id && state.submission.complete !== 'draft' ? undefined : (() => {
    if (!window.confirm(__('Are you sure that you want to submit this assignment?'))) { return; }

    setState(s => ({ ...s, submitting: true }));

    saveSubmission({...state.submission, complete: Atlas.SubmissionComplete.Incomplete}).then(({ data: submission }) => {
      setState(s => ({ ...s, submitting: false, saved: true, isModified: false, submission }));
      props.onSubmitted?.();
    }, () => {
      setState(s => ({ ...s, submitting: false, saved: false }));
    });
  });

  const handleMarkAsComplete = () => {
    setState(s => ({ ...s, submitting: true }));
    saveFeedback(state.submission).then(({ data: submission }) => {
      setState(s => ({ ...s, submitting: false, saved: true, isModified: false, submission }));
      props.onCompleted?.(submission);
    }, () => {
      setState(s => ({ ...s, savsubmittinging: false, saved: false }));
    });
  };

  const handleAssessorFormChange = (changes: Partial<Atlas.AssignmentSubmission>) => {
    handleSubmissionChange((submission: Partial<Atlas.AssignmentSubmission>) => ({
      ...submission,
      ...changes
    }));
  };

  const handleDiscardDraft = async () => {
    if (!window.confirm(__('Discard draft? You\'ll lose the answers provided so far.'))) { return; }
    if (!state.submission.id) { throw new Error('Missing submission id'); }

    setState(s => ({ ...s, submitting: true }));

    await updateSubmissionMutation.mutateAsync({
      params: { assignmentId: assignment.id, assignmentSubmissionId: state.submission.id},
      body: {
        submission: {
          ...state.submission,
          answers: [],
          complete: Atlas.SubmissionComplete.Draft
        },
      },
    })

    setState(s => ({
      ...s,
      submission: {...s.submission,answers: []},
      submitting: false,
      savedDraft: false,
    }));
  }

  const userName = joinWords(state.submission?.first_name ?? '', state.submission?.last_name ?? '');

  return (
    <AssignmentSubmissionContext.Provider value={{
      assignment,
      submission: state.submission,
    }}>
      <RouterPrompt
        when={
          state.isAutoSaving ||
          (
            !!state.isModified && !state.submitting &&
            state.submission.complete !== Atlas.SubmissionComplete.Draft
          )
        }
        message={__('You have unsaved changes on this page.')}
      />

      <OverlayContainer className={state.submitting ? 'active tw-px-[2px]' : 'tw-px-[2px]'}>
        {props.hideDetails ? null : (
          <React.Fragment>
            <div className="mb-3">
              <AssignmentDetails
                assignment={assignment}
                date={state.submission.created_at}
                name={userName} />
            </div>
            <div className="tw-divider">
            </div>
          </React.Fragment>
        )}

        <AssignmentSubmissionForm
          readOnly={
            readOnly || (!!state.submission.created_at && state.submission.complete !== 'draft')
          }
          assignment={assignment}
          submission={state.submission}
          onInit={handleSubmissionInit}
          saving={state.savingDraft}
          saved={state.savedDraft}
          isAutoSaving={state.isAutoSaving}
          hasAnswers={hasAnswers}
          discardDraft={handleDiscardDraft}
          onChange={handleSubmissionChange}
          onSubmit={handleSubmit} />

        {props.hideDetails ? null : state.submission.created_at && state.submission.complete !== 'draft' && isAssessor && props.initialSubmission? (
          <React.Fragment>
            <div className="tw-divider">
            </div>
            <div>
              <AssignmentSubmissionAssessorForm
                submission={state.submission}
                showGrade={props.assignment.show_grade}
                showFeedback={props.assignment.show_feedback}
                onChange={handleAssessorFormChange}
                onSubmit={handleMarkAsComplete}
              />
            </div>
          </React.Fragment>
        ) : state.submission.complete === Atlas.SubmissionComplete.Complete ? (
          <React.Fragment>
            <div className="tw-divider">
            </div>
            <div className="px-3">
              {state.submission.grade ? (
                <>
                  <h5 className="control-label blue-text mt-0">
                    {__('Grade')}
                  </h5>
                  <p className="bg-light rounded px-3 py-2">
                    <b>{state.submission.grade}</b>
                  </p>
                </>
              ) : null}

              {state.submission.feedback ? (
                <>
                  <h5 className="control-label blue-text mt-0">
                    {__('Feedback')}
                  </h5>
                  <p className="bg-light rounded px-3 py-2">
                    {state.submission.feedback}
                  </p>
                </>
              ) : null}
            </div>
          </React.Fragment>
        ) : null}
        {props.hideDetails ? null : !isAssessor && state.submission.complete !== Atlas.SubmissionComplete.Draft && props.initialSubmission? (
          <React.Fragment>
            <div className="tw-divider">
            </div>
            <div>
              <AssignmentResubmissionHandler
                submission={props.initialSubmission}
              />
            </div>
          </React.Fragment>
        ) : null}
        <Overlay>
          <Spinner color="info" />
        </Overlay>
      </OverlayContainer>
    </AssignmentSubmissionContext.Provider>
  );
};

export default GroupAssignmentSubmission;
