import React, { useEffect, useState } from 'react';
import { formCsvPath } from '../../common/api/forms';
import * as Atlas from '../../common/types/Atlas';
import { formElementIcon } from '../form-builder/form-element-utils';
import FormResponsesList from './ResponsesList';
import { FormReportingFilter } from './types';

interface FormResponsesProps {
  formId: Atlas.FormID;
  form?: Atlas.Form;
  filters: FormReportingFilter[];
}

interface FormResponsesState {
  selectedFilter: string;
  questionFilter?: string;
  questionFilterDropdownOpen: boolean;
}

const FormResponses = (props: FormResponsesProps): JSX.Element => {
  const { formId, form, filters } = props;

  const [state, setState] = useState<FormResponsesState>({
    selectedFilter: filters[0].id,
    questionFilterDropdownOpen: false,
  });

  const filter = filters.find((f) => f.id === state.selectedFilter) || filters[0];

  useEffect(() => {
    setState((s) => ({ ...s, selectedFilter: filter.id }));
  }, [filter.id]);

  const handleQuestionFilterClick = (formFieldName?: Atlas.FormFieldName) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();
    setState((s) => ({ ...s, questionFilter: formFieldName }));
  };

  const questionDropdownLabel = (() => {
    if (!state.questionFilter) { return __('All questions'); }
    if (!form) { return undefined; }

    const fe = form.elements.find((fe) => fe.field.name === state.questionFilter);
    if (!fe) { return undefined; }

    return (
      <>
        <i className={`${formElementIcon(fe)} fa-fw tw-mr-2`} />
        {fe.field.label}
      </>
    );
  })();

  const handleFilterClick = (filter: FormReportingFilter) => () => {
    setState((s) => ({ ...s, selectedFilter: filter.id }));
  };

  return (
    <div>
      <div className="tw-grid tw-grid-cols-2 tw-mb-4">
        <div className="tw-grid-cols-2 lg:tw-grid-cols-1">
          {filters.length > 1 ? (
            <div className="tw-flex tw-gap-2">
              {filters.map((filter) => {
                const isActive = filter.id === state.selectedFilter;

                return (
                  <button
                    key={filter.id}
                    type="button"
                    className={`tw-btn tw-btn-sm ${isActive ? 'tw-btn-primary' : 'tw-btn-ghost'}`}
                    onClick={handleFilterClick(filter)}
                  >
                    {filter.name}
                  </button>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="tw-grid-cols-2 lg:tw-grid-cols-1 tw-flex tw-justify-end tw-gap-3">
          <div className="tw-dropdown tw-dropdown-end">
            <button type="button" className="tw-btn tw-btn-sm tw-btn-white tw-shadow">
              {questionDropdownLabel}
              <i className="fa fa-chevron-down tw-ml-3" />
            </button>

            <div className="tw-dropdown-content tw-iris-dropdown">
              <div className="tw-iris-dropdown-box">
                <ul>
                  <li>
                    <button type="button" className="tw-text-left" onClick={handleQuestionFilterClick()}>
                      {__('All questions')}
                    </button>
                  </li>

                  {form?.elements.map((fe) => (
                    <li key={fe.field.name}>
                      <button type="button" className="tw-text-left" onClick={handleQuestionFilterClick(fe.field.name)}>
                        <div className="tw-flex tw-items-center tw-gap-3">
                          <i className={`${formElementIcon(fe)} fa-fw`} />
                          {fe.field.label}
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* <ButtonDropdown
            isOpen={state.questionFilterDropdownOpen}
            toggle={toggleQuestionFilterDropdown}
          >
            <DropdownToggle caret className="bg-light" color="light">
              {questionDropdownLabel}
              <i className="fa fa-chevron-down tw-ml-3" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={handleQuestionFilterClick()}>
                {__('All questions')}
              </DropdownItem>
              <DropdownItem divider />
              {form?.elements.map((fe) => (
                <DropdownItem
                  key={fe.field.name}
                  onClick={handleQuestionFilterClick(fe.field.name)}
                >
                  <i className={`${formElementIcon(fe)} fa-fw tw-mr-3`} />
                  {fe.field.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown> */}

          <a
            href={formCsvPath({ params: { formId, fieldName: state.questionFilter, ...filter } })}
            target="_blank"
            rel="noreferrer"
            download
            className="tw-btn tw-btn-white tw-btn-sm tw-shadow"
          >
            <i className="fa fa-download" />
          </a>
        </div>
      </div>

      {filter ? (
        <FormResponsesList
          key={state.selectedFilter}
          formId={formId}
          form={form}
          question={state.questionFilter}
          filter={filter}
        />
      ) : null}
    </div>
  );
};

export default FormResponses;
