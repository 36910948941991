import React, {
  useState, useRef, useLayoutEffect,
} from 'react';
import * as Atlas from '../../common/types/Atlas';
import {
  Modal, ModalBody, ModalHeader, ModalRef,
} from '../../common/components/Modal';
import DiscussionCommentForm from './components/DiscussionCommentForm';
import useCreateDiscussionDiscussionCommentMutation from '../../common/hooks/api/discussions/useCreateDiscussionDiscussionCommentMutation';
import DiscussionComment from './components/Comment';
import Spinner from '../../common/components/Spinner';
import useFindDiscussionDiscussionCommentsInfiniteQuery from '../../common/hooks/api/discussions/useFindDiscussionDiscussionCommentsInfiniteQuery';
import { DecoratedExpandedDiscussionComment } from '../../common/api/discussions/[discussionId]/comments';

interface DiscussionModalProps {
  discussion: Atlas.Discussion;
  onClose: () => void;
}

const DiscussionModal = (props: DiscussionModalProps): JSX.Element => {
  const {
    discussion,
    onClose,
  } = props;

  const [autoScroll, setAutoScroll] = useState(true);

  const discussionId = discussion.id;

  const discussionCommentsQuery = useFindDiscussionDiscussionCommentsInfiniteQuery({
    discussionId,
    per_page: 7,
  });
  const createDiscussionComment = useCreateDiscussionDiscussionCommentMutation({ discussionId });

  const commentListRef = useRef<HTMLDivElement>(null);
  const chatBottomRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<ModalRef>(null);

  useLayoutEffect(() => {
    if (!autoScroll) { return; }
    if (!commentListRef.current) { return; }
    if (!chatBottomRef.current) { return; }

    commentListRef.current.scrollTo({
      top: chatBottomRef.current.offsetTop,
      behavior: 'smooth',
    });
  }, [autoScroll, discussionCommentsQuery.isFetching]);

  const handleCommentListScroll = (event: React.MouseEvent<HTMLInputElement>) => {
    const { offsetHeight, scrollTop, scrollHeight } = event.currentTarget;

    const canFetchNextPage = discussionCommentsQuery.hasNextPage && !discussionCommentsQuery.isFetching;
    if (scrollTop < 200 && canFetchNextPage) {
      discussionCommentsQuery.fetchNextPage();
    }

    const scrolledToBottom = (offsetHeight + scrollTop) >= scrollHeight;
    setAutoScroll(scrolledToBottom);
  };

  const handleCommentSubmit = async (commentBody: string) => {
    await createDiscussionComment.mutateAsync({ body: commentBody });
    setAutoScroll(true);
  };

  const handleModalClose = () => {
    modalRef.current?.close();
    onClose();
  };

  const handleCloseModalClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    handleModalClose();
  };

  const pages = discussionCommentsQuery.data?.pages || [];

  const comments = pages.reduce<DecoratedExpandedDiscussionComment[]>((acc, page) => ([
    ...acc,
    ...page.data
  ]), []).sort((a, b) => a.created_at.valueOf() - b.created_at.valueOf());

  return (
    <Modal
      ref={modalRef}
      open
      onClose={handleModalClose}
      className="d-flex flex-column"
      style={{
        marginLeft: '-40vw', width: '80vw', top: '10vh', maxHeight: '80vh',
      }}
    >
      <ModalHeader className="d-flex">
        <h4 className="flex-fill">{discussion.title}</h4>
        <a href="#" onClick={handleCloseModalClick}>
          <i className="fa fa-times fa-lg" />
        </a>
      </ModalHeader>

      <ModalBody className="position-relative d-flex flex-column p-0" style={{ maxHeight: '100%' }}>

        {comments.length > 0 ? (
          <div
            className="flex-fill overflow-auto p-3"
            ref={commentListRef}
            onScroll={handleCommentListScroll}
          >

            {discussionCommentsQuery.hasNextPage ? (
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-secondary"
                  disabled={discussionCommentsQuery.isFetchingNextPage}
                  onClick={() => discussionCommentsQuery.fetchNextPage()}
                >
                  {__('Load more comments')}
                </button>
              </div>
            ) : null}

            {discussionCommentsQuery.isLoading || discussionCommentsQuery.isFetchingNextPage ? (
              <div className="d-flex justify-content-center py-5">
                <Spinner color="info" />
              </div>
            ) : null}

            {comments.map((comment) => (
              <div key={comment.id} className="my-4">
                <DiscussionComment
                  comment={comment}
                  replies={comment.replies}
                  isLocked={discussion.locked}
                />
              </div>
            ))}

            <div ref={chatBottomRef} />
          </div>
        ) : (
          <div className="flex-fill d-flex align-items-center justify-content-center p-4">
            <div className="text-muted">
              {__('No comments')}
            </div>
          </div>
        )}

        <div className="p-3">
          <DiscussionCommentForm
            discussionId={discussion.id}
            onSubmit={handleCommentSubmit}
            isLocked={discussion.locked}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DiscussionModal;
