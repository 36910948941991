import { useQuery, UseQueryResult } from 'react-query';
import { findVideoSprites, FindVideoSpritesResponse } from '../../../api/videos/[videoId]/sprites';
import * as Atlas from '../../../types/Atlas';

const useFindVideoSpritesQuery = (
  videoId: Atlas.VideoID,
  options?: { staleTime?: number; enabled?: boolean; },
): UseQueryResult<FindVideoSpritesResponse> => (
  useQuery(
    ['videos', videoId, 'sprites'],
    () => findVideoSprites({ params: { videoId } }),
    options,
  )
);

export default useFindVideoSpritesQuery;
