import { useMutation, UseMutationResult } from 'react-query';
import { queryClient } from '../../withQueryClient';
import { associateReflectionForm, AssociateReflectionFormArguments, AssociateReflectionFormResponse } from '../../../api/reflections/[reflectionId]/forms/[formId]/associate';

const useAssociateReflectionFormMutation = (): UseMutationResult<
AssociateReflectionFormResponse, unknown, AssociateReflectionFormArguments, unknown
> => {
  const mutation = useMutation(associateReflectionForm, {
    onSuccess: async (_, args) => {
      await queryClient.invalidateQueries(['reflections', args.params.reflectionId]);
    },
  });

  return mutation;
};

export default useAssociateReflectionFormMutation;
