import React, { useState } from 'react';
import { isNumber } from 'lodash-es';
import useCreateVideoCommentMutation from '../../../common/hooks/api/videos/useCreateVideoCommentMutation';
import * as Atlas from '../../../common/types/Atlas';
import useVideoPosition from '../../jwplayer/useVideoPosition';
import NewCommentResourceLink from './video-comment-options/NewCommentResourceLink';
import NewMediaComment from './video-comment-options/NewMediaComment';
import RouterPrompt from '../../../common/components/RouterPrompt';
import { CommentPrivacyButtonDropdown } from './CommentPrivacyButtonDropdown';
import { msToTimestamp } from '../../../common/utils/utils';
import { CommentTimestampButtonDropdown } from './CommentTimestampButtonDropdown';

const VideoCommentForm: React.FC<{
  videoId: Atlas.VideoID;
}> = (props) => {
  const { videoId } = props;

  const { getPosition } = useVideoPosition();

  const createComment = useCreateVideoCommentMutation();
  const [comment, setComment] = useState('');
  const [privacy, setPrivacy] = useState(Atlas.CommentPrivacy.Public);
  const [start, setStart] = useState<number | undefined>();
  const [mediaStart, setMediaStart] = useState<number | undefined>();

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (createComment.isLoading) { return; }

    const { value } = event.currentTarget;
    setComment(value);

    if (getPosition && value) {
      setStart((prevStart) => prevStart ?? Math.round(getPosition()));
    } else {
      setStart(undefined);
    }
  };

  const calculateDuration = (startPosition: number) => {
    if (!Number.isFinite(startPosition)) { return; }
    if (!getPosition) { return undefined; }

    const end = Math.round(getPosition());

    return Math.max(end - startPosition, 0);
  };

  const trimmedComment = comment.trim();
  const hasUnsavedComment = trimmedComment.length >= 1;

  const handleSubmitClick = () => {
    if (!hasUnsavedComment) { return; }

    const duration = isNumber(start) ? calculateDuration(start) : undefined;

    void createComment.mutateAsync({
      params: { videoId },
      body: {
        type: Atlas.CommentType.Text,
        body: trimmedComment,
        start,
        duration,
        privacy,
      },
    }).then(() => {
      setComment('');
      setStart(undefined);
    });
  };

  return (
    <div className="tw-p-0 tw-bg-base-100 tw-shadow tw-px-4 tw-py-2 tw-rounded-xl">
      <RouterPrompt
        when={hasUnsavedComment}
        message={__('You have unsaved changes on this page.')}
      />

      <div className="tw-flex tw-gap-2 lg:tw-gap-4 tw-items-center tw-my-2">
        <textarea
          style={{ height: '1rem' }}
          className="tw-textarea !tw-border-0 !tw-shadow-none tw-flex-grow"
          placeholder={__('Type your comment')}
          value={comment}
          onChange={handleCommentChange}
        />

        <button
          type="button"
          className="tw-btn tw-btn-sm xs:tw-btn-md tw-btn-success tw-btn-circle tw-shadow lg:tw-shadow-none"
          onClick={handleSubmitClick}
        >
          {createComment.isLoading ? (
            <span className="tw-loading" />
          ) : (
            <i className="fa fa-paper-plane tw-text-white" />
          )}
        </button>
      </div>

      <div className="tw-flex tw-flex-wrap tw-gap-2">
        <CommentTimestampButtonDropdown
          timestamp={start}
          onUpdateClick={() => setStart(Math.round(getPosition?.() ?? 0))}
          onRemoveClick={() => setStart(undefined)}
        />

        <CommentPrivacyButtonDropdown
          privacy={privacy}
          onChange={(nextPrivacy) => setPrivacy(nextPrivacy)}
        />

        {(() => {
          const updateMediaStartPosition = () => {
            if (getPosition) {
              setMediaStart(Math.round(getPosition()));
            }
          };

          const createNewComment = async (commentArgs?: Partial<Atlas.Comment>) => {
            const duration = isNumber(mediaStart) ? calculateDuration(mediaStart) : undefined;

            const response = await createComment.mutateAsync({
              params: { videoId },
              body: {
                body: '',
                ...commentArgs,
                start: mediaStart,
                duration,
                privacy,
              },
            });

            setComment('');
            setMediaStart(undefined);

            return response.data;
          };

          return (
            <>
              <NewMediaComment
                onAttachClick={updateMediaStartPosition}
                onStart={async (commentArgs) => {
                  const comment = await createNewComment({
                    ...commentArgs,
                    type: Atlas.CommentType.Media,
                    synchronicity: Atlas.CommentSynchronicity.Pause,
                  });

                  return comment.id;
                }}
              />

              <NewCommentResourceLink
                className="tw-btn tw-rounded-full tw-btn-block tw-btn-xs"
                initialBody={comment}
                onClick={updateMediaStartPosition}
                onSave={async ({ body, resource_links }) => {
                  await createNewComment({
                    type: Atlas.CommentType.Text,
                    body: body?.trim() ?? '',
                    resource_links,
                  });
                }}
              >
                <i className="fa fa-link tw-mr-2 tw-text-xs" />
                {__('Link Resource')}
              </NewCommentResourceLink>
            </>
          );
        })()}
      </div>
    </div>
  );
};

export default VideoCommentForm;
