import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import MilestoneListItem from './MilestoneListItem';

interface MilestonesListProps {
  milestones: Atlas.Milestone[];
  groupId: Atlas.GroupID;
  onDeleteClick: (milestoneId: Atlas.MilestoneIdentifier) => void;
}

const MilestonesList = (props: MilestonesListProps): JSX.Element => {

  return(
    <div>
      {props.milestones.map(milestone => (
        <div key={milestone.id}>
          <MilestoneListItem 
            milestone={milestone}
            groupId={props.groupId}
            onDeleteClick={props.onDeleteClick} 
          />
        </div>
      ))}
    </div>
  );
};

export default MilestonesList;