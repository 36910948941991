import React, {
  ComponentProps, useMemo, useState,
} from 'react';
import useCreateReflectionShareMutation from '../../../../common/hooks/api/reflections/useCreateReflectionShareMutation';
import useFindReflectionsShareQuery from '../../../../common/hooks/api/reflections/useFindReflectionSharesQuery';

import * as Atlas from '../../../../common/types/Atlas';
import SearchSharable from './SearchSharable';

interface ShareReflectionFormProps {
  onBack: () => void;
  reflectionId: Atlas.ReflectionID;
}

type OnResultsSelect = ComponentProps<typeof SearchSharable>['onSelect'];

interface ShareReflectionFormState {
  selected: Array<Parameters<OnResultsSelect>[0]>;
  shared: Array<Parameters<OnResultsSelect>[0]>;
  message: string;
  saving: boolean;
}

const ShareReflectionForm = (props: ShareReflectionFormProps) => {
  const { reflectionId, onBack } = props;

  const [state, setState] = useState<ShareReflectionFormState>({
    selected: [],
    shared: [],
    message: '',
    saving: false,
  });

  const createReflectionShare = useCreateReflectionShareMutation();
  const findReflectionShares = useFindReflectionsShareQuery({ reflectionId });

  const handleResultSelect: OnResultsSelect = (result) => {
    setState((s) => ({
      ...s,
      resultsOpen: false,
    }));

    setState((s) => ({
      ...s,
      selected: [
        ...s.selected,
        result,
      ],
    }));
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;

    setState((s) => ({ ...s, message: value }));
  };

  const invalid = state.selected.length === 0;

  const handleConfirmClick = () => {
    if (invalid) { return; }
    if (state.saving) { return; }

    setState((s) => ({
      ...s,
      saving: true,
    }));

    void Promise.all(state.selected.map(async (result) => {
      switch (result.model) {
        case 'User': {
          await createReflectionShare.mutateAsync({
            params: { reflectionId },
            body: {
              shareeUserId: result.data.id,
              message: state.message,
            },
          });
          break;
        }

        case 'Group': {
          await createReflectionShare.mutateAsync({
            params: { reflectionId },
            body: {
              shareeGroupId: result.data.id,
              message: state.message,
            },
          });
          break;
        }

        default: break;
      }

      setState((s) => ({
        ...s,
        shared: [
          ...s.shared,
          result,
        ],
      }));
    })).finally(() => {
      setState((s) => ({
        ...s,
        saving: false,
      }));
      onBack();
    });
  };

  const {
    userShares,
    groupShares,
  } = useMemo(() => {
    if (!findReflectionShares.isSuccess) {
      return { userShares: [], groupShares: [] };
    }

    const shares = findReflectionShares.data.data;

    return shares.reduce<{
      userShares: Atlas.UserID[];
      groupShares: Atlas.GroupID[];
    }>((acc, share) => {
      if (share.sharee_user_id) {
        acc.userShares.push(share.sharee_user_id);
      }

      if (share.sharee_group_id) {
        acc.groupShares.push(share.sharee_group_id);
      }

      return acc;
    }, { userShares: [], groupShares: [] });
  }, [findReflectionShares.data?.data, findReflectionShares.isSuccess]);

  const excludeGroupIds = useMemo(() => {
    const selectedGroups = state.selected.filter((result) => result.model === 'Group').map((result) => result.data.id);

    return [
      ...selectedGroups,
      ...groupShares,
    ];
  }, [groupShares, state.selected]);

  const excludeUserIds = useMemo(() => {
    const selectedUsers = state.selected.filter((result) => result.model === 'User').map((result) => result.data.id);

    return [
      ...selectedUsers,
      ...userShares,
    ];
  }, [userShares, state.selected]);

  return (
    <div>
      <div className="tw-mb-6">
        <SearchSharable
          reflectionId={reflectionId}
          excludeGroupIds={excludeGroupIds}
          excludeUserIds={excludeUserIds}
          onSelect={handleResultSelect}
        />
      </div>

      {state.selected.length === 0 ? (
        <p className="tw-text-gray-400 tw-text-center tw-my-10 tw-text-lg">
          {__('Add a user or group')}
        </p>
      ) : (
        <>
          <p className="tw-text-gray-600 tw-font-bold tw-my-4 tw-italic">
            {__('Pending shares')}
          </p>

          <div className="tw-flex tw-flex-col tw-gap-2 tw-my-4">
            {state.selected.map((result, i) => {
              const isShared = state.shared.includes(result);

              const userThumbPlaceholder = '/assets/img/reflections/display-picture-blue.png';
              const groupThumbPlaceholder = '/assets/img/reflections/display-picture-group-blue.png';

              const {
                key,
                thumb,
                name,
                type,
                parentName,
              } = (() => {
                switch (result.model) {
                  case 'Group': {
                    const group = result.data;

                    return {
                      key: `group-${group.id}`,
                      thumb: group.avatar_s3uri?.url ?? groupThumbPlaceholder,
                      name: group.name,
                      type: __('Group'),
                      parentName: undefined,
                    };
                  }

                  case 'User': {
                    const user = result.data;

                    return {
                      key: `user-${user.id}`,
                      thumb: user.avatar_s3uri?.url ?? userThumbPlaceholder,
                      name: `${user.first_name} ${user.last_name}`,
                      type: __('User'),
                      parentName: user.organization.name,
                    };
                  }

                  default: return {
                    key: `unknown-${i}`,
                    thumb: userThumbPlaceholder,
                    name: __('Unknown'),
                    type: undefined,
                    parentName: undefined,
                  };
                }
              })();

              const handleRemoveClick = () => {
                setState((s) => ({
                  ...s,
                  selected: s.selected.filter((ssr) => ssr !== result),
                }));
              };

              return (
                <div
                  key={key}
                  className="tw-grid tw-grid-cols-[auto_1fr_1fr_1fr_auto] tw-gap-2 tw-items-center"
                >
                  <div className="tw-avatar">
                    <div className="tw-w-10 tw-rounded-full">
                      <img alt={name} src={thumb} />
                    </div>
                  </div>

                  <div className="tw-truncate">
                    {name}
                  </div>

                  <div className="tw-truncate">
                    {type}
                  </div>

                  <div className="tw-truncate">
                    {parentName}
                  </div>

                  <div>
                    {isShared ? (
                      <i className="fa fa-check" />
                    ) : (
                      <button type="button" className="tw-btn tw-btn-xs tw-btn-block" onClick={handleRemoveClick}>
                        {__('Remove')}
                      </button>
                    )}
                  </div>
                </div>
              );
            })}

            <textarea
              className="tw-textarea tw-textarea-bordered tw-w-full tw-mt-2"
              placeholder={__("Enter a message...")}
              value={state.message}
              onChange={handleMessageChange}
            />
          </div>
        </>
      )}

      <div className="tw-flex tw-justify-between tw-mt-6">
        <button type="button" className="tw-btn" onClick={onBack}>
          {__('Back')}
        </button>

        <button
          type="button"
          className="tw-btn tw-btn-primary"
          disabled={invalid}
          onClick={handleConfirmClick}
        >
          {state.saving ? <span className="tw-loading" /> : null}
          {__('Confirm')}
        </button>
      </div>
    </div>
  );
};

export default ShareReflectionForm;
