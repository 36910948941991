import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../../../api-parser';
import ApiError from '../../../error';
import { generateFetchHeaders, jsonHeaders, Metadata, PaginationParams, searchParams } from '../../../routes';

export const groupPagesRoute = (args: {
  groupId: Atlas.GroupID;
}): string => `/api/v0/groups/${args.groupId}/pages`;

export type FindGroupPagesParams = PaginationParams & {
  groupId: Atlas.GroupID;
  admin?: boolean;
};

export interface FindGroupPagesArguments {
  params: FindGroupPagesParams;
}

export interface FindGroupPagesResponse {
  data: Atlas.Page[];
  metadata: Metadata;
}

export const findGroupPages = async (
  args: FindGroupPagesArguments,
): Promise<FindGroupPagesResponse> => {
  const { params } = args;

  const url = [
    groupPagesRoute({ groupId: params.groupId }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
      admin: typeof params.admin === 'boolean' ? String(params.admin) : undefined,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findGroupPages');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.Page>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface CreateGroupPageParams {
  groupId: Atlas.GroupID;
}

export interface CreateGroupPageBody {
  title?: Atlas.PageTitle;
  type?: Atlas.PageType;
  body?: Atlas.PageBody;
  publish?: Atlas.PublishStatus;
  jsFilter?: Atlas.JSFilter;
  createRole?: Atlas.MemberRole;
}

export interface CreateGroupPageArguments {
  params: CreateGroupPageParams;
  body: CreateGroupPageBody;
}

export interface CreateGroupPageResponse {
  data: Atlas.Page;
}

export const createGroupPage = async (
  args: CreateGroupPageArguments,
): Promise<CreateGroupPageResponse> => {
  const { groupId } = args.params;

  const url = groupPagesRoute({ groupId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      title: args.body.title,
      type: args.body.type,
      body: args.body.body,
      publish: args.body.publish,
      js_filter: args.body.jsFilter,
      create_role: args.body.createRole,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'createGroupPage');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Page>(body.data);

  return { data };
};

export const reorderGroupPagesRoute = (args: {
  groupId: Atlas.GroupID;
}): string => `/api/v0/groups/${args.groupId}/pages/reorder`;

export interface ReorderGroupPagesParams {
  groupId: Atlas.GroupID;
}

export interface ReorderGroupPagesBody {
  pageIds: Atlas.PageID[];
}

export interface ReorderGroupPagesArguments {
  params: ReorderGroupPagesParams;
  body: ReorderGroupPagesBody;
}

export type ReorderGroupPagesResponse = void;

export const reorderGroupPages = async (
  args: ReorderGroupPagesArguments,
): Promise<ReorderGroupPagesResponse> => {
  const { groupId } = args.params;

  const url = reorderGroupPagesRoute({ groupId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      page_ids: args.body.pageIds,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'reorderGroupPages');
  }

  return undefined;
};
