import React from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Atlas from '../../../../common/types/Atlas';

interface MilestonesListHeaderProps {
  milestonesCount: number | null;
  groupId: Atlas.GroupID;
  sorting: boolean;
  toggleSorting: () => void;
}

const MilestonesListHeader = (props: MilestonesListHeaderProps): JSX.Element => {
  const params = useParams<{ containerId: string; }>();
  const containerId = Number(params.containerId);

  return (
    <div className="tw-flex tw-flex-row tw-my-2 tw-justify-between">
      <div className="tw-mb-3 tw-mx-1 tw-flex tw-items-center">
        {Number.isInteger(props.milestonesCount) ? (
          <span className="tw-text-gray-500">
            {props.milestonesCount} {props.milestonesCount === 1 ? __('milestone') : __('milestones')}
          </span>
        ) : null}
      </div>

      <div className="tw-mb-3">
        {props.sorting ? (
          <button
            className='tw-btn tw-btn-sm tw-shadow tw-gap-2 tw-btn-primary'
            onClick={props.toggleSorting}
          >
            {__("Done")}
          </button>
        ) : (
          <div className="tw-flex tw-justify-end tw-gap-3">
            <button
              className='tw-btn tw-btn-sm tw-shadow tw-gap-2 tw-btn-primary'
              onClick={props.toggleSorting}
            >
              <i className="fa fa-sort"></i>
              {__("Reorder")}
            </button>
            <Link className="tw-btn tw-btn-success tw-btn-sm tw-shadow tw-gap-2" to={`/pathways_management/${containerId}/templates/${props.groupId}/milestones/new`}>
              <i className="fa fa-plus"></i>
              {__('New Milestone')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MilestonesListHeader;
