import React, { ComponentProps, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useClickAway } from 'react-use';
import * as Atlas from '../../../../common/types/Atlas';
import { sameWidthModifier } from '../../../search/global-search/utils';
import SearchResults from './SearchResults';

const filters = [{
  value: undefined,
  label: __('All'),
}, {
  value: 'User',
  label: __('Users'),
}, {
  value: 'Group',
  label: __('Groups'),
}] as const;

type OnSelect = ComponentProps<typeof SearchResults>['onSelect'];

interface SearchSharableProps {
  reflectionId: Atlas.ReflectionID;
  excludeGroupIds: Atlas.GroupID[];
  excludeUserIds: Atlas.UserID[];
  onSelect: OnSelect;
}

interface SearchSharableState {
  filter: typeof filters[number]['value'];
  query: string;
  resultsOpen: boolean;
}

const SearchSharable = (props: SearchSharableProps) => {
  const {
    reflectionId,
    excludeGroupIds,
    excludeUserIds,
    onSelect,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'preventOverflow',
        options: {
          rootBoundary: 'viewport',
        },
      },
      sameWidthModifier,
    ],
  });

  const [state, setState] = useState<SearchSharableState>({
    filter: undefined,
    query: '',
    resultsOpen: false,
  });

  useClickAway(containerRef, () => {
    setState((s) => {
      if (!s.resultsOpen) { return s; }
      return { ...s, resultsOpen: false };
    });
  });

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setState((s) => ({ ...s, query: value }));
  };

  const handleQueryFocus = () => {
    setState((s) => ({ ...s, resultsOpen: true }));
  };

  const handleSelect: OnSelect = (result) => {
    onSelect(result);
    setState((s) => ({ ...s, resultsOpen: false }));
  };

  const activeFilter = filters.find((filter) => filter.value === state.filter);

  return (
    <div className="tw-grid tw-grid-cols-[1fr_auto] tw-gap-2 tw-text-base">
      <div ref={containerRef}>
        <div
          ref={setReferenceElement}
          className="tw-relative"
        >
          <i
            className="fa fa-search tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-mx-4"
          />
          <input
            className="ltr:tw-pl-10 tw-bg-base rtl:tw-pr-10 tw-input tw-w-full tw-placeholder-tw-base-300 focus:tw-outline-none tw-input-bordered"
            placeholder={__('Search')}
            value={state.query}
            onChange={handleQueryChange}
            onFocus={handleQueryFocus}
          />
        </div>

        {state.resultsOpen ? (
          <div ref={setPopperElement} className="tw-z-50 tw-flex tw-flex-col tw-max-h-80" style={styles.popper} {...attributes.popper}>
            <SearchResults
              reflectionId={reflectionId}
              searchQuery={state.query}
              excludeGroupIds={excludeGroupIds}
              excludeUserIds={excludeUserIds}
              scope={state.filter}
              onSelect={handleSelect}
            />
          </div>
        ) : null}
      </div>

      <div>
        <div className="tw-dropdown tw-dropdown-end">
          <label tabIndex={0} className="tw-btn tw-gap-1" data-testid="search-filter-button">
            {activeFilter?.label ?? __('Unknown')}

            <i className="fa fa-caret-down" />
          </label>

          <div
            className="tw-dropdown-content tw-iris-dropdown"
          >
            <div className="tw-iris-dropdown-box">
              <ul tabIndex={0}>
                {filters.map((filter) => {
                  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    event.currentTarget.blur();
                    setState((s) => ({ ...s, filter: filter.value }));
                  };

                  return (
                    <li key={filter.value ?? ''}>
                      <button type="button" onClick={handleClick}>
                        {filter.label}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSharable;
