import * as Atlas from '../../types/Atlas';
import { inRange } from '../../utils/utils';
import { parseModelObjectArray } from '../api-parser';
import ApiError from '../error';
import { jsonHeaders, Metadata, PaginationParams, searchParams } from '../routes';

const aiModelsRoute = (): string => '/api/v0/ai_models';

export type FindAiModelsParams = PaginationParams;

export interface FindAiModelsArguments {
  params?: FindAiModelsParams;
}

export interface FindAiModelsResponse {
  data: Atlas.AIModel[];
  metadata: Metadata;
}

export const findAiModels = async (
  args?: FindAiModelsArguments
): Promise<FindAiModelsResponse> => {
  const { params = {} } = args || {};

  const url = [
    aiModelsRoute(),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findAiModels');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.AIModel>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};
