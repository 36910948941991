import * as Atlas from '../../../../common/types/Atlas';

export const isButtonMilestone = (type: Atlas.MilestoneType): type is { button: Atlas.ButtonMilestone } => 'button' in type;
export const isPageMilestone = (type: Atlas.MilestoneType): type is { visit_page: Atlas.PageMilestone } => 'visit_page' in type;
export const isGuideMilestone = (type: Atlas.MilestoneType): type is { link_guide: Atlas.PageMilestone } => 'link_guide' in type;
export const isReflectionMilestone = (type: Atlas.MilestoneType): type is { watch_reflection: Atlas.ReflectionMilestone } => 'watch_reflection' in type;
export const isAssignmentSubmitMilestone = (type: Atlas.MilestoneType): type is { submit_to_assignment: Atlas.AssignmentMilestone } => 'submit_to_assignment' in type;
export const isAssignmentCompleteMilestone = (type: Atlas.MilestoneType): type is { complete_assignment: Atlas.AssignmentMilestone }=> 'complete_assignment' in type;
export const isDiscussionMilestone = (type: Atlas.MilestoneType): type is { discuss: Atlas.DiscussionMilestone } => 'discuss' in type;
export const isAttachmentMilestone = (type: Atlas.MilestoneType): type is { download_attachment: Atlas.AttachmentMilestone } => 'download_attachment' in type;
export const isFormMilestone = (type: Atlas.MilestoneType): type is { submit_insight: Atlas.FormMilestone } => 'submit_insight' in type;
export const isFeedbackMilestone = (type: Atlas.MilestoneType): type is { submit_feedback: Atlas.FeedbackMilestone } => 'submit_feedback' in type;
export const isLinkReflectionMilestone = (type: Atlas.MilestoneType): type is { link_reflection: Atlas.PageMilestone } => 'link_reflection' in type;

export const getMilestoneLabel = (milestone: Atlas.MilestoneType): string => {
  return Object.keys(milestone)[0];
}

export const getFormattedMilestoneLabel = (type: Atlas.MilestoneType): string => {
  if (isButtonMilestone(type)) {
    return __("Button");
  } else if (isPageMilestone(type)) {
    return __("Visit page");
  } else if (isGuideMilestone(type)) {
    return __("Link guide");
  } else if (isReflectionMilestone(type)) {
    return __("Watch reflection");
  } else if (isAssignmentSubmitMilestone(type)) {
    return __("Submit to assignment");
  } else if (isAssignmentCompleteMilestone(type)) {
    return __("Complete assignment");
  } else if (isDiscussionMilestone(type)) {
    return __("Discussion");
  } else if (isAttachmentMilestone(type)) {
    return __("Download attachment");
  } else if (isFormMilestone(type)) {
    return __("Submit insight");
  } else if (isFeedbackMilestone(type)) {
    return __("Submit feedback");
  } else if (isLinkReflectionMilestone(type)) {
    return __("Link reflection");
  }
  
  throw new Error(__('Unknown milestone type'));
}

export const getMilestoneResourceString = (type: Atlas.MilestoneType): string => {
  if (isButtonMilestone(type)) {
    throw new Error(__('No resource type for button milestone'));
  } else if (isPageMilestone(type)) {
    return __('page');
  } else if (isGuideMilestone(type)) {
    return __('guide page');
  } else if (isReflectionMilestone(type)) {
    return __('reflection');
  } else if (isAssignmentSubmitMilestone(type) || isAssignmentCompleteMilestone(type)) {
    return __('assignment');
  } else if (isDiscussionMilestone(type)) {
    return __('discussion');
  } else if (isAttachmentMilestone(type)) {
    return __('attachment');
  } else if (isFormMilestone(type)) {
    return __('insight');
  } else if (isLinkReflectionMilestone(type)) {
    return __('reflection page');
  }

  throw new Error(__('Unknown milestone type'));
}

export const hasValidResourceID = (type: Atlas.MilestoneType): boolean => {
  if (isPageMilestone(type)) {
    return type.visit_page?.page_id != null;
  } else if (isGuideMilestone(type)) {
    return type.link_guide?.page_id != null;
  } else if (isReflectionMilestone(type)) {
    return type.watch_reflection?.reflection_id != null;
  } else if (isAssignmentSubmitMilestone(type)) {
    return type.submit_to_assignment?.assignment_id != null;
  } else if (isAssignmentCompleteMilestone(type)) {
    return type.complete_assignment?.assignment_id != null
  } else if (isDiscussionMilestone(type)) {
    return type.discuss?.discussion_id != null;
  } else if (isAttachmentMilestone(type)) {
    return type.download_attachment?.attachment_id != null;
  } else if (isFormMilestone(type)) {
    return type.submit_insight?.form_id != null;
  } else if (isLinkReflectionMilestone(type)) {
    return type.link_reflection?.page_id != null;
  }

  return false;
}


export function createEmptyButtonMilestone(): { button: Atlas.ButtonMilestone } {
  return { button: { text: '' } };
}

export function createEmptyPageMilestone(): { visit_page: Atlas.PageMilestone } {
  return { visit_page: { page_id: undefined } };
}

export function createEmptyGuideMilestone(): { link_guide: Atlas.PageMilestone } {
  return { link_guide: { page_id: undefined }};
}

export function createEmptyReflectionMilestone(): { watch_reflection: Atlas.ReflectionMilestone } {
  return { watch_reflection: { reflection_id: undefined } };
}

export function createEmptySubmitAssignmentMilestone(): { submit_to_assignment: Atlas.AssignmentMilestone } {
  return { submit_to_assignment: { assignment_id: undefined } };
}

export function createEmptyCompleteAssignmentMilestone(): { complete_assignment: Atlas.AssignmentMilestone } {
  return { complete_assignment: { assignment_id: undefined } };
}

export function createEmptyDiscussionMilestone(): { discuss: Atlas.DiscussionMilestone } {
  return { discuss: { discussion_id: undefined } };
}

export function createEmptyAttachmentMilestone(): { download_attachment: Atlas.AttachmentMilestone } {
  return { download_attachment: { attachment_id: undefined } };
}

export function createEmptyFormMilestone(): { submit_insight: Atlas.FormMilestone } {
  return { submit_insight: { form_id: undefined } };
}

export function createEmptyLinkReflectionMilestone(): { link_reflection: Atlas.PageMilestone } {
  return { link_reflection: { page_id: undefined } };
}

export function createFeedbackMilestone(): { submit_feedback: Atlas.FeedbackMilestone } {
  return { submit_feedback: { type: Atlas.FeedbackType.CoachSummary } };
}