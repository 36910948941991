import React from 'react';
import Spinner from '../../../../common/components/Spinner';

const GroupLoadingBanner = () => (
  <div className="tw-relative tw-object-cover tw-object-left tw-min-w-full tw-min-h-full tw-flex tw-items-center tw-justify-center">
    <Spinner color="info" />
  </div>
);

export default GroupLoadingBanner;
