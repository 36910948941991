import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Linkify from 'linkify-react';
import DiscussionMention from './Mention';
import * as Atlas from '../../../common/types/Atlas';
import RouterPrompt from '../../../common/components/RouterPrompt';
import { Button } from '../../../common/components/Button';
import { Row } from '../../../common/components/Row';
import { Col } from '../../../common/components/Col';

const a = /<a class="discussion-mention" data-user-id="(\d*)">(.*)<\/a>/;
const parseCommentMentions = (body: string): Array<React.ReactNode> => {
  const chunks = body.split(/(<a class="discussion-mention" data-user-id="\d*">.*?<\/a>)/);

  return chunks.map((chunk, i) => {
    const match = a.exec(chunk);
    if (!match) { return chunk; }
    const [, userId, userName] = match;

    return (
      <a key={`${userId}-${i}`}>
        {userName}
      </a>
    );
  });
};

interface EditableTextProps {
  discussionId: Atlas.DiscussionID;
  editing: boolean;
  value: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onSubmit: (value: string) => void;
}

const EditableText = (props: EditableTextProps): JSX.Element => {
  const {
    discussionId,
    editing,
    value,
    onFocus,
    onBlur,
    onSubmit,
  } = props;

  const [state, setState] = useState({ value });

  useEffect(() => {
    setState((s) => ({ ...s, value }));
  }, [value, editing]);

  if (!editing) {
    return (
      <span style={{ whiteSpace: 'pre-line' }}>
        <Linkify options={{ className: '!tw-link !tw-link-primary', target: '_blank', rel: 'noreferrer' }}>
          {parseCommentMentions(state.value ?? value)}
        </Linkify>
      </span>
    );
  }

  const handleChange = (nextValue: string) => {
    setState((s) => ({ ...s, value: nextValue }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(state.value);
  };

  return (
    <EditReplyComment>
      <RouterPrompt
        when={state.value !== value}
        message={__('You have unsaved changes on this page.')}
      />

      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <DiscussionMention
              autoFocus
              discussionId={discussionId}
              value={state.value}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </Col>
          <Col xs="auto" className="pl-2 d-flex">
            <Button type="submit">
              <i
                className="fa fa-paper-plane"
                style={{
                  color: '#2A6EBA',
                }}
              />
            </Button>
          </Col>
        </Row>
      </form>
    </EditReplyComment>
  );
};

const EditReplyComment = styled.div`
  width: 20rem;

  button {
    background-color: white !important;
    border: unset !important;
  }
}

`;
export default EditableText;
