import React, { useEffect, useState } from 'react';
import AssignmentSubmissions from '../../../../../modules/group-assignment/submissions/AssignmentSubmissions';
import GroupAssignmentLayout from '../layout';
import * as Atlas from '../../../../../common/types/Atlas';
import { GenericNumberOptionType } from '../../../../../common/types/Generic';
import Pagination from '../../../../../common/components/Pagination';
import { useQuery } from '../../../../../common/hooks/useQuery';
import { useParams } from 'react-router-dom';
import useGroupQuery from '../../../../../common/hooks/api/groups/useFindGroupQuery';
import useGroupAssignmentQuery from '../../../../../common/hooks/api/groups/useFindGroupAssignmentQuery';
import Spinner from '../../../../../common/components/Spinner';
import useFindAssignmentSubmissionsQuery from '../../../../../common/hooks/api/assignments/useFindAssignmentSubmissionsQuery';
import useDestroyAssignmentSubmissionMutation from '../../../../../common/hooks/api/assignments/useDestroyAssignmentSubmissionMutation';
import DeletePrompt from '../../../../../common/components/DeletePrompt';

interface ShowGroupAssignmentPageState {
  filter: {
    userId?: GenericNumberOptionType;
    complete?: { label: string; value: Atlas.SubmissionComplete; };
  }
}

interface DeleteSubmissionPromptOptions {
  prompt?: string;
  typeToConfirm?: boolean;
  selectedSubmissionId?: Atlas.AssignmentSubmissionID;
}

const ShowGroupAssignmentPage = (): JSX.Element => {
  const params = useParams<{
    groupId: string;
    assignmentId: string;
  }>();

  const groupId = Number(params.groupId);
  const assignmentId = Number(params.assignmentId);

  const groupQuery = useGroupQuery(groupId);
  const group = groupQuery.data?.data;
  const groupLoading = groupQuery.isLoading;

  const groupAssignmentQuery = useGroupAssignmentQuery(groupId, assignmentId);
  const assignment = groupAssignmentQuery.data?.data;
  const assignmentLoading = groupAssignmentQuery.isLoading;

  const [getQuery, setQuery] = useQuery();

  const page = Number(getQuery('page') ?? 1),
        perPage = Number(getQuery('per_page') ?? 20);

  const [state, setState] = useState<ShowGroupAssignmentPageState>({
    filter: {},
  });

  const [deletePromptState, setDeletePromptState] = useState<DeleteSubmissionPromptOptions>(
    {
      prompt: undefined,
      typeToConfirm: false,
      selectedSubmissionId: undefined,
    }
  );

  const [showDeletePrompt, setShowDeletePrompt] = useState(false)

  const handleDeletePromptClosed = () => {
    setDeletePromptState(s => ({...s, prompt: undefined, typeToConfirm: false, selectedSubmissionId: undefined}));
    setShowDeletePrompt(false);
  }

  const deleteSubmissionMutation = useDestroyAssignmentSubmissionMutation(assignmentId);

  const handleDeleteClicked = (options: DeleteSubmissionPromptOptions) => {
    setShowDeletePrompt(true);
    setDeletePromptState(s => ({...s, ...options}));
  }

  const handleDleteSubmission = () => {
    if (!deletePromptState.selectedSubmissionId) return;

    deleteSubmissionMutation.mutateAsync({
      params: {
        assignmentId: assignmentId,
        assignmentSubmissionId: deletePromptState.selectedSubmissionId,
      }
    });

    setDeletePromptState(s => ({...s, prompt: undefined, typeToConfirm: false, selectedSubmissionId: undefined}));
    setShowDeletePrompt(false);
  }

  const submissionsQuery = useFindAssignmentSubmissionsQuery(
    assignmentId,
    state.filter.complete?.value,
    state.filter.userId?.value,
    page,
    perPage,
  );

  const totalPages = submissionsQuery.data?.metadata.total_pages;

  useEffect(() => {
    if (typeof totalPages !== 'number') { return; }
    if (page <= totalPages) { return; }
    const nextPage = Math.max(totalPages, 1);
    setQuery({ page: String(nextPage) });
  }, [page, totalPages]);

  const reloadSubmisions = async () => {
    await submissionsQuery.refetch();
  }

  const handleFilterChange = (name: string, option: GenericNumberOptionType) => {
    setState(s => ({ ...s, filter: {
      ...s.filter,
      [name]: option
    }}));
  };

  const handlePageChange = (page: number) => {
    setQuery({ page: String(page) });
  };

  const filteredSubmissions =
    submissionsQuery.data?.data.filter(
      (submission) => submission.complete !== Atlas.SubmissionComplete.Draft
    ) ?? [];

  const isLoading =
    groupLoading ||
    assignmentLoading ||
    submissionsQuery.isLoading ||
    submissionsQuery.isRefetching;

  return (
    <GroupAssignmentLayout>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center my-5">
          <Spinner color="info" />
        </div>
      ) : null}

      {!isLoading && group && assignment ? (
        <AssignmentSubmissions
          group={group}
          assignment={assignment}
          submissions={filteredSubmissions}
          submissionsLoading={submissionsQuery.isLoading}
          totalSubmissions={filteredSubmissions.length}
          reloadSubmissions={reloadSubmisions}
          filter={state.filter}
          onFilterChange={handleFilterChange}
          onDeleteSubmission={handleDeleteClicked} />
      ) : null}

      <DeletePrompt
        showPrompt={showDeletePrompt}
        prompt={deletePromptState.prompt}
        typeToConfirm={deletePromptState.typeToConfirm}
        onClose={handleDeletePromptClosed}
        onDelete={handleDleteSubmission} />

      {totalPages && totalPages > 1 ? (
        <Pagination
          page={page}
          totalPages={totalPages}
          onPageChange={handlePageChange} />
      ) : null}
    </GroupAssignmentLayout>
  );
};

export default ShowGroupAssignmentPage;
