import React from 'react';
import PropTypes from 'prop-types';
import FormElement from '../reflections/FormElement';

const FormElements = props => {
  const form = props.formJson ? JSON.parse(props.formJson) : props.form;

  return form.elements.map(fe => (
    <FormElement key={fe.field.name} formElement={fe} />
  ));
};

FormElements.propTypes = {
  form: PropTypes.object,
  formJson: PropTypes.string
};

export default FormElements;
