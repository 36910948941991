import { useQuery, UseQueryResult } from 'react-query';
import { findGroup, FindGroupResponse } from '../../../api/groups/_legacy';
import * as Atlas from '../../../types/Atlas';

const useFindGroupQuery = (
  groupId: Atlas.GroupID,
): UseQueryResult<FindGroupResponse> => (
  useQuery(['groups', groupId], () => findGroup({ params: { groupId } }))
);

export default useFindGroupQuery;
