import * as Atlas from '../../types/Atlas';
import { inRange } from '../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../api-parser';
import ApiError from '../error';
import {
  jsonHeaders, Metadata, PaginationParams, searchParams,
} from '../routes';

export const reflectionsRoute = (): string => '/api/v0/reflections';

export const reflectionRoute = (args: {
  reflectionId: Atlas.ReflectionID
}): string => `/api/v0/reflections/${args.reflectionId}`;

export type FindReflectionsParams = {
  userId?: Atlas.UserID;
  search?: string;
} & PaginationParams;

export interface FindReflectionsArguments {
  params?: FindReflectionsParams;
}

export interface FindReflectionsResponse {
  data: Atlas.CondensedReflection[];
  metadata: Metadata;
}

export const findReflections = async (
  args?: FindReflectionsArguments,
): Promise<FindReflectionsResponse> => {
  const { params = {} } = args || {};

  const url = [
    reflectionsRoute(),
    searchParams({
      page: params.page,
      per_page: params.per_page,
      user_id: params.userId,
      search: params.search,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'findReflections');
  }

  const body = await response.json() as { data: object[], metadata: unknown };
  const data = parseModelObjectArray<Atlas.CondensedReflection>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface FindReflectionParams {
  reflectionId: Atlas.ReflectionID;
}

export interface FindReflectionArguments {
  params: FindReflectionParams;
}

export interface FindReflectionResponse {
  data: Atlas.ExpandedReflection;
}

export const findReflection = async (
  args: FindReflectionArguments,
): Promise<FindReflectionResponse> => {
  const { reflectionId } = args.params;

  const url = reflectionRoute({ reflectionId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findReflection');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.ExpandedReflection>(body.data);

  return { data };
};
