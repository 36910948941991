import { useQuery, UseQueryResult } from 'react-query';
import { FindGroupPagesParams, FindGroupPagesResponse, findGroupPages } from '../../../api/groups/[groupId]/pages';

const useFindGroupPagesQuery = (
  params: FindGroupPagesParams,
  options?: { enabled?: boolean; },
): UseQueryResult<FindGroupPagesResponse> => {
  const { groupId, ...otherParams } = params;

  return useQuery(
    ['groups', groupId, 'pages', otherParams],
    () => findGroupPages({ params }),
    { staleTime: 1000, ...options },
  );
};

export default useFindGroupPagesQuery;
