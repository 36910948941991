import { nanoid } from 'nanoid';
import * as Atlas from '../../../common/types/Atlas';

export const allFormElementOptions = {
  text: {
    type: 'text',
    name: __('Text'),
    description: __('Create a text response question'),
    icon: 'icon-quote-left',
    defaultValue: {
      type: { text: 0 },
    } as Partial<Atlas.FormField>,
  },
  multiple_choice: {
    type: 'multiple_choice',
    name: __('Multiple Choice'),
    description: __('Create a question with multiple choice answer that you define'),
    icon: 'icon-check',
    defaultValue: {
      type: {
        multiple_choice: {
          allow_multiple: true,
          allow_other: false,
          choices: [] as Atlas.Choice[],
        },
      },
    } as Partial<Atlas.FormField>,
  },
  rating: {
    type: 'rating',
    name: __('Rating Scale'),
    description: __('Create a question to be answered with a rating scale that you define'),
    icon: 'icon-dashboard',
    defaultValue: {
      type: { rating: {} },
    } as Partial<Atlas.FormField>,
  },
  counter: {
    type: 'counter',
    name: __('Counter'),
    description: __('Create a question to be counted'),
    icon: 'icon-bar-chart',
    defaultValue: {
      type: {
        counter: {
          choices: [] as Atlas.Choice[],
        },
      },
    } as Partial<Atlas.FormField>,
  },
  timer: {
    type: 'timer',
    name: __('Timer'),
    description: __('Create a question to be timed'),
    icon: 'icon-time',
    defaultValue: {
      type: {
        timer: {
          choices: [] as Atlas.Choice[],
        },
      },
    } as Partial<Atlas.FormField>,
  },
  attachment: {
    type: 'attachment',
    name: __('Attachment'),
    description: __('Attachment'),
    icon: 'fa fa-paperclip',
    defaultValue: {
      type: { attachment: 0 },
    } as Partial<Atlas.FormField>,
  },
  reflection: {
    type: 'reflection',
    name: __('Reflection'),
    description: __('Reflection'),
    icon: 'fa fa-video-camera',
    defaultValue: {
      type: { reflection: 0 },
    } as Partial<Atlas.FormField>,
  },
  url: {
    type: 'url',
    name: __('URL'),
    description: __('URL'),
    icon: 'fa fa-link',
    defaultValue: {
      type: { url: 0 },
    } as Partial<Atlas.FormField>,
  },
};

export const formElementOptions = [
  allFormElementOptions.text,
  allFormElementOptions.multiple_choice,
  allFormElementOptions.rating,
  allFormElementOptions.counter,
  allFormElementOptions.timer,
];

type FEOptionMap = Record<string, {
  type: string;
  name: string;
  description: string;
  icon: string;
  defaultValue: Partial<Atlas.FormField>;
}>;

export const formElementOptionsMap = formElementOptions.reduce<FEOptionMap>((acc, feo) => {
  acc[feo.type] = feo;

  return acc;
}, {});

export const generateFormElement = (
  type: keyof typeof allFormElementOptions,
): {
  field: Partial<Atlas.FormField>;
} => ({
  field: {
    name: nanoid(),
    allow_multiple: true,
    has_notes: true,
    ai_options: Atlas.FormFieldAIOptions.AiResponse,
    ai_settings: {
      reasoning_level: Atlas.AIReasoningLevel.Low
    },
    ...allFormElementOptions[type].defaultValue,
  },
});

export default formElementOptions;
