import React from 'react';
import { DropdownOpen, DropdownProps } from './DropdownOpen';

export const Dropdown: React.FC<DropdownProps & { open: boolean; }> = (props) => {
  const { open, ...otherProps } = props;

  if (!open) { return null; }

  return (
    <DropdownOpen {...otherProps} />
  );
};
