import React, { useState, useEffect, useRef, useCallback } from 'react';
import DiscussionMention from './Mention';
import * as Atlas from '../../../common/types/Atlas';
import Picker from 'emoji-picker-react'
import ReactDOM from 'react-dom';
import { Tooltip } from 'react-tippy'
import { EmojiContainer, EmojiIcon, EmojiPickerContainer } from '../styles/emoji'
import RouterPrompt from '../../../common/components/RouterPrompt';
import { Button } from '../../../common/components/Button';

interface DiscussionCommentFormProps {
  discussionId: Atlas.DiscussionID;
  onSubmit: (commentBody: string) => Promise<void>;
  isLocked?: Atlas.DiscussionLocked;
}

interface DiscussionCommentFormState {
  commentBody: string;
  submitting: boolean;
  showPicker: boolean;
  message: string;
  cursorPosition: number | null;
  chosenEmoji: any;
  focused: boolean;
}

const DiscussionCommentForm = (props: DiscussionCommentFormProps): JSX.Element => {
  const { discussionId, onSubmit, isLocked } = props;
  const emojiInputRef = useRef<HTMLInputElement>(null);
  const emojiPickerRef = useRef<any>();
  const emojiIconRef = useRef<any>();
  const [state, setState] = useState<DiscussionCommentFormState>({
    commentBody: '',
    message: '',
    submitting: false,
    showPicker: false,
    cursorPosition: null,
    chosenEmoji: '',
    focused: false,
  });

  const pickEmoji = (e: any, { emoji }: any) => {
    const ref = emojiInputRef.current;
    if (!ref) { return; }

    const { selectionStart, selectionEnd } = ref;
    const start = state.commentBody.substring(0, selectionStart ?? 0)
    const end = state.commentBody.substring(selectionEnd ?? 0);
    const msg = start + emoji + end;

    ref.focus();

    setState((s) => ({
      ...s,
      chosenEmoji: emoji,
      commentBody: msg,
      cursorPosition: start.length + emoji.length,
    }))
  }

  const handleShowEmoji = () => {
    if (isLocked === 'locked') { return; };

    setState((s) => ({
      ...s, showPicker: !s.showPicker
    }));
    emojiInputRef.current?.focus();
  }

  useEffect(() => {
    const emojiInput = emojiInputRef.current;
    if (!emojiInput) { return; }
    emojiInput.selectionEnd = state.cursorPosition;
  }, [state.cursorPosition])


  const handleDocumentClick = useCallback((event: any) => {
    const emojiPickerNode = ReactDOM.findDOMNode(emojiPickerRef.current);
    const emojiIconNode = ReactDOM.findDOMNode(emojiIconRef.current);

    if (emojiPickerNode == null) { return; }

    else if (!emojiPickerNode.contains(event.target) && !emojiIconNode?.contains(event.target)) {
      setState((s) => ({
        ...s, showPicker: !s.showPicker
      }))
    }
  }, [])

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [handleDocumentClick]);

  const hasUnsavedChange = !!state.commentBody.length;

  const locked = isLocked === 'locked';

  const handleCommentBodyChange = (value: string) => {
    if (state.submitting) { return; }

    setState((s) => ({ ...s, commentBody: value }));
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (state.submitting) { return; }
    if (locked) { return }
    if (!state.commentBody.length) { return }

    setState((s) => ({ ...s, submitting: true }));
    onSubmit(state.commentBody).then(() => {
      setState((s) => ({ ...s, commentBody: '', submitting: false }));
    }, () => {
      setState((s) => ({ ...s, submitting: false }));
    });
  };

  const handleFocus = () => {
    setState((s) => ({ ...s, focused: true }));
  };

  const handleBlur = () => {
    setState((s) => ({ ...s, focused: false }));
  };

  return (
    <Tooltip
      title={locked ? __('The discussion is locked') : ''}
      position="top"
      trigger="mouseenter"
      followCursor={true}
      size="small"
    >
      <RouterPrompt
        when={hasUnsavedChange}
        message={__('You have unsaved changes on this page.')}
      />

      <form
        className={`d-flex align-items-center p-3 rounded-pill bg-light border ${state.submitting || state.focused || !state.commentBody ? 'border-light' : 'border-warning'}`}
        onSubmit={handleFormSubmit}
      >
        <div className="flex-fill">
          <DiscussionMention
            discussionId={discussionId}
            value={state.commentBody}
            onChange={handleCommentBodyChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            emojiRef={emojiInputRef}
            isLocked={isLocked}
          />
        </div>

        <div className="d-flex justify-content-end" style={{ columnGap: '1.5rem' }}>
          <div>
            <EmojiContainer>
              <EmojiIcon
                onClick={handleShowEmoji}
                ref={emojiIconRef}
              >
                <i className="fa fa-smile-o fa-2x" />
              </EmojiIcon>

              {state.showPicker ? (
                <EmojiPickerContainer
                  ref={emojiPickerRef}
                >
                  <Picker
                    onEmojiClick={pickEmoji}
                    disableAutoFocus
                    disableSearchBar
                    disableSkinTonePicker
                    pickerStyle={{ width: '250px', height: '300px' }}
                  />
                </EmojiPickerContainer>
              ) : null}
            </EmojiContainer>
          </div>
          <div>
            <Tooltip
              title={!state.commentBody.length ? __('Enter message') : ''}
              position="top"
              trigger="mouseenter"
              followCursor
              size="small"
            >
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  id="submit-discussion"
                  block
                  type="submit"
                  disabled={!state.commentBody.length}
                  onClick={() => { setState((s) => ({ ...s, showPicker: false })) }}
                  className="px-4 rounded-pill font-weight-bold btn-large"
                  style={{
                    backgroundColor: '#2A6EBA',
                    color: 'white',
                    textShadow: 'none',
                    cursor: locked ? 'not-allowed' : 'pointer',
                  }}
                >
                  {state.submitting ? (
                    <i
                      className="fa fa-circle-o-notch fa-spin"
                      style={{
                        fontSize: '17px',
                      }}
                    />
                  ) : (
                    <i
                      className="fa fa-paper-plane"
                      style={{
                        fontSize: '17px',
                      }}
                    />
                  )}
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      </form>
    </Tooltip>
  );
};

export default DiscussionCommentForm;
