import { associateReflectionFormRoute, generateFetchHeaders } from '../../../../../routes';
import ApiError from '../../../../../error';
import { FormID, ReflectionID } from '../../../../../../types/Atlas';

export interface AssociateReflectionFormArguments {
  params: {
    reflectionId: ReflectionID;
    formId: FormID;
  };
}

export type AssociateReflectionFormResponse = void;

export const associateReflectionForm = async (
  args: AssociateReflectionFormArguments,
): Promise<AssociateReflectionFormResponse> => {
  const url = associateReflectionFormRoute({
    reflectionId: args.params.reflectionId,
    formId: args.params.formId,
  });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'associateReflectionForm');
  }
};
