import { useMutation } from 'react-query';
import { FindReflectionsResponse } from '../../../api/reflections';
import { FindReflectionVideosResponse } from '../../../api/reflections/videos';
import { FindReflectionPlaylistResponse } from '../../../api/reflections/[reflectionId]/playlist';
import {
  CreateVideoResponse, CreateVideoArguments, createVideo, FindVideoResponse,
} from '../../../api/videos';
import { queryClient } from '../../withQueryClient';

const useCreateVideoMutation = () => {
  const mutation = useMutation<CreateVideoResponse, unknown, CreateVideoArguments, unknown>(
    createVideo,
    {
      onSuccess: (result) => {
        const video = result.data;

        queryClient.setQueryData<FindVideoResponse>(['videos', video.id], { data: video });

        if (video.reflection_id) {
          void queryClient.invalidateQueries<FindReflectionsResponse>(['reflections', video.reflection_id], { exact: true });
          void queryClient.invalidateQueries<FindReflectionPlaylistResponse>(['reflections', video.reflection_id, 'playlist']);
          void queryClient.invalidateQueries<FindReflectionVideosResponse>(['reflections', video.reflection_id, 'videos']);
        }
      },
    },
  );

  return mutation;
};

export default useCreateVideoMutation;
