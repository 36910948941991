import styled from '@emotion/styled'

export const EmojiPickerContainer = styled.div`
  position: absolute;
  bottom: 70px;
  left: -100px;

  img {
    max-width: unset !important;
    max-width: unset !important;
    border: unset !important;
    border-radius: unset !important;
  }
`;

export const EmojiContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .content-wrapper:before {
    display: none !important;
  }

  @media only screen and  (max-width: 697px) {
    display: none !important;
  }
`;

export const EmojiIcon = styled.div`
  width: 25px !important;
  transition: .3s ease-in-out;

  img {
    border: none !important;
    max-width: 80% !important;
  }

  &:hover {
    transform: scale(1.1)
  }
`;
