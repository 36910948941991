import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../common/components/Button';

import {
  newBookingRoute,
  phoneCallsRoute,
  efUploadNewReflectionsRoute,
} from '../../../common/api/routes';

const NewReflectionOptions = (props) => {
  const {
    liveReflectionsEnabled,
    phoneCallsEnabled,
    efUploadAndCopyEnabled,
    source
  } = props;

  const handleDesktopUploadClick = props.onDesktopUploadClick;
  const handleScreenCaptureClick = props.onScreenCaptureClick;

  return (
    <div data-testid={`NewReflectionOptions-${source}`}>
      <Button
        tag="a"
        size="large"
        className="d-block d-flex align-items-center justify-content-between mb-2"
        style={{ backgroundColor: 'white', color: '#363565', fontWeight: 600, fontSize: '16px' }}
        disabled={!liveReflectionsEnabled}
        href={liveReflectionsEnabled ? newBookingRoute() : undefined}
      >
        <p>
          {__('Book A Live Reflection')}
        </p>
        <i className="fa fa-chevron-right ml-3" />
      </Button>
      <Button
        tag="a"
        size="large"
        className="d-block d-flex align-items-center justify-content-between mb-2"
        style={{ backgroundColor: 'white', color: '#363565', fontWeight: 600, fontSize: '16px' }}
        onClick={handleDesktopUploadClick}
      >
        <p>
          {__('Desktop Upload')}
        </p>
        <i className="fa fa-chevron-right ml-3" />
      </Button>
      <Button
        tag="a"
        size="large"
        className="d-block d-flex align-items-center justify-content-between mb-2"
        style={{ backgroundColor: 'white', color: '#363565', fontWeight: 600, fontSize: '16px' }}
        onClick={handleScreenCaptureClick}
      >
        <p>
          {__('Screen Capture')}
        </p>
        <i className="fa fa-chevron-right ml-3" />
      </Button>
      {phoneCallsEnabled ? (
        <Button
          tag="a"
          size="large"
          className="d-block d-flex align-items-center justify-content-between mb-2"
          style={{ backgroundColor: 'white', color: '#363565', fontWeight: 600, fontSize: '16px' }}
          href={phoneCallsRoute()}
        >
          <p>
            {__('Phone Call')}
          </p>
          <i className="fa fa-chevron-right ml-3" />
        </Button>
      ) : null}
      {efUploadAndCopyEnabled ? (
        <Button
          tag="a"
          size="large"
          className="d-block d-flex align-items-center justify-content-between mb-2"
          style={{ backgroundColor: 'white', color: '#363565', fontWeight: 600, fontSize: '16px' }}
          href={efUploadNewReflectionsRoute()}
        >
          <p>
            {__('EF Upload & Copy')}
          </p>
          <i className="fa fa-chevron-right ml-3" />
        </Button>
      ) : null}
    </div>
  );
};

NewReflectionOptions.propTypes = {
  liveReflectionsEnabled: PropTypes.bool,
  phoneCallsEnabled: PropTypes.bool,
  efUploadAndCopyEnabled: PropTypes.bool,
  onDesktopUploadClick: PropTypes.func,
  onScreenCaptureClick: PropTypes.func,
  onBlankReflectionClick: PropTypes.func,
};

export default NewReflectionOptions;
