import React, { useMemo } from 'react';
import { createStorageBrowser } from '@aws-amplify/ui-react-storage/browser';
import '@aws-amplify/ui-react-storage/styles.css';
import { getCredentials } from './api';

const ReportsBrowser: React.FC<{
  awsRegion: string;
  awsAccountId: string;
  awsBucket: string;
  athenaRegion: string;
}> = ({ awsRegion, awsAccountId, awsBucket, athenaRegion }) => {
  const { StorageBrowser } = useMemo(
    () =>
      createStorageBrowser({
        config: {
          region: awsRegion,
          accountId: awsAccountId,
          listLocations: (input) => {
            const { nextToken } = input.options ?? {};

            return Promise.resolve({
              nextToken,
              items: [
                {
                  bucket: awsBucket,
                  prefix: `products/${athenaRegion}/`,
                  id: awsBucket,
                  type: 'BUCKET',
                  permissions: ['get', 'list'],
                },
              ],
            });
          },

          getLocationCredentials: async () => {
            const credentials = await getCredentials();

            return {
              credentials: {
                accessKeyId: credentials.AccessKeyId,
                secretAccessKey: credentials.SecretAccessKey,
                sessionToken: credentials.SessionToken,
                expiration: credentials.Expiration,
              },
            };
          },
          registerAuthListener: () => {},
        },
      }),
    [awsAccountId, awsBucket, awsRegion],
  );

  return <StorageBrowser />;
};

export default ReportsBrowser;
