import { useMutation, UseMutationResult } from 'react-query';
import { updateReflection, UpdateReflectionArguments, UpdateReflectionResponse } from '../../../api/reflections/[reflectionId]';
import { queryClient } from '../../withQueryClient';

const useUpdateReflectionMutation = (): UseMutationResult<
UpdateReflectionResponse, unknown, UpdateReflectionArguments, unknown
> => {
  const mutation = useMutation(updateReflection, {
    onSuccess: async (result) => {
      const reflection = result.data;

      await queryClient.invalidateQueries(['reflections', reflection.id]);

      void queryClient.invalidateQueries(['reflections']);
    },
  });

  return mutation;
};

export default useUpdateReflectionMutation;
