import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Tooltip } from 'react-tippy';
import * as Atlas from '../../../common/types/Atlas';
import EditableText from './EditableText';
import useUpdateDiscussionCommentMutation from '../../../common/hooks/api/discussion-comments/useUpdateDiscussionCommentMutation';
import useDestroyDiscussionCommentMutation from '../../../common/hooks/api/discussion-comments/useDestroyDiscussionCommentMutation';
import * as dayjsLocale from '../../../common/utils/dayjs-locale';
import { isGroupAdministrator } from '../../../common/utils/group-permissions';
import GroupContext from '../GroupContext';
import { Col } from '../../../common/components/Col';
import { Row } from '../../../common/components/Row';

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.updateLocale('en', dayjsLocale.EN);

interface CommentReplyProps {
  currentUser?: Atlas.MyUser;
  alignRight?: boolean;
  reply: Atlas.ExpandedDiscussionComment;
  isLocked?: Atlas.DiscussionLocked;
}

const CommentReply = (props: CommentReplyProps): JSX.Element | null => {
  const {
    currentUser, alignRight, reply, isLocked,
  } = props;

  const [state, setState] = useState<{
    editing: boolean;
    focused: boolean;
  }>({
    editing: false,
    focused: false,
  });

  const group = useContext(GroupContext);

  const updateDiscussionComment = useUpdateDiscussionCommentMutation({
    discussionCommentId: reply.id,
  });
  const destroyDiscussionComment = useDestroyDiscussionCommentMutation({
    discussionCommentId: reply.id,
  });

  if (destroyDiscussionComment.isLoading) { return null; }

  const handleEditFormSubmit = async (commentBody: string) => {
    if (updateDiscussionComment.isLoading) { return; }

    await updateDiscussionComment.mutateAsync({ body: commentBody });
    setState((s) => ({ ...s, editing: false }));
  };

  const canEdit = (() => {
    if (isLocked === 'locked') { return false; }
    if (!currentUser) { return false; }
    return currentUser.id === reply.user_id;
  })();

  const handleEditClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setState((s) => ({ ...s, editing: !s.editing }));
  };

  const canDelete = (() => {
    if (isLocked === 'locked') { return false; }
    if (!currentUser) { return false; }
    if (currentUser.admin === Atlas.AdministratorType.Super) { return true; }
    if (currentUser.id === reply.user_id) { return true; }
    if (!group?.group) { return false; }

    return isGroupAdministrator(group.group, currentUser.id, currentUser.organization_id);
  })();

  const handleDeleteClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (!canDelete) { return; }

    if (!window.confirm(__('Are you sure you want to delete this comment?'))) { return; }

    destroyDiscussionComment.mutate();
  };

  const handleFocus = () => {
    setState((s) => ({ ...s, focused: true }));
  };

  const handleBlur = () => {
    setState((s) => ({ ...s, focused: false }));
  };

  return (
    <Row className="flex-column">
      <Col xs="auto">
        <div className={alignRight ? '' : 'text-right'}>
          <small className="px-2 mt-3 mb-1 font-weight-bold text-dark">
            {reply.user.first_name}
          </small>
          {reply.edited_at ? (
            <Tooltip
              html={
                <div>
                  <div>
                    {__('Created')} {dayjs(reply.created_at).format('LLLL')}
                  </div>
                  <div>
                    {__('Edited')} {dayjs(reply.edited_at).format('LLLL')}
                  </div>
                </div>
              }
              size="small"
              delay={500}
            >
              <small>
                {__('Edited')} {dayjs().to(dayjs(reply.edited_at))}
              </small>
            </Tooltip>
          ) : (
            <Tooltip
              title={dayjs(reply.created_at).format('LLLL')}
              size="small"
              delay={500}
            >
              <small>
                {dayjs().to(dayjs(reply.created_at))}
              </small>
            </Tooltip>
          )}
        </div>
        <div className={`px-3 py-2 rounded-lg ${state.editing ? 'shadow-sm' : 'bg-light'} border ${state.editing && !state.focused ? 'border-warning' : 'border-white'}`}>
          <EditableText
            discussionId={reply.discussion_id}
            value={reply.body}
            editing={state.editing}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onSubmit={handleEditFormSubmit}
          />
        </div>

        <div className="px-3">
          {canEdit || canDelete ? (
            <Row className={classNames('mx-n2 align-items-end', { 'justify-content-start': alignRight, 'justify-content-end': !alignRight })}>
              {canEdit ? (
                <Col className="px-2" xs="auto">
                  <small>
                    <a href="#" role="button" className="text-dark" onClick={handleEditClick}>
                      {__('Edit')}
                    </a>
                  </small>
                </Col>
              ) : null}
              {canDelete ? (
                <Col className="px-2" xs="auto">
                  <small>
                    <a href="#" role="button" className="text-dark" onClick={handleDeleteClick}>
                      {__('Delete')}
                    </a>
                  </small>
                </Col>
              ) : null}
            </Row>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

export default CommentReply;
