import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';

interface FileDropzoneProps {
  accept?: Accept;
  selectedFile?: File[];
  onDrop: (file: File) => void;
  className?: string;
  children?: (args: { isDragActive: boolean, open: () => void }) => React.ReactNode;
  disabled?: boolean;
}

const FileDropzone: React.FC<FileDropzoneProps> = (props) => {
  const { accept, onDrop, className, children, disabled } = props;

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept,
    onDrop: ([file]) => {
      if (!file) { return; }
      onDrop(file);
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    disabled,
  });

  return (
    <div
      className={className}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      {children?.({ isDragActive, open })}
    </div>
  );
};

export default FileDropzone;
