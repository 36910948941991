import React, { useEffect, useState } from 'react';
import Pagination from '../../../common/components/Pagination';
import useReflectionsQuery from '../../../common/hooks/api/useReflectionsQuery';
import * as Atlas from '../../../common/types/Atlas';

interface ReflectionListProps {
  userId: Atlas.UserID;
  onReflectionSelect: (reflectionId: Atlas.ReflectionID) => void;
}

const ReflectionList = (props: ReflectionListProps) => {
  const {
    userId, onReflectionSelect,
  } = props;

  const [state, setState] = useState({
    page: 1,
    perPage: 10,
    totalResults: 10,
    totalPages: 1,
  });

  const reflectionsQuery = useReflectionsQuery({
    page: state.page,
    per_page: state.perPage,
    userId,
  });

  const totalPages = reflectionsQuery.data?.metadata.total_pages ?? state.totalPages;
  const totalResults = reflectionsQuery.data?.data.length ?? state.totalResults;

  useEffect(() => {
    setState((s) => ({ ...s, totalPages }));
  }, [totalPages]);

  useEffect(() => {
    setState((s) => ({ ...s, totalResults }));
  }, [totalResults]);

  const handleReflectionClick = (reflection: Atlas.CondensedReflection) => () => {
    onReflectionSelect(reflection.id);
  };

  const handlePageChange = (page: number) => {
    setState((s) => ({ ...s, page }));
  };

  switch (reflectionsQuery.status) {
    case 'loading': {
      return (
        <>
          <div className="tw-my-4 tw-space-y-2">
            {new Array(state.totalResults).fill(null).map((_, i) => (
              <div key={i}>
                <button type="button" className="tw-btn tw-btn-block" disabled>
                  &nbsp;
                </button>
              </div>
            ))}
          </div>
          <Pagination
            page={state.page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      );
    }

    case 'success': {
      return (
        <>
          <div className="tw-my-4 tw-space-y-2">
            {reflectionsQuery.data.data.map((reflection) => (
              <div key={reflection.id}>
                <button type="button" className="tw-btn tw-btn-block" onClick={handleReflectionClick(reflection)}>
                  {reflection.name ? (
                    reflection.name
                  ) : (
                    <span className="tw-italic">
                      {__('Untitled reflection')}
                    </span>
                  )}
                </button>
              </div>
            ))}
          </div>
          <Pagination
            page={state.page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      );
    }

    case 'idle':
    case 'error':
    default: {
      return null;
    }
  }
};

export default ReflectionList;
