import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject } from '../../../api-parser';
import ApiError from '../../../error';
import { generateFetchHeaders } from '../../../routes';

const downloadVideoRoute = (args: {
  videoId: Atlas.VideoID;
}): string => `/api/v0/videos/${args.videoId}/download`;

export interface DownloadVideoParams {
  videoId: Atlas.VideoID;
}

export interface DownloadVideoArguments {
  params: DownloadVideoParams;
}

export interface DownloadVideoResponse {
  data: Atlas.DownloadResponse;
}

export const downloadVideo = async (
  args: DownloadVideoArguments,
): Promise<DownloadVideoResponse> => {
  const { videoId } = args.params;

  const url = downloadVideoRoute({ videoId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'downloadVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.DownloadResponse>(body.data);

  return { data };
};
