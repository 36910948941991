import { useMutation, UseMutationResult } from 'react-query';
import { createDiscussionComment, CreateDiscussionCommentBody, CreateDiscussionCommentParams, CreateDiscussionCommentResponse } from '../../../api/discussions/[discussionId]/comments';
import { queryClient } from '../../withQueryClient';

const useCreateDiscussionDiscussionCommentMutation = (
  params: CreateDiscussionCommentParams,
): UseMutationResult<CreateDiscussionCommentResponse, unknown, CreateDiscussionCommentBody, unknown> => {
  const mutation = useMutation((body: CreateDiscussionCommentBody) => {
    return createDiscussionComment({ params, body });
  }, {
    onSuccess: (result) => {
      const discussionComment = result.data;
      queryClient.invalidateQueries(['discussions', discussionComment.discussion_id]);
    },
  });

  return mutation;
};

export default useCreateDiscussionDiscussionCommentMutation;
