import { useMutation } from 'react-query';
import { FindReflectionVideosResponse } from '../../../api/reflections/videos';
import {
  FindVideoResponse, updateVideo, UpdateVideoArguments, UpdateVideoResponse,
} from '../../../api/videos/[videoId]';
import { queryClient } from '../../withQueryClient';

const useUpdateVideoMutation = () => {
  const mutation = useMutation<UpdateVideoResponse, unknown, UpdateVideoArguments, unknown>(
    updateVideo,
    {
      onSuccess: async (result) => {
        const video = result.data;

        queryClient.setQueryData<FindVideoResponse>(['videos', video.id], { data: video });

        if (video.reflection_id) {
          await Promise.all([
            queryClient.invalidateQueries<FindReflectionVideosResponse>(['reflections', video.reflection_id, 'videos']),
            queryClient.invalidateQueries<FindReflectionVideosResponse>(['reflections', video.reflection_id, 'playlist']),
          ]);
        }
      },
    },
  );

  return mutation;
};

export default useUpdateVideoMutation;
