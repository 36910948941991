/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const cardSubtitleVariant = cva('card-subtitle');

export type CardSubtitleVariantProps = VariantProps<typeof cardSubtitleVariant>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const CardSubtitle = ({
  className,
  ...props
}: CardSubtitleVariantProps & DivProps) => (
  <div
    {...props}
    className={cx(className, cardSubtitleVariant())}
  />
);
