import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { search, SearchParams, SearchResponse } from '../../../api/search';

const useSearchInfiniteQuery = (
  params: Pick<SearchParams, 'query' | 'excludeUsers' | 'excludeDevices' | 'excludeGroups' | 'excludeOrganizations' | 'excludeReflections' | 'includeTrashed'>,
  options?: {
    enabled?: boolean,
    cacheTime?: number,
    staleTime?: number,
  },
): UseInfiniteQueryResult<SearchResponse> => useInfiniteQuery(
  ['search', 'infinite', params],
  (context) => search({ params: { ...params, page: context.pageParam ?? 1 } }),
  {
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.metadata.current_page + 1;
      if (nextPage > lastPage.metadata.total_pages) { return null; }
      return nextPage;
    },
    ...options,
  },
);

export default useSearchInfiniteQuery;
