import { useQuery, UseQueryResult } from 'react-query';
import { findAiModels, FindAiModelsArguments, FindAiModelsResponse } from '../../../api/ai-models';

const useFindAiModelsQuery = (
  args: FindAiModelsArguments = {},
): UseQueryResult<FindAiModelsResponse> => (
  useQuery(['ai-models', args.params], () => findAiModels(args))
);

export default useFindAiModelsQuery;
