const parseField = (key: string, value: unknown): unknown => {
  if (Array.isArray(value)) {
    return value.map((v) => parseField(key, v));
  }
  if (value === Object(value)) {
    return parseModelObject(value as Record<string, unknown>);
  }
  if (key.endsWith('_at') && (
    typeof value === 'string' || typeof value === 'number'
  )) {
    return new Date(value);
  }

  return value;
};

export const parseModelObject = <T>(obj: object): T => {
  const parsedObject = Object
    .entries(obj)
    .map<[string, unknown]>(([key, value]) => [key, parseField(key, value)])
    .reduce<Record<string, unknown>>((acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }), {});

  return parsedObject as T;
};

export const parseModelObjectArray = <T>(
  arr: Array<object>,
): Array<T> => arr.map<T>(parseModelObject);

export const parseResponse = async <T>(response: Response): Promise<T> => {
  if (response.status === 500) {
    const {
      error,
      message,
    } = await response.json() as {
      error: string;
      message: string;
    };

    throw new Error(`[${error}] ${message}`);
  }

  if (response.status !== 200) {
    throw new Error(`[${response.status}] Unknown error occurred`);
  }

  return await response.json() as T;
};
