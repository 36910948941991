import React from 'react';

// UI
import {
  CartesianGrid,
  DotProps,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// Utils
import { msToTimestamp } from '../../../../common/utils/utils';
import { pickColor } from '../../../../common/utils/colors';
import * as Atlas from '../../../../common/types/Atlas';
import { lineLegendFormatter } from '../legendFormatter';
import useVideoPlayer from '../../../jwplayer/useVideoPlayer';

interface Payload {
  time: number;
  videoId: Atlas.VideoID;
}

interface FormCounterLineChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

const FormCounterLineChart = (props: FormCounterLineChartProps): JSX.Element => {
  const { formElement, formAnswers = [] } = props;

  const videoPlayer = useVideoPlayer();

  if (!('counter' in formElement.field.type)) {
    throw 'FormFieldType is incompatible with CounterFormFieldType';
  }

  const { choices } = formElement.field.type.counter;

  const handleDotClick = (
    _: DotProps,
    event: React.MouseEvent<SVGCircleElement, MouseEvent> & { payload: Payload; },
  ) => {
    if (!videoPlayer) { return; }

    const { time, videoId } = event.payload;
    const currentVideoId = videoPlayer.videoId;

    if (currentVideoId === videoId) {
      videoPlayer.seek(time);
    } else {
      videoPlayer.play(videoId, time);
    }
  };

  const data: Record<number, Record<number, Record<string, number>>> = {};
  videoPlayer?.playlist.forEach((videoId) => {
    data[videoId] = {};
  });

  formAnswers.forEach((fa) => {
    fa.counter_value?.forEach(({ choice, clicks }) => {
      clicks.forEach((click) => {
        const { video_id: vId, time } = click;
        data[vId] ||= {};
        data[vId][time] ||= {};
        data[vId][time][choice] ||= 0;
        data[vId][time][choice] += 1;
      });
    });
  });

  const chartsData = Object.entries(data).map(([videoId, times]) => {
    const data = Object.entries(times).map(([time, clicks]) => ({
      time: Number(time),
      videoId: Number(videoId),
      ...clicks,
    }));

    return { videoId, data };
  });

  return (
    <div>
      {chartsData.map(({ videoId, data }) => (
        data.length ? (
          <ResponsiveContainer height={200} width="100%" key={videoId}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" type="number" scale="time" name={__('Time')} tickFormatter={(n) => msToTimestamp(Number(n))} domain={[0, 10]} />

              <YAxis allowDecimals={false} type="number" name={__('Count')}>
                <Label angle={-90} value={__('Count')} position="insideLeft" offset={20} style={{ textAnchor: 'middle' }} />
              </YAxis>

              <Tooltip labelFormatter={(n) => msToTimestamp(Number(n))} />
              <Legend iconSize={8} iconType="circle" formatter={lineLegendFormatter} />

              {choices.map((c, i) => (
                <Line
                  key={c}
                  dataKey={c}
                  isAnimationActive={false}
                  stroke={pickColor(i)}
                  strokeWidth={0}
                  dot={{ stroke: pickColor(i), fill: pickColor(i), strokeWidth: 2 }}
                  activeDot={{
                    onClick: handleDotClick,
                    stroke: pickColor(i),
                    fill: pickColor(i),
                    strokeWidth: 2,
                  }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        ) : null
      ))}
    </div>
  );
};

export default FormCounterLineChart;
