import React, { ComponentProps } from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import useFindReflectionQuery from '../../../../common/hooks/api/reflections/useFindReflectionQuery';
import ReflectionEmbedModeForm from './ReflectionEmbedModeForm';
import useUpdateReflectionMutation from '../../../../common/hooks/api/reflections/useUpdateReflectionMutation';
import ReflectionEmbedCode from './ReflectionEmbedCode';

interface ReflectionEmbedCodeContainerProps {
  reflectionId: Atlas.ReflectionID;
}

const ReflectionEmbedCodeContainer = (props: ReflectionEmbedCodeContainerProps) => {
  const { reflectionId } = props;

  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const updateReflectionMutation = useUpdateReflectionMutation();

  const handleEmbedModeSubmit: ComponentProps<typeof ReflectionEmbedModeForm>['onSubmit'] = async (embedMode) => {
    await updateReflectionMutation.mutateAsync({
      params: { reflectionId },
      body: { embed_mode: embedMode },
    });
  };

  return (
    <section>
      {reflectionQuery.status === 'success' ? (
        <ReflectionEmbedModeForm
          embedMode={reflectionQuery.data.data.embed_mode}
          onSubmit={handleEmbedModeSubmit}
        />
      ) : null}

      <hr className="tw-my-4" />

      <ReflectionEmbedCode
        reflectionId={reflectionId}
      />
    </section>
  );
};

export default ReflectionEmbedCodeContainer;
