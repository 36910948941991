import styled from '@emotion/styled';

export const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 1rem;
  border-radius: .5rem;
  margin: .25rem 0;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #0074cc;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #0074cc;
    cursor: pointer;
  }
`;
