import React from 'react';
import { parseAsString, parseAsStringEnum, useQueryState } from 'nuqs';
import { ChartsData } from './types';
import MetricsDashboardView from './metrics-dashboard-view';
import withNuqsAdapter from '../../common/hooks/withNuqsAdapter';
import { MetricsCharts } from '../../common/types/Atlas';
import { downloadAggregatesDataCsv, downloadLineChartsDataCsv } from './utils';

const parseAsMetricsCharts = parseAsStringEnum<MetricsCharts>(
  Object.values(MetricsCharts),
);

const MetricsDashboardContainer: React.FC<{
  chartsData: ChartsData;
  minDate: string;
  maxDate: string;
}> = ({ chartsData, minDate, maxDate }) => {
  const [dateStart, setDateStart] = useQueryState(
    'date_start',
    parseAsString.withDefault(minDate),
  );

  const [dateEnd, setDateEnd] = useQueryState(
    'date_end',
    parseAsString.withDefault(maxDate),
  );
  const [chartType, setChartType] = useQueryState(
    'chart_type',
    parseAsMetricsCharts.withDefault(MetricsCharts.LineCharts),
  );

  const handleDateStartChange = (value: string) => {
    void setDateStart(value);
  };

  const handleDateEndChange = (value: string) => {
    void setDateEnd(value);
  };

  const handleChartTypeChange = (value: string) => {
    void setChartType(parseAsMetricsCharts.parse(value));
  };

  const handleExportClick = () => {
    switch (chartType) {
      case MetricsCharts.Aggregates: {
        downloadAggregatesDataCsv(chartsData, dateStart, dateEnd);
        break;
      }

      case MetricsCharts.LineCharts: {
        downloadLineChartsDataCsv(chartsData, dateStart, dateEnd);
        break;
      }

      default:
        break;
    }
  };

  return (
    <MetricsDashboardView
      chartsData={chartsData}
      minDate={minDate}
      maxDate={maxDate}
      dateStart={dateStart}
      dateEnd={dateEnd}
      chartType={chartType}
      onDateStartChange={handleDateStartChange}
      onDateEndChange={handleDateEndChange}
      onChartTypeChange={handleChartTypeChange}
      onExportClick={handleExportClick}
    />
  );
};

export default withNuqsAdapter(MetricsDashboardContainer);
