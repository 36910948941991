import * as Atlas from '../../types/Atlas';
import { inRange } from '../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../api-parser';
import ApiError from '../error';
import { generateFetchHeaders, jsonHeaders, Metadata, PaginationParams, searchParams } from '../routes';

export const videosRoute = (): string => '/api/v0/videos';

export const toUploadVideoRoute = (args: {
  videoMasterKey: Atlas.VideoMasterKey;
}): string => (
  `${videosRoute()}/to_upload/${args.videoMasterKey}`
);

export const uploadedVideoRoute = (args: {
  videoMasterKey: Atlas.VideoMasterKey;
}): string => (
  `${videosRoute()}/uploaded/${args.videoMasterKey}`
);

export const videoRoute = (args: {
  videoId: Atlas.VideoID
}): string => `/api/v0/videos/${args.videoId}`;

export type FindVideosParams = PaginationParams;

export interface FindVideosArguments {
  params?: FindVideosParams;
}

export interface FindVideosResponse {
  data: Atlas.Video[];
  metadata: Metadata;
}

export const findVideos = async (
  args?: FindVideosArguments
): Promise<FindVideosResponse> => {
  const { params = {} } = args || {};

  const url = [
    videosRoute(),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findVideos');
  }

  const body = await response.json();
  const data = parseModelObjectArray(body.data) as Atlas.Video[];
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface FindVideoParams {
  videoId: Atlas.VideoID;
}

export interface FindVideoArguments {
  params: FindVideoParams;
}

export interface FindVideoResponse {
  data: Atlas.Video;
}

export const findVideo = async (
  args: FindVideoArguments,
): Promise<FindVideoResponse> => {
  const { videoId } = args.params;

  const url = videoRoute({ videoId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Video>(body.data);

  return { data };
};

export interface CreateVideoBody {
  description?: Atlas.VideoDescription;
  reflectionId?: Atlas.ReflectionID;
  commentId?: Atlas.CommentID;
  upload_source?: Atlas.Video['upload_source'];
  channels?: Array<{}>;
}

export interface CreateVideoArguments {
  body: CreateVideoBody;
}

export interface CreateVideoResponse {
  data: Atlas.Video;
  metadata: Metadata;
}

export const createVideo = async (
  args: CreateVideoArguments,
): Promise<CreateVideoResponse> => {
  const url = videosRoute();

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      description: args.body.description,
      reflection_id: args.body.reflectionId,
      comment_id: args.body.commentId,
      upload_source: args.body.upload_source,
      channels: args.body.channels,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'createVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Video>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};


export interface ToUploadVideoParams {
  videoMasterKey: Atlas.VideoMasterKey;
}

export interface ToUploadVideoArguments {
  params: ToUploadVideoParams;
}

export interface ToUploadVideoResponse {
  data: Atlas.UploadCredentials;
  metadata: Metadata;
}

export const toUploadVideo = async (
  args: ToUploadVideoArguments,
): Promise<ToUploadVideoResponse> => {
  const { videoMasterKey } = args.params;

  const url = toUploadVideoRoute({ videoMasterKey });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'toUploadVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.UploadCredentials>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};


export interface UploadedVideoParams {
  videoMasterKey: Atlas.VideoMasterKey;
}

export interface UploadedVideoArguments {
  params: ToUploadVideoParams;
}

export interface UploadedVideoResponse {
  data: Atlas.UploadDescriptor;
  metadata: Metadata;
}

export const uploadedVideo = async (
  args: UploadedVideoArguments,
): Promise<UploadedVideoResponse> => {
  const { videoMasterKey } = args.params;

  const url = uploadedVideoRoute({ videoMasterKey });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'uploadedVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.UploadDescriptor>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};
