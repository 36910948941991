import { useContext } from 'react';
import { ReflectionStore, ReflectionStoreContext } from './context';
import { useShallow } from 'zustand/react/shallow';

export default function useReflectionStore<U>(selector: (state: ReflectionStore) => U) {
  const useBoundStore = useContext(ReflectionStoreContext);

  if (!useBoundStore) {
    throw new Error('useReflectionStore can only be used within ReflectionStoreProvider');
  }

  return useBoundStore(useShallow(selector));
}
