import React, { useState } from 'react';
import MediaCommentModal from '../attachments/MediaCommentModal';
import { MediaAttachmentOption } from '../attachments/media-capture-form/FormModal';
import * as Atlas from '../../../../common/types/Atlas';
import { Dropdown } from '../../../dropdown';

const NewMediaComment: React.FC<{
  onAttachClick: () => void;
  onStart: (commentArgs?: Partial<Atlas.Comment>) => Promise<Atlas.CommentID>;
}> = (props) => {
  const { onAttachClick, onStart } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [recordOption, setRecordOption] = useState<MediaAttachmentOption | null>(null);

  const opts = [{
    icon: 'fa fa-fw fa-lg fa-video-camera',
    label: __('Video'),
    value: MediaAttachmentOption.Video,
  }, {
    icon: 'fa fa-fw fa-lg fa-microphone',
    label: __('Audio'),
    value: MediaAttachmentOption.Audio,
  }, {
    icon: 'fa fa-fw fa-lg fa-video-camera',
    label: __('Video and screen capture'),
    value: MediaAttachmentOption.VideoAndScreenCapture,
  }, {
    icon: 'fa fa-fw fa-lg fa-desktop',
    label: __('Screen capture'),
    value: MediaAttachmentOption.ScreenCapture,
  }, {
    icon: 'fa fa-fw fa-lg fa-cloud-upload',
    label: __('Video upload'),
    value: MediaAttachmentOption.Upload,
  }];

  const [dropdownTarget, setDropdownTarget] = useState<HTMLElement | null>(null);

  const toggleDropdown = () => {
    setDropdownOpen((o) => !o);
  };

  return (
    <>
      <div>
        <button ref={setDropdownTarget} type="button" className="tw-btn tw-rounded-full tw-btn-block tw-btn-xs" onClick={toggleDropdown}>
          <i className="fa fa-video-camera tw-mr-2 tw-text-xs" />
          {__('Media Comment')}
        </button>

        <Dropdown placement="bottom" target={dropdownTarget} open={dropdownOpen} close={() => setDropdownOpen(false)}>
          <div className="tw-iris-dropdown !tw-w-full">
            <div className="tw-iris-dropdown-box !tw-mt-0 !tw-w-full">
              <ul>
                {opts.map((opt) => (
                  <li key={opt.value}>
                    <button
                      type="button"
                      onClick={() => {
                        onAttachClick();
                        setRecordOption(opt.value);
                        setDropdownOpen(false);
                      }}
                    >
                      <i className={`${opt.icon} tw-mr-2`} />
                      {opt.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          
        </Dropdown>
      </div>

      {recordOption !== null ? (
        <MediaCommentModal
          initialOption={recordOption}
          onStart={onStart}
          onClose={() => { setRecordOption(null); }}
        />
      ) : null}
    </>
  );
};

export default NewMediaComment;
