import React, { useState, useCallback, useEffect } from 'react';
import ReflectionAttachableForms from './ReflectionAttachableForms';
import ReflectionAttachedForms from './ReflectionAttachedForms';
import ReflectionAttachedForm from './ReflectionAttachedForm';
import { withQueryClient } from '../../common/hooks/withQueryClient';
import withCurrentUser from '../../common/hooks/withCurrentUser';
import {
  reflectionRoute,
  reflectionFormsRoute,
  associateReflectionFormRoute,
  disassociateReflectionFormRoute,
  updateFormAssociationReflectionRoute,
  searchParams,
  generateFetchHeaders,
} from '../../common/api/routes';

const defaultState = {
  page: 'index',
  forms: [],
  formsToAttach: [],
  loading: true,
};

const ReflectionForms = (props) => {
  const reflectionId = Number(props.reflectionId);

  const [state, setState] = useState(defaultState);

  const fetchReflection = useCallback(() => {
    setState((s) => ({ ...s, reflectionLoading: true }));

    const reflectionUrl = [
      reflectionRoute({ reflectionId }),
      searchParams({ view: 'condensed' }),
    ].join('?');

    return fetch(reflectionUrl, {
      credentials: 'same-origin',
    }).then((r) => r.json()).then((reflection) => {
      setState((s) => ({ ...s, reflection, reflectionLoading: false }));
    });
  }, [reflectionId]);

  const fetchForms = useCallback(() => {
    setState((s) => ({ ...s, formsLoading: true }));

    const reflectionFormsUrl = [
      reflectionFormsRoute({ reflectionId }),
      searchParams({ view: 'condensed' }),
    ].join('?');

    return fetch(reflectionFormsUrl, {
      credentials: 'same-origin',
    }).then((r) => r.json()).then((forms) => {
      setState((s) => ({
        ...s, forms, formsToAttach: [], formsLoading: false,
      }));
    });
  }, [reflectionId]);

  useEffect(() => {
    fetchReflection();
    fetchForms();
  }, [fetchForms, fetchReflection, reflectionId]);

  // INDEX
  const handleFormClick = (form) => {
    setState((s) => ({ ...s, page: 'show', pageProps: { form } }));
  };

  const handleAddFormClick = () => {
    setState((s) => ({ ...s, page: 'attach', pageProps: {} }));
  };

  // SHOW
  const handleAttachedFormBack = () => {
    setState((s) => ({ ...s, page: 'index', pageProps: {} }));
  };

  // ATTACH
  const handleAttachableFormsBack = () => {
    setState((s) => ({
      ...s, page: 'index', pageProps: {}, formsToAttach: [],
    }));
  };

  const handleAttachableFormsClick = (form) => {
    if (state.forms.some((f) => f.id === form.id)) {
      setState((s) => ({
        ...s,
        page: 'show',
        pageProps: { form },
      }));

      return;
    }

    setState((s) => ({ ...s, attachingForm: true }));

    fetch(associateReflectionFormRoute({ reflectionId, formId: form.id }), {
      method: 'POST',
      credentials: 'same-origin',
      headers: generateFetchHeaders(),
    }).then((r) => {
      if (r.status === 200) {
        setState((s) => ({
          ...s,
          page: 'show',
          pageProps: { form },
        }));
      }

      fetchReflection();
      fetchForms();
    }).finally(() => {
      setState((s) => ({ ...s, attachingForm: false }));
    });
  };

  const handleFormRemoveClick = (form) => {
    if (!window.confirm(__('Remove Insight?'))) { return; }

    setState((s) => ({
      ...s,
      page: 'index',
      pageProps: {},
      forms: s.forms.filter(({ id }) => id !== form.id),
    }));

    fetch(disassociateReflectionFormRoute({ reflectionId, formId: form.id }), {
      method: 'POST',
      credentials: 'same-origin',
      headers: generateFetchHeaders(),
    }).finally(() => {
      fetchReflection();
      fetchForms();
    });
  };

  const changeFormPrivacy = async ({ formId, privacy }) => {
    setState((s) => ({ ...s, reflectionLoading: true }));

    const updateFormAssociationReflectionUrl = [
      updateFormAssociationReflectionRoute({ reflectionId }),
      searchParams({ form_id: formId, answer_privacy: privacy }),
    ].join('?');

    await fetch(updateFormAssociationReflectionUrl, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: generateFetchHeaders(),
    }).finally(async () => {
      await fetchReflection();
    });
  };

  const $content = (() => {
    switch (state.page) {
      case 'index': {
        return (
          <ReflectionAttachedForms
            reflectionId={reflectionId}
            forms={state.forms}
            loading={state.formsLoading}
            onAddFormClick={handleAddFormClick}
            onFormClick={handleFormClick}
            onFormRemoveClick={handleFormRemoveClick}
          />
        );
      }

      case 'show': {
        const handleFormPrivacyChange = async (privacy) => {
          await changeFormPrivacy({ formId: state.pageProps.form.id, privacy });
        };

        return (
          <ReflectionAttachedForm
            reflectionId={reflectionId}
            {...state.pageProps}
            reflection={state.reflection}
            onBack={handleAttachedFormBack}
            onPrivacyChange={handleFormPrivacyChange}
            onRemoveClick={handleFormRemoveClick}
          />
        );
      }

      case 'attach': {
        const attachedFormsIds = [
          ...state.forms.map((form) => form.id),
          ...state.formsToAttach,
        ];

        return (
          <ReflectionAttachableForms
            loading={state.attachingForm}
            attachedFormsIds={attachedFormsIds}
            detachableFormIds={state.formsToAttach}
            onBack={handleAttachableFormsBack}
            onFormClick={handleAttachableFormsClick}
          />
        );
      }

      default: return null;
    }
  })();

  return $content;
};

export default withQueryClient(withCurrentUser(ReflectionForms));
