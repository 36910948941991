/* eslint-disable import/prefer-default-export */
import classNames from 'classnames';
import React, { useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';

export const CommentPrivacyButtonDropdown: React.FC<{
  privacy: Atlas.CommentPrivacy;
  onChange: (privacy: Atlas.CommentPrivacy) => void;
}> = ({
  privacy,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((o) => !o);

  const privacyOptions = [{
    value: Atlas.CommentPrivacy.Public,
    label: __('Public'),
  }, {
    value: Atlas.CommentPrivacy.Private,
    label: __('Private'),
  }];

  const selected = privacyOptions.find(({ value }) => value === privacy) ?? privacyOptions[0];

  return (
    <div className={`tw-dropdown ${open ? 'tw-dropdown-open' : ''}`}>
      <button type="button" className="tw-btn tw-btn-xs tw-rounded-full tw-gap-1" onClick={toggleOpen}>
        {selected.label}

        <i className="fa fa-caret-down" />
      </button>

      <div className="tw-dropdown-content tw-iris-dropdown">
        <div className="tw-iris-dropdown-box">
          <ul>
            {privacyOptions.map(({ value, label }) => {
              const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
                e.currentTarget.blur();

                setOpen(false);
                onChange(value);
              };

              return (
                <li key={value}>
                  <button
                    type="button"
                    onClick={handleClick}
                  >
                    <i className={classNames('fa fa-check tw-mr-2', { 'tw-invisible': value !== privacy })} />

                    {label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
