import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type QueryValue = string | null;
type GetQuery = { (name: string): QueryValue; } & { (): Record<string, QueryValue>; };
type SetQuery = { (query: Record<string, QueryValue>): void; }

const paramsToObject = (params: URLSearchParams) => {
  const obj: Record<string, string> = {};

  params.forEach((value, key) => {
    if (value === undefined) { return; }
    if (value === null) { return; }

    obj[key] = value;
  });

  return obj;
};

export const useQuery = (): [GetQuery, SetQuery] => {
  const location = useLocation(),
        navigate = useNavigate();

  const get = useMemo<GetQuery>(() => {
    const params = new URLSearchParams(location.search);

    function getQuery(name: string): QueryValue;
    function getQuery(): Record<string, string>;
    function getQuery(name?: string) {
      if (typeof name === 'string') {
        return params.get(name);
      }

      return paramsToObject(params);
    }

    return getQuery;
  }, [location.search]);

  const set = useCallback<SetQuery>(query => {
    const nextQuery = Object.entries(query).reduce<Record<string, string>>((acc, [k, v]) => {
      if (v !== null) {
        acc[k] = v;
      }
      return acc;
    }, {});

    const params = new URLSearchParams(nextQuery);
    navigate({ search: params.toString(), hash: location.hash });
  }, [location.hash]);

  return [get, set];
};
