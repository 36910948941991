/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const cardTitleVariant = cva('card-title');

export type CardTitleVariantProps = VariantProps<typeof cardTitleVariant>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const CardTitle = ({
  className,
  ...props
}: CardTitleVariantProps & DivProps) => (
  <div
    {...props}
    className={cx(className, cardTitleVariant())}
  />
);
