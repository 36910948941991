import React from 'react';
import useVideoPlayer from '../../jwplayer/useVideoPlayer';
import { ReflectionModal } from './store/reflection-modals.store';
import useReflectionStore from './store/useReflectionStore';
import { ReflectionTab } from './store/reflection-tabs.store';

const EditActiveVideoButton: React.FC<{
  className: string;
  children: React.ReactNode;
}> = (props) => {
  const { className, children } = props;

  const videoPlayer = useVideoPlayer();
  const videoId = videoPlayer?.videoId;

  const { changeTab, changeModal } = useReflectionStore((store) => ({
    changeModal: store.changeModal,
    changeTab: store.changeTab,
  }));

  const handleEditClick = () => {
    if (!videoId) { return; }

    videoPlayer.pause();
    changeTab(ReflectionTab.Videos);
    changeModal({ modal: ReflectionModal.EditVideo, videoId });
  };

  return (
    <button
      type="button"
      disabled={!videoId}
      className={className}
      onClick={handleEditClick}
    >
      {children}
    </button>
  );
};

export default EditActiveVideoButton;
