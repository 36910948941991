import React, {
  PropsWithChildren, useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import useRandomId from '../hooks/useRandomId';
import { useJwPlayerScope } from './JwPlayerScope';

export interface JwPlayerPlaylistItem {
  file: string;
  image?: string;
  title?: string;
  mediaid: string;
}

interface JwPlayerConfiguration {
  aspectratio?: string;
  captions?: { backgroundOpacity?: number; };
  nextUpDisplay?: boolean;
  playbackRateControls?: boolean;
  playbackRates?: number[];
  plugins?: Record<string, unknown>;
  skin?: { name: string; }
  width?: string;
}

export type PlayerStatus = 'idle' | 'buffering' | 'playing' | 'paused';

export interface JwPlayerState {
  id: string;
  status?: PlayerStatus;
  player?: jwplayer.JWPlayer;
}

export type JwPlayerSetStateAction = React.Dispatch<React.SetStateAction<JwPlayerState>>;

export const JwPlayerContext = React.createContext<readonly [
  JwPlayerState | null,
  JwPlayerSetStateAction | null,
]>([null, null]);

interface JwPlayerProviderProps {
  value: JwPlayerState;
}

export const JwPlayerProvider = (props: PropsWithChildren<JwPlayerProviderProps>) => {
  const { value, children } = props;

  const context = useState(value);

  return (
    <JwPlayerContext.Provider value={context}>
      {children}
    </JwPlayerContext.Provider>
  );
};

export type ReadyEvent = (param: jwplayer.ReadyParam, player: jwplayer.JWPlayer) => void;
export type TimeEvent = (param: jwplayer.TimeParam, player: jwplayer.JWPlayer) => void;
export type BufferEvent = (param: jwplayer.BufferParam, player: jwplayer.JWPlayer) => void;
export type PlayEvent = (param: jwplayer.PlayParam, player: jwplayer.JWPlayer) => void;
export type IdleEvent = (param: jwplayer.IdleParam, player: jwplayer.JWPlayer) => void;
export type PlaylistItemEvent = (param: jwplayer.PlaylistItemParam, player: jwplayer.JWPlayer) => void;
export type BufferChangeEvent = (param: jwplayer.BufferChangeParam, player: jwplayer.JWPlayer) => void;
export type MetadataEvent = (param: jwplayer.MetadataParam, player: jwplayer.JWPlayer) => void;
export type BeforePlayEvent = (player: jwplayer.JWPlayer) => void;
export type SeekEvent = (param: jwplayer.SeekParam, player: jwplayer.JWPlayer) => void;
export type SetupErrorEvent = (param: jwplayer.ErrorParam, player: jwplayer.JWPlayer) => void;
export type ErrorEvent = (param: jwplayer.ErrorParam, player: jwplayer.JWPlayer) => void;
export type AudioTracksEvent = (param: jwplayer.AudioTracksParam, player: jwplayer.JWPlayer) => void;
export type AudioTrackChangedEvent = (param: jwplayer.AudioTrackChangedParam, player: jwplayer.JWPlayer) => void;

interface UseJwPlayerArgs {
  onReady?: ReadyEvent;
  onBuffer?: BufferEvent;
  onPlay?: PlayEvent;
  onPause?: jwplayer.EventCallback<jwplayer.PlayParam>;
  onIdle?: IdleEvent;
  onTime?: TimeEvent;
  onPlaylist?: jwplayer.EventCallback<jwplayer.PlaylistParam>;
  onPlaylistItem?: PlaylistItemEvent;
  onSeek?: SeekEvent;
  onComplete?: () => void;
  onBufferChange?: BufferChangeEvent;
  onMetadata?: MetadataEvent;
  onBeforePlay?: BeforePlayEvent;
  onSetupError?: SetupErrorEvent;
  onError?: ErrorEvent;
  onAudioTracks?: AudioTracksEvent;
  onAudioTrackChanged?: AudioTrackChangedEvent;

}

export const useJwPlayer = (args?: UseJwPlayerArgs): JwPlayerState | null => {
  const {
    onReady,
    onBuffer,
    onPlay,
    onPause,
    onIdle,
    onTime,
    onPlaylist,
    onPlaylistItem,
    onSeek,
    onComplete,
    onBufferChange,
    onMetadata,
    onBeforePlay,
    onSetupError,
    onError,
    onAudioTracks,
    onAudioTrackChanged,
  } = args ?? {};

  const [state] = useContext(JwPlayerContext);
  const { player } = state ?? {};

  useEffect(() => {
    if (!onReady) { return undefined; }
    if (!player) { return undefined; }

    const handleReady = (param: jwplayer.ReadyParam) => onReady(param, player);

    player.on('ready', handleReady);
    return () => {
      player.off('ready', handleReady);
    };
  }, [onReady, player]);

  useEffect(() => {
    if (!onBeforePlay) { return undefined; }
    if (!player) { return undefined; }

    const handleBeforePlay = () => onBeforePlay(player);

    player.on('beforePlay', handleBeforePlay);
    return () => {
      player.off('beforePlay', handleBeforePlay);
    };
  }, [onBeforePlay, player]);

  useEffect(() => {
    if (!onMetadata) { return undefined; }
    if (!player) { return undefined; }

    const handleMetadata = (param: jwplayer.MetadataParam) => onMetadata(param, player);

    player.on('meta', handleMetadata);
    return () => {
      player.off('meta', handleMetadata);
    };
  }, [onMetadata, player]);

  useEffect(() => {
    if (!onBuffer) { return undefined; }
    if (!player) { return undefined; }

    const handleBuffer = (param: jwplayer.BufferParam) => onBuffer(param, player);

    player.on('buffer', handleBuffer);
    return () => {
      player.off('buffer', handleBuffer);
    };
  }, [onBuffer, player]);

  useEffect(() => {
    if (!onBufferChange) { return undefined; }
    if (!player) { return undefined; }

    const handleBuffer = (param: jwplayer.BufferChangeParam) => onBufferChange(param, player);

    player.on('bufferChange', handleBuffer);
    return () => {
      player.off('bufferChange', handleBuffer);
    };
  }, [onBufferChange, player]);

  useEffect(() => {
    if (!onPlay) { return undefined; }
    if (!player) { return undefined; }

    const handlePlay = (param: jwplayer.PlayParam) => onPlay(param, player);

    player.on('play', handlePlay);
    return () => {
      player.off('play', handlePlay);
    };
  }, [onPlay, player]);

  useEffect(() => {
    if (!onPause) { return undefined; }
    if (!player) { return undefined; }

    player.on('pause', onPause);
    return () => {
      player.off('pause', onPause);
    };
  }, [onPause, player]);

  useEffect(() => {
    if (!onIdle) { return undefined; }
    if (!player) { return undefined; }

    const handleIdle = (param: jwplayer.IdleParam) => onIdle(param, player);

    player.on('idle', handleIdle);
    return () => {
      player.off('idle', handleIdle);
    };
  }, [onIdle, player]);

  useEffect(() => {
    if (!onTime) { return undefined; }
    if (!player) { return undefined; }

    const handleTime = (param: jwplayer.TimeParam) => onTime(param, player);

    player.on('time', handleTime);
    return () => {
      player.off('time', handleTime);
    };
  }, [onTime, player]);

  useEffect(() => {
    if (!onPlaylist) { return undefined; }
    if (!player) { return undefined; }

    player.on('playlist', onPlaylist);
    return () => {
      player.off('playlist', onPlaylist);
    };
  }, [onPlaylist, player]);

  useEffect(() => {
    if (!onPlaylistItem) { return undefined; }
    if (!player) { return undefined; }

    const handlePlaylistItem = (param: jwplayer.PlaylistItemParam) => onPlaylistItem(param, player);

    player.on('playlistItem', handlePlaylistItem);
    return () => {
      player.off('playlistItem', handlePlaylistItem);
    };
  }, [onPlaylistItem, player]);

  useEffect(() => {
    if (!onAudioTracks) { return undefined; }
    if (!player) { return undefined; }

    const handleAudioTracks = (param: jwplayer.AudioTracksParam) => onAudioTracks(param, player);

    player.on('audioTracks', handleAudioTracks);
    return () => {
      player.off('audioTracks', handleAudioTracks);
    };
  }, [onAudioTracks, player]);

  useEffect(() => {
    if (!onAudioTrackChanged) { return undefined; }
    if (!player) { return undefined; }

    const handleAudioTrackChanged = (param: jwplayer.AudioTrackChangedParam) => (
      onAudioTrackChanged(param, player)
    );

    player.on('audioTrackChanged', handleAudioTrackChanged);
    return () => {
      player.off('audioTrackChanged', handleAudioTrackChanged);
    };
  }, [onAudioTrackChanged, player]);

  useEffect(() => {
    if (!onSeek) { return undefined; }
    if (!player) { return undefined; }

    const handleSeek = (param: jwplayer.SeekParam) => onSeek(param, player);

    player.on('seek', handleSeek);
    return () => {
      player.off('seek', handleSeek);
    };
  }, [onSeek, player]);

  useEffect(() => {
    if (!onComplete) { return undefined; }
    if (!player) { return undefined; }

    player.on('complete', onComplete);
    return () => {
      player.off('complete', onComplete);
    };
  }, [onComplete, player]);

  useEffect(() => {
    if (!onSetupError) { return undefined; }
    if (!player) { return undefined; }

    const handleSetupError = (param: jwplayer.ErrorParam) => onSetupError(param, player);

    player.on('setupError', handleSetupError);
    return () => {
      player.off('setupError', handleSetupError);
    };
  }, [onSetupError, player]);

  useEffect(() => {
    if (!onError) { return undefined; }
    if (!player) { return undefined; }

    const handleError = (param: jwplayer.ErrorParam) => onError(param, player);

    player.on('error', handleError);
    return () => {
      player.off('error', handleError);
    };
  }, [onError, player]);

  return state;
};

interface JwPlayerProps {
  configuration: JwPlayerConfiguration;
  playlist: JwPlayerPlaylistItem[];
}

const JwPlayer = (props: JwPlayerProps) => {
  const { configuration, playlist } = props;

  const randId = useRandomId();
  const [state, setState] = useContext(JwPlayerContext);
  if (!setState) { throw new Error('setState is missing'); }

  const id = `${randId}-${state?.id ?? ''}`;
  const ref = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) { return undefined; }

    const playerElement = document.createElement('div');
    if (id) {
      playerElement.id = id;
    }

    ref.current.append(playerElement);
    const player = jwplayer(playerElement);

    setState((prevState) => ({
      ...prevState,
      player,
    }));

    return () => {
      player.remove();
      playerElement.remove();
    };
  }, [id, setState]);

  const { player } = state ?? {};

  useEffect(() => {
    if (!player) { return; }
    if (!playlist) { return; }

    const jwState = player.getState();
    if (!jwState) {
      player.setup({ ...configuration, playlist });
    } else {
      player.load(playlist);
    }
  }, [configuration, player, playlist, setState]);

  useEffect(() => {
    if (!player) { return undefined; }

    const setStatus = (status: PlayerStatus | undefined) => () => {
      setState((prevState) => {
        if (prevState?.status === status) { return prevState; }

        return { ...prevState, status };
      });
    };

    const onIdle = setStatus('idle');
    const onBuffer = setStatus('buffering');
    const onPlay = setStatus('playing');
    const onPaus = setStatus('paused');

    player.on('idle', onIdle);
    player.on('buffer', onBuffer);
    player.on('play', onPlay);
    player.on('pause', onPaus);

    setStatus(player.getState() as PlayerStatus);

    return () => {
      player.off('idle', onIdle);
      player.off('buffer', onBuffer);
      player.off('play', onPlay);
      player.off('pause', onPaus);

      setStatus(undefined);
    };
  }, [player, setState]);

  useJwPlayerScope(player);

  return (
    <section ref={ref} />
  );
};

export default JwPlayer;
