import React, { useMemo } from 'react';
import { ReflectionStoreContext, createReflectionStore } from './context';

const ReflectionStoreProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const { children } = props;

  const store = useMemo(() => createReflectionStore(), []);

  return (
    <ReflectionStoreContext.Provider value={store}>
      {children}
    </ReflectionStoreContext.Provider>
  );
};

export default ReflectionStoreProvider;
