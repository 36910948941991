import React from 'react';
import useCurrentUserQuery from '../../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../../common/types/Atlas';
import { SearchResultLink } from './list-item';

interface SearchDeviceResultProps {
  device: Atlas.SearchDevice;
}

const SearchDeviceResult = (props: SearchDeviceResultProps) => {
  const { device } = props;

  const currentUserQuery = useCurrentUserQuery();
  const isSuperAdmin = currentUserQuery.data?.admin === Atlas.AdministratorType.Super;

  const avatarSrc = (() => {
    switch (device.type) {
      case Atlas.DeviceType.DiscoveryKit: {
        return '/assets/img/discovery_kit1_device.png';
      }
      case Atlas.DeviceType.UploadBox: {
        return '/assets/img/upload_box_device.png';
      }
      case Atlas.DeviceType.Lv215:
      case Atlas.DeviceType.Lv5512:
      case Atlas.DeviceType.Lv5514:
      default: {
        return '/assets/img/lv_5512_device.png';
      }
    }
  })();

  return (
    <SearchResultLink href={`/admin/devices/${device.id}/edit`}>
      <div className="tw-avatar">
        <div className="tw-rounded-xl tw-w-10">
          <img src={avatarSrc} alt={__('Device avatar')} />
        </div>
      </div>

      <div>
        <div className="tw-text-sm tw-opacity-60">
          {__('Device')}
          {isSuperAdmin ? (
            ` #${device.id}`
          ) : null}
        </div>
        <div>
          {device.name}
        </div>
      </div>
    </SearchResultLink>
  );
};

export default SearchDeviceResult;
