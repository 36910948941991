import React from 'react';
import { Modal, ModalBox } from '../../../common/components/TailwindModal';
import useDownloadVideoMutation from '../../../common/hooks/api/videos/useDownloadVideoMutation';
import * as Atlas from '../../../common/types/Atlas';
import Spinner from '../../../common/components/Spinner';

interface VideoDownloadRequestProps {
  videoId: Atlas.VideoID;
  onClose: () => void;
}

const VideoDownloadRequest = (props: VideoDownloadRequestProps) => {
  const { videoId, onClose } = props;

  const downloadVideo = useDownloadVideoMutation();

  switch (downloadVideo.status) {
    case 'idle': {
      const handleRequestClick = () => {
        downloadVideo.mutate({ params: { videoId } });
      };

      return (
        <>
          <p className="tw-text-lg tw-mb-3 tw-font-semibold">
            {__('Approval required')}
          </p>

          <p className="tw-mb-4">
            {__('You require approval to download this video. Would you like to submit a download request?')}
          </p>

          <div className="tw-flex tw-justify-end tw-gap-3">
            <button
              type="button"
              className="tw-btn tw-btn-ghost"
              onClick={onClose}
            >
              {__('Cancel')}
            </button>

            <button
              type="button"
              className="tw-btn tw-btn-success"
              onClick={handleRequestClick}
            >
              {__('Request download')}
            </button>
          </div>
        </>
      );
    }

    case 'loading': {
      return (
        <div className="tw-text-center tw-my-10">
          <Spinner color="info" />
        </div>
      );
    }

    case 'success': {
      return (
        <>
          <p className="tw-text-lg tw-mb-3 tw-font-semibold">
            {__('Download request submitted')}
          </p>

          <p className="tw-mb-4">
            {__('You will receive an email once your request has been approved or rejected.')}
          </p>

          <div className="tw-flex tw-justify-end">
            <button
              type="button"
              className="tw-btn"
              onClick={onClose}
            >
              {__('Close')}
            </button>
          </div>
        </>
      );
    }

    case 'error': {
      const handleRetryClick = () => {
        downloadVideo.mutate({ params: { videoId } });
      };

      return (
        <>
          <p className="tw-text-lg tw-mb-3 tw-font-semibold">
            {__('Oops! Something went wrong.')}
          </p>

          <p className="tw-mb-4">
            {__('Your download request has not been submitted. Do you want to retry?')}
          </p>

          <div className="tw-flex tw-justify-end tw-gap-3">
            <button
              type="button"
              className="tw-btn tw-btn-ghost"
              onClick={onClose}
            >
              {__('Cancel')}
            </button>

            <button
              type="button"
              className="tw-btn"
              onClick={handleRetryClick}
            >
              {__('Retry')}
            </button>
          </div>
        </>
      );
    }

    default: return null;
  }
};

interface VideoDownloadRequestModalProps {
  videoId: Atlas.VideoID;
  isOpen: boolean;
  onClose: () => void;
}

const VideoDownloadRequestModal = (props: VideoDownloadRequestModalProps) => {
  const { videoId, isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBox>
        <VideoDownloadRequest videoId={videoId} onClose={onClose} />
      </ModalBox>
    </Modal>
  );
};

export default VideoDownloadRequestModal;
