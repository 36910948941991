import React from 'react';
import { useParams } from 'react-router-dom';
import VideoEditor from '../../../../modules/video-editor';
import { VideoID } from '../../../../common/types/Atlas';
import useVideoPlayer from '../../../../modules/jwplayer/useVideoPlayer';
import useReflectionStore from '../../../../modules/reflections/show/store/useReflectionStore';
import { ReflectionModal } from '../../../../modules/reflections/show/store/reflection-modals.store';

const ReflectionVideosPage = (): JSX.Element => {
  const params = useParams<{ reflectionId: string; }>();
  const reflectionId = Number(params.reflectionId);

  const videoPlayer = useVideoPlayer();

  const {
    changeModal,
  } = useReflectionStore((state) => ({
    changeModal: state.changeModal,
  }));


  return (
    <VideoEditor
      reflectionId={reflectionId}
      onEditVideoClick={(videoId: VideoID) => {
        videoPlayer?.pause();
        changeModal({ modal: ReflectionModal.EditVideo, videoId });
      }}
    />
  );
};

export default ReflectionVideosPage;
