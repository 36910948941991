import React from 'react';
import useFindReflectionQuery from '../../../common/hooks/api/reflections/useFindReflectionQuery';
import * as Atlas from '../../../common/types/Atlas';
import useReflectionStore from './store/useReflectionStore';
import { ReflectionModal } from './store/reflection-modals.store';
import { isUserOwnedReflection } from './utils/is-user-owned-reflection';
import useFeatureToggle from '../../../common/hooks/useFeatureToggle';

interface ReflectionAdditionalMenuProps {
  reflectionId: Atlas.ReflectionID;
  focusMode: boolean;
}

const ReflectionAdditionalMenu = (props: ReflectionAdditionalMenuProps) => {
  const { reflectionId, focusMode } = props;

  const {
    changeModal,
  } = useReflectionStore((state) => ({
    changeModal: state.changeModal,
  }));

  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const reflection = reflectionQuery.data?.data;
  const userOwned = reflection && isUserOwnedReflection(reflection);

  const reflectionEmbedToggleEnabled = useFeatureToggle('reflection_embed');

  return (
    <div className={focusMode ? 'tw-hidden' : 'tw-flex tw-gap-2'}>
      {userOwned ? (
        <>
          <button
            type="button"
            className="tw-btn tw-btn-xs tw-rounded-full tw-btn-white"
            onClick={() => changeModal({ modal: ReflectionModal.Sharing })}
          >
            {__('Sharing')}
          </button>
        </>
      ) : null}

      {userOwned && reflectionEmbedToggleEnabled ? (
        <>
          <button
            type="button"
            className="tw-btn tw-btn-xs tw-rounded-full tw-btn-white"
            onClick={() => changeModal({ modal: ReflectionModal.Embed })}
          >
            {__('Embed')}
          </button>
        </>
      ) : null}

      {userOwned ? (
        <>
          <button
            type="button"
            className="tw-btn tw-btn-xs tw-rounded-full tw-btn-white"
            onClick={() => changeModal({ modal: ReflectionModal.Privacy })}
          >
            {__('Privacy')}
          </button>
        </>
      ) : null}

      <button
        type="button"
        className="tw-btn tw-btn-xs tw-rounded-full tw-btn-white"
        onClick={() => changeModal({ modal: ReflectionModal.Details })}
        data-id="reflection-details"
      >
        {__('Details')}
      </button>
    </div>
  );
};

export default ReflectionAdditionalMenu;
