import React from 'react';
import GroupDiscussionsList from '../../../../modules/discussions/GroupDiscussionsList';
import { useParams } from 'react-router-dom';

const GroupDiscussionsPage = (): JSX.Element => {
  const params = useParams<{ groupId: string; }>();
  const groupId = Number(params.groupId);

  return (
    <GroupDiscussionsList groupId={groupId} />
  );
};

export default GroupDiscussionsPage;
