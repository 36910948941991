import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject } from '../../../api-parser';
import ApiError from '../../../error';
import { generateFetchHeaders } from '../../../routes';

const reorderRefectionVideoRoute = (args: {
  videoId: Atlas.VideoID;
}): string => `/api/v0/videos/${args.videoId}/reorder`;

export interface ReorderReflectionVideoParams {
  reflectionId: Atlas.ReflectionID;
  videoId: Atlas.VideoID;
}

export interface ReorderReflectionVideoBody {
  to: number;
}

export interface ReorderReflectionVideoArguments {
  params: ReorderReflectionVideoParams;
  body: ReorderReflectionVideoBody;
}

export interface ReorderReflectionVideoResponse {
  data: Atlas.Reflection;
}

export const reorderReflectionVideo = async (
  args: ReorderReflectionVideoArguments,
): Promise<ReorderReflectionVideoResponse> => {
  const { videoId } = args.params;

  const url = reorderRefectionVideoRoute({ videoId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      to: args.body.to,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'reorderReflectionVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Reflection>(body.data);

  return { data };
};
