import { FormElement } from "../../common/types/Atlas";

export const formElementIcon = (formElement: FormElement): string => {
  const type = formElement.field.type;

  if ('text' in type) {
    return 'icon-quote-left';
  } else if ('multiple_choice' in type) {
    return 'icon-check';
  } else if ('rating' in type) {
    return 'icon-dashboard';
  } else if ('timer' in type) {
    return 'icon-time';
  } else if ('counter' in type) {
    return 'icon-bar-chart';
  } else if ('url' in type) {
    return 'icon-link';
  } else if ('attachment' in type) {
    return 'icon-paperclip';
  } else if ('reflection' in type) {
    return 'icon-film';
  }

  return '';
};
