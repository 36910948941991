
import React, { useState } from 'react';
import Spinner from '../../../common/components/Spinner';
import useFindDiscussionQuery from '../../../common/hooks/api/discussions/useFindDiscussionQuery';
import useUpdateDiscussionMutation from '../../../common/hooks/api/discussions/useUpdateDiscussionMutation';
import * as Atlas from '../../../common/types/Atlas';

const notificationLevels = [{
  value: Atlas.NotificationLevel.Minimal,
  label: __('Quiet'),
  description: __('No comments'),
  icon: 'fa fa-volume-off',
}, {
  value: Atlas.NotificationLevel.OrganizationOnly,
  label: __('Medium'),
  description: __('Comments from the same organisation'),
  icon: 'fa fa-volume-down',
}, {
  value: Atlas.NotificationLevel.All,
  label: __('Loud'),
  description: __('All comments'),
  icon: 'fa fa-volume-up',
}];

interface NotificationLevelFormProps {
  discussionId: Atlas.DiscussionID;
}

interface NotificationLevelFormState {
  notificationLevel?: Atlas.NotificationLevel;
}

const NotificationLevelForm = (props: NotificationLevelFormProps): JSX.Element => {
  const { discussionId } = props;

  const discussionQuery = useFindDiscussionQuery({ discussionId });
  const updateDiscussion = useUpdateDiscussionMutation({ discussionId });

  const [state, setState] = useState<NotificationLevelFormState>({});

  if (discussionQuery.isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner color="info" />
      </div>
    );
  }

  const discussion = discussionQuery.data?.data;

  if (!discussion) {
    return (
      <div>
        Oops! Something went wrong.
      </div>
    );
  }

  const updateNotificationLevel = (nextNotificationLevel: Atlas.NotificationLevel) => {
    setState((s) => ({ ...s, notificationLevel: nextNotificationLevel }));

    updateDiscussion.mutateAsync({
      notification_level: nextNotificationLevel,
    }).catch(() => {
      setState((s) => ({ ...s, notificationLevel: undefined }));
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (updateDiscussion.isLoading) { return; }

    const nextNotificationLevel = event.currentTarget.value;

    switch (nextNotificationLevel) {

      case Atlas.NotificationLevel.All:
      case Atlas.NotificationLevel.Minimal:
      case Atlas.NotificationLevel.None:
      case Atlas.NotificationLevel.OrganizationOnly: {
        break;
      }

      default: return;
    }

    updateNotificationLevel(nextNotificationLevel);
  };

  const handleLevelClick = (nextNotificationLevel: Atlas.NotificationLevel) => () => {
    updateNotificationLevel(nextNotificationLevel);
  };

  const notificationLevel = state.notificationLevel ?? discussion.notification_level;

  return (
    <div>
      <div className="d-flex justify-content-center my-3">
        <select
          data-testid="notification-level"
          name="notificationLevel"
          value={notificationLevel}
          onChange={handleSelectChange}
        >
          {notificationLevels.map((nl) => (
            <option
              key={nl.value}
              value={nl.value}
              data-testid="notification-level-option"
            >
              {nl.label}
            </option>
          ))}
        </select>

        {updateDiscussion.isLoading ? (
          <Spinner size="sm" color="info" className="ml-2" />
        ) : null}
      </div>

      <div className="py-3 modalHelp">
        <ul className="d-flex helpList">
          {notificationLevels.map((nl) => (
            <li key={nl.value} style={{ cursor: 'pointer' }} onClick={handleLevelClick(nl.value)}>
              <div className="eachHelpMessage">
                <i className={nl.icon} />
                <h4 className="my-2">
                  {' '}
                  {nl.label}
                  {' '}
                </h4>
              </div>
              <p className="my-0 px-3">
                {nl.description}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NotificationLevelForm;
