import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders } from '../../routes';

export const discussionCommentRoute = (args: {
  discussionCommentId: Atlas.DiscussionCommentID,
}): string => `/api/v0/discussion_comments/${args.discussionCommentId}`;

export interface UpdateDiscussionCommentParams {
  discussionCommentId: Atlas.DiscussionCommentID;
}

export type UpdateDiscussionCommentBody = Partial<Atlas.DiscussionComment>;

export interface UpdateDiscussionCommentArguments {
  params: UpdateDiscussionCommentParams;
  body: UpdateDiscussionCommentBody;
}

export interface UpdateDiscussionCommentResponse {
  data: Atlas.ExpandedDiscussionComment;
}

export const updateDiscussionComment = async (
  args: UpdateDiscussionCommentArguments,
): Promise<UpdateDiscussionCommentResponse> => {
  const { discussionCommentId } = args.params;

  const url = discussionCommentRoute({ discussionCommentId });

  const response = await fetch(url, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updateDiscussionComment');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.ExpandedDiscussionComment>(body.data);

  return { data };
};

export interface DestroyDiscussionCommentParams {
  discussionCommentId: Atlas.DiscussionCommentID;
}

export interface DestroyDiscussionCommentArguments {
  params: DestroyDiscussionCommentParams;
}

export interface DestroyDiscussionCommentResponse {
  data: Atlas.DiscussionComment;
}

export const destroyDiscussionComment = async (
  args: DestroyDiscussionCommentArguments,
): Promise<DestroyDiscussionCommentResponse> => {
  const { discussionCommentId } = args.params;

  const url = discussionCommentRoute({ discussionCommentId });

  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'destroyDiscussionComment');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.DiscussionComment>(body.data);

  return { data };
};
