import { useMutation, UseMutationResult } from 'react-query';
import { updateGroupDiscussionsCudRole, UpdateGroupDiscussionsCudRoleBody, UpdateGroupDiscussionsCudRoleParams, UpdateGroupDiscussionsCudRoleResponse } from '../../../api/groups/[groupId]/update-discussions-cud-role';
import { queryClient } from '../../withQueryClient';

const useUpdateGroupDiscussionsCudRoleMutation = (
  params: UpdateGroupDiscussionsCudRoleParams,
): UseMutationResult<UpdateGroupDiscussionsCudRoleResponse, unknown, UpdateGroupDiscussionsCudRoleBody, unknown> => {
  const mutation = useMutation((body: UpdateGroupDiscussionsCudRoleBody) => {
    return updateGroupDiscussionsCudRole({ params, body });
  }, {
    onSuccess: (result) => {
      const group = result.data;
      queryClient.setQueryData(['groups', group.id], result);
      queryClient.invalidateQueries(['groups'], { exact: true });
      queryClient.invalidateQueries(['groups', 'joinable']);
      queryClient.invalidateQueries(['groups', 'offers']);
    },
  });

  return mutation;
};

export default useUpdateGroupDiscussionsCudRoleMutation;
