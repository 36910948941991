import React from 'react';
import { useBlocker } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import { Modal, ModalBox } from './TailwindModal';
import classNames from 'classnames';

const RouterPromptModal: React.FC<{
  onProceed?: () => void;
  onCancel?: () => void;
  isProceeding?: boolean;
  children: string;
}> = ({
  onProceed,
  onCancel,
  isProceeding,
  children,
}) => {
  return (
    <Modal isOpen>
      <ModalBox>
        <p className="tw-text-lg tw-mb-6">
          {children}
        </p>

        <div className="tw-flex tw-justify-end tw-gap-2">
          <button
            type="button"
            className="tw-btn"
            disabled={!onCancel}
            onClick={onCancel ? () => onCancel() : undefined}
          >
            {__('Cancel')}
          </button>

          <button
            type="button"
            className="tw-btn tw-btn-error"
            disabled={!onProceed}
            onClick={onProceed ? () => onProceed() : undefined}
          >
            {isProceeding ? <span className="tw-loading" /> : null}
            {__('Proceed')}
          </button>
        </div>
      </ModalBox>
    </Modal>
  );
};

const RouterPrompt: React.FC<{
  when: boolean;
  message: string;
}> = (props) => {
  const { when, message } = props;

  useBeforeUnload(when, message);

  try {
    const blocker = useBlocker(when);

    switch (blocker.state) {
      case 'blocked':
      case 'proceeding': return (
        <RouterPromptModal
          onProceed={blocker.proceed}
          onCancel={blocker.reset}
          isProceeding={blocker.state === 'proceeding'}
        >
          {message}
        </RouterPromptModal>
      );
      default: return null
    }
  } catch {
    // Component isn't within a react router context
    return null;
  }
};

export default RouterPrompt;
