import * as Atlas from "../types/Atlas";

type ExpandedAssignmentJson = Atlas.ExpandedAssignment & {
  due_date?: string;
  created_at: string;
  updated_at: string;
}

export const parseAssignmentJson = (json: ExpandedAssignmentJson): Atlas.ExpandedAssignment => {
  return {
    ...json,
    due_date: json.due_date ? new Date(json.due_date) : undefined,
    created_at: new Date(json.created_at),
    updated_at: new Date(json.updated_at),
  };
};
