import React from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Atlas from '../../../../common/types/Atlas';

interface MilestoneFormHeaderProps {
  groupId: Atlas.GroupID;
}

const MilestoneFormHeader = (props: MilestoneFormHeaderProps): JSX.Element => {
  const params = useParams<{ containerId: string; }>();
  const containerId = Number(params.containerId);

  return (
    <>
      <div>
        <div className="tw-flex tw-items-center tw-justify-between tw-iris-element-box tw-mb-3">
          <Link className="tw-btn tw-btn-sm" to={`/pathways_management/${containerId}/templates/${props.groupId}/milestones`}>
            <i className="fa fa-chevron-left tw-mr-1" />
            {__('Back')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default MilestoneFormHeader;
