import React from 'react';
import { defaultGroupBanner } from '../shared';

const GroupDefaultBanner = () => (
  <img
    alt={__('Group banner')}
    draggable={false}
    src={defaultGroupBanner}
    className="tw-relative tw-object-cover tw-object-left tw-min-w-full tw-min-h-full"
  />
);

export default GroupDefaultBanner;
