import * as Atlas from '../../types/Atlas';
import { generateFetchHeaders } from '../routes';
import { inRange } from '../../../common/utils/utils';
import ApiError from '../../../common/api/error';
import { parseModelObject } from '../api-parser';

export const assignmentSubmissionsFeedbackRoute = ({ assignmentSubmissionId }: { assignmentSubmissionId: Atlas.AssignmentSubmissionID }): string => `/api/v0/assignment_submissions/${assignmentSubmissionId}/save_feedback`;

interface UpdateAssignmentSubmissionFeedbackBody {
  page_id?: Atlas.PageID;
  complete?: Atlas.SubmissionComplete;
  grade?: string;
  feedback?: string;
  notify_submitter: boolean;
}

export interface UpdateAssignmentSubmissionFeedbackArgs {
  params: {
    assignmentSubmissionId: Atlas.AssignmentSubmissionID;
  };
  body: UpdateAssignmentSubmissionFeedbackBody,
}

export interface UpdateAssignmentSubmissionFeedbackResponse {
  data: Atlas.ExpandedAssignmentSubmission;
}

export const updateAssignmentSubmissionFeedback = async (
  args: UpdateAssignmentSubmissionFeedbackArgs,
): Promise<UpdateAssignmentSubmissionFeedbackResponse> => {
  const { assignmentSubmissionId } = args?.params || {};

  const url = assignmentSubmissionsFeedbackRoute({ assignmentSubmissionId });

  const response = await fetch(url, {
    method: 'POST',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'reorderMilestones');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.ExpandedAssignmentSubmission>(body);

  return { data };
};
