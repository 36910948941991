import { Boundary, Placement } from '@popperjs/core';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';

export const Popover: React.FC<{
  target?: HTMLElement | null;
  placement: Placement;
  isOpen: boolean;
  boundariesElement?: Boundary;
  children: React.ReactNode;
}> = ({
  target,
  placement,
  isOpen,
  boundariesElement,
  children,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(target, popperElement, {
    placement,
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'preventOverflow', options: { boundary: boundariesElement } },
    ],
  });

  if (!isOpen) { return null; }

  return (
    <div
      ref={setPopperElement}
      style={styles.popper}
      className="popover"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes.popper}
    >
      {children}
    </div>

  );
};

export const PopoverBody: React.FC<{
  children: React.ReactNode;
}> = ({
  children,
}) => (
  <div
    className="popover-body"
  >
    {children}
  </div>
);
