import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObjectArray } from '../../../api-parser';
import ApiError from '../../../error';
import { jsonHeaders, Metadata, PaginationParams, searchParams } from '../../../routes';

export const discussionUsersRoute = (args: {
  discussionId: Atlas.DiscussionID;
}): string => (
  `/api/v0/discussions/${args.discussionId}/users`
);

export type FindDiscussionUsersParams = PaginationParams & {
  discussionId: Atlas.DiscussionID;
  search?: string;
}

export interface FindDiscussionUsersArguments {
  params: FindDiscussionUsersParams;
}

export interface FindDiscussionUsersResponse {
  data: Atlas.DiscussionMentionUser[];
  metadata: Metadata;
}

export const findDiscussionUsers = async (
  args: FindDiscussionUsersArguments,
): Promise<FindDiscussionUsersResponse> => {
  const { discussionId, ...params } = args.params;

  const url = [
    discussionUsersRoute({ discussionId }),
    searchParams({ ...params }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findDiscussionUsers');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.DiscussionMentionUser>(body.data);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};
