import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject } from '../../../api-parser';
import ApiError from '../../../error';
import { generateFetchHeaders } from '../../../routes';

const replicateVideoRoute = (args: {
  videoId: Atlas.VideoID;
}): string => `/api/v0/videos/${args.videoId}/replicate`;

export interface ReplicateVideoParams {
  videoId: Atlas.VideoID;
}

export interface ReplicateVideoBody {
  reflectionId: Atlas.ReflectionID | null;
}

export interface ReplicateVideoArguments {
  params: ReplicateVideoParams;
  body: ReplicateVideoBody;
}

export interface ReplicateVideoResponse {
  data: Atlas.Video;
}

export const replicateVideo = async (
  args: ReplicateVideoArguments,
): Promise<ReplicateVideoResponse> => {
  const { videoId } = args.params;

  const url = replicateVideoRoute({ videoId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      reflection_id: args.body.reflectionId,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'replicateVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Video>(body.data);

  return { data };
};
