import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import ApiError from '../../../error';
import { generateFetchHeaders } from '../../../routes';

const transcribeVideoRoute = (args: {
  videoId: Atlas.VideoID;
}): string => `/api/v0/videos/${args.videoId}/transcribe`;

export interface TranscribeVideoParams {
  videoId: Atlas.VideoID;
}

export interface TranscribeVideoArguments {
  params: TranscribeVideoParams;
}

export type TranscribeVideoResponse = void;

export const transcribeVideo = async (
  args: TranscribeVideoArguments,
): Promise<TranscribeVideoResponse> => {
  const { videoId } = args.params;

  const url = transcribeVideoRoute({ videoId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'transcribeVideo');
  }
};
