import * as Atlas from '../../types/Atlas';
import { inRange } from '../../utils/utils';
import { parseModelObjectArray } from '../api-parser';
import ApiError from '../error';
import {
  jsonHeaders, Metadata, PaginationParams, searchParams,
} from '../routes';

export const searchRoute = (): string => '/api/v0/search';

export type SearchParams = {
  query: string;
  excludeUsers?: boolean;
  excludeDevices?: boolean;
  excludeGroups?: boolean;
  excludeOrganizations?: boolean;
  excludeReflections?: boolean;
  includeTrashed?: boolean;
} & PaginationParams;

export interface SearchArguments {
  params: SearchParams;
}

interface SearchResultsWithSuggestions {
  results: Atlas.SearchResult[];
  completions: string[];
}

export interface SearchResponse {
  data: SearchResultsWithSuggestions;
  metadata: Metadata;
}

export const search = async (
  args: SearchArguments,
): Promise<SearchResponse> => {
  const { params } = args;

  const url = [
    searchRoute(),
    searchParams({
      query: params.query,
      users: params.excludeUsers ? 'false' : undefined,
      devices: params.excludeDevices ? 'false' : undefined,
      groups: params.excludeGroups ? 'false' : undefined,
      organizations: params.excludeOrganizations ? 'false' : undefined,
      reflections: params.excludeReflections ? 'false' : undefined,
      include_trashed: params.includeTrashed ? 'true' : undefined,
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'search');
  }

  const body = await response.json() as {
    data: {
      results: object[];
      completions: string[];
    };
    metadata: unknown;
  };

  const results = parseModelObjectArray<Atlas.SearchResult>(body.data.results);
  const { completions } = body.data;
  const metadata = body.metadata as Metadata;

  return {
    data: {
      results,
      completions,
    },
    metadata,
  };
};
