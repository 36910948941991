import React, { useState, useEffect } from 'react';
import useFindFeatureToggleQuery from '../../../../common/hooks/api/features/useFindFeatureToggleQuery';
import * as Atlas from '../../../../common/types/Atlas';

interface ReflectionPlaybackModeFormProps {
  playbackMode: Atlas.PlaybackMode;
  onSubmit: (playbackMode: Atlas.PlaybackMode) => Promise<void>;
}

const ReflectionPlaybackModeForm = (props: ReflectionPlaybackModeFormProps) => {
  const { playbackMode, onSubmit } = props;

  const videoAnonymisedDefaceToggleQuery = useFindFeatureToggleQuery({
    featureToggle: 'video_anonymised_deface',
  });

  const enableAnonymisedDefaceOption = !!videoAnonymisedDefaceToggleQuery.data?.enabled;

  const [nextPlaybackMode, setNextPlaybackMode] = useState(playbackMode);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setNextPlaybackMode(playbackMode);
  }, [playbackMode]);

  const handlePlaybackModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.PlaybackMode.Normal:
      case Atlas.PlaybackMode.Anonymised:
      case Atlas.PlaybackMode.AnonymisedDeface: break;
      default: return;
    }

    setNextPlaybackMode(value);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (updating) { return; }

    setUpdating(true);
    onSubmit(nextPlaybackMode).finally(() => {
      setUpdating(false);
    });
  };

  const formDisabled = nextPlaybackMode === playbackMode;

  return (
    <form onSubmit={handleFormSubmit}>
      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Video Mode')}
      </p>

      <div className="tw-mb-2">
        <label className="tw-cursor-pointer tw-flex tw-gap-2">
          <input
            type="radio"
            name="playbackMode"
            value={Atlas.PlaybackMode.Normal}
            checked={nextPlaybackMode === Atlas.PlaybackMode.Normal}
            className="!tw-m-0"
            onChange={handlePlaybackModeChange}
          />
          <span className="tw-label-text">
            {__('Standard')}
          </span>
        </label>

        <label className="tw-cursor-pointer tw-flex tw-gap-2">
          <input
            type="radio"
            name="playbackMode"
            value={Atlas.PlaybackMode.Anonymised}
            checked={nextPlaybackMode === Atlas.PlaybackMode.Anonymised}
            className="!tw-m-0"
            onChange={handlePlaybackModeChange}
          />
          <span className="tw-label-text">
            {__('Anonymise')}
          </span>
        </label>

        {enableAnonymisedDefaceOption ? (
          <label className="tw-cursor-pointer tw-flex tw-gap-2">
            <input
              type="radio"
              name="playbackMode"
              value={Atlas.PlaybackMode.AnonymisedDeface}
              checked={nextPlaybackMode === Atlas.PlaybackMode.AnonymisedDeface}
              className="!tw-m-0"
              onChange={handlePlaybackModeChange}
            />
            <span className="tw-label-text">
              {__('Face Blur (BETA)')}
            </span>
          </label>
        ) : null}
      </div>

      <p className="tw-text-gray-600 tw-mb-2">
        {__('Note: If selecting the Anonymise option there will be a delay in playback as the system prepares the video.')}
      </p>

      <div className="tw-flex tw-justify-end tw-mt-3">
        <button
          type="submit"
          className="tw-btn tw-btn-sm tw-btn-success"
          disabled={formDisabled}
        >
          {updating ? <span className="tw-loading" /> : null}
          {__('Update Video Mode')}
        </button>
      </div>
    </form>
  );
};

export default ReflectionPlaybackModeForm;
