import React, { useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import VideoEditor from '../../video-editor';
import DynamicMenu from '../DynamicMenu';
import ReflectionForms from '../ReflectionForms';
import useFindReflectionQuery from '../../../common/hooks/api/reflections/useFindReflectionQuery';
import ReflectionComments from '../reflection-comments/ReflectionComments';
import ReflectionFiles from '../reflection-files/ReflectionFiles';
import { isPresent } from '../../../common/utils/utils';
import useReflectionStore from './store/useReflectionStore';
import { ReflectionTab } from './store/reflection-tabs.store';
import { ReflectionModal } from './store/reflection-modals.store';
import { isUserOwnedReflection } from './utils/is-user-owned-reflection';
import useFeatureToggle from '../../../common/hooks/useFeatureToggle';
import useVideoPlayer from '../../jwplayer/useVideoPlayer';
import { Outlet, useLocation } from 'react-router-dom';

interface ReflectionTabsProps {
  reflectionId: Atlas.ReflectionID;
  focusMode?: boolean;
  toggleFocusMode?: () => void;
}

const ReflectionTabs = (props: ReflectionTabsProps) => {
  const { reflectionId, focusMode, toggleFocusMode } = props;

  const location = useLocation();
  const basePath = location.pathname.startsWith('/lti') ? '/lti/reflections' : '/reflections';

  const videoPlayer = useVideoPlayer();

  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const reflection = reflectionQuery.data?.data;

  const userOwned = reflection && isUserOwnedReflection(reflection);

  const reflectionEmbedToggleEnabled = useFeatureToggle('reflection_embed');

  const {
    changeModal,
  } = useReflectionStore((state) => ({
    changeModal: state.changeModal,
  }));

  const formsEnabled = reflection?.form_mode !== Atlas.FormMode.Disabled;

  const mainItems = [
    {
      id: 'comments',
      label: __('Comments'),
      activeClassName: 'tw-btn tw-btn-primary tw-rounded-full tw-btn-xs xs:tw-btn-sm',
      href: `${basePath}/${reflectionId}`,
    },
    {
      id: 'videos',
      label: __('Videos'),
      activeClassName: 'tw-btn tw-btn-primary tw-rounded-full tw-btn-xs xs:tw-btn-sm',
      href: `${basePath}/${reflectionId}/videos`,
    },
    formsEnabled ? ({
      id: 'forms',
      label: __('Insights'),
      activeClassName: 'tw-btn tw-btn-primary tw-rounded-full tw-btn-xs xs:tw-btn-sm',
      href: `${basePath}/${reflectionId}/forms`,
      hasSubPaths: true,
    }) : undefined,
    {
      id: 'files',
      label: __('Files'),
      activeClassName: 'tw-btn tw-btn-primary tw-rounded-full tw-btn-xs xs:tw-btn-sm',
      href: `${basePath}/${reflectionId}/files`,
    },
  ].filter(isPresent);

  const additionalItems = [
    userOwned ? {
      className: focusMode ? 'tw-hidden' : undefined,
      id: 'sharing',
      label: __('Sharing'),
      onClick: () => changeModal({ modal: ReflectionModal.Sharing }),
    } : undefined,
    userOwned && reflectionEmbedToggleEnabled ? {
      className: focusMode ? 'tw-hidden' : undefined,
      id: 'embed',
      label: __('Embed'),
      onClick: () => changeModal({ modal: ReflectionModal.Embed }),
    } : undefined,
    userOwned ? {
      className: focusMode ? 'tw-hidden' : undefined,
      id: 'privacy',
      label: __('Privacy'),
      onClick: () => changeModal({ modal: ReflectionModal.Privacy }),
    } : undefined,
    {
      className: focusMode ? 'tw-hidden' : undefined,
      id: 'details',
      label: __('Details'),
      onClick: () => changeModal({ modal: ReflectionModal.Details }),
    },
  ].filter(isPresent);

  const allItems = [
    ...mainItems,
    ...additionalItems,
  ];

  return (
    <div className="tw-flex tw-flex-col tw-h-full">
      <div className="lg:tw-hidden tw-flex tw-w-full tw-mb-2 xs:tw-mb-4">
        <div className="tw-flex-1 tw-w-[calc(100%-40px)] lg:tw-w-full">
          <DynamicMenu
            defaultButtonClassName={`tw-btn tw-btn-ghost tw-rounded-full tw-btn-xs xs:tw-btn-sm ${focusMode ? 'tw-text-base-100' : ''}`}
            items={allItems}
          />
        </div>
        {toggleFocusMode ? (
          <button type="button" className={`tw-btn tw-btn-xs xs:tw-btn-sm tw-btn-error tw-btn-circle lg:tw-hidden ${focusMode ? '' : 'tw-hidden'}`} onClick={toggleFocusMode}>
            <i className="fa fa-arrows-alt" />
          </button>
        ) : null}
      </div>

      <div className="tw-hidden lg:tw-block tw-mb-4">
        <DynamicMenu
          defaultButtonClassName={`tw-btn tw-btn-ghost tw-rounded-full tw-btn-sm ${focusMode ? 'tw-text-base-100' : ''}`}
          items={mainItems}
        />
      </div>

      <div className="tw-h-full">
        <Outlet />
      </div>
    </div>
  );
};

export default ReflectionTabs;
