import React, { ComponentProps, useState } from 'react';
import useFindCommentQuery from '../../../common/hooks/api/comments/useFindCommentQuery';
import useUpdateCommentMutation from '../../../common/hooks/api/comments/useUpdateCommentMutation';
import * as Atlas from '../../../common/types/Atlas';

type MediaCommentChanges = {
  synchronicity?: Atlas.CommentSynchronicity;
};

const MediaCommentSettingsForm: React.FC<{
  synchronicity: Atlas.CommentSynchronicity;
  onSubmit: (values: MediaCommentChanges) => Promise<void>;
}> = (props) => {
  const {
    synchronicity: currentSynchronicity,
    onSubmit,
  } = props;

  const [state, setState] = useState<{
    loading: boolean,
    success: boolean,
    changes: MediaCommentChanges;
  }>({
    loading: false,
    success: false,
    changes: {},
  });

  const handleSynchronicityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.CommentSynchronicity.Pause:
      case Atlas.CommentSynchronicity.Simultaneous: {
        setState((s) => ({
          ...s,
          success: false,
          error: false,
          changes: {
            ...s.changes,
            synchronicity: value,
          }
        }));
        break;
      }
    }
  };

  const hasNoChanges = Object.keys(state.changes).length === 0;

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (hasNoChanges) { return; }
    if (state.loading) { return; }

    setState((s) => ({ ...s, loading: true, success: false, error: false }));
    onSubmit(state.changes).then(() => {
      setState((s) => ({ ...s, changes: {}, success: true }));
    }, () => {
      setState((s) => ({ ...s, error: true }));
    }).finally(() => {
      setState((s) => ({ ...s, loading: false }));
    });
  };

  const synchronicity = state.changes.synchronicity ?? currentSynchronicity;

  return (
    <form onSubmit={handleSubmit}>
      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-2">
        {__('Comment Synchronicity')}
      </p>

      <div className="tw-form-control tw-mb-4 tw-space-y-2">
        <label className="tw-flex tw-gap-3 tw-mb-0">
          <input
            type="radio"
            className="!tw-radio !tw-radio-info !tw-outline-none !tw-m-0"
            name="synchronicity"
            value={Atlas.CommentSynchronicity.Pause}
            checked={synchronicity === Atlas.CommentSynchronicity.Pause}
            onChange={handleSynchronicityChange}
          />
          <div>
            <div className="tw-font-semibold tw-text-base">
              {__('Pause')}
            </div>
            <div>
              {__('Pause the reflection video when this media comment is played.')}
            </div>
          </div>
        </label>

        <label className="tw-flex tw-gap-3 tw-mb-0">
          <input
            type="radio"
            className="!tw-radio !tw-radio-info !tw-outline-none !tw-m-0"
            name="synchronicity"
            value={Atlas.CommentSynchronicity.Simultaneous}
            checked={synchronicity === Atlas.CommentSynchronicity.Simultaneous}
            onChange={handleSynchronicityChange}
          />
          <div>
            <div className="tw-font-semibold tw-text-base">
              {__('Simultaneous')}
            </div>
            <div>
              {__('Play media comment simultaneously with the reflection video.')}
            </div>
          </div>
        </label>
      </div>

      <div className="tw-flex tw-justify-start tw-mt-6">
        <button
          type="submit"
          className="tw-btn tw-btn-success"
          disabled={hasNoChanges}
        >
          {state.loading ? <span className="tw-loading" /> : null}
          {__('Update')}
        </button>
      </div>

      {state.success ? (
        <div className="tw-alert tw-alert-success tw-mt-2">
          {__('Settings updated successfully.')}
        </div>
      ) : null}
    </form>
  );
};

const MediaCommentSettings: React.FC<{
  commentId: Atlas.CommentID;
}> = (props) => {
  const { commentId } = props;

  const commentQuery = useFindCommentQuery({ commentId });
  const updateComment = useUpdateCommentMutation();

  const handleSubmit: ComponentProps<typeof MediaCommentSettingsForm>['onSubmit'] = async (changes) => {
    await updateComment.mutateAsync({
      params: { commentId },
      body: changes,
    });
  };

  switch (commentQuery.status) {
    case 'idle':
    case 'loading': {
      return (
        <div className="tw-flex tw-justify-center tw-py-10">
          <i className="fa fa-circle-o-notch fa-spin tw-text-info tw-text-4xl" />
        </div>
      );
    }

    case 'error': {
      return (
        <div className="tw-grid tw-place-items-center">
          <p className="tw-text-lg tw-font-semibold tw-py-4">
            {__('Oops! Something went wrong.')}
          </p>

          <button
            type="button"
            className="tw-btn tw-btn-wide"
            onClick={() => commentQuery.refetch()}
          >
            {__('Reload')}
          </button>
        </div>
      );
    }

    case 'success': {
      const comment = commentQuery.data.data;

      return (
        <MediaCommentSettingsForm
          synchronicity={comment.synchronicity}
          onSubmit={handleSubmit}
        />
      );
    }
  }
};

export default MediaCommentSettings;
