import styled from "@emotion/styled";

export const AspectRatio = styled.div<{
  ratio: string;
}>`
  position: relative;

  ::before {
    height: 0;
    content: "";
    display: block;
    padding-bottom: calc(100% / (${props => props.ratio}));
  }

  > :first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;
