import { mean } from 'lodash-es';

class VolumeMeter {
  audioContext: AudioContext;
  analyser: AnalyserNode;
  microphone: MediaStreamAudioSourceNode;
  processor: ScriptProcessorNode;

  constructor(stream: MediaStream) {
    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.microphone = this.audioContext.createMediaStreamSource(stream);
    this.processor = this.audioContext.createScriptProcessor(2048, 1, 1);
  }

  start(callback: (volume: number) => void) {
    this.microphone.connect(this.analyser);
    this.analyser.connect(this.processor);
    this.processor.connect(this.audioContext.destination);

    this.processor.onaudioprocess = () => {
      const array = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.getByteFrequencyData(array);

      const average = mean(array);

      callback(average);
    };
  }

  stop() {
    this.processor.onaudioprocess = null;

    this.processor.disconnect();
    this.microphone.disconnect();
    this.analyser.disconnect();
    this.audioContext.close();
  }
}

export default VolumeMeter;
