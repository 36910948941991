import React from 'react';
import { Modal, ModalBox, ModalHeader } from '../../../../common/components/TailwindModal';
import * as Atlas from '../../../../common/types/Atlas';
import {
  getMilestoneResourceString,
  isAssignmentCompleteMilestone,
  isAssignmentSubmitMilestone,
  isAttachmentMilestone,
  isButtonMilestone,
  isDiscussionMilestone,
  isFeedbackMilestone,
  isFormMilestone,
  isGuideMilestone,
  isLinkReflectionMilestone,
  isPageMilestone,
  isReflectionMilestone
} from '../utils/utils';
import useFindGroupPagesQuery from '../../../../common/hooks/api/groups/useFindGroupPagesQuery';
import { withQueryClient } from '../../../../common/hooks/withQueryClient';
import useFindGroupReflectionsQuery from '../../../../common/hooks/api/groups/useFindGroupReflectionsQuery';
import useFindGroupAssignmentsQuery from '../../../../common/hooks/api/groups/useFindGroupAssignments';
import useFindGroupDiscussionsQuery from '../../../../common/hooks/api/groups/useFindGroupDiscussionsQuery';
import useFindGroupAttachmentsQuery from '../../../../common/hooks/api/groups/useFindGroupAttachments';
import useFindGroupFormsQuery from '../../../../common/hooks/api/groups/useFindGroupFormsQuery';
import { replaceKeys } from '../../../utils/utils';
import Spinner from '../../../../common/components/Spinner';

type Resource = Atlas.ExpandedReflection | Atlas.Discussion | Atlas.Assignment | Atlas.Page | Atlas.Attachment | Atlas.Form;

interface RenderItemProps {
  resource: Resource;
  displayText: string;
  onClick: (resourceId: number) => void;
}

const RenderItem: React.FC<RenderItemProps> = ({ resource, displayText, onClick }) => (
  <div
    onClick={() => onClick(resource.id)}
    className="tw-iris-element-box tw-mb-2 hover:tw-bg-gray-200 tw-cursor-pointer item"
    key={resource.id}
  >
    {displayText}
  </div>
);

interface RenderReflectionItemProps {
  reflection: Atlas.ExpandedReflection;
  onClick: (resourceId: number) => void;
}

const RenderReflectionItem: React.FC<RenderReflectionItemProps> = ({ reflection, onClick }) => (
  <div
    onClick={() => onClick(reflection.id)}
    className="tw-flex tw-items-center tw-iris-element-box tw-mb-2 hover:tw-bg-gray-200 tw-cursor-pointer item"
    key={reflection.id}
  >
    <img
      className="tw-h-14 tw-w-14 tw-rounded"
      src={reflection.thumbnail_s3uri ? reflection.thumbnail_s3uri.url : "/assets/img/groups/page_snippets/video.gif"}
    />
    <div className="tw-ml-4">
      {reflection.name}
    </div>
  </div>
);

interface PageListProps {
  pages: Atlas.Page[];
  onSelectPage: (pageId: number) => void;
}

const PageList: React.FC<PageListProps> = ({ pages, onSelectPage }) => {
  return (
    <div>
      {pages.map(page => (
        <RenderItem
          key={page.id}
          resource={page}
          displayText={page.title}
          onClick={onSelectPage}
        />
      ))}
    </div>
  )
}

interface PageListContainerProps {
  type: Atlas.MilestoneType;
  groupId: Atlas.GroupID;
  onSelectResource: (type: Atlas.MilestoneType) => void;
}

const PageListContainer: React.FC<PageListContainerProps> = ({type, groupId, onSelectResource}) => {
  const {
    data: pages,
    isLoading,
    isIdle,
    isFetching,
  } = useFindGroupPagesQuery({ groupId: groupId, per_page: 100 });

  const filteredPages = pages?.data.filter(page => {
    if (isGuideMilestone(type)) {
      return page.type === 'guide';
    } else if (isLinkReflectionMilestone(type)) {
      return page.type === 'reflection_select'
    }
    return page.type === 'page' || page.type === 'guide' || page.type === 'reflection_select';
  }) || [];

  const sortedPages = filteredPages.sort((a, b) => a.page_no - b.page_no);

  const setPageResource = (pageId: number) => {
    if (isPageMilestone(type)) {
      type.visit_page.page_id = pageId;
      onSelectResource(type);
    } else if (isGuideMilestone(type)) {
      type.link_guide.page_id = pageId;
      onSelectResource(type);
    } else if (isLinkReflectionMilestone(type)) {
      type.link_reflection.page_id = pageId;
      onSelectResource(type);
    }
  };

  if ((isLoading || isIdle || isFetching)) {
    return (
      <div className="tw-flex tw-justify-center">
        <Spinner color="info" />
      </div>
    );
  }

  if (!sortedPages || sortedPages.length === 0) {
    return (
      <h1 className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
        {__('No pages')}
      </h1>
    )
  }

  return (
    <PageList pages={sortedPages} onSelectPage={setPageResource}/>
  )
}

interface ReflectionListProps {
  reflections: Atlas.ExpandedReflection[];
  onSelectReflection: (reflectionId: number) => void;
}

const ReflectionList: React.FC<ReflectionListProps> = ({ reflections, onSelectReflection }) => {
  return (
    <div>
      {reflections.map(reflection => (
        <RenderReflectionItem
          key={reflection.id}
          reflection={reflection}
          onClick={onSelectReflection}
        />
      ))}
    </div>
  )
}

interface ReflectionListContainerProps {
  type: Atlas.MilestoneType;
  groupId: Atlas.GroupID;
  onSelectResource: (type: Atlas.MilestoneType) => void;
}

const ReflectionListContainer: React.FC<ReflectionListContainerProps> = ({type, groupId, onSelectResource}) => {
  const {
    data: reflections,
    isLoading,
    isIdle,
    isFetching,
  } = useFindGroupReflectionsQuery({ groupId: groupId, per_page: 100 });

  const setReflectionResource = (reflectionId: number) =>  {
    if (isReflectionMilestone(type)){
      type.watch_reflection.reflection_id = reflectionId;
      onSelectResource(type);
    }
  }

  return (
    <>
      {(isLoading ||
        isIdle ||
        isFetching) ? (
          <div className="tw-flex tw-justify-center">
            <Spinner color="info" />
          </div>
      ) : reflections && reflections.data.length > 0 ? (
        <ReflectionList reflections={reflections.data} onSelectReflection={setReflectionResource}/>
      ) : (
        <h1 className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
          {__('No reflections')}
        </h1>
      )}
    </>
  )
}

interface AssignmentListProps {
  assignments: Atlas.Assignment[];
  onSelectPage: (assignmentId: number) => void;
}

const AssignmentList: React.FC<AssignmentListProps> = ({ assignments, onSelectPage }) => {
  return (
    <div>
      {assignments.map(assignment => (
        <RenderItem
          key={assignment.id}
          resource={assignment}
          displayText={assignment.name}
          onClick={onSelectPage}
        />
      ))}
    </div>
  )
}

interface AssignmentListContainerProps {
  type: Atlas.MilestoneType;
  groupId: Atlas.GroupID;
  onSelectResource: (type: Atlas.MilestoneType) => void;
}

const AssignmentListContainer: React.FC<AssignmentListContainerProps> = ({type, groupId, onSelectResource}) => {
  const {
    data: assignments,
    isLoading,
    isIdle,
    isFetching,
  } = useFindGroupAssignmentsQuery({ groupId: groupId, per_page: 100 });

  const setAssignmentResource = (assignmentId: number) =>  {
    if (isAssignmentSubmitMilestone(type)){
      type.submit_to_assignment.assignment_id = assignmentId;
      onSelectResource(type);
    } else if (isAssignmentCompleteMilestone(type)) {
      type.complete_assignment.assignment_id = assignmentId;
      onSelectResource(type);
    }
  }

  return (
    <>
      {(isLoading ||
        isIdle ||
        isFetching) ? (
          <div className="tw-flex tw-justify-center">
            <Spinner color="info" />
          </div>
      ) : assignments && assignments.data.length > 0 ? (
        <AssignmentList assignments={assignments.data} onSelectPage={setAssignmentResource}/>
      ) : (
        <h1 className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
          {__('No assignments')}
        </h1>
      )}
    </>
  )
}

interface DiscussionListProps {
  discussions: Atlas.Discussion[];
  onSelectDiscussion: (discussionId: number) => void;
}

const DiscussionList: React.FC<DiscussionListProps> = ({ discussions, onSelectDiscussion }) => {
  return (
    <div>
      {discussions.map(discussion => (
        <RenderItem
          key={discussion.id}
          resource={discussion}
          displayText={discussion.title}
          onClick={onSelectDiscussion}
        />
      ))}
    </div>
  )
}

interface DiscussionListContainerProps {
  type: Atlas.MilestoneType;
  groupId: Atlas.GroupID;
  onSelectResource: (type: Atlas.MilestoneType) => void;
}

const DiscussionListContainer: React.FC<DiscussionListContainerProps> = ({type, groupId, onSelectResource}) => {
  const {
    data: discussions,
    isLoading,
    isIdle,
    isFetching,
  } = useFindGroupDiscussionsQuery({ groupId: groupId, per_page: 100 });

  const setDiscussionResource = (discussionId: number) =>  {
    if (isDiscussionMilestone(type)){
      type.discuss.discussion_id = discussionId;
      onSelectResource(type);
    }
  }

  return (
    <>
      {(isLoading ||
        isIdle ||
        isFetching) ? (
          <div className="tw-flex tw-justify-center">
            <Spinner color="info" />
          </div>
      ) : discussions && discussions.data.length > 0 ? (
        <DiscussionList discussions={discussions.data} onSelectDiscussion={setDiscussionResource}/>
      ) : (
        <h1 className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
          {__('No discussions')}
        </h1>
      )}
    </>
  )
}

interface AttachmentListProps {
  attachments: Atlas.Attachment[];
  onSelectAttachment: (attachmentId: number) => void;
}

const AttachmentList: React.FC<AttachmentListProps> = ({ attachments, onSelectAttachment }) => {
  return (
    <div>
      {attachments.map(attachment => (
        <RenderItem
          key={attachment.id}
          resource={attachment}
          displayText={attachment.name}
          onClick={onSelectAttachment}
        />
      ))}
    </div>
  )
}

interface AttachmentListContainerProps {
  type: Atlas.MilestoneType;
  groupId: Atlas.GroupID;
  onSelectResource: (type: Atlas.MilestoneType) => void;
}

const AttachmentListContainer: React.FC<AttachmentListContainerProps> = ({type, groupId, onSelectResource}) => {
  const {
    data: attachments,
    isLoading,
    isIdle,
    isFetching,
  } = useFindGroupAttachmentsQuery({ groupId: groupId, per_page: 100 });

  const setAttachmentResource = (attachmentId: number) =>  {
    if (isAttachmentMilestone(type)){
      type.download_attachment.attachment_id = attachmentId;
      onSelectResource(type);
    }
  }

  return (
    <>
      {(isLoading ||
        isIdle ||
        isFetching) ? (
          <div className="tw-flex tw-justify-center">
            <Spinner color="info" />
          </div>
      ) : attachments && attachments.data.length > 0 ? (
        <AttachmentList attachments={attachments.data} onSelectAttachment={setAttachmentResource}/>
      ) : (
        <h1 className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
          {__('No attachments')}
        </h1>
      )}
    </>
  )
}

interface InsightListProps {
  insights: Atlas.Form[];
  onSelectInsight: (insightId: number) => void;
}

const InsightList: React.FC<InsightListProps> = ({ insights, onSelectInsight }) => {
  return (
    <div>
      {insights.map(insight => (
        <RenderItem
          key={insight.id}
          resource={insight}
          displayText={insight.name}
          onClick={onSelectInsight}
        />
      ))}
    </div>
  )
}

interface InsightListContainerProps {
  type: Atlas.MilestoneType;
  groupId: Atlas.GroupID;
  onSelectResource: (type: Atlas.MilestoneType) => void;
}

const InsightListContainer: React.FC<InsightListContainerProps> = ({type, groupId, onSelectResource}) => {
  const {
    data: insights,
    isLoading,
    isIdle,
    isFetching,
  } = useFindGroupFormsQuery({ groupId: groupId, per_page: 100 });

  const setInsightResource = (insightId: number) =>  {
    if (isFormMilestone(type)){
      type.submit_insight.form_id = insightId;
      onSelectResource(type);
    }
  }

  return (
    <>
      {(isLoading ||
        isIdle ||
        isFetching) ? (
          <div className="tw-flex tw-justify-center">
            <Spinner color="info" />
          </div>
      ) : insights && insights.data.length > 0 ? (
        <InsightList insights={insights.data} onSelectInsight={setInsightResource}/>
      ) : (
        <h1 className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
          {__('No insights')}
        </h1>
      )}
    </>
  )
}

interface ResourceListProps {
  type: Atlas.MilestoneType;
  groupId: Atlas.GroupID;
  onSelectResource: (type: Atlas.MilestoneType) => void;
}

const ResourceList: React.FC<ResourceListProps> = ({type, groupId, onSelectResource}) => {
  if (isPageMilestone(type) || isGuideMilestone(type) || isLinkReflectionMilestone(type)) {
    return (
      <PageListContainer
        type={type}
        groupId={groupId}
        onSelectResource={onSelectResource}
      />
    );
  } else if (isReflectionMilestone(type)) {
    return (
      <ReflectionListContainer
        type={type}
        groupId={groupId}
        onSelectResource={onSelectResource}
      />
    );
  } else if (isAssignmentSubmitMilestone(type) || isAssignmentCompleteMilestone(type)) {
    return (
      <AssignmentListContainer
        type={type}
        groupId={groupId}
        onSelectResource={onSelectResource}
      />
    )
  } else if (isDiscussionMilestone(type)) {
    return (
      <DiscussionListContainer
        type={type}
        groupId={groupId}
        onSelectResource={onSelectResource}
      />
    )
  } else if (isAttachmentMilestone(type)) {
    return (
      <AttachmentListContainer
        type={type}
        groupId={groupId}
        onSelectResource={onSelectResource}
      />
    )
  } else if (isFormMilestone(type)) {
    return (
      <InsightListContainer
        type={type}
        groupId={groupId}
        onSelectResource={onSelectResource}
      />
    )
  } else {
    return null;
  }
}

interface ResourceSelectorModalProps{
  isOpen: boolean;
  milestoneType: Atlas.MilestoneType;
  groupId: Atlas.GroupID;
  onSelectResource: (type: Atlas.MilestoneType) => void;
  onClose: () => void;
}

const ResourceSelectorModal = ({
  isOpen,
  milestoneType,
  groupId,
  onSelectResource,
  onClose,
}: ResourceSelectorModalProps): JSX.Element => {

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBox className="tw-bg-gray-50">
        <ModalHeader onClose={onClose}>
          <h1>
            {replaceKeys(
              __('Select {{resource}}'),
              { resource: (!isButtonMilestone(milestoneType) && !isFeedbackMilestone(milestoneType)) 
                ? getMilestoneResourceString(milestoneType) 
                : ''
              },
            )}
          </h1>
        </ModalHeader>
      <div className="tw-bg-gray-50">
        <ResourceList
          type={milestoneType}
          groupId={groupId}
          onSelectResource={onSelectResource}/>
      </div>
      </ModalBox>
    </Modal>
  );
};

export default withQueryClient(ResourceSelectorModal);
