import React from 'react';

// UI
import {
  PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer, TooltipProps,
} from 'recharts';

// Utils
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { renderTimestamp, generateTimestamp } from '../../../../common/components/timerTimestamp';
import { pickColor } from '../../../../common/utils/colors';
import * as Atlas from '../../../../common/types/Atlas';
import { pieLegendFormatter } from '../legendFormatter';
import useVideoPlayer from '../../../jwplayer/useVideoPlayer';

interface Payload {
  choice: string;
  duration: number;
}

const TimerTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { active, payload } = props;
  if (!active) { return null; }
  if (!payload) { return; }

  const [data] = payload;

  return (
    <div className="tw-p-3 tw-bg-white tw-border tw-shadow">
      <div className="tw-font-bold">{data.payload.choice}</div>
      <div>
        {`${__('Duration')}:`}
        {renderTimestamp(data.payload.duration)}
      </div>
    </div>
  );
};

interface FormTimerPieChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

const FormTimerPieChart = (props: FormTimerPieChartProps): JSX.Element => {
  const { formAnswers = [] } = props;

  const videoPlayer = useVideoPlayer();

  const data: Record<number, Record<string, number>> = {};
  videoPlayer?.playlist.forEach((videoId) => {
    data[videoId] = {};
  });

  formAnswers.forEach((fa) => {
    fa.timer_value?.forEach((tv) => {
      tv.intervals.forEach((interval) => {
        const start = Math.floor(interval.start.time / 1000) * 1000;
        const end = Math.floor(interval.end.time / 1000) * 1000;

        const vId = interval.start.video_id;
        const duration = end - start;

        data[vId] ||= {};
        data[vId][tv.choice] ||= 0;
        data[vId][tv.choice] += duration;
      });
    });
  });

  const chartsData = Object.entries(data).map(([vId, data]) => ({
    videoId: vId,
    chartData: Object.entries(data).map(([choice, duration]): Payload => ({ choice, duration })),
  }));

  return (
    <div>
      {chartsData.map(({ videoId, chartData }) => (
        chartData.length ? (
          <ResponsiveContainer key={videoId} height={300} width="100%">
            <PieChart>
              <Pie
                data={chartData}
                outerRadius={80}
                label={({ value, percent }) => `${generateTimestamp(value)} (${Math.floor((percent ?? 0) * 100).toFixed(0)}%)`}
                nameKey="choice"
                dataKey="duration"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${entry.choice}`} fill={pickColor(index)} />
                ))}
              </Pie>
              <Legend
                formatter={pieLegendFormatter}
              />
              <Tooltip content={TimerTooltip} />
            </PieChart>
          </ResponsiveContainer>
        ) : null
      ))}
    </div>
  );
};

export default FormTimerPieChart;
