import React from 'react';
import * as Atlas from '../../common/types/Atlas';
import Discussion from './Discussion';
import { getUsersGroupMemberRole, userHasRole } from '../../common/utils/group-permissions';
import DiscussionListControls from './components/DiscussionListControls';
import useFindGroupQuery from '../../common/hooks/api/groups/useFindGroupQuery';
import useFindGroupDiscussionsQuery from '../../common/hooks/api/groups/useFindGroupDiscussionsQuery';
import useCurrentUserQuery from '../../common/hooks/api/useCurrentUserQuery';
import GroupContext from './GroupContext';
import Spinner from '../../common/components/Spinner';

interface GroupDiscussionsListProps {
  groupId: Atlas.GroupID;
}

const GroupDiscussionsList = (props: GroupDiscussionsListProps): JSX.Element => {
  const { groupId } = props;

  const currentUserQuery = useCurrentUserQuery();
  const user = currentUserQuery.data;

  const groupQuery = useFindGroupQuery(groupId);
  const group = groupQuery.data?.data;

  const canManageDiscussions = (() => {
    if (!user) { return false; }
    if (user.admin === Atlas.AdministratorType.Super) { return true; }

    if (!group) { return false; }

    const groupInfo = group.group;
    if (!groupInfo) { return false; }

    const userMemberRole = getUsersGroupMemberRole(groupInfo, user.id, user.organization_id);
    if (!userMemberRole) { return false; }
    const requiredMemberRole = groupInfo.discussions_cud_role;

    return userHasRole(userMemberRole, requiredMemberRole);
  })();

  const discussionsQuery = useFindGroupDiscussionsQuery({ groupId });
  const discussions = discussionsQuery.data?.data;

  return (
    <GroupContext.Provider value={group}>
      <div className="position-relative">
        <DiscussionListControls
          groupId={groupId}
          className="mb-4"
        />

        {currentUserQuery.isLoading || discussionsQuery.isLoading ? (
          <div className="d-flex justify-content-center py-5">
            <Spinner color="info" />
          </div>
        ) : null}

        {discussions?.map((discussion: Atlas.Discussion) => (
          <div key={discussion.id} className="mb-4">
            <Discussion
              discussion={discussion}
              checkPermissions={canManageDiscussions}
            />
          </div>
        ))}
      </div>
    </GroupContext.Provider>
  );
};

export default GroupDiscussionsList;
