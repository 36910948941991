import React, { useEffect } from 'react';
import useAttachmentUploader from './useAttachmentUploader';
import * as Atlas from '../../../../common/types/Atlas';
import AttachmentLink from '../../../../common/components/AttachmentLink';
import { Button } from '../../../../common/components/Button';

export interface AttachmentFormAnswer {
  name: string;
  attachment_value?: Atlas.AttachmentID;
}

interface AttachmentQuestionProps {
  readOnly?: boolean;
  question: Atlas.FormElement;
  answer?: AttachmentFormAnswer;
  showValidation?: boolean;
  onAnswerChange?(fa: AttachmentFormAnswer): void;
}

const AttachmentQuestion = (props: AttachmentQuestionProps): JSX.Element => {
  const { question, answer, readOnly, showValidation } = props,
    questionName = question.field.name;

  const { files, selectFile, uploadFile, deleteAttachment, uploading, progress } = useAttachmentUploader();

  useEffect(() => {
    if (uploading || !files?.length) { return; }

    if (props.onAnswerChange && !readOnly) {
      uploadFile().then(attachment => {
        props.onAnswerChange?.({
          name: questionName,
          ...answer,
          attachment_value: attachment.id
        });
      });
    }
  }, [props.onAnswerChange, props.readOnly, uploading, files, uploadFile]);

  if (uploading) {
    return (
      <progress className="tw-progress tw-progress-primary tw-h-4" value={progress} max={100} />
    );
  }

  const attachmentId = answer?.attachment_value;

  const handleAttachClick = () => {
    selectFile();
  };

  const handleDeleteClick = () => {
    if (!answer?.attachment_value) { return; }

    if (props.onAnswerChange) {
      props.onAnswerChange({
        ...answer,
        attachment_value: undefined
      });
  
      deleteAttachment(answer.attachment_value);
    }
  };

  return (
    <div>
      <div className={`tw-mb-2 tw-p-1 tw-flex ${!attachmentId && question.field.required && showValidation ? 'tw-border tw-border-error/40 tw-ring-1 tw-ring-error/40 tw-rounded-lg' : ''}`}>
        {attachmentId ? (
          <AttachmentLink attachmentId={attachmentId} />
        ) : (
          <div className="overflow-auto bg-light px-3 py-2">
            <span className="text-muted">
              {__('No file uploaded')}
            </span>
          </div>
        )}

        {attachmentId && !readOnly ? (
          <Button className="text-danger" size="small" color="link" onClick={handleDeleteClick}>
            <i className="fa fa-trash" />
          </Button>
        ) : null}
      </div>

      {!attachmentId && !readOnly ? (
        <Button color="primary" className="mt-2" onClick={handleAttachClick}>
          <i className="fa fa-paperclip mr-2" />
          <span>{__('Attach')}</span>
        </Button>
      ) : null}
    </div>
  )
};

export default AttachmentQuestion;
