import React, { useState } from 'react';
import FormTextLineChart from './form-text-charts/LineChart';
import * as Atlas from '../../../common/types/Atlas';

interface FormTextChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

enum Page {
  Line,
}

const FormTextChart = (props: FormTextChartProps): JSX.Element => {
  const { formElement, formAnswers = [] } = props;

  const [state, setState] = useState({
    page: Page.Line,
  });

  const handlePageClick = (page: Page) => {
    setState((s) => ({ ...s, page }));
  };

  return (
    <div>
      <div className="tw-flex tw-justify-end">
        <button
          type="button"
          className={`tw-btn tw-btn-xs ${state.page === Page.Line ? 'tw-btn-info' : ''}`}
          onClick={() => handlePageClick(Page.Line)}
        >
          <i className="fa fa-line-chart" />
        </button>
      </div>

      {(() => {
        switch (state.page) {
          case Page.Line: {
            return (
              <FormTextLineChart formElement={formElement} formAnswers={formAnswers} />
            );
          }

          default: return null;
        }
      })()}
    </div>
  );
};

export default FormTextChart;
