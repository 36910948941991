import React, { useLayoutEffect, useState, useRef } from 'react';
import * as Atlas from '../../../common/types/Atlas';

interface QuickCreateReflectionFormProps {
  onCreate: (name: Atlas.ReflectionName) => Promise<void>;
}

const QuickCreateReflectionForm = (props: QuickCreateReflectionFormProps): JSX.Element => {
  const { onCreate } = props;

  const form = useRef<HTMLFormElement>(null);
  const [reportValidity, setReportValidity] = useState(false);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState('');

  useLayoutEffect(() => {
    if (!reportValidity) { return; }

    form.current?.reportValidity();
  }, [reportValidity]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setName(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!name) {
      setReportValidity(true);
      return;
    }

    setSaving(true);
    onCreate(name).finally(() => {
      setSaving(false);
    });
  };

  return (
    <form ref={form} onSubmit={handleSubmit}>
      <label className="tw-join">
        <input
          required={reportValidity}
          className="tw-input tw-input-bordered tw-flex-1 tw-join-item"
          placeholder={__('Enter a new reflection name')}
          value={name}
          onChange={handleNameChange}
        />

        <button type="submit" className="tw-btn tw-btn-success tw-join-item">
          {saving ? <span className="tw-loading" /> : null}
          {__('Create')}
        </button>
      </label>
    </form>
  );
};

export default QuickCreateReflectionForm;
