import React from 'react';
import useCurrentUserQuery from '../../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../../common/types/Atlas';
import { SearchResultLink } from './list-item';

interface SearchReflectionResultProps {
  reflection: Atlas.SearchReflection;
}

const SearchReflectionResult = (props: SearchReflectionResultProps) => {
  const { reflection } = props;

  const currentUserQuery = useCurrentUserQuery();
  const isSuperAdmin = currentUserQuery.data?.admin === Atlas.AdministratorType.Super;

  const avatarSrc = reflection.thumbnail_s3uri?.url || '/assets/img/video_placeholder.svg';

  return (
    <SearchResultLink href={`/reflections/${reflection.id}`}>
      <div className="tw-rounded-xl tw-w-10 tw-aspect-video tw-overflow-hidden">
        <img src={avatarSrc} alt={__('Reflection thumbnail')} className="tw-w-full tw-h-full tw-object-cover" />
      </div>

      <div>
        <div className="tw-text-sm tw-opacity-60">
          {__('Reflection')}
          {isSuperAdmin ? (
            ` #${reflection.id}`
          ) : null}
        </div>
        <div>
          {reflection.name}
        </div>
      </div>
    </SearchResultLink>
  );
};

export default SearchReflectionResult;
