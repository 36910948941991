import { useMutation, UseMutationResult } from 'react-query';
import {
  updatePage, UpdatePageBody, UpdatePageParams, UpdatePageResponse,
} from '../../../api/pages/[pageId]';
import { queryClient } from '../../withQueryClient';

const useUpdatePageMutation = (
  params: UpdatePageParams,
): UseMutationResult<UpdatePageResponse, unknown, UpdatePageBody, unknown> => {
  const mutation = useMutation((body: UpdatePageBody) => updatePage({ params, body }), {
    onSuccess: (result) => {
      const page = result.data;
      queryClient.setQueryData(['pages', page.id], result);
      void queryClient.invalidateQueries(['pages'], { exact: true });
      void queryClient.invalidateQueries(['groups', page.group_id, 'pages']);
    },
  });

  return mutation;
};

export default useUpdatePageMutation;
