import { useMutation, UseMutationResult } from 'react-query';
import { createMilestone, CreateMilestoneArguments, CreateMilestoneResponse } from '../../api/index';
import { queryClient } from '../../../../../common/hooks/withQueryClient';
import * as Atlas from '../../../../../common/types/Atlas';

type UseCreateMilestoneMutationResult = 
  UseMutationResult<CreateMilestoneResponse, unknown, CreateMilestoneArguments>

const useCreateMilestoneMutation = (
  groupId: Atlas.GroupID,
): UseCreateMilestoneMutationResult => {
  const mutation = useMutation(createMilestone, {
    onSuccess: () => {
      queryClient.invalidateQueries(['groups', groupId, 'milestones']);
    }
  });
  return mutation;
};

export default useCreateMilestoneMutation;