import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartsData } from './types';
import { pickExtendedColor } from '../../common/utils/colors';
import { aggregateChartsData } from './utils';

const transformToRechartsData = (aggregatedData: Map<string, number>) =>
  [...aggregatedData.entries()].reduce<
    Array<{
      name: string;
      total: number;
    }>
  >((acc, [name, total]) => {
    acc.push({ name, total });
    return acc;
  }, []);

const MetricsBarChart: React.FC<{
  chartsData: ChartsData;
  dateStart?: string;
  dateEnd?: string;
}> = ({ chartsData, dateStart, dateEnd }) => {
  const data = transformToRechartsData(
    aggregateChartsData(chartsData, dateStart, dateEnd),
  );

  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        layout="vertical"
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey="total" type="number" />
        <YAxis dataKey="name" type="category" />

        <Tooltip />

        <Bar dataKey="total">
          {data.map((entry, index) => (
            <Cell key={`cell-${entry.name}`} fill={pickExtendedColor(index)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MetricsBarChart;
