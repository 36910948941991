import { useQuery, UseQueryResult } from 'react-query';
import { findFeatureToggle, FindFeatureToggleParams, FindFeatureToggleResponse } from '../../../api/features';

interface UseQueryOptions {
  staleTime?: number;
}

const useFindFeatureToggleQuery = (
  params: FindFeatureToggleParams,
  options?: UseQueryOptions,
): UseQueryResult<FindFeatureToggleResponse> => (
  useQuery(['features', params.featureToggle], () => findFeatureToggle({ params }), {
    staleTime: Infinity,
    ...options,
  })
);

export default useFindFeatureToggleQuery;
