import React from 'react';
import CommentResourceLink from './CommentResourceLink';

const CommentResourceLinkReflection: React.FC<{
  name: string;
  iconUrl?: string;
  href?: string;
  inaccessible?: boolean;
}> = (props) => {
  const {
    name,
    iconUrl = '/assets/img/video_placeholder.svg',
    href,
    inaccessible
  } = props;

  return (
    <CommentResourceLink
      type={__('Reflection')}
      name={name}
      icon={
        <div className="tw-rounded-lg tw-aspect-video tw-overflow-hidden">
          <img src={iconUrl} alt={__('Reflection thumbnail')} className="tw-w-full tw-h-full tw-object-cover" />
        </div>
      }
      href={href}
      inaccessible={inaccessible}
    />
  );
};

export default CommentResourceLinkReflection;
