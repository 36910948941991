import React, { ComponentProps } from 'react';
import useFindFeatureToggleQuery from '../../../../common/hooks/api/features/useFindFeatureToggleQuery';
import useFindReflectionQuery from '../../../../common/hooks/api/reflections/useFindReflectionQuery';
import usePrepareReflectionMutation from '../../../../common/hooks/api/reflections/usePrepareReflectionMutation';
import useUpdateReflectionMutation from '../../../../common/hooks/api/reflections/useUpdateReflectionMutation';
import * as Atlas from '../../../../common/types/Atlas';
import ReflectionAutoDeleteForm from './ReflectionAutoDeleteForm';
import ReflectionOtherModesForm from './ReflectionOtherModesForm';
import ReflectionPlaybackModeForm from './ReflectionPlaybackModeForm';
import Spinner from '../../../../common/components/Spinner';

const renderLoader = () => (
  <section className="tw-flex tw-justify-center tw-py-5">
    <Spinner color="info" />
  </section>
);

interface ReflectionPrivacyFormsProps {
  reflectionId: Atlas.ReflectionID;
}

const ReflectionPrivacyForms = (props: ReflectionPrivacyFormsProps) => {
  const { reflectionId } = props;

  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const updateReflection = useUpdateReflectionMutation();
  const prepareReflection = usePrepareReflectionMutation();

  const autoDeleteEnabledQuery = useFindFeatureToggleQuery({ featureToggle: 'auto_delete' });

  const handlePlaybackModeSubmit = async (playbackMode: Atlas.PlaybackMode) => {
    await prepareReflection.mutateAsync({
      params: { reflectionId },
      body: {
        playback_mode: playbackMode,
      },
    });
  };

  const handleModesSubmit: ComponentProps<typeof ReflectionOtherModesForm>['onSubmit'] = async (changes) => {
    await updateReflection.mutateAsync({
      params: { reflectionId },
      body: {
        attachment_mode: changes.attachmentMode,
        comment_mode: changes.commentMode,
        form_mode: changes.formMode,
      },
    });
  };

  const handleAutoDeleteSubmit: ComponentProps<typeof ReflectionAutoDeleteForm>['onSubmit'] = async (changes) => {
    await updateReflection.mutateAsync({
      params: { reflectionId },
      body: {
        expires_at: changes.expiresAt,
      },
    });
  };

  switch (reflectionQuery.status) {
    case 'success': {
      const reflection = reflectionQuery.data.data;

      if (autoDeleteEnabledQuery.isLoading) {
        return renderLoader();
      }

      return (
        <section>
          <ReflectionPlaybackModeForm
            playbackMode={reflection.playback_mode}
            onSubmit={handlePlaybackModeSubmit}
          />

          <hr className="tw-my-4" />

          <ReflectionOtherModesForm
            attachmentMode={reflection.attachment_mode}
            commentMode={reflection.comment_mode}
            formMode={reflection.form_mode}
            onSubmit={handleModesSubmit}
          />

          {autoDeleteEnabledQuery.data?.enabled ? (
            <>
              <hr className="tw-my-4" />

              <ReflectionAutoDeleteForm
                expiresAt={reflection.expires_at}
                onSubmit={handleAutoDeleteSubmit}
              />
            </>
          ) : null}
        </section>
      );
    }

    case 'error': {
      return (
        <section className="tw-flex tw-justify-center tw-py-5">
          {__('Something went wrong.')}
        </section>
      );
    }

    case 'loading':
    default: {
      return renderLoader();
    }
  }
};

export default ReflectionPrivacyForms;
