import classNames from 'classnames';
import { useState } from 'react';
import ResourcesSearch from '../../../search/resource-search';
import { ResourceSearchResult } from '../../../search/resource-search/common/types';
import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import CommentResourceLinkReflection from './comment-resource-link/CommentResourceLinkReflection';
import CommentResourceLinkGroup from './comment-resource-link/CommentResourceLinkGroup';
import { convertResourceSearchResultToResourceLink } from './utils';
import useCurrentUserQuery from '../../../../common/hooks/api/useCurrentUserQuery';

const PreviewResourceLink: React.FC<{
  resource: ResourceSearchResult;
}> = (props) => {
  const { resource } = props;

  switch (resource.type) {
    case 'reflection': {
      const reflection = resource.data;

      return (
        <CommentResourceLinkReflection
          name={reflection.name}
          iconUrl={reflection.thumbnail_s3uri?.url}
        />
      );
    }

    case 'group': {
      const group = resource.data;

      return (
        <CommentResourceLinkGroup
          name={group.name}
          type={group.group?.type}
          iconUrl={group.logo?.viewable_s3uri?.url}
        />
      );
    }
  }
};

const guideTab = {
  id: 'guide',
  searchPlaceholder: __('Search guides'),
  searchFilter: { type: 'group', filter: 'guide' },
} as const;

const walkthruTab = {
  id: 'walkthru',
  searchPlaceholder: __('Search WalkThrus'),
  searchFilter: { type: 'group', filter: 'walkthru' },
} as const;

const groupTab = {
  id: 'group',
  searchPlaceholder: __('Search groups'),
  searchFilter: { type: 'group' },
} as const;

const reflectionTab = {
  id: 'reflection',
  searchPlaceholder: __('Search reflections'),
  searchFilter: { type: 'reflection' },
} as const;

const tabs = [guideTab, walkthruTab, groupTab, reflectionTab] as const;

const CommentResourceLinkForm: React.FC<{
  initialBody?: string;
  hideBody?: boolean;
  onCancel: () => void;
  onSave: (data: {
    body?: string;
    resource_links: Atlas.CommentResourceLink[];
  }) => Promise<void>;
}> = (props) => {
  const { initialBody, hideBody, onCancel, onSave } = props;

  const [tab, setTab] = useState<typeof tabs[number]>(guideTab);
  const [resource, setResource] = useState<ResourceSearchResult | undefined>();
  const [body, setBody] = useState(initialBody);
  const [saving, setSaving] = useState(false);

  const currentUser = useCurrentUserQuery();
  const walkthrusEnabled = currentUser.data?.settings?.walkthrus_access === 'customer';

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (saving) { return; }
    if (!resource) { return; }

    const resourceLink = convertResourceSearchResultToResourceLink(resource);
    if (!resourceLink) { return; }

    setSaving(true);

    onSave({
      body,
      resource_links: [resourceLink],
    }).finally(() => {
      setSaving(false);
    });
  };

  const handleBodyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (saving) { return; }

    const { value } = event.currentTarget;
    setBody(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      {resource ? (
        <div className="tw-text-base">
          <PreviewResourceLink resource={resource} />

          <div className="tw-flex tw-justify-end">
            <button type="button" className="tw-btn tw-btn-link tw-btn-xs" disabled={saving} onClick={() => setResource(undefined)}>
              {__('Change Resource')}
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-mb-4">
            <button type="button" className={classNames('tw-btn tw-btn-sm', { 'tw-btn-primary': tab.id === 'guide' })} onClick={() => setTab(guideTab)}>
              {__('Guide')}
            </button>
            <button type="button" className={classNames('tw-btn tw-btn-sm', { 'tw-btn-primary': tab.id === 'walkthru' })} onClick={() => setTab(walkthruTab)} disabled={!walkthrusEnabled}>
              {__('WalkThru')}
            </button>
            <button type="button" className={classNames('tw-btn tw-btn-sm', { 'tw-btn-primary': tab.id === 'group' })} onClick={() => setTab(groupTab)}>
              {__('Group')}
            </button>
            <button type="button" className={classNames('tw-btn tw-btn-sm', { 'tw-btn-primary': tab.id === 'reflection' })} onClick={() => setTab(reflectionTab)}>
              {__('Reflection')}
            </button>
          </div>

          <div className="tw-mb-4">
            <ResourcesSearch
              filter={tab.searchFilter}
              placeholder={tab.searchPlaceholder}
              onResultClick={(result) => setResource(result)}
            />
          </div>
        </>
      )}

      {hideBody ? null : (
        <label className="tw-mb-0">
          <div className="tw-label">
            <span className="tw-label-text tw-font-semibold">
              {__('Comment')}
            </span>
          </div>

          <textarea
            className="tw-textarea tw-textarea-bordered tw-w-full"
            value={body}
            onChange={handleBodyChange}
          />
        </label>
      )}

      <div className="tw-flex tw-justify-between tw-gap-4 tw-mt-6">
        <button type="button" className="tw-btn" onClick={onCancel}>
          {__('Cancel')}
        </button>

        <button type="submit" className="tw-btn tw-btn-success" disabled={!resource}>
          {saving ? <span className="tw-loading" /> : null}
          {__('Link')}
        </button>
      </div>
    </form>
  );
};

export default CommentResourceLinkForm;
