
import React, { useRef, useState } from 'react';

import { Popover, PopoverBody } from '../../common/components/BSPopover';
import { Button } from '../../common/components/Button';
import { Row } from '../../common/components/Row';
import { Col } from '../../common/components/Col';
import Spinner from '../../common/components/Spinner';

import GenericBuilderFormElements from '../form-builder/builder/GenericBuilderFormElements';
import formElementValidator from '../form-builder/formElementValidator';

import { formElementOptions, generateFormElement } from './formElementOptions';

import { Link, useLocation } from 'react-router-dom';
import * as Atlas from '../../common/types/Atlas';
import { useClickAway } from 'react-use';
import { getAssignmentsBasePath } from '../../common/utils/getAssignmentsBasePath';
import { assignmentSyncReplicas } from '../../common/api/assignments';

const parseDate = date => date ? moment(date).format('YYYY-MM-DDTHH:mm') : '';

const GroupAssignmentBuilder = props => {
  const [state, setState] = useState({
    addQuestionPopoverOpen: false,
    assignment: props.assignment || {
      name: '',
      description: '',
      due_date: null,
      required_fields: Atlas.EnforceRequired,
      questions: [],
      completion: Atlas.AssignmentCompletion.Manual,
      notify_assessors: true,
      notify_submitter: true,
      show_grade: false,
      show_feedback: false
    }
  });

  const [isSynching, setIsSynching] = useState(false);
  const [message, setMessage] = useState(null);

  const isEditingLimited = props.assignment?.submissions > 0;

  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);

  const setAssignment = func => {
    setState(s => ({ ...s, assignment: func(s.assignment) }));
  };

  const now = parseDate(new Date());

  const handleSaveClick = () => {
    setState(s => ({ ...s, saving: true }));
    props.onSaveClick(state.assignment).finally(() => {
      setState(s => ({ ...s, saving: false }));
    });
  };

  const handleInputChange = event => {
    const { name, value, type } = event.currentTarget;

    const parsedValue = (() => {
      switch (type) {
        case 'datetime-local': {
          return value ? new Date(value) : null;
        }
        default:
          return value;
      }
    })();

    setAssignment(a => ({ ...a, [name]: parsedValue }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.currentTarget;

    setAssignment(a => ({ ...a, [name]: checked }));
  };

  const handleFormElementsChange = formElements => {
    setAssignment(a => ({ ...a, questions: formElements }));
  };

  const handleAddQuestionToggle = () => {
    setState(s => ({
      ...s,
      addQuestionPopoverOpen: !s.addQuestionPopoverOpen
    }));
  };

  const handleAddQuestionClick = type => () => {
    setState(s => ({
      ...s,
      addQuestionPopoverOpen: false,
      assignment: {
        ...s.assignment,
        questions: [
          ...s.assignment.questions,
          generateFormElement(type, { required: true })
        ]
      }
    }))
  };


  const handleSyncReplicas = async () => {
    if (!state.assignment.id) { return };
    if (!window.confirm(__('Are you sure you want to sync replicas of this assignment?'))) {
      return;
    }

    setIsSynching(true);
    setMessage(null);

    try {
      await assignmentSyncReplicas({ params: { assignmentId: props.assignment.id} });
      setMessage(__('Replicas synced successfully.'));
    } catch (error) {
      setMessage(__('Failed to sync replicas.'));
    } finally {
      setIsSynching(false);
    }
  };

  const isFormValid =
    !!state.assignment.name && !!state.assignment.questions?.length && state.assignment.questions.every(fe => formElementValidator(fe));

  const [popoverTarget, setPopoverTarget] = useState(null);
  const ref = useRef();
  useClickAway(ref, () => {
    setState(s => {
      if (!s.addQuestionPopoverOpen) { return s; }
      return { ...s, addQuestionPopoverOpen: false };
    });
  });

  return (
    <div className="overflow-hidden">
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-between tw-iris-element-box mb-3">
            <Link className="tw-btn tw-btn-sm" disabled={state.saving} to={`/${basePath}/${props.groupId}/assignments`}>
              <i className="fa fa-chevron-left mr-2" />
              {__('Back')}
            </Link>

            <h5 className="text-muted font-weight-light px-2 my-0">
              {props.assignment?.name ?? __('New Assignment')}
            </h5>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          <div className="tw-iris-element-box mb-3">
            <div className="form-group">
              <label className="d-block mb-2">
                {__('Name')}
                <input
                  readOnly={state.saving}
                  type="text"
                  autoFocus
                  required
                  name="name"
                  className="form-control input-block-level"
                  placeholder={__('Assignment name')}
                  value={state.assignment.name}
                  onChange={handleInputChange} />
              </label>
            </div>

            <div className="form-group">
              <label className="d-block mb-2">
                {__('Description')}
                <textarea
                  readOnly={state.saving}
                  name="description"
                  className="form-control input-block-level"
                  placeholder={__('Assignment description')}
                  value={state.assignment.description}
                  onChange={handleInputChange} />
              </label>
            </div>

            <div className="form-group">
              <label className="d-block mb-2">
                {__('Due Date')}
                <input
                  readOnly={state.saving}
                  name="due_date"
                  type="datetime-local"
                  className="form-control input-block-level"
                  min={now}
                  value={parseDate(state.assignment.due_date)}
                  onChange={handleInputChange} />
              </label>
            </div>

            <div className="form-group">
              <label className="d-block mb-2">
                {__('Mark As Complete')}
                <select
                  readOnly={state.saving}
                  name="completion"
                  className="form-control input-block-level"
                  value={state.assignment.completion}
                  onChange={handleInputChange}>
                    <option value={Atlas.AssignmentCompletion.Manual}>{__('Manually')}</option>
                    <option value={Atlas.AssignmentCompletion.Auto}>{__('Automatically')}</option>
                  </select>
              </label>
            </div>

            {props.isSuperAdmin && (
              <label className="d-block mb-2">
              {__('Required Fields')}
              <select
                readOnly={state.saving}
                name="required_fields"
                className="form-control input-block-level"
                value={state.assignment.required_fields}
                onChange={handleInputChange}>
                  <option value={Atlas.EnforceRequired.EnforceRequired}>{__('Enforce Required Fields')}</option>
                  <option value={Atlas.EnforceRequired.IgnoreRequired}>{__('Ignore Required Fields')}</option>
                </select>
            </label>
            )}

            <div className="form-group">
              <label className="d-flex align-items-center mb-2">
                <input
                  readOnly={state.saving}
                  type="checkbox"
                  name="notify_assessors"
                  className="form-control mt-0 mr-1"
                  checked={state.assignment.notify_assessors}
                  onChange={handleCheckboxChange}
                />
                {__('Notify Assessors')}
              </label>
            </div>

            <div className="form-group">
              <label className="d-flex align-items-center mb-2">
                <input
                  readOnly={state.saving}
                  type="checkbox"
                  name="notify_submitter"
                  className="form-control mt-0 mr-1"
                  checked={state.assignment.notify_submitter}
                  onChange={handleCheckboxChange}
                />
                {__('Notify Submitter')}
              </label>
            </div>

            <div className="form-group">
              <label className="d-flex align-items-center mb-2">
                <input
                  readOnly={state.saving}
                  type="checkbox"
                  name="show_grade"
                  className="form-control mt-0 mr-1"
                  checked={state.assignment.show_grade}
                  onChange={handleCheckboxChange}
                />
                {__('Show Grade')}
              </label>
            </div>

            <div className="form-group">
              <label className="d-flex align-items-center mb-2">
                <input
                  readOnly={state.saving}
                  type="checkbox"
                  name="show_feedback"
                  className="form-control mt-0 mr-1"
                  checked={state.assignment.show_feedback}
                  onChange={handleCheckboxChange}
                />
                {__('Show Feedback')}
              </label>
            </div>

            {props.isSuperAdmin && props.assignment?.id && (
              <div>
                <button
                  className="tw-btn tw-btn-warning tw-btn-sm tw-my-2"
                  onClick={handleSyncReplicas}
                  disabled={isSynching}
                  >
                  {isSynching ? __('Synching...') : __('Sync Replicas')}
                </button>
                {message && <p className="tw-text-sm tw-mt-2">{message}</p>}
              </div>
            )}
          </div>

          <div>
            <Button block color="success" size="large" className="mb-3" disabled={!isFormValid || state.saving} onClick={handleSaveClick}>
              {state.saving ? (
                <Spinner size="sm" />
              ) : props.assignment ? __('Save') : __('Create')}
            </Button>
          </div>
        </Col>

        <Col>
          {(isEditingLimited && props.isSuperAdmin) &&
            <div className="alert alert-danger tw-rounded-lg">
              <span className="tw-font-semibold">{__('Warning for Super Administrators: ')}</span>
              {__('Editing assignments with submissions can affect data validity. Make changes cautiously and only when necessary.')}
            </div>
          }
          <GenericBuilderFormElements
            showRequiredOption
            formElements={state.assignment.questions}
            isEditingLimited={isEditingLimited}
            onChange={handleFormElementsChange} />

          <div className="d-flex align-items-center justify-content-center mx-3 my-5">
            <Button
              ref={setPopoverTarget}
              size="large"
              color="primary"
              className="shadow rounded-pill"
              disabled={isEditingLimited}
              onClick={handleAddQuestionToggle}>
              <i className="fa fa-plus fa-lg mr-3" />
              <span>
                {__('Add Question')}
              </span>
            </Button>

            <Popover isOpen={state.addQuestionPopoverOpen} target={popoverTarget} boundariesElement="viewport">
              <div ref={ref}>
                <PopoverBody>
                  {formElementOptions.map((feo, i) => (
                    <Button key={feo.type} className={i !== 0 ? 'mt-1 px-3' : 'px-3'} block color="primary" size="large" onClick={handleAddQuestionClick(feo.type)}>
                      <i className={feo.icon} />
                      <span className="mx-2">{feo.name}</span>
                    </Button>
                  ))}
                </PopoverBody>
              </div>
            </Popover>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GroupAssignmentBuilder;
