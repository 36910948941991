import { useMutation, UseMutationResult } from 'react-query';
import { destroyDiscussionComment, DestroyDiscussionCommentParams, DestroyDiscussionCommentResponse } from '../../../api/discussion-comments/[discussionCommentId]';
import { queryClient } from '../../withQueryClient';

const useDestroyDiscussionCommentMutation = (
  params: DestroyDiscussionCommentParams,
): UseMutationResult<DestroyDiscussionCommentResponse, unknown, void, unknown> => {
  const mutation = useMutation(() => {
    return destroyDiscussionComment({ params });
  }, {
    onSuccess: (result) => {
      const discussionComment = result.data;
      queryClient.invalidateQueries(['discussions', discussionComment.discussion_id, 'comments']);
    },
  });

  return mutation;
};

export default useDestroyDiscussionCommentMutation;
