import classNames from 'classnames';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { splitVideo, swapDualPartsVideo } from '../../../common/api/videos/_legacy';
import { Button } from '../../../common/components/Button';
import * as Atlas from '../../../common/types/Atlas';

enum OperationStatus {
  NotStarted,
  Executing,
  Executed,
  Failed
}

enum Operation {
  SplitLeft,
  SplitRight,
  Swap
}

interface ReflectionAdvancedOptionsProps {
  videoId: Atlas.VideoID;
  reflectionId: Atlas.ReflectionID;
  isDual: boolean,
  clipSource?: Atlas.VideoID;
  prepState: Atlas.VideoPrepState;
  preflightChecksPassed(passed: boolean): void;
  notifySuccessfulOperation(vid: Atlas.VideoID): void;
  notifyFailedOperation(err: string): void;
}

interface ReflectionAdvancedOptionsState {
  splitLeftStatus: OperationStatus;
  splitRightStatus: OperationStatus;
  swapStatus: OperationStatus;
  prepState: Atlas.VideoPrepState;
}

const opToIcon = (op: Operation) => {
  switch (op) {
    case Operation.SplitLeft:
      return 'fa fa-chevron-left';
    case Operation.SplitRight:
      return 'fa fa-chevron-right';
    case Operation.Swap:
      return 'fa fa-exchange';
  }
};

const opToTitle = (op: Operation) => {
  switch (op) {
    case Operation.SplitLeft:
      return __('Split Left');
    case Operation.SplitRight:
      return __('Split Right');
    case Operation.Swap:
      return __('Swap Orientations');
  }
};

const isClip = (clipSource?: Atlas.VideoID) => !!clipSource;

const ReflectionAdvancedOptions = React.forwardRef((props: ReflectionAdvancedOptionsProps, ref): JSX.Element => {
  const { videoId, reflectionId } = props;

  const [state, setState] = useState<ReflectionAdvancedOptionsState>({
    splitLeftStatus: OperationStatus.NotStarted,
    splitRightStatus: OperationStatus.NotStarted,
    swapStatus: OperationStatus.NotStarted,
    prepState: props.prepState
  });

  useImperativeHandle(ref, () => ({
    prepStateChanged(prepState: Atlas.VideoPrepState) {
      setState(s => ({ ...s, prepState }));
    }
  }), []);

  useEffect(() => {
    props.preflightChecksPassed(props.isDual && !isClip(props.clipSource));
  }, [props.preflightChecksPassed, props.isDual, props.clipSource]);

  const performSplitLeft = () => {
    setState(s => ({ ...s, splitLeftStatus: OperationStatus.Executing }));
    splitVideo({ params: { videoId, reflectionId, direction: 'left' } }).then(video => {
      setState(s => ({ ...s, splitLeftStatus: OperationStatus.Executed }));
      props.notifySuccessfulOperation(video.id);
    }, (error: string) => {
      setState(s => ({ ...s, splitLeftStatus: OperationStatus.Failed }));
      props.notifyFailedOperation(error);
    });
  };

  const performSplitRight = () => {
    setState(s => ({ ...s, splitRightStatus: OperationStatus.Executing }));
    splitVideo({ params: { videoId, reflectionId, direction: 'right' } }).then(video => {
      setState(s => ({ ...s, splitRightStatus: OperationStatus.Executed }));
      props.notifySuccessfulOperation(video.id);
    }, (error: string) => {
      setState(s => ({ ...s, splitRightStatus: OperationStatus.Failed }));
      props.notifyFailedOperation(error);
    });
  };

  const performSwap = () => {
    setState(s => ({ ...s, swapStatus: OperationStatus.Executing }));
    swapDualPartsVideo({ params: { videoId, reflectionId } }).then(video => {
      setState(s => ({ ...s, swapStatus: OperationStatus.Executed }));
      props.notifySuccessfulOperation(video.id);
    }, (error: string) => {
      setState(s => ({ ...s, swapStatus: OperationStatus.Failed }));
      props.notifyFailedOperation(error);
    });
  };

  const performOperation = (op: Operation) => {
    switch (op) {
      case Operation.SplitLeft:
        return performSplitLeft();
      case Operation.SplitRight:
        return performSplitRight();
      case Operation.Swap:
        return performSwap();
    }
  };

  const isEnabled =
    props.isDual &&
    !isClip(props.clipSource) &&
    state.prepState === Atlas.VideoPrepState.Ready;

  const renderButton = (op: Operation) => {
    const status = (() => {
      switch (op) {
        case Operation.SplitLeft:
          return state.splitLeftStatus;
        case Operation.SplitRight:
          return state.splitRightStatus;
        case Operation.Swap:
          return state.swapStatus;
      }
    })();

    const icon = (() => {
      switch (status) {
        case OperationStatus.NotStarted:
          return opToIcon(op);
        case OperationStatus.Executing:
          return 'fa fa-spinner';
        case OperationStatus.Executed:
          return 'fa fa-check green-text';
        case OperationStatus.Failed:
          return 'fa fa-exclamation-circle red-text';
      }
    })();

    const isButtonEnabled = isEnabled && status !== OperationStatus.Executing,
          handleButtonClick = isEnabled ? (() => performOperation(op)) : undefined;

    return (
      <Button
        aria-hidden
        data-toggle="tooltip"
        size="small"
        className={classNames('advanced-button-wrapper advanced-button', { disabled: !isButtonEnabled })}
        onClick={handleButtonClick}>

        <i title={opToTitle(op)} className={icon} />
      </Button>
    );
  };

  return (
    <div>
      {renderButton(Operation.SplitLeft)}
      {renderButton(Operation.SplitRight)}
      {renderButton(Operation.Swap)}
    </div>
  );
});

export default ReflectionAdvancedOptions;
