import styled from '@emotion/styled';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const ColBase = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

export const Col = styled(ColBase)`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

import { Row as BSRow } from "../components/Row";

export const CustomRow = styled(BSRow)<{ gutter: string; }>`
  margin: calc(${props => props.gutter} * -1);

  > [class^="col-"],
  > [class*=" col-"] {
    padding: ${props => props.gutter};
  }
`;
