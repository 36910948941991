import React, { useState } from 'react';
import Spinner from '../../../common/components/Spinner';
import * as Atlas from '../../../common/types/Atlas';

const adminPermissionLevel = [{
  value: Atlas.MemberRole.Admin,
  label: __('Only admins can create'),
  description: __('Only Admins can create, update, delete'),
}, {
  value: Atlas.MemberRole.Standard,
  label: __('All users can create'),
  description: __('All users can create, update, delete'),
}];
interface AdminPermissionForm {
  adminPermission?: Atlas.MemberRole;
  onChange: (handleAdminPermission: Atlas.MemberRole) => Promise<void>;
}
interface AdminPermissionState {
  saving: boolean;
  helpExpanded: boolean;
  nextAdminPermission?: Atlas.MemberRole;
}

const AdminPermissionForm = (props: AdminPermissionForm): JSX.Element => {
  const { adminPermission, onChange } = props;

  const [state, setState] = useState<AdminPermissionState>({
    saving: false,
    helpExpanded: false,
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (state.saving) { return; }

    const nextAdminPermission = event.currentTarget.value as Atlas.MemberRole;
    const previousRole = adminPermission;

    setState((s) => ({ ...s, nextAdminPermission, saving: true }));

    onChange(nextAdminPermission).catch(() => {
      setState((s) => ({ ...s, nextAdminPermission: previousRole }));
    }).finally(() => {
      setState((s) => ({ ...s, saving: false }));
    });
  };

  return (
    <div className="d-flex align-items-center">
      <div className="mr-3">
        {state.saving ? (
          <Spinner size="sm" color="info" className="ml-2" />
        ) : null}
      </div>

      <div>
        <select
          className="mb-0"
          value={state.nextAdminPermission ?? adminPermission}
          onChange={handleSelectChange}
        >
           {adminPermissionLevel.map((nl) => (
            <option key={nl.value} value={nl.value}>{nl.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default AdminPermissionForm;
