import * as Atlas from '../../../types/Atlas';
import { parseAssignmentSubmissionJson } from '../../../utils/assignment-submission-parser';
import {
  generateFetchHeaders, jsonHeaders, Metadata, PaginationParams, searchParams, ViewID,
} from '../../routes';

export const assignmentAssignmentSubmissionsRoute = ({ assignmentId }: { assignmentId: Atlas.AssignmentID }): string => `/assignments/${assignmentId}/submissions`;
export const assignmentAssignmentSubmissionRoute = ({ assignmentId, assignmentSubmissionId }: { assignmentId: Atlas.AssignmentID, assignmentSubmissionId: Atlas.AssignmentSubmissionID }): string => `/assignments/${assignmentId}/submissions/${assignmentSubmissionId}`;

interface FindExpandedAssignmentSubmissionsArgs {
  params: PaginationParams & {
    assignmentId: Atlas.AssignmentID;
    userId?: Atlas.UserID;
    complete?: Atlas.SubmissionComplete;
  };
}

export const findExpandedAssignmentSubmissions = (
  args: FindExpandedAssignmentSubmissionsArgs,
): Promise<{ data: Atlas.ExpandedAssignmentSubmission[], metadata: Metadata }> => {
  const { assignmentId, userId, ...otherParams } = args?.params || {};

  const params: { [k: string]: number | string; } = {};
  if (userId) { params.user_id = userId; }

  const url = [
    assignmentAssignmentSubmissionsRoute({ assignmentId }),
    searchParams({ ...params, ...otherParams, view: ViewID.Expanded }),
  ].join('?');

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] findExpandedAssignmentSubmissions failed`; }
    return r.json();
  }).then(({ data, metadata }) => ({
    data: data.map(parseAssignmentSubmissionJson),
    metadata: metadata as Metadata,
  }));
};

interface FindExpandedAssignmentSubmissionArgs {
  params: PaginationParams & {
    assignmentId: Atlas.AssignmentID;
    assignmentSubmissionId: Atlas.AssignmentSubmissionID;
  };
}

export interface FindExpandedAssignmentSubmissionResponse {
  data: Atlas.ExpandedAssignmentSubmission;
}

export const findExpandedAssignmentSubmission = (
  args: FindExpandedAssignmentSubmissionArgs,
): Promise<FindExpandedAssignmentSubmissionResponse> => {
  const { assignmentId, assignmentSubmissionId } = args?.params || {};

  const url = [
    assignmentAssignmentSubmissionRoute({ assignmentId, assignmentSubmissionId }),
    searchParams({ view: ViewID.Expanded }),
  ].join('?');

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] findExpandedAssignmentSubmission failed`; }
    return r.json();
  }).then(({ data }) => ({
    data: parseAssignmentSubmissionJson(data),
  }));
};

export interface CreateAssignmentSubmissionArgs {
  params: { assignmentId: Atlas.AssignmentID; };
  body: {
    submission: Partial<Atlas.AssignmentSubmission>;
  };
}

export const createAssignmentSubmission = (
  args: CreateAssignmentSubmissionArgs,
): Promise<{ data: Atlas.ExpandedAssignmentSubmission }> => {
  const { assignmentId } = args?.params || {};

  const url = assignmentAssignmentSubmissionsRoute({ assignmentId });

  return fetch(url, {
    method: 'POST',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] createAssignmentSubmission failed`; }
    return r.json();
  }).then(({ data }) => ({
    data: parseAssignmentSubmissionJson(data),
  }));
};

export interface UpdateAssignmentSubmissionArgs {
  params: {
    assignmentId: Atlas.AssignmentID;
    assignmentSubmissionId: Atlas.AssignmentSubmissionID;
  };
  body: {
    submission: Partial<Atlas.AssignmentSubmission>;
  };
}

export const updateAssignmentSubmssion = (
  args: UpdateAssignmentSubmissionArgs,
): Promise<{ data: Atlas.ExpandedAssignmentSubmission }> => {
  const { assignmentId, assignmentSubmissionId } = args?.params || {};

  const url = assignmentAssignmentSubmissionRoute({
    assignmentId,
    assignmentSubmissionId,
  });

  return fetch(url, {
    method: 'PATCH',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] updateAssignmentSubmssion failed`; }
    return r.json();
  }).then(({ data }) => ({
    data: parseAssignmentSubmissionJson(data),
  }));
};

export interface DestroyAssignmentSubmissionArgs {
  params: {
    assignmentId: Atlas.AssignmentID;
    assignmentSubmissionId: Atlas.AssignmentSubmissionID;
  };
}

export const destroyAssignmentSubmssion = (
  args: DestroyAssignmentSubmissionArgs,
): Promise<{ data: Atlas.AssignmentSubmission; }> => {
  const { assignmentId, assignmentSubmissionId } = args?.params || {};

  const url = assignmentAssignmentSubmissionRoute({
    assignmentId,
    assignmentSubmissionId,
  });

  return fetch(url, {
    method: 'DELETE',
    headers: generateFetchHeaders(),
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] destroyAssignmentSubmssion failed`; }
    return r.json();
  }).then(({data}) => ({
    data: parseAssignmentSubmissionJson(data),
  }));
};
