import { useQuery, UseQueryResult } from 'react-query';
import { findReflectionForms, FindReflectionFormsArguments, FindReflectionFormsResponse } from '../../../api/reflections/[reflectionId]/forms';

const useFindReflectionFormsQuery = (
  args: FindReflectionFormsArguments,
): UseQueryResult<FindReflectionFormsResponse> => {
  const { reflectionId, ...otherParams } = args.params;

  return (
    useQuery(
      ['reflections', reflectionId, 'forms', otherParams],
      () => findReflectionForms(args),
    )
  );
};

export default useFindReflectionFormsQuery;
