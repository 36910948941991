import React, { ComponentProps, useState } from 'react';
import { Modal, ModalBox, ModalHeader } from '../../../../common/components/TailwindModal';
import CommentResourceLinkForm from '../comment-resource-links/CommentResourceLinkForm';

const NewCommentResourceLink: React.FC<{
  initialBody?: string;
  onClick?: () => void;
  onSave: ComponentProps<typeof CommentResourceLinkForm>['onSave'];
  className?: string;
  children?: React.ReactNode;
}> = (props) => {
  const {
    children,
    className = 'tw-btn tw-btn-ghost tw-btn-block tw-btn-xs xs:tw-btn-sm',
    initialBody,
    onClick,
    onSave,
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <>
      <div>
        <button
          type="button"
          className={className}
          onClick={() => {
            onClick?.();
            setOpen(true);
          }}
        >
          {children ? children : (
            <>
              <i className="fa fa-link tw-mr-2 tw-text-xs" />
              {__('Link Resource')}
            </>
          )}
        </button>
      </div>

      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <ModalBox className="tw-overflow-visible">
          <ModalHeader onClose={() => setOpen(false)}>
            {__('Link Resource')}
          </ModalHeader>

          <CommentResourceLinkForm
            initialBody={initialBody}
            onCancel={() => setOpen(false)}
            onSave={async (data) => {
              await onSave(data);
              setOpen(false)
            }}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

export default NewCommentResourceLink
