import React, { useEffect, useReducer, useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import { withQueryClient } from '../../../common/hooks/withQueryClient';
import useFindReflectionsShareQuery from '../../../common/hooks/api/reflections/useFindReflectionSharesQuery';
import ShareReflectionModal from './ShareReflectionModal';

interface ShareReflectionButtonContentProps {
  reflectionId: Atlas.ReflectionID;
}

const ShareReflectionButtonContent = (props: ShareReflectionButtonContentProps) => {
  const { reflectionId } = props;

  const findReflectionShares = useFindReflectionsShareQuery({ reflectionId });

  switch (findReflectionShares.status) {
    case 'loading': {
      return (
        <i className="fa fa-circle-o-notch fa-spin" />
      );
    }

    case 'success': {
      const count = findReflectionShares.data.data.length;

      return (
        <>
          <i className="fa fa-share" />
          {count}
        </>
      );
    }

    default: {
      return (
        <i className="fa fa-share" />
      );
    }
  }
};

interface ReflectionSharesProps {
  reflectionId: Atlas.ReflectionID;
  reflectionName: Atlas.ReflectionName;
  initialCount?: number;
}

const ShareReflections = (props: ReflectionSharesProps) => {
  const {
    reflectionId, reflectionName, initialCount,
  } = props;

  const [openedBefore, setOpenedBefore] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    if (!openedBefore) {
      setOpenedBefore(true);
    }

    setOpen((o) => !o);
  };

  useEffect(() => {
    // Removes RoR skeleton button on load
    $('.reflection-skeleton-btn').remove();
  }, []);

  return (
    <>
      <button
        type="button"
        className="tw-btn tw-btn-circle tw-btn-success tw-gap-1 tw-text-white"
        id="share-button"
        onClick={toggleOpen}
      >
        {openedBefore ? (
          <ShareReflectionButtonContent reflectionId={reflectionId} />
        ) : (
          <>
            <i className="fa fa-share" />
            {initialCount}
          </>
        )}
      </button>

      <ShareReflectionModal
        reflectionId={reflectionId}
        title={reflectionName}
        isOpen={open}
        toggle={toggleOpen}
      />
    </>
  );
};

export default withQueryClient(ShareReflections);
