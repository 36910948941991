import React from 'react';
import { create } from 'zustand';
import { ReflectionTabSlice, createReflectionTabSlice } from './reflection-tabs.store';
import { ReflectionModalSlice, createReflectionModalSlice } from './reflection-modals.store';

export type ReflectionStore = ReflectionTabSlice & ReflectionModalSlice;

export const createReflectionStore = () => (
  create<ReflectionStore>()((...a) => ({
    ...createReflectionTabSlice(...a),
    ...createReflectionModalSlice(...a),
  }))
);

export const ReflectionStoreContext = React.createContext<ReturnType<typeof createReflectionStore> | null>(null);
