import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI
import FormCounterElement from './form-elements/FormCounterElement';
import FormMultipleChoiceElement from './form-elements/FormMultipleChoiceElement';
import FormRatingElement from './form-elements/FormRatingElement';
import FormTimerElement from './form-elements/FormTimerElement';
import VideoTimeRangeSelector from './form-elements/VideoTimeRangeSelector';
import Input from '../../common/components/Input';
import TimelineSegments from './TimelineSegments';

const elementMap = {
  counter: {
    component: FormCounterElement,
    props: { timeLinkDisabled: true },
  },
  multiple_choice: {
    component: FormMultipleChoiceElement,
  },
  rating: {
    component: FormRatingElement,
  },
  timer: {
    component: FormTimerElement,
    props: { timeLinkDisabled: true },
  },
};

const TIME_LINKED = 'time_linked';

const FormQuestion = (props) => {
  const {
    formElement, formAnswer, onChange, onSubmit,
  } = props;
  const { field } = formElement;
  const type = Object.keys(field.type)[0];

  const {
    component: Question,
    props: elementProps = {},
  } = elementMap[type] || {};

  const [state, setState] = useState({
    recordingTimeline: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;

    onChange((s) => ({ ...s, [name]: value }));
  };

  const handleTimelineChange = (timeline) => {
    onChange((fa) => ({ ...fa, timeline }));
  };

  const handleTimeRangeSelect = (videoSegment) => {
    onChange((fa) => ({
      ...fa,
      timeline: [
        ...(fa.timeline || []),
        videoSegment,
      ],
    }));
  };

  const handleTimeRangeRecordingStart = () => {
    setState((s) => ({ ...s, recordingTimeline: true }));
  };

  const handleTimeRangeRecordingEnd = () => {
    setState((s) => ({ ...s, recordingTimeline: false }));
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const handleConfirmClick = async () => {
    if (isSubmitting) { return; }

    setSubmitting(true);
    try {
      await onSubmit();
    } finally {
      setSubmitting(false);
    }
  }

  const { timeLinkDisabled } = elementProps;
  const isTimeLinked = !timeLinkDisabled && field.link === TIME_LINKED;

  const isValid = formAnswer.notes
    || formAnswer.value
    || Number.isInteger(formAnswer.value)
    || (formAnswer.counter_value?.some((cv) => cv.clicks?.length))
    || (formAnswer.timer_value?.some((tv) => tv.intervals?.length))
    || (formAnswer.timeline && formAnswer.timeline.length);

  const commentField = field.has_notes ? 'notes' : type === 'text' ? 'value' : null;

  const saveDisabled = state.recordingTimeline;

  return (
    <div className="tw-flex tw-items-start">
      <div className="tw-flex-grow">
        {Question ? (
          <div className="tw-mb-3">
            <Question {...props} />
          </div>
        ) : null}

        <div className="tw-flex tw-items-start tw-gap-2">
          <div className="tw-flex-grow tw-flex tw-flex-col tw-gap-1">
            {commentField ? (
              <Input
                placeholder={__('Write a response')}
                name={commentField}
                value={formAnswer[commentField] || ''}
                onChange={handleChange}
              />
            ) : null}

            {isTimeLinked ? (
              <>
                <div>
                  <TimelineSegments
                    timeline={formAnswer.timeline || []}
                    onChange={handleTimelineChange}
                  />
                </div>

                <VideoTimeRangeSelector
                  className="tw-flex-grow"
                  onRecordingStart={handleTimeRangeRecordingStart}
                  onRecordingEnd={handleTimeRangeRecordingEnd}
                  onSelect={handleTimeRangeSelect}
                >
                  {__('Start Timestamp')}
                </VideoTimeRangeSelector>
              </>
            ) : null}
          </div>

          {isValid ? (
            <button
              type="button"
              className="tw-btn tw-btn-success tw-btn-sm tw-btn-square tw-rounded-xl"
              disabled={saveDisabled}
              onClick={handleConfirmClick}
            >
              {isSubmitting ? (
                <i className="fa fa-circle-o-notch fa-spin" />
              ) : (
                <i className="icon-ok" />
              )}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

FormQuestion.propTypes = {
  formElement: PropTypes.object.isRequired,
  formAnswer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default FormQuestion;
