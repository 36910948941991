import React from 'react';
import { useParams } from 'react-router-dom';
import ReflectionFormsWrapper from '../../../../modules/reflections/ReflectionFormsWrapper';

const ReflectionFormsPage = (): JSX.Element => {
  const params = useParams<{ reflectionId: string; }>();
  const reflectionId = Number(params.reflectionId);

  return (
    <ReflectionFormsWrapper
      reflectionId={reflectionId}
    />
  );
};

export default ReflectionFormsPage;
