import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import * as Atlas from '../../../../common/types/Atlas';

const dateToStr = (date: Date | dayjs.Dayjs) => dayjs(date).format('YYYY-MM-DD');

interface ReflectionAutoDeleteFormProps {
  expiresAt?: Date;
  onSubmit: (updates: {
    expiresAt: Date | null;
  }) => Promise<void>;
}

const ReflectionAutoDeleteForm = (props: ReflectionAutoDeleteFormProps) => {
  const {
    expiresAt, onSubmit,
  } = props;

  const expiresAtString = expiresAt ? dateToStr(expiresAt) : '';

  const [nextExpiresAt, setNextExpiresAt] = useState(expiresAtString);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setNextExpiresAt(expiresAtString);
  }, [expiresAtString]);

  const handleExpiresAtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setNextExpiresAt(value);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (updating) { return; }

    setUpdating(true);
    onSubmit({
      expiresAt: nextExpiresAt ? dayjs(nextExpiresAt).toDate() : null,
    }).finally(() => {
      setUpdating(false);
    });
  };

  const formDisabled = (
    nextExpiresAt === expiresAtString
  );

  const minExpiresAt = dateToStr(dayjs().add(1, 'day'));

  return (
    <form onSubmit={handleFormSubmit}>
      <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
        {__('Auto Delete')}
      </p>

      <div className="tw-mb-2">
        <input
          type="date"
          min={minExpiresAt}
          className="!tw-input !tw-input-sm !tw-input-bordered !tw-text-base-content tw-w-56"
          value={nextExpiresAt}
          onChange={handleExpiresAtChange}
        />
      </div>

      <div className="tw-flex tw-justify-end tw-mt-3">
        <button
          type="submit"
          className="tw-btn tw-btn-sm tw-btn-success"
          disabled={formDisabled}
        >
          {updating ? <span className="tw-loading" /> : null}
          {__('Update Auto Delete')}
        </button>
      </div>
    </form>
  );
};

export default ReflectionAutoDeleteForm;
