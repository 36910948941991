import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { updateGroupAssignment } from '../../../../../../common/api/groups/assignments';
import GroupAssignmentBuilder from '../../../../../../modules/group-assignment/GroupAssignmentBuilder';
import * as Atlas from '../../../../../../common/types/Atlas';
import useGroupAssignmentQuery from '../../../../../../common/hooks/api/groups/useFindGroupAssignmentQuery';
import { getAssignmentsBasePath } from '../../../../../../common/utils/getAssignmentsBasePath';
import Spinner from '../../../../../../common/components/Spinner';
import useCurrentUserQuery from '../../../../../../common/hooks/api/useCurrentUserQuery';

interface EditGroupAssignmentPageState {
  saving: boolean;
}

const EditGroupAssignmentPage = (): JSX.Element => {
  const params = useParams<{
    groupId: string;
    assignmentId: string;
  }>();

  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);

  const groupId = Number(params.groupId);
  const assignmentId = Number(params.assignmentId);

  const groupAssignmentQuery = useGroupAssignmentQuery(groupId, assignmentId);
  const assignment = groupAssignmentQuery.data?.data;
  const assignmentLoading = groupAssignmentQuery.isLoading;

  const userQuery = useCurrentUserQuery();

  const isSuperAdmin = userQuery.data && userQuery.data.admin === Atlas.AdministratorType.Super;

  const navigate = useNavigate();

  const [state, setState] = useState<EditGroupAssignmentPageState>({
    saving: false
  });

  if (!assignment && !assignmentLoading) {
    return <Navigate to={`/${basePath}/${groupId}/assignments`} />;
  } else if (state.saving || !assignment || userQuery.isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-5">
        <Spinner color="info" />
      </div>
    );
  } else if (assignment.submissions > 0 && 
    (userQuery.isError || !userQuery.data || userQuery.data.admin !== Atlas.AdministratorType.Super)) {
    return <Navigate to={`/${basePath}/${assignment.group_id}/assignments/${assignment.id}`} />;
  }

  const handleSaveClick = async (assignmentChanges: Partial<Atlas.Assignment> & { id: Atlas.AssignmentID; name: Atlas.AssignmentName }) => {
    setState(s => ({ ...s, saving: true }));

    await updateGroupAssignment({
      params: { groupId, assignmentId: assignmentChanges.id },
      body: { assignment: assignmentChanges }
    }).then(async () => {
      navigate(`/${basePath}/${assignment.group_id}/assignments`);
      await groupAssignmentQuery.refetch();
    }, () => {
      setState(s => ({ ...s, saving: false }));
    });
  };

  return (
    <GroupAssignmentBuilder
      groupId={groupId}
      assignment={assignment}
      isSuperAdmin={isSuperAdmin}
      onSaveClick={handleSaveClick} />
  );
};

export default EditGroupAssignmentPage;
