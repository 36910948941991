import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import * as Atlas from '../../common/types/Atlas';
import { findFormReporters } from '../../common/api/forms/reporters';
import { portraitPlaceholderUrl } from '../../common/utils/placeholders';
import Spinner from '../../common/components/Spinner';

interface FormReportersProps {
  formId: Atlas.FormID;
}

type FormReportersState = {
  reportersStatus: 'loading';
} | {
  reportersStatus: 'hasData';
  reporters: Atlas.FormUser[];
} | {
  reportersStatus: 'hasError';
};

const FormReporters = (props: FormReportersProps): JSX.Element => {
  const { formId } = props;

  const [state, setState] = useState<FormReportersState>({
    reportersStatus: 'loading',
  });

  const loadFormReporters = useCallback(() => {
    setState((s) => ({ ...s, reporters: undefined, reportersStatus: 'loading' }));
    findFormReporters({ params: { formId } }).then(({ data: reporters }) => {
      setState((s) => ({ ...s, reporters, reportersStatus: 'hasData' }));
    }, () => {
      setState((s) => ({ ...s, reporters: undefined, reportersStatus: 'hasError' }));
    });
  }, [formId]);

  useEffect(() => {
    loadFormReporters();
  }, [loadFormReporters]);

  const renderIcon = (url?: string) => (
    <div className="tw-avatar">
      <div className="tw-w-7 tw-rounded-full">
        <img
          alt={__('Reporter')}
          src={url || portraitPlaceholderUrl}
        />
      </div>
    </div>
  );

  return (
    <div className="tw-min-w-[200px]">
      <p className="tw-font-bold tw-text-base tw-text-success tw-py-2 tw-mb-2 tw-border-b">
        {__('Reporters')}
      </p>

      <p className="tw-text-sm tw-text-base-300 tw-mb-2">
        {__('Reporters can see all responses, even if the response privacy is set as hidden.')}
      </p>

      {(() => {
        switch (state.reportersStatus) {
          case 'loading': {
            return (
              <div className="tw-flex tw-justify-center tw-py-4">
                <Spinner color="info" size="sm" />
              </div>
            );
          }

          case 'hasData': {
            return (
              <ul>
                {state.reporters.map((user) => (
                  <li key={user.id} className="tw-py-2 tw-border-b last:tw-border-b-0">
                    <div className="tw-flex tw-items-center">
                      {renderIcon(user.avatar_s3uri?.url)}
                      <div className="tw-truncate tw-px-3">
                        {user.first_name}
                        {' '}
                        {user.last_name}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            );
          }

          case 'hasError': {
            const handleReloadClick = () => {
              loadFormReporters();
            };

            return (
              <div className="tw-alert tw-px-3 tw-py-2">
                <p>
                  {__('Something went wrong!')}
                </p>
                <div className="tw-flex-none">
                  <button
                    type="button"
                    className="tw-link tw-link-hover tw-link-primary"
                    onClick={handleReloadClick}
                  >
                    <i className="fa fa-refresh" />
                    &nbsp;
                    {__('Retry')}
                  </button>
                </div>
              </div>
            );
          }

          default: return null;
        }
      })()}
    </div>
  );
};

export default FormReporters;
