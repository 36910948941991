import React from 'react';

export const generateTimestamp = (ms: number): string | null => {
  if (!Number.isInteger(ms)) { return null; }

  const s = Math.floor(ms / 1000),
        mm = Math.floor(s / 60),
        ss = s % 60;

  if (!mm && !ss) {
    return '0s';
  }

  if (!mm) {
    return `${ss}s`;
  }

  return `${mm}m${ss}s`;
};

export const renderTimestamp = (ms: number): JSX.Element | null => {
  if (!Number.isInteger(ms)) { return null; }

  const s = Math.floor(ms / 1000),
        mm = Math.floor(s / 60),
        ss = s % 60;

  const timestamp = (n: number, unit: string) => (
    <span>
      {n}
      <small>{unit}</small>
    </span>
  );

  if (!mm && !ss) {
    return timestamp(0, 's');
  }

  return (
    <span>
      {mm ? timestamp(mm, 'm') : null}
      {ss ? timestamp(ss, 's') : null}
    </span>
  );
};

export const renderDurationTimestamp = ({ start, end }: { start: number, end: number }): JSX.Element | null => {
  const isSameSeconds = (Math.floor(start / 1000) === Math.floor(end / 1000));

  if (isSameSeconds) {
    return renderTimestamp(start);
  }

  return (
    <span>
      {renderTimestamp(start)} - {renderTimestamp(end)}
    </span>
  );
};

export default renderTimestamp;
