import React, { useState } from 'react';
import { Modal, ModalBox } from '../../../../common/components/TailwindModal';
import useDestroyPageMutation from '../../../../common/hooks/api/pages/useDestroyPageMutation';
import * as Atlas from '../../../../common/types/Atlas';
import { interpolateWithJSX } from '../../../../common/utils/utils';

interface DeletePageButtonProps {
  page: Atlas.Page;
}

const DeletePageButton = (props: DeletePageButtonProps) => {
  const { page } = props;

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((o) => !o);
  };

  const destroyPage = useDestroyPageMutation({ pageId: page.id });

  const handleDeleteClick = () => {
    if (destroyPage.isLoading) { return; }

    void destroyPage.mutateAsync().then(() => {
      setOpen(false);
    });
  };

  return (
    <>
      <button type="button" onClick={toggle} data-testid="delete-page-button">
        {__('Delete')}
      </button>

      <Modal isOpen={open} onClose={toggle}>
        <ModalBox>
          <p className="tw-text-xl tw-font-bold tw-mb-2">
            {(() => {
              switch (page.type) {
                case Atlas.PageType.Heading: {
                  return __('Delete Heading');
                }

                default: {
                  return __('Delete Page');
                }
              }
            })()}
          </p>

          <p className="tw-mb-4">
            {interpolateWithJSX(
              __('Are you sure you want to delete %s? This action cannot be undone.'),
              <strong>{page.title}</strong>,
            )}
          </p>

          <div className="tw-flex tw-justify-end tw-gap-2">
            <button
              type="button"
              className="tw-btn tw-btn-link"
              onClick={toggle}
            >
              {__('Cancel')}
            </button>

            <button
              type="button"
              className="tw-btn tw-btn-error"
              onClick={handleDeleteClick}
              data-testid="confirm-delete-page-button"
            >
              {destroyPage.isLoading ? <span className="tw-loading" /> : null}
              {__('Delete')}
            </button>
          </div>
        </ModalBox>
      </Modal>
    </>
  );
};

export default DeletePageButton;
