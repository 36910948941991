import { useMutation } from 'react-query';
import { CreateAttachmentArgs, CreateAttachmentResponse, createAttachment } from '../../../api/attachments';

const useCreateAttachmentMutation = () => {
  const mutation = useMutation<CreateAttachmentResponse, unknown, CreateAttachmentArgs, unknown>(
    createAttachment,
  );

  return mutation;
};

export default useCreateAttachmentMutation;
