/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const cardDeckVariant = cva('card-deck');

export type CardDeckVariantProps = VariantProps<typeof cardDeckVariant>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const CardDeck = ({
  className,
  ...props
}: CardDeckVariantProps & DivProps) => (
  <div
    {...props}
    className={cx(className, cardDeckVariant())}
  />
);
