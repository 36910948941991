import React, { useCallback, useState } from 'react';
import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend, Tooltip } from 'recharts';
import * as Atlas from '../../common/types/Atlas';
import { colors, darkColor } from '../../common/utils/colors';
import { FormReportingFilter } from './types';
import DataFetcher from './DataFetcher';

interface FormRatingsState {
  overviews: Record<string, {
    loading: boolean;
    data?: Atlas.FormFieldOverview[]
  }>;
  overviewsLoading: boolean;
}

interface FormRatingsProps {
  formId: Atlas.FormID;
  form?: Atlas.Form;
  filters: FormReportingFilter[];
}

interface FormRatingElement {
  formElement: Atlas.FormElement;
  rating: Atlas.RatingFormField;
}

interface GroupedRating {
  minOrdinal: number;
  maxOrdinal: number;
  formRatings: FormRatingElement[];
}

const FormRatings = (props: FormRatingsProps): JSX.Element => {
  const { formId, form, filters } = props;

  const [state, setState] = useState<FormRatingsState>({
    overviews: {},
    overviewsLoading: false,
  });

  const handleFilterDataLoading = useCallback((filter: FormReportingFilter) => {
    setState((s) => {
      const nextOverviews = { ...s.overviews };
      const prevData = nextOverviews[filter.id]?.data;
      nextOverviews[filter.id] = { loading: true, data: prevData };
      return { ...s, overviews: nextOverviews };
    });
  }, []);

  const handleFilterDataLoaded = useCallback((filter: FormReportingFilter, data: Atlas.FormFieldOverview[]) => {
    setState((s) => {
      const nextOverviews = { ...s.overviews };
      nextOverviews[filter.id] = { loading: false, data };
      return { ...s, overviews: nextOverviews };
    });
  }, []);

  const handleFilterUnmount = useCallback((filterId: string) => {
    setState((s) => {
      const nextOverviews = { ...s.overviews };
      delete nextOverviews[filterId];
      return { ...s, overviews: nextOverviews };
    });
  }, []);

  const formRatings = form?.elements.reduce<Array<FormRatingElement>>((acc, formElement) => {
    if (!('rating' in formElement.field.type)) { return acc; }

    return [
      ...acc,
      { formElement, rating: formElement.field.type.rating }
    ];
  }, []) ?? [];

  const groupedFormRatings = Object.values(formRatings.reduce<Record<string, GroupedRating>>((acc, formRating) => {
    const { min_ordinal: minOrdinal, max_ordinal: maxOrdinal } = formRating.rating;

    const key = `${minOrdinal}-${maxOrdinal}`;
    acc[key] ||= { minOrdinal, maxOrdinal, formRatings: [] };
    acc[key].formRatings.push(formRating);

    return acc;
  }, {}));

  const groupedData = groupedFormRatings.map(({ minOrdinal, maxOrdinal, formRatings }) => {
    const data = formRatings.map((rating) => {
      return filters.reduce<{
        name: string;
        label: string;
        avgs: Record<string, number>;
      }>((acc, filter) => {
        const overviews = state.overviews[filter.id];
        if (!overviews) { return acc; }

        const overview = overviews.data?.find((datum) => (
          datum.field.name === rating.formElement.field.name)
        );

        if (
          overview
          && ('rating' in overview.stats)
          && overview.stats.rating.count
        ) {
          acc.avgs[filter.id] = overview.stats.rating.total / overview.stats.rating.count;
        }

        return acc;
      }, {
        name: rating.formElement.field.name,
        label: rating.formElement.field.label,
        avgs: {},
      });
    });

    return {
      minOrdinal,
      maxOrdinal,
      data,
    };
  });

  return (
    <div>
      {filters.map((filter) => (
        <DataFetcher
          key={filter.id}
          formId={formId}
          filter={filter}
          onLoading={handleFilterDataLoading}
          onLoaded={handleFilterDataLoaded}
          onUnmount={handleFilterUnmount}
        />
      ))}

      {groupedData.length === 0 ? (
        <div className="tw-text-center tw-my-10 tw-text-lg">
          {__('This Insight has no rating questions.')}
        </div>
      ) : null}

      {groupedData.map(({ minOrdinal, maxOrdinal, data }) => (
        <div key={`${minOrdinal}-${maxOrdinal}`} className="tw-my-5 tw-rounded-xl tw-shadow tw-bg-base-100 tw-p-4">
          <ResponsiveContainer width="100%" height={400}>
            <RadarChart data={data}>
              <PolarGrid gridType="circle" />
              {data.length ? (
                <PolarAngleAxis dataKey="label" fontSize="80%" />
              ) : null}
              <PolarRadiusAxis angle={30} domain={[minOrdinal, maxOrdinal]} stroke={darkColor} fontSize="80%" />
              {filters.map((filter, i) => (
                <Radar
                  key={filter.id}
                  name={filter.name}
                  dataKey={`avgs.${filter.id}`}
                  stroke={colors[i % colors.length]}
                  strokeWidth={2}
                  fill={colors[i % colors.length]}
                  fillOpacity={0.2}
                  dot={{ r: 2, fillOpacity: 1 }}
                  activeDot={{ r: 4, fillOpacity: 1, stroke: colors[i % colors.length] }}
                />
              ))}
              <Legend />
              <Tooltip />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      ))}
    </div>
  );
};

export default FormRatings;
