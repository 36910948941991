import { useQuery, UseQueryResult } from 'react-query';
import { findAttachment, FindAttachmentResponse } from '../../../api/attachments/[attachmentId]';
import * as Atlas from '../../../types/Atlas';

const useFindAttachmentQuery = (
  attachmentId: Atlas.AttachmentID,
  options?: { staleTime?: number; enabled?: boolean; },
): UseQueryResult<FindAttachmentResponse> => (
  useQuery(
    ['attachments', attachmentId],
    () => findAttachment({ params: { attachmentId } }),
    {
      staleTime: 60000,
      ...options,
    },
  )
);

export default useFindAttachmentQuery;
