import React from 'react';
import useCurrentUserQuery from '../../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../../common/types/Atlas';
import { SearchResultLink } from './list-item';

const labelForDeleteState = (deleteState: Atlas.DeleteState) => {
  switch (deleteState) {
    case Atlas.DeleteState.Suspended:
      return __('Deactivated');
    case Atlas.DeleteState.Trashed:
      return __('Trashed');
    default:
      return null;
  }
};

interface SearchGroupResultProps {
  group: Atlas.SearchGroup;
}

const SearchGroupResult = (props: SearchGroupResultProps) => {
  const { group } = props;

  const currentUserQuery = useCurrentUserQuery();
  const isSuperAdmin = currentUserQuery.data?.admin === Atlas.AdministratorType.Super;

  if (group.is_organization) {
    const avatarSrc = group.avatar_s3uri?.url || '/assets/img/organization_placeholder.png';

    return (
      <SearchResultLink href={`/admin/organizations/${group.id}`}>
        <div className="tw-flex tw-items-center tw-gap-4 tw-w-full">
          <div className="tw-avatar">
            <div className="tw-rounded-xl tw-w-10">
              <img src={avatarSrc} alt={__('Organization avatar')} />
            </div>
          </div>

          <div className="tw-flex-1 tw-flex tw-gap-4">
            <div className="tw-flex-1 tw-flex tw-flex-col">
              <div className="tw-text-sm tw-opacity-60">
                {__('Organization')}
                {isSuperAdmin ? ` #${group.id}` : null}
              </div>
              <div>{group.name}</div>
              {isSuperAdmin && group.postcode ? (
                <div className="tw-text-sm">{group.postcode}</div>
              ) : null}
            </div>

            {isSuperAdmin && group.delete_state ? (
              <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end tw-justify-center">
                <div className="tw-badge tw-badge-warning tw-text-xs">
                  {labelForDeleteState(group.delete_state)}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </SearchResultLink>
    );
  }

  const avatarSrc = group.avatar_s3uri?.url || '/assets/img/reflections/display-picture-group-blue.png';

  return (
    <SearchResultLink href={`/groups/${group.id}`}>
      <div className="tw-flex tw-items-center tw-gap-4 tw-w-full">
        <div className="tw-avatar">
          <div className="tw-rounded tw-w-10">
            <img src={avatarSrc} alt={__('Group avatar')} />
          </div>
        </div>

        <div className="tw-flex-1 tw-flex tw-gap-4">
          <div className="tw-flex-1 tw-flex tw-flex-col">
            <div className="tw-text-sm tw-opacity-60">
              {__('Group')}
              {isSuperAdmin ? ` #${group.id}` : null}
            </div>
            <div>{group.name}</div>
          </div>

          {isSuperAdmin && group.delete_state ? (
            <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end tw-justify-center">
              <div className="tw-badge tw-badge-warning tw-text-xs">
                {labelForDeleteState(group.delete_state)}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </SearchResultLink>
  );
};

export default SearchGroupResult;
