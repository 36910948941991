/* eslint-disable import/prefer-default-export */
export const defaultJwplayerConfig = {
  aspectratio: '16:9',
  captions: { backgroundOpacity: 50 },
  nextUpDisplay: false,
  playbackRateControls: true,
  playbackRates: [0.75, 1, 1.25, 1.5, 2],
  plugins: {
    '/vendor/assets/javascripts/jwplayer8/jwplayer_plugins/ic-audio-panning-plugin.js': {},
  },
  skin: { name: 'iris' },
  width: '100%',
  stretching: 'uniform',
  horizontalVolumeSlider: true,
};
