import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { withQueryClient } from '../../../common/hooks/withQueryClient';
import { ReflectionID } from '../../../common/types/Atlas';
import { JwPlayerProvider } from '../../../modules/jwplayer/JwPlayer';
import ReflectionPlayer from '../../../modules/reflections/show/ReflectionPlayer';
import ReflectionComments from '../../../modules/reflections/reflection-comments/ReflectionComments';

const EmbeddedReflectionPlayer: React.FC<{
  reflectionId: ReflectionID;
  showLink?: boolean;
  showComments?: 'comments' | 'no-comments' | 'optional';
}> = ({
  reflectionId,
  showLink,
  showComments = 'no-comments',
}) => {
  const [commentsVisible, setCommentsVisible] = useState(false);

  const toggleCommentsVisibility = () => {
    setCommentsVisible((prev) => !prev);
  };

  return (
    <BrowserRouter>
      <JwPlayerProvider value={{ id: `embed-${reflectionId}` }}>
        <ReflectionPlayer reflectionId={reflectionId} />
        <div className="tw-flex tw-gap-2 tw-mt-2">
          {showComments === 'optional' ? (
            <button
              className="tw-btn tw-btn-sm tw-bg-base-400"
              onClick={toggleCommentsVisibility}
            >
              {commentsVisible ? __("Hide comments") : __("Show comments")}
              <i
                className={`fa tw-ml-1 ${
                  commentsVisible ? 'fa-chevron-up' : 'fa-chevron-down'
                }`}
              ></i>
            </button>
          ) : null}

          {showLink ? (
            <a
              href={`/reflections/${reflectionId}?linked_from_group=true`}
              className="tw-btn tw-btn-sm tw-bg-base-400 !tw-text-black"
            >
              {__('Open Reflection')}
            </a>
          ) : null}
        </div>

        {(showComments === 'comments' ||
          (showComments === 'optional' && commentsVisible)) && (
          <div className="tw-mt-3">
            <ReflectionComments reflectionId={reflectionId} isEmbedded={true} />
          </div>
        )}
      </JwPlayerProvider>
    </BrowserRouter>
  );
};

export default withQueryClient(EmbeddedReflectionPlayer);
