import React from 'react';
import { useParams } from 'react-router-dom';
import EditMilestoneWrapper from '../../../../../../src/features/milestones/components/EditMilestoneWrapper';

const EditGroupMilestonePage = (): JSX.Element => {
  const params = useParams<{
    groupId: string;
    milestoneId: string;
  }>();

  const groupId = Number(params.groupId);
  const milestoneId = Number(params.milestoneId);


  return (
    <EditMilestoneWrapper groupId={groupId} milestoneId={milestoneId}/>
  );
};

export default EditGroupMilestonePage;
