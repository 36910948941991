import React, { useState } from 'react';
import { withQueryClient } from '../../../../common/hooks/withQueryClient';
import * as Atlas from '../../../../common/types/Atlas';
import useCreateMilestoneMutation from '../hooks/api/useCreateMilestoneMutation';
import MilestoneForm from './MilestoneForm';
import MilestoneFormHeader from './MilestoneFormHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { createEmptyAttachmentMilestone, createEmptyButtonMilestone, createEmptyCompleteAssignmentMilestone, createEmptyDiscussionMilestone, createEmptyFormMilestone, createEmptyGuideMilestone, createEmptyLinkReflectionMilestone, createEmptyPageMilestone, createEmptyReflectionMilestone, createEmptySubmitAssignmentMilestone, createFeedbackMilestone, isButtonMilestone } from '../utils/utils';

interface MilestoneDetails {
  name: Atlas.MilestoneName;
  description: Atlas.MilestoneDescription;
  notify: Atlas.MilestoneNotify;
  notification_text?: string | null;
  completesPathway: boolean;
}

interface NewMilestoneContainerProps {
  groupId: number;
}

const NewMilestoneContainer = (props: NewMilestoneContainerProps): JSX.Element => {
  const [milestoneDetails, setMilestoneDetails] = useState<MilestoneDetails>({
    name: '',
    description: '',
    notify: Atlas.MilestoneNotify.None,
    notification_text: null,
    completesPathway: false,
  });
  const [milestoneType, setMilestoneType] = useState<Atlas.MilestoneType>(createEmptyButtonMilestone());

  const params = useParams<{ containerId: string; }>();
  const containerId = Number(params.containerId);

  const mutation = useCreateMilestoneMutation(props.groupId);
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const milestoneData = {
      name: milestoneDetails.name,
      description: milestoneDetails.description,
      type: milestoneType,
      notify: milestoneDetails.notify,
      ...(milestoneDetails.notification_text !== null && {
        notification_text: milestoneDetails.notification_text,
      }),
      completes_pathway: milestoneDetails.completesPathway,
      completed: false,
    };

    mutation.mutateAsync({
      params: { groupId: props.groupId },
      body: milestoneData,
    }).then(() => {
      navigate(`/pathways_management/${containerId}/templates/${props.groupId}/milestones`);
    });
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const selectedType = event.target.value;
    
    switch (selectedType) {
      case 'button':
        setMilestoneType(createEmptyButtonMilestone());
        break;
      case 'visit_page':
        setMilestoneType(createEmptyPageMilestone());
        break;
      case 'link_guide':
        setMilestoneType(createEmptyGuideMilestone());
        break;
      case 'link_reflection':
        setMilestoneType(createEmptyLinkReflectionMilestone());
        break;
      case 'watch_reflection':
        setMilestoneType(createEmptyReflectionMilestone());
        break;
      case 'submit_to_assignment':
        setMilestoneType(createEmptySubmitAssignmentMilestone());
        break;
      case 'complete_assignment':
        setMilestoneType(createEmptyCompleteAssignmentMilestone());
        break;
      case 'discuss':
        setMilestoneType(createEmptyDiscussionMilestone());
        break;
      case 'download_attachment':
        setMilestoneType(createEmptyAttachmentMilestone());
        break;
      case 'submit_insight':
        setMilestoneType(createEmptyFormMilestone());
        break;
      case 'submit_feedback':
        setMilestoneType(createFeedbackMilestone());
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div>
        <MilestoneFormHeader groupId={props.groupId} />
      </div>
      <div>
        <MilestoneForm
          groupId={props.groupId}
          milestoneDetails={milestoneDetails}
          setMilestoneDetails={setMilestoneDetails}
          milestoneType={milestoneType}
          setMilestoneType={setMilestoneType}
          error={mutation.isError ? __('An error occurred while creating the milestone.') : ''}
          success={''}
          isEditForm={false}
          handleSubmit={handleSubmit}
          handleTypeChange={handleTypeChange}
        />
      </div>
    </>
  );
};

export default withQueryClient(NewMilestoneContainer);
