import { useRef, useEffect, useCallback } from 'react';

interface FilePicker {
  accept: string;
  onPicked: (files: FileList | null) => void;
}

const useFilePicker = ({
  accept,
  onPicked,
}: FilePicker) => {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', accept);
    inputRef.current = input;

    return () => {
      input.remove();
    };
  }, [accept]);

  useEffect(() => {
    const input = inputRef.current;
    if (!input) { return undefined; }

    function handler(this: HTMLInputElement) {
      onPicked(this.files);
      this.value = '';
    }

    input.addEventListener('change', handler);

    return () => {
      input.removeEventListener('change', handler);
    };
  }, [onPicked]);

  const selectFile = useCallback(() => {
    inputRef.current?.click();
  }, []);

  return selectFile;
};

export default useFilePicker;
