import { Parser } from '@json2csv/plainjs';
import { ChartsData } from './types';
import { isNotEmpty } from '../../common/utils/utils';

export const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

export const isDateInRange = (
  date: Date | string | number,
  rangeStart?: Date | string,
  rangeEnd?: Date | string,
) => {
  if (rangeStart && new Date(date).getTime() < new Date(rangeStart).getTime()) {
    return false;
  }

  if (rangeEnd && new Date(date).getTime() > new Date(rangeEnd).getTime()) {
    return false;
  }

  return true;
};

export const aggregateChartsData = (
  chartsData: ChartsData,
  dateStart?: string,
  dateEnd?: string,
) => {
  const aggregatedData = new Map<string, number>();

  chartsData.series.forEach((data) => {
    const total = data.data.reduce((acc, [date, value]) => {
      if (isDateInRange(date, dateStart, dateEnd)) {
        return acc + value;
      }

      return acc;
    }, 0);

    aggregatedData.set(data.name, total);
  });

  return aggregatedData;
};

export const groupChartsDataByDate = (chartsData: ChartsData) => {
  const groupedData = new Map<string, Record<string, number>>();

  chartsData.series.forEach((data) => {
    data.data.forEach(([key, value]) => {
      const entry = groupedData.get(key);

      if (entry) {
        entry[data.name] = value;
      } else {
        groupedData.set(key, { [data.name]: value });
      }
    });
  });

  return groupedData;
};

const convertToCsv = (
  data: object | object[],
  opts?: { fields?: string[] },
) => {
  const parser = new Parser({ fields: opts?.fields });
  const csv = parser.parse(data);
  return csv;
};

const downloadCsv = (csvString: string, filename = 'data.csv') => {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const generateAggregatesDataCsv = (
  chartsData: ChartsData,
  dateStart: string,
  dateEnd: string,
) => {
  const fields = chartsData.series.map(({ name }) => name);

  const aggregates = aggregateChartsData(chartsData, dateStart, dateEnd);
  const filteredData = Object.fromEntries(
    [...aggregates.entries()].filter(([date]) =>
      isDateInRange(date, dateStart, dateEnd),
    ),
  );

  return convertToCsv(filteredData, { fields });
};

const sortGroupedData = (chartsData: ChartsData) =>
  [...groupChartsDataByDate(chartsData).entries()]
    .map(([date, values]) => ({
      dateAsString: date,
      date: new Date(date),
      values,
    }))
    .sort((a, b) => a.date.valueOf() - b.date.valueOf());

const generateLineChartsDataCsv = (
  chartsData: ChartsData,
  dateStart: string,
  dateEnd: string,
) => {
  const fields = chartsData.series.map(({ name }) => name);

  const data = sortGroupedData(chartsData)
    .filter(({ date }) => isDateInRange(date, dateStart, dateEnd))
    .map(({ dateAsString, values }) => ({
      date: dateAsString,
      ...values,
    }));

  return convertToCsv(data, { fields: ['date', ...fields] });
};

export const downloadAggregatesDataCsv = (
  chartsData: ChartsData,
  dateStart: string,
  dateEnd: string,
) =>
  downloadCsv(
    generateAggregatesDataCsv(chartsData, dateStart, dateEnd),
    [__('Metrics Totals'), dateStart, dateEnd].filter(isNotEmpty).join(' '),
  );

export const downloadLineChartsDataCsv = (
  chartsData: ChartsData,
  dateStart: string,
  dateEnd: string,
) =>
  downloadCsv(
    generateLineChartsDataCsv(chartsData, dateStart, dateEnd),
    [__('Weekly Metrics'), dateStart, dateEnd].filter(isNotEmpty).join(' '),
  );
