import React, { useState } from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import {
  isButtonMilestone,
  isPageMilestone,
  isReflectionMilestone,
  isAssignmentSubmitMilestone,
  isAssignmentCompleteMilestone,
  isDiscussionMilestone,
  isAttachmentMilestone,
  getMilestoneLabel,
  isGuideMilestone,
  hasValidResourceID,
  isFormMilestone,
  isLinkReflectionMilestone,
  isFeedbackMilestone,
} from '../utils/utils';
import useFindPageQuery from '../../../../common/hooks/api/pages/useFindPageQuery';
import useFindReflectionQuery from '../../../../common/hooks/api/reflections/useFindReflectionQuery';
import useFindGroupAssignmentQuery from '../../../../common/hooks/api/groups/useFindGroupAssignmentQuery';
import useFindDiscussionQuery from '../../../../common/hooks/api/discussions/useFindDiscussionQuery';
import useFindAttachmentQuery from '../../../../common/hooks/api/attachments/useFindAttachmentQuery';
import useFindFormQuery from '../../../../common/hooks/api/forms/useFindFormQuery';
import ResourceSelectorModal from './ResourceSelectorModal';
import { replaceKeys } from '../../../utils/utils';
import { Button } from '../../../../common/components/Button';
import { isEnum } from '../../../../common/utils/utils';

interface ButtonInputProps {
  value: string;
  onChange: (value: Atlas.MilestoneType) => void;
}

const ButtonInput: React.FC<ButtonInputProps> = (
  {value, onChange}) => {
  return (
    <div className="tw-mb-3">
      <label
        className="tw-font-semibold"
        htmlFor="buttonTextInput">
          {__('Button Text')}
        </label>
      <input
        id="buttonTextInput"
        name="buttonText"
        required
        className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
        placeholder={__('Button text')}
        value={value}
        onChange={(e) => (onChange({button: {text: e.target.value}}))}
      />
    </div>
  );
}

interface PageInputProps {
  page?: Atlas.Page | undefined ;
  onClick: () => void;
}

const PageInput: React.FC<PageInputProps> = (props) => {
  return (
    <div>
      <label className="tw-font-semibold">{__('Page')}</label>
      <Button className="tw-mb-3" onClick={props.onClick}>
        {props.page
          ? replaceKeys(
            __('Page: {{page_name}}'),
            { page_name: props.page.title },
          )
          : __('Select Page')}
      </Button>
    </div>
  )
}

interface PageInputContainerProps {
  type: Atlas.PageMilestone;
  openModal: () => void;
}

const PageInputContainer: React.FC<PageInputContainerProps> = (
  {type, openModal}) => {

  const { data: page } = useFindPageQuery(
    type.page_id !== undefined ? type.page_id : -1,
    { enabled: (type.page_id !== undefined && type.page_id !== null) });

  return <PageInput page={page?.data} onClick={openModal}/>
}

interface ReflectionInputProps {
  reflection?: Atlas.Reflection | undefined ;
  onClick: () => void;
}

const ReflectionInput: React.FC<ReflectionInputProps> = (props) => {
  return (
    <div>
      <label className="tw-font-semibold">{__('Reflection')}</label>
      <Button className="tw-mb-3" onClick={props.onClick}>
        {props.reflection
          ? replaceKeys(
            __('Reflection: {{reflection_name}}'),
            { reflection_name: props.reflection.name },
          )
          : __('Select Reflection')}
      </Button>
    </div>
  )
}

interface ReflectionInputContainerProps {
  type: Atlas.ReflectionMilestone;
  openModal: () => void;
}

const ReflectionInputContainer: React.FC<ReflectionInputContainerProps> = (
  {type, openModal}) => {

  const { data: reflection } = useFindReflectionQuery(
    type.reflection_id !== undefined ? type.reflection_id : -1,
    { enabled: (type.reflection_id !== undefined && type.reflection_id !== null) });

  return <ReflectionInput reflection={reflection?.data} onClick={openModal}/>
}

interface AssignmentInputProps {
  assignment?: Atlas.Assignment | undefined ;
  onClick: () => void;
}

const AssignmentInput: React.FC<AssignmentInputProps> = (props) => {
  return (
    <div>
      <label className="tw-font-semibold">{__('Assignment')}</label>
      <Button className="tw-mb-3" onClick={props.onClick}>
        {props.assignment
          ? replaceKeys(
            __('Assignment: {{assignment_name}}'),
            { assignment_name: props.assignment.name },
          )
          : __('Select Assignment')}
      </Button>
    </div>
  )
}

interface AssignmentInputContainerProps {
  groupId: Atlas.GroupID;
  type: Atlas.AssignmentMilestone;
  openModal: () => void;
}

const AssignmentInputContainer: React.FC<AssignmentInputContainerProps> = (
  {groupId, type, openModal}) => {

  const { data: assignment } = useFindGroupAssignmentQuery(
    groupId,
    type.assignment_id !== undefined ? type.assignment_id : -1,
    { enabled: (type.assignment_id !== undefined && type.assignment_id !== null) });

  return <AssignmentInput assignment={assignment?.data} onClick={openModal}/>
}

interface DiscussionInputProps {
  discussion?: Atlas.Discussion | undefined ;
  onClick: () => void;
}

const DiscussionInput: React.FC<DiscussionInputProps> = (props) => {
  return (
    <div>
      <label className="tw-font-semibold">{__('Discussion')}</label>
      <Button className="tw-mb-3" onClick={props.onClick}>
        {props.discussion
          ? replaceKeys(
            __('Discussion: {{discussion_name}}'),
            { discussion_name: props.discussion.title },
          )
          : __('Select Discussion')}
      </Button>
    </div>
  )
}

interface DiscussionInputContainerProps {
  type: Atlas.DiscussionMilestone;
  openModal: () => void;
}

const DiscussionInputContainer: React.FC<DiscussionInputContainerProps> = ({type, openModal}) => {

  const { data: discussion } = useFindDiscussionQuery(
    { discussionId: type.discussion_id !== undefined ? type.discussion_id : -1},
    { enabled: (type.discussion_id !== undefined && type.discussion_id !== null) });

  return <DiscussionInput discussion={discussion?.data} onClick={openModal}/>
}

interface AttachmentInputProps {
  attachment?: Atlas.Attachment | undefined ;
  onClick: () => void;
}

const AttachmentInput: React.FC<AttachmentInputProps> = (props) => {
  return (
    <div>
      <label className="tw-font-semibold">{__('Attachment')}</label>
      <Button className="tw-mb-3" onClick={props.onClick}>
        {props.attachment
          ? replaceKeys(
            __('Attachment: {{attachment_name}}'),
            { attachment_name: props.attachment.name },
          )
          : __('Select Attachment')}
      </Button>
    </div>
  )
}

interface AttachmentInputContainerProps {
  type: Atlas.AttachmentMilestone;
  openModal: () => void;
}

const AttachmentInputContainer: React.FC<AttachmentInputContainerProps> = (
  {type, openModal}) => {

  const { data: attachment } = useFindAttachmentQuery(
    type.attachment_id !== undefined ? type.attachment_id : -1,
    { enabled: (type.attachment_id !== undefined && type.attachment_id !== null) });

  return <AttachmentInput attachment={attachment?.data} onClick={openModal}/>
}

interface InsightInputProps {
  insight?: Atlas.Form | undefined ;
  onClick: () => void;
}

const InsightInput: React.FC<InsightInputProps> = (props) => {
  return (
    <div>
      <label className="tw-font-semibold">{__('Insight')}</label>
      <Button className="tw-mb-3" onClick={props.onClick}>
        {props.insight
          ? replaceKeys(
            __('Insight: {{insight_name}}'),
            { insight_name: props.insight.name },
          )
          : __('Select Insight')}
      </Button>
    </div>
  )
}

interface InsightInputContainerProps {
  type: Atlas.FormMilestone;
  openModal: () => void;
}

const InsightInputContainer: React.FC<InsightInputContainerProps> = ({type, openModal}) => {

  const { data: insight } = useFindFormQuery(
    { params: { formId: type.form_id !== undefined ? type.form_id : -1 } },
    { enabled: (type.form_id !== undefined && type.form_id !== null) });

  return <InsightInput insight={insight?.data} onClick={openModal}/>
}

interface FeedbackInputProps {
  feedback: Atlas.FeedbackType;
  onChange: (value: Atlas.MilestoneType) => void;
}

const FeedbackInput: React.FC<FeedbackInputProps> = ({feedback, onChange}) => {

  const setFeedbackType = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = event.target;

    if (!isEnum(value, Atlas.FeedbackType)) return;

    onChange({ submit_feedback: { type: value } });
  }

  return (
    <div className="tw-mb-3">
      <label
        className="tw-font-semibold"
        htmlFor="feedbackTypeInput">
          {__('Feedback Type')}
      </label>
      <select
        id="feedbackTypeInput"
        required
        className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
        value={feedback}
        onChange={setFeedbackType}>
        <option value={Atlas.FeedbackType.CoachSummary}>{__('Coach feedback')}</option>
        <option value={Atlas.FeedbackType.TraineeSummary}>{__('Coachee feedback')}</option>
        <option value={Atlas.FeedbackType.Objective}>{__('Objective')}</option>
        <option value={Atlas.FeedbackType.LearningChallenge}>{__('Learning challenge')}</option>
        <option value={Atlas.FeedbackType.Action}>{__('Action')}</option>
        <option value={Atlas.FeedbackType.Impact}>{__('Impact')}</option>
        <option value={Atlas.FeedbackType.SuccessSummary}>{__('Success summary')}</option>
      </select>
    </div>
  );
}

interface MilestoneTypeFieldProps {
  groupId: Atlas.GroupID;
  type: Atlas.MilestoneType;
  setType: (type: Atlas.MilestoneType) => void;
  openModal: () => void;
}

const MilestoneTypeField: React.FC<MilestoneTypeFieldProps> = (
  {groupId, type, setType, openModal}) => {
  if (isButtonMilestone(type)) {
    return (
      <ButtonInput
        onChange={setType}
        value={type.button.text}
      />
    );
  } else if (isPageMilestone(type)) {
    return (
      <PageInputContainer
        type={type.visit_page}
        openModal={openModal}
      />
    );
  } else if (isGuideMilestone(type)) {
    return (
      <PageInputContainer
        type={type.link_guide}
        openModal={openModal}
      />
    );
  } else if (isLinkReflectionMilestone(type)) {
    return (
      <PageInputContainer
        type={type.link_reflection}
        openModal={openModal}
      />
    );
  } else if (isReflectionMilestone(type)) {
    return (
      <ReflectionInputContainer
        type={type.watch_reflection}
        openModal={openModal}
      />
    );
  } else if (isAssignmentSubmitMilestone(type)) {
    return (
      <AssignmentInputContainer
        groupId={groupId}
        type={type.submit_to_assignment}
        openModal={openModal}
      />
    );
  } else if (isAssignmentCompleteMilestone(type)) {
    return (
      <AssignmentInputContainer
        groupId={groupId}
        type={type.complete_assignment}
        openModal={openModal}
      />
    );
  } else if (isDiscussionMilestone(type)) {
    return (
      <DiscussionInputContainer
        type={type.discuss}
        openModal={openModal}
      />
    );
  } else if (isAttachmentMilestone(type)) {
    return (
      <AttachmentInputContainer
        type={type.download_attachment}
        openModal={openModal}
      />
    );
  } else if (isFormMilestone(type)) {
    return (
      <InsightInputContainer
        type={type.submit_insight}
        openModal={openModal}
      />
    );
  } else if (isFeedbackMilestone(type)) {
    return (
      <FeedbackInput
        feedback={type.submit_feedback.type}
        onChange={setType}
      />
    );
  } else {
    return null;
  }
}

interface MilestoneDetails {
  name: Atlas.MilestoneName;
  description: Atlas.MilestoneDescription;
  notify: Atlas.MilestoneNotify;
  notification_text?: string | null;
  completesPathway: boolean;
}

interface MilestoneFormProps {
  groupId: Atlas.GroupID;
  milestoneDetails: MilestoneDetails;
  milestoneType: Atlas.MilestoneType;
  error: string;
  success: string;
  isEditForm: boolean;
  setMilestoneDetails: (details: MilestoneDetails) => void
  handleTypeChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  setMilestoneType: (value: Atlas.MilestoneType) => void;
}

const MilestoneForm = (props: MilestoneFormProps): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setShowModal(true);
  }

  const handleModalClosed = () => {
    setShowModal(false);
  }

  const onSelectedResource = (type: Atlas.MilestoneType) => {
    props.setMilestoneType(type);
    handleModalClosed();
  }

  const isFormValid = () => {
    const isNameValid = !!props.milestoneDetails.name;
    const isButtonTextValid = isButtonMilestone(props.milestoneType)
      ? props.milestoneType.button.text !== ""
      : true
    const isResourceLinkedValid = !isButtonMilestone(props.milestoneType) && !isFeedbackMilestone(props.milestoneType)
      ? hasValidResourceID(props.milestoneType)
      : true

    return isNameValid && isButtonTextValid && isResourceLinkedValid;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setMilestoneDetails({
      ...props.milestoneDetails,
      name: e.target.value,
    });
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.setMilestoneDetails({
      ...props.milestoneDetails,
      description: e.target.value,
    });
  };

  const handleCompletesPathwayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setMilestoneDetails({
      ...props.milestoneDetails,
      completesPathway: e.target.checked,
    });
  }

  const handleNotifyChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = e.target;

    if (!isEnum(value, Atlas.MilestoneNotify)) return;

    props.setMilestoneDetails({
      ...props.milestoneDetails,
      notify: value,
      notification_text: value === 'none' ? null : props.milestoneDetails.notification_text,
    });
  }

  const handleNotificationTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.setMilestoneDetails({
      ...props.milestoneDetails,
      notification_text: e.target.value,
    });
  }

  return (
    <>
      <form onSubmit={props.handleSubmit}>
        <div className="tw-iris-element-box">
          <div className="tw-flex tw-flex-row tw-gap-5">
            <div className="tw-flex tw-flex-col tw-flex-1">
              <div className="tw-mb-3">
                <label className="tw-font-semibold" htmlFor="nameInput">{__('Name')}</label>
                <input
                  id="nameInput"
                  name="name"
                  autoFocus
                  required
                  className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
                  placeholder={__('Milestone name')}
                  value={props.milestoneDetails.name}
                  onChange={handleNameChange}
                />
              </div>

              <div className="tw-mb-3">
                <label className="tw-font-semibold" htmlFor="typeSelect">{__('Type')}</label>
                <select
                  id="typeSelect"
                  name="name"
                  required
                  className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
                  value={getMilestoneLabel(props.milestoneType)}
                  onChange={props.handleTypeChange}>
                  <option value="button">{__('Button')}</option>
                  <option value="visit_page">{__('Visit Page')}</option>
                  <option value="link_guide">{__('Link Guide')}</option>
                  <option value="link_reflection">{__('Link Reflection')}</option>
                  <option value="watch_reflection">{__('Watch Reflection')}</option>
                  <option value="submit_to_assignment">{__('Submit to Assignment')}</option>
                  <option value="complete_assignment">{__('Complete Assignment')}</option>
                  <option value="discuss">{__('Discussion')}</option>
                  <option value="download_attachment">{__('Download Attachment')}</option>
                  <option value="submit_insight">{__('Submit to Insight')}</option>
                  <option value="submit_feedback">{__('Submit Feedback')}</option>
                </select>
              </div>

              <MilestoneTypeField
                groupId={props.groupId}
                type={props.milestoneType}
                setType={props.setMilestoneType}
                openModal={handleModalOpen}
              />
              
              <div className="tw-flex tw-flex-col tw-gap-2">
                <label
                  htmlFor="completesPathwayInput"
                  className="checkbox tw-mb-0 tw-text-base"
                >
                  <input
                    id="completesPathwayInput"
                    type="checkbox"
                    name="completesPathway"
                    className="tw-w-4 tw-h-4 tw-align-middle "
                    checked={props.milestoneDetails.completesPathway}
                    onChange={handleCompletesPathwayChange}
                  />
                  {__('Completes pathway')}
                </label>
              </div>

            </div>
            <div className="tw-flex tw-flex-col tw-flex-1">
              <div className="tw-mb-3">
                <label className="tw-font-semibold" htmlFor="descriptionInput">{__('Description')}</label>
                <textarea
                  id="descriptionInput"
                  name="description"
                  rows={2}
                  className="tw-textarea tw-textarea-bordered tw-block tw-w-full tw-flex-1 tw-resize-none"
                  placeholder={__('Milestone description')}
                  value={props.milestoneDetails.description}
                  onChange={handleDescriptionChange}
                />
              </div>

              <div className="tw-mb-3">
                <label
                  className="tw-font-semibold"
                  htmlFor="notifyInput">
                    {__('Notify')}
                </label>
                <select
                  id="notifyInput"
                  required
                  className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
                  value={props.milestoneDetails.notify}
                  onChange={handleNotifyChange}>
                  <option value={Atlas.MilestoneNotify.None}>{__('None')}</option>
                  <option value={Atlas.MilestoneNotify.PathwayUser}>{__('Pathway user')}</option>
                  <option value={Atlas.MilestoneNotify.Admins}>{__('Admins')}</option>
                  <option value={Atlas.MilestoneNotify.AdminsAndPathwayUser}>{__('Admins and pathway user')}</option>
                  <option value={Atlas.MilestoneNotify.All}>{__('All')}</option>
                </select>
              </div>

              {props.milestoneDetails.notify !== Atlas.MilestoneNotify.None && 
                <div>
                  <label className="tw-font-semibold" htmlFor="notificationTextInput">{__('Notification text')}</label>
                  <textarea
                    id="notificationTextInput"
                    name="notificationText"
                    rows={2}
                    className="tw-textarea tw-textarea-bordered tw-block tw-w-full tw-flex-1 tw-resize-none"
                    placeholder={__('Email notification text')}
                    value={props.milestoneDetails.notification_text || ''}
                    onChange={handleNotificationTextChange}
                  />
                </div>
              }

              <ResourceSelectorModal
                isOpen={showModal}
                milestoneType={props.milestoneType}
                groupId={props.groupId}
                onSelectResource={onSelectedResource}
                onClose={handleModalClosed}
              />
              {props.error && <p style={{ color: 'red' }}>{props.error}</p>}
              {props.success && <p style={{ color: 'green' }}>{props.success}</p>}
            </div>
          </div>
        </div>
        <div className="tw-iris-element-box tw-mt-3 tw-flex tw-justify-end">
          <button
            className="tw-btn tw-btn-sm tw-btn-success"
            type="submit"
            disabled={!isFormValid()}>
              {props.isEditForm ? __('Update Milestone'): __('Create Milestone')}
          </button>
        </div>
      </form>
    </>
  );
};

export default MilestoneForm;
