import React, { useRef } from 'react';
import { AttachmentID } from '../types/Atlas';
import useFindAttachmentQuery from '../hooks/api/attachments/useFindAttachmentQuery';
import { useIntersection } from 'react-use';

const AttachmentName: React.FC<{
  attachmentId: AttachmentID;
  className?: string;
}> = ({
  attachmentId,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersection(ref, {});
  const visible = entry?.isIntersecting ?? false;

  const query = useFindAttachmentQuery(attachmentId, { enabled: visible });

  switch (query.status) {
    case 'idle':
    case 'loading': return (
      <div className={className} ref={ref}>
        {__('Loading...')}
      </div>
    );

    case 'success': return (
      <div className={className} ref={ref}>
        {query.data.data.name}
      </div>
    );

    case 'error':
    default: return (
      <div className={className} ref={ref}>
        {__('Something went wrong.')}
      </div>
    );
  }
};

export default AttachmentName;
