import { useQuery, UseQueryResult } from 'react-query';
import { findForm, FindFormArgs, FindFormResponse } from '../../../api/forms';

const useFindFormQuery = (
  args: FindFormArgs,
  options?: { enabled?: boolean; },
): UseQueryResult<FindFormResponse> => (
  useQuery(
    ['forms', args.params.formId], 
    () => findForm(args),
    { ...options },
  )
);

export default useFindFormQuery;
