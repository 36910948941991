import React, { useState } from 'react';
import { Modal, ModalBox } from '../../../../common/components/TailwindModal';
import useUpdatePageMutation from '../../../../common/hooks/api/pages/useUpdatePageMutation';
import * as Atlas from '../../../../common/types/Atlas';
import PageTitleForm from './PageTitleForm';

interface RenamePageButtonProps {
  page: Atlas.Page;
}

const RenamePageButton = (props: RenamePageButtonProps) => {
  const { page } = props;

  const [open, setOpen] = useState(false);

  const updateGroupPage = useUpdatePageMutation({ pageId: page.id });

  const toggle = () => {
    setOpen((o) => !o);
  };

  const handleTitleSave = async ({ title }: Pick<Atlas.Page, 'title'>) => {
    await updateGroupPage.mutateAsync({
      title,
    });

    setOpen(false);
  };

  return (
    <>
      <button type="button" onClick={toggle}>
        {__('Rename')}
      </button>

      <Modal isOpen={open} onClose={toggle}>
        <ModalBox>
          <p className="tw-text-xl tw-font-bold tw-mb-4">
            {(() => {
              switch (page.type) {
                case Atlas.PageType.Heading: {
                  return __('Rename Heading');
                }

                default: {
                  return __('Rename Page');
                }
              }
            })()}
          </p>

          <PageTitleForm
            initialTitle={page.title}
            pageType={page.type}
            onSave={handleTitleSave}
            onCancel={toggle}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

export default RenamePageButton;
