
import { useQuery, UseQueryResult } from 'react-query';
import { findVideoCaptions, FindVideoCaptionsArguments, FindVideoCaptionsResponse } from '../../../api/videos/[videoId]/captions';

const useFindVideoCaptionsQuery = (
  args: FindVideoCaptionsArguments,
  options?: { staleTime?: number; enabled?: boolean; },
): UseQueryResult<FindVideoCaptionsResponse> => {
  const { videoId, ...otherParams } = args.params;

  return (
    useQuery(
      ['videos', videoId, 'captions', otherParams],
      () => findVideoCaptions(args),
      options
    )
  );
};

export default useFindVideoCaptionsQuery;
