import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObjectArray } from '../../api-parser';
import ApiError from '../../error';
import { jsonHeaders, Metadata, PaginationParams, searchParams } from '../../routes';

export const groupAttachmentsRoute = (args: {
  groupId: Atlas.GroupID;
}): string => (
  `/api/v0/groups/${args.groupId}/attachments`
);

export type FindGroupAttachmentsParams = PaginationParams & {
  groupId: Atlas.GroupID;
}

export interface FindGroupAttachmentsArguments {
  params: FindGroupAttachmentsParams;
}

export interface FindGroupAttachmentsResponse {
  data: Atlas.Attachment[];
  metadata: Metadata;
}

export const findGroupAttachments = async (
  args: FindGroupAttachmentsArguments
): Promise<FindGroupAttachmentsResponse> => {
  const { groupId, ...params } = args.params;

  const url = [
    groupAttachmentsRoute( { groupId }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findGroupAttachments');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.Attachment>(body.attachments);
  const metadata = body.metadata as Metadata;

  return { data, metadata };
}
