import * as Atlas from '../../types/Atlas';
import { jsonHeaders, Metadata, PaginationParams, searchParams, ViewID } from '../routes';

const reflectionsRoute = (): string => '/reflections';

interface FindCondensedReflectionsArgs {
  params?: PaginationParams & { filter?: 'mine'; };
}

export const findCondensedReflections = (args: FindCondensedReflectionsArgs): Promise<{ reflections: Atlas.CondensedReflection[], metadata: Metadata }> => {
  const { params } = args || {};

  const reflectionUrl = [
    reflectionsRoute(),
    searchParams({ ...params, view: ViewID.Condensed })
  ].join('?');

  return fetch(reflectionUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] findCondensedReflections failed`; }
    return r.json();
  });
};
