import { useMutation, UseMutationResult } from 'react-query';
import { reorderGroupPages, ReorderGroupPagesBody, ReorderGroupPagesParams, ReorderGroupPagesResponse } from '../../../api/groups/[groupId]/pages';
import { queryClient } from '../../withQueryClient';

const useReorderGroupPagesMutation = (
  params: ReorderGroupPagesParams,
): UseMutationResult<ReorderGroupPagesResponse, unknown, ReorderGroupPagesBody, unknown> => {
  const mutation = useMutation(
    (body: ReorderGroupPagesBody) => reorderGroupPages({ params, body }),
    {
      onSuccess: (_, vars) => {
        void queryClient.invalidateQueries(['groups', params.groupId, 'pages']);

        vars.pageIds.forEach((pageId) => {
          void queryClient.invalidateQueries(['pages', pageId]);
        });
      },
    },
  );

  return mutation;
};

export default useReorderGroupPagesMutation;
