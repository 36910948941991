import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject } from '../../../api-parser';
import ApiError from '../../../error';
import { generateFetchHeaders } from '../../../routes';

export const prepareReflectionRoute = (args: {
  reflectionId: Atlas.ReflectionID,
}): string => `/api/v0/reflections/${args.reflectionId}/prepare`;

export interface PrepareReflectionParams {
  reflectionId: Atlas.ReflectionID;
}

export interface PrepareReflectionBody {
  playback_mode: Atlas.PlaybackMode;
}

export interface PrepareReflectionArguments {
  params: PrepareReflectionParams;
  body: PrepareReflectionBody;
}

export interface PrepareReflectionResponse {
  data: Atlas.Reflection;
}

export const prepareReflection = async (
  args: PrepareReflectionArguments,
): Promise<PrepareReflectionResponse> => {
  const { reflectionId } = args.params;

  const url = prepareReflectionRoute({ reflectionId });

  const response = await fetch(url, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'prepareReflection');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Reflection>(body.data);

  return { data };
};
