import { useMutation } from 'react-query';
import { createVideoCaption, CreateVideoCaptionArguments, CreateVideoCaptionResponse } from '../../../api/videos/[videoId]/captions';

const useCreateVideoCaptionMutation = () => {
  const mutation = useMutation<CreateVideoCaptionResponse, unknown, CreateVideoCaptionArguments, unknown>(
    createVideoCaption,
  );

  return mutation;
};

export default useCreateVideoCaptionMutation;
