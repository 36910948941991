import React from 'react';

import { Text } from 'recharts';

interface AxisTickProps {
  payload?: {
    value: string;
  };
  x?: number;
  y?: number;
}

const AxisTick = (props: AxisTickProps): JSX.Element => {
  const { payload, x, y } = props;

  return (
    <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload?.value}
    </Text>
  );
};

export default AxisTick;
