import React, { useState } from 'react';
import {
  Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { colors } from '../../../common/utils/colors';
import { formatSeconds, truncate } from '../../../common/utils/utils';
import * as Atlas from '../../../common/types/Atlas';
import { FormReportingFilter } from '../types';

interface TimerFormFieldStatsProps {
  field: Atlas.FormField;
  data: Array<{
    filter: FormReportingFilter;
    overview: Atlas.FormFieldOverview;
  }>;
}

enum ChartType {
  Count,
  Duration,
}

const TimerFormFieldStats = (props: TimerFormFieldStatsProps): JSX.Element => {
  const { field, data } = props;

  const choices = new Set<string>();

  const chartData = data.reduce<Array<{
    filterName: string;
  } & Record<string, number | string>>>((acc, datum) => {
    if ('timer' in datum.overview.stats) {
      acc.push({
        filterName: datum.filter.name,
        ...datum.overview.stats.timer.reduce<Record<string, number>>((acc2, choiceStats) => {
          choices.add(choiceStats.choice);

          return {
            ...acc2,
            [`${choiceStats.choice}-count`]: choiceStats.count,
            [`${choiceStats.choice}-duration`]: choiceStats.total_duration,
          };
        }, {}),
      });
    }

    return acc;
  }, []);

  const [chartType, setChartType] = useState(ChartType.Count);

  return (
    <div>
      <div className="tw-flex tw-justify-between">
        <div>
          <p className="tw-text-gray-600">{__('Timer')}</p>
          <p className="tw-my-0 tw-font-bold tw-text-lg">{truncate(field.label, { maxLength: 500 })}</p>
        </div>

        <div className="tw-flex tw-gap-1">
          <button
            type="button"
            className={`tw-btn tw-btn-xs ${chartType === ChartType.Count ? 'tw-btn-primary' : 'tw-btn-ghost'}`}
            onClick={() => setChartType(ChartType.Count)}
          >
            {__('Count')}
          </button>
          <button
            type="button"
            className={`tw-btn tw-btn-xs ${chartType === ChartType.Duration ? 'tw-btn-primary' : 'tw-btn-ghost'}`}
            onClick={() => setChartType(ChartType.Duration)}
          >
            {__('Duration')}
          </button>
        </div>
      </div>

      <hr className="tw-my-4" />

      {(() => {
        switch (chartType) {
          case ChartType.Count: {
            return (
              <ResponsiveContainer height={200} width="100%">
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="filterName" name={__('Filter')} />
                  <YAxis allowDecimals={false} type="number" name={__('Count')} />
                  <Tooltip />
                  <Legend formatter={(value: string) => truncate(value, { maxLength: 120 })} />
                  {[...choices].map((choice, i) => (
                    <Bar
                      key={choice}
                      dataKey={`${choice}-count`}
                      name={choice}
                      fill={colors[i % colors.length]}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            );
          }

          case ChartType.Duration: {
            return (
              <ResponsiveContainer height={200} width="100%">
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="filterName" name={__('Filter')} />
                  <YAxis allowDecimals={false} type="number" name={__('Duration')} tickFormatter={(value: number) => formatSeconds(value / 1000)} />
                  <Tooltip formatter={(value) => (typeof value === 'number' ? formatSeconds(value / 1000) : value)} />
                  <Legend formatter={(value: string) => truncate(value, { maxLength: 120 })} />
                  {[...choices].map((choice, i) => (
                    <Bar
                      key={choice}
                      dataKey={`${choice}-duration`}
                      name={choice}
                      fill={colors[i % colors.length]}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            );
          }

          default: return null;
        }
      })()}
    </div>
  );
};

export default TimerFormFieldStats;
