import React from 'react';

const SearchResultsContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <div className="tw-bg-base-100 tw-rounded-lg tw-shadow-lg tw-p-1 tw-border tw-border-base-200 tw-space-y-1 tw-h-full tw-overflow-y-auto">
    {children}
  </div>
);

export default SearchResultsContainer;
