import { cx } from 'cva';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

const DropdownContext = React.createContext<{
  isOpen?: boolean;
  toggle?: () => void;
}>({});

export const Dropdown: React.FC<{
  children: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  toggle?: () => void;
}> = ({
  children,
  className,
  isOpen,
  toggle,
}) => {
  const value = useMemo(() => ({
    isOpen,
    toggle,
  }), [isOpen, toggle]);

  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => {
    if (!isOpen) { return; }
    toggle?.();
  });

  return (
    <DropdownContext.Provider value={value}>
      <div
        ref={ref}
        className={cx('dropdown', className, { show: isOpen })}
      >
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

export const UncontrolledDropdown: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({
  children,
  className,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Dropdown className={className} isOpen={isOpen} toggle={() => setOpen((o) => !o)}>
      {children}
    </Dropdown>
  );
};

export const DropdownToggle: React.FC<{
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}> = ({
  children,
  className,
  style,
}) => {
  const { toggle } = useContext(DropdownContext);

  return (
    <button
      type="button"
      className={cx('btn btn-secondary', className)}
      onClick={toggle ? (() => toggle()) : undefined}
      style={style}
    >
      {children}
    </button>
  );
};

export const DropdownMenu: React.FC<{
  children: React.ReactNode;
  right?: boolean;
  className?: string;
}> = ({
  children,
  right,
  className,
}) => {
  const { isOpen } = useContext(DropdownContext);

  return (
    <div
      className={cx('dropdown-menu', className, { show: isOpen, 'dropdown-menu-right': right })}
      tabIndex={-1}
      role="menu"
    >
      {children}
    </div>
  );
};

export const DropdownItem: React.FC<{
  children?: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  header?: boolean;
  divider?: boolean;
}> = ({
  children,
  className,
  onClick,
  disabled,
  header,
  divider,
}) => {
  const { isOpen, toggle } = useContext(DropdownContext);

  if (header) {
    return (
      <h6
        tabIndex={-1}
        className={cx('dropdown-header', className)}
      >
        {children}
      </h6>
    );
  }

  if (divider) {
    return (
      <div
        tabIndex={-1}
        className={cx('dropdown-divider', className)}
      >
        {children}
      </div>
    );
  }

  return (
    <button
      type="button"
      tabIndex={0}
      role="menuitem"
      className={cx('dropdown-item', className, { disabled })}
      disabled={disabled}
      onClick={(event) => {
        if (isOpen) {
          toggle?.();
        }

        onClick?.(event);
      }}
    >
      {children}
    </button>
  );
};
