import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import ShareReflection from '../../share/ShareReflection';

interface ReflectionSharingFormProps {
  reflectionId: Atlas.ReflectionID;
}

const ReflectionSharingForm = (props: ReflectionSharingFormProps) => {
  const { reflectionId } = props;

  return (
    <section>
      <ShareReflection
        reflectionId={reflectionId}
      />
    </section>
  );
};

export default ReflectionSharingForm;
