import React, { FormEvent, useCallback, useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import AssignmentQuestion from './AssignmentQuestion';
import { validateAssignmentSubmission } from './validateAssignmentSubmission';
interface GroupAssignmentSubmissionFormProps {
  readOnly?: boolean;
  assignment: Atlas.Assignment;
  submission: Partial<Atlas.AssignmentSubmission>;
  saving?: boolean;
  saved?: boolean;
  hasAnswers?: boolean;
  isAutoSaving?: boolean;
  discardDraft?: () => void;
  onSubmit?: (draft?: boolean) => void;
  onInit: (func: { (s: Partial<Atlas.AssignmentSubmission>): Partial<Atlas.AssignmentSubmission>; }) => void;
  onChange: (func: { (s: Partial<Atlas.AssignmentSubmission>): Partial<Atlas.AssignmentSubmission>; }) => void;
}

const GroupAssignmentSubmissionForm = (props: GroupAssignmentSubmissionFormProps): JSX.Element => {
  const { assignment, submission, onSubmit, onInit, onChange } = props;
  const [showValidation, setShowValidation] = useState(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowValidation(true);

    const results = validateAssignmentSubmission(assignment, submission);
    const isValid = Object.values(results).every(Boolean);

    if (!isValid) {
      // Find the first invalid field name and scroll to it
      const firstInvalidFieldName = Object.entries(results).find(([_, valid]) => !valid)?.[0];
      if (firstInvalidFieldName) {
        const element = document.getElementById(`question-${firstInvalidFieldName}`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    onSubmit?.();
    setShowValidation(false);
  };

  const handleAnswerInit = useCallback((answer: Atlas.FormAnswer) => {
    onInit((subm) => {
      const nextAnswers = subm.answers?.filter(a => a.name !== answer.name) ?? [];
      nextAnswers.push(answer);

      return {
        ...subm,
        answers: nextAnswers,
      };
    });
  }, [onInit]);

  const handleAnswerChange = useCallback((answer: Atlas.FormAnswer) => {
    onChange((subm) => {
      const nextAnswers = subm.answers?.filter(a => a.name !== answer.name) ?? [];
      nextAnswers.push(answer);

      return {
        ...subm,
        answers: nextAnswers,
      };
    });
  }, []);

  const handleDiscardDraft = (event: React.MouseEvent) => {
    event.preventDefault()
    props.discardDraft?.();
  }

  const answerForQuestion = (question: Atlas.FormElement) =>
    submission.answers?.find(a => a.name === question.field.name);

  return (
    <form onSubmit={handleSubmit} noValidate>
      {assignment.questions.map((q, i) => (
        <div 
          key={q.field.name} 
          id={`question-${q.field.name}`}
          className="tw-flex tw-mb-3 tw-p-4 tw-border-4 tw-rounded-xl tw-border-base-300 tw-iris-element-box !tw-shadow-none"
        >
          <div className="flex-fill" style={{ minWidth: 0 }}>
            <AssignmentQuestion
              readOnly={props.readOnly}
              question={q}
              answer={answerForQuestion(q)}
              saving={props.saving}
              saved={props.saved}
              showValidation={showValidation}
              onInit={handleAnswerInit}
              onAnswerChange={handleAnswerChange} />
          </div>
        </div>
      ))}

      {onSubmit ? (
        <React.Fragment>
          <div className="tw-divider">
          </div>

          <div className="tw-flex tw-justify-between">
             <button
                role="submit"
                className="tw-btn tw-btn-success"
                disabled={props.isAutoSaving}
                id="submit-assignment">
                {__('Submit')}
              </button>
              {props.hasAnswers && (
                <button
                  className="tw-btn"
                  disabled={!props.hasAnswers || props.isAutoSaving}
                  id="discard-draft"
                  onClick={handleDiscardDraft}>
                  {__('Discard draft')}
                  <i className="fa fa-trash tw-ml-1 tw-text-red-500"></i>
                </button>
              )}
          </div>
        </React.Fragment>
      ) : null}
    </form>
  );
};

export default GroupAssignmentSubmissionForm;
