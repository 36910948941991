import { useQuery, UseQueryResult } from 'react-query';
import { findComment, FindCommentParams, FindCommentResponse } from '../../../api/comments/[commentId]';

const useFindCommentQuery = (
  params: FindCommentParams,
): UseQueryResult<FindCommentResponse> => (
  useQuery(['comments', params.commentId], () => findComment({ params }))
);

export default useFindCommentQuery;
