import React, {
  useCallback, useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Atlas from '../../../common/types/Atlas';
import JwPlayer, {
  ErrorEvent, JwPlayerPlaylistItem, PlaylistItemEvent, ReadyEvent, SetupErrorEvent, useJwPlayer,
} from '../../jwplayer/JwPlayer';
import JwPlayerAudioChannels from '../../jwplayer/JwPlayerAudioChannels';
import useReflectionPlaylist from '../../video-editor/use-reflection-playlist';
import { getVideoChannel } from './utils';
import DualVideoStatusAlert from './DualVideoStatusAlert';
import Spinner from '../../../common/components/Spinner';
import { defaultJwplayerConfig } from '../../../common/utils/jwplayer-defaults';

const defaultAspectRatio = '16:9';

interface ReflectionPlayerProps {
  reflectionId: Atlas.ReflectionID;
}

const ReflectionPlayer = (props: ReflectionPlayerProps) => {
  const { reflectionId } = props;

  const [searchParams] = useSearchParams();

  const autoPlay = useMemo(() => {
    const videoId = searchParams.get('video_id');
    const startAt = searchParams.get('start_at');
    if (!videoId) { return null; }
    if (!startAt) { return null; }

    return {
      videoId: Number(videoId),
      startAt: Number(startAt),
    };
  }, [searchParams]);

  const [playerMounted, setPlayerMounted] = useState(false);
  const [error, setError] = useState(false);

  const onReady = useCallback<ReadyEvent>((_, player) => {

    setError(false);

    if (playerMounted) { return; }
    setPlayerMounted(true);
  }, [autoPlay, playerMounted]);

  const onSetupError = useMemo(() => () => {
    setError(true);

    if (playerMounted) { return; }
    setPlayerMounted(true);
  }, [playerMounted]);

  const onError = useCallback<SetupErrorEvent & ErrorEvent>((_param, player) => {
    const nextIndex = player.getPlaylistIndex() + 1;
    const lastIndex = player.getPlaylist().length - 1;
    if (lastIndex < nextIndex) { return; }

    player.playlistItem(nextIndex);
  }, []);

  const [activeVideoId, setActiveVideoId] = useState<number | undefined>(undefined);

  const onPlaylistItem = useCallback<PlaylistItemEvent>((param) => {
    const playlistItem = param.item as { video_id?: any; };
    if (typeof playlistItem.video_id !== 'number') { return; }

    setActiveVideoId(playlistItem.video_id);
  }, []);

  const playerState = useJwPlayer({
    onReady,
    onSetupError,
    onError,
    onPlaylistItem,
  });

  useEffect(() => {
    if (!playerMounted) { return; }
    if (!autoPlay) { return; }
    if (!playerState?.player) { return; }

    const currentIndex = playerState.player.getPlaylistIndex();
    const targetIndex = playerState.player.getPlaylist().findIndex((item) => item.video_id === autoPlay.videoId)

    const player = playerState.player;

    if (currentIndex === targetIndex) {
      const playerState = player.getState();
      // Only seek if the video is playing
      if (playerState === 'playing') {
        player.seek(autoPlay.startAt);
      // Otherwise, an event listener ensures we seek right before play
      } else {
        player.once('beforePlay', () => {
          player.seek(autoPlay.startAt);
        });
      }
    } else {
      const handlePlaylistItemEvent = (ev: jwplayer.PlaylistItemParam) => {
        if (targetIndex !== ev.index) { return; }

        // Triggering play makes jwplayer buffer and trigger beforePlay
        player.play();

        // Seeking can't be performed right away, so an event listener is used to seek right before play occurs
        player.once('beforePlay', () => { player.seek(autoPlay.startAt); });

        // Unload this event listener function as it's served its purpose
        player.off('playlistItem', handlePlaylistItemEvent);
      };

      player.playlistItem(targetIndex);

      // Hook up an event listening for the playlistItem event
      // This event may fire for any playlist item so it's important we use .on not .once
      player.on('playlistItem', handlePlaylistItemEvent);
    }
  }, [playerMounted, autoPlay, playerState?.player]);

  const {
    videosQuery,
    playlistsQuery,
  } = useReflectionPlaylist({ reflectionId });

  const activeVideo = (() => {
    if (!activeVideoId) { return; }
    if (!videosQuery.isSuccess) { return; }

    const videos = videosQuery.data.data;

    return videos.find((v) => v.id === activeVideoId);
  })();

  const activeChannel = activeVideo && getVideoChannel(activeVideo);
  const aspectratio = activeChannel?.aspect_ratio || defaultJwplayerConfig.aspectratio;

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    playerState?.player?.setConfig({ aspectratio });
  }, [aspectratio, playerState?.player]);

  const playlist = useMemo(() => {
    if (playlistsQuery.status !== 'success') { return []; }

    const playlists = playlistsQuery.data.data;

    return playlists.map((pl) => ({
      ...pl,
      title: null,
      tracks: pl.tracks.map((plt) => {
        switch (plt.kind) {
          case 'captions': return {
            ...plt,
            label: plt.label ?? __('Comments'),
          };

          default: return plt;
        }
      }),
    }));
  }, [playlistsQuery.data?.data, playlistsQuery.status]);

  useEffect(() => {
    // clear error if playlist changes, this will cause jwplayer to remount
    setError(false);
  }, [playlist]);

  switch (playlistsQuery.status) {
    case 'loading':
    case 'idle': {
      return (
        <div className="tw-rounded tw-shadow tw-overflow-hidden">
          <div className="tw-aspect-w-16 tw-aspect-h-9 tw-bg-black">
            <div className="tw-grid tw-place-items-center">
              <Spinner color="light" />
            </div>
          </div>
        </div>
      );
    }

    case 'success': {
      if (error) {
        return (
          <div className="tw-rounded tw-shadow tw-overflow-hidden">
            <div className="tw-aspect-w-16 tw-aspect-h-9 tw-bg-black tw-text-white">
              <div className="tw-grid tw-place-items-center tw-text-xl">
                {__('No media ready to stream')}
              </div>
            </div>
          </div>
        );
      }

      return (
        <div>
          {activeVideo ? (
            <DualVideoStatusAlert video={activeVideo} />
          ) : null}

          <div className="tw-rounded tw-shadow tw-overflow-hidden">
            <div className={`${playerMounted ? '' : 'tw-aspect-w-16 tw-aspect-h-9'} tw-bg-black`}>
              <JwPlayer
                configuration={defaultJwplayerConfig}
                playlist={playlist as unknown as JwPlayerPlaylistItem[]}
              />
              <JwPlayerAudioChannels />
            </div>
          </div>
        </div>
      );
    }

    case 'error':
    default: {
      return (
        <div className="tw-rounded tw-shadow tw-overflow-hidden">
          <div className="tw-aspect-w-16 tw-aspect-h-9 tw-bg-black tw-text-white">
            <div className="tw-grid tw-place-items-center">
              {__('Oops! Something went wrong.')}
            </div>
          </div>
        </div>
      );
    }
  }
};

export default ReflectionPlayer;
