import type { StateCreator } from 'zustand';
import { VideoID } from '../../../../common/types/Atlas';

export enum ReflectionModal {
  Sharing,
  Embed,
  Privacy,
  Details,
  EditVideo,
}

type ModalState = {
  modal: Exclude<ReflectionModal, ReflectionModal.EditVideo>;
} | {
  modal: ReflectionModal.EditVideo;
  videoId: VideoID;
};

export type ReflectionModalSlice = {
  modalState: ModalState | null;
  changeModal: (modalState: ModalState | null) => void;
};

export const createReflectionModalSlice: StateCreator<
  ReflectionModalSlice
> = (set) => ({
  modalState: null,
  changeModal: (modalState) => set({ modalState }),
});
