import React, { useMemo, useState } from 'react';
import useFindGroupPagesQuery from '../../../common/hooks/api/groups/useFindGroupPagesQuery';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../common/types/Atlas';
import { getUsersGroupMemberRole, userHasRole } from '../../../common/utils/group-permissions';
import NewHeadingButton from './buttons/NewHeadingButton';
import GroupMenuItem from './group-menu-item';
import GroupMenuHeading from './group-menu-item/GroupMenuHeading';
import SortablePageList from './SortablePageList';
import { groupByHeadings } from './utils';
import RouterPrompt from '../../../common/components/RouterPrompt';

const GroupSidebar: React.FC<{
  group: Atlas.Group;
  previewMode?: boolean;
  disableSort?: boolean;
}> = (props) => {
  const { group, previewMode, disableSort } = props;

  const [sorting, setSorting] = useState(false);

  const toggleSort = () => {
    setSorting((s) => !s);
  };

  const userQuery = useCurrentUserQuery();
  const canManagePages = (() => {
    if (previewMode) { return false; }
    if (!group.group) { return false; }
    if (userQuery.status !== 'success') { return false; }

    const user = userQuery.data;
    if (user.admin === Atlas.AdministratorType.Super) { return true; }

    const userRole = getUsersGroupMemberRole(group.group, user.id, user.organization_id);
    if (group.template_id) { return false; }

    return userHasRole(userRole, group.group.pages_cud_role);
  })();

  const pagesQuery = useFindGroupPagesQuery({ groupId: group.id, per_page: 1000, admin: true });
  const allPages = pagesQuery.data?.data ?? [];

  const filteredPages = useMemo(() => {
    if (previewMode) {
      // Show public and published pages
      return allPages.filter((page) => [
        Atlas.PublishStatus.Public, Atlas.PublishStatus.Published
      ].includes(page.publish));
    }

    if (canManagePages) {
      // Show all pages
      return allPages;
    }

    // Show published pages, hide marketing pages
    return allPages.filter((page) => page.publish === Atlas.PublishStatus.Published && page.type !== Atlas.PageType.Marketing);
  }, [canManagePages, allPages, previewMode]);

  const sortedPages = useMemo(() => (
    [...filteredPages].sort((a, b) => a.page_no - b.page_no)
  ), [filteredPages]);

  const groupedPages = groupByHeadings(sortedPages);

  return (
    <div>
      <RouterPrompt
        when={sorting}
        message={__('You have have not saved the page sort order. Leaving this page will discard these changes.')}
      />

      <div className="tw-rounded-2xl tw-bg-white tw-px-4 tw-py-2 tw-mb-4">
        <p className="tw-font-semibold tw-text-2xl tw-text-primary tw-text-center">
          {group.name}
        </p>
      </div>

      <div className="tw-rounded-2xl tw-bg-white tw-border-b tw-border-gray-100 last:tw-border-0">
        <div className="tw-px-4 tw-py-2 tw-flex tw-items-center tw-justify-between tw-font-medium">
          <i className="fa fa-files-o fa-fw" />

          <span className="tw-font-medium tw-text-lg">
            {__('Pages')}
          </span>

          <i className="fa fa-fw tw-invisible" />
        </div>

        <div className="tw-pt-1 tw-pb-4">
          {(() => {
            switch (pagesQuery.status) {
              case 'loading': return (
                <div className="tw-flex tw-justify-center tw-py-5 tw-px-2 tw-text-2xl">
                  <i className="fa fa-circle-o-notch fa-spin" />
                </div>
              );
              case 'error': return (
                <div className="tw-px-2">
                  <div className="tw-alert tw-alert-error">
                    {__('Oops! Something went wrong.')}
                  </div>
                </div>
              );
              default: return null;
            }
          })()}

          <ol className="tw-space-y-1">
            {sorting ? (
              <SortablePageList groupId={group.id} pages={sortedPages} />
            ) : groupedPages.map(({ heading, pages }, index) => {
              if (heading) {
                return (
                  <li key={heading.id}>
                    <GroupMenuHeading
                      page={heading}
                      editable={canManagePages && !previewMode}
                    />

                    <ol>
                      {pages.map((page) => (
                        <li key={page.id}>
                          <GroupMenuItem
                            page={page}
                            editable={canManagePages}
                            locked={previewMode}
                            showDisc
                          />
                        </li>
                      ))}
                    </ol>
                  </li>
                );
              }

              return (
                <React.Fragment key={index}>
                  {pages.map((page) => (
                    <li key={page.id}>
                      <GroupMenuItem
                        page={page}
                        editable={canManagePages}
                        locked={previewMode}
                      />
                    </li>
                  ))}
                </React.Fragment>
              );
            })}
          </ol>

          {(() => {
            if (!canManagePages) { return; }

            if (sorting) {
              return (
                <>
                  <hr className="tw-my-4" />

                  <div className="tw-px-4 tw-mt-4">
                    <button
                      type="button"
                      className="tw-btn tw-btn-sm tw-btn-primary tw-btn-block"
                      onClick={toggleSort}
                      data-testid="group-sort-button"
                    >
                      {__('Done')}
                    </button>
                  </div>
                </>
              );
            }

            return (
              <div className="tw-flex tw-gap-1 tw-px-4 tw-mt-4">
                <div className="tw-dropdown tw-dropdown-hover tw-w-full">
                  <button
                    type="button"
                    className="tw-btn tw-btn-sm tw-gap-2 tw-w-full"
                    data-testid="group-new-button"
                  >
                    <i className="fa fa-plus tw-text-xs" />
                    <span className="group-hover:tw-underline">
                      {__('New')}
                    </span>
                  </button>

                  <ul tabIndex={0} className="tw-dropdown-content tw-z-50 tw-menu tw-p-2 p-_ tw-shadow tw-bg-base-100 tw-rounded-box tw-w-40">
                    <li>
                      <a className="new-page" href={`/groups/${group.id}/pages/new`} data-testid="group-new-page-button">
                        {__('New Page')}
                      </a>
                    </li>
                    <li>
                      <NewHeadingButton groupId={group.id} />
                    </li>
                  </ul>
                </div>

                {disableSort ? null : (
                  <button
                    type="button"
                    className="tw-btn tw-btn-sm tw-gap-2"
                    onClick={toggleSort}
                    data-testid="group-sort-button"
                  >
                    <i className="fa fa-sort" />
                    {__('Sort')}
                  </button>
                )}
              </div>
            );
          })()}
        </div>
      </div>
    </div>
  );
};

export default GroupSidebar;
