import React from 'react';
import { useParams } from 'react-router-dom';
import ReflectionShowLayout from '../../../modules/reflections/show/ReflectionLayout';

const ReflectionLayout = (): JSX.Element => {
  const params = useParams<{ reflectionId: string; }>();
  const reflectionId = Number(params.reflectionId);

  return (
    <ReflectionShowLayout reflectionId={reflectionId} />
  );
};

export default ReflectionLayout;
