import { useQuery, UseQueryResult } from 'react-query';
import { findVideo, FindVideoParams, FindVideoResponse } from '../../../api/videos/[videoId]';

const useVideoQuery = (
  params: FindVideoParams,
): UseQueryResult<FindVideoResponse> => {
  const { videoId } = params;

  return useQuery(
    ['videos', videoId],
    () => findVideo({ params }),
  );
};

export default useVideoQuery;
