import React, { useState } from 'react';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from '../../common/components/BSDropdown';
import classNames from 'classnames';
import FormCounterAnswer from './form-answers/FormCounterAnswer';
import FormMultipleChoiceAnswer from './form-answers/FormMultipleChoiceAnswer';
import FormRatingAnswer from './form-answers/FormRatingAnswer';
import FormTimerAnswer from './form-answers/FormTimerAnswer';
import TimelineSegments from './TimelineSegments';
import * as Atlas from '../../common/types/Atlas';
import { portraitPlaceholderUrl } from '../../common/utils/placeholders';
import Markdown from '../../common/components/Markdown';

const elementMap = {
  counter: {
    component: FormCounterAnswer,
    props: { timeLinkDisabled: true },
  },
  multiple_choice: {
    component: FormMultipleChoiceAnswer,
  },
  rating: {
    component: FormRatingAnswer,
  },
  timer: {
    component: FormTimerAnswer,
    props: { timeLinkDisabled: true },
  },
};

interface FormAnswerProps {
  formElement: Atlas.FormElement;
  formAnswer: Atlas.FormAnswer;
  user?: Atlas.User | Atlas.FormUser;
  aiGenerated?: boolean;
  onView?: () => void;
  onEdit?: () => void;
  onDelete?(): void;
  className?: string;
}

const FormAnswer = (props: FormAnswerProps): JSX.Element => {
  const { user, formElement, formAnswer, aiGenerated, onView, onEdit, onDelete } = props;
  const type = Object.keys(formElement.field.type)[0];

  const AnswerComponent = (() => {
    switch (type) {
      case 'counter':
      case 'multiple_choice':
      case 'rating':
      case 'timer':
        break;
      default: return null;
    }

    const {
      component: Answer,
    } = elementMap[type];

    return Answer;
  })();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setDropdownOpen((s) => !s);
  };

  const timeline = formAnswer.timeline || [];

  const name = user ? [user.first_name, user.last_name].join(' ') : '';

  const avatarUrl = (() => {
    if (!user) { return undefined; }

    if ('avatar' in user) {
      return user.avatar.viewable_s3uri?.url;
    }

    return user.avatar_s3uri?.url;
  })() || portraitPlaceholderUrl;

  const notes = (formAnswer.notes || (type === 'text' && formAnswer.value) || '') as string;

  return (
    <div className={classNames('tw-group tw-flex tw-gap-3 tw-items-start', props.className)}>
      <div className="tw-w-full tw-border-t tw-border-base-400">
        <div className="tw-bg-base-200 tw-p-2 tw-flex tw-gap-2 tw-items-center">
          <div className="tw-flex-shrink-0 tw-w-10">
            <div className="tw-avatar">
              <div className="tw-rounded-full tw-w-10 tw-shadow-sm">
                <img alt={__('User avatar')} src={avatarUrl} />
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col">
            <div className="tw-font-bold">
              {name}
            </div>
            {aiGenerated ? (
              <div className="tw-text-xs tw-text-[#5d7ca2]">
                <i className="fa fa-magic tw-mr-1" />
                {__('Generated by AI')}
              </div>
            ) : null}
          </div>
          <div className="tw-flex-1"></div>
          {onView || onEdit || onDelete ? (
            <Dropdown isOpen={dropdownOpen} toggle={handleDropdownClick}>
              <DropdownToggle className="tw-btn tw-btn-ghost tw-btn-sm tw-opacity-20 group-hover:tw-opacity-100">
                <i className="fa fa-ellipsis-v" />
              </DropdownToggle>

              <DropdownMenu right>
                {onView ? (
                  <DropdownItem onClick={onView}>
                    {__('View')}
                  </DropdownItem>
                ) : null}

                {onEdit ? (
                  <DropdownItem onClick={onEdit}>
                    {__('Edit')}
                  </DropdownItem>
                ) : null}

                {onDelete ? (
                  <DropdownItem className="tw-text-error" onClick={onDelete}>
                    {__('Delete')}
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </Dropdown>
          ) : null}
        </div>

        <div className="tw-bg-base-100 tw-py-3 tw-px-4 tw-flex tw-flex-col tw-gap-1 tw-overflow-hidden">

          {AnswerComponent ? (
            <AnswerComponent {...props} />
          ) : null}

          {notes ? (
            <div className="markdown-content-div">
              <Markdown>
                {notes}
              </Markdown>
            </div>
          ) : null}

          {timeline.length ? (
            <TimelineSegments timeline={timeline} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FormAnswer;
