import { useMutation, UseMutationResult } from 'react-query';
import { prepareReflection, PrepareReflectionArguments, PrepareReflectionResponse } from '../../../api/reflections/[reflectionId]/prepare';
import { queryClient } from '../../withQueryClient';

const usePrepareReflectionMutation = (): UseMutationResult<
PrepareReflectionResponse, unknown, PrepareReflectionArguments, unknown
> => {
  const mutation = useMutation(prepareReflection, {
    onSuccess: async (result) => {
      const reflection = result.data;

      await queryClient.invalidateQueries(['reflections', reflection.id]);
    },
  });

  return mutation;
};

export default usePrepareReflectionMutation;
