import { ReflectionID, Video, VideoID } from '../../types/Atlas';
import { generateFetchHeaders, searchParams } from '../routes';

export const splitVideoRoute = ({ videoId }: { videoId: VideoID }): string => `/videos/${videoId}/split`;
export const swapDualPartsVideoRoute = ({ videoId }: { videoId: VideoID }): string => `/videos/${videoId}/swap_dual_parts`;

interface ErrorMessage {
  message?: string;
}

interface SplitVideoArgs {
  params: {
    videoId: VideoID;
    reflectionId: ReflectionID;
    direction: 'left' | 'right';
  };
}

export const splitVideo = (args: SplitVideoArgs): Promise<Video> => {
  const { params } = args;

  const splitVideoUrl = [
    splitVideoRoute({ videoId: params.videoId }),
    searchParams({
      reflection_id: params.reflectionId,
      direction: params.direction,
    })
  ].join('?');

  return fetch(splitVideoUrl, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders()
  }).then(async r => {
    if (r.status !== 200) {
      const json = (await r.json()) as ErrorMessage;
      throw (json.message || `[${r.status}] splitVideo failed`);
    }

    return await r.json();
  });
};


interface SwapDualPartsVideoArgs {
  params: {
    videoId: VideoID;
    reflectionId: ReflectionID;
  };
}

export const swapDualPartsVideo = (args: SwapDualPartsVideoArgs): Promise<Video> => {
  const { params } = args;

  const swapDualPartsVideoUrl = [
    swapDualPartsVideoRoute({ videoId: params.videoId }),
    searchParams({ reflection_id: params.reflectionId })
  ].join('?');

  return fetch(swapDualPartsVideoUrl, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders()
  }).then(async r => {
    if (r.status !== 200) {
      const json = (await r.json()) as ErrorMessage;
      throw (json.message || `[${r.status}] swapDualPartsVideo failed`);
    }

    return await r.json();
  });
};
