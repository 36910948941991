import React from 'react';
import { truncate, interpolate } from '../../common/utils/utils';
import * as Atlas from '../../common/types/Atlas';
import Spinner from '../../common/components/Spinner';

interface ReflectionAttachedFormsProps {
  forms?: Atlas.Form[];
  loading?: boolean;
  onAddFormClick: () => void;
  onFormClick: (form: Atlas.Form) => void;
}

const ReflectionAttachedForms = (props: ReflectionAttachedFormsProps) => {
  const {
    forms = [], loading, onAddFormClick, onFormClick,
  } = props;

  const handleAddFormsClick = () => {
    onAddFormClick();
  };

  const handleClick = (form: Atlas.Form) => () => {
    onFormClick(form);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 xs:tw-gap-4 tw-h-full">
      <div className="tw-grow tw-overflow-scroll tw-h-0 tw-rounded-xl tw-bg-base-300 tw-border tw-border-base-400 tw-shadow-inner tw-p-2 xs:tw-p-4 tw-min-h-[130px]">
        <ul className="tw-space-y-3">
          {!loading && forms.length === 0 ? (
            <li className="tw-text-center py-4">
              <div className="tw-text-gray-600 tw-mb-6">
                {__('There are no Insights for this reflection.')}
              </div>

              <button type="button" className="tw-btn tw-btn-success tw-shadow tw-btn-sm" onClick={handleAddFormsClick}>
                {__('Add Insight')}
                &nbsp;
                <i className="icon-plus" />
              </button>
            </li>
          ) : null}

          {forms.map((form) => {

            const visibleElements = form.elements.filter(fe => fe.field.visible !== false);

            return (
            <li key={form.id}>
              <button
                type="button"
                className="tw-btn tw-btn-white tw-btn-block tw-shadow tw-px-5 tw-py-4 tw-gap-5 tw-h-auto tw-items-start"
                onClick={handleClick(form)}
              >
                <div className="tw-flex-1 tw-text-left tw-text-base tw-font-normal">
                  <div className="tw-font-bold">
                    {form.name}
                  </div>
                  {form.instructions ? (
                    <div className="tw-mt-2 tw-text-sm">
                      {truncate(form.instructions, { maxLength: 160 })}
                    </div>
                  ) : null}
                </div>

                <div className="tw-space-y-1">
                  <div className="tw-badge tw-badge-sm tw-badge-info tw-flex tw-w-full">
                    {interpolate(n__('%n Question', '%n Questions', visibleElements.length), visibleElements.length)}
                  </div>

                  {form.has_data ? (
                    <div className="tw-badge tw-badge-sm tw-badge-success tw-flex tw-w-full">
                      <i className="fa fa-check" />
                      &nbsp;
                      {__('Answered')}
                    </div>
                  ) : null}
                </div>
              </button>
            </li>
          );
        })}
        </ul>

        {loading ? (
          <div className="tw-text-center">
            <Spinner color="info" />
          </div>
        ) : null}
      </div>

      <div className="tw-text-right">
        <button type="button" className="tw-btn tw-btn-success tw-shadow tw-btn-sm" onClick={handleAddFormsClick}>
          {__('Add Insight')}
          &nbsp;
          <i className="icon-plus" />
        </button>
      </div>
    </div>
  );
};

export default ReflectionAttachedForms;
