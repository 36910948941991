/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const alertVariant = cva('alert', {
  variants: {
    color: {
      primary: 'alert-primary',
      secondary: 'alert-secondary',
      success: 'alert-success',
      danger: 'alert-danger',
      warning: 'alert-warning',
      info: 'alert-info',
      light: 'alert-light',
      dark: 'alert-dark',
    },
  },
  defaultVariants: {
    color: 'success',
  },
});

export type AlertVariantProps = VariantProps<typeof alertVariant>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Alert = ({
  color,
  className,
  ...props
}: AlertVariantProps & DivProps) => (
  <div
    {...props}
    className={cx(className, alertVariant({
      color,
    }))}
  />
);
