import React from 'react';
import { AttachmentID } from '../../../common/types/Atlas';
import AttachmentName from '../../../common/components/AttachmentName';

const AttachmentList: React.FC<{
  attachmentIds: AttachmentID[];
  onRemoveClick: (attachmentId: AttachmentID) => void;
}> = (props) => {
  const { attachmentIds, onRemoveClick } = props;

  return (
    <ul className="tw-space-y-2">
      {attachmentIds.map((id) => (
        <li key={id} className="tw-flex tw-gap-2 tw-px-2 tw-py-1 tw-items-center tw-justify-between tw-rounded-lg tw-border">
          <AttachmentName attachmentId={id} className="tw-truncate" />

          <button type="button" className="tw-btn tw-btn-sm" onClick={() => onRemoveClick(id)}>
            {__('Remove')}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default AttachmentList;
