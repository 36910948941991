import { useMutation, UseMutationResult } from 'react-query';
import { destroyAssignmentSubmssion, DestroyAssignmentSubmissionArgs } from '../../../api/assignments/assignment-submissions';
import { queryClient } from '../../withQueryClient';
import * as Atlas from '../../../types/Atlas';

type UseDestroyAssignmentSubmissionMutationResult =
  UseMutationResult<{ data: Atlas.AssignmentSubmission }, unknown, DestroyAssignmentSubmissionArgs>;

const useDestroyAssignmentSubmissionMutation = (
  assignmentId: Atlas.AssignmentID,
): UseDestroyAssignmentSubmissionMutationResult => {
  const mutation = useMutation(destroyAssignmentSubmssion, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assignments', assignmentId, 'submissions']);
    }
  });

  return mutation;
};

export default useDestroyAssignmentSubmissionMutation;