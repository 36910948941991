import React from 'react';
import useCurrentUserQuery from '../../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../../common/types/Atlas';
import { portraitPlaceholderUrl } from '../../../../common/utils/placeholders';
import { SearchResultLink } from './list-item';

const labelForAdminType = (type: Atlas.AdministratorType) => {
  switch (type) {
    case Atlas.AdministratorType.Super: {
      return __('Super Admin');
    }

    case Atlas.AdministratorType.Uber: {
      return __('Uber Admin');
    }

    case Atlas.AdministratorType.Organization: {
      return __('Organization Admin');
    }

    case Atlas.AdministratorType.None: {
      return __('Standard User');
    }

    default: return null;
  }
};

const labelForDeleteState = (deleteState: Atlas.DeleteState) => {
  switch (deleteState) {
    case Atlas.DeleteState.Suspended: {
      return __('Deactivated');
    }

    case Atlas.DeleteState.Trashed: {
      return __('Trashed');
    }

    default: return null;
  }
};

interface SearchUserResultProps {
  user: Atlas.SearchUser;
}

const SearchUserResult = (props: SearchUserResultProps) => {
  const { user } = props;

  const currentUserQuery = useCurrentUserQuery();

  const isSuperAdmin = currentUserQuery.data?.admin === Atlas.AdministratorType.Super;
  const isUberAdmin = currentUserQuery.data?.admin === Atlas.AdministratorType.Uber;
  const isOrgAdmin = currentUserQuery.data?.admin === Atlas.AdministratorType.Organization;

  const avatarSrc = user.avatar_s3uri?.url || portraitPlaceholderUrl;

  return (
    <SearchResultLink href={`/admin/organizations/${user.organization.id}/users/${user.id}/edit`}>
      <div className="tw-avatar">
        <div className="tw-rounded-xl tw-border tw-w-10">
          <img src={avatarSrc} alt={__('User avatar')} />
        </div>
      </div>

      <div className="tw-flex-1 tw-flex">
        <div className="tw-flex-1 tw-flex tw-flex-col">
          <div className="tw-text-sm tw-opacity-60">
            {__('User')}
            {isSuperAdmin ? (
              ` #${user.id}`
            ) : null}
          </div>
          <div>
            {`${user.first_name} ${user.last_name}`}
          </div>
          <div className="tw-flex tw-items-center tw-gap-1">
            <i className="fa fa-institution tw-text-xs" />
            <span className="tw-text-sm">
              {user.organization.name}
            </span>
          </div>
        </div>

        {isSuperAdmin || isUberAdmin || isOrgAdmin ? (
          <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end tw-justify-center">
            {user.admin !== Atlas.AdministratorType.None ? (
              <div className="tw-badge tw-text-xs">
                {labelForAdminType(user.admin)}
              </div>
            ) : null}
            {user.delete_state ? (
              <div className="tw-badge tw-badge-warning tw-text-xs">
                {labelForDeleteState(user.delete_state)}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </SearchResultLink>
  );
};

export default SearchUserResult;
