import React, { useState } from 'react';
import { Modal, ModalBox, ModalHeader } from './TailwindModal';

interface DeletePromptProps {
  showPrompt: boolean;
  prompt?: string;
  typeToConfirm?: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeletePrompt = (props: DeletePromptProps): JSX.Element => {
  const { showPrompt, prompt, typeToConfirm, onClose, onDelete } = props;

  const [confirmationText, setConfirmationText] = useState('');

  const isOkDisabled = typeToConfirm && confirmationText !== "delete";

  const handleDeleteConfirmed = () => {
    setConfirmationText('');
    onDelete();
  }

  const handleClose = () => {
    setConfirmationText('');
    onClose();
  }

  return (
    <>
      <Modal isOpen={showPrompt} onClose={handleClose}>
        <ModalBox className="tw-max-w-lg">
          <ModalHeader onClose={handleClose}>
            {__("Are you sure you want to delete this?")}
          </ModalHeader>
            <div className="tw-text-base">
              {prompt && (
                <p className="tw-mb-3">
                  {__(prompt)}
                </p>
              )}
              
              {typeToConfirm && (
                <div className="tw-mb-6">
                  <p className="tw-mb-3">
                    To proceed with deletion, type 'delete' in the box blow and press 'Ok'.
                  </p>
                  <input
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                    className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
                  />
                </div>
              )}

              <div className="tw-flex tw-justify-between tw-mt-3">
                <button 
                  className={`tw-btn tw-btn-md tw-btn-warning disabled:tw-opacity-75 disabled:tw-bg-warning`}
                  disabled={isOkDisabled}
                  onClick={handleDeleteConfirmed}>
                    {__("Ok")}
                </button>
                <button 
                  className="tw-btn tw-btn-md tw-btn-success"
                  onClick={handleClose}>
                  {__("Cancel")}
                </button>
              </div>

            </div>
        </ModalBox>
      </Modal>
    </>
  )
}

export default DeletePrompt;