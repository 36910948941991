import { useMutation, UseMutationResult } from 'react-query';
import { createReflectionShare, CreateReflectionShareArgs, CreateReflectionShareResponse } from '../../../api/reflections/shares';
import { queryClient } from '../../withQueryClient';

type UseCreateReflectionShareMutationResult =
  UseMutationResult<CreateReflectionShareResponse, unknown, CreateReflectionShareArgs, unknown>;

const useCreateReflectionShareMutation = (): UseCreateReflectionShareMutationResult => {
  const mutation = useMutation(createReflectionShare, {
    onSuccess: async (result) => {
      const share = result.data;

      if (share.resource && 'reflections' in share.resource) {
        await queryClient.invalidateQueries(['reflections', share.resource.reflections, 'shares']);
      }
    },
  });

  return mutation;
};

export default useCreateReflectionShareMutation;
