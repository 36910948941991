import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject } from '../../../api-parser';
import ApiError from '../../../error';
import { generateFetchHeaders } from '../../../routes';

export const groupImagesRoute = (args: {
  groupId: Atlas.GroupID,
}): string => `/api/v0/groups/${args.groupId}/images`;

export interface CreateGroupImageParams {
  groupId: Atlas.GroupID;
}

export interface CreateGroupImageBody {
  type: Atlas.ImageType.Embedded | Atlas.ImageType.GroupBanner | Atlas.ImageType.GroupLogo;
}

export interface CreateGroupImageArguments {
  params: CreateGroupImageParams;
  body: CreateGroupImageBody;
}

export interface CreateGroupImageResponse {
  data: Atlas.Image;
  metadata: Atlas.AtlasMetadata;
}

export const createGroupImage = async (
  args: CreateGroupImageArguments,
): Promise<CreateGroupImageResponse> => {
  const { groupId } = args.params;

  const url = groupImagesRoute({ groupId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      type: args.body.type,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'createGroupImage');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Image>(body.data);
  const metadata = body.metadata as Atlas.AtlasMetadata;

  return { data, metadata };
};
