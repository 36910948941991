import React, { useEffect, useState } from 'react';
import * as Atlas from '../../../../common/types/Atlas';

interface PageTitleFormProps {
  initialTitle?: Atlas.PageTitle;
  pageType: Atlas.PageType;
  onSave: (changes: Pick<Atlas.Page, 'title'>) => Promise<void>;
  onCancel: () => void;
}

const PageTitleForm = (props: PageTitleFormProps) => {
  const {
    initialTitle,
    pageType,
    onSave,
    onCancel,
  } = props;

  const [pageTitle, setPageTitle] = useState(initialTitle ?? '');
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    if (typeof initialTitle !== 'string') { return; }

    setPageTitle(initialTitle);
  }, [initialTitle]);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (isSaving) { return; }

    setSaving(true);
    void onSave({ title: pageTitle }).finally(() => {
      setSaving(false);
    });
  };

  const handlePageTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSaving) { return; }

    const { value } = event.currentTarget;

    setPageTitle(value);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="tw-form-control tw-w-full tw-mb-4">
        <input
          autoFocus
          className="tw-input tw-input-bordered tw-w-full"
          placeholder={(() => {
            switch (pageType) {
              case Atlas.PageType.Heading: {
                return __('Enter the new heading title');
              }
              default: {
                return __('Enter the new page title');
              }
            }
          })()}
          required
          value={pageTitle}
          onChange={handlePageTitleChange}
          data-testid="page-title-input"
        />
      </div>

      <div className="tw-flex tw-justify-end tw-gap-2">
        <button
          type="button"
          className="tw-btn tw-btn-link"
          onClick={onCancel}
        >
          {__('Cancel')}
        </button>

        <button
          type="submit"
          className="tw-btn tw-btn-success"
          data-testid="page-title-save-button"
        >
          {isSaving ? <span className="tw-loading" /> : null}
          {__('Save')}
        </button>
      </div>
    </form>
  );
};

export default PageTitleForm;
