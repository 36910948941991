export interface HermesMetadata {
  upload_uri: string;
  address: string;
  rest_uri: string;
}

export interface AtlasMetadata {
  readonly hermes: HermesMetadata;
}

export interface Metadata extends AtlasMetadata {
  readonly current_page: number;
  readonly total_pages: number;
  readonly per_page: number;
  readonly total_entries: number;
}

export enum DeleteState {
  Suspended = 'suspended',
  Trashed = 'trashed',
}

export type CreatedAt = Date;
export type UpdatedAt = Date;

export interface Model {
  readonly delete_state?: DeleteState;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type AvatarVersion = number;
export type UploadFilename = string;
export type AvatarMagicString = string;

export enum AvatarType {
  Default = 'default',
  UserV0 = 'user_v0',
  OrganizationV0 = 'organization_v0',
  UserV1 = 'user_v1',
  OrganizationV1 = 'organization_v1',
  GroupV1 = 'group_v1',
}

export interface Avatar {
  type: AvatarType;
  media_key?: AvatarMasterKey;
  master_key?: AvatarMasterKey;
  current_version: AvatarVersion;
  history: AvatarMasterKey[];
  master_s3uri?: S3URI;
  viewable_s3uri?: S3URI;
  filename?: UploadFilename;
}

export type OpenAIModelName = string;

export interface OpenAIModelParameters {
  name: OpenAIModelName;
  parameters: object;
}

export type AIModelID = number;
export type AIModelName = string;
export type AIModelDescription = string;

export type AIModelParameters = {
  open_ai: OpenAIModelParameters;
};

export interface AIModel {
  id: AIModelID;
  name: AIModelName;
  description: AIModelDescription;
  parameters: AIModelParameters;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
  delete_state?: DeleteState;
}

export type EmailAddress = string;
export type E164 = string;
export type Password = string;
export type PasswordDigest = string;

export interface User extends Model {
  id: UserID;
  organization_id: OrganizationID;
  organization_name?: OrganizationName;
  username: Username;
  first_name: FirstName;
  last_name: LastName;
  email?: EmailAddress;
  phone?: E164;
  kind?: string;
  interests: string[];
  setup_password?: Password;
  password?: PasswordDigest;
  avatar: Avatar;
  settings?: Settings;
  admin: AdministratorType;
}

export interface MyUser {
  id: UserID;
  organization_id: OrganizationID;
  organization_name: OrganizationName;
  username: Username;
  first_name: FirstName;
  last_name: LastName;
  email?: EmailAddress;
  phone?: E164;
  kind?: string;
  interests: string[];
  setup_password?: Password;
  password?: PasswordDigest;
  settings?: Settings;
  avatar: Avatar;
  admin: AdministratorType;
  is_basic: boolean;
  delete_state?: DeleteState;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export enum LearningType {
  Learner = 'learner',
  Teacher = 'teacher',
}

export enum Acceptance {
  Outstanding = 'outstanding',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface UserInGroup {
  id: UserID;
  organization_id: OrganizationID;
  username: Username;
  first_name: FirstName;
  last_name: LastName;
  avatar: Avatar;
  admin: AdministratorType;
  learning_type: LearningType;
  validated: boolean;
  read_status?: Acceptance;
  is_admin: boolean;
  is_visible_member: boolean;
  is_subscriber: boolean;
  is_writer: boolean;
  is_assessor: boolean;
  is_basic: boolean;
}

export type OrganizationName = string;
export type Username = string;

export enum AdministratorType {
  None = 'none',
  Organization = 'organization',
  Uber = 'uber',
  Super = 'super',
}

export interface CondensedUser extends Model {
  id: UserID;
  organization_id: OrganizationID;
  organization_name: OrganizationName;
  username: Username;
  first_name: FirstName;
  last_name: LastName;
  avatar: Avatar;
  admin: AdministratorType;
  validated: boolean;
}

export interface DiscussionMentionUser {
  id: UserID;
  first_name: FirstName;
  last_name: LastName;
  avatar: Avatar;
}

export enum DownloadSetting {
  Enabled = 'enabled',
  PermissionRequired = 'permission_required',
  Disabled = 'disabled',
}

export interface Settings {
  download?: DownloadSetting;
  [field: string]: unknown; // TODO typings
}

export type AssessmentID = number;

export type ShareID = number;
export type Message = string;
export type TemplateID = number;

export type MediaID = {
  attachments: AttachmentID;
} | {
  forms: FormID;
} | {
  reflections: ReflectionID;
} | {
  templates: TemplateID;
};

export interface Share {
  id: ShareID;
  resource?: MediaID;
  readonly media_user_id: UserID;
  readonly media_organization_id: OrganizationID;
  readonly delete_state?: DeleteState;
  sharee_user_id?: UserID;
  sharee_group_id?: GroupID;
  sharee_assignment_id?: AssignmentID;
  sharee_submission_id?: AssignmentSubmissionID;
  sharee_assessment_id?: AssessmentID;
  message: Message;
  replicated_from?: ShareID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_date: UpdatedAt;
}

export interface ExpandedShare {
  id: ShareID;
  resource?: MediaID;
  readonly media_user_id: UserID;
  readonly media_organization_id: OrganizationID;
  readonly delete_state?: DeleteState;
  sharee_user_id?: UserID;
  sharee_user?: CondensedUser;
  sharee_group_id?: GroupID;
  sharee_group?: CondensedGroup;
  sharee_assignment_id?: AssignmentID;
  sharee_assignment?: CondensedAssignment;
  sharee_submission_id?: AssignmentSubmissionID;
  sharee_assessment_id?: AssessmentID;
  message: Message;
  replicated_from?: ShareID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

// Groups

export type ImageID = number;
export type TrustNetworkID = number;

export interface GroupScope {
  group_ids: GroupID[];
  user_ids: UserID[];
}

export type PageID = number;
export type OrganizationTypeID = number;

export type GroupName = string;
export type GroupDescription = string;

export type UsersCount = number;
export type DevicesCount = number;

export enum DistanceLearningFlag {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

export type URN = number;
export type LTIOrganizationKey = string;

export enum ProgrammeTermsReply {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface OrganizationProgrammeMembership {
  programme_id: ProgrammeID;
  tos_signed_by?: UserID;
  tos_signed_at?: Date;
  tos_reply?: ProgrammeTermsReply;
}

export type SSOIntegrationID = number;

export interface OrganizationInfo {
  readers: GroupScope;
  permitted_shares: ShareID[];
  auth_extend_readers: boolean;
  type?: OrganizationTypeID;
  settings?: Settings;
  podio_id?: string;
  distance_learning_flag: DistanceLearningFlag;
  pure: boolean;
  org_group_id?: GroupID;
  visible_users: boolean;
  is_ccot_enabled: boolean;
  urn?: URN;
  lti_organization_key?: LTIOrganizationKey;
  programme_memberships: OrganizationProgrammeMembership[];
  sso_integrations: SSOIntegrationID[];
}

export type UserTag = string;
export type Tag = string;
export interface PrivateTags {
  user_id: UserID;
  tags: Tag[];
}

export interface Tags {
  public: Tag[];
  private: PrivateTags[];
}

export enum TagsAccess {
  Owner = 'owner',
  All = 'all',
}

export enum OrgKind { } // TODO typings

export enum NotifyFlag {
  None = 'none',
  All = 'all',
  Members = 'members',
}

export type MetricsEnabledAt = Date;
export type SubscriptionStartsAt = Date;
export type SubscriptionExpiresAt = Date;
export type InstalledAt = Date;
export type RetiredAt = Date;
export type DatabridgedAt = Date;

export enum CustomerStatus {
  Customer = 'customer',
  OutOfLicence = 'out_of_licence',
  Trial = 'trial',
  Trial2020Stage1 = 'trial_2020_stage_1',
  Trial2020Stage2 = 'trial_2020_stage_2',
  Project = 'project',
  ExClient = 'ex_client',
}

export type OfferLabel = string;
export type Price = number;
export enum Currency {
  AUD = 'aud',
  EUR = 'eur',
  GBP = 'gbp',
  USD = 'usd',
}

export type URI = string;

export enum SalesArea { } // TODO typings

export interface Population {
  all: boolean;
  areas: SalesArea[];
  scope: OrganizationScope;
}

export enum OrganizationIndustry {
  Education = 'education',
  Financial = 'financial',
  Legal = 'legal',
  Healthcare = 'healthcare',
  Corporate = 'corporate',
  Vocational = 'vocational',
  Sports = 'sports',
  NoIndustry = 'no_industry',
}

export interface GroupOffer {
  label: OfferLabel;
  price?: Price;
  currency?: Currency;
  link?: URI;
  population: Population;
  organization_types?: OrganizationTypeID[];
  organization_industries?: OrganizationIndustry[];
  preview_enabled: boolean;
}

export interface Group {
  id: GroupID;
  name: GroupName;
  description?: GroupDescription;
  organization?: OrganizationInfo;
  group?: GroupInfo;
  tags?: Tags;
  tags_access: TagsAccess;
  user_tags: UserTag[];
  sort_key?: SortKey;
  eu_customer?: boolean;
  kind?: OrgKind;
  is_unlimited: boolean;
  notify: NotifyFlag;
  logo?: Image;
  banner?: Image;
  metrics_enabled_at?: MetricsEnabledAt;
  installed_at?: InstalledAt;
  subscription_starts_at?: SubscriptionStartsAt;
  subscription_expires_at?: SubscriptionExpiresAt;
  retired_at?: RetiredAt;
  customer_status?: CustomerStatus;
  offer?: GroupOffer;
  delete_state?: DeleteState;
  replicated_from?: GroupID;
  template_id?: TemplateID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
  databridged_at?: DatabridgedAt;
}

export interface SharableGroup {
  id: GroupID;
  name: GroupName;
  description?: GroupDescription;
  type?: GroupType;
  sort_key?: SortKey;
  avatar_s3uri?: S3URI;
}

export interface CondensedGroup {
  id: GroupID;
  name: GroupName;
  description?: GroupDescription;
  sort_key?: SortKey;
  sales_area?: SalesArea;
  type?: OrganizationTypeID;
  logo?: CondensedImage;
  is_organization: boolean;
  is_pathway: boolean;
  users_count?: UsersCount;
  devices_count?: DevicesCount;
}

export type Markdown = string;

export type PageTitle = string;
export type PageNumber = number;

export type PageBody = {
  markdown: Markdown;
};

export enum PublishStatus {
  Unpublished = 'unpublished',
  Published = 'published',
  Public = 'public',
}

export enum PageType {
  Page = 'page',
  Heading = 'heading',
  Marketing = 'marketing',
  Guide = 'guide',
  ReflectionSelect = 'reflection_select',
}

export enum JSFilter {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

export type AccessCount = number;

export interface Page {
  id: PageID;
  group_id: GroupID;
  title: PageTitle;
  body: PageBody;
  publish: PublishStatus;
  type: PageType;
  js_filter: JSFilter;
  page_no: PageNumber;
  update_role: MemberRole;
  access_count: AccessCount;
  readonly delete_state?: DeleteState;
  replicated_from?: PageID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export enum PrivacyFlag {
  Open = 'open',
  VisibleBasic = 'visible_basic',
  Visible = 'visible',
  Private = 'private',
}

export interface Population {
  all: boolean;
  areas: SalesArea[];
  scope: OrganizationScope;
}

export interface GroupOffer {
  label: OfferLabel;
  price?: Price;
  currency?: Currency;
  link?: URI;
  population: Population;
  organization_types?: OrganizationTypeID[];
  organization_industries?: OrganizationIndustry[];
  preview_enabled: boolean;
}

export enum MemberRoleStatus {
  Pending = 'pending',
  Standard = 'standard',
  Contributor = 'contributor',
  Admin = 'admin',
}

export interface JoinableGroup {
  id: GroupID;
  name: GroupName;
  description?: GroupDescription;
  type: GroupType;
  privacy: PrivacyFlag;
  sort_key?: SortKey;
  offer?: GroupOffer;
  logo?: Image;
  member_role_status?: MemberRoleStatus;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface Coordinates {
  x: number;
  y: number;
}

export interface CondensedImage {
  original_dimensions?: Dimensions;
  cropped_dimensions?: Dimensions;
  offset?: Coordinates;
  viewable_s3uri?: S3URI;
}

export interface GlobalOrganization {
  id: OrganizationID;
  name: OrganizationName;
  logo?: CondensedImage;
}

export interface OrganizationScope {
  group_ids: OrganizationID[];
  user_ids: UserID[];
}

export enum WatchStatus {
  Unwatch = 'unwatch',
  Watch = 'watch',
}

export enum GroupType {
  Local = 'local',
  Job = 'job',
  Community = 'community',
  Pathway = 'pathway',
  Guide = 'guide',
  Learner = 'learner',
  LTICourse = 'lti_course',
  ProgrammeWorkspace = 'programme_workspace',
  ProgrammeCollaboration = 'programme_collaboration',
}

export enum GlobalFlag {
  Normal = 'normal',
  Global = 'global',
}

export enum HiddenFlag {
  Normal = 'normal',
  Hidden = 'hidden',
}

export enum HardwareAccess {
  RecordedOnly = 'recorded_only',
  Live = 'live',
}

export enum AssessmentsFlag {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

export type SignupToken = string;

export type TermsOfService = string;

export enum MemberRole {
  Standard = 'standard',
  Contributor = 'contributor',
  Admin = 'admin',
}

export type ProgrammeID = number;
export type ProgrammeUserID = number;
export type GuideType = string;

export interface GroupInfo {
  organization_id?: OrganizationID;
  administrators: OrganizationScope;
  visible_members: OrganizationScope;
  subscribers: OrganizationScope;
  writers: OrganizationScope;
  assessors: OrganizationScope;
  permitted_media: MediaID[];
  all_writers: boolean;
  type: GroupType;
  global: GlobalFlag;
  hidden: HiddenFlag;
  privacy: PrivacyFlag;
  hardware_access: HardwareAccess;
  assessments: AssessmentsFlag;
  signup_token?: SignupToken;
  terms_of_services: TermsOfService;
  attachments_images_cud_role: MemberRole;
  discussions_cud_role: MemberRole;
  pages_cud_role: MemberRole;
  programme_id?: ProgrammeID;
  programme_user_id?: ProgrammeUserID;
  guide_type?: GuideType;
}

export enum AssignmentCompletion {
  Manual = 'manual',
  Auto = 'auto',
}

export interface RatingFormField {
  min_label: string;
  max_label: string;
  min_ordinal: number;
  max_ordinal: number;
}

export type Choice = string;

export interface MultipleChoiceFormField {
  choices: Choice[];
  allow_multiple: boolean;
  allow_other: boolean;
}

export type TextFormField = 0;

export interface CounterFormField {
  choices: Choice[];
}

export interface TimerFormField {
  choices: Choice[];
}

interface RatingFormFieldType {
  rating: RatingFormField;
}

interface MultipleChoiceFormFieldType {
  multiple_choice: MultipleChoiceFormField;
}

interface TextFormFieldType {
  text: TextFormField;
}

interface UrlFormFieldType {
  url: 0;
}

interface CounterFormFieldType {
  counter: CounterFormField;
}

interface TimerFormFieldType {
  timer: TimerFormField;
}

interface AttachmentFormFieldType {
  attachment: 0;
}

interface ReflectionFormFieldType {
  reflection: 0;
}

export type FormFieldType =
  RatingFormFieldType |
  MultipleChoiceFormFieldType |
  TextFormFieldType |
  UrlFormFieldType |
  CounterFormFieldType |
  TimerFormFieldType |
  AttachmentFormFieldType |
  ReflectionFormFieldType;

export enum FormFieldLink {
  Unlinked = 'unlinked',
  TimeLinked = 'time_linked',
}

export enum FormFieldAIOptions {
  NoAi = 'no_ai',
  AiResponse = 'ai_response',
  AiResponseTimestamped = 'ai_response_timestamped',
}

export enum AIReasoningLevel {
  High = 'high',
  Medium = 'medium',
  Standard = 'standard',
  Low = 'low',
}

interface FormFieldAISettings {
  reasoning_level: AIReasoningLevel;
}

export interface FormField {
  name: FormFieldName;
  label: FormFieldLabel;
  instructions: string;
  type: FormFieldType;
  link: FormFieldLink;
  has_notes: boolean;
  required: boolean;
  allow_multiple: boolean;
  visible: boolean;
  ai_options: FormFieldAIOptions;
  ai_instructions: string;
  ai_attachment_ids: AttachmentID[];
  ai_settings?: FormFieldAISettings
}

export interface FormElement {
  field: FormField;
}

export type AssignmentID = number;
export type AssignmentSubmissionID = number;
export type AttachmentID = number;
export type FormDataID = number;
export type FormID = number;
export type GroupID = number;
export type UserID = number;
export type OrganizationID = number;
export type ReflectionID = number;
export type VideoID = number;

export type Seconds = number;
export type Milliseconds = number;

export type AssignmentName = string;
export type AssignmentDescription = string;

export enum EnforceRequired {
  EnforceRequired = 'enforce_required',
  IgnoreRequired = 'ignore_required',
}

export interface Assignment extends Model {
  id: AssignmentID;
  group_id: GroupID;
  name: AssignmentName;
  description: AssignmentDescription;
  due_date?: Date;
  required_fields: EnforceRequired;
  completion: AssignmentCompletion;
  questions: FormElement[];
  notify_submitter: boolean;
  show_grade: boolean;
  show_feedback: boolean;
}

export interface ExpandedAssignment extends Assignment {
  readonly submissions: number;
  readonly attachments: number;
  readonly reflections: number;
}

export interface CondensedAssignment {
  id: AssignmentID;
  group_id: GroupID;
  group_name: GroupName;
  name: AssignmentName;
  description: AssignmentDescription;
}

export interface TimeInVideo {
  video_id: VideoID;
  time: Seconds;
}

export interface TimeInterval {
  start: TimeInVideo;
  end: TimeInVideo;
  total: Seconds;
}

export interface CounterValue {
  choice: Choice;
  clicks: TimeInVideo[];
}

export interface TimerValue {
  choice: Choice;
  intervals: TimeInterval[];
}

export interface VideoSegment {
  video_id: VideoID;
  start: Milliseconds;
  duration: Milliseconds;
}

export type FormFieldName = string;
export type FormFieldLabel = string;

export type FormAnswerTextValue = string;
export type FormAnswerMultipleChoiceValue = string[] | string;
export type FormAnswerRatingValue = number;
export type FormAnswerUrlValue = string;

type FormAnswerValue =
  FormAnswerTextValue |
  FormAnswerMultipleChoiceValue |
  FormAnswerRatingValue |
  FormAnswerUrlValue;

export interface FormAnswer {
  name: FormFieldName;
  value: FormAnswerValue;
  counter_value?: CounterValue[];
  timer_value?: TimerValue[];
  attachment_value?: AttachmentID;
  reflection_value?: ReflectionID;
  timeline: VideoSegment[];
  notes?: string;
  user_id: UserID;
}

export interface FormData {
  id: FormDataID;
  user_id: UserID;
  organization_id: OrganizationID;
  form_id: FormID;
  reflection_id: ReflectionID;
  answers: FormAnswer[];
  readonly delete_state: DeleteState;
  replicated_form?: FormDataID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export interface ExpandedFormData {
  id: FormDataID;
  user_id: UserID;
  user: CondensedUser;
  organization_id: OrganizationID;
  form_id: FormID;
  reflection_id: ReflectionID;
  answers: FormAnswer[];
  readonly delete_state: DeleteState;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export enum SubmissionComplete {
  Incomplete = 'incomplete',
  Complete = 'complete',
  Draft = 'draft',
}

export type Grade = string;

export interface AssignmentSubmission extends Model {
  id: AssignmentSubmissionID;
  user_id: UserID;
  organization_id: OrganizationID;
  assignment_id: AssignmentID;
  original_submission_id?: AssignmentSubmissionID;
  page_id?: PageID;
  answers: FormAnswer[];
  complete: SubmissionComplete;
  grade?: Grade;
  feedback?: string;
}

export type FirstName = string;
export type LastName = string;

export interface ExpandedAssignmentSubmission extends AssignmentSubmission {
  readonly first_name: FirstName;
  readonly last_name: LastName;
}

export type AttachmentName = string;
export type AttachmentDescription = string;
export type AvatarMasterKey = string;

export enum CrossOrgFlag {
  Normal = 'normal',
  Cross = 'cross',
}

export interface CaptionTrackPurpose {
  video_id: VideoID;
}

export type AttachmentPurpose = {
  caption_track: CaptionTrackPurpose;
};

export interface Attachment {
  id: AttachmentID;
  user_id?: UserID;
  readonly user?: CondensedUser;
  organization_id?: OrganizationID;
  cross_org: CrossOrgFlag;
  group_id?: GroupID;
  reflection_id?: ReflectionID;
  name: AttachmentName;
  description: AttachmentDescription;
  readonly purpose?: AttachmentPurpose;
  freeze: FreezeState;
  readonly media_key?: AvatarMasterKey;
  media_s3uri?: S3URI;
  access_count: AccessCount;
  delete_state?: DeleteState;
  replicated_from?: AttachmentID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type MilestoneIdentifier = number;
export type MilestoneName = string;
export type MilestoneDescription = string;

export interface ButtonMilestone {
  text: string;
}

export interface PageMilestone {
  page_id?: PageID;
}

export interface ReflectionMilestone {
  reflection_id?: ReflectionID;
}

export interface AssignmentMilestone {
  assignment_id?: AssignmentID;
}

export interface DiscussionMilestone {
  discussion_id?: DiscussionID;
}

export interface AttachmentMilestone {
  attachment_id?: AttachmentID;
}

export interface FormMilestone {
  form_id?: FormID;
}

export interface FeedbackMilestone {
  type: FeedbackType
}

export enum FeedbackType {
  TraineeSummary = 'trainee_summary',
  CoachSummary = 'coach_summary',
  SuccessSummary = 'success_summary',
  Objective = 'objective',
  LearningChallenge = 'learning_challenge',
  Action = 'action',
  Impact = 'impact',
}

export type MilestoneType = {
  button: ButtonMilestone;
} | {
  visit_page: PageMilestone;
} | {
  link_guide: PageMilestone;
} | {
  link_reflection: PageMilestone;
} | {
  watch_reflection: ReflectionMilestone;
} | {
  submit_to_assignment: AssignmentMilestone;
} | {
  complete_assignment: AssignmentMilestone;
} | {
  discuss: DiscussionMilestone;
} | {
  download_attachment: AttachmentMilestone;
} | {
  submit_insight: FormMilestone;
} | {
  submit_feedback: FeedbackMilestone;
};

export enum MilestoneNotify {
  None = 'none',
  PathwayUser = 'pathway_user',
  Admins = 'admins',
  AdminsAndPathwayUser = 'admins_and_pathway_user',
  All = 'all',
}

export interface Milestone {
  id: MilestoneIdentifier;
  name: MilestoneName;
  description: MilestoneDescription;
  type: MilestoneType;
  notify: MilestoneNotify;
  notification_text?: string | null;
  completes_pathway: boolean;
  completed: boolean;
}

export enum Region {
  AU = 'au',
  CN = 'cn',
  EU = 'eu',
  UK = 'uk',
  US = 'us',
}

export type S3Bucket = string;
export type S3Path = string;

export enum S3URIType {
  NoCDN = 'no_cdn',
  Download = 'download',
  Unsigned = 'unsigned',
  Streaming = 'streaming',
  StreamingHLS = 'streaming_hls',
}

export interface S3URI {
  region?: Region;
  bucket: S3Bucket;
  path: S3Path;
  type: S3URIType;
  readonly url?: string;
}

export enum PlaybackMode {
  Normal = 'normal',
  Anonymised = 'anonymised',
  AnonymisedDeface = 'anonymised_deface',
}

export enum AttachmentMode {
  Normal = 'normal',
  Disabled = 'disabled',
}

export enum CommentMode {
  Normal = 'normal',
  Disabled = 'disabled',
}

export type ReflectionName = string;
export type ReflectionRoom = string;
export type ReflectionDescription = string;

export type LessonCode = string;

export interface Reflection extends Model {
  id: ReflectionID;
  name: ReflectionName;
  room: ReflectionRoom;
  description: ReflectionDescription;
  user_id?: UserID;
  group_id?: GroupID;
  readonly video_ids: VideoID[];
  playback_mode: PlaybackMode;
  attachment_mode: AttachmentMode;
  form_mode: FormMode;
  embed_mode: EmbedMode;
  comment_mode: CommentMode;
  thumbnail?: ThumbnailIdentity;
  expires_at?: ReflectionExpiresAt;
}

export type RoomID = number;
export type RoomMeetingID = number;
export type ReflectionUUID = string;

export interface VideoPrepRange {
  best: VideoPrepState;
  worst: VideoPrepState;
}

export interface VideoPrepSummary {
  normal?: VideoPrepRange;
  anonymised?: VideoPrepRange;
  desktop?: VideoPrepRange;
  mobile?: VideoPrepRange;
}

export enum VideoProductsOrdered {
  FLVWithoutThumbnails = 'flv_without_thumbnails',
  FLVWithThumbnails = 'flv_with_thumbnails',
  FLVDualView = 'flv_dual_view',
  MobileButNoCartoon = 'mobile_but_no_cartoon',
  Standard = 'standard',
}

export enum TreatmentType {
  Treatment = 'treatment',
  Control = 'control',
}

export enum VideoRetake {
  Original = 'original',
  Retake = 'retake',
}

export interface MathematicaMetadata {
  field_staff_id: number;
  district_name: string;
  teacher_id: number;
  teacher_name: string;
  treatment_type: TreatmentType;
  school_name: string;
  video_retake: VideoRetake;
  subject_area: string;
  num_students: number;
  notes?: string;
}

export type CustomerMetadata = {
  mathematica: MathematicaMetadata;
};

export enum AnswerPrivacy {
  Hidden = 'hidden',
  Visible = 'visible',
}

export interface FormAssociation {
  form_id: FormID;
  user_id?: UserID;
  answer_privacy: AnswerPrivacy;
}

export enum FormMode {
  Normal = 'normal',
  FormDataOnly = 'form_data_only',
  Disabled = 'disabled',
}

export enum EmbedMode {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export type LastCommentAt = Date;
export type ReflectionExpiresAt = Date;
export type PairingID = string;

export enum BookingProgression {
  Before = 'before',
  PreGrace = 'pre_grace',
  InProgress = 'in_progress',
  PostGrace = 'post_grace',
  Over = 'over',
}

export enum ReflectionOrdered {
  Default = 'default',
  None = 'none',
}

export interface CondensedBooking {
  id: BookingID;
  user_id: UserID;
  organization_id: OrganizationID;
  delete_state?: DeleteState;
  device_id: DeviceID;
  upload_box_device_id?: DeviceID;
  start_grace: Minutes;
  starts_at: Date;
  duration: Minutes;
  end_grace: Minutes;
  state: BookingProgression;
  owner_id: UserID;
  reflection_user_id?: UserID;
  observer_id?: UserID;
  reflection_ordered: ReflectionOrdered;
  reflection_name: ReflectionName;
  reflection_room: ReflectionRoom;
  reflection_description: ReflectionDescription;
  reflection_lesson_code?: LessonCode;
  reflection_id?: ReflectionID;
  booking_invitations: BookingInvitation[];
  active: Acceptance;
  relationship: Relationship;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type WebRTCRoomName = string;

export interface Booking {
  id: BookingID;
  user_id: UserID;
  organization_id: OrganizationID;
  delete_state?: DeleteState;
  device_id: DeviceID;
  upload_box_device_id?: DeviceID;
  start_grace: Minutes;
  starts_at: Date;
  duration: Minutes;
  end_grace: Minutes;
  reflection_user_id?: UserID;
  observer_id?: UserID;
  reflection_ordered: ReflectionOrdered;
  reflection_name: ReflectionName;
  reflection_room: ReflectionRoom;
  reflection_description: ReflectionDescription;
  reflection_lesson_code?: LessonCode;
  reflection_id?: ReflectionID;
  booking_invitations: BookingInvitation[];
  active: Acceptance;
  web_rtc_room_name?: WebRTCRoomName;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export interface ExpandedReflection {
  id: ReflectionID;
  name: ReflectionName;
  room: ReflectionRoom;
  description: ReflectionDescription;
  lesson_code?: LessonCode;
  user_id?: UserID;
  user?: CondensedUser;
  organization_id?: OrganizationID;
  organization?: CondensedOrganization;
  cross_org: CrossOrgFlag;
  group_id?: GroupID;
  room_id?: RoomID;
  room_meeting_id?: RoomMeetingID;
  booking?: CondensedBooking;
  share_ids: ShareID[];
  shares: Share[];
  tags: Tags;
  tags_access: TagsAccess;
  delete_state?: DeleteState;
  video_ids: VideoID[];
  video_mode: VideoMode;
  videos: ExpandedVideo[];
  uuid?: ReflectionUUID;
  prep_state: VideoPrepState;
  prep_summary: VideoPrepSummary;
  progress?: Percent;
  all_reprocessed: boolean;
  products_ordered?: VideoProductsOrdered;
  thumbnail?: ThumbnailIdentity;
  thumbnail_s3uri?: S3URI;
  started_at?: StartedAt;
  duration?: Minutes;
  customer_metadata?: CustomerMetadata;
  form_associations: FormAssociation[];
  watchers: UserID[];
  unwatchers: UserID[];
  notification_level: NotificationLevel;
  playback_mode: PlaybackMode;
  attachment_mode: AttachmentMode;
  comment_mode: CommentMode;
  form_mode: FormMode;
  embed_mode: EmbedMode;
  comment_count: number;
  last_comment_at?: LastCommentAt;
  relationship: Relationship;
  original_user?: CondensedUser;
  allow_default_copy: boolean;
  replicated_from?: ReflectionID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
  databridged_at?: DatabridgedAt;
  expires_at?: ReflectionExpiresAt;
  pairing_id?: PairingID;
}

export interface CondensedReflection extends Model {
  id: ReflectionID;
  name: ReflectionName;
  room: ReflectionRoom;
  description: ReflectionDescription;
  user_id?: UserID;
  user?: CondensedUser;
  thumbnail_s3uri?: S3URI;
  readonly video_ids: VideoID[];
}

export type DiscussionID = number;
export type DiscussionTitle = string;

export enum DiscussionLocked {
  Unlocked = 'unlocked',
  Locked = 'locked',
}

export enum NotificationLevel {
  All = 'all',
  OrganizationOnly = 'organization_only',
  Minimal = 'minimal',
  None = 'none',
}

export enum UserVisibility {
  All = 'all',
  Restricted = 'restricted',
}

export interface Discussion {
  id: DiscussionID;
  group_id: GroupID;
  title: DiscussionTitle;
  locked: DiscussionLocked;
  watchers: UserID[];
  unwatchers: UserID[];
  notification_level: NotificationLevel;
  user_visibility: UserVisibility;
  delete_state?: DeleteState;
  replicated_form?: DiscussionID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type DiscussionCommentID = number;
export type CommentBody = string;
export type EditedAt = Date;

export interface UserMention {
  user_id?: UserID;
  first_name: FirstName;
  last_name: LastName;
}

export type CommentBodyChunk = {
  text: string;
} | {
  user_mention: UserMention;
};

export interface DiscussionCommentEdit {
  user_id?: UserID;
  edited_at: EditedAt;
  olb_body_rep: CommentBodyChunk[];
}

export type DiscussionCommentAction = {
  attached_to_page: PageID;
};

export interface DiscussionComment {
  id: DiscussionCommentID;
  parent_id?: DiscussionCommentID;
  user_id: UserID;
  organization_id: OrganizationID;
  discussion_id: DiscussionID;
  page_id?: PageID;
  body: CommentBody;
  body_rep: CommentBodyChunk[];
  privacy: CommentPrivacy;
  edit_log: DiscussionCommentEdit[];
  action?: DiscussionCommentAction;
  delete_state?: DeleteState;
  edited_at?: EditedAt;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export interface ExpandedDiscussionComment {
  id: DiscussionCommentID;
  parent_id?: DiscussionCommentID;
  user_id: UserID;
  organization_id: OrganizationID;
  user: CondensedUser;
  discussion_id: DiscussionID;
  page_id?: PageID;
  body: CommentBody;
  body_rep: CommentBodyChunk[];
  privacy: CommentPrivacy;
  edit_log: DiscussionCommentEdit[];
  action?: DiscussionCommentAction;
  delete_state?: DeleteState;
  content_origin?: ContentOrigin;
  edited_at?: EditedAt;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export enum VideoPrepState {
  Error = 'error',
  Undecryptable = 'undecryptable',
  Unsupported = 'unsupported',
  Waiting = 'waiting',
  Uploading = 'uploading',
  Transcoding = 'transcoding',
  Ready = 'ready',
}

export type VideoMasterKey = string;
export type VideoPartNumber = number;

export enum ChannelType {
  Video = 'video',
  VideoLeft = 'video_left',
  VideoRight = 'video_right',
  VideoLeftRight = 'video_left_right',
  Audio = 'audio',
}

export enum Quality {
  FLV = 'flv',
  Standard = 'standard',
  HLSLowDef = 'hls_low_def',
  AdaptiveHLS = 'adaptive_hls',
  HLSAdaptive = 'hls_adaptive',
}

export enum VideoFilter {
  Cartoon = 'cartoon',
}

export type Percent = number;

export interface StreamableVideo {
  quality: Quality;
  filter?: VideoFilter;
  video_s3uri?: S3URI;
  thumbnail_s3uri?: S3URI;
  thumbnail_present: boolean;
  prep_state: VideoPrepState;
  progress?: Percent;
  duration?: Milliseconds;
}

type AspectRatio = string;

export interface Channel {
  type: ChannelType;
  media_key?: VideoMasterKey;
  master_s3uri?: S3URI;
  master_duration?: Milliseconds;
  master_copy_std: boolean;
  // hermes_sha?: SHA256;
  // mobile_sha?: SHA256;
  // mobile_etag?: ETag;
  aspect_ratio?: AspectRatio;
  streamable_videos: StreamableVideo[];
  // master?: PrepStatus;
  // audio_track?: AudioTrack;
  prep_state: VideoPrepState;
  prep_state_minimum?: VideoPrepState;
  // prep_summary?: VideoPrepSummary;
  // progress?: Percent;
  eta?: Milliseconds;
  // uploaded_at?: UploadedAt;
  // platform?: MobilePlatform;
  // record_version?: IOSVersion;
  // diagnostic?: IOSVersion;
  // ios_version?: IOSVersion;
  // device_version?: IOSVersion;
  // mic_version?: IOSVersion;
  // mic_ios_version?: IOSVersion;
  // mic_device_version?: IOSVersion;
  // ble_disconnections?: DisconnectionCount;
  // streaming_disconnections?: DisconnectCount;
  // media_descriptor?: MediaDescriptorText;
  chronos_metadata?: unknown;
  // standard_thumbs?: ThumbnailCount;
  // cartoon_thumbs?: ThumbnailCount;
}

export type ThumbnailIndex = number;

export interface ThumbnailIdentity {
  video_id: VideoID;
  channel_type: ChannelType;
  filter?: VideoFilter;
  thumbnail_index: ThumbnailIndex;
}

export interface VideoThumbnail {
  thumbnail: ThumbnailIdentity;
  s3uri: S3URI;
}

export interface Playlist {
  video_id: VideoID;
  mediaid: string;
  pan: number;
  image: string;
  sources: Array<{
    file: string;
    label: string;
    type: string;
  }>;
  tracks: Array<{
    file: string;
    kind: string;
    label?: string;
  }>;
}

export type VideoDescription = string;

export enum DualView {
  None = 'none',
  Single = 'single',
  Dual = 'dual',
  Audio = 'audio',
}

export enum VideoMode {
  Provisional = 'provisional',
  Normal = 'normal',
  Frozen = 'frozen',
  Legacy = 'legacy',
}

export enum LeftOrRight {
  Left = 'left',
  Right = 'right',
}

export enum VideoEvictionState {
  NotEvicted = 'not_evicted',
  FullyEvicted = 'fully_evicted',
  DkEvicted = 'dk_evicted',
}

export enum VideoLifeCycleStage {
  Provisional = 'provisional',
  Frozen = 'frozen',
  Demo = 'demo',
  Legacy = 'legacy',
  ImportImpossibleMissingStreamable = 'import_impossible_missing_streamable',
  ImportImpossibleUnknownMaster = 'import_impossible_unknown_master',
  ImportImpossibleDuplicateChannel_type = 'import_impossible_duplicate_channel_type',
  ImportImpossibleInvalidMode = 'import_impossible_invalid_mode',
  ImportFailed = 'import_failed',
  NoPrep = 'no_prep',
  PrepFailedFrozenVideo = 'prep_failed_frozen_video',
  PrepFailedInvalidChannels = 'prep_failed_invalid_channels',
  LastPrepSucceededProcessing = 'last_prep_succeeded_processing',
  Processing = 'processing',
  TranscodingFailed = 'transcoding_failed',
  Corrupted = 'corrupted',
  Ready = 'ready',
}

export type VideoUploadSource = {
  device: DeviceType;
} | {
  manual: void;
};

export interface Video {
  id: VideoID;
  mode: VideoMode;
  description?: VideoDescription;
  // lesson_code?: LessonCode;
  // playback_mode?: PlaybackMode;
  part_no: VideoPartNumber;
  user_id?: UserID;
  organization_id?: OrganizationID;
  group_id?: GroupID;
  reflection_id?: ReflectionID;
  comment_id?: CommentID;
  // extension?: VideoExtension;
  delete_state?: DeleteState;
  started_at?: StartedAt;
  duration?: Milliseconds;
  channels: Channel[];
  // video_events: VideoEvent[];
  first_converted_channel?: LeftOrRight;
  override_channel?: LeftOrRight;
  // products_ordered?: VideoProductsOrdered;
  // room_id?: RoomID;
  // room_meeting_id?: RoomMeetingID;
  // meeting_strategy?: MeetingUploadStrategy;
  upload_source?: VideoUploadSource;
  // clip_source?: VideoClipSource;
  // merge_source?: VideoMergeSource;
  spillover_source?: VideoID;
  readonly comment_ids: CommentID[];
  readonly dual_view?: DualView;
  readonly video_life_cycle_stage?: VideoLifeCycleStage;
  readonly thumbnail_s3uri?: S3URI;
  // readonly decryption_keys: ChannelDecryptionKey[];
  // product_sprites: ProductSprites;
  // download_count: DownloadCount;
  is_reconnect: boolean;
  is_evicted: VideoEvictionState;
  // multiple_audio_support: MultipleAudioSupport;
  // caption_position: CaptionPosition;
  custom_metadata?: any;
  // mobile_upload_region?: AwsRegion;
  replicated_from?: VideoID;
  original_replicated_from?: VideoID;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export interface ExpandedVideo {
  id: VideoID;
  readonly video_life_cycle_stage?: VideoLifeCycleStage;
  // Requires typing
}

export interface VideoSpriteSheet {
  video_id: VideoID;
  s3uri: S3URI;
}

export type AnswerCount = number;

export interface RatingStats {
  min: number;
  max: number;
  total: number;
  count: number;
}

export interface ChoiceStats {
  choice: Choice;
  count: number;
}

export interface TimerStats {
  choice: Choice;
  count: number;
  total_duration: Seconds;
}

export interface RatingFormFieldStats {
  rating: RatingStats;
}

export interface MultipleChoiceFormFieldStats {
  multiple_choice: ChoiceStats[];
}

export interface TextFormFieldStats {
  text: AnswerCount;
}

export interface UrlFormFieldStats {
  url: AnswerCount;
}

export interface CounterFormFieldStats {
  counter: ChoiceStats[];
}

export interface TimerFormFieldStats {
  timer: TimerStats[];
}

export interface AttachmentFormFieldStats {
  attachment: AnswerCount;
}

export interface ReflectionFormFieldStats {
  reflection: AnswerCount;
}

export type FormFieldStats =
  RatingFormFieldStats |
  MultipleChoiceFormFieldStats |
  TextFormFieldStats |
  UrlFormFieldStats |
  CounterFormFieldStats |
  TimerFormFieldStats |
  AttachmentFormFieldStats |
  ReflectionFormFieldStats;

export interface FormFieldOverview {
  field: FormField;
  stats: FormFieldStats;
}

export interface FormUser {
  id: UserID;
  organization_id: OrganizationID;
  first_name: FirstName;
  last_name: LastName;
  avatar_s3uri?: S3URI;
}

export enum Relationship {
  Owner = 'owner',
  Observer = 'observer',
  Sharee = 'sharee',
  Admin = 'admin',
}

export enum MediaAccess {
  None = 'none',
  Basic = 'basic',
  Full = 'full',
}

export interface FormReflection {
  id: ReflectionID;
  name?: ReflectionName;
  user?: FormUser;
  relationship: Relationship;
  thumbnail_s3uri?: S3URI;
  media_access: MediaAccess;
}

export interface ExpandedAnswer {
  answer: FormAnswer;
  respondent: FormUser;
  ai_model_id?: AIModelID;
  reflection: FormReflection;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type FormName = string;

export enum FormReporting {
  Private = 'private',
  Reporting = 'reporting',
}

export interface FormNotifyComplete {
  reflection_owner: boolean;
  form_owner: boolean;
  associator: boolean;
  all_sharees: boolean;
  lock_on_complete: boolean;
}

export enum ContentOrigin {
  Original = 'original',
  Synthetic = 'synthetic',
}

export enum ModelChoice {
  Specified = 'specified',
  Unspecified = 'unspecified',
}

export interface FormAIOptions {
  model_choice: ModelChoice;
  model_id: AIModelID;
  context: string;
  attachment_ids: AttachmentID[];
}

export interface Form {
  id: FormID;
  user_id?: UserID;
  user_name?: Username;
  organization_id?: OrganizationID;
  organization_name?: OrganizationName;
  group_id?: GroupID;
  name: FormName;
  instructions: string;
  copyright: string;
  reporting: FormReporting;
  reporters: UserID[];
  notify_complete?: FormNotifyComplete;
  elements: FormElement[];
  access_acount: number;
  readonly has_data?: boolean;
  ai_options?: FormAIOptions | null;
  clone_allowed: boolean;
  readonly delete_state?: DeleteState;
  replicated_from?: FormID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

type ReplicatedAt = Date;

type JobID = number;

export interface AIFormJobInput {
  model_id: AIModelID;
  form_id: FormID;
  reflection_id: ReflectionID;
  user_id?: UserID;
  video_ids: VideoID[];
}

export enum JobStage {
  Pending = 'pending',
  Acknowledged = 'acknowledged',
  Processing = 'processing',
  Completed = 'completed',
  Aborted = 'aborted',
  DependencyError = 'dependency_error',
  Error = 'error',
}

export interface FormJob {
  id: JobID;
  input: AIFormJobInput;
  stage: JobStage;
  progress: Percent;
}

export interface ExpandedForm {
  id: FormID;
  user_id?: UserID;
  user_name?: Username;
  organization_id?: OrganizationID;
  organization_name?: OrganizationName;
  group_id?: GroupID;
  name: FormName;
  instructions: string;
  copyright: string;
  reporting:	FormReporting;
  reporters: UserID[];
  notify_complete?: FormNotifyComplete
  elements: FormElement[];
  access_count: AccessCount;
  has_data?: boolean;
  ai_options?: FormAIOptions;
  clone_allowed: boolean;
  form_jobs: FormJob[];
  delete_state?: DeleteState;
  replicated_from?: FormID;
  replicated_at?: ReplicatedAt;
  content_origin?: ContentOrigin;
  created_at?: CreatedAt;
  updated_at?: UpdatedAt;
}

export type SortKey = string;
export type Postcode = string;
export type DeviceID = number;
export type DeviceName = string;
export type BookingID = number;
export type StartedAt = Date;
export type Minutes = number;
export type InvitationID = string;

export interface BookingInvitation {
  invitation_id?: InvitationID;
  user_id: UserID;
  acceptance: Acceptance;
}

export enum DeviceType {
  Lv215 = 'lv_215',
  Lv5512 = 'lv_5512',
  Lv5514 = 'lv_5514',
  DiscoveryKit = 'discovery_kit',
  UploadBox = 'upload_box',
  ScreenCapture = 'screen_capture',
  CallRecording = 'call_recording',
  VideoConference = 'video_conference',
}

export interface SearchGroup {
  id: GroupID;
  name: GroupName;
  description?: GroupDescription;
  is_organization?: boolean;
  sort_key?: SortKey;
  avatar_s3uri?: S3URI;
  postcode?: Postcode;
  delete_state?: DeleteState;
}

export interface SearchUser {
  id: UserID;
  organization: SearchGroup;
  username: Username;
  first_name: FirstName;
  last_name: LastName;
  avatar_s3uri?: S3URI;
  admin: AdministratorType;
  validated: boolean;
  delete_state?: DeleteState;
}

export interface SearchDevice {
  id: DeviceID;
  name: DeviceName;
  type: DeviceType;
  organization: SearchGroup;
  ios_terminal_count: number;
}

export interface SearchReflection {
  id: ReflectionID;
  name: ReflectionName;
  description: ReflectionDescription;
  user?: SearchUser;
  username?: Username;
  organization_name?: OrganizationName;
  group_name?: GroupName;
  booking_id?: BookingID;
  booking_invitations: BookingInvitation[];
  started_at?: StartedAt;
  duration?: Minutes;
  thumbnail_s3uri?: S3URI;
}

export type SearchResult = {
  user: SearchUser;
} | {
  device: SearchDevice;
} | {
  group: SearchGroup;
} | {
  reflection: SearchReflection;
};

export enum FreezeState {
  Normal = 'normal',
  Frozen = 'frozen',
}

export enum ImageType {
  Embedded = 'embedded',
  GroupLogo = 'group_logo',
  GroupBanner = 'group_banner',
  LegacyOrganzation = 'legacy_organization',
  TrustNetworkLogo = 'trust_network_logo',
  Room = 'room',
}

export interface Image {
  id: ImageID;
  user_id?: UserID;
  organization_id?: OrganizationID;
  group_id?: GroupID;
  trust_network_id?: TrustNetworkID;
  freeze: FreezeState;
  type: ImageType;
  original_dimensions?: Dimensions;
  cropped_dimensions?: Dimensions;
  offset?: Coordinates;
  readonly media_key?: AvatarMasterKey;
  master_s3uri?: S3URI;
  viewable_s3uri?: S3URI;
  readonly magic_string: AvatarMagicString;
  readonly delete_state?: DeleteState;
  replicated_form?: ImageID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type TrustNetworkName = string;
export type TrustNetworkDescription = string;

export enum TrustNetworkType {
  Training = 'training',
  Service = 'service',
  AiMedia = 'ai_media',
}

export interface TrustNetwork {
  id: TrustNetworkID;
  organization_id: OrganizationID;
  name: TrustNetworkName;
  description?: TrustNetworkDescription;
  readonly logo?: Image;
  type: TrustNetworkType;
  hardware_access: HardwareAccess;
  caches: UserID[];
  readonly subscribers: OrganizationScope;
  sales_area?: SalesArea;
  terms_of_service: TermsOfService;
  readonly delete_state?: DeleteState;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type PermissionID = number;

export interface DownloadPermission {
  video_id: VideoID;
}

export interface JoinPermission {
  organization_id?: OrganizationID;
  readonly organization?: CondensedOrganization;
  group_id: GroupID;
  readonly group?: CondensedGroup;
}

export interface ReadPermission {
  organization_id?: OrganizationID;
  group_id: GroupID;
  readonly group?: CondensedGroup;
  initiator_user_id?: UserID;
  initiator?: CondensedUser;
}

export interface UpgradePermission {
  group_id: GroupID;
  readonly group?: CondensedGroup;
}

export interface InvitePermission {
  organization_id: OrganizationID;
  readonly organization?: GlobalOrganization;
  trust_network_id: TrustNetworkID;
  readonly trust_network?: TrustNetwork;
}

export type PermissionRequest = {
  download: DownloadPermission;
} | {
  join: JoinPermission;
} | {
  read: ReadPermission;
} | {
  upgrade: UpgradePermission;
} | {
  invite: InvitePermission;
};

export interface Approval {
  readonly user_id?: UserID;
  readonly is_approved: boolean;
}

export interface Permission {
  id: PermissionID;
  user_id: UserID;
  organization_id: OrganizationID;
  readonly user?: CondensedUser;
  readonly organization?: CondensedOrganization;
  request: PermissionRequest;
  approval?: Approval;
  delete_state?: DeleteState;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type DownloadResponse = {
  download: URI;
} | {
  permission: Permission;
} | {
  download_interval: S3URI;
};

export type OrganizationDescription = string;

export interface CondensedOrganization {
  id: OrganizationID;
  name: OrganizationName;
  description?: OrganizationDescription;
  sales_area?: SalesArea;
  type?: OrganizationTypeID;
  is_organization: boolean;
  users_count?: UsersCount;
  devices_count?: DevicesCount;
}

export interface CommentEdit {
  user_id?: UserID;
  edited_at: EditedAt;
  olb_body?: CommentBody;
  old_start?: Milliseconds;
  old_duration?: Milliseconds;
}

export type CommentID = number;

export type CommentResourceLink = {
  group: GroupID;
} | {
  pathway_group: GroupID;
} | {
  guide_group: GroupID;
} | {
  reflection: ReflectionID;
};

export interface CommentResourceLinkGroup {
  id: GroupID;
  type: GroupType;
  name: GroupName;
  logo?: Image;
}

export interface CommentResourceLinkReflection {
  id: ReflectionID;
  name: ReflectionName;
  thumbnail_s3uri?: S3URI;
}

export type ExpandedCommentResourceLink = {
  group: CommentResourceLinkGroup;
} | {
  pathway_group: CommentResourceLinkGroup;
} | {
  guide_group: CommentResourceLinkGroup;
} | {
  reflection: CommentResourceLinkReflection;
} | {
  inaccessible: CommentResourceLink;
};

export enum CommentPrivacy {
  Private = 'private',
  Public = 'public',
}

export interface Comment {
  id: CommentID;
  type: CommentType;
  parent_id?: CommentID;
  user_id: UserID;
  organization_id: OrganizationID;
  video_id?: VideoID;
  delete_state?: DeleteState;
  body: CommentBody;
  privacy: CommentPrivacy;
  resource_links: CommentResourceLink[];
  start?: Milliseconds;
  duration?: Milliseconds;
  edit_log: CommentEdit[];
  synchronicity: CommentSynchronicity;
  replicated_from?: CommentID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type JoinGroupResponse = {
  group: Group;
} | {
  permission: Permission;
};

export interface LeaveGroupRequest {
  organization_id?: OrganizationID;
  remove_members: boolean;
  group_id: GroupID;
}

export interface JoinGroupRequest {
  organization_id?: OrganizationID;
  group_id: GroupID;
  signup_token?: SignupToken;
}

export enum TargetGroupType {
  Local = 'local',
  Community = 'community',
}

export interface Template {
  id: TemplateID;
  user_id: UserID;
  organization_id: OrganizationID;
  source_group_id?: GroupID;
  template_group_id: GroupID;
  target_group_type: TargetGroupType;
  readonly delete_state?: DeleteState;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export enum CommentType {
  Text = 'text',
  Media = 'media',
}

export interface VideoComment {
  id: VideoID;
  mode: VideoMode;
  duration?: Milliseconds;
  prep_state: VideoPrepState;
  prep_summary?: VideoPrepSummary;
  readonly thumbnail_s3uri?: S3URI;
}

export enum CommentSynchronicity {
  Simultaneous = 'simultaneous',
  Pause = 'pause',
}

export interface ExpandedComment {
  id: CommentID;
  type: CommentType;
  parent_id?: CommentID;
  parent?: Comment;
  user_id: UserID;
  user: CondensedUser;
  organization_id: OrganizationID;
  organization: CondensedOrganization;
  video_id?: VideoID;
  video_media?: VideoComment;
  delete_state?: DeleteState;
  body: CommentBody;
  privacy: CommentPrivacy;
  resource_links: CommentResourceLink[];
  resource_links_details: ExpandedCommentResourceLink[];
  start?: Milliseconds;
  duration?: Milliseconds;
  edit_log: CommentEdit[];
  synchronicity: CommentSynchronicity;
  replicated_from?: CommentID;
  content_origin?: ContentOrigin;
  created_at: CreatedAt;
  updated_at: UpdatedAt;
}

export type S3BucketText = string;

export interface S3Object {
  endpoint: URI;
  bucket: S3BucketText;
  path: S3Path;
}

export interface UploadCredentials {
  s3_object: S3Object;
  credentials: any;
}

export interface UploadDescriptor {
  key: VideoMasterKey;
  // veo_extension?: VideoExtension;
  // sha?: SHA256;
  duration?: Milliseconds;
  // product_durations: ProductDuration[];
  // product_sprites: ProductSprite[];
  // product_thumbs: ProductThumbnailCount[];
  aspect_ratio?: AspectRatio;
  state?: VideoPrepState;
  chn_progress?: Percent;
}

export enum MetricsCharts {
  LineCharts = 'line_charts',
  Aggregates = 'aggregates',
}
