import React, { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';

interface LoadMoreButtonProps {
  isLoading: boolean;
  onLoadMore: () => void;
}

const LoadMoreButton = (props: LoadMoreButtonProps): JSX.Element => {
  const { isLoading, onLoadMore } = props;

  const ref = useRef<HTMLButtonElement>(null);
  const intersection = useIntersection(ref, {
    root: ref.current?.parentElement,
    rootMargin: '50%',
  });

  const isIntersecting = intersection?.isIntersecting ?? false;

  useEffect(() => {
    if (isIntersecting) {
      onLoadMore();
    }
  }, [isIntersecting, onLoadMore]);

  const handleClick = () => {
    if (isLoading) { return; }

    onLoadMore();
  };

  return (
    <button
      ref={ref}
      type="button"
      className="tw-btn tw-btn-ghost tw-w-full"
      onClick={handleClick}
    >
      {isLoading ? <span className="tw-loading" /> : __('Load more')}
    </button>
  );
};

export default LoadMoreButton;
