import React, { useState } from 'react';

import NewReflectionOptions from './NewReflectionOptions';
import BookingForm from './BookingForm';

import {
  reflectionRoute,
  searchParams,
} from '../../../common/api/routes';

const pages = {
  TYPES: 'types',
  BOOKING_FORM: 'booking-form',
  LOADER: 'loader',
};

const bookingTypes = {
  'desktop-upload': { title: __('Desktop Upload') },
  'screen-capture': { title: __('Screen Capture') },
  'blank-reflection': { title: __('Blank Reflection') },
};

const NewReflectionForm = (props) => {
  const { source } = props; 

  const [state, setState] = useState({
    page: pages.TYPES,
  });

  switch (state.page) {
    case pages.TYPES: {
      const handleOptionClick = (type) => () => {
        setState({
          page: pages.BOOKING_FORM,
          pageProps: { type },
        });
      };

      return (
        <NewReflectionOptions
          {...props}
          onDesktopUploadClick={handleOptionClick('desktop-upload')}
          onScreenCaptureClick={handleOptionClick('screen-capture')}
          onBlankReflectionClick={handleOptionClick('blank-reflection')}
          source={source}
        />
      );
    }

    case pages.BOOKING_FORM: {
      const handleSaved = (reflection) => {
        window.location = [
          reflectionRoute({ reflectionId: reflection.id }),
          searchParams({ intent: state.pageProps.type }),
        ].join('?');
      };

      return (
        <BookingForm
          {...props}
          loading={state.pageProps.loading}
          title={bookingTypes[state.pageProps.type].title}
          onSaved={handleSaved}
        />
      );
    }
    default: return '';
  }
};

NewReflectionForm.propTypes = {};

export default NewReflectionForm;
