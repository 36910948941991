import { useEffect, useState } from 'react';
import useFindReflectionPlaylistQuery from '../../common/hooks/api/reflections/useFindReflectionPlaylistQuery';
import useReflectionVideosQuery from '../../common/hooks/api/useReflectionVideosQuery';
import * as Atlas from '../../common/types/Atlas';

const pendingPrepStates = new Set([
  Atlas.VideoPrepState.Waiting,
  Atlas.VideoPrepState.Uploading,
  Atlas.VideoPrepState.Transcoding,
]);

const useReflectionPlaylist = (args: {
  reflectionId: Atlas.ReflectionID;
}) => {
  const { reflectionId } = args;

  const videosQuery = useReflectionVideosQuery({ reflectionId, per_page: -1 });
  const playlistsQuery = useFindReflectionPlaylistQuery(reflectionId);

  const isVideoPrepping = (() => {
    const videos = videosQuery.data?.data;
    if (!videos) { return false; }

    const prepStates = videos.reduce((acc, video) => {
      video.channels.forEach((channel) => {
        acc.add(channel.prep_state);
      });

      return acc;
    }, new Set<Atlas.VideoPrepState>());

    return [...prepStates].some((prepState) => pendingPrepStates.has(prepState));
  })();

  const { refetch: refetchVideos } = videosQuery;
  useEffect(() => {
    if (!isVideoPrepping) { return undefined; }

    let mounted = true;
    const interval = setInterval(() => {
      if (!mounted) { return; }

      void refetchVideos();
    }, 5000);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [isVideoPrepping, refetchVideos]);

  const [wasVideoPrepping, setWasVideoPrepping] = useState(false);

  useEffect(() => {
    setWasVideoPrepping(isVideoPrepping);
  }, [isVideoPrepping, wasVideoPrepping]);

  const stalePlaylist = wasVideoPrepping && !isVideoPrepping;

  const { refetch: refetchPlaylist } = playlistsQuery;
  useEffect(() => {
    if (!stalePlaylist) { return; }

    void refetchPlaylist();
  }, [refetchPlaylist, stalePlaylist]);

  return {
    videosQuery,
    playlistsQuery,
  };
};

export default useReflectionPlaylist;
