import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import useRandomId from '../../../hooks/useRandomId';

export interface MultipleChoiceFormAnswer {
  name: string;
  value?: Atlas.Choice[] | Atlas.Choice;
}

interface MultipleChoiceQuestionProps {
  readOnly?: boolean;
  question: Atlas.FormElement;
  answer?: MultipleChoiceFormAnswer;
  showValidation?: boolean;
  onAnswerChange?(fa: MultipleChoiceFormAnswer): void;
}

const MultipleChoiceQuestion = (props: MultipleChoiceQuestionProps): JSX.Element => {
  const { question, answer, readOnly, showValidation } = props,
        { field } = question;

  if (!('multiple_choice' in field.type)) {
    throw 'FormFieldType is incompatible with MultipleChoiceFormFieldType';
  }

  const { choices, allow_multiple: allowMultiple } = field.type.multiple_choice;
  const hasValue = Array.isArray(answer?.value) ? answer?.value.length > 0 : !!answer?.value;
  const showError = showValidation && field.required && !hasValue;

  const handleCheckboxChange = (choice: Atlas.Choice) => () => {
    if (readOnly) { return; }

    let value: Atlas.Choice[] | Atlas.Choice | undefined;
    let values: Atlas.Choice[];

    if (typeof answer?.value === 'string') {
      values = [answer.value];
    } else {
      values = answer?.value || [];
    }

    if (values.includes(choice)) {
      value = values.filter(c => c !== choice);
    } else {
      value = [...values, choice];
    }

    if (props.onAnswerChange) {
      props.onAnswerChange({
        name: question.field.name,
        ...answer,
        value
      });
    }
  };

  const handleDropdownChange = (selectedChoice: Atlas.Choice) => {
    if (readOnly) return;
  
    const value = selectedChoice === answer?.value ? undefined : selectedChoice;
  
    if (props.onAnswerChange) {
      props.onAnswerChange({
        name: question.field.name,
        ...answer,
        value,
      });
    }
  };

  const isSelected = (choice: Atlas.Choice) => {
    if (Array.isArray(answer?.value)) {
      return answer?.value.includes(choice);
    }
    return answer?.value === choice;
  };

  const inputType = allowMultiple ? 'checkbox' : 'dropdown';

  switch (inputType) {
    case 'checkbox': {
      return (
        <div className={`tw-p-1 ${showError ? 'tw-border tw-border-error/40 tw-ring-1 tw-ring-error/40 tw-rounded-lg' : 'tw-border-base-300'}`}>
          <fieldset>
            {choices.map(c => (
              <label key={c} className={`${inputType}`}>
                <input 
                  type={inputType} 
                  checked={isSelected(c)} 
                  onChange={handleCheckboxChange(c)} 
                  readOnly={readOnly}
                />
                {c}
              </label>
            ))}
          </fieldset>
        </div>
      );
    }

    case 'dropdown': {
      return (
        <div className={`tw-p-1 ${showError ? 'tw-border tw-border-error/40 tw-ring-1 tw-ring-error/40 tw-rounded-lg' : 'tw-border-base-300'}`}>
          <select
            value={answer?.value || ""}
            onChange={(e) => handleDropdownChange(e.target.value)}
            disabled={readOnly}
          >
            <option value="" disabled>
              {__("Select an option")}
            </option>
            {choices.map(c => (
              <option key={c} value={c}>
                {c}
              </option>
            ))}
          </select>
        </div>
      );
    }
  }
};

export default MultipleChoiceQuestion;
