import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders, jsonHeaders } from '../../routes';

export const pageRoute = (args: {
  pageId: Atlas.PageID,
}): string => `/api/v0/pages/${args.pageId}`;

export interface FindPageParams {
  pageId: Atlas.PageID;
}

export interface FindPageArguments {
  params: FindPageParams;
}

export interface FindPageResponse {
  data: Atlas.Page;
}

export const findPage = async (
  args: FindPageArguments,
): Promise<FindPageResponse> => {
  const { pageId } = args.params;

  const url = pageRoute({ pageId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findPage');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Page>(body.data);

  return { data };
};

export interface UpdatePageParams {
  pageId: Atlas.PageID;
}

export interface UpdatePageBody {
  title?: Atlas.PageTitle;
  body?: Atlas.PageBody;
  publish?: Atlas.PublishStatus;
  jsFilter?: Atlas.JSFilter;
  updateRole?: Atlas.MemberRole;
}

export interface UpdatePageArguments {
  params: UpdatePageParams;
  body: UpdatePageBody;
}

export interface UpdatePageResponse {
  data: Atlas.Page;
}

export const updatePage = async (
  args: UpdatePageArguments,
): Promise<UpdatePageResponse> => {
  const { pageId } = args.params;

  const url = pageRoute({ pageId });

  const response = await fetch(url, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      title: args.body.title,
      body: args.body.body,
      publish: args.body.publish,
      js_filter: args.body.jsFilter,
      update_role: args.body.updateRole,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updatePage');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Page>(body.data);

  return { data };
};

export interface DestroyPageParams {
  pageId: Atlas.PageID;
}

export interface DestroyPageArguments {
  params: DestroyPageParams;
}

export interface DestroyPageResponse {
  data: Atlas.Page;
}

export const destroyPage = async (
  args: DestroyPageArguments,
): Promise<DestroyPageResponse> => {
  const { pageId } = args.params;

  const url = pageRoute({ pageId });

  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'destroyPage');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Page>(body.data);

  return { data };
};
