import React from 'react';

const SearchResultButton: React.FC<{
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  const { children, onClick } = props;

  return (
    <button
      type="button"
      className="tw-flex tw-items-center tw-gap-4 tw-px-3 tw-py-2 tw-w-full tw-rounded-xl tw-cursor-pointer hover:tw-bg-base-200"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SearchResultButton;
