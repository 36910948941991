import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../common/components/Spinner';
import { OverlayContainer, Overlay } from '../../../common/components/Overlay';

const FormsList = props => {
  const {
    loading,
    forms = [],
    onBackClick: handleBackClick,
    onNextClick: handleNextClick
  } = props;

  if (loading) {
    return (
      <div className="tw-text-center">
        <Spinner color="info" />
      </div>
    );
  }

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-mb-4">
        {forms.map(form => {
          const handleFormClick = event => {
            event.preventDefault();
            props.onFormClick(form);
          };

          return (
            <div key={form.id}>
              <button type="button" className="tw-btn tw-btn-white tw-btn-block tw-block tw-truncate tw-text-left" onClick={handleFormClick}>
                {form.name}
              </button>
            </div>
          );
        })}
      </div>

      <div className="tw-flex tw-justify-center tw-gap-2">
        <button type="button" className="tw-btn tw-btn-sm tw-btn-white" disabled={!handleBackClick} onClick={handleBackClick}>
          {__('Back')}
        </button>

        <button type="button" className="tw-btn tw-btn-sm tw-btn-white" disabled={!handleNextClick} onClick={handleNextClick}>
          {__('Next')}
        </button>
      </div>
    </div>
  );
};

FormsList.propTypes = {
  loading: PropTypes.bool,
  forms: PropTypes.array,
  onFormClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func
};

export default FormsList;
