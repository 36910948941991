import React, {
  PropsWithChildren, useMemo, useState, useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import { useKeyPressEvent } from 'react-use';

interface ModalCloserProps {
  onClose: () => void;
}

const ModalCloser = (props: ModalCloserProps) => {
  const { onClose } = props;

  useKeyPressEvent('Escape', () => {
    onClose();
  });

  return null;
};

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
}

export const Modal = (props: PropsWithChildren<ModalProps>): JSX.Element | null => {
  const { isOpen, onClose, className = '', children } = props;

  const [mounted, setMounted] = useState(isOpen);

  const node = useMemo(() => document.querySelector('body'), []);
  const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
      return undefined;
    }

    const timeout = setTimeout(() => {
      setMounted(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === modalRef) {
      onClose?.();
    }
  };

  const $modal = (
    <div ref={setModalRef} className={`tw-modal ${isOpen ? 'tw-modal-open' : ''} ${className}`} onClick={handleClick}>
      {mounted ? (
        <>
          {onClose ? <ModalCloser onClose={onClose} /> : null}
          {children}
        </>
      ) : null}
    </div>
  );

  if (!node) { return $modal; }

  return ReactDOM.createPortal($modal, node);
};

interface ModalBoxProps {
  className?: string;
}

export const ModalBox = (props: PropsWithChildren<ModalBoxProps>) => {
  const { className = '', children } = props;

  return (
    <div className={`tw-modal-box ${className}`}>
      {children}
    </div>
  );
};

interface ModalHeaderProps {
  onClose?: () => void;
}

export const ModalHeader = (props: PropsWithChildren<ModalHeaderProps>) => {
  const { children, onClose } = props;

  return (
    <div className="tw-flex tw-justify-between tw-items-start tw-mb-4 tw-gap-4">
      <p className="tw-text-2xl tw-font-bold tw-text-primary">
        {children}
      </p>

      {onClose ? (
        <button
          type="button"
          className="tw-btn tw-btn-ghost tw-btn-sm tw-btn-square tw-text-lg"
          onClick={() => onClose()}
        >
          &#x2715;
        </button>
      ) : null}
    </div>
  );
};
