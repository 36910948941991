import styled from '@emotion/styled';

export const Alert = styled.div`
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
`;

export const AlertPrimary = styled(Alert)`
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
`;

export const AlertInfo = styled(Alert)`
  color: #3a87ad;
  background-color: #d1ecf1;
  border-color: #bee5eb;
`;
