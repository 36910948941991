import React from 'react';
import { Modal, ModalBox, ModalHeader } from '../../../common/components/TailwindModal';
import * as Atlas from '../../../common/types/Atlas';
import VideoEditor from './VideoEditor';

interface VideoEditorModalProps {
  isOpen: boolean;
  toggle: () => void;
  videoId: Atlas.VideoID;
}

const VideoEditorModal = (props: VideoEditorModalProps): JSX.Element => {
  const { isOpen, toggle, videoId } = props;

  return (
    <Modal isOpen={isOpen} className="tw-modal-bottom md:tw-modal-middle">
      <ModalBox className="tw-max-w-screen-md">
        <ModalHeader onClose={toggle}>
          {__('Edit Video')}
        </ModalHeader>

        <VideoEditor
          videoId={videoId}
          onCancel={toggle}
        />
      </ModalBox>
    </Modal>
  );
};

export default VideoEditorModal;
