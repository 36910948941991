import { useMutation } from 'react-query';
import { destroyComment, DestroyCommentArguments, DestroyCommentResponse } from '../../../api/comments';
import { FindVideoCommentsResponse } from '../../../api/videos/comments';
import { queryClient } from '../../withQueryClient';

const useDestroyCommentMutation = () => {
  const mutation = useMutation<DestroyCommentResponse, unknown, DestroyCommentArguments, unknown>(
    destroyComment,
    {
      onSuccess: async (result) => {
        const comment = result.data;

        if (comment.video_id) {
          await queryClient.invalidateQueries<FindVideoCommentsResponse>(['videos', comment.video_id, 'comments']);
        }
      },
    },
  );

  return mutation;
};

export default useDestroyCommentMutation;
