import React, { useEffect, useState } from 'react';
import { Modal, ModalBox } from '../../../../common/components/TailwindModal';
import useUpdatePageMutation from '../../../../common/hooks/api/pages/useUpdatePageMutation';
import useCurrentUserQuery from '../../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../../common/types/Atlas';
import { isPresent } from '../../../../common/utils/utils';

interface EditPagePublishFormProps {
  page: Atlas.Page;
  onCancel: () => void;
}

const EditPagePublishForm = (props: EditPagePublishFormProps) => {
  const { page, onCancel } = props;

  const [pagePublish, setPagePublish] = useState(page.publish);

  useEffect(() => {
    setPagePublish(page.publish);
  }, [page.publish]);

  const currentUserQuery = useCurrentUserQuery();

  const isPublic = page.publish === Atlas.PublishStatus.Public;
  const isSuperAdmin = currentUserQuery.data?.admin === Atlas.AdministratorType.Super;

  const options = [isPublic || isSuperAdmin ? ({
    value: Atlas.PublishStatus.Public,
    label: __('Public'),
    disabled: !isSuperAdmin,
  }) : undefined, {
    value: Atlas.PublishStatus.Published,
    label: __('Published'),
  }, {
    value: Atlas.PublishStatus.Unpublished,
    label: __('Unpublished'),
  }].filter(isPresent);

  const updatePage = useUpdatePageMutation({ pageId: page.id });

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (updatePage.isLoading) { return; }

    void updatePage.mutateAsync({
      publish: pagePublish,
    }).then(() => {
      onCancel();
    });
  };

  const handlePagePublishChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    switch (value) {
      case Atlas.PublishStatus.Public:
      case Atlas.PublishStatus.Published:
      case Atlas.PublishStatus.Unpublished: {
        break;
      }

      default: return;
    }

    setPagePublish(value);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="tw-form-control tw-w-full tw-mb-4">
        <select
          required
          className="tw-select tw-select-bordered tw-w-full"
          value={pagePublish}
          onChange={handlePagePublishChange}
          data-testid="page-publish-select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="tw-flex tw-justify-end tw-gap-2">
        <button
          type="button"
          className="tw-btn tw-btn-link"
          onClick={onCancel}
        >
          {__('Cancel')}
        </button>

        <button
          type="submit"
          className="tw-btn tw-btn-success"
          data-testid="page-publish-save-button"
        >
          {__('Save')}
        </button>
      </div>
    </form>
  );
};

interface PublishPageButtonProps {
  page: Atlas.Page;
}

const PublishPageButton = (props: PublishPageButtonProps) => {
  const { page } = props;

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((o) => !o);
  };

  return (
    <>
      <button type="button" onClick={toggle} data-testid="publish-page-button">
        {(() => {
          switch (page.publish) {
            case Atlas.PublishStatus.Public:
            case Atlas.PublishStatus.Published: {
              return __('Unpublish');
            }

            case Atlas.PublishStatus.Unpublished:
            default: {
              return __('Publish');
            }
          }
        })()}
      </button>

      <Modal isOpen={open} onClose={toggle}>
        <ModalBox>
          <p className="tw-text-xl tw-font-bold tw-mb-4">
            {(() => {
              switch (page.type) {
                case Atlas.PageType.Heading: {
                  return __('Publish Heading');
                }

                default: {
                  return __('Publish Page');
                }
              }
            })()}
          </p>

          <EditPagePublishForm
            page={page}
            onCancel={toggle}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

export default PublishPageButton;
