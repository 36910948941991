import React from 'react';
import { ResourceSearchResult } from '../../../common/types';
import LoadMoreButton from './load-more-button';
import SearchGroupResult from './../../list-items/search-group-result';
import SearchReflectionResult from './../../list-items/search-reflection-result';

const SearchResultsSuccess: React.FC<{
  results: ResourceSearchResult[];
  hasMoreResults?: boolean;
  isLoading: boolean;
  onLoadMoreClick: () => void;
  onResultClick: (result: ResourceSearchResult) => void;
}> = (props) => {
  const {
    results,
    isLoading,
    hasMoreResults,
    onLoadMoreClick,
    onResultClick,
  } = props;

  if (results.length === 0) {
    return (
      <div className="tw-p-3 tw-flex tw-justify-center tw-items-center tw-text-accent">
        {__('No matches found')}
      </div>
    );
  }

  return (
    <>
      {results.map((result) => {
        switch (result.type) {
          case 'group': return (
            <SearchGroupResult
              key={`${result.type}-${result.data.id}`}
              group={result.data}
              onClick={() => onResultClick(result)}
            />
          );

          case 'reflection': return (
            <SearchReflectionResult
              key={`${result.type}-${result.data.id}`}
              reflection={result.data}
              onClick={() => onResultClick(result)}
            />
          );

          default: return null;
        }
      })}

      {hasMoreResults ? (
        <LoadMoreButton
          isLoading={isLoading}
          onLoadMore={onLoadMoreClick}
        />
      ) : null}
    </>
  );
};

export default SearchResultsSuccess;
