import React from 'react';
import {
  ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip,
} from 'recharts';
import { colors } from '../../../common/utils/colors';
import { truncate } from '../../../common/utils/utils';
import * as Atlas from '../../../common/types/Atlas';
import { FormReportingFilter } from '../types';

interface TextFormFieldStatsProps {
  field: Atlas.FormField;
  data: Array<{
    filter: FormReportingFilter;
    overview: Atlas.FormFieldOverview;
  }>;
}

const TextFormFieldStats = (props: TextFormFieldStatsProps): JSX.Element => {
  const { field } = props;

  const data = props.data.reduce<Array<{
    filterName: string;
    count: Atlas.AnswerCount;
  }>>((acc, datum) => {
    if ('text' in datum.overview.stats) {
      acc.push({
        filterName: datum.filter.name,
        count: datum.overview.stats.text,
      });
    }

    return acc;
  }, []);

  return (
    <div>
      <p className="tw-text-gray-600">{__('Text')}</p>
      <p className="tw-mt-0 tw-mb-4 tw-font-bold tw-text-lg">{truncate(field.label, { maxLength: 500 })}</p>

      <hr className="tw-my-4" />

      <ResponsiveContainer height={200} width="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="filterName" name={__('Filter')} />
          <YAxis allowDecimals={false} type="number" name={__('Count')} />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" name={__('Responses')} fill={colors[0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TextFormFieldStats;
