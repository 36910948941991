import styled from '@emotion/styled';

export const Overlay = styled.figure`
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const OverlayContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  > :not(${Overlay}) {
    opacity: 1;
    transition: opacity 100ms;
  }

  &.active > ${Overlay} {
    @keyframes fadeOverlayIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes delayedFadeOverlayIn {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    display: flex;
    animation: ${({ delay = false }) => delay ? 'delayedFadeOverlayIn 1s' : 'fadeOverlayIn 500ms'};
  }

  &.active > :not(${Overlay}) {
    opacity: .25;
  }
`;
