import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject, parseModelObjectArray } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders, jsonHeaders, Metadata, PaginationParams, searchParams, ViewID } from '../../routes';

export const reflectionSharesRoute = (args: {
  reflectionId: Atlas.ReflectionID;
}): string => (
  `/api/v0/reflections/${args.reflectionId}/shares`
);

export const reflectionShareRoute = (args: {
  reflectionId: Atlas.ReflectionID;
  shareId: Atlas.ShareID;
}): string => (
  `/api/v0/reflections/${args.reflectionId}/shares/${args.shareId}`
);

export type FindReflectionSharesParams = {
  reflectionId: Atlas.ReflectionID;
} & PaginationParams;

export interface FindReflectionSharesArgs {
  params: FindReflectionSharesParams;
}

export interface FindReflectionSharesResponse {
  data: Atlas.ExpandedShare[];
  metadata: Metadata;
}

export const findReflectionShares = async (
  args: FindReflectionSharesArgs,
): Promise<FindReflectionSharesResponse> => {
  const { reflectionId } = args.params;

  const url = [
    reflectionSharesRoute({ reflectionId }),
    searchParams({ view: ViewID.Expanded }),
  ].join('?');

  const r = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(r.status, 200, 300)) {
    throw new ApiError(r.status, 'findReflectionShares');
  }

  const body = await r.json();
  const data = parseModelObjectArray(body.data) as Atlas.ExpandedShare[];
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};

export interface CreateReflectionShareParams {
  reflectionId: Atlas.ReflectionID;
}

export interface CreateReflectionShareBody {
  shareeGroupId?: Atlas.GroupID;
  shareeUserId?: Atlas.UserID;
  message?: Atlas.Message;
}

export interface CreateReflectionShareArgs {
  params: CreateReflectionShareParams;
  body: CreateReflectionShareBody;
}

export interface CreateReflectionShareResponse {
  data: Atlas.Share;
}

export const createReflectionShare = async (
  args: CreateReflectionShareArgs,
): Promise<CreateReflectionShareResponse> => {
  const { reflectionId } = args.params;
  const { shareeGroupId, shareeUserId, message } = args.body;

  const payload = {
    sharee_group_id: shareeGroupId,
    sharee_user_id: shareeUserId,
    message,
  };

  const url = reflectionSharesRoute({ reflectionId });

  const r = await fetch(url, {
    method: 'POST',
    headers: generateFetchHeaders(),
    body: JSON.stringify(payload),
  });

  if (!inRange(r.status, 200, 300)) {
    throw new ApiError(r.status, 'createReflectionShare');
  }

  const body = await r.json();
  const data = parseModelObject(body.data) as Atlas.Share;

  return { data };
};

export interface DestroyReflectionShareParams {
  reflectionId: Atlas.ReflectionID;
  shareId: Atlas.ShareID;
}

export interface DestroyReflectionShareArgs {
  params: DestroyReflectionShareParams;
}

export interface DestroyReflectionShareResponse {
  data: Atlas.Share;
}

export const destroyReflectionShare = async (
  args: DestroyReflectionShareArgs,
): Promise<DestroyReflectionShareResponse> => {
  const { reflectionId, shareId } = args.params;

  const url = reflectionShareRoute({ reflectionId, shareId });

  const r = await fetch(url, {
    method: 'DELETE',
    headers: generateFetchHeaders(),
  });

  if (!inRange(r.status, 200, 300)) {
    throw new ApiError(r.status, 'destroyReflectionShare');
  }

  const body = await r.json();
  const data = parseModelObject(body.data) as Atlas.Share;

  return { data };
};
