import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select/async';
import { addFormReporter, findFormReporters, removeFormReporter } from '../../common/api/forms/reporters';
import { search, SearchResult } from '../../common/api/search/legacy';
import { selectComponents as searchResultSelectComponents } from '../../common/components/select/SearchResult';
import * as Atlas from '../../common/types/Atlas';
import { useCurrentUser } from '../../common/hooks/withCurrentUser';
import { OnChangeValue } from 'react-select';
import { portraitPlaceholderUrl } from '../../common/utils/placeholders';
import Spinner from '../../common/components/Spinner';

interface FormReportersProps {
  formId: Atlas.FormID;
  form?: Atlas.Form;
}

interface Option {
  value: number;
  result: SearchResult;
}

const FormReporters = (props: FormReportersProps): JSX.Element => {
  const { formId, form } = props;

  const { user: currentUser } = useCurrentUser();

  const [state, setState] = useState<{
    reporters: Atlas.FormUser[];
    reportersLoading: boolean;
    value: Option | null;
  }>({
    reporters: [],
    reportersLoading: true,
    value: null,
  });

  const loadFormReporters = useCallback(() => {
    setState((s) => ({ ...s, reportersLoading: true }));
    void findFormReporters({ params: { formId } }).then(({ data }) => {
      setState((s) => ({ ...s, reporters: data, reportersLoading: false }));
    });
  }, [formId]);

  useEffect(() => {
    loadFormReporters();
  }, [loadFormReporters]);

  const searchUsers = (q: string) => {
    if (q.length < 2) {
      return Promise.resolve<Option[]>([]);
    }

    return search({ params: { q, users: true } }).then((resp) => resp.results.filter((r) => !state.reporters.some((u) => u.id === r.atlas_id)).map((result) => ({
      value: result.atlas_id,
      result,
    })));
  };

  const handleAddReporterChange = (option: OnChangeValue<Option, false>) => {
    if (!option) { return; }

    setState((s) => ({ ...s, value: option }));

    void addFormReporter({ params: { formId, userId: option.value } }).then(() => {
      setState((s) => ({ ...s, value: null }));
    }).then(() => {
      loadFormReporters();
    });
  };

  const handleRemoveReporterClick = (user: Atlas.FormUser) => () => {
    if (!window.confirm(`Remove ${user.first_name} ${user.last_name} from reporters?`)) { return; }

    setState((s) => ({ ...s, reporters: s.reporters.filter((u) => u.id !== user.id) }));

    removeFormReporter({ params: { formId, userId: user.id } }).catch(() => {
      setState((s) => ({ ...s, reporters: [user, ...s.reporters] }));
    });
  };

  const renderIcon = (url?: string) => (
    <div className="tw-avatar">
      <div className="tw-w-7 tw-h-7 tw-rounded-full">
        <img src={url || 'https://s3-eu-west-1.amazonaws.com/atlas-ruby/portrait_placeholder.png'} />
      </div>
    </div>
  );

  return (
    <div className="tw-rounded-xl tw-bg-base-100 tw-shadow tw-p-4">
      <h5 className="tw-mt-0 tw-mb-3 tw-font-bold">
        {__('Reporters')}
      </h5>

      {state.reporters.length || state.reportersLoading ? (
        <ul className="tw-mb-3">
          {state.reporters.map((user) => (
            <li key={user.id}>
              <div className="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-1">
                <div className="tw-flex tw-items-center tw-gap-3">
                  {renderIcon(user.avatar_s3uri?.url)}
                  <div className="tw-truncate">
                    {user.first_name}
                    {' '}
                    {user.last_name}
                  </div>
                </div>

                {form && form.user_id === currentUser?.id && form.user_id !== user.id ? (
                  <button type="button" className="tw-btn tw-btn-ghost tw-btn-xs" onClick={handleRemoveReporterClick(user)}>
                    <i className="fa fa-times" />
                  </button>
                ) : null}
              </div>
            </li>
          ))}

          {state.reportersLoading ? (
            <li className="bg-light tw-px-0">
              <div className="tw-flex tw-items-center tw-justify-center tw-py-3 tw-px-2">
                <Spinner size="sm" color="info" />
              </div>
            </li>
          ) : null}
        </ul>
      ) : null}

      <Select
        isLoading={!!state.value}
        placeholder={__('Add reporter')}
        loadOptions={searchUsers}
        components={searchResultSelectComponents}
        noOptionsMessage={({ inputValue }) => (inputValue.length < 2 ? __('Please enter 2 or more characters') : __('No users found'))}
        value={state.value}
        onChange={handleAddReporterChange}
      />
    </div>
  );
};

export default FormReporters;
