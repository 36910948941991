import TextareaAutosize from 'react-textarea-autosize';

import styled from '@emotion/styled';

export const Textarea = styled(TextareaAutosize)`
  box-sizing: border-box;
  display: block;
  resize: none;
  width: 100%;
`;

export const ReplyTextarea = styled(TextareaAutosize)`
    box-sizing: border-box;
    display: block;
    resize: none;
    width: 100%;
`

const Input = styled(TextareaAutosize)`
  background: rgba(0, 0, 0, .03);
  border-radius: 1rem;
  border: 0;
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  margin-bottom: 0;
  padding: .5rem 1rem;
  resize: none;
  width: 100%;

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

export default Input;
