import React from 'react';
import { Modal, ModalBox, ModalHeader } from '../../../common/components/TailwindModal';
import useFindFeatureToggleQuery from '../../../common/hooks/api/features/useFindFeatureToggleQuery';
import useFindReflectionQuery from '../../../common/hooks/api/reflections/useFindReflectionQuery';
import * as Atlas from '../../../common/types/Atlas';
import ReflectionDetailsForms from './reflection-forms/ReflectionDetailsForms';
import ReflectionEmbedCodeContainer from './reflection-forms/ReflectionEmbedCodeContainer';
import ReflectionPrivacyForms from './reflection-forms/ReflectionPrivacyForms';
import ReflectionSharingForm from './reflection-forms/ReflectionSharingForm';
import { ReflectionModal } from './store/reflection-modals.store';
import useReflectionStore from './store/useReflectionStore';
import VideoEditorModal from '../../video-editor/v2/VideoEditorModal';

const ReflectionModals: React.FC<{
  reflectionId: Atlas.ReflectionID;
}> = (props) => {
  const { reflectionId } = props;

  const {
    modalState, changeModal,
  } = useReflectionStore((state) => ({
    modalState: state.modalState,
    changeModal: state.changeModal,
  }));

  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const reflection = reflectionQuery.data?.data;

  const userOwned = (() => {
    if (!reflection) { return false; }
    if (reflection.group_id) { return false; }

    return reflection.relationship === Atlas.Relationship.Owner;
  })();

  const reflectionEmbedToggleQuery = useFindFeatureToggleQuery({
    featureToggle: 'reflection_embed',
  });
  const reflectionEmbedToggleEnabled = reflectionEmbedToggleQuery.data?.enabled ?? false;

  return (
    <>
      <Modal isOpen={modalState?.modal === ReflectionModal.Sharing} onClose={() => changeModal(null)}>
        <ModalBox>
          <ModalHeader onClose={() => changeModal(null)}>
            {__('Reflection Sharing')}
          </ModalHeader>
          <ReflectionSharingForm reflectionId={reflectionId} />
        </ModalBox>
      </Modal>

      {userOwned ? (
        <Modal isOpen={modalState?.modal === ReflectionModal.Privacy} onClose={() => changeModal(null)}>
          <ModalBox>
            <ModalHeader onClose={() => changeModal(null)}>
              {__('Reflection Privacy')}
            </ModalHeader>
            <ReflectionPrivacyForms reflectionId={reflectionId} />
          </ModalBox>
        </Modal>
      ) : null}

      {userOwned && reflectionEmbedToggleEnabled ? (
        <Modal isOpen={modalState?.modal === ReflectionModal.Embed} onClose={() => changeModal(null)}>
          <ModalBox>
            <ModalHeader onClose={() => changeModal(null)}>
              {__('Reflection Embed')}
            </ModalHeader>

            <ReflectionEmbedCodeContainer reflectionId={reflectionId} />
          </ModalBox>
        </Modal>
      ) : null}

      <Modal isOpen={modalState?.modal === ReflectionModal.Details} onClose={() => changeModal(null)}>
        <ModalBox>
          <ModalHeader onClose={() => changeModal(null)}>
            {__('Reflection Details')}
          </ModalHeader>
          <ReflectionDetailsForms reflectionId={reflectionId} readOnly={!userOwned} />
        </ModalBox>
      </Modal>

      {modalState?.modal === ReflectionModal.EditVideo ? (
        <VideoEditorModal
          isOpen
          toggle={() => changeModal(null)}
          videoId={modalState.videoId}
        />
      ) : null}
    </>
  );
};

export default ReflectionModals;
