/* eslint-disable react/jsx-props-no-spreading */
import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const col = cva('', {
  variants: {
    xs: {
      1: 'col-1',
      2: 'col-2',
      3: 'col-3',
      4: 'col-4',
      5: 'col-5',
      6: 'col-6',
      7: 'col-7',
      8: 'col-8',
      9: 'col-9',
      10: 'col-10',
      11: 'col-11',
      12: 'col-12',
      auto: 'col-auto',
    },
    sm: {
      1: 'col-sm-1',
      2: 'col-sm-2',
      3: 'col-sm-3',
      4: 'col-sm-4',
      5: 'col-sm-5',
      6: 'col-sm-6',
      7: 'col-sm-7',
      8: 'col-sm-8',
      9: 'col-sm-9',
      10: 'col-sm-10',
      11: 'col-sm-11',
      12: 'col-sm-12',
      auto: 'col-sm-auto',
    },
    md: {
      1: 'col-md-1',
      2: 'col-md-2',
      3: 'col-md-3',
      4: 'col-md-4',
      5: 'col-md-5',
      6: 'col-md-6',
      7: 'col-md-7',
      8: 'col-md-8',
      9: 'col-md-9',
      10: 'col-md-10',
      11: 'col-md-11',
      12: 'col-md-12',
      auto: 'col-md-auto',
    },
    lg: {
      1: 'col-lg-1',
      2: 'col-lg-2',
      3: 'col-lg-3',
      4: 'col-lg-4',
      5: 'col-lg-5',
      6: 'col-lg-6',
      7: 'col-lg-7',
      8: 'col-lg-8',
      9: 'col-lg-9',
      10: 'col-lg-10',
      11: 'col-lg-11',
      12: 'col-lg-12',
      auto: 'col-lg-auto',
    },
    xl: {
      1: 'col-xl-1',
      2: 'col-xl-2',
      3: 'col-xl-3',
      4: 'col-xl-4',
      5: 'col-xl-5',
      6: 'col-xl-6',
      7: 'col-xl-7',
      8: 'col-xl-8',
      9: 'col-xl-9',
      10: 'col-xl-10',
      11: 'col-xl-11',
      12: 'col-xl-12',
      auto: 'col-xl-auto',
    },
  },
  compoundVariants: [{
    xs: undefined,
    sm: undefined,
    md: undefined,
    lg: undefined,
    xl: undefined,
    class: 'col',
  }],
});

export type ColVariantProps = VariantProps<typeof col>;

type DivProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Col = ({
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  ...props
}: ColVariantProps & DivProps) => (
  <div
    {...props}
    className={cx(className, col({
      xs, sm, md, lg, xl,
    }))}
  />
);
