import React, { useEffect, useCallback } from 'react';
import * as Atlas from '../../../../common/types/Atlas';

const ordinals = Array.from(Array(11), (_, i) => i);
const minOrdinals = ordinals.slice(0, -1);
const maxOrdinals = ordinals.slice(1);

interface FormRatingElementProps {
  formElement: Atlas.FormElement;
  isEditingLimited?: boolean;
  onChange: (changes: { field: Partial<Atlas.FormField> }) => void;
}

const FormRatingElement = (props: FormRatingElementProps): JSX.Element | null => {
  const { formElement, isEditingLimited, onChange } = props;
  const { field } = formElement;

  const {
    max_label,
    max_ordinal,
    min_label,
    min_ordinal,
  } = ((): Partial<Atlas.RatingFormField> => {
    if (!('rating' in field.type)) { return {}; }

    return field.type.rating;
  })();

  const changeRating = useCallback((changes: Partial<Atlas.RatingFormField>) => {
    if (!onChange) { return; }
    if (!('rating' in field.type)) { return; }

    onChange({
      field: {
        ...field,
        type: {
          ...field.type,
          rating: {
            ...field.type.rating,
            ...changes,
          },
        },
      },
    });
  }, [field, onChange]);

  useEffect(() => {
    if (
      typeof max_label === 'string'
      && Number.isInteger(max_ordinal)
      && typeof min_label === 'string'
      && Number.isInteger(min_ordinal)
    ) { return; }

    changeRating({
      max_label: max_label ?? __('High'),
      max_ordinal: max_ordinal ?? ordinals[ordinals.length - 1],
      min_label: min_label ?? __('Low'),
      min_ordinal: min_ordinal ?? ordinals[0],
    });
  }, [min_ordinal, max_ordinal, max_label, min_label, changeRating]);

  if (!('rating' in field.type)) {
    return null;
  }

  const handleRatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    changeRating({
      [name]: value,
    });
  };

  const handleMinChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const min = Number(value);
    const max = Math.max(min + 1, max_ordinal ?? 10);

    changeRating({
      min_ordinal: min,
      max_ordinal: max,
    });
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const max = Number(value);
    const min = Math.min(max - 1, min_ordinal ?? 0);

    changeRating({
      min_ordinal: min,
      max_ordinal: max,
    });
  };

  return (
    <div className="tw-flex tw-gap-4 tw-flex-wrap tw-text-base">
      <div>
        <div className="tw-mb-2 tw-font-semibold">
          {__('Minimum')}
        </div>

        <div className="tw-join">
          <select
            className="tw-select tw-select-bordered tw-select-sm tw-w-16 tw-join-item"
            name="min_ordinal"
            value={min_ordinal}
            disabled={isEditingLimited}
            onChange={handleMinChange}
          >
            {minOrdinals.map((o) => (
              <option key={o}>{o}</option>
            ))}
          </select>
          <input
            placeholder={__('Enter min label')}
            className="tw-input tw-input-bordered tw-input-sm tw-join-item"
            name="min_label" value={min_label}
            disabled={isEditingLimited}
            onChange={handleRatingChange}
          />
        </div>
      </div>

      <div>
        <div className="tw-mb-2 tw-font-semibold">
          {__('Maximum')}
        </div>

        <div className="tw-join">
          <select
            className="tw-select tw-select-bordered tw-select-sm tw-w-16 tw-join-item"
            name="max_ordinal"
            value={max_ordinal}
            disabled={isEditingLimited}
            onChange={handleMaxChange}
          >
            {maxOrdinals.map((o) => (
              <option key={o}>{o}</option>
            ))}
          </select>
          <input
            placeholder={__('Enter max label')}
            className="tw-input tw-input-bordered tw-input-sm tw-join-item"
            name="max_label" value={max_label}
            disabled={isEditingLimited}
            onChange={handleRatingChange}
          />
        </div>
      </div>
    </div>
  );
};

export default FormRatingElement;
