import React from 'react';
import CommentResourceLink from './CommentResourceLink';
import * as Atlas from '../../../../../common/types/Atlas';
import { groupTypeLabel } from '../../../../../common/utils/group-info.utils';

const CommentResourceLinkGroup: React.FC<{
  name: Atlas.GroupName;
  type?: Atlas.GroupType;
  iconUrl?: string;
  href?: string;
  inaccessible?: boolean;
}> = (props) => {
  const {
    name,
    type,
    iconUrl = '/assets/img/reflections/display-picture-group-blue.png',
    href,
    inaccessible,
  } = props;

  return (
    <CommentResourceLink
      type={groupTypeLabel(type)}
      name={name}
      icon={
        <div className="tw-flex tw-justify-center">
          <div className="tw-rounded-lg tw-overflow-hidden tw-w-10">
            <img src={iconUrl} alt={__('Group avatar')} />
          </div>
        </div>
      }
      href={href}
      inaccessible={inaccessible}
    />
  );
};

export default CommentResourceLinkGroup;
