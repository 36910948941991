import React, { useRef, useEffect, useMemo } from 'react';
import * as Atlas from '../../../../common/types/Atlas';

type ChangeChoicesFunc = (choices: Atlas.Choice[]) => Atlas.Choice[];

interface FormMultipleChoiceElementProps {
  formElement: Atlas.FormElement;
  onChange?: (changes: { field: Partial<Atlas.FormField> }) => void;
  isEditingLimited?: boolean;
}

const FormMultipleChoiceElement = (props: FormMultipleChoiceElementProps): JSX.Element | null => {
  const { formElement, onChange, isEditingLimited } = props;
  const { field } = formElement;

  const choices = useMemo(() => {
    if (!('multiple_choice' in field.type)) {
      return [];
    }

    return field.type.multiple_choice.choices;
  }, [field.type]);

  const choicesWithPlaceholder = useMemo(() => [...choices, ''], [choices]);

  const choiceInputsRef = useRef<HTMLInputElement[]>([]);
  useEffect(() => {
    choiceInputsRef.current = choiceInputsRef.current.slice(0, choicesWithPlaceholder.length)
  }, [choicesWithPlaceholder]);

  if (!('multiple_choice' in field.type)) {
    return null;
  }

  const { multiple_choice } = field.type;
  const { allow_multiple } = multiple_choice;

  const handleAllowMultipleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;

    if (!onChange) { return; }

    onChange({
      field: {
        ...field,
        type: {
          ...field.type,
          multiple_choice: {
            ...multiple_choice,
            allow_multiple: checked,
          },
        },
      },
    })
  };

  const changeChoices = (func: ChangeChoicesFunc) => {
    if (!onChange) { return; }

    onChange({
      field: {
        ...field,
        type: {
          ...field.type,
          multiple_choice: {
            ...multiple_choice,
            choices: func(multiple_choice.choices),
          },
        },
      },
    });
  };

  const removeChoice = (i: number) => {
    changeChoices((prevChoices) => {
      const nextChoices = [...prevChoices];
      nextChoices.splice(i, 1);
      return nextChoices;
    });
  };

  const handleChoiceChange = (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    changeChoices((prevChoices) => {
      const nextChoices = [...prevChoices];
      nextChoices[i] = value;
      return nextChoices;
    });
  };

  const handleChoiceBlur = (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value) { return; }

    removeChoice(i);
  };

  const handleChoiceRemove = (i: number) => () => {
    removeChoice(i);
  };

  const handleChoiceKeyPress = (i: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') { return; }

    const nextInput = choiceInputsRef.current[i + 1];
    if (!nextInput) { return; }

    nextInput.focus();
  };

  return (
    <>
      <label className="checkbox tw-mb-0 tw-text-base">
        <input type="checkbox" checked={allow_multiple} onChange={handleAllowMultipleChange} />
        {__('Allow users to select multiple choices')}
      </label>

      <div className="tw-text-base">
        <div className="tw-mb-2 tw-font-semibold">
          {__('Choices')}
        </div>

        <div className="tw-flex tw-flex-col tw-gap-2">
          {choicesWithPlaceholder.map((c, i) => {
            const isPlaceholder = choices.length === i;

            return (
              <div key={i} className={isPlaceholder ? '' : 'tw-join'}>
                <input
                  ref={(el) => { if (el) { choiceInputsRef.current[i] = el; } }}
                  required={i === 0}
                  className="tw-input tw-input-bordered tw-input-sm tw-join-item"
                  placeholder={`${__('Enter a choice')}...`}
                  name={`choice-${i}`}
                  value={c}
                  disabled={isEditingLimited}
                  onChange={handleChoiceChange(i)}
                  onBlur={handleChoiceBlur(i)}
                  onKeyPress={handleChoiceKeyPress(i)}
                />
                {isPlaceholder ? null : (
                  <button
                    type="button"
                    className="tw-btn tw-btn-square tw-btn-sm tw-join-item"
                    disabled={isEditingLimited}
                    onClick={handleChoiceRemove(i)}
                  >
                    <i className="icon-remove" />
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FormMultipleChoiceElement;
