import * as Atlas from '../../types/Atlas';
import { generateFetchHeaders } from '../routes';
import { inRange } from '../../../common/utils/utils';
import ApiError from '../../../common/api/error';

export const assignmentSyncReplicasRoute = ({ assignmentId }: { assignmentId: Atlas.AssignmentID }): string => `/api/v0/assignments/${assignmentId}/sync_replicas`;

interface assignmentSyncReplicasArgs {
  params: {
    assignmentId: Atlas.AssignmentID;
  };
}

export const assignmentSyncReplicas = async (
  args: assignmentSyncReplicasArgs,
): Promise<void> => {
  const { assignmentId } = args?.params || {};

  const url = assignmentSyncReplicasRoute({ assignmentId });

  const response = await fetch(url, {
    method: 'POST',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'reorderMilestones');
  }
};
