import classNames from 'classnames';
import React from 'react';

const CommentResourceLink: React.FC<{
  type: string;
  name: string;
  icon: JSX.Element;
  href?: string;
  inaccessible?: boolean;
}> = (props) => {
  const {
    type,
    name,
    icon,
    href,
    inaccessible,
  } = props;

  const $content = (
    <>
      <div className="tw-flex tw-gap-4 tw-items-center">
        <div className="tw-w-16 tw-flex-shrink-0">
          {icon}
        </div>

        <div className="tw-flex-1">
          <small className="tw-block tw-text-gray-500 tw-font-medium">
            {type}
          </small>

          <p className={classNames({ 'group-hover:tw-underline': !!href, 'tw-italic': inaccessible })}>
            {name}
          </p>
        </div>

        {href ? (
          <div>
            <i className="fa fa-chevron-right tw-text-gray-500" />
          </div>
        ) : null}
      </div>
    </>
  );

  return href ? (
    <a
      href={href}
      className={`
        tw-block
        tw-border tw-p-4 tw-rounded-xl tw-bg-gray-50
        tw-group
        hover:tw-bg-gray-100 tw-cursor-pointer
      `}
    >
      {$content}
    </a>
  ) : (
    <div className="tw-border tw-p-4 tw-rounded-xl tw-bg-gray-50">
      {$content}
    </div>
  );
};

export default CommentResourceLink;
