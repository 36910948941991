import * as Atlas from '../../../types/Atlas';
import { parseAssignmentJson } from '../../../utils/assignment-parser';
import {
  generateFetchHeaders, jsonHeaders, Metadata, PaginationParams, searchParams,
} from '../../routes';

export const groupAssignmentsRoute = ({ groupId }: { groupId: Atlas.GroupID }): string => `/groups/${groupId}/assignments`;
export const groupAssignmentRoute = ({ groupId, assignmentId }: { groupId: Atlas.GroupID, assignmentId: Atlas.AssignmentID }): string => `/groups/${groupId}/assignments/${assignmentId}`;
export const cloneGroupAssignmentRoute = ({ groupId, assignmentId }: { groupId: Atlas.GroupID, assignmentId: Atlas.AssignmentID }): string => `/groups/${groupId}/assignments/${assignmentId}/clone`;

export type FindGroupAssignmentsParams = PaginationParams & {
  groupId: Atlas.GroupID;
}

export interface FindGroupAssignmentsArgs {
  params: FindGroupAssignmentsParams;
}

export interface FindGroupAssignmentsResponse {
  data: Atlas.ExpandedAssignment[]; 
  metadata: Metadata;
}

export const findGroupAssignments = (
  args: FindGroupAssignmentsArgs,
): Promise<FindGroupAssignmentsResponse> => {
  const { params } = args;

  const url = [
    groupAssignmentsRoute({ groupId: params.groupId }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] findGroupAssignments failed`; }
    return r.json();
  }).then(({ data, metadata }) => ({
    data: data.map(parseAssignmentJson),
    metadata: metadata as Metadata,
  }));
};

interface FindGroupAssignmentArgs {
  params: {
    groupId: Atlas.GroupID;
    assignmentId: Atlas.AssignmentID;
  };
}

export interface FindGroupAssignmentResponse {
  data: Atlas.ExpandedAssignment;
}

export const findGroupAssignment = (
  args: FindGroupAssignmentArgs,
): Promise<FindGroupAssignmentResponse> => {
  const { groupId, assignmentId } = args?.params || {};

  const url = groupAssignmentRoute({ groupId, assignmentId });

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] findGroupAssignment failed`; }
    return r.json();
  }).then(({ data }) => ({
    data: parseAssignmentJson(data),
  }));
};

interface CreateGroupAssignmentArgs {
  params: { groupId: Atlas.GroupID; };
  body: {
    assignment: Partial<Atlas.AssignmentSubmission> & {
      name: Atlas.AssignmentName;
    };
  };
}

export const createGroupAssignment = (
  args: CreateGroupAssignmentArgs,
): Promise<{ data: Atlas.Assignment }> => {
  const { groupId } = args?.params || {};

  const url = groupAssignmentsRoute({ groupId });

  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] createGroupAssignment failed`; }
    return r.json();
  }).then(({ data }) => ({
    data: parseAssignmentJson(data),
  }));
};

interface UpdateGroupAssignmentArgs {
  params: {
    groupId: Atlas.GroupID;
    assignmentId: Atlas.AssignmentID;
  };
  body: {
    assignment: Partial<Atlas.AssignmentSubmission> & {
      name: Atlas.AssignmentName;
    };
  };
}

export const updateGroupAssignment = (
  args: UpdateGroupAssignmentArgs,
): Promise<{ data: Atlas.Assignment }> => {
  const { groupId, assignmentId } = args?.params || {};

  const url = groupAssignmentRoute({ groupId, assignmentId });

  return fetch(url, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] updateGroupAssignment failed`; }
    return r.json();
  }).then(({ data }) => ({
    data: parseAssignmentJson(data),
  }));
};

interface DestroyGroupAssignmentArgs {
  params: {
    groupId: Atlas.GroupID;
    assignmentId: Atlas.AssignmentID;
  };
}

export const destroyGroupAssignment = (
  args: DestroyGroupAssignmentArgs,
): Promise<void> => {
  const { groupId, assignmentId } = args?.params || {};

  const url = groupAssignmentRoute({ groupId, assignmentId });

  return fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] destroyGroupAssignment failed`; }
  });
};

interface CloneGroupAssignmentArgs {
  params: {
    groupId: Atlas.GroupID;
    assignmentId: Atlas.AssignmentID;
  };
}

export const cloneGroupAssignment = (
  args: CloneGroupAssignmentArgs,
): Promise<{ data: Atlas.Assignment }> => {
  const { groupId, assignmentId } = args?.params || {};

  const url = cloneGroupAssignmentRoute({ groupId, assignmentId });

  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  }).then((r) => {
    if (r.status !== 200) { throw `[${r.status}] cloneGroupAssignment failed`; }
    return r.json();
  }).then(({ data }) => ({
    data: parseAssignmentJson(data),
  }));
};
