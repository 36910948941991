import React, { ChangeEvent } from 'react';

import { Textarea } from '../../../../common/components/Input';
import * as Atlas from '../../../../common/types/Atlas';

export interface TextFormAnswer {
  name: string;
  value?: string;
}

interface TextQuestionProps {
  readOnly?: boolean;
  question: Atlas.FormElement;
  answer?: TextFormAnswer;
  showValidation?: boolean;
  onAnswerChange?(fa: TextFormAnswer): void;
}

const TextQuestion = (props: TextQuestionProps): JSX.Element => {
  const { readOnly, question, answer, showValidation } = props;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (props.onAnswerChange) {
      const { value } = event.currentTarget;

      props.onAnswerChange({
        name: question.field.name,
        ...answer,
        value
      });
    }
  };

  const value = answer?.value || '';
  const isEmpty = !value.trim();
  const showError = showValidation && question.field.required && isEmpty;

  if (readOnly) {
    return (
      <p className="mb-0">
        {value}
      </p>
    );
  }

  return (
    <div>
      <Textarea
        className={`mb-0 tw-p-2 tw-rounded-xl ${showError ? 'tw-border tw-border-error/40 tw-ring-1 tw-ring-error/40' : 'tw-border-base-300'}`}
        required={false}
        minRows={4}
        maxRows={20}
        value={value}
        onChange={handleChange} />
    </div>
  );
};

export default TextQuestion;
