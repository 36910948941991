import React, { useState } from 'react';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartsData } from './types';
import { dateFormatter, groupChartsDataByDate, isDateInRange } from './utils';
import { pickExtendedColor } from '../../common/utils/colors';

const formatDate = (value: string | number) =>
  dateFormatter.format(new Date(value));

const transformToRechartsData = (
  dataGroupedByDate: Map<string, Record<string, number>>,
) =>
  [...dataGroupedByDate.entries()].reduce<
    Array<{
      date: number;
      [prop: string]: string | number;
    }>
  >((acc, [date, values]) => {
    acc.push({ ...values, date: new Date(date).valueOf() });
    return acc;
  }, []);

const MetricsLineChart: React.FC<{
  chartsData: ChartsData;
  dateStart?: string;
  dateEnd?: string;
}> = ({ chartsData, dateStart, dateEnd }) => {
  const keys = chartsData.series.map((data) => data.name);

  const dataGroupedByDate = groupChartsDataByDate(chartsData);
  const data = transformToRechartsData(dataGroupedByDate).filter((entry) =>
    isDateInRange(entry.date, dateStart, dateEnd),
  );

  const [hidden, setHidden] = useState<Record<string, boolean>>({});

  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis
          dataKey="date"
          type="number"
          tickFormatter={formatDate}
          domain={['dataMin', 'dataMax']}
        />
        <YAxis />

        <Tooltip labelFormatter={formatDate} />

        <Legend
          onClick={(e: { value: string }) => {
            const { value } = e;

            setHidden((prev) => ({
              ...prev,
              [value]: !prev[value],
            }));
          }}
        />

        {keys.map((dataKey, index) => (
          <Line
            key={dataKey}
            type="monotone"
            dataKey={dataKey}
            dot={false}
            stroke={pickExtendedColor(index)}
            hide={hidden[dataKey]}
          />
        ))}

        <Brush
          dataKey="date"
          height={30}
          stroke="hsl(var(--p))"
          tickFormatter={formatDate}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MetricsLineChart;
