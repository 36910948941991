import { isMatch } from 'lodash-es';
import { useEffect, useCallback } from 'react';
import useFindReflectionPlaylistQuery from '../../common/hooks/api/reflections/useFindReflectionPlaylistQuery';
import * as Atlas from '../../common/types/Atlas';
import { useJwPlayer } from '../jwplayer/JwPlayer';

const usePlaylistSync = (args: {
  reflectionId: Atlas.ReflectionID;
}) => {
  const { reflectionId } = args;

  const playlistQuery = useFindReflectionPlaylistQuery(reflectionId, { staleTime: 60000 });
  const playlist = playlistQuery.data?.data;
  const playerState = useJwPlayer();
  const player = playerState?.player;

  useEffect(() => {
    if (!playlist) { return; }
    if (!player) { return; }

    const currentPlaylist = player.getPlaylist();
    const nextPlaylist = playlist.map((item) => ({ ...item, title: null }));
    if (isMatch(currentPlaylist, nextPlaylist)) { return; }

    const currentItem = player.getPlaylistItem() as Atlas.Playlist;
    const currentPosition = player.getPosition();
    const playing = player.getState() === 'playing';

    player.load(nextPlaylist);

    if (!currentItem || !playing) { return; }

    const newPlaylist = player.getPlaylist() as Atlas.Playlist[];
    const playlistItemIndex = newPlaylist.findIndex((item) => (
      item.video_id === currentItem.video_id
    ));

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    player.playlistItem(playlistItemIndex).once('play', () => {
      player.seek(currentPosition);
    });
  }, [player, playlist]);

  const refetchPlaylist = useCallback(async () => {
    await playlistQuery.refetch();
  }, [playlistQuery]);

  return { refetchPlaylist };
};

export default usePlaylistSync;
