import React, { useState } from 'react';
import Spinner from '../../../common/components/Spinner';
import { Modal, ModalBox } from '../../../common/components/TailwindModal';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import useReplicateVideoMutation from '../../../common/hooks/api/videos/useReplicateVideoMutation';
import * as Atlas from '../../../common/types/Atlas';
import ReflectionList from '../video-clipper/reflection-list';

interface ReplicateVideoModalProps {
  video: Atlas.Video;
  isOpen: boolean;
  onClose: () => void;
}

const ReplicateVideoModal = (props: ReplicateVideoModalProps) => {
  const { video, isOpen, onClose } = props;

  const [replicating, setReplicating] = useState(false);

  const currentUserQuery = useCurrentUserQuery();
  const replicateVideoMutation = useReplicateVideoMutation();

  const handleClose = () => {
    onClose();
  };

  const handleReflectionClick = (reflectionId: Atlas.ReflectionID) => {
    if (replicating) { return false; }

    setReplicating(true);
    void replicateVideoMutation.mutateAsync({
      params: { videoId: video.id },
      body: { reflectionId },
    }).then(({ data: newVideo }) => {
      if (newVideo.reflection_id && newVideo.reflection_id !== video.reflection_id) {
        window.location.href = `/reflections/${newVideo.reflection_id}`;
      }
      onClose();
    }).finally(() => {
      setReplicating(false);
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalBox className="tw-p-0">
        <div className="tw-flex tw-justify-end tw-mb-2">
          <button type="button" className="tw-link tw-link-neutral tw-no-underline tw-text-2xl tw-font-bold tw-px-4 tw-py-2" onClick={handleClose}>
            ×
          </button>
        </div>

        {replicating ? (
          <>
            <div className="tw-p-4 tw-mb-6 bg-primary tw-text-white">
              <div className="tw-text-lg tw-text-center">
                {__('Your request is being processed, please don\'t reload the page')}
              </div>
            </div>

            <div className="tw-px-4 tw-my-10 tw-flex tw-justify-center tw-items-center">
              <Spinner color="info" />
            </div>
          </>
        ) : (
          <>
            <div className="tw-p-4 tw-mb-6 bg-primary tw-text-white">
              <div className="tw-text-lg tw-text-center">
                {__('Where do you want to replicate the video?')}
              </div>
            </div>

            <div className="tw-px-4 tw-my-4">
              {currentUserQuery.status === 'success' ? (
                <ReflectionList
                  userId={currentUserQuery.data.id}
                  onReflectionSelect={handleReflectionClick}
                />
              ) : (
                <div className="tw-text-center">
                  <Spinner color="info" />
                </div>
              )}
            </div>
          </>
        )}
      </ModalBox>
    </Modal>
  );
};

export default ReplicateVideoModal;
