import React from 'react';
import classNames from 'classnames';
import { clamp, pageNumbers } from '../utils/utils';

interface PaginationProps {
  page: number;
  totalPages: number;
  onPageChange(page: number): void;
}

const Pagination = (props: PaginationProps): JSX.Element => {
  const { page, totalPages, onPageChange } = props;

  const handlePageChange = (nextPage: number) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    nextPage = clamp(nextPage, 1, totalPages);
    if (nextPage === page) { return; }

    onPageChange(nextPage);
  };

  return (
    <div className="pagination pagination-centered my-0">
      <ul className="pagination mt-0">
        <li className={classNames('prev', { disabled: page === 1 })}>
          <a href="#" onClick={handlePageChange(page - 1)}>
            Previous
          </a>
        </li>

        {pageNumbers({ page, totalPages: totalPages }).map(n => (
          <li key={n} className={classNames({ active: n === page })}>
            <a href="#" onClick={handlePageChange(n)}>
              {n}
            </a>
          </li>
        ))}

        <li className={classNames('next', { disabled: page === totalPages })}>
          <a href="#" onClick={handlePageChange(page + 1)}>
            Next
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
