import React from 'react';
import * as Atlas from '../types/Atlas';

interface CurrentUserContextState {
  user?: Atlas.User;
  userLoading: boolean;
  reloadUser?: () => void;

}

const CurrentUserContext = React.createContext<CurrentUserContextState>({
  userLoading: false,
  reloadUser: () => {},
});

export default CurrentUserContext;
