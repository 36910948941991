import { useMutation, UseMutationResult } from 'react-query';
import { destroyPage, DestroyPageParams, DestroyPageResponse } from '../../../api/pages/[pageId]';
import { queryClient } from '../../withQueryClient';

const useDestroyPageMutation = (
  params: DestroyPageParams,
): UseMutationResult<DestroyPageResponse, unknown, void, unknown> => {
  const mutation = useMutation(() => destroyPage({ params }), {
    onSuccess: (result) => {
      const page = result.data;
      void queryClient.resetQueries(['pages', page.id]);
      void queryClient.invalidateQueries(['pages'], { exact: true });
      void queryClient.invalidateQueries(['groups', page.group_id, 'pages']);
    },
  });

  return mutation;
};

export default useDestroyPageMutation;
