import React from 'react';
import { ActionMeta, Options, OnChangeValue } from 'react-select';
import { searchGroupUsers } from '../../../common/api/groups/users';
import * as Atlas from '../../../common/types/Atlas';
import { selectClassNames } from '../../../common/components/Select';
import AsyncSelect from 'react-select/async';

type Option = {
  label: string;
  value: number;
};

const userInGroupToOption = (user: Atlas.UserInGroup) => {
  return {
    label: [user.first_name, user.last_name].filter(Boolean).join(' '),
    value: user.id
  };
};

interface GroupUserSelectProps {
  groupId: Atlas.GroupID;
  name: string;
  value?: Option | Options<Option>;
  isClearable?: boolean;
  placeholder?: string;
  onChange(value: OnChangeValue<Option, false>, action: ActionMeta<Option>): void;
}

const GroupUserSelect = (props: GroupUserSelectProps): JSX.Element => {
  const {
    groupId,
    name,
    value,
    isClearable,
    placeholder,
  } = props;

  const loadUsers = (q: string) =>
    searchGroupUsers({ params: { groupId, search: q } }).then(({ data }) => data.map(userInGroupToOption));

  return (
    <AsyncSelect
      classNames={selectClassNames}
      cacheOptions
      defaultOptions
      loadOptions={loadUsers}
      name={name}
      value={value}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

export default GroupUserSelect;
