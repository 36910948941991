import { pick } from 'lodash-es';
import * as Atlas from '../../../../../types/Atlas';
import { inRange } from '../../../../../utils/utils';
import { parseModelObject } from '../../../../api-parser';
import ApiError from '../../../../error';
import { generateFetchHeaders } from '../../../../routes';

async function hashString(message: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await window.crypto.subtle.digest('SHA-256', data);

  return Array.from(new Uint8Array(hash))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
}

export const createIntegrityHash = (fa: Atlas.FormAnswer) => hashString(JSON.stringify({
  attachment_value: fa.attachment_value,
  counter_value: fa.counter_value,
  notes: fa.notes,
  reflection_value: fa.reflection_value,
  timeline: fa.timeline,
  timer_value: fa.timer_value,
  value: fa.value,
}));

const updateFormAnswerRoute = (args: {
  formDataId: Atlas.FormDataID;
  answerIndex: number;
}): string => `/api/v0/form_datas/${args.formDataId}/form_answers/${args.answerIndex}`;

export interface UpdateFormAnswerParams {
  formDataId: Atlas.FormDataID;
  answerIndex: number;
}

export interface UpdateFormAnswerBody {
  value: Atlas.FormAnswer['value'];
  counter_value: Atlas.FormAnswer['counter_value'];
  timer_value: Atlas.FormAnswer['timer_value'];
  attachment_value: Atlas.FormAnswer['attachment_value'];
  reflection_value: Atlas.FormAnswer['reflection_value'];
  timeline: Atlas.FormAnswer['timeline'];
  notes: Atlas.FormAnswer['notes'];

  integrity_hash: string;
}

export interface UpdateFormAnswerArguments {
  params: UpdateFormAnswerParams;
  body: UpdateFormAnswerBody;
}

export interface UpdateFormAnswerResponse {
  data: Atlas.FormData;
}

export const updateFormAnswer = async (
  args: UpdateFormAnswerArguments,
): Promise<UpdateFormAnswerResponse> => {
  const {
    formDataId,
    answerIndex,
   } = args.params;

  const url = updateFormAnswerRoute({
    formDataId,
    answerIndex,
  });

  const response = await fetch(url, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(pick(args.body, [
      'value',
      'counter_value',
      'timer_value',
      'attachment_value',
      'reflection_value',
      'timeline',
      'notes',
      'integrity_hash',
    ])),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updateFormAnswer');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.FormData>(body.data);

  return { data };
};
