import * as Atlas from '../../../../common/types/Atlas';
import { ResourceSearchResult } from '../../../search/resource-search/common/types';

export const convertResourceSearchResultToResourceLink = (
  result: ResourceSearchResult,
): Atlas.CommentResourceLink | null => {
  switch (result.type) {
    case 'group': {
      if (result.data.group?.type === Atlas.GroupType.Guide) {
        return {
          guide_group: result.data.id,
        }
      }

      return {
        group: result.data.id,
      };
    }

    case 'reflection': return {
      reflection: result.data.id,
    };

    default: return null;
  }
};
