import * as Atlas from '../../../../types/Atlas';
import { inRange } from '../../../../utils/utils';
import { parseModelObject } from '../../../api-parser';
import ApiError from '../../../error';
import { generateFetchHeaders } from '../../../routes';

const swapVideoRoute = (args: {
  videoId: Atlas.VideoID;
}): string => `/api/v0/videos/${args.videoId}/swap`;

export interface SwapVideoParams {
  videoId: Atlas.VideoID;
}

export interface SwapVideoBody {
  reflectionId: Atlas.ReflectionID | null;
}

export interface SwapVideoArguments {
  params: SwapVideoParams;
  body: SwapVideoBody;
}

export interface SwapVideoResponse {
  data: Atlas.Video;
}

export const swapVideo = async (
  args: SwapVideoArguments,
): Promise<SwapVideoResponse> => {
  const { videoId } = args.params;

  const url = swapVideoRoute({ videoId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      reflection_id: args.body.reflectionId,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'swapVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Video>(body.data);

  return { data };
};
