/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { usePopper } from 'react-popper';
import GlobalSearchResults from './results';
import { sameWidthModifier } from './utils';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../common/types/Atlas';

interface GlobalSearchProps {
  excludeUsers?: boolean;
  excludeDevices?: boolean;
  excludeGroups?: boolean;
  excludeOrganizations?: boolean;
  excludeReflections?: boolean;
  hideCompletions?: boolean;
}

const GlobalSearch = (props: GlobalSearchProps): JSX.Element => {
  const {
    excludeUsers,
    excludeDevices,
    excludeGroups,
    excludeOrganizations,
    excludeReflections,
    hideCompletions,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'preventOverflow',
        options: {
          rootBoundary: 'viewport',
        },
      },
      sameWidthModifier,
    ],
  });

  const [state, setState] = useState({
    resultsOpen: false,
    query: '',
  });

  useClickAway(containerRef, () => {
    setState((s) => {
      if (!s.resultsOpen) { return s; }
      return { ...s, resultsOpen: false };
    });
  });

  const currentUserQuery = useCurrentUserQuery();
  const isSuper = currentUserQuery.data?.admin === Atlas.AdministratorType.Super;

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setState((s) => ({ ...s, query: value }));
  };

  const handleQueryFocus = () => {
    setState((s) => ({ ...s, resultsOpen: true }));
  };

  const changeQuery = (nextQuery: string) => {
    setState((s) => ({ ...s, query: nextQuery }));
  };

  const resetQuery = () => {
    setState((s) => ({ ...s, query: '' }));
  };

  return (
    <div ref={containerRef} className="tw-text-base">
      <div
        ref={setReferenceElement}
        className="tw-relative"
      >
        <div className="tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-left-0 tw-px-4">
          <i className="fa fa-search" />
        </div>
        <input
          className={`tw-px-10 tw-bg-base tw-input tw-shadow tw-w-full tw-placeholder-tw-base-300 focus:tw-outline-none ${state.resultsOpen ? 'tw-shadow-lg' : ''}`}
          placeholder={__('Search')}
          value={state.query}
          onChange={handleQueryChange}
          onFocus={handleQueryFocus}
        />
        {state.query.length >= 1 ? (
          <button type="button" className="tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-right-0 tw-px-4 tw-h-full hover:tw-opacity-80" onClick={resetQuery}>
            <i className="fa fa-times" />
          </button>
        ) : null}
      </div>

      {state.resultsOpen ? (
        <div ref={setPopperElement} className="tw-z-50 tw-flex tw-flex-col tw-max-h-80" style={styles.popper} {...attributes.popper}>
          <GlobalSearchResults
            query={state.query}
            onQueryChange={changeQuery}
            excludeUsers={excludeUsers}
            excludeDevices={excludeDevices}
            excludeGroups={excludeGroups}
            excludeOrganizations={excludeOrganizations}
            excludeReflections={excludeReflections || isSuper}
            hideCompletions={hideCompletions}
          />
        </div>
      ) : null}
    </div>
  );
};

export default GlobalSearch;
