import { useParams } from "react-router-dom";

export const getAssignmentsBasePath = (pathname: string): string => {
  if (pathname.includes('/groups/')) {
      return 'groups';
  } else if (pathname.includes('/pathways_management/')) {
			const params = useParams<{ containerId: string; }>();
			const containerId = Number(params.containerId);
      return `pathways_management/${containerId}/templates`;
  } else if (pathname.includes('/unity_pathways/')) {
      return 'unity_pathways';
  }
  return '';
};