import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from '../../common/components/Spinner';
import Collapse from '@kunukn/react-collapse';
import FormAnswer from './FormAnswer';
import FormQuestion from './FormQuestion';
import FormElementChart from './FormElementChart';
import { useCurrentUser } from '../../common/hooks/withCurrentUser';
import { interpolate } from '../../common/utils/utils';
import { createIntegrityHash } from '../../common/api/form-datas/[formDataId]/form-answers/[answerIndex]';
import { Modal, ModalBox, ModalHeader } from '../../common/components/TailwindModal';

// Utils
const icons = {
  text: 'icon-quote-left',
  multiple_choice: 'icon-check',
  rating: 'icon-dashboard',
  counter: 'icon-bar-chart',
  timer: 'icon-time',
};

const names = {
  text: __('Text'),
  multiple_choice: __('Choice'),
  rating: __('Rating'),
  counter: __('Counter'),
  timer: __('Timer'),
};

const FormElement = React.forwardRef((props, ref) => {
  const {
    loading,
    formElement,
    formAnswers = [],
    disabled,
    onEditSubmit,
    onSubmit,
    isCompleted,
    defaultExpanded,
  } = props;
  const { field } = formElement;
  const type = Object.keys(field.type)[0];

  const [state, setState] = useState({ page: 1, pageSize: 2, mode: 'question' });
  const [expanded, setExpanded] = useState(defaultExpanded ?? false);
  const [viewAnswerModal, setViewAnswerModal] = useState(null);

  const [editIntegrityHash, setEditIntegrityHash] = useState();
  const [formAnswer, setFormAnswer] = useState({ name: field.name });

  const { user: currentUser } = useCurrentUser();

  useEffect(() => {
    if (field.name === formAnswer.name) { return; }

    setFormAnswer({ name: field.name });
  }, [field.name, formAnswer.name]);

  const handleHeaderClick = () => {
    setExpanded((expanded) => !expanded);
  };

  const handleViewMoreResponsesClick = () => {
    setState((s) => ({ ...s, page: s.page + 1 }));
  };

  const handleFormAnswerDelete = (fa) => {
    if (!props.onFormAnswerDelete) { return; }
    return () => {
      props.onFormAnswerDelete(fa);
    };
  };

  const handleChange = useCallback((func) => {
    if (disabled) { return; }
    setFormAnswer(func);
  }, [disabled]);

  const isEditing = (
    typeof formAnswer._formDatumId === 'number'
    && typeof formAnswer._formAnswerIndex === 'number'
  );

  const handleFormQuestionSubmit = (() => {
    if (disabled) { return; }

    if (isEditing && onEditSubmit) {
      return async () => {
        await onEditSubmit({ ...formAnswer }, editIntegrityHash);
        setFormAnswer({ name: field.name });
        setEditIntegrityHash(undefined);
      };
    }

    if (onSubmit) {
      return () => {
        onSubmit({ ...formAnswer, user_id: currentUser.id });
        setFormAnswer({ name: field.name });
      };
    }
  })();

  const totalFormAnswerCount = state.page * state.pageSize;
  const hiddenFormAnswerCount = formAnswers.length - totalFormAnswerCount;
  const visibleFormAnswers = [...formAnswers].splice(0, totalFormAnswerCount).reverse();
  const nextPageSize = Math.min(hiddenFormAnswerCount, state.pageSize);
  const uniqueUsers = new Set(formAnswers.map((fa) => fa.user_id));

  const isAnswered = currentUser ? formAnswers.some((fa) => fa.user_id === currentUser.id) : undefined;
  const canAnswerQuestion = formElement.field.allow_multiple || isAnswered === false;

  const handleChartsClick = () => {
    setState((s) => ({ ...s, mode: s.mode === 'chart' ? 'question' : 'chart' }));
  };

  const $content = (() => {
    switch (state.mode) {
      case 'question': {
        return (
          <>
            <div className="tw-flex tw-gap-4 tw-py-1 tw-px-2 tw-border-y tw-border-base-200">
              <div className="tw-flex tw-gap-2 tw-items-center tw-text-xs" title={__('Number of responses')}>
                {loading ? null : (
                  <div>{formAnswers.length}</div>
                )}
                <i className="fa fa-comments fa-fw tw-text-info" />
              </div>

              <div className="tw-flex tw-gap-2 tw-items-center tw-text-xs" title={__('Number of participants')}>

                {loading ? null : (
                  <div>{uniqueUsers.size}</div>
                )}
                <i className="fa fa-user fa-fw tw-text-success" />
              </div>

              <div className ="tw-flex-1"></div>

              <button
                type="button"
                className="tw-btn tw-btn-sm"
                title={__('View Chart')}
                onClick={handleChartsClick}
              >
                <i className="fa fa-bar-chart" />
              </button>
            </div>

            {field.instructions ? (
              <div className="tw-p-4 tw-border-b tw-border-base-200">
                <div className="tw-text-base-100 tw-bg-info tw-p-4 tw-whitespace-pre-line tw-rounded-xl
                ">
                  <div className="tw-font-semibold tw-mb-1">
                    <i className="fa fa-info-circle tw-mr-1"></i>
                    {__('Guidance:')}
                  </div>
                  {field.instructions}
                </div>
              </div>
            ) : null}

            {formAnswers.length || loading ? (
              <div>
                {nextPageSize > 0 ? (
                  <div className="tw-mb-2 tw-mt-1 tw-text-center">
                    <button type="button" className="tw-link tw-link-hover tw-link-primary" onClick={handleViewMoreResponsesClick}>
                      {interpolate(n__('View %n more response', 'View %n more responses', nextPageSize), nextPageSize)}
                    </button>
                  </div>
                ) : null}

                {visibleFormAnswers.length ? (
                  <div className="tw-border-b tw-border-base-400">
                    {visibleFormAnswers.map((fa) => {
                      const isEditingAnswer = (
                        fa._formDatumId === formAnswer._formDatumId
                        && fa._formAnswerIndex === formAnswer._formAnswerIndex
                      );

                      const canEdit = fa.user_id === currentUser.id && !isEditing && !isCompleted;

                      const handleView = () => {
                        setViewAnswerModal({
                          formDatumId: fa._formDatumId,
                          formAnswerIndex: fa._formAnswerIndex,
                        });
                      };

                      const handleEdit = canEdit ? (async () => {
                        const integrityHash = await createIntegrityHash(fa);

                        setFormAnswer(fa);
                        setEditIntegrityHash(integrityHash);
                      }) : undefined;

                      return (
                        <FormAnswer
                          key={`${fa._formDatumId}_${fa._formAnswerIndex}`}
                          className={`${isEditingAnswer ? 'tw-outline tw-outline-2 tw-outline-offset-4 tw-outline-info tw-rounded-md' : ''}`}
                          user={fa._user}
                          formElement={formElement}
                          formAnswer={fa}
                          aiGenerated={!!fa._aiModel}
                          onView={handleView}
                          onEdit={handleEdit}
                          onDelete={canEdit ? handleFormAnswerDelete(fa) : undefined}
                        />
                      );
                    })}
                  </div>
                ) : null}

                {loading ? (
                  <div className="tw-text-center tw-mb-4">
                    <Spinner color="info" />
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="tw-p-4">
              {isEditing ? (() => {
                const handleEditCancel = () => {
                  setFormAnswer({ name: field.name });
                };

                return (
                  <div className="tw-badge tw-badge-lg tw-badge-info tw-w-full tw-p-0 tw-mb-3 tw-flex tw-justify-between">
                    <div className="tw-px-3">
                      {__('Editing response')}
                    </div>

                    <button type="button" className="tw-btn tw-btn-xs tw-btn-ghost" onClick={handleEditCancel}>
                      <i className="fa fa-close" />
                    </button>
                  </div>
                );
              })() : null}

              {isCompleted ? (
                <div className="tw-alert tw-justify-start">
                  <i className="fa fa-lock" />
                  {__('You have completed this form.')}
                </div>
              ) : null}

              {!isCompleted && (canAnswerQuestion || isEditing) ? (
                <FormQuestion
                  formElement={formElement}
                  formAnswer={formAnswer}
                  onChange={handleChange}
                  onSubmit={handleFormQuestionSubmit}
                />
              ) : null}

              {!isCompleted && !canAnswerQuestion ? (
                <div className="tw-alert tw-justify-start">
                  <i className="fa fa-lock" />
                  {__('This question is locked to a single response.')}
                </div>
              ) : null}
            </div>
          </>
        );
      }

      case 'chart': {
        const handleChartBackClick = () => {
          setState((s) => ({ ...s, mode: 'question' }));
        };

        return (
          <div className="tw-p-4">
            <FormElementChart
              onBackClick={handleChartBackClick}
              formElement={formElement}
              formAnswers={formAnswers}
            />
          </div>
        );
      }

      default: {
        return null;
      }
    }
  })();

  return (
    <div ref={ref} className={classNames('tw-iris-element-box !tw-p-0 tw-mb-4 tw-transition-shadow tw-shadow', { 'tw-shadow-lg tw-overflow-visible': expanded })}>
      <div
        role="button"
        tabIndex={0}
        className="tw-cursor-pointer tw-select-none tw-p-4 tw-space-y-2"
        onClick={handleHeaderClick}
      >
        <div className="tw-flex tw-items-center">
          <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-text-xs tw-w-12 tw-shrink-0" title={__('Type of question')}>
            <i className={icons[type]} />
            <div>{names[type]}</div>
          </div>
          <div className="tw-divider tw-divider-horizontal tw-mx-0 tw-mr-[10px]"></div>
          <div>
            <div className={`tw-text-base tw-test-xl tw-font-semibold ${expanded ? 'tw-whitespace-pre-line' : 'tw-line-clamp-2'}`}>
              {field.label}
            </div>
            {formElement.field.required ? (
              <div className="tw-text-xs tw-text-gray-500 tw-italic tw-mt-1">
                * {__('Required')}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Collapse overflowOnExpanded isOpen={expanded} className="tw-transition-all">
        <div>
          {$content}
        </div>
      </Collapse>

      {viewAnswerModal ? (() => {
        const formAnswer = formAnswers.find((fa) => (
          fa._formDatumId === viewAnswerModal.formDatumId
          && fa._formAnswerIndex === viewAnswerModal.formAnswerIndex
        ));

        if (!formAnswer) { return null; }

        const handleClose = () => {
          setViewAnswerModal(null);
        };

        return (
          <Modal isOpen onClose={handleClose}>
            <ModalBox className="tw-bg-slate-100 tw-w-auto tw-max-w-3xl">
              <ModalHeader onClose={handleClose}>
                {field.label}
              </ModalHeader>

              <FormAnswer
                user={formAnswer._user}
                formElement={formElement}
                formAnswer={formAnswer}
                aiGenerated={!!formAnswer._aiModel}
              />
            </ModalBox>
          </Modal>
        );
      })() : null}
    </div>
  );
});

FormElement.propTypes = {
  formElement: PropTypes.object.isRequired,
  formAnswers: PropTypes.array,
  users: PropTypes.array,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onFormAnswerDelete: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default FormElement;
