import React from 'react';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import { Group } from '../../../common/types/Atlas';
import { isGroupAdministrator } from '../../../common/utils/group-permissions';
import GroupBanner from './banner';
import GroupLogo from './logo';
import { isSuperAdmin } from '../../../common/utils/user-permissions';

interface GroupHeaderProps {
  group: Group;
}

const GroupHeader = (props: GroupHeaderProps): JSX.Element => {
  const { group } = props;

  const currentUserQuery = useCurrentUserQuery();
  const isAdmin = (() => {
    if (!group.group) { return false; }
    if (currentUserQuery.status !== 'success') { return false; }

    const currentUser = currentUserQuery.data;
    if (isSuperAdmin(currentUser)) { return true; }

    const groupInfo = group.group;
    if (!groupInfo) { return false; }

    return isGroupAdministrator(groupInfo, currentUser.id, currentUser.organization_id);
  })();

  return (
    <div className="tw-pb-4">
      <div className="tw-aspect-w-6 tw-aspect-h-1">
        <GroupBanner group={group} editable={isAdmin} />

        <div className="tw-w-1/6 tw-left-auto rtl:tw-right-auto tw-m-4">
          <div className="tw-aspect-h-1 tw-aspect-w-1">
            <GroupLogo group={group} editable={isAdmin} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupHeader;
