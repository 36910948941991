import * as Atlas from '../types/Atlas';

export const groupTypeLabel = (groupType?: Atlas.GroupType) => {
  switch (groupType) {
    case Atlas.GroupType.Guide: {
      return __('Guide');
    }
    default: return __('Group');
  }
};
