/*global $,require,window*/

var autosize = require("autosize"),
    CustomEvent = require("custom-event");

var KEY = {
    BackSpace: 8,
    DownArrow: 40,
    Escape: 27,
    Tab: 9,
    UpArrow: 38
};

module.exports = function (element, url, scrollHooks) {
    "use strict";

    var container = $(element),
        overlay = container.find(".discussion-mention-overlay"),
        textArea = container.find(".discussion-mention-input"),
        embed = container.find(".discussion-mention");

    var React = webpack.React;
    var ReactDOMClient = webpack.ReactDOMClient;

    var domNodes = {
        overlay: overlay[0],
        textArea: textArea[0]
    };

    var ref = { current: null };

    var props = {
        ref,
        delimiter: "@",
        searchUrl: url,
        domNodes,
        focus() {
            domNodes.textArea.focus();
        },
        render(string) {
            domNodes.overlay.innerHTML = string;
        },
        pick(string) {
            var e = new CustomEvent("input");
            domNodes.textArea.value = string;
            domNodes.textArea.dispatchEvent(e);
        }
    };

    var component = React.createElement(window.webpack.discussions.Mention, props);

    var root = ReactDOMClient.createRoot(embed[0]);
    root.render(component);

    autosize(textArea);

    const scrollHookElements = $(scrollHooks.map(function (element) {
        return $(element)[0];
    }));

    (function () {
        var i;
        for (i = 0; i < scrollHookElements.length; i = i + 1) {
            scrollHookElements[i].addEventListener("scroll", () => ref.current.positionResults());
        }
    }());

    domNodes.textArea.addEventListener("input", function () {
        ref.current.keyPress({
            startPos: domNodes.textArea.selectionStart,
            keyCode: 100
        });
    });

    domNodes.textArea.addEventListener("keypress", function (e) {
        ref.current.keyPress({
            startPos: domNodes.textArea.selectionStart,
            keyCode: e.keyCode || e.which
        });
    });

    domNodes.textArea.addEventListener("keydown", function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === KEY.Tab || keyCode === KEY.Escape || keyCode === KEY.UpArrow || keyCode === KEY.DownArrow) {
            e.preventDefault();
            ref.current.keyPress({
                startPos: domNodes.textArea.selectionStart,
                keyCode: keyCode
            });
        } else if (keyCode === KEY.BackSpace) {
            ref.current.keyPress({
                startPos: domNodes.textArea.selectionStart,
                keyCode: keyCode
            });
        }
    });

    domNodes.textArea.addEventListener("cut", function () {
        ref.current.cut({
            startPos: domNodes.textArea.selectionStart,
            endPos: domNodes.textArea.selectionEnd
        });
    });

    domNodes.textArea.addEventListener("paste", function (e) {
        var paste = "";
        if (window.clipboardData && window.clipboardData.getData) {
            paste = window.clipboardData.getData("Text");
        } else {
            paste = e.clipboardData.getData("Text");
        }
        ref.current.paste({
            startPos: domNodes.textArea.selectionStart,
            value: paste
        });
    });
};
