import React, { useLayoutEffect, useRef } from 'react';
import NotificationLevel, { NotificationLevelProps, NotificationLevelRef } from './NotificationLevel';

interface NotificationLevelEmbedProps {
  parentId: string;
}

const NotificationLevelEmbed = (props: NotificationLevelEmbedProps & NotificationLevelProps): JSX.Element => {
  const { parentId, ...notificationLevelProps } = props;

  const notificationLevelRef = useRef<NotificationLevelRef>(null);

  useLayoutEffect(() => {
    const button =
      document
        .getElementById(parentId)
        ?.closest('.discussion')
        ?.querySelector('a.notification-level');

    if (!button) { return; }

    const handler = () => {
      notificationLevelRef.current?.toggleVisibility();
    };

    button.addEventListener('click', handler);
    return () => { button.removeEventListener('click', handler); };
  }, [parentId]);

  return <NotificationLevel ref={notificationLevelRef} {...notificationLevelProps} />
};

export default NotificationLevelEmbed;
