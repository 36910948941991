import React, { useEffect, useMemo, useState } from 'react';
import useCurrentUserQuery from '../../common/hooks/api/useCurrentUserQuery';
import useFindReflectionQuery from '../../common/hooks/api/reflections/useFindReflectionQuery';
import useReflectionVideosQuery from '../../common/hooks/api/useReflectionVideosQuery';
import { withQueryClient } from '../../common/hooks/withQueryClient';
import * as Atlas from '../../common/types/Atlas';
import VideoEditorContext, { VideoEditorActions, VideoEditorState, VideoPlaylistState } from './context';
import VideoEditorPlaylist from './playlist';
import VideoClipper from './video-clipper';
import VideoUploader from './video-uploader';
import usePlaylistSync from './use-playlist-sync';
import useFindFeatureToggleQuery from '../../common/hooks/api/features/useFindFeatureToggleQuery';
import { ScreenCaptureButton } from '../reflections/show/ScreenCapture';
import Spinner from '../../common/components/Spinner';

interface PlaylistSyncProps {
  reflectionId: Atlas.ReflectionID;
  pollEvery?: number;
}

const PlaylistSync = (props: PlaylistSyncProps) => {
  const { reflectionId, pollEvery } = props;

  const { refetchPlaylist } = usePlaylistSync({ reflectionId });

  useEffect(() => {
    if (!pollEvery) { return undefined; }

    const interval = setInterval(() => {
      void refetchPlaylist();
    }, pollEvery);

    return () => {
      clearInterval(interval);
    };
  }, [pollEvery, refetchPlaylist]);

  return null;
};

const pendingPrepStates = new Set([
  Atlas.VideoPrepState.Waiting,
  Atlas.VideoPrepState.Uploading,
  Atlas.VideoPrepState.Transcoding,
]);

interface VideoEditorProps {
  reflectionId: Atlas.ReflectionID;
  onEditVideoClick: (videoId: Atlas.VideoID) => void;
}

const VideoEditor = (props: VideoEditorProps) => {
  const { reflectionId, onEditVideoClick } = props;

  const [state, setState] = useState<VideoEditorState>({
    status: 'idle',
  });

  const disableDesktopUploadsToggleQuery = useFindFeatureToggleQuery({
    featureToggle: 'disable_desktop_uploads',
  });
  const disableUploads = disableDesktopUploadsToggleQuery.data?.enabled ?? true;

  const currentUseryQuery = useCurrentUserQuery();
  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const videosQuery = useReflectionVideosQuery({
    reflectionId,
    per_page: -1,
  });

  const isVideoPrepping = (() => {
    const videos = videosQuery.data?.data;
    if (!videos) { return false; }

    const prepStates = videos.reduce((acc, video) => {
      video.channels.forEach((channel) => {
        acc.add(channel.prep_state);
      });

      return acc;
    }, new Set<Atlas.VideoPrepState>());

    return [...prepStates].some((prepState) => pendingPrepStates.has(prepState));
  })();

  const pollEvery = isVideoPrepping ? 5000 : undefined;

  const { refetch } = videosQuery;
  useEffect(() => {
    if (typeof pollEvery !== 'number') { return undefined; }

    const interval = setInterval(() => {
      void refetch();
    }, pollEvery);

    return () => {
      clearInterval(interval);
    };
  }, [pollEvery, refetch]);

  const isOwnedByCurrentUser = (() => {
    const currentUser = currentUseryQuery.data;
    if (!currentUser) { return false; }
    const reflection = reflectionQuery.data?.data;
    if (!reflection) { return false; }

    return reflection.user_id === currentUser.id;
  })();

  const currentUserIsSuper = currentUseryQuery.data?.admin === Atlas.AdministratorType.Super;

  const context = useMemo((): VideoPlaylistState & VideoEditorActions => {
    const editVideo = (videoId: Atlas.VideoID) => {
      setState({
        status: 'editing',
        videoId,
      });
    };

    const reset = () => {
      setState({
        status: 'idle',
      });
    };

    return {
      editor: state,
      editable: isOwnedByCurrentUser || currentUserIsSuper,
      editVideo,
      reset,
    };
  }, [state, isOwnedByCurrentUser]);

  if (reflectionQuery.status !== 'success') { return null; }

  const reflection = reflectionQuery.data.data;
  const videoIds = reflection.video_ids;

  return (
    <VideoEditorContext.Provider value={context}>
      <div className="tw-flex tw-flex-col tw-gap-2 xs:tw-gap-4 tw-h-full">
        {!reflection.booking || reflection.booking.state === Atlas.BookingProgression.Over ? (
          <PlaylistSync
            reflectionId={reflectionId}
            pollEvery={pollEvery}
          />
        ) : null}

        <div className="tw-text-base tw-grow tw-overflow-scroll tw-h-0 tw-rounded-xl tw-bg-base-300 tw-border tw-border-base-400 tw-shadow-inner tw-shadow tw-p-2 xs:tw-p-4">
          {(() => {
            switch (context.editor.status) {
              case 'editing': {
                return (
                  <div>
                    <VideoClipper
                      videoId={context.editor.videoId}
                      onDone={context.reset}
                      onCancel={context.reset}
                    />
                  </div>
                );
              }

              default: return null;
            }
          })()}

          {(() => {
            switch (videosQuery.status) {
              case 'idle':
              case 'loading': {
                return (
                  <div className="tw-p-4 tw-grid tw-place-items-center">
                    <Spinner color="info" />
                  </div>
                );
              }
              case 'error': {
                return (
                  <div>
                    {__('Something went wrong.')}
                  </div>
                );
              }
              case 'success': {
                const videos = videosQuery.data.data;

                return (
                  <VideoEditorPlaylist
                    videos={videos}
                    videoOrder={videoIds}
                    onEditVideoClick={onEditVideoClick}
                  />
                );
              }
              default: return null;
            }
          })()}
        </div>

        {isOwnedByCurrentUser && !disableUploads ? (
          <>
            <div>
              <VideoUploader reflectionId={reflectionId} />
            </div>

            <div className="tw-flex tw-justify-end">
              <ScreenCaptureButton />
            </div>
          </>
        ) : null}
      </div>
    </VideoEditorContext.Provider>
  );
};

export default withQueryClient(VideoEditor);
