import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders, jsonHeaders } from '../../routes';

export const attachmentRoute = (args: {
  attachmentId: Atlas.AttachmentID,
}): string => `/api/v0/attachments/${args.attachmentId}`;

export interface FindAttachmentParams {
  attachmentId: Atlas.AttachmentID;
}

export interface FindAttachmentArgs {
  params: FindAttachmentParams;
}

export interface FindAttachmentResponse {
  data: Atlas.Attachment;
}

export const findAttachment = async (
  args: FindAttachmentArgs,
): Promise<FindAttachmentResponse> => {
  const { attachmentId } = args.params;

  const url = attachmentRoute({ attachmentId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findAttachment');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Attachment>(body.data);

  return { data };
};

export interface DestroyAttachmentParams {
  attachmentId: Atlas.AttachmentID;
}

export interface DestroyAttachmentArguments {
  params: DestroyAttachmentParams;
}

export interface DestroyAttachmentResponse {
  data: Atlas.Attachment;
}

export const destroyAttachment = async (
  args: DestroyAttachmentArguments,
): Promise<DestroyAttachmentResponse> => {
  const { attachmentId } = args.params;

  const url = attachmentRoute({ attachmentId });

  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'destroyAttachment');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Attachment>(body.data);

  return { data };
};
