import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import GroupAssignmentSubmission from '../../../../../../../modules/group-assignment/submissions/AssignmentSubmission';
import AssignmentSubmissionLayout from '../layout';
import useGroupQuery from '../../../../../../../common/hooks/api/groups/useFindGroupQuery';
import useGroupAssignmentQuery from '../../../../../../../common/hooks/api/groups/useFindGroupAssignmentQuery';
import useAssignmentSubmissionQuery from '../../../../../../../common/hooks/api/assignments/useFindAssignmentSubmissionQuery';
import { getAssignmentsBasePath } from '../../../../../../../common/utils/getAssignmentsBasePath';

const ShowGroupAssignmentSubmissionPage = (): JSX.Element => {
  const params = useParams<{
    groupId: string;
    assignmentId: string;
    assignmentSubmissionId: string;
  }>();

  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);

  const groupId = Number(params.groupId);
  const assignmentId = Number(params.assignmentId);
  const assignmentSubmissionId = Number(params.assignmentSubmissionId);

  const groupQuery = useGroupQuery(groupId);
  const group = groupQuery.data?.data;

  const groupAssignmentQuery = useGroupAssignmentQuery(groupId, assignmentId);
  const assignment = groupAssignmentQuery.data?.data;

  const assignmentSubmissionQuery = useAssignmentSubmissionQuery(
    assignmentId,
    assignmentSubmissionId,
  );
  const assignmentSubmission = assignmentSubmissionQuery.data?.data;

  const navigate = useNavigate();

  const handleSubmitted = () => {
    if (!assignment) { return; }

    navigate(`/${basePath}/${assignment.group_id}/assignments/${assignment.id}`);
  };

  const handleCompleted = () => {
    if (!assignment) { return; }

    navigate(`/${basePath}/${assignment.group_id}/assignments/${assignment.id}`);
  };

  if (!group || !assignment || !assignmentSubmission) { return <AssignmentSubmissionLayout />; }

  return (
    <AssignmentSubmissionLayout>
      <GroupAssignmentSubmission
        group={group}
        assignment={assignment}
        initialSubmission={assignmentSubmission}
        onSubmitted={handleSubmitted}
        onCompleted={handleCompleted} />
    </AssignmentSubmissionLayout>
  );
};

export default ShowGroupAssignmentSubmissionPage;
