import { useMutation, UseMutationResult } from 'react-query';
import { watchDiscussion, WatchDiscussionBody, WatchDiscussionParams, WatchDiscussionResponse } from '../../../api/discussions/[discussionId]/watch';
import { queryClient } from '../../withQueryClient';

const useWatchDiscussionMutation = (
  params: WatchDiscussionParams,
): UseMutationResult<WatchDiscussionResponse, unknown, WatchDiscussionBody, unknown> => {
  const mutation = useMutation((body: WatchDiscussionBody) => {
    return watchDiscussion({ params, body });
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['discussions', params.discussionId]);
    },
  });

  return mutation;
};

export default useWatchDiscussionMutation;
