import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import { withQueryClient } from '../../../../common/hooks/withQueryClient';
import useFindMilestoneQuery from '../hooks/api/useFindMilestoneQuery';;
import EditMilestoneContainer from './EditMilestoneContainer';
import MilestoneFormHeader from './MilestoneFormHeader';
import Spinner from '../../../../common/components/Spinner';

interface EditMilestoneWrapperProps {
  groupId: number;
  milestoneId: Atlas.MilestoneIdentifier;
}

const EditMilestoneWrapper = (props: EditMilestoneWrapperProps): JSX.Element => {
  const query = useFindMilestoneQuery(props.groupId, props.milestoneId);

  const renderChildComponent = () => {
    switch (query.status) {
      case 'success': {
        return (
          <div>
            <EditMilestoneContainer groupId={props.groupId} milestone={query.data.data} />
          </div>
        );
      }
      case 'idle':
      case 'loading': {
        return (
          <div className="tw-flex tw-items-center tw-justify-center tw-my-5">
            <Spinner color="info" />
          </div>
        );
      }
      case 'error':
      default: {
        return (
          <div>
            <h1 className="tw-mx-3 tw-my-3 tw-text-center tw-font-light">
              {__('No milestone found')}
            </h1>
          </div>
        );
      }
    }
  }

  return (
    <div className="tw-flex tw-flex-col">
      <div>
        <MilestoneFormHeader
          groupId={props.groupId}
        />
      </div>
      <div>
        {renderChildComponent()}
      </div>
    </div>
  );
};

export default withQueryClient(EditMilestoneWrapper);
