import React from 'react';
import CommentResourceLinkGroup from './CommentResourceLinkGroup';
import CommentResourceLinkReflection from './CommentResourceLinkReflection';
import * as Atlas from '../../../../../common/types/Atlas';

const CommentResourceLinkInaccessible: React.FC<{
  resourceLink: Atlas.CommentResourceLink;
}> = (props) => {
  const { resourceLink } = props;

  if ('group' in resourceLink) {
    return (
      <CommentResourceLinkGroup
        name={__('You do not have access to this group')}
        inaccessible
      />
    );
  }

  if ('pathway_group' in resourceLink) {
    return (
      <CommentResourceLinkGroup
        name={__('You do not have access to this pathway')}
        inaccessible
      />
    );
  }

  if ('guide_group' in resourceLink) {
    return (
      <CommentResourceLinkGroup
        name={__('You do not have access to this walkthru')}
        inaccessible
      />
    );
  }

  if ('reflection' in resourceLink) {
    return (
      <CommentResourceLinkReflection
        name={__('You do not have access to this reflection')}
        inaccessible
      />
    );
  }

  return null;
};

export default CommentResourceLinkInaccessible;
