import React from 'react';

// UI
import {
  CartesianGrid,
  DotProps,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// Utils
import { msToTimestamp } from '../../../../common/utils/utils';
import { pickColor } from '../../../../common/utils/colors';
import * as Atlas from '../../../../common/types/Atlas';
import { lineLegendFormatter } from '../legendFormatter';
import useVideoPlayer from '../../../jwplayer/useVideoPlayer';

interface Payload {
  time: number;
  videoId: Atlas.VideoID;
}

interface FormMultipleChoiceLineChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

const FormMultipleChoiceLineChart = (props: FormMultipleChoiceLineChartProps): JSX.Element => {
  const { formElement, formAnswers = [] } = props;

  const videoPlayer = useVideoPlayer();

  if (!('multiple_choice' in formElement.field.type)) {
    throw 'FormFieldType is incompatible with MultipleChoiceFormFieldType';
  }

  const { choices } = formElement.field.type.multiple_choice;

  const handleDotClick = (
    _: DotProps,
    event: React.MouseEvent<SVGCircleElement, MouseEvent> & { payload: Payload; },
  ) => {
    if (!videoPlayer) { return; }

    const { time, videoId } = event.payload;
    const currentVideoId = videoPlayer.videoId;

    if (currentVideoId === videoId) {
      videoPlayer.seek(time / 1000);
    } else {
      videoPlayer.play(videoId, time / 1000);
    }
  };

  const data: Record<Atlas.VideoID, Record<number, Record<string, number>>> = {};

  videoPlayer?.playlist.forEach((videoId) => {
    data[videoId] = {};
  });

  formAnswers.forEach((fa) => {
    const value = fa.value as string | string[];
    const values = Array.isArray(value) ? value : [value];

    fa.timeline?.forEach((tl) => {
      const vId = tl.video_id;
      data[vId] ||= {};

      const start = Math.floor(tl.start);
      const beforeStart = Math.max(start - 1, 0);
      const end = Math.floor(tl.start + tl.duration);
      const afterEnd = Math.min(end + 1);

      data[vId][beforeStart] ||= {};
      data[vId][start] ||= {};
      data[vId][end] ||= {};
      data[vId][afterEnd] ||= {};

      values.forEach((v) => {
        data[vId][beforeStart][v] ||= 0;
        data[vId][start][v] ||= 0;
        data[vId][start][v] += 1;
        data[vId][end][v] ||= 0;
        data[vId][afterEnd][v] ||= 0;
        data[vId][afterEnd][v] -= 1;
      });
    });
  });

  const chartsData = Object.entries(data).map(([videoId, times]) => {
    const data: Array<{
      time: number;
      videoId: Atlas.VideoID;
    }> = [];

    Object.entries(times).reduce((prev, [time, choices]) => {
      const next: {
        time: number;
        videoId: Atlas.VideoID;
      } & Record<string, number> = {
        ...prev,
        time: Number(time),
        videoId: Number(videoId),
      };

      Object.entries(choices).forEach(([k, v]) => {
        next[k] ||= 0;
        next[k] += v;
      });

      data.push(next);
      return next;
    }, {
      time: 0,
    });

    return { videoId, data };
  });

  return (
    <div>
      {chartsData.length ? (
        <div className="tw-text-center tw-py-4 px-3 tw-text-base-300">
          {__('There is no timeline data to show.')}
        </div>
      ) : chartsData.map(({ videoId, data }) => (
        data.length ? (
          <ResponsiveContainer height={200} width="100%" key={videoId}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" type="number" scale="time" name={__('Time')} tickFormatter={(n: number) => msToTimestamp(Number(n))} />

              <YAxis allowDecimals={false} type="number" name={__('Count')}>
                <Label angle={-90} value={__('Count')} position="insideLeft" offset={20} style={{ textAnchor: 'middle' }} />
              </YAxis>

              <Tooltip labelFormatter={(n) => msToTimestamp(Number(n))} />
              <Legend formatter={lineLegendFormatter} />

              {choices.map((c, i) => (
                <Line
                  key={c}
                  dataKey={c}
                  connectNulls
                  stroke={pickColor(i)}
                  activeDot={{ onClick: handleDotClick }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        ) : null
      ))}
    </div>
  );
};

export default FormMultipleChoiceLineChart;
