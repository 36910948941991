import dayjs from 'dayjs';
import React, {
  PropsWithChildren, useContext, useEffect, useMemo, useState,
} from 'react';

interface IridiumState {
  recordingStartedAt: Date;
  offsetMs: number;
}

type Iridium = IridiumState & {
  getPosition: () => number;
}

const IridiumContext = React.createContext<Iridium | null>(null);

export const IridiumProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  const [state, setState] = useState<IridiumState | null>(null);

  useEffect(() => {
    // @ts-ignore
    broker.subscribe('webRTC.comments.clockSynced', (_, data) => {
      // @ts-ignore
      const recordingStartedAt = data.recording_started_at as string;
      // @ts-ignore
      const offsetMs = data.offset_ms as number;
      const startedAt = dayjs(recordingStartedAt, 'YYYY-MM-DD HH:mm:ss.SSSZ').toDate();

      setState({
        recordingStartedAt: startedAt,
        offsetMs,
      });
    });
  }, []);

  const memo = useMemo(() => {
    if (!state) { return null; }

    const getPosition = () => (Date.now() - state.offsetMs) - state.recordingStartedAt.valueOf();

    return ({
      ...state,
      getPosition,
    });
  }, [state]);

  return (
    <IridiumContext.Provider value={memo}>
      {children}
    </IridiumContext.Provider>
  );
};

export const useIridium = () => useContext(IridiumContext);
