import React from 'react';

// UI
import {
  PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer, TooltipProps,
} from 'recharts';

// Utils
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { pickColor } from '../../../../common/utils/colors';
import * as Atlas from '../../../../common/types/Atlas';
import { pieLegendFormatter } from '../legendFormatter';

interface Payload {
  choice: string;
  count: number;
}

const ChoiceTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { active, payload } = props;
  if (!active) { return null; }
  if (!payload) { return null; }

  const [data] = payload;

  return (
    <div className="tw-p-3 tw-bg-white tw-border tw-shadow">
      <div className="tw-font-bold">{data.payload.choice}</div>
      <div>
        {`${__('Count')}:`}
        {data.payload.count}
      </div>
    </div>
  );
};

interface FormMultipleChoicePieChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

const FormMultipleChoicePieChart = (props: FormMultipleChoicePieChartProps): JSX.Element => {
  const { formAnswers = [] } = props;

  const choices: Record<string, Payload> = {};

  formAnswers.forEach((fa) => {
    const value = fa.value as string | string[];

    if (Array.isArray(value)) {
      value.forEach((v) => {
        choices[v] ||= { choice: v, count: 0 };
        choices[v].count += 1;
      });
      return;
    }

    choices[value] ||= { choice: value, count: 0 };
    choices[value].count += 1;
  });

  const chartData = Object.values(choices);

  return (
    <div>
      <ResponsiveContainer height={250} width="100%">
        <PieChart>
          <Pie
            data={chartData}
            outerRadius={80}
            label={({ value, percent }) => `${value} (${Math.floor((percent ?? 0) * 100).toFixed(0)}%)`}
            nameKey="choice"
            dataKey="count"
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${entry.choice}`} fill={pickColor(index)} />
            ))}
          </Pie>
          <Legend
            formatter={pieLegendFormatter}
          />
          <Tooltip content={ChoiceTooltip} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FormMultipleChoicePieChart;
