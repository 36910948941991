export const EN = {
  relativeTime: {
    future: __('in %s'),
    past: __('%s ago'),
    s: __('a few seconds'),
    m: __("a minute"),
    mm: __('%d minutes'),
    h: __('an hour'),
    hh: __('%d hours'),
    d: __('a day'),
    dd: __('%d days'),
    M: __('a month'),
    MM: __('%d months'),
    y: __('a year'),
    yy: __('%d years'),
  },
};
