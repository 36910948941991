import React, { useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import ShareReflectionForm from './components/ShareReflectionForm';
import ShareReflectionList from './ShareReflectionList';

interface ShareReflectionProp {
  reflectionId: Atlas.ReflectionID;
}

enum Page {
  List,
  Add,
}

const ShareReflection = (props: ShareReflectionProp): JSX.Element => {
  const { reflectionId } = props;

  const [page, setPage] = useState(Page.List);

  switch (page) {
    case Page.Add: {
      const handleBack = () => {
        setPage(Page.List);
      };

      return (
        <ShareReflectionForm
          key={reflectionId}
          reflectionId={reflectionId}
          onBack={handleBack}
        />
      );
    }

    case Page.List:
    default: {
      const handleNewShareBtnClick = () => {
        setPage(Page.Add);
      };

      return (
        <ShareReflectionList
          reflectionId={reflectionId}
          onNewShareClick={handleNewShareBtnClick}
        />
      );
    }
  }
};

export default ShareReflection;
