import React from 'react';
import { useParams } from 'react-router-dom';
import ReflectionComments from '../../../modules/reflections/reflection-comments/ReflectionComments';

const ReflectionPage = (): JSX.Element => {
  const params = useParams<{ reflectionId: string; }>();
  const reflectionId = Number(params.reflectionId);

  return (
    <ReflectionComments
      reflectionId={reflectionId}
    />
  );
};

export default ReflectionPage;
