import { useMutation } from 'react-query';
import { createVideoComment, CreateVideoCommentArgs, CreateVideoCommentResponse, FindVideoCommentsResponse } from '../../../api/videos/comments';
import { queryClient } from '../../withQueryClient';

const useCreateVideoCommentMutation = () => {
  const mutation = useMutation<CreateVideoCommentResponse, unknown, CreateVideoCommentArgs, unknown>(
    createVideoComment,
    {
      onSuccess: (result) => {
        const comment = result.data;

        if (comment.video_id) {
          void queryClient.invalidateQueries<FindVideoCommentsResponse>(['videos', comment.video_id, 'comments']);
        }
      },
    },
  );

  return mutation;
};

export default useCreateVideoCommentMutation;
