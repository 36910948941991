import { useMutation, UseMutationResult } from 'react-query';
import { updateMilestone, UpdateMilestoneArguments, UpdateMilestoneResponse } from '../../api/index';
import { queryClient } from '../../../../../common/hooks/withQueryClient';
import * as Atlas from '../../../../../common/types/Atlas';

type UseUpdateMilestoneMutationResult = 
  UseMutationResult<UpdateMilestoneResponse, unknown, UpdateMilestoneArguments>

const useUpdateMilestoneMutation = (
  groupId: Atlas.GroupID,
  milestoneId: Atlas.MilestoneIdentifier,
): UseUpdateMilestoneMutationResult => {
  const mutation = useMutation(updateMilestone, {
    onSuccess: () => {
      queryClient.invalidateQueries(['groups', groupId, 'milestones', milestoneId]);
      queryClient.invalidateQueries(['groups', groupId, 'milestones']);
    }
  });
  return mutation;
};

export default useUpdateMilestoneMutation;