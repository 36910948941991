import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { FindGroupsArguments, FindGroupsResponse, findGroups } from '../../../api/groups';

const useFindGroupsInfiniteQuery = (
  args: FindGroupsArguments,
  options?: { staleTime?: number; enabled?: boolean; },
): UseInfiniteQueryResult<FindGroupsResponse> => (
  useInfiniteQuery(
    ['groups', 'infinite', args],
    (context) => findGroups({
      params: {
        ...args.params,
        page: context.pageParam,
      },
    }),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.metadata.current_page + 1;
        if (nextPage > lastPage.metadata.total_pages) { return null; }
        return nextPage;
      },
      staleTime: 60000,
      ...options,
    },
  )
);

export default useFindGroupsInfiniteQuery;
