import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useFindVideoPlaylistsQuery from '../../../common/hooks/api/videos/useFindVideoPlaylistsQuery';
import * as Atlas from '../../../common/types/Atlas';
import JwPlayer, { JwPlayerPlaylistItem, JwPlayerProvider, useJwPlayer } from '../../jwplayer/JwPlayer';
import useRandomId from '../../hooks/useRandomId';
import { useIntersection } from 'react-use';
import useFindVideoQuery from '../../../common/hooks/api/videos/useFindVideoQuery';
import { getVideoChannel } from '../show/utils';
import { defaultJwplayerConfig } from '../../../common/utils/jwplayer-defaults';

interface VideoSyncProps {
  videoId: Atlas.VideoID;
  onPrepStateChange: (prepState: Atlas.VideoPrepState) => void;
}

const VideoSync: React.FC<VideoSyncProps> = (props) => {
  const { videoId, onPrepStateChange } = props;

  const videoQuery = useFindVideoQuery({ videoId }, { refetchInterval: 30000 });

  useEffect(() => {
    if (!videoQuery.isSuccess) { return; }

    const video = videoQuery.data.data;

    const channel = getVideoChannel(video);
    if (!channel) { return; }

    onPrepStateChange(channel.prep_state);
  }, [videoQuery]);

  return null;
};

interface VideoCommentPlaceholderProps {
  onPlayClick: () => void;
}

const VideoCommentPlaceholder: React.FC<VideoCommentPlaceholderProps> = (props) => {
  const { onPlayClick } = props;

  const ref = useRef<HTMLDivElement>(null);
  const observer = useIntersection(ref, {});
  const [visible, setVisible] = useState(false);

  if (!visible && observer?.isIntersecting) {
    setVisible(true);
  }

  useEffect(() => {
    if (!visible) { return; }

    onPlayClick();
  }, [visible]);

  return (
    <div ref={ref}>
      <button type="button" className="tw-btn tw-btn-block tw-btn-sm" onClick={onPlayClick}>
        {__('Load video')}
      </button>
    </div>
  );
};

interface VideoCommentPlayerProps {
  autoPlay: boolean;
  aspectRatio: string;
  playlists: Atlas.Playlist[];
  onAutoPlay: () => void;
  onPlay?: () => void;
  onComplete: () => void;
}

const VideoCommentPlayer = (props: VideoCommentPlayerProps) => {
  const {
    autoPlay,
    aspectRatio,
    playlists,
    onAutoPlay,
    onPlay,
    onComplete,
  } = props;

  const player = useJwPlayer({
    onPlay,
    onComplete,
  });

  const [autoPlaying, setAutoPlaying] = useState(autoPlay);
  if (autoPlay && !autoPlaying) {
    setAutoPlaying(true);
  }

  useEffect(() => {
    if (autoPlaying) {
      onAutoPlay();
    }
  }, [autoPlaying, onAutoPlay]);

  useEffect(() => {
    if (!autoPlaying) { return; }
    if (!player?.player) { return; }
    setAutoPlaying(false);

    player.player.pause();
    player.player.seek(0);
    player.player.play();
  }, [autoPlay, player]);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    player?.player?.setConfig({ aspectratio: aspectRatio });
  }, [aspectRatio, player?.player]);

  const playlist = useMemo(() => playlists.map((pl) => ({
    ...pl,
    title: null,
    tracks: pl.tracks.map((plt) => {
      switch (plt.kind) {
        case 'captions': return {
          ...plt,
          label: plt.label ?? __('Comments'),
        };

        default: return plt;
      }
    }),
  })), [playlists]);

  return (
    <div className="tw-rounded tw-overflow-hidden">
      <JwPlayer
        configuration={defaultJwplayerConfig}
        playlist={playlist as unknown as JwPlayerPlaylistItem[]}
      />
    </div>
  );
};

interface AttachedVideoContentProps {
  autoPlay: boolean;
  videoComment: Atlas.VideoComment;
  onAutoPlay: () => void;
  onComplete: () => void;
}

const AttachedVideoContent: React.FC<AttachedVideoContentProps> = (props) => {
  const {
    autoPlay,
    videoComment,
    onAutoPlay,
    onComplete,
  } = props;

  const id = useRandomId();
  const context = useMemo(() => ({ id }), [id]);
  const videoQuery = useFindVideoQuery({ videoId: videoComment.id });
  const playlistsQuery = useFindVideoPlaylistsQuery(videoComment.id);

  switch (playlistsQuery.status) {
    case 'loading': {
      return (
        <div className="tw-flex tw-justify-center tw-py-4">
          <i className="fa fa-spin fa-circle-o-notch fa-2x tw-text-info" />
        </div>
      );
    }

    case 'success': {
      const playlists = playlistsQuery.data.data;

      const aspectRatio = (() => {
        const video = videoQuery.data?.data;
        const activeChannel = video && getVideoChannel(video);

        return activeChannel?.aspect_ratio ?? '16:9';
      })();

      return (
        <JwPlayerProvider value={context}>
          <VideoCommentPlayer
            aspectRatio={aspectRatio}
            autoPlay={autoPlay}
            playlists={playlists}
            onAutoPlay={onAutoPlay}
            onComplete={onComplete}
          />
        </JwPlayerProvider>
      );
    }

    case 'error': {
      return (
        <div>
          {__('Oops! Something went wrong.')}
        </div>
      );
    }

    default: return null;
  }
};

const AttachedVideo: React.FC<AttachedVideoContentProps> = (props) => {
  const {
    autoPlay,
    videoComment,
    onAutoPlay,
    onComplete,
  } = props;

  const [prepState, setPrepState] = useState(videoComment.prep_state);
  const [mounted, setMounted] = useState(autoPlay);

  if (autoPlay && !mounted) {
    setMounted(true);
  }

  if (!mounted) {
    const handlePlayClick = () => {
      setMounted(true);
    };

    return (
      <VideoCommentPlaceholder onPlayClick={handlePlayClick} />
    );
  }

  const handlePrepStateChange = (
    nextPrepState: Atlas.VideoPrepState,
  ) => {
    setPrepState(nextPrepState);
  };

  switch (prepState) {
    case Atlas.VideoPrepState.Ready: return (
      <AttachedVideoContent
        autoPlay={autoPlay}
        videoComment={videoComment}
        onAutoPlay={onAutoPlay}
        onComplete={onComplete}
      />
    );

    case Atlas.VideoPrepState.Transcoding: return (
      <div className="tw-badge tw-gap-1 tw-py-3">
        <VideoSync videoId={videoComment.id} onPrepStateChange={handlePrepStateChange} />

        <div>
          <i className="fa fa-circle-o-notch fa-spin" />
        </div>

        <div>
          {__('Processing media')}
        </div>
      </div>
    );

    case Atlas.VideoPrepState.Uploading: return (
      <div className="tw-badge tw-gap-1 tw-py-3">
        <VideoSync videoId={videoComment.id} onPrepStateChange={handlePrepStateChange} />

        <div>
          <i className="fa fa-circle-o-notch fa-spin" />
        </div>

        <div>
          {__('Preparing media')}
        </div>
      </div>
    );

    case Atlas.VideoPrepState.Waiting: return (
      <div className="tw-badge tw-gap-1 tw-py-3">
        <VideoSync videoId={videoComment.id} onPrepStateChange={handlePrepStateChange} />

        <div>
          <i className="fa fa-circle-o-notch fa-spin" />
        </div>

        <div>
          {__('Waiting for media')}
        </div>
      </div>
    );

    case Atlas.VideoPrepState.Error:
    case Atlas.VideoPrepState.Undecryptable:
    case Atlas.VideoPrepState.Unsupported:
    default: return (
      <div className="tw-badge tw-badge-error tw-gap-1 tw-py-3">
        <div>
          <i className="fa fa-circle-o-notch fa-spin" />
        </div>

        <div>
          {__('Error processing media')}
        </div>
      </div>
    )
  }
}

export default AttachedVideo;
