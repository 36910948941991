import React, { useState, useEffect } from 'react';
import FormBuilder from './FormBuilder';
import { formsRoute, formRoute, generateFetchHeaders } from '../../common/api/routes';
import formElementValidator from './formElementValidator';
import * as Atlas from '../../common/types/Atlas';
import { withQueryClient } from '../../common/hooks/withQueryClient';
import useFindFeatureToggleQuery from '../../common/hooks/api/features/useFindFeatureToggleQuery';

interface AddEditFormProps {
  formId: Atlas.FormID;
}

interface AddEditFormState {
  loading: boolean;
  saving?: boolean;
  saved?: boolean;
  form: DeepPartial<Atlas.Form>;
  formChanges: DeepPartial<Atlas.Form>;
}

const NewFormBuilder = (props: AddEditFormProps): JSX.Element => {
  const aiFormsEnabled = useFindFeatureToggleQuery({ featureToggle: 'ai_forms' }).data?.enabled ?? false;

  const { formId } = props;

  const [state, setState] = useState<AddEditFormState>({
    loading: !!formId,
    form: {clone_allowed: false},
    formChanges: {clone_allowed: false},
  });

  useEffect(() => {
    if (!formId) { return; }
    setState((s) => ({ ...s, loading: true }));
    fetch(formRoute({ formId })).then((r) => {
      if (r.status === 200) { return r.json(); }
      throw new Error('form-failed-to-save');
    }).then((form: Atlas.Form) => {
      setState((s) => ({
        ...s, loading: false, form, formChanges: {},
      }));
    }, (error) => {
      setState((s) => ({ ...s, loading: false, error }));
    });
  }, [formId]);

  useEffect(() => {
    if (state.loading) { return; }
    const path = state.form.id ? `/forms/${state.form.id}/edit` : '/forms/new';
    if (path === window.location.pathname) { return; }
    window.history.pushState('', '', path);
  }, [state.form.id, state.loading]);

  const formHasChanges = state.formChanges && !!Object.keys(state.formChanges).length;

  useEffect(() => {
    if (!formHasChanges) { return undefined; }

    setState((s) => ({ ...s, saved: false }));

    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [formHasChanges]);

  const handleChange = (changes: DeepPartial<Atlas.Form>) => {
    if (state.loading || state.saving) { return; }

    setState((s) => ({
      ...s,
      saved: false,
      formChanges: {
        ...s.formChanges || {},
        ...changes,
      },
    }));
  };

  const handleCancel = () => {
    if (state.saving) { return; }
    window.location.href = (formsRoute() + "?filter=my_insights");
  };

  const handleSave = formHasChanges ? (() => {
    if (state.loading || state.saving) { return undefined; }
    setState((s) => ({ ...s, saving: true }));

    const fetchParams = {
      credentials: 'same-origin' as RequestCredentials,
      headers: generateFetchHeaders(),
      body: JSON.stringify(state.formChanges),
    };

    const promise = state.form.id ? (
      fetch(formRoute({ formId: state.form.id }), { method: 'PATCH', ...fetchParams })
    ) : (
      fetch(formsRoute(), { method: 'POST', ...fetchParams })
    );

    return promise.then((r) => {
      if (r.status === 200) { return r.json(); }
      throw new Error('form-failed-to-save');
    }).then((form: Atlas.Form) => {
      setState((s) => ({
        ...s, form, formChanges: {}, saving: false, saved: true,
      }));
    }, (error) => {
      setState((s) => ({
        ...s, saved: false, saving: false, error,
      }));
      throw error;
    });
  }) : null;

  const form = {
    ...state.form || {},
    ...state.formChanges || {},
  } as Partial<Atlas.Form>;

  const isFormValid = (
    !!form.name
    && !!form.elements?.length
    && form.elements.every((fe) => formElementValidator(fe))
  );

  const isNewForm = !state.form.id;

  return (
    <div>
      <div className="tw-breadcrumbs">
        <ul>
          <li>
            <a href="/forms">
              {__('Insights')}
            </a>
          </li>
          <li>
            {isNewForm ? __('New Insight') : __('Edit Insight')}
          </li>
        </ul>
      </div>

      <div className="tw-py-4">
        <FormBuilder
          form={form}
          isFormValid={isFormValid}
          isChanged={formHasChanges}
          loading={state.loading}
          saving={!!state.saving}
          saved={!!state.saved}
          onChange={handleChange}
          onCancel={handleCancel}
          onSave={handleSave}
          isNewForm={isNewForm}
        />
      </div>
    </div>
  );
};

export default withQueryClient(NewFormBuilder);
