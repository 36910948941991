import React from "react";
import { OptionProps, components, SingleValueProps } from "react-select";
import { SearchResult } from "../../api/search/legacy";

export interface SearchResultOption {
  value: number;
  result: SearchResult;
}

export const SearchResultSelectOption = (props: OptionProps<SearchResultOption, false>): JSX.Element => {
  const { result } = props.data as SearchResultOption;

  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <img src={result.image_url} className="mr-2 p-0 thumbnail rounded-circle" style={{ height: '1.6rem', width: '1.6rem' }} />
        <span>{result.name}</span>
      </div>
    </components.Option>
  );
};

export const SearchResultSelectSingleValue = (props: SingleValueProps<SearchResultOption>): JSX.Element => {
  const { result } = props.data;

  return (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center">
        <img src={result.image_url} className="mr-2 p-0 thumbnail rounded" style={{ height: '1.6rem', width: '1.6rem' }} />
        <span>{result.name}</span>
      </div>
    </components.SingleValue>
  );
};

const selectComponents = {
  Option: SearchResultSelectOption,
  SingleValue: SearchResultSelectSingleValue
};

export { selectComponents };
