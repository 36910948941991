import { queryClient } from '../../../../webpack/common/hooks/withQueryClient';

function newVideoAjaxPayload(opts, name) {
  const payload = {
    filename: name,
    video: {
      reflection_id: opts.reflectionId,
      channels: 1, // By default, request a single-channel video part.
    },
  };

  if (opts.upload_source) {
    payload.video.upload_source = opts.upload_source;
  }

  if (opts.channels) {
    payload.video.channels = opts.channels;
  }

  return payload;
}

const invalidateQueries = () => {
  void queryClient.invalidateQueries(['videos']);
  void queryClient.invalidateQueries(['reflections']);
};

export default {
  onStart(data, name, sendData) {
    void $.ajax({
      url: '/videos',
      type: 'POST',
      dataType: 'json',
      data: newVideoAjaxPayload(data, name),
      success(video) {
        sendData(data, video.media_keys, video.id);

        invalidateQueries();
      },
    });
  },
  onStop() {
    invalidateQueries();
  },
  onFail() {
    invalidateQueries();
  },
  onProgress() {},
};
