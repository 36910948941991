import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders } from '../../routes';

const videoRoute = (args: {
  videoId: Atlas.VideoID;
}): string => `/api/v0/videos/${args.videoId}`;

export interface FindVideoParams {
  videoId: Atlas.VideoID;
}

export interface FindVideoArguments {
  params: FindVideoParams;
}

export interface FindVideoResponse {
  data: Atlas.Video;
}

export const findVideo = async (
  args: FindVideoArguments,
): Promise<FindVideoResponse> => {
  const { videoId } = args.params;

  const url = videoRoute({ videoId });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Video>(body.data);

  return { data };
};

export interface UpdateVideoParams {
  videoId: Atlas.VideoID;
}

export interface UpdateVideoBody {
  description?: Atlas.VideoDescription | null;
  override_channel?: Atlas.LeftOrRight | null;
}

export interface UpdateVideoArguments {
  params: UpdateVideoParams;
  body: UpdateVideoBody;
}

export interface UpdateVideoResponse {
  data: Atlas.Video;
}

export const updateVideo = async (
  args: UpdateVideoArguments,
): Promise<UpdateVideoResponse> => {
  const { videoId } = args.params;

  const url = videoRoute({ videoId });

  const response = await fetch(url, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updateVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Video>(body.data);

  return { data };
};

export interface DestroyVideoParams {
  videoId: Atlas.VideoID;
}

export interface DestroyVideoArguments {
  params: ClipVideoParams;
}

export interface DestroyVideoResponse {
  data: Atlas.Video;
}

export const destroyVideo = async (
  args: DestroyVideoArguments,
): Promise<DestroyVideoResponse> => {
  const { videoId } = args.params;

  const url = videoRoute({ videoId });

  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'destroyVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Video>(body.data);

  return { data };
};

//

const clipVideoRoute = (args: {
  videoId: Atlas.VideoID
}): string => `/api/v0/videos/${args.videoId}/clip`;

export interface ClipVideoParams {
  videoId: Atlas.VideoID;
}

export type ClipVideoBody = {
  start: Atlas.Seconds;
  end: Atlas.Seconds;
} & ({
  reflectionId: Atlas.ReflectionID;
} | {
  reflectionName: Atlas.ReflectionName;
})

export interface ClipVideoArguments {
  params: ClipVideoParams;
  body: ClipVideoBody;
}

export interface ClipVideoResponse {
  data: Atlas.Video;
}

export const clipVideo = async (
  args: ClipVideoArguments,
): Promise<ClipVideoResponse> => {
  const { videoId } = args.params;

  const url = clipVideoRoute({ videoId });

  const reqBody = (() => {
    if ('reflectionId' in args.body) {
      return { reflection_id: args.body.reflectionId };
    }

    return { reflection_name: args.body.reflectionName };
  })();

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify({
      ...reqBody,
      start: args.body.start,
      end: args.body.end,
    }),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'clipVideo');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Video>(body.data);

  return { data };
};
