import { useQuery, UseQueryResult } from 'react-query';
import { findExpandedAssignmentSubmission, FindExpandedAssignmentSubmissionResponse } from '../../../api/assignments/assignment-submissions';
import * as Atlas from '../../../types/Atlas';

const useFindAssignmentSubmissionQuery = (
  assignmentId: Atlas.AssignmentID,
  assignmentSubmissionId: Atlas.AssignmentSubmissionID,
): UseQueryResult<FindExpandedAssignmentSubmissionResponse> => (
  useQuery([
    'assignments',
    assignmentId,
    'submissions',
    assignmentSubmissionId,
  ], () => findExpandedAssignmentSubmission({ params: { assignmentId, assignmentSubmissionId } }))
);

export default useFindAssignmentSubmissionQuery;
