import { useQuery, UseQueryResult } from 'react-query';
import { findVideoPlaylists, FindVideoPlaylistsResponse } from '../../../api/videos/[videoId]/playlists';
import * as Atlas from '../../../types/Atlas';

const useFindVideoPlaylistsQuery = (
  videoId: Atlas.VideoID,
  options?: { staleTime?: number; enabled?: boolean; },
): UseQueryResult<FindVideoPlaylistsResponse> => (
  useQuery(
    ['videos', videoId, 'playlists'],
    () => findVideoPlaylists({ params: { videoId } }),
    options,
  )
);

export default useFindVideoPlaylistsQuery;
