import React from 'react';
import { groupPageRoute } from '../../common/api/groups/pages';
import { Button } from '../../common/components/Button';
import * as Atlas from '../../common/types/Atlas';

interface GroupPage {
  id: Atlas.PageID;
  pageNo: number;
}

interface PageProgressProps {
  groupId: Atlas.PageID;
  page: GroupPage;
  pages: GroupPage[];
}

const PageProgress = (props: PageProgressProps): JSX.Element => {
  const { groupId, page, pages } = props;

  const sortedPages = [...pages].sort((a, b) => a.pageNo - b.pageNo),
        pageIndex = sortedPages.findIndex(p => p.id === page.id),
        progress = ((pageIndex + 1) / pages.length) * 100,
        prevPage = sortedPages[pageIndex - 1],
        nextPage = sortedPages[pageIndex + 1];

  const pageUrl = (groupPage?: GroupPage) => {
    if (!groupPage) { return; }
    return groupPageRoute({ groupId, pageId: groupPage.id });
  };

  return (
    <div>
      <div className="tw-iris-element-box tw-mb-4">
        <progress className="tw-progress tw-mb-0 tw-progress-success tw-w-full" value={progress} max="100"></progress>
      </div>
      <div className="d-flex justify-content-between">
        <Button tag="a" disabled={!prevPage} href={pageUrl(prevPage)} className="tw-btn tw-btn-sm tw-rounded-full">
          <i className="fa fa-chevron-left tw-mr-1"></i>
          {__('Prev')}
        </Button>

        <Button tag="a" color={nextPage ? 'success' : 'light'} disabled={!nextPage} href={pageUrl(nextPage)} className="tw-btn tw-btn-success tw-btn-sm tw-rounded-full">
          {__('Next')}
          <i className="fa fa-chevron-right tw-ml-1"></i>
        </Button>
      </div>
    </div>
  );
};

export default PageProgress;
