import { matchPath } from 'react-router-dom';
import { buildInternalUrl } from '../../utils/url.utils';
import { NavigationType } from './types';

// Not all react router links support full client side rendering
// Add supported routes here
const supportedRoutes = [
  '/reflections/:reflectionId',
  '/reflections/:reflectionId/files',
  '/reflections/:reflectionId/forms',
  '/reflections/:reflectionId/forms/:formId',
  '/reflections/:reflectionId/videos',
];

export const isSupportedReactRouterPath = (pathname: string) => {
  return supportedRoutes.some((route) => matchPath(route, pathname));
};

export const getNavigationType = (urlString: string) => {
  const url = buildInternalUrl(urlString);
  if (!url) { return NavigationType.NativeExternal; }

  const isSupported = isSupportedReactRouterPath(url.pathname);
  if (!isSupported) { return NavigationType.NativeInternal; }

  return NavigationType.ReactRouter;
};
