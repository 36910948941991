import React from 'react';
import PropTypes from 'prop-types';
import TimelineSegments from '../TimelineSegments';
import { renderTimestamp } from '../../../common/components/timerTimestamp';

const timeIntervalToVideoSegment = timeInterval => ({
  video_id: timeInterval.start.video_id,
  start: timeInterval.start.time,
  duration: timeInterval.end.time - timeInterval.start.time
});

const FormTimerElement = props => {
  const { formAnswer } = props;
  const timerValue = formAnswer.timer_value || [];

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      {timerValue.map(({ choice, intervals }) => {
        const timeline = intervals.map(timeIntervalToVideoSegment);

        const total = timeline.reduce((total, vs) => {
          const start = Math.floor(vs.start / 1000) * 1000,
                end = Math.floor((vs.start + vs.duration) / 1000) * 1000;

          return total + (end - start);
         }, 0);

        return (
          <div key={choice} className="tw-border-b last:tw-border-0">
            <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
              {choice}

              <div className="tw-badge tw-badge-sm tw-badge-info tw-ml-3" title={__('Total duration')}>
                {renderTimestamp(total)}
              </div>
            </div>

            <TimelineSegments
              timeline={timeline}
            />
          </div>
        );
      })}
    </div>
  );
};

FormTimerElement.propTypes = {
  formAnswer: PropTypes.object.isRequired,
  formElement: PropTypes.object.isRequired
};

export default FormTimerElement;
