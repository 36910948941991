import { useMutation, UseMutationResult } from 'react-query';
import {
  createGroupPage, CreateGroupPageBody, CreateGroupPageParams, CreateGroupPageResponse,
} from '../../../api/groups/[groupId]/pages';
import { queryClient } from '../../withQueryClient';

const useCreateGroupPageMutation = (
  params: CreateGroupPageParams,
): UseMutationResult<CreateGroupPageResponse, unknown, CreateGroupPageBody, unknown> => {
  const mutation = useMutation((body: CreateGroupPageBody) => createGroupPage({ params, body }), {
    onSuccess: (result) => {
      const page = result.data;
      queryClient.setQueryData(['pages', page.id], result);
      void queryClient.invalidateQueries(['pages'], { exact: true });
      void queryClient.invalidateQueries(['groups', page.group_id, 'pages']);
    },
  });

  return mutation;
};

export default useCreateGroupPageMutation;
