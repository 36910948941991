import React from 'react';
import * as Atlas from '../../../common/types/Atlas';
import { GenericOptionType } from '../../../common/types/Generic';
import { Options } from 'react-select';
import { useCurrentUser } from '../../../common/hooks/withCurrentUser';
import AssessorGroupAssignmentSubmissions from './AssessorAssignmentSubmissions';
import AssesseeGroupAssignmentSubmissions from './AssesseeAssignmentSubmissions';
import Spinner from '../../../common/components/Spinner';

interface UserIdOptionType {
  label: string;
  value: Atlas.UserID;
}

interface DeleteSubmissionPromptOptions {
  prompt?: String;
  typeToConfirm?: boolean;
  selectedSubmissionId?: Atlas.AssignmentSubmissionID;
}

interface SubmissionCompleteOptionType {
  label: string;
  value: Atlas.SubmissionComplete;
}

interface GroupAssignmentSubmissionsProps {
  group: Atlas.Group;
  assignment: Atlas.Assignment;
  submissions: Atlas.ExpandedAssignmentSubmission[];
  submissionsLoading: boolean;
  totalSubmissions: number;
  filter: {
    userId?: UserIdOptionType;
    complete?: SubmissionCompleteOptionType;
  };
  onFilterChange(name: string, value: GenericOptionType | Options<GenericOptionType>): void;
  reloadSubmissions(): Promise<void>;
  onDeleteSubmission(options: DeleteSubmissionPromptOptions): void;
}

const CenteredSpinner = () => (
  <div className="d-flex align-items-center justify-content-center my-5">
    <Spinner color="info" />
  </div>
);

const GroupAssignmentSubmissions = (props: GroupAssignmentSubmissionsProps): JSX.Element => {
  const { group } = props;
  const { user, userLoading } = useCurrentUser();

  if (!group || !user || userLoading) { return <CenteredSpinner />; }

  const isAssessor = user && (
    group.group?.administrators.user_ids.includes(user.id) ||
    group.group?.assessors.user_ids.includes(user.id)
  );

  if (isAssessor) {
    return <AssessorGroupAssignmentSubmissions {...props} />
  }

  return <AssesseeGroupAssignmentSubmissions {...props} />
};

export default GroupAssignmentSubmissions;
