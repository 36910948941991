import React from 'react';
import moment from 'moment';

import * as Atlas from '../../../common/types/Atlas';
import classNames from 'classnames';
import { Badge } from '../../../common/components/Badge';

interface AssignmentDetailsProps {
  assignment: Atlas.Assignment;
  date?: Date;
  name?: string;
}

const AssignmentDetails = (props: AssignmentDetailsProps): JSX.Element => {
  const { assignment, date, name } = props;

  const isOverdue = assignment.due_date && assignment.due_date < (date || new Date());

  return (
    <div>
      <div className="d-flex align-items-end justify-content-between flex-wrap-reverse tw-mb-2">
        <h3 className="flex-fill my-0 !tw-text-xl tw-font-semibold tw-mb-0">
          {assignment.name}
        </h3>

        <div className="flex-fill d-flex align-items-center justify-content-end">
          {assignment.due_date ? (
            <div className="pl-2 ml-2 text-right">
              <small className="text-muted">
                {isOverdue ? (
                  <Badge color="danger" className="mr-1">
                    {__('Overdue')}
                  </Badge>
                ) : null}
                Due
              </small>
              <div>{moment(assignment.due_date).format('l')}</div>
            </div>
          ) : null}
          {date ? (
            <div className={classNames('pl-2 ml-2 text-right', { 'border-left': assignment.due_date })}>
              <small className="text-muted">
                Submitted at
              </small>
              <div>{moment(date).format('l')}</div>
            </div>
          ) : null}
          {name ? (
            <div className={classNames('pl-2 ml-2 text-right', { 'border-left': date })}>
              <small className="text-muted">
                Submitted by
              </small>
              <div>{name}</div>
            </div>
          ) : null}
        </div>
      </div>

      {assignment.description ? (
        <p className="mb-0 text-muted">
          {assignment.description}
        </p>
      ) : null}
    </div>
  );
};

export default AssignmentDetails;
