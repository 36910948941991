import React from 'react';
import { useParams } from 'react-router-dom';
import MilestonesListContainer from '../../../../src/features/milestones/components/MilestonesListContainer';

const GroupMilestonesPage = (): JSX.Element => {
  const params = useParams<{ groupId: string; }>();
  const groupId = Number(params.groupId);

  return (
    <MilestonesListContainer groupId={groupId}/>
  );
};

export default GroupMilestonesPage;
