/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function withQueryClient<P>(Component: React.ComponentType<P>) {
  const WithQueryClient: React.FC<P & JSX.IntrinsicAttributes> = (props) => (
    <QueryClientProvider client={queryClient}>
      <Component {...props} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );

  return WithQueryClient;
}
