import { useQuery, UseQueryResult } from 'react-query';
import { findMilestone, FindMilestoneResponse } from '../../api/index';
import * as Atlas from '../../../../../common/types/Atlas';

const useFindMilestoneQuery = (
  groupId: Atlas.GroupID,
  milestoneId: Atlas.MilestoneIdentifier,
  options?: { staleTime?: number; enabled?: boolean; },
): UseQueryResult<FindMilestoneResponse> => (
  useQuery(
    ['groups', groupId, 'milestones', milestoneId],
    () => findMilestone({ params: { groupId, milestoneId } }),
    {
      staleTime: 60000,
      retry: false,
      ...options,
    },
  )
);

export default useFindMilestoneQuery;
