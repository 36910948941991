import React, { ChangeEvent, FormEvent } from 'react';
import { Button } from '../../../common/components/Button';
import { Textarea } from '../../../common/components/Input';
import * as Atlas from '../../../common/types/Atlas';
import AssignmentResubmissionHandler from './AssignmentResubmissionHandler';

interface AssignmentSubmissionAssessorFormProps {
  submission: Partial<Atlas.AssignmentSubmission>;
  showGrade: boolean;
  showFeedback: boolean;
  onSubmit(): void;
  onChange(changes: Partial<Atlas.AssignmentSubmission>): void;
}

const AssignmentSubmissionAssessorForm = (props: AssignmentSubmissionAssessorFormProps): JSX.Element => {
  const { submission } = props;

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;

    props.onChange({ [name]: value });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {props.showGrade && (
        <label>
          <h5 className="control-label tw-font-semibold tw-text-primary mt-0">
            {__('Grade')}
          </h5>
          <input
            style={{height: "35px"}}
            type="text"
            name="grade"
            value={submission.grade || ''}
            onChange={handleInputChange} />
        </label>
      )}

      {props.showFeedback && (
        <label>
          <h5 className="control-label tw-font-semibold tw-text-primary mt-0">
            {__('Feedback')}
          </h5>

          <Textarea
            className="tw-p-3 tw-mb-4"
            name="feedback"
            minRows={4}
            maxRows={20}
            value={submission.feedback || ''}
            onChange={handleInputChange} />
        </label>
      )}
      <div className="tw-flex tw-justify-between">
        {(props.showGrade || props.showFeedback || submission.complete === Atlas.SubmissionComplete.Incomplete) && (
          <Button type="submit" color="success" size="large">
            {submission.complete === Atlas.SubmissionComplete.Incomplete ? (
              __('Mark complete')
            ) : (
              __('Save')
            )}
          </Button>
        )}
        {props.submission.assignment_id && props.submission.id && (
          <AssignmentResubmissionHandler 
            submission={props.submission as Atlas.AssignmentSubmission}
          />
        )}
      </div>
    </form>
  );
};

export default AssignmentSubmissionAssessorForm;
