import React, { useState } from 'react';
import { Modal, ModalBox } from '../../../common/components/TailwindModal';
import * as Atlas from '../../../common/types/Atlas';
import ReflectionList from './reflection-list';
import Spinner from '../../../common/components/Spinner';

interface VideoClipperModalProps {
  userId: Atlas.UserID;
  isOpen: boolean;
  onClose: () => void;
  onNewReflectionSelect: (name: Atlas.ReflectionName) => Promise<void>;
  onReflectionSelect: (reflectionId: Atlas.ReflectionID) => Promise<void>;
}

const VideoClipperModal = (props: VideoClipperModalProps) => {
  const {
    userId, isOpen, onClose, onNewReflectionSelect, onReflectionSelect,
  } = props;

  const [state, setState] = useState({
    clipping: false,
    newReflectionName: '',
  });

  const handleClose = () => {
    onClose();
  };

  const handleNewReflectionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setState((s) => ({ ...s, newReflectionName: value }));
  };

  const handleNewReflectionSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!state.newReflectionName) { return; }

    setState((s) => ({ ...s, clipping: true }));
    onNewReflectionSelect(state.newReflectionName).finally(() => {
      setState((s) => ({ ...s, clipping: false }));
    });
  };

  const handleReflectionClick = (reflectionId: Atlas.ReflectionID) => {
    setState((s) => ({ ...s, clipping: true }));
    onReflectionSelect(reflectionId).finally(() => {
      setState((s) => ({ ...s, clipping: false }));
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalBox className="tw-p-0">
        <div className="tw-flex tw-justify-end tw-mb-2">
          <button type="button" className="tw-link tw-link-neutral tw-no-underline tw-text-2xl tw-font-bold tw-px-4 tw-py-2" onClick={handleClose}>
            ×
          </button>
        </div>

        {state.clipping ? (
          <>
            <div className="tw-p-4 tw-mb-6 bg-primary tw-text-white">
              <div className="tw-text-lg tw-text-center">
                {__('Your request is being processed, please don\'t reload the page')}
              </div>
            </div>

            <div className="tw-px-4 tw-my-10 tw-flex tw-justify-center tw-items-center">
              <Spinner color="info" />
            </div>
          </>
        ) : (
          <>
            <div className="tw-p-4 tw-mb-6 bg-primary tw-text-white">
              <div className="tw-text-lg tw-text-center">
                {__('Where would you like to save your new video?')}
              </div>
            </div>

            <div className="tw-px-4 tw-my-4">
              <div className="tw-mb-4">
                <form onSubmit={handleNewReflectionSubmit}>
                  <label className="tw-join">
                    <input
                      required
                      className="tw-input tw-input-bordered tw-flex-1 tw-join-item"
                      placeholder={__('Enter a new reflection name')}
                      value={state.newReflectionName}
                      onChange={handleNewReflectionNameChange}
                    />
                    <button type="submit" className="tw-btn tw-btn-success tw-join-item">
                      {__('Create')}
                    </button>
                  </label>
                </form>
              </div>

              <div className="tw-divider tw-uppercase">
                {__('Or')}
              </div>

              <ReflectionList
                userId={userId}
                onReflectionSelect={handleReflectionClick}
              />
            </div>
          </>
        )}
      </ModalBox>
    </Modal>
  );
};

export default VideoClipperModal;
