import { useQuery, UseQueryResult } from 'react-query';
import { findGroupDiscussions, FindGroupDiscussionsParams, FindGroupDiscussionsResponse } from '../../../api/groups/[groupId]/discussions';

const useFindGroupDiscussionsQuery = (
  params: FindGroupDiscussionsParams,
  options?: { enabled?: boolean; },
): UseQueryResult<FindGroupDiscussionsResponse> => (
  useQuery(
    ['groups', params.groupId, 'discussions', ], 
    () => findGroupDiscussions({ params }),
    { ...options },
  )
);

export default useFindGroupDiscussionsQuery;
