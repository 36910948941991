import React, { useContext, useEffect, useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import { msToTimestamp } from '../../../common/utils/utils';
import VideoEditorContext from '../context';
import useReorderVideoMutation from '../../../common/hooks/api/videos/useReorderVideoMutation';
import useDestroyVideoMutation from '../../../common/hooks/api/videos/useDestroyVideoMutation';
import PlaylistItemAdvancedControls from './advanced-controls';
import useUpdateVideoMutation from '../../../common/hooks/api/videos/useUpdateVideoMutation';
import useVideoPlayer from '../../jwplayer/useVideoPlayer';
import CaptionUploadButton from './caption-upload-button';
import ReplicateVideoModal from './replicate-video-modal';
import useFindFeatureToggleQuery from '../../../common/hooks/api/features/useFindFeatureToggleQuery';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import VideoDownloadRequestModal from './video-download-request-modal';
import { getVideoChannel } from '../../reflections/show/utils';
import ImageWithFallback from '../../../common/components/ImageWithFallback';
import { Modal, ModalBox } from '../../../common/components/TailwindModal';
import useTranscribeVideoMutation from '../../../common/hooks/api/videos/useTranscribeVideoMutation';
import VideoCaptionsModal from './video-captions-modal';

interface PlaylistItemProps {
  video: Atlas.Video;
  position: number;
  lastPosition: number;
  onEditClick: () => void;
}

interface PlaylistItemState {
  editingDescription: boolean;
  replicateOpen: boolean;
  transcribeOpen: boolean;
  exportCaptionsOpen: boolean;
  requestDownloadOpen: boolean;
  description?: string;
}

const PlaylistItem = (props: PlaylistItemProps): JSX.Element | null => {
  const { video, position, lastPosition, onEditClick } = props;

  const videoEditor = useContext(VideoEditorContext);

  const [state, setState] = useState<PlaylistItemState>({
    editingDescription: false,
    replicateOpen: false,
    exportCaptionsOpen: false,
    transcribeOpen: false,
    requestDownloadOpen: false,
    description: video.description,
  });

  useEffect(() => {
    setState((s) => {
      if (s.description === video.description) { return s; }
      return { ...s, description: video.description };
    });
  }, [video.description]);

  const currentUser = useCurrentUserQuery();
  const aiEnabled = currentUser.data?.settings?.ai_access === 'full' ||
    currentUser.data?.settings?.ai_access === 'embedded_only';
    
  const downloadSubtitlesToggleQuery = useFindFeatureToggleQuery({
    featureToggle: 'download_subtitles_toggle',
  });
  const exportCaptionsEnabled = 
    downloadSubtitlesToggleQuery.data?.enabled || aiEnabled;

  const transcribeVideoToggleQuery = useFindFeatureToggleQuery({
    featureToggle: 'transcribe_video',
  });
  const transcribeVideoEnabled = 
    transcribeVideoToggleQuery.data?.enabled || aiEnabled;

  const updateVideoMutation = useUpdateVideoMutation();
  const reorderVideoMutation = useReorderVideoMutation();
  const destroyVideoMutation = useDestroyVideoMutation();
  const transcribeVideoMutation = useTranscribeVideoMutation();

  const videoPlayer = useVideoPlayer();

  if (destroyVideoMutation.isSuccess) { return null; }

  const videoId = videoPlayer?.videoId;
  const isActive = videoId === video.id;

  const selectAndPlayVideo = () => {
    if (isActive) { return; }
    if (!videoPlayer) { return; }

    videoPlayer.play(video.id);
  };

  const isDestroying = destroyVideoMutation.isLoading;

  const prevPosition = position - 1;
  const isMoveUpDisabled = (prevPosition < 0) || isDestroying;
  const nextPosition = position + 1;
  const isMoveDownDisabled = (nextPosition > lastPosition) || isDestroying;

  const showDescriptionInput = () => {
    setState((s) => ({ ...s, editingDescription: true }));
  };

  const handleDescriptionBlur = () => {
    setState((s) => ({ ...s, editingDescription: false }));

    updateVideoMutation.mutate({
      params: { videoId: video.id },
      body: { description: state.description || null },
    });
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setState((s) => ({ ...s, description: value }));
  };

  const handleMoveUpClick = () => {
    if (reorderVideoMutation.isLoading) { return; }
    if (isMoveUpDisabled) { return; }
    if (!video.reflection_id) { return; }

    reorderVideoMutation.mutate({
      params: { reflectionId: video.reflection_id, videoId: video.id },
      body: { to: prevPosition },
    });
  };

  const handleMoveDownClick = () => {
    if (reorderVideoMutation.isLoading) { return; }
    if (isMoveDownDisabled) { return; }
    if (!video.reflection_id) { return; }

    reorderVideoMutation.mutate({
      params: { reflectionId: video.reflection_id, videoId: video.id },
      body: { to: nextPosition },
    });
  };

  const handleDeleteClick = () => {
    if (destroyVideoMutation.isLoading) { return; }
    if (!window.confirm(__('Are you sure you want to delete this video?'))) { return; }

    destroyVideoMutation.mutate({ params: { videoId: video.id } });
  };

  const handleThumbnailClick = () => {
    if (!videoPlayer) { return; }

    if (isActive) {
      switch (videoPlayer.status) {
        case 'playing': {
          videoPlayer.pause();
          return;
        }

        default: {
          videoPlayer.play();
          return;
        }
      }
    }

    selectAndPlayVideo();
  };

  const channel = getVideoChannel(video);
  const prepState = channel?.prep_state;
  const isReady = prepState === Atlas.VideoPrepState.Ready;

  const renderPrepStateBadge = () => {
    switch (prepState) {
      case Atlas.VideoPrepState.Waiting: {
        return (
          <div className="tw-inline-flex tw-items-center tw-gap-1 tw-text-xs tw-font-bold tw-text-gray-400">
            {__('Waiting for video')}
          </div>
        );
      }

      case Atlas.VideoPrepState.Uploading: {
        return (
          <div className="tw-inline-flex tw-items-center tw-gap-1 tw-text-xs tw-font-bold tw-text-gray-400">
            <i className="fa fa-circle-o-notch fa-spin" />
            {__('Uploading')}
          </div>
        );
      }

      case Atlas.VideoPrepState.Transcoding: {
        return (
          <div className="tw-inline-flex tw-items-center tw-gap-1 tw-text-xs tw-font-bold tw-text-gray-400">
            <i className="fa fa-circle-o-notch fa-spin" />
            {__('Processing')}
          </div>
        );
      }

      case Atlas.VideoPrepState.Error: {
        return (
          <div className="tw-badge tw-badge-sm tw-badge-error tw-gap-1">
            <i className="fa fa-exclamation-circle" />
            {__('Error')}
          </div>
        );
      }

      case Atlas.VideoPrepState.Undecryptable: {
        return (
          <div className="tw-badge tw-badge-sm tw-badge-error tw-gap-1">
            <i className="fa fa-exclamation-circle" />
            {__('Undecryptable')}
          </div>
        );
      }

      case Atlas.VideoPrepState.Unsupported: {
        return (
          <div className="tw-badge tw-badge-sm tw-badge-error tw-gap-1">
            <i className="fa fa-exclamation-circle" />
            {__('Unsupported video')}
          </div>
        );
      }

      default: return null;
    }
  };

  return (
    <div
      className={`${isActive ? 'tw-bg-secondary' : 'tw-bg-white'} tw-shadow tw-rounded-box tw-p-4 tw-transition ${isDestroying ? 'tw-opacity-50' : ''}`}
      data-id={`video-playlist-item-${video.id}`}
    >
      <div className="tw-flex tw-items-center tw-gap-4">
        <button
          type="button"
          disabled={isDestroying || !isReady || !videoPlayer}
          className="tw-w-36 tw-max-h-36 tw-rounded-lg tw-overflow-hidden tw-group tw-relative"
          onClick={handleThumbnailClick}
          data-id="video-thumbnail-button"
        >
          <ImageWithFallback
            alt={__('Video thumbnail')}
            src={video.thumbnail_s3uri?.url}
            fallbackSrc="/assets/img/video_placeholder.svg"
          />

          {videoPlayer ? (
            <div className="tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-flex tw-justify-center tw-items-center tw-text-4xl tw-drop-shadow tw-text-white tw-opacity-80 tw-transition-opacity group-hover:tw-opacity-100">
              {(() => {
                if (!isActive) {
                  return (
                    <i className="fa fa-play" />
                  );
                }

                switch (videoPlayer?.status) {
                  case 'buffering': {
                    return (
                      <i className="fa fa-circle-o-notch fa-spin" />
                    );
                  }

                  case 'playing': {
                    return (
                      <i className="fa fa-pause" />
                    );
                  }

                  default: {
                    return (
                      <i className="fa fa-play" />
                    );
                  }
                }
              })()}
            </div>
          ) : null}
        </button>

        <div className="tw-flex-1">
          {(() => {
            if (!videoEditor.editable) {
              return (
                <span className="tw-font-bold">
                  {state.description ? (
                    state.description
                  ) : (
                    <span className="tw-italic">
                      {__('No name')}
                    </span>
                  )}
                </span>
              );
            }

            if (state.editingDescription) {
              return (
                <span>
                  <input
                    autoFocus
                    className="tw-input tw-input-xs tw-w-full"
                    value={state.description ?? ''}
                    onChange={handleDescriptionChange}
                    onBlur={handleDescriptionBlur}
                  />
                </span>
              );
            }

            return (
              <span
                role="button"
                tabIndex={0}
                className="tw-font-bold"
                onKeyPress={showDescriptionInput}
                onFocus={showDescriptionInput}
                onClick={showDescriptionInput}
                data-id="video-description-button"
              >
                {state.description ? (
                  state.description
                ) : (
                  <span className="tw-italic">
                    {__('No name')}
                  </span>
                )}
              </span>
            );
          })()}

          {video.duration ? (
            <div className="tw-font-bold tw-text-sm">
              {msToTimestamp(video.duration, true)}
            </div>
          ) : null}

          {videoEditor.editable && video.dual_view === Atlas.DualView.Dual ? (
            <div className="tw-flex tw-gap-x-2 tw-flex-wrap">
              <span className="tw-basis-1/3">
                {__('Advanced')}
                :
              </span>
              <PlaylistItemAdvancedControls video={video} />
            </div>
          ) : null}

          <div className="tw-flex-1 tw-flex tw-items-end">
            {renderPrepStateBadge()}
          </div>
        </div>

        {videoEditor.editable ? (
          <div className="tw-col-auto tw-grid tw-grid-cols-2 tw-gap-1 tw-content-start">
            <button data-id="video-sort-up" type="button" disabled={isMoveUpDisabled} className="tw-btn tw-btn-xs tw-btn-block" onClick={handleMoveUpClick}>
              <i className="fa fa-arrow-up" />
            </button>
            <button data-id="video-sort-down" type="button" disabled={isMoveDownDisabled} className="tw-btn tw-btn-xs tw-btn-block" onClick={handleMoveDownClick}>
              <i className="fa fa-arrow-down" />
            </button>
            <button
              type="button"
              disabled={!isReady || isDestroying}
              className="tw-btn tw-btn-xs tw-btn-block tw-btn-primary tw-col-span-2"
              style={{ float: 'initial', minWidth: 'initial', marginLeft: 'initial' }}
              onClick={onEditClick}
              data-id="video-edit-button"
            >
              {__('Edit')}
            </button>
            <div className="tw-dropdown tw-dropdown-end tw-col-span-2" style={{ float: 'initial', minWidth: 'initial', marginLeft: 'initial' }}>
              <label tabIndex={0} data-id="video-settings-dropdown" className={`tw-btn tw-btn-xs tw-btn-block ${isDestroying ? 'tw-btn-disabled' : ''}`}>
                <i className="fa fa-cog" />
              </label>
              <div className={`tw-dropdown-content tw-iris-dropdown ${isDestroying ? 'tw-hidden' : ''}`}>
                <div className="tw-iris-dropdown-box tw-text-sm">
                  <ul tabIndex={0}>
                    {(() => {
                      switch (currentUser.data?.settings?.download) {
                        case Atlas.DownloadSetting.Enabled: {
                          return (
                            <li>
                              <a
                                href={`/videos/${video.id}/download_video`}
                                download
                              >
                                <i className="fa fa-download fa-fw tw-mr-1" />
                                {__('Download')}
                              </a>
                            </li>
                          );
                        }
                        case Atlas.DownloadSetting.PermissionRequired: {
                          const toggleRequestDownloadOpen = (bool: boolean) => () => {
                            setState((s) => ({
                              ...s,
                              requestDownloadOpen: bool,
                            }));
                          };

                          return (
                            <>
                              <li>
                                <button
                                  type="button"
                                  onClick={toggleRequestDownloadOpen(true)}
                                >
                                  <i className="fa fa-download fa-fw tw-mr-1" />
                                  {__('Download')}
                                </button>
                              </li>
                              <VideoDownloadRequestModal
                                videoId={video.id}
                                isOpen={state.requestDownloadOpen}
                                onClose={toggleRequestDownloadOpen(false)}
                              />
                            </>
                          );
                        }
                        default: return null;
                      }
                    })()}
                    <li>
                      <button
                        type="button"
                        disabled={!isReady || isDestroying}
                        onClick={() => { setState((s) => ({ ...s, replicateOpen: true })); }}
                      >
                        <i className="fa fa-copy fa-fw tw-mr-1" />
                        {__('Replicate')}
                      </button>
                      <ReplicateVideoModal
                        video={video}
                        isOpen={state.replicateOpen}
                        onClose={() => { setState((s) => ({ ...s, replicateOpen: false })); }}
                      />
                    </li>
                    {transcribeVideoEnabled ? (
                      <li>
                        <button
                          type="button"
                          disabled={!isReady || isDestroying}
                          onClick={() => {
                            transcribeVideoMutation.mutate({ params: { videoId: video.id } });
                            setState((s) => ({ ...s, transcribeOpen: true }));
                          }}
                        >
                          <i className="fa fa-magic fa-fw tw-mr-1" />
                          {__('Transcribe')}
                        </button>
                        <Modal isOpen={state.transcribeOpen} onClose={() => setState((s) => ({ ...s, transcribeOpen: false }))}>
                          <ModalBox className="tw-px-6 tw-py-10">
                            <div className="tw-text-xl tw-text-center tw-mb-4">
                              {__('Transcription has started.')}
                            </div>

                            <div className="tw-text-gray-500 tw-text-center tw-mb-8">
                              {__('You may now close this window.')}
                            </div>

                            <button type="button" className="tw-btn tw-btn-primary tw-btn-wide tw-mx-auto tw-block" onClick={() => setState((s) => ({ ...s, transcribeOpen: false }))}>
                              {__('Close')}
                            </button>
                          </ModalBox>
                        </Modal>
                      </li>
                    ) : null}
                    {exportCaptionsEnabled ? (
                      <li>
                        <button
                          type="button"
                          onClick={() => setState((s) => ({ ...s, exportCaptionsOpen: true }))}
                        >
                          <i className="fa fa-cc fa-fw tw-mr-1" />
                          {__('Export Captions')}
                        </button>

                        <VideoCaptionsModal
                          videoId={video.id}
                          isOpen={state.exportCaptionsOpen}
                          onClose={() => setState((s) => ({ ...s, exportCaptionsOpen: false }))}
                        />
                      </li>
                    ) : null}
                    <li>
                      <CaptionUploadButton videoId={video.id} />
                    </li>

                    {video.override_channel ? (() => {
                      const handleRemoveOverrideChannelClick = () => {
                        updateVideoMutation.mutate({
                          params: { videoId: video.id },
                          body: { override_channel: null },
                        });
                      };

                      return (
                        <li>
                          <button
                            type="button"
                            onClick={handleRemoveOverrideChannelClick}
                          >
                            <i className="fa fa-undo fa-fw tw-mr-1" />
                            {__('Reset channel override')}
                          </button>
                        </li>
                      );
                    })() : null}

                    <li>
                      <button
                        type="button"
                        onClick={handleDeleteClick}
                      >
                        <i className="fa fa-remove fa-fw tw-mr-1 tw-text-red-700" />
                        {__('Delete')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PlaylistItem;
