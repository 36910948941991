import * as Atlas from "../types/Atlas";

export const submissionHasReflectionOrAttachment = (answers: Atlas.FormAnswer[]): boolean => {
    const hasReflectionOrAttachment = answers.some(
      (answer) => answer.reflection_value !== null || answer.attachment_value !== null
    );

    if (hasReflectionOrAttachment) {
      return true;
    }

    return false;
};