import React from 'react';
import PropTypes from 'prop-types';

const FormRatingElement = props => {
  const { formAnswer } = props,
        { field } = props.formElement;

  const {
    max_label: maxLabel,
    max_ordinal: maxOrdinal,
    min_label: minLabel,
    min_ordinal: minOrdinal
  } = field.type.rating;

  const percent = Math.max((formAnswer.value / maxOrdinal) * 100, 5);

  return (
    <div className="tw-max-w-full">
      <div className="tw-flex tw-justify-between">
        <small>{minLabel} ({minOrdinal})</small>
        <small>{maxLabel} ({maxOrdinal})</small>
      </div>

      <div
        className="progress tw-mb-0"
        style={{ backgroundImage: 'none', boxShadow: 'none' }}
      >
        <div className="progress-bar bg-success" style={{ width: `${percent}%` }}>
          {formAnswer.value}
        </div>
      </div>
    </div>
  );
};

FormRatingElement.propTypes = {
  formAnswer: PropTypes.object.isRequired,
  formElement: PropTypes.object.isRequired
};

export default FormRatingElement;
