import * as Atlas from '../types/Atlas';

type QPageNo = number | string;
type QPerPage = number | string;

export enum ViewID {
  Canonical = 'canonical',
  Expanded = 'expanded',
  Condensed = 'condensed',
}

export interface PaginationParams {
  page?: QPageNo;
  per_page?: QPerPage;
}

export type Metadata = Atlas.Metadata;

// Fetch utils
export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const jsonHeadersNew = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data'
};

export const generateCsrfTokenHeader = (): { 'X-CSRF-Token'?: string } => ({
  'X-CSRF-Token': document.querySelector<HTMLMetaElement>("[name='csrf-token']")?.content,
});

export const generateFetchHeaders = (): {
  Accept: string;
  'Content-Type': string;
  credentials: string;
  'X-CSRF-Token'?: string;
} => ({
  credentials: 'same-origin',
  ...jsonHeaders,
  ...generateCsrfTokenHeader(),
});

export const generateFetchHeadersNew = () => ({
  ...jsonHeadersNew,
  ...generateCsrfTokenHeader(),
});

export const searchParams = (
  params: Record<string, string | number | undefined | null>,
): string => Object.entries(params)
  .filter(([, v]) => v !== undefined && v !== null)
  .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v as string)}`)
  .join('&');

type SerializerableValue = string | number | boolean;
type SerializerableArray = Array<SerializerableValue | SerializerableArray>;
type SerializerableRecord =
  Record<string | number, SerializerableValue | SerializerableArray | undefined>;

function serializeArray(key: string, array: SerializerableArray): string[] {
  const uriKey = `${key}[]`;

  return array.reduce<string[]>((acc, value) => {
    const serialized = (() => {
      if (Array.isArray(value)) {
        return serializeArray(uriKey, value);
      }

      return [`${encodeURIComponent(uriKey)}=${encodeURIComponent(value)}`];
    })();

    return [...acc, ...serialized];
  }, []);
}

export function serializeSearchParams(
  params: SerializerableRecord,
  prefix?: string,
): string {
  const entries = Object.entries(params);

  const serializedParams = entries.reduce<string[]>((acc, [key, value]) => {
    if (value === undefined) { return acc; }

    const uriKey = prefix ? `${prefix}[${key}]` : key;

    const serialized = (() => {
      if (Array.isArray(value)) {
        return serializeArray(uriKey, value);
      }

      return [`${encodeURIComponent(uriKey)}=${encodeURIComponent(value)}`];
    })();

    return [...acc, ...serialized];
  }, []);

  return serializedParams.join('&');
}

// Routes
export const currentUserRoute = (): string => '/users/me';

export const attachmentsRoute = (): string => '/attachments';
export const attachmentRoute = ({ attachmentId }: { attachmentId: Atlas.AttachmentID }): string => `/attachments/${attachmentId}`;

// Forms
export const formsRoute = (): string => '/forms';
export const formRoute = ({ formId }: { formId: Atlas.FormID }): string => `/forms/${formId}`;
export const newFormRoute = (): string => '/forms/new';
export const editFormRoute = ({ formId }: { formId: Atlas.FormID }): string => `/forms/${formId}/edit`;

// Form Data
export const formDataRoute = (): string => '/form_data';
export const formDatumRoute = ({ formDataId }: { formDataId: Atlas.FormDataID }): string => `/form_data/${formDataId}`;

export const newBookingRoute = (): string => '/booking/new';
export const phoneCallsRoute = (): string => '/phone_calls';

// Reflections
export const reflectionsRoute = (): string => '/reflections';
export const reflectionRoute = ({ reflectionId }: { reflectionId: Atlas.ReflectionID }): string => `/reflections/${reflectionId}`;
export const reflectionFormsRoute = ({ reflectionId }: { reflectionId: Atlas.ReflectionID }): string => `/reflections/${reflectionId}/forms`;
export const reflectionAddFormRoute = ({ reflectionId }: { reflectionId: Atlas.ReflectionID }): string => `/reflections/${reflectionId}/add_form`;
export const reflectionCsvRoute = ({ reflectionId, formId }: { reflectionId: Atlas.ReflectionID, formId: Atlas.FormID }): string => `/reflections/${reflectionId}/csv/${formId}.csv`;
export const updateFormAssociationReflectionRoute = ({ reflectionId }: { reflectionId: Atlas.ReflectionID }): string => `/reflections/${reflectionId}/update_form_association`;

export const efUploadNewReflectionsRoute = (): string => '/reflections/ef_upload/new';

// Reflection Forms
export const associateReflectionFormRoute = ({ reflectionId, formId }: { reflectionId: Atlas.ReflectionID, formId: Atlas.FormID }): string => `/reflections/${reflectionId}/forms/${formId}/associate`;
export const disassociateReflectionFormRoute = ({ reflectionId, formId }: { reflectionId: Atlas.ReflectionID, formId: Atlas.FormID }): string => `/reflections/${reflectionId}/forms/${formId}/disassociate`;
