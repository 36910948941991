/* eslint-disable import/prefer-default-export */
import * as Retrier from '@jsier/retrier';
import * as _ from 'lodash-es';
import {
  VideoChannel,
  RecordingType,
  requiredChannels,
  RecordingContext,
  RecordManager,
} from './types';
import reactSync from './react-sync';

export const ScreenCapture_util = function (state, actions, elements, streams) {
  // "use strict";

  const { StreamingUploader } = webpack;
  const ThisModule = {};

  // Access scope on veditUploader directive e.g. to call functions
  ThisModule.veditUploader = function () {
    return reactSync;
  };

  ThisModule.ajax = function (options) {
    return new Promise((resolve, reject) => {
      options.success = function (response) {
        resolve(response);
      };

      options.error = function (_data, textStatus, errorThrown) {
        console.error(textStatus);
        console.error(errorThrown);
        reject(errorThrown);
      };

      $.ajax(options);
    });
  };

  ThisModule.recordingSampleRate = 5000; // Produce a chunk every 5 seconds.

  ThisModule.systemAudioFileName = 'audio';

  /* This function is responsible for setting up the 'RecordManager', which is a data structure which
     embeds a 'MediaRecorder' and a 'StreamingUploader'. It also registers all the relevant callbacks to
     handle starting & stopping a recording (and thus an upload).
  */
  ThisModule.newRecordManager = function (veditData, videoId, recordingContext, s3UploadCredentials, onProgress) {
    const streamingUploader = new StreamingUploader.uploader.S3MultipartUploader(recordingContext.videoChannel.toString(), s3UploadCredentials, onProgress);

    const { mediaStream } = recordingContext;
    const { mediaRecorder } = recordingContext;
    const { videoChannel } = recordingContext;

    const manager = new RecordManager(recordingContext, streamingUploader);

    mediaRecorder.ondataavailable = function (e) {
      streamingUploader.onNewChunk(e.data);
    };

    // Bind the logic for when we start this 'MediaRecorder'.
    mediaRecorder.onstart = function () {
      streamingUploader.onStart();
      actions.recordingState();
      actions.bindStop(mediaRecorder, videoChannel);
    };

    // Bind the logic for when we stop this 'MediaRecorder'.
    mediaRecorder.onstop = function () {
      actions.unBindStop();
      actions.uploadState();

      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });

      actions.cleanUp();

      ThisModule.stopStreamingUploader(manager, veditData, videoId);
    };

    actions.displayPreview(mediaStream, videoChannel);

    return manager;
  };

  ThisModule.stopVeditUploader = function (veditData) {
    try { // Ignore any weird error from the vedit uploader.
      ThisModule.veditUploader().onStop(veditData);
    } catch (err) {
      console.debug('The vedit uploader onStop function failed, but is not important.', err);
    }
  };

  ThisModule.stopStreamingUploader = function (manager, veditData, videoId) {
    const streamingUploader = manager.uploader;
    const { videoChannel } = manager;

    // Set a timer that will show the retryUpload button if we detect no progress.
    const options = {
      limit: 20000,
      delay: 30000,
      stopRetryingIf(_error, _attempt) { return ThisModule.areWeDone(); },
    };

    const retrier = new Retrier.Retrier(options);

    retrier.resolve((_attempt) => new Promise((onSuccess, onFailure) => {
      const now = new Date();
      const elapsed = Math.abs(now.getTime() - manager.uploader.lastSuccessfulRoundtrip().getTime()) / 1000;
      if (elapsed > 600) {
        actions.showRetryUploadBtn((mgr) => ThisModule.stopStreamingUploader(mgr, veditData, videoId));
      }

      if (ThisModule.areWeDone()) { onSuccess(); } else { onFailure(); }
    })).then(() => { actions.hideRetryUploadBtn(); });

    // Implement the body of this callback to do any post-processing action after the streamingUploader
    // returned the final ETag, signalling it has finished uploading on S3.
    streamingUploader.onStop().then((onStopData) => {
      // console.debug("Final upload eTag: " + onStopData.eTag);
      veditData.prepState = 'transcoding';
      ThisModule.stopVeditUploader(veditData);
      manager.videoPartUploaded = true;
      actions.hideRetryUploadBtn();
      ThisModule.finaliseUpload(onStopData, videoChannel, streamingUploader);
    }).catch((e) => {
      // Activate the 'retry upload' button
      actions.showRetryUploadBtn((mgr) => ThisModule.stopStreamingUploader(mgr, veditData, videoId));

      const streamingError = new StreamingUploader.uploader.StreamingUploaderError(e);
      streamingUploader.onError(streamingError);

      if (!streamingError.isNetworkFailure()) {
        console.error(JSON.stringify(e));
        ThisModule.veditUploader().onFail(veditData, e, false);
        // Do not delete the video part (see Agora#8104)
      }
    });
  };

  ThisModule.areWeDone = function () {
    const leftCompleted = _.every([
      state.leftRecordManager,
      state.leftRecordManager.uploader,
      state.leftRecordManager.uploader.uploadStatus == StreamingUploader.types.UploadStatus.Completed,
    ]);

    let rightCompleted = true;

    if (state.rightRecordManager && state.rightRecordManager.uploader) {
      rightCompleted = state.rightRecordManager.uploader.uploadStatus == StreamingUploader.types.UploadStatus.Completed;
    }

    return leftCompleted && rightCompleted;
  };

  // Finalises the uploading, making sure /uploaded is called when all relevant files have been uploaded
  // to S3.
  ThisModule.finaliseUpload = function (onStopData, videoChannel, streamingUploader) {
    // A limit of 16 hours, which is close enough to the expiration date of the STS credentials,
    // which is 18 hours. Even on very slow connections this should be fine, considering most of the time
    // by the time we hit stop we have only a handful of parts left, and it's a bit unlikely a users would
    // leave their computers open for more than 16 hours.
    const options = {
      limit: 20000,
      delay: 3000,
      stopRetryingIf(_error, _attempt) { return ThisModule.areWeDone(); },
    };

    const retrier = new Retrier.Retrier(options);

    retrier.resolve((_attempt) => new Promise((onSuccess, onFailure) => {
      if (ThisModule.areWeDone()) {
        onSuccess();
      } else {
        onFailure();
      }
    }).then(() => {
      // If we are in the DesktopWithSystemAudio and this is the Right channel, we have to skip
      // the second Ajax call as this won't have a proper mediaKey. The modal will be closed, opportunistically,
      // by the left channel.
      const callAtlas = !(_.every(
        [videoChannel == VideoChannel.Right, state.recordingType == RecordingType.DesktopWithSystemAudio],
      ));

      if (callAtlas) {
        $.ajax({
          url: '/uploaded',
          type: 'POST',
          dataType: 'json',
          data: { vmk: onStopData.mediaKey },

          success(_json) {
            elements.finalisingLeftDiv.find('i').removeClass('fa-refresh fa-spin');
            elements.finalisingLeftDiv.find('i').addClass('fa-check');

            elements.finalisingRightDiv.find('i').removeClass('fa-refresh fa-spin');
            elements.finalisingRightDiv.find('i').addClass('fa-check');

            actions.closeModal();
            elements.uploadProgressModal.modal('hide');
          },

          // In case we failed to grab the upload credentials, we fail.
          error(_, textStatus, errorThrown) {
            console.error(textStatus);
            console.error(errorThrown);
            streamingUploader.onError(errorThrown);
          },

        });
      }
    })).catch((_e) => { }); // Nothing we can do.
  };

  // Converts the STS credentials returned by Atlas into a domain-specific 'S3UploadCredentials'.
  ThisModule.toS3UploadCredentials = function (stsCredentials, mediaKey, videoChannel) {
    const { bucket } = stsCredentials.s3_object;
    let s3Path = stsCredentials.s3_object.path;
    let key = mediaKey;

    // Override the target filename in case we have a DesktopWithSystemAudio.
    switch (videoChannel) {
      case VideoChannel.Left:
        switch (state.recordingType) {
          case RecordingType.DesktopWithSystemAudio:
            // Do not change the key here, as otherwise the call to /uploaded would fail.
            s3Path = s3Path.replace('screen-capture', 'screen-capture/video');
        }
        break;

      case VideoChannel.Right:
        switch (state.recordingType) {
          case RecordingType.DesktopWithSystemAudio:
            key = 'screen-capture/audio';
            s3Path = s3Path.replace('screen-capture', key);
        }
        break;
    }

    const tempKey = stsCredentials.credentials.AccessKeyId;
    const tempSecret = stsCredentials.credentials.SecretAccessKey;
    const sessionToken = stsCredentials.credentials.SessionToken;
    const s3Bucket = new StreamingUploader.uploader.S3Bucket(bucket, stsCredentials.s3_object.endpoint);

    return new StreamingUploader.uploader.S3UploadCredentials(tempKey, sessionToken, tempSecret, key, s3Bucket, s3Path);
  };

  /* The main workhorse. Kicks-off the chain of the events that starts the recording.
  */
  ThisModule.record = function () {
    const veditName = ThisModule.uuidv4();
    const veditData = {
      reflectionId: state.reflectionId,
      files: [
        {
          name: veditName,
        },
      ],
      channels: requiredChannels(state.recordingType),
      upload_source: { device: 'screen_capture' },
    };

    // Try to first resolve both RecordingContext(es), and only if we have no errors we go ahead
    // and create the relevant parts.
    const leftCtxPromise = function () { return ThisModule.newRecordingContext(VideoChannel.Left); };
    const rightCtxPromise = function () { return ThisModule.newRecordingContext(VideoChannel.Right); };

    let ctxs = [];
    if (ThisModule.isDualView() || state.recordingType == RecordingType.DesktopWithSystemAudio) { ctxs = [leftCtxPromise(), rightCtxPromise()]; } else ctxs = [leftCtxPromise()];

    Promise.all(ctxs).then((recordingContexts) => {
      ThisModule.veditUploader().onStart(veditData, veditName, (_data, mediaKeys, videoId) => {
        ThisModule.ajax({
          url: '/to_upload',
          type: 'POST',
          dataType: 'json',
          data: {
            vmk: mediaKeys[0], // the left part is always going to be there
          },
        }).then((leftUploadCreds) => {
          const uploadCreds = ThisModule.toS3UploadCredentials(leftUploadCreds, mediaKeys[0], VideoChannel.Left);
          const leftCtx = recordingContexts[0];

          state.leftRecordManager = ThisModule.newRecordManager(
            veditData,
            videoId,
            leftCtx,
            uploadCreds,
            ThisModule.onProgress(veditData, VideoChannel.Left),
          );

          if (ThisModule.isRightPartRequired(mediaKeys)) {
            if (requiredChannels(state.recordingType) == 2 && mediaKeys.length >= 2) {
              // Ugh, nested callbacks galore.
              ThisModule.ajax({
                url: '/to_upload',
                type: 'POST',
                dataType: 'json',
                data: {
                  vmk: mediaKeys[1],
                },
              }).then((rightUploadCreds) => {
                const ctx = recordingContexts[1];
                const key = mediaKeys[1];
                ThisModule.recordRightPart(veditData, videoId, rightUploadCreds, ctx, key);
              }).catch((err) => {
                console.error(err);
                // Do not delete the video part (See Agora#8104)
                actions.resetEverything();
                elements.recordingAbortedModal.modal('show');
              });
            }

            if (state.recordingType == RecordingType.DesktopWithSystemAudio) {
              const ctx = recordingContexts[1];
              const key = ThisModule.systemAudioFileName;
              ThisModule.recordRightPart(veditData, videoId, leftUploadCreds, ctx, key);
            }
          } else {
            state.leftRecordManager.uploader.beforeStart().then((_uploadId) => {
              state.leftRecordManager.mediaRecorder.start(ThisModule.recordingSampleRate);
            }).catch((err) => {
              state.leftRecordManager.uploader.onError(err);
              // Do not delete the video part (see Agora#8104)
              actions.resetEverything();
              elements.recordingAbortedModal.modal('show');
            });
          }
        }).catch((e) => {
          console.error(e);
          ThisModule.veditUploader().onFail(veditData, e, false);
          // Do not delete the video part (see Agora#8104)
          actions.resetEverything();
          elements.recordingAbortedModal.modal('show');
        });
      });
    }).catch((e) => {
      console.error(e);
      actions.resetEverything();
      elements.recordingAbortedModal.modal('show');
    });
  };

  ThisModule.isRightPartRequired = function (mediaKeys) {
    return _.some(
      [requiredChannels(state.recordingType) == 2 && mediaKeys.length >= 2,
        state.recordingType == RecordingType.DesktopWithSystemAudio,
      ],
    );
  };

  ThisModule.recordRightPart = function (veditData, videoId, rightUploadCreds, rightCtx, key) {
    const uploadCreds = ThisModule.toS3UploadCredentials(rightUploadCreds, key, VideoChannel.Right);

    state.rightRecordManager = ThisModule.newRecordManager(
      veditData,
      videoId,
      rightCtx,
      uploadCreds,
      ThisModule.onProgress(veditData, VideoChannel.Right),
    );

    const left = state.leftRecordManager.uploader;
    const right = state.rightRecordManager.uploader;

    Promise.all([left.beforeStart(), right.beforeStart()]).then((_uploadIds) => {
      state.leftRecordManager.mediaRecorder.start(ThisModule.recordingSampleRate);
      state.rightRecordManager.mediaRecorder.start(ThisModule.recordingSampleRate);
    }).catch((err) => {
      state.leftRecordManager.uploader.onError(err);
      state.rightRecordManager.uploader.onError(err);
      // Do not delete the video (see Agora#8104)
      actions.resetEverything();
      elements.recordingAbortedModal.modal('show');
    });
  };

  // Updates the progress bar. Even in case we have multiple streams (i.e. desktop and webcam)
  // we want to track the global progress in a way that reassures users. We do that by showing two
  // separate progress bars: a green one which shows already-uploaded parts, and a blue one that
  // tracks "in flight" parts.
  ThisModule.onProgress = function (veditData, videoChannel) {
    const consolidatedBar = elements.consolidatedParts;
    const inFlightBar = elements.inFlightParts;

    return function (uploadStats) {
      let whitePercentage = 0;
      let greenPercentage = 0;
      let bluePercentage = 100;

      switch (videoChannel) {
        case VideoChannel.Left:
          state.uploadStats.left.uploaded = uploadStats.uploadedParts;
          state.uploadStats.left.total = uploadStats.totalUploadParts;
          state.uploadStats.left.inFlight = uploadStats.inFlightParts;
          state.uploadStats.left.finalising = uploadStats.uploadingFirstChunkToFixMetadata;
          break;
        case VideoChannel.Right:
          state.uploadStats.right.uploaded = uploadStats.uploadedParts;
          state.uploadStats.right.total = uploadStats.totalUploadParts;
          state.uploadStats.right.inFlight = uploadStats.inFlightParts;
          state.uploadStats.right.finalising = uploadStats.uploadingFirstChunkToFixMetadata;
          break;
      }

      const totalParts = state.uploadStats.left.total + state.uploadStats.right.total;

      if (totalParts > 0) {
        const totalUploaded = Math.min(state.uploadStats.left.uploaded + state.uploadStats.right.uploaded, totalParts);
        const totalInFlight = state.uploadStats.left.inFlight + state.uploadStats.right.inFlight;
        const totalWaitingParts = Math.max(0, totalParts - totalUploaded - totalInFlight);

        // Sets the total upload progress to be the number of upload parts minus how
        // many we have uploaded, all normalised to 100.
        whitePercentage = ((totalWaitingParts / totalParts) * 100).toFixed(2);
        greenPercentage = Math.min(((totalUploaded / totalParts) * 100).toFixed(2), 100);
        bluePercentage = Math.min(((totalInFlight / totalParts) * 100).toFixed(2), 100 - greenPercentage);

        // For some reason when we finalise an upload, we might have the green bar at 100% but still
        // inFlight parts, which would be confusing for the user.
        if (totalInFlight > 0) {
          bluePercentage = Math.min(((totalInFlight / totalParts) * 100).toFixed(2), 100);
          greenPercentage = (100 - bluePercentage - whitePercentage).toFixed(2);
        }

        ThisModule.veditUploader().onProgress(veditData, greenPercentage);
        consolidatedBar.css('width', `${greenPercentage}%`);
        inFlightBar.css('width', `${bluePercentage}%`);
        $('.screen-cap-upload-total-progress').text(`${greenPercentage}%`);

        const remainingInFlightTxt = __('Remaining in flight parts: ');
        const remainingWaitingTxt = __('Remaining queued parts: ');

        $('#screen-capture-upload-progress-modal .remaining-in-flight').text(remainingInFlightTxt + totalInFlight);
        $('#screen-capture-upload-progress-modal .remaining-waiting').text(remainingWaitingTxt + totalWaitingParts);
      }

      // If any of the two parts are finalising (to fix the duration) notify the user about it.

      const txt = __('Finalising the upload...');
      let whichStream = __('First stream: ');

      if (state.uploadStats.left.finalising) {
        elements.finalisingLeftDiv.removeClass('hide');
        elements.finalisingLeftSpan.text(whichStream + txt);
      }

      if (state.uploadStats.right.finalising) {
        elements.finalisingRightDiv.removeClass('hide');
        whichStream = __('Second stream: ');
        elements.finalisingRightSpan.text(whichStream + txt);
      }
    };
  };

  ThisModule.resolveDeviceConstraint = function (source) {
    let constraint = true;
    if (source.val()) {
      constraint = { deviceId: { exact: source.val() } };
    }
    return constraint;
  };

  // Return true if this is a dual view recording. There is a notable exception, though:
  // due to the fact Chrome doesn't really allow adding two audio streams as part of the same
  // MediaStream object (which is unbelievable) we need to "carry" the system audio into a separate
  // media object which we will treat specially in Hermes.
  ThisModule.isDualView = function () {
    return _.some(
      [RecordingType.DesktopAndWebcam, RecordingType.DesktopAndWebcamWithSystemAudio],
      (rt) => rt == state.recordingType,
    );
  };

  /* Returns the video tracks associated to this screen capture. Which stream will be returned
     (destkop or user) will depend on the 'VideoChannel' we are dealing with, and whether or
     not this is a dua view.
  */
  ThisModule.getVideoTrack = function (videoChannel) {
    let videoTrack = null;

    switch (videoChannel) {
      case VideoChannel.Left:

        switch (state.recordingType) {
          case RecordingType.Desktop:
            videoTrack = streams.desktop.getVideoTracks()[0];
            break;
          case RecordingType.DesktopWithSystemAudio:
            videoTrack = streams.desktop.getVideoTracks()[0];
            break;
          case RecordingType.Webcam:
            videoTrack = streams.user.getVideoTracks()[0];
            break;
          case RecordingType.DesktopAndWebcam:
            videoTrack = streams.desktop.getVideoTracks()[0];
            break;
          case RecordingType.DesktopAndWebcamWithSystemAudio:
            videoTrack = streams.desktop.getVideoTracks()[0];
            break;
        }

        break;

      case VideoChannel.Right:

        switch (state.recordingType) {
          case RecordingType.Desktop:
            videoTrack = null;
            break;
          case RecordingType.DesktopWithSystemAudio:
            videoTrack = null;
            break;
          case RecordingType.Webcam:
            videoTrack = null;
            break;
          case RecordingType.DesktopAndWebcam:
            videoTrack = streams.user.getVideoTracks()[0];
            break;
          case RecordingType.DesktopAndWebcamWithSystemAudio:
            videoTrack = streams.user.getVideoTracks()[0];
            break;
        }

        break;
    }

    if (videoTrack != null) videoTrack.applyConstraints(state.selectedMediaConstraints.videoConstraints);
    return videoTrack;
  };

  /* Get the appropriate audio track for this videoChannel. We have a few cases we have to deal with:
     1. This is a single view, desktop-only video part, with or without system audio;
     2. This is a single view, webcam-only video part (systemAudio option has no effect in this case);
     3. This is a dual view, with or without system audio.
  */
  ThisModule.getAudioTrack = function (videoChannel) {
    // By default, capture user's mic.
    let audioTrack = streams.user.getAudioTracks()[0];

    switch (videoChannel) {
      case VideoChannel.Left:

        switch (state.recordingType) {
          case RecordingType.Desktop:
            audioTrack = streams.user.getAudioTracks()[0];
            break;
          case RecordingType.DesktopWithSystemAudio:
            audioTrack = streams.user.getAudioTracks()[0];
            break;
          case RecordingType.Webcam:
            audioTrack = streams.user.getAudioTracks()[0];
            break;
          case RecordingType.DesktopAndWebcam:
            audioTrack = streams.user.getAudioTracks()[0];
            break;
          case RecordingType.DesktopAndWebcamWithSystemAudio:
            audioTrack = streams.desktop.getAudioTracks()[0];
            break;
        }

        break;

      case VideoChannel.Right:

        switch (state.recordingType) {
          case RecordingType.Desktop:
            audioTrack = null;
            break;
          case RecordingType.DesktopWithSystemAudio:
            audioTrack = streams.desktop.getAudioTracks()[0];
            break;
          case RecordingType.Webcam:
            audioTrack = null;
            break;
          case RecordingType.DesktopAndWebcam:
            audioTrack = streams.user.getAudioTracks()[0];
            break;
          case RecordingType.DesktopAndWebcamWithSystemAudio:
            audioTrack = streams.user.getAudioTracks()[0];
            break;
        }

        break;
    }

    return audioTrack;
  };

  // N.B. We return this as a Promise even though technically it doesn't require to be
  // to get better composability with the rest of the system.
  ThisModule.newRecordingContext = function (videoChannel) {
    return new Promise((onSuccess, onFailure) => {
      const tracks = ThisModule.tracks(videoChannel);

      try {
        const mediaStream = new MediaStream(tracks);
        const mediaRecorder = new MediaRecorder(mediaStream);
        onSuccess(new RecordingContext(videoChannel, mediaRecorder, mediaStream));
      } catch (err) {
        // Stop any potentially-started tracks.
        tracks.forEach((track) => {
          if (track != undefined && track != null) track.stop();
        });

        onFailure(err);
      }
    });
  };

  // Get the appropriate audio and video tracks for the given 'VideoChannel'.
  ThisModule.tracks = function (videoChannel) {
    const videoTrack = ThisModule.getVideoTrack(videoChannel);
    const audioTrack = ThisModule.getAudioTrack(videoChannel);

    // Insert the callback to end the sharing in case the user clicks on "Stop" from the browser
    // rather than from the UI.
    if (!(videoTrack === null) && videoChannel === VideoChannel.Left) {
      videoTrack.onended = function () {
        elements.stopBtn.click(); // Trigger the "stop" event chain.
      };
    }

    if (audioTrack === null) return [videoTrack];
    if (videoTrack === null) return [audioTrack];
    return [videoTrack, audioTrack];
  };

  // handle devices errors
  ThisModule.handleDevicesError = function (error) {
    console.error(
      'navigator.MediaDevices.getUserMedia error: ',
      error.message,
      error.name,
    );
  };

  // Generate UUID
  ThisModule.uuidv4 = function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (
      c
        ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16));
  };

  ThisModule.deleteVideoIfNotUploaded = function (videoId) {
    let rightUploaded = true; // This starts as true as we might not have a right part.
    let leftUploaded = false;
    if (state.leftRecordManager != null && state.leftRecordManager != undefined) {
      leftUploaded = state.leftRecordManager.videoPartUploaded;
    }

    if (state.rightRecordManager != null && state.rightRecordManager != undefined) {
      rightUploaded = state.rightRecordManager.videoPartUploaded;
    }

    // Something is off, as some video parts are not uploaded (but we called this function due to
    // an error). Delete the video part.
    if (!leftUploaded || !rightUploaded) {
      ThisModule.ajax({
        url: `/videos/${videoId}`,
        method: 'DELETE',
        contentType: 'application/json',
      });
    }
  };

  return ThisModule;
};
