import React, { useState } from 'react';
import { Modal, ModalBox } from '../../../../common/components/TailwindModal';
import useCreateGroupPageMutation from '../../../../common/hooks/api/groups/useCreatePageMutation';
import * as Atlas from '../../../../common/types/Atlas';
import PageTitleForm from './PageTitleForm';

interface NewHeadingButtonProps {
  groupId: Atlas.GroupID;
}

const NewHeadingButton = (props: NewHeadingButtonProps) => {
  const { groupId } = props;

  const [open, setOpen] = useState(false);

  const createGroupPage = useCreateGroupPageMutation({ groupId });

  const toggle = () => {
    setOpen((o) => !o);
  };

  const handleTitleSave = async ({ title }: Pick<Atlas.Page, 'title'>) => {
    await createGroupPage.mutateAsync({
      title,
      type: Atlas.PageType.Heading,
    });

    setOpen(false);
  };

  return (
    <>
      <button className="new-heading" type="button" onClick={toggle} data-testid="group-new-heading-button">
        {__('New Heading')}
      </button>

      <Modal isOpen={open} onClose={toggle}>
        <ModalBox>
          <p className="tw-text-xl tw-font-bold tw-mb-3">
            {__('New Heading')}
          </p>

          <PageTitleForm
            pageType={Atlas.PageType.Heading}
            onSave={handleTitleSave}
            onCancel={toggle}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

export default NewHeadingButton;
