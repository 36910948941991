import React, { useContext, useEffect, useMemo } from 'react';
import { BehaviorSubject } from 'rxjs';

const JwPlayerScopeContext = React.createContext<BehaviorSubject<string | null> | null>(null);

export const JwPlayerScope = (props: { children: React.ReactNode; }) => {
  const { children } = props;

  const activePlayer = useMemo(() => (
    new BehaviorSubject<string | null>(null)
  ), []);

  return (
    <JwPlayerScopeContext.Provider value={activePlayer}>
      {children}
    </JwPlayerScopeContext.Provider>
  );
};

export const useJwPlayerScope = (player?: jwplayer.JWPlayer) => {
  const scope = useContext(JwPlayerScopeContext);

  useEffect(() => {
    if (!scope) { return; }
    if (!player) { return undefined; }

    const getId = () => player.getContainer().id;

    const subscription = scope.subscribe({
      next: (activeId) => {
        const id = getId();

        if (activeId !== id) {
          player.pause();
        }
      },
    });

    const onPlay = () => {
      scope.next(getId());
    };

    player.on('play', onPlay);

    return () => {
      subscription.unsubscribe();
      player.off('play', onPlay);
    };
  }, [scope, player]);
};
