import { cva, cx, VariantProps } from 'cva';
import React from 'react';

export const spinner = cva([
  'fa', 'fa-circle-o-notch', 'fa-spin',
], {
  variants: {
    color: {
      primary: 'tw-text-primary',
      info: 'tw-text-info',
      white: 'tw-text-white',
      light: 'tw-text-gray-400',
    },
    size: {
      xs: ['tw-text-[12px]', 'tw-leading-[12px]'],
      sm: ['tw-text-[16px]', 'tw-leading-[16px]'],
      md: ['tw-text-[32px]', 'tw-leading-[32px]'],
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type SpinnerProps = VariantProps<typeof spinner>;

const Spinner = ({
  className,
  ...props
}: SpinnerProps & { className?: string; }) => (
  <i className={cx(className, spinner(props))} />
);

export default Spinner;
