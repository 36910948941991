import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import moment from 'moment';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from '../../../common/components/BSDropdown';
import * as Atlas from '../../../common/types/Atlas';
import { selectClassNames } from '../../../common/components/Select';
import GroupAssignmentSubmission from './AssignmentSubmission';
import { getAssignmentsBasePath } from '../../../common/utils/getAssignmentsBasePath';
import Spinner from '../../../common/components/Spinner';
import { Row } from '../../../common/components/Row';
import { Col } from '../../../common/components/Col';
import { Badge } from '../../../common/components/Badge';
import { submissionHasReflectionOrAttachment } from '../../../common/utils/submission-delete-prompt';

type StatusOption = {
  label: string;
  value: string;
};

interface UserIdOption {
  label: string;
  value: Atlas.UserID;
}

interface SubmissionCompleteOption {
  label: string;
  value: Atlas.SubmissionComplete;
}

interface DeleteSubmissionPromptOptions {
  prompt?: String;
  typeToConfirm?: boolean;
  selectedSubmissionId?: Atlas.AssignmentSubmissionID;
}

interface AssesseeGroupAssignmentSubmissionsProps {
  group: Atlas.Group;
  assignment: Atlas.Assignment;
  submissions: Atlas.ExpandedAssignmentSubmission[];
  submissionsLoading: boolean;
  totalSubmissions: number;
  filter: {
    userId?: UserIdOption;
    complete?: SubmissionCompleteOption;
  };
  onFilterChange(name: string, value: StatusOption | UserIdOption | SubmissionCompleteOption): void;
  reloadSubmissions(): Promise<void>;
  onDeleteSubmission(options: DeleteSubmissionPromptOptions): void;
}

const submissionCompleteOptions: SubmissionCompleteOption[] = [
  { label: __('Complete'), value: Atlas.SubmissionComplete.Complete },
  { label: __('Submitted'), value: Atlas.SubmissionComplete.Incomplete }
];

const CenteredSpinner = () => (
  <div className="d-flex align-items-center justify-content-center my-5">
    <Spinner color="info" />
  </div>
);

const AssesseeGroupAssignmentSubmissions = (props: AssesseeGroupAssignmentSubmissionsProps): JSX.Element => {
  const { group, assignment, submissions, submissionsLoading } = props;

  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);

  if (submissionsLoading) { return <CenteredSpinner />; }

  const handleStatusChange = (value: OnChangeValue<StatusOption, false>, action: ActionMeta<StatusOption>) => {
    const { name } = action;
    if (!name) { return; }
    if (!value) { return; }

    props.onFilterChange(name, value);
  };

  const handleDeleteSubmissionClick = (submission: Atlas.AssignmentSubmission) => () => {
    let deleteOptions: DeleteSubmissionPromptOptions

    if (submissionHasReflectionOrAttachment(submission.answers)) {
      deleteOptions = {
        prompt: "If this submission has been resubmitted by another user, their resubmission can lose visibility of any reflections and attachments included in this submission.",
        typeToConfirm: true,
        selectedSubmissionId: submission.id
      }
    } else {
      deleteOptions = {
        typeToConfirm: false,
        selectedSubmissionId: submission.id
      }
    }
    props.onDeleteSubmission(deleteOptions);
  };

  const hideStatus = assignment.completion === Atlas.AssignmentCompletion.Auto;
  const showGrade = submissions.some((submission) => !!submission.grade);

  return (
    <div>
      <Row>
        <Col className="mb-3 d-flex align-items-center">
          {Number.isInteger(props.totalSubmissions) ? (
            <span className="mx-2 text-muted">
              {props.totalSubmissions} {props.totalSubmissions === 1 ? __('submission') : __('submissions')}
            </span>
          ) : null}
        </Col>

        <Col className="mb-3">
          <div className="d-flex justify-content-end">
            <Link className="btn btn-success" to={`/${basePath}/${assignment.group_id}/assignments/${assignment.id}/submissions/new`}>
              {__('New Submission')}
            </Link>
          </div>
        </Col>
      </Row>

      <Row>
        <Col />
        <Col />

        <Col>
          <h6 className="my-0">Status</h6>
          <Select classNames={selectClassNames} placeholder={__('All')} isClearable name="complete" options={submissionCompleteOptions} value={props.filter.complete} onChange={handleStatusChange} />
        </Col>
      </Row>

      <table className="table ml-0">
        <thead>
          <tr>
            <th>{__('Date')}</th>
            {showGrade ? (
              <th>{__('Grade')}</th>
            ) : null}
            {hideStatus ? null : (
              <th>{__('Status')}</th>
            )}
            <th style={{ width: '1px' }} />
          </tr>
        </thead>

        <tbody>
          {submissionsLoading ? (
            <tr>
              <td colSpan={5} className="text-center">
                <Spinner color="info" />
              </td>
            </tr>
          ) : !submissions.length ? (
            <tr>
              <td colSpan={5} className="text-center text-muted">
                {__('No submissions were found')}
              </td>
            </tr>
          ) : submissions.map(submission => (
            <tr key={submission.id} >
              <td>
                {moment(submission.created_at).format('l')}
              </td>
              {showGrade ? (
                <td>
                  {submission.grade}
                </td>
              ) : null}
              {hideStatus ? null : (
                <td>
                  {
                    submission.complete === 'complete' ? (
                      <Badge color="success">
                        {__('Complete')}
                      </Badge>
                    ) : submission.complete === 'incomplete' ? (
                      <Badge color="info">
                        {__('Submitted')}
                      </Badge>
                    ) : null
                  }
                </td>
              )}
              <td>
                <UncontrolledDropdown className="btn-group">
                  <Link className="btn" to={`/${basePath}/${assignment.group_id}/assignments/${submission.assignment_id}/submissions/${submission.id}`}>
                    {__('View')}
                  </Link>

                  <DropdownToggle>
                    <i className="fa fa-caret-down" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="text-danger" onClick={handleDeleteSubmissionClick(submission)}>
                      {__('Delete')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssesseeGroupAssignmentSubmissions;
