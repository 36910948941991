import { inRange } from 'lodash-es';
import { ExpandedForm, Form, FormID, ReflectionID } from '../../../../../types/Atlas';
import { generateFetchHeaders } from '../../../../routes';
import ApiError from '../../../../error';
import { parseModelObject } from '../../../../api-parser';

export const reflectionFormRoute = (args: {
  reflectionId: ReflectionID;
  formId: FormID;
}): string => `/reflections/${args.reflectionId}/forms/${args.formId}`;

export type FindReflectionFormArguments = {
  params: {
    reflectionId: ReflectionID;
    formId: FormID;
  };
};

export type FindReflectionFormResponse = ExpandedForm;

export const findReflectionForm = async (
  args: FindReflectionFormArguments,
): Promise<FindReflectionFormResponse> => {
  const {
    reflectionId,
    formId,
  } = args.params;

  const url = reflectionFormRoute({
    reflectionId,
    formId,
  });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'findReflectionForm');
  }

  const body = await response.json();
  const data = parseModelObject<ExpandedForm>(body);

  return data;
};

export const createReflectionFormAiCommentsRoute = (args: {
  reflectionId: ReflectionID;
  formId: FormID;
}): string => `/api/v0/reflections/${args.reflectionId}/forms/${args.formId}/ai_comments`;

export interface CreateReflectionFormAiCommentsArguments {
  params: {
    reflectionId: ReflectionID;
    formId: FormID;
  };
}

export type CreateReflectionFormAiCommentsResponse = void;

export const createReflectionFormAiComments = async (
  args: CreateReflectionFormAiCommentsArguments,
): Promise<CreateReflectionFormAiCommentsResponse> => {
  const url = createReflectionFormAiCommentsRoute({
    reflectionId: args.params.reflectionId,
    formId: args.params.formId,
  });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'createReflectionFormAiComments');
  }
};
