import React from 'react';

import moment from 'moment';
import * as Atlas from '../../common/types/Atlas';
import { Link } from 'react-router-dom';
import { interpolate } from '../../common/utils/utils';
import classNames from 'classnames';
import GroupAssignmentRow from './AssignmentRow';
import { useLocation } from 'react-router-dom';
import { isSuperAdmin } from '../../common/utils/user-permissions';
import { getAssignmentsBasePath } from '../../common/utils/getAssignmentsBasePath';
import Spinner from '../../common/components/Spinner';
import { Row } from '../../common/components/Row';
import { Col } from '../../common/components/Col';

interface GroupAssignmentsProps {
  user?: Atlas.User;
  groupLoading: boolean;
  group?: Atlas.Group;
  assignmentsLoading: boolean;
  assignments: Atlas.ExpandedAssignment[];
  totalAssignments?: number;
  onCloneAssignmentClick(a: Atlas.Assignment): void;
  onDeleteAssignmentClick(a: Atlas.Assignment): void;
}

const GroupAssignments = (props: GroupAssignmentsProps): JSX.Element => {
  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);
  const { user, group, assignments, assignmentsLoading } = props;

  const handleCloneAssignmentClick = (assignment: Atlas.Assignment) => {
    props.onCloneAssignmentClick(assignment);
  };

  const handleDeleteAssignmentClick = (assignment: Atlas.Assignment) => {
    props.onDeleteAssignmentClick(assignment);
  };

  const isAssessor = user && group && (
    isSuperAdmin(user) ||
    group.group?.administrators.user_ids.includes(user.id) ||
    group.group?.assessors.user_ids.includes(user.id)
  );

  const isUserSuperAdmin = user && user.admin === Atlas.AdministratorType.Super;

  return (
    <div>
      <Row>
        <Col className="mb-3 d-flex align-items-center">
          {Number.isInteger(props.totalAssignments) ? (
            <span className="mx-2 text-muted">
              {props.totalAssignments} {props.totalAssignments === 1 ? __('assignment') : __('assignments')}
            </span>
          ) : null}
        </Col>

        {isAssessor && group && basePath !== 'unity_pathways' ? (
          <Col className="mb-3">
            <div className="d-flex justify-content-end">
              <Link className="tw-btn tw-btn-success tw-btn-sm" to={`/${basePath}/${group.id}/assignments/new`}>
                {__('New Assignment')}
              </Link>
            </div>
          </Col>
        ) : null}
      </Row>

      <Row>
        <Col>
          {assignmentsLoading ? (
            <div className="d-flex align-items-center justify-content-center my-5">
              <Spinner color="info" />
            </div>
          ) : assignments.length ? assignments.map((assignment, i) => (
            <GroupAssignmentRow
              key={assignment.id}
              isAssessor={isAssessor}
              isSuperAdmin={isUserSuperAdmin}
              assignment={assignment}
              i={i}
              onCloneClick={handleCloneAssignmentClick}
              onDeleteClick={handleDeleteAssignmentClick}
            />
          )) : (
            <h4 className="mx-3 my-5 text-center font-weight-light">
              {__('No assignments')}
            </h4>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default GroupAssignments;
