import { useMutation } from 'react-query';
import { FindReflectionsResponse } from '../../../api/reflections';
import { FindReflectionVideosResponse } from '../../../api/reflections/videos';
import { FindReflectionPlaylistResponse } from '../../../api/reflections/[reflectionId]/playlist';
import { FindVideoResponse } from '../../../api/videos/[videoId]';
import { splitVideo, SplitVideoArguments, SplitVideoResponse } from '../../../api/videos/[videoId]/split';
import { queryClient } from '../../withQueryClient';

const useSplitVideoMutation = () => {
  const mutation = useMutation<SplitVideoResponse, unknown, SplitVideoArguments, unknown>(
    splitVideo,
    {
      onSuccess: (result) => {
        const video = result.data;

        queryClient.setQueryData<FindVideoResponse>(['videos', video.id], { data: video });

        if (video.reflection_id) {
          void queryClient.invalidateQueries<FindReflectionsResponse>(['reflections', video.reflection_id], { exact: true });
          void queryClient.invalidateQueries<FindReflectionPlaylistResponse>(['reflections', video.reflection_id, 'playlist']);
          void queryClient.invalidateQueries<FindReflectionVideosResponse>(['reflections', video.reflection_id, 'videos']);
        }
      },
    },
  );

  return mutation;
};

export default useSplitVideoMutation;
