import React, { useState } from 'react';
import { Modal, ModalBox } from '../../../common/components/TailwindModal';
import * as Atlas from '../../../common/types/Atlas';
import useVideoCaptionUploader from './use-video-caption-uploader';

interface CaptionUploaderModalProps {
  videoId: Atlas.VideoID;
  onClose: () => void;
}

interface CaptionUploaderModalState {
  file?: File;
  name?: string;
}

const CaptionUploaderModal = (props: CaptionUploaderModalProps): JSX.Element => {
  const { videoId, onClose } = props;

  const [state, setState] = useState<CaptionUploaderModalState>({});

  const uploader = useVideoCaptionUploader(videoId);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen>
      <ModalBox>
        {(() => {
          switch (uploader.status) {
            case 'idle': {
              const baseId = `CaptionUploaderModal-${videoId}`;
              const fileInputId = `${baseId}-file`;
              const nameInputId = `${baseId}-name`;

              const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const { files } = event.currentTarget;
                if (!files) { return; }
                const file = files[0];
                if (!file) { return; }

                setState((s) => ({ ...s, file }));
              };

              const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = event.currentTarget;

                switch (name) {
                  case 'name': break;
                  default: return;
                }

                setState((s) => ({
                  ...s,
                  [name]: value,
                }));
              };

              const handleUploadClick = () => {
                if (!state.file) { return; }

                const nameWithExtension = (() => {
                  if (!state.name) { return undefined; }

                  const trimmedName = state.name.trim();
                  if (trimmedName.endsWith('.vtt')) { return trimmedName; }

                  return `${trimmedName}.vtt`;
                })();

                uploader.uploadFile({ file: state.file, name: nameWithExtension });
              };

              return (
                <>
                  <p className="tw-text-2xl tw-mb-4">
                    {__('Caption upload')}
                  </p>
                  <div className="tw-flex tw-items-center tw-gap-4 tw-mb-3">
                    <input
                      id={fileInputId}
                      type="file"
                      accept=".vtt"
                      className="tw-input tw-hidden"
                      onChange={handleFileChange}
                    />
                    <label htmlFor={fileInputId} className="tw-btn tw-m-0">
                      {__('Select File')}
                    </label>
                    <span>
                      {state.file ? (
                        state.file.name
                      ) : (
                        __('No file selected')
                      )}
                    </span>
                  </div>
                  <div className="tw-form-control">
                    <label htmlFor={nameInputId} className="tw-label">
                      <span className="tw-label-text tw-font-semibold">
                        {__('Caption name')}
                      </span>
                    </label>
                    <input
                      id={nameInputId}
                      className="tw-input tw-input-bordered tw-w-full"
                      name="name"
                      placeholder={state.file?.name}
                      value={state.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="tw-flex tw-justify-end tw-mt-4 tw-gap-3">
                    <button type="button" className="tw-btn" onClick={handleClose}>
                      {__('Close')}
                    </button>
                    <button
                      type="button"
                      disabled={!state.file}
                      className="tw-btn tw-btn-success"
                      onClick={handleUploadClick}
                    >
                      {__('Upload')}
                    </button>
                  </div>
                </>
              );
            }
            case 'creating': return (
              <>
                <p className="tw-text-2xl tw-mb-3">
                  {__('Uploading captions')}
                </p>
                <progress className="tw-progress tw-progress-info tw-h-4" value={5} max={100} />
              </>
            );
            case 'uploading': return (
              <>
                <p className="tw-text-2xl tw-mb-3">
                  {__('Uploading captions')}
                </p>
                <progress className="tw-progress tw-progress-info tw-h-4" value={Math.max(10, uploader.progress)} max={100} />
              </>
            );
            case 'success': return (
              <>
                <p className="tw-text-2xl tw-mb-3">
                  {__('Upload successful')}
                </p>
                <progress className="tw-progress tw-progress-info tw-h-4" value={100} max={100} />
                <div className="tw-flex tw-justify-end tw-mt-4">
                  <button type="button" className="tw-btn" onClick={handleClose}>
                    {__('Close')}
                  </button>
                </div>
              </>
            );
            case 'failed': return (
              <>
                <p className="tw-text-2xl tw-mb-3">
                  {__('Upload failed')}
                </p>
                <div className="tw-flex tw-justify-end tw-mt-4">
                  <button type="button" className="tw-btn" onClick={handleClose}>
                    {__('Close')}
                  </button>
                </div>
              </>
            );
            default: return null;
          }
        })()}
      </ModalBox>
    </Modal>
  );
};

export default CaptionUploaderModal;
