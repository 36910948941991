import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';

const GroupMenuLockedPage: React.FC<{
  page: Atlas.Page;
}> = (props): JSX.Element => {
  const { page } = props;

  return (
    <div className="tw-px-4 tw-py-2 tw-text-sm">
      <i className="fa fa-lock tw-mr-2" />

      {page.title}
    </div>
  );
};

export default GroupMenuLockedPage;
