import { useQuery, UseQueryResult } from 'react-query';
import { findReflectionAttachments, FindReflectionAttachmentsParams, FindReflectionAttachmentsResponse } from '../../api/reflections/attachments';

const useReflectionAttachmentsQuery = (
  params: FindReflectionAttachmentsParams,
): UseQueryResult<FindReflectionAttachmentsResponse> => {
  const { reflectionId, ...otherParams } = params;

  return useQuery(
    ['reflections', reflectionId, 'attachments', otherParams],
    () => findReflectionAttachments({ params }),
    { staleTime: 60000 },
  );
};

export default useReflectionAttachmentsQuery;
