import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import ApiError from '../../error';
import { generateFetchHeaders } from '../../routes';

export const watchDiscussionPath = (args: {
  discussionId: Atlas.DiscussionID;
}): string => (
  `/api/v0/discussions/${args.discussionId}/watch`
);

export interface WatchDiscussionParams {
  discussionId: Atlas.DiscussionID;
}

export interface WatchDiscussionBody {
  watch: Atlas.WatchStatus;
}

export interface WatchDiscussionArguments {
  params: WatchDiscussionParams;
  body: WatchDiscussionBody
}

export type WatchDiscussionResponse = void;

export const watchDiscussion = async (
  args: WatchDiscussionArguments,
) : Promise<WatchDiscussionResponse> => {
  const { discussionId } = args.params;
  const url = watchDiscussionPath({ discussionId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findDiscussion');
  }
};
