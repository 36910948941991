
import { Observable, from, concat, empty, defer } from "rxjs" ;
import { concatMap, delay, expand, } from 'rxjs/operators';

import * as types from './types';
import { logger } from './logger';
import { repackFirstPartWithEbmlHeader } from './ebmlMetadataWriter';

const deepClone = function (obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export class S3UploadCredentials extends types.UploadCredentials {
  readonly sessionToken: string
  readonly s3Bucket: S3Bucket
  readonly s3BucketKey: string
  readonly s3Region: types.S3Region

  constructor( awsAccessKeyId: string
             , awsSessionToken: string
             , awsSecretAccessKey: string
             , mediaKey: string
             , s3Bucket: S3Bucket
             , s3BucketKey: string
             ) {
    super(awsAccessKeyId, awsSecretAccessKey, mediaKey);
    this.sessionToken = awsSessionToken;
    this.s3Bucket     = s3Bucket;
    this.s3BucketKey  = s3BucketKey;
    this.s3Region     = inferS3RegionFromBucket(s3Bucket);
  }
}

/* An S3 bucket including its endpoint, which will be used to automatically determine the region. */
export class S3Bucket implements types.UploadDestination {
    readonly destination: string
    readonly endpoint: string
    constructor(bucketURI: string, endpoint: string) {
        this.destination = bucketURI;
        this.endpoint = endpoint;
    }
}

class S3UploadId implements types.UploadId {
    readonly uploadId: string
    constructor(newId: string) {
        this.uploadId = newId
    }
}

export class S3UploadStats extends types.UploadStats {
    currentChunksSize: number
    uploadingFirstChunkToFixMetadata: boolean
    // The roundtrip time between two upload parts.
    lastSuccessfulRoundtrip: Date

    constructor() {
        super();
        this.currentChunksSize = 0;
        this.uploadingFirstChunkToFixMetadata = false;
        this.lastSuccessfulRoundtrip = new Date();
    }

    recordIncomingChunk(this: S3UploadStats, blob: Blob) {
        this.totalRawMediaChunks += 1;
        this.currentChunksSize   += blob.size;
    }

    resetCurrentChunkSize(this: S3UploadStats) {
        this.currentChunksSize = 0;
    }

    canFormUploadPart(this: S3UploadStats): boolean {
        // 5 MB S3 UploadPart min size.
        // AWS is very picky about it, just having 5_000_000 won't work.
        return this.currentChunksSize >= 5 * 1024 * 1024;
    }
}

class S3UploadState implements types.UploadState {
    readonly s3: AWS.S3
    readonly mediaKey: string;
    readonly uploadDestination: S3Bucket
    // A key within an S3 bucket. We can't use the mediaKey directly as the Atlas
    // rendered S3 URI expects something different usually.
    readonly s3BucketKey: string;
    recordingStartedAt?: Date
    recordingStoppedAt?: Date
    partNumberCounter: number
    finalPartCreated: boolean
    uploadStats: S3UploadStats
    multiPartUploadId?: types.UploadId
    recordedChunks: Array<types.Chunk>
    pendingUploadParts: Array<S3UploadPart>
    completedUploads:   Map<number, UploadPartDigest>
    firstUploadPart?: S3UploadPart // Needed to inject the duration as metadata.

    // A boolean flag which sequence all the uploads to S3, preventing multiple
    // PUT requests to the Amazon API.
    canUploadNext: boolean

    constructor(mediaKey: string, s3BucketKey: string, awsBucket: S3Bucket, s3: AWS.S3) {
        this.s3 = s3;
        this.uploadDestination = awsBucket;
        this.mediaKey = mediaKey;
        this.s3BucketKey = s3BucketKey;
        this.partNumberCounter = 1; // AWS requires parts numbers to be between 1..10000, so this starts from 1.
        this.finalPartCreated = false;
        this.uploadStats = new S3UploadStats();
        this.recordedChunks = [];
        this.pendingUploadParts = [];
        this.completedUploads = new Map();
        this.canUploadNext  = true;
    }
}

type ETag = string;

/* A callback which will be invoked then the recording stops. Note how this doesn't mean
   the upload process also stopped, as there might be UploadPart(s) in transit. */
interface OnStop {
    (): Promise<OnStopResult>
}

export interface OnStopResult {
    readonly eTag: ETag;
    readonly mediaKey: string;
}


/* A callback which will be invoked in case of errors. */
interface OnError {
    (err: StreamingUploaderError): void
}

interface StreamingUploader {
    readonly beforeStart: types.BeforeStart
    readonly onStart: types.OnStart
    readonly onError: OnError
    readonly onStop:  OnStop
    readonly onNewChunk: types.OnNewChunk
    uploadStatus: types.UploadStatus
}

export class S3MultipartUploader implements StreamingUploader {

    private internalState: S3UploadState
    uploadStatus: types.UploadStatus
    onProgress:  types.OnProgress
    tag: string; // A tag we can use for logging purposes.

    constructor( tag: string
               , uploadCredentials: S3UploadCredentials
               , onProgress: types.OnProgress
               ) {
        this.onProgress = function (stats) { onProgress(deepClone(stats)) };
        this.uploadStatus = types.UploadStatus.NotStarted;
        this.tag = tag;

        var oldReqRegionForNetworkingError = AWS.S3.prototype.reqRegionForNetworkingError;

        // See: https://github.com/aws/aws-sdk-js/issues/1895
        // Avoids spamming max-keys=0 requests.
        AWS.util.update(AWS.S3.prototype, {
            reqRegionForNetworkingError(resp: any, done: any) {
                var request = resp.request;
                var bucket = request.params.Bucket;

                if (bucket != undefined || bucket != null) {
                    done();
                } else {
                  oldReqRegionForNetworkingError(resp, done);
                }

            }});

        var s3: AWS.S3 = new AWS.S3({
            apiVersion: "2006-03-01",
            params: { Bucket: uploadCredentials.s3Bucket.destination },
            region: renderS3Region(uploadCredentials.s3Region),
            accessKeyId: uploadCredentials.secretId,
            secretAccessKey: uploadCredentials.secretPassword,
            sessionToken: uploadCredentials.sessionToken,
            endpoint: uploadCredentials.s3Bucket.endpoint,
            signatureVersion: 'v4',
            s3DisableBodySigning: true,
            httpOptions: {
              connectTimeout: 0,
              timeout: 0 // See: https://github.com/aws/aws-sdk-js/issues/1704#issuecomment-326058806
            }
        });
        this.internalState = new S3UploadState( uploadCredentials.mediaKey
                                              , uploadCredentials.s3BucketKey
                                              , uploadCredentials.s3Bucket
                                              , s3);
    }

    readonly onStart = function (this: S3MultipartUploader): void {
        this.internalState.recordingStartedAt = new Date();
    }

    readonly beforeStart = function (this: S3MultipartUploader): Promise<types.UploadId> {
        var self = this;

        return new Promise((onSuccess, onFailure) => {

        var params = {
            Bucket: self.internalState.uploadDestination.destination,
            Key: self.internalState.s3BucketKey,
            ContentType: 'video/webm',
            ACL: 'private',
        };

        self.internalState.s3.createMultipartUpload(params, function(err: Error, data: any) {
            if (err) {
                let uploaderErr = self.makeStreamingUploaderError(err);
                self.onError(uploaderErr);
                onFailure(uploaderErr);
            }
            else {
              self.uploadStatus = types.UploadStatus.InProgress;
              logger.log(self.tag, "Starting new upload with Id" + data.UploadId);
              let s3UploadId = new S3UploadId(data.UploadId);
              self.internalState.multiPartUploadId = s3UploadId;
              onSuccess(s3UploadId);
            }
        });

        });
    }

    readonly onError = function (this: S3MultipartUploader, error: StreamingUploaderError) {
        let self = this;

        if (self.uploadStatus != types.UploadStatus.Errored) {
            self.uploadStatus = types.UploadStatus.Errored;
        }

        if (!error.isNetworkFailure()) {
            // Do not log the error (to avoid generating panic) but
            // rather keep track of the fact the network failed.

            console.error("An error occurred.");
            console.error(error, error.stack);

            self.writePostMortemInformation();
        }
    }

    // Writes some useful debugging information into an 'errors.log' file on S3.
    writePostMortemInformation() {
      // Currently unimplemented as we need
      // https://github.com/iconnect/atlas/issues/5630
      return;
    }

    // Aborts an upload. This is currently not used as it could lead to the premature
    // eviction of MPU that could be otherwise recovered later by support.
    abortUpload(this: S3MultipartUploader) {
        let self = this;
        var params = {
            Bucket: self.internalState.uploadDestination.destination,
            Key: self.internalState.s3BucketKey,
            UploadId: self.internalState.multiPartUploadId?.uploadId
        };

        var s3: AWS.S3 = self.internalState.s3;
        s3.abortMultipartUpload(params, function(err: Error, _data: any) {
            if (err) console.error(err, err.message);
            else     self.uploadStatus = types.UploadStatus.Errored
        });

        return;
    }

    readonly onStop = function (this: S3MultipartUploader): Promise<OnStopResult> {
        return new Promise((onSuccess, onFailure) => {

            let self = this;

            // Run the logic of the promise only if we aren't stopped yet. In case of errors we will
            // flip the status to errored, and this can be retried.
            if (self.uploadStatus != types.UploadStatus.Stopped) {

                // 1. Record the time so we can infer the duration out of this.
                self.internalState.recordingStoppedAt = self.internalState.recordingStoppedAt ?? new Date ();
                let endTime = self.internalState.recordingStoppedAt!;

                self.uploadStatus = types.UploadStatus.Stopped;
                let duration = Math.abs(endTime.getTime() - self.internalState.recordingStartedAt!.getTime()) / 1000;
                logger.log(self.tag, "Duration was..." + duration);


                // Complete the upload. At this point we will have the following situation:
                // 1. A number of 'UploadPart's which are currently *in flight* (i.e. 's3.uploadPart' is still
                //    finishing);
                // 2. A number of 'UploadPart's waiting to be uploaded (in the 'pendingUploadParts' array);
                // 3. A number of recorded 'Chunk's not turned yet into 'UploadPart's.

                if (!self.internalState.finalPartCreated) {
                  logger.log(self.tag, "Creating last part...");
                  // 3. If the array of recorded chunks is empty, this should still be fine, we will generate
                  // an empty 'Blob' that later will contain the metadata.
                  let finalUploadPart = self.newUploadPart();
                  self.internalState.pendingUploadParts.push(finalUploadPart);
                  self.internalState.finalPartCreated = true;
                }

                // Termination condition: the 'UploadStatus' is 'Stopped' and the number of 'inFlightParts' is 0.
                const uploadRest = self.uploadAllParts();

                const noInFlightParts = defer(async () => {
                    let noInFlight = self.internalState.uploadStats.inFlightParts == 0,
                        noPending  = self.internalState.pendingUploadParts.length == 0;
                    return [ noInFlight, noPending ];
                });

                const waitAllUploaded = noInFlightParts.pipe(
                    expand(result => {
                        switch(result.join(",")) {
                            case "true,true": // we are really done.
                                return empty();
                            case "true,false":
                                logger.log(self.tag, "Detected more pending uploads...");
                                // we have no in-flight parts but we do
                                // still have pending uploads. We try again.
                                return concat(self.uploadAllParts(), noInFlightParts);
                            default:
                                return noInFlightParts.pipe(delay(2000));
                        }
                    })
                );

                const overrideFirstPart = new Observable(subscriber => {
                    logger.debug(self.tag, "Overriding the first part to inject metadata...");
                    const found = self.internalState.firstUploadPart;

                    if (found == undefined) {
                        subscriber.error(new StreamingUploaderError(Reason.FirstPartNotFound));
                    } else {
                        from(repackFirstPartWithEbmlHeader(found, duration).then(
                            newPart => {
                                // Make this count as a genuine new part, so that user progress won't look weird.
                                self.internalState.uploadStats.totalUploadParts += 1;
                                self.internalState.uploadStats.uploadingFirstChunkToFixMetadata = true;
                                self.onProgress(self.internalState.uploadStats);
                                self.uploadPart(self, newPart);
                            }
                        )).subscribe({
                            error(err) { subscriber.error(err); },
                            complete() { subscriber.complete(); },
                        });
                    }
                });

                concat( uploadRest
                  , waitAllUploaded
                  , overrideFirstPart
                  , waitAllUploaded
                      ).subscribe({
                          next(x) {
                              logger.debug(self.tag, "finalUpload.subscribe:" + x);
                              self.onProgress(self.internalState.uploadStats);
                          },
                          error(err) {

                              // Don't log the error here, leave the handling to the 'onError' callback.
                              let streamingErr = self.makeStreamingUploaderError(err, Reason.UploadStillInProgress);

                              self.onError(streamingErr);
                              onFailure(streamingErr);
                          },
                          complete() {
                              logger.debug(self.tag, "Ready to call completeMultipartUpload.");

                              var unorderedParts = [];
                              for (let entry of self.internalState.completedUploads.values()) {
                                  unorderedParts.push(entry);
                              }

                              // AWS requires the parts to be ordered.
                              var orderedParts =
                                  unorderedParts.sort(compareParts).map(d => d.toAwsPart());

                              var params = {
                                  Bucket: self.internalState.uploadDestination.destination,
                                  Key: self.internalState.s3BucketKey,
                                  MultipartUpload: {
                                      Parts: orderedParts
                                  },
                                  UploadId: self.internalState.multiPartUploadId?.uploadId
                              };

                              self.internalState.s3.completeMultipartUpload(params, function(err, data) {
                                  if (err) {
                                      logger.debug(self.tag, JSON.stringify(err));
                                      self.onError(self.makeStreamingUploaderError(err));
                                  }
                                  else  {
                                      self.uploadStatus = types.UploadStatus.Completed;
                                      self.internalState.uploadStats.lastSuccessfulRoundtrip = new Date();
                                      let json = { eTag: data.ETag, mediaKey: self.internalState.mediaKey };
                                      logger.debug(self.tag, "Returning " + JSON.stringify(json));
                                      onSuccess(json);
                                  }
                              });
                          }
                      });
            }

        });

    }

    readonly lastSuccessfulRoundtrip = function (this: S3MultipartUploader): Date {
        var self = this;
        return self.internalState.uploadStats.lastSuccessfulRoundtrip;
    }

    readonly onNewChunk = function (this: S3MultipartUploader, rawBlob: Blob) {
        var self = this;
        var chunk = new types.Chunk(rawBlob);
        self.internalState.recordedChunks.push(chunk);
        self.internalState.uploadStats.recordIncomingChunk(rawBlob);

        // If the recording stopped, this will now be handled by the finaliser.
        if (self.uploadStatus == types.UploadStatus.Stopped) return;

        // check if the total recordedSize is > 5MB.


        // If we can upload the next part, try to opportunistically upload the next chunk.
        if (self.internalState.uploadStats.canFormUploadPart()) {
          self.internalState.uploadStats.resetCurrentChunkSize();

          var uploadPart = self.newUploadPart();
          logger.debug(self.tag, "New upload part created: " + JSON.stringify(uploadPart));
          self.internalState.uploadStats.totalUploadParts += 1;

          // Notify the user
          self.onProgress(self.internalState.uploadStats);

          self.internalState.pendingUploadParts.push(uploadPart);
       }

       if (self.internalState.canUploadNext && self.internalState.pendingUploadParts.length >= 1) {
          // This will never fail, we pushed 'uploadPart' into 'pendingUploadParts' and therefore the
          // size of that array is always at least >= 1.
          var nextUploadablePart = self.internalState.pendingUploadParts.shift()!;

          // Try to opportunistically upload a chunk.
          var nextUpload = self.uploadPart(self, nextUploadablePart);
          from(nextUpload).subscribe({
              next(_digestAndInFlights) {
                  self.onProgress(self.internalState.uploadStats);
              },
              error(err) {
                  self.internalState.uploadStats.retransmittedParts += 1;
                  self.onProgress(self.internalState.uploadStats);
                  self.onError(err);
              },
              complete() {
                  self.onProgress(self.internalState.uploadStats);
              }
          });

        }

        return;
    }

    // A 'Promise' that an 'UploadPart' will be uploaded (or not) by S3, at some point in the future.
    // This will take variable time depending from a lot of factors, primarily the connection speed.
    uploadPart(uploader: S3MultipartUploader, nextUploadablePart: S3UploadPart): Promise<[UploadPartDigest, number]> {
        var self = uploader;
        self.internalState.uploadStats.inFlightParts += 1;
        self.onProgress(self.internalState.uploadStats);
        logger.debug(self.tag, "inFlightParts = " + self.internalState.uploadStats.inFlightParts);

        return new Promise((onSuccess, onFailure) => {
          var params = {
              Body: nextUploadablePart.blob,
              Bucket: self.internalState.uploadDestination.destination,
              Key: self.internalState.s3BucketKey,
              PartNumber: nextUploadablePart.partNumber,
              UploadId: self.internalState.multiPartUploadId?.uploadId,
          };

          logger.debug(self.tag, "Calling s3.uploadPart...");
          self.internalState.canUploadNext = false;
          self.internalState.s3.uploadPart(params, function(err: Error, data: any) {
              if (err) {
                  self.internalState.uploadStats.inFlightParts -= 1;
                  self.onProgress(self.internalState.uploadStats);
                  // Put back the 'nextUploadablePart' into the queue, at the front.
                  self.internalState.pendingUploadParts.unshift(nextUploadablePart);
                  self.internalState.canUploadNext = true;
                  onFailure(self.makeStreamingUploaderError(err));
              }
              else {
                  self.internalState.uploadStats.uploadedParts += 1;
                  self.internalState.uploadStats.inFlightParts -= 1;
                  nextUploadablePart.eTag = data.ETag;
                  let digest = new UploadPartDigest(nextUploadablePart);
                  self.internalState.completedUploads.set(digest.partNumber, digest);
                  logger.debug(self.tag, data);
                  self.internalState.uploadStats.lastSuccessfulRoundtrip = new Date();
                  self.onProgress(self.internalState.uploadStats);
                  self.internalState.canUploadNext = true;
                  onSuccess([digest, self.internalState.uploadStats.inFlightParts]);
              }
          });
        });
    }

    // Uploads /all/ the pending 'UploadPart's.
    uploadAllParts(this: S3MultipartUploader): Observable<[UploadPartDigest, number]> {
        var self = this;
        var uploader = this;
        var parts = uploader.internalState.pendingUploadParts;
        uploader.internalState.pendingUploadParts = [];
        logger.log(self.tag, "Uploading remaining " + parts.length + " parts..");
        return from(parts).pipe( concatMap(p => uploader.uploadPart(uploader, p)) );
    }


    // Generates a new UploadPart.
    newUploadPart(this: S3MultipartUploader): S3UploadPart {
      var self = this;
      var part = new S3UploadPart(this.internalState.recordedChunks, self.internalState.partNumberCounter);

      if (part.partNumber == 1) self.internalState.firstUploadPart = part;

      self.internalState.partNumberCounter += 1;
      self.internalState.recordedChunks = []; // reset the chunk cache.
      return part;
    }

    makeStreamingUploaderError(this: S3MultipartUploader, err: Error, reason?: Reason): StreamingUploaderError {
        var self = this;
        var finalReason  = Reason.UploadStillInProgress;
        var finalMessage = "S3Error";
        var errStack     = err.stack ?? "";

        if (err && (err.name.includes("NetworkError")
                    || err.message.includes("NetworkError")

                    || err.name.includes("net::ERR")
                    || err.message.includes("net::ERR")

                    || err.name.includes("Network Failure")
                    || err.message.includes("Network Failure")

                    || err.name.includes("NetworkFailure")
                    || err.message.includes("NetworkFailure")

                    || err.message.includes("NetworkingError")
                    || err.name.includes("NetworkingError")

                    || errStack.includes("NetworkFailure")
                    || errStack.includes("NetworkError")
                    || errStack.includes("NetworkingError")
                    || errStack.includes("net::ERR")
                    || (!navigator.onLine)
                   )
           ) {
               finalMessage = "NetworkFailure";
               finalReason  = Reason.NetworkFailure;
           } else {
               finalReason = reason ?? Reason.S3Error;
           }

        let streamingErr = new StreamingUploaderError(finalReason);

        if (finalReason == Reason.UploadStillInProgress) {
            // Try to be clever: if the 'inFlightParts' are > 0
            // and/or the 'pendingUploadParts' > 0 then use the
            // user-friendly message, otherwise log the raw underlying
            // error, which might be masking the real failure
            // see https://github.com/iconnect/agora/issues/7969.
            if (self.internalState.uploadStats.inFlightParts > 0 || self.internalState.pendingUploadParts.length > 0)
              finalMessage = "There are still "
                           + self.internalState.uploadStats.inFlightParts
                           + " parts in flight, size of toUploadChunks = "
                           + self.internalState.pendingUploadParts.length;
            else
              finalMessage = err.message;
        }

        streamingErr.message = finalMessage;
        return streamingErr;
    }

}

const compareParts = function(a: UploadPartDigest, b: UploadPartDigest): number {
    return a.partNumber - b.partNumber;
};


class S3UploadPart extends types.UploadPart {
  eTag?: string;
}

class UploadPartDigest {
    readonly partNumber: number;
    readonly bytesSize: number;
    eTag: string;

    constructor(part: S3UploadPart) {
        this.bytesSize  = part.blob.size;
        this.partNumber = part.partNumber;

        if (part.eTag == undefined) {
            throw new StreamingUploaderError(Reason.ETagMissing);
        } else this.eTag = part.eTag;
    }

    // Converts this 'UploadPart' into a JavaScript Object AWS understands.
    toAwsPart(this: UploadPartDigest): Object {
        return { ETag: this.eTag, PartNumber: this.partNumber };
    }
}


export enum Reason {
    ETagMissing = 1,
    UploadIdMissing,
    S3Error,
    UploadStillInProgress,
    PartNumberMissing,
    FirstPartNotFound,
    NetworkFailure
};

export class StreamingUploaderError extends Error {
    reason: Reason
    constructor(reason: Reason) {
        super();
        Object.setPrototypeOf(this, StreamingUploaderError.prototype);
        this.reason = navigator.onLine ? reason : Reason.NetworkFailure;
    }

    // Returns 'True' if this error was generated by a network failure
    // of some kind.
    isNetworkFailure(this: StreamingUploaderError) {
        var self = this;
        return (self.reason == Reason.NetworkFailure);
    }
}

// Parses the input string into an 'S3Region'.
function parseS3Region(s3RegionTxt: string): types.S3Region {
    switch(s3RegionTxt) {
        case "us-east-1":
            return types.S3Region.Us_east_1;
        case "us-east-2":
            return types.S3Region.Us_east_2;
        case "us-west-1":
            return types.S3Region.Us_west_1;
        case "us-west-2":
            return types.S3Region.Us_west_2;
        case "ca-central-1":
            return types.S3Region.Ca_central_1;
        case "ap-south-1":
            return types.S3Region.Ap_south_1;
        case "ap-northeast-2":
            return types.S3Region.Ap_northeast_2;
        case "ap-southeast-1":
            return types.S3Region.Ap_southeast_1;
        case "ap-southeast-2":
            return types.S3Region.Ap_southeast_2;
        case "ap-northeast-1":
            return types.S3Region.Ap_northeast_1;
        case "eu-central-1":
            return types.S3Region.Eu_central_1;
        case "eu-west-1":
            return types.S3Region.Eu_west_1;
        case "eu-west-2":
            return types.S3Region.Eu_west_2;
        case "sa-east-1":
            return types.S3Region.Sa_east_1;
        case "cn-north-1":
            return types.S3Region.Cn_north_1;
        default: {
            console.warn("Unrecognised S3 bucket region: " + s3RegionTxt + ". Defaulting to eu-west-1.");
            return types.S3Region.Eu_west_1;
        }
    }
}

// The dual of 'parseS3Region', it renders an 'S3Region' back
// into a valid string.
function renderS3Region(s3Region: types.S3Region): string {
    switch(s3Region) {
        case types.S3Region.Us_east_1:
            return "us-east-1";
        case types.S3Region.Us_east_2:
            return "us-east-2";
        case types.S3Region.Us_west_1:
            return "us-west-1";
        case types.S3Region.Us_west_2:
            return "us-west-2";
        case types.S3Region.Ca_central_1:
            return "ca-central-1";
        case types.S3Region.Ap_south_1:
            return "ap-south-1";
        case types.S3Region.Ap_northeast_2:
            return "ap-northeast-2";
        case types.S3Region.Ap_southeast_1:
            return "ap-southeast-1";
        case types.S3Region.Ap_southeast_2:
            return "ap-southeast-2";
        case types.S3Region.Ap_northeast_1:
            return "ap-northeast-1";
        case types.S3Region.Eu_central_1:
            return "eu-central-1";
        case types.S3Region.Eu_west_1:
            return "eu-west-1";
        case types.S3Region.Eu_west_2:
            return "eu-west-2";
        case types.S3Region.Sa_east_1:
            return "sa-east-1";
        case types.S3Region.Cn_north_1:
            return "cn-north-1";
        default: {
            return "eu-west-1";
        }
    }
}

/* Infers the S3 Region from the Bucket. Defaults to 'eu-west-1' if no
   reasonable region can be found.
*/
function inferS3RegionFromBucket(s3Bucket: S3Bucket): types.S3Region {
    var parsed = parseS3Region(s3Bucket.endpoint.split(".")[0]?.replace("s3-", ""));
    if (parsed == undefined) {
        console.warn("inferS3RegionFromBucket couldn't extract region, defaulting to eu-west-1");
        return types.S3Region.Eu_west_1;
    }
    return parsed;
}
