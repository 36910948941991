import useDestroyMilestoneMutation from './api/useDestroyMilestoneMutation';
import * as Atlas from '../../../../common/types/Atlas';

const useDeleteMilestone = (groupId: Atlas.GroupID, onSuccess?: () => void) => {
  const milestoneMutation = useDestroyMilestoneMutation(groupId);

  const handleDelete = async (milestoneId: Atlas.MilestoneIdentifier) => {
    if (!confirm(__('Are you sure you want to delete this milestone?'))) { return; }

    await milestoneMutation.mutateAsync({
      params: { groupId, milestoneId },
    });
    if (onSuccess) {
      onSuccess();
    }
  };

  return { 
    handleDelete,
    isError: milestoneMutation.isError,
    error: __('Could not delete the milestone. Try again later.') };
};

export default useDeleteMilestone;