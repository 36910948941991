import * as Atlas from '../../types/Atlas';
import { inRange } from '../../utils/utils';
import { parseModelObjectArray } from '../api-parser';
import ApiError from '../error';
import { jsonHeaders, searchParams, serializeSearchParams } from '../routes';

export const searchRoute = (): string => '/search';
export const searchSharableRoute = (): string => '/api/v0/searchs/sharable';

interface SearchArgs {
  params: {
    q: string;
    perPage?: number;
    page?: number;
    groups?: boolean;
    organizations?: boolean;
    users?: boolean;
    reflections?: boolean;
    devices?: boolean;
  };
}

export interface SearchResult {
  model: 'Organization' | 'Group' | 'User' | 'Reflection' | 'Device';
  name: string;
  description: string;
  image_url: string;
  id: string;
  organization: string;
  atlas_id: number;
}

export const search = (args: SearchArgs): Promise<{
  results: SearchResult[];
  page: number;
  total_pages: number;
}> => {
  const { params } = args || {};

  const searchUrl = [
    searchRoute(),
    searchParams({
      q: params.q,
      page: params.page,
      per_page: params.perPage,
      'options[groups]': String(!!params.groups),
      'options[organizations]': String(!!params.organizations),
      'options[users]': String(!!params.users),
      'options[reflections]': String(!!params.reflections),
      'options[devices]': String(!!params.devices)
    })
  ].join('?');

  return fetch(searchUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] search failed`; }
    return r.json();
  });
};

export interface SearchSharableParams {
  query: string;
  excludeUserIds: Atlas.UserID[];
  excludeGroupIds: Atlas.GroupID[];
  scope?: 'User' | 'Group';
  reflectionId?: Atlas.ReflectionID;
}

interface SearchSharableArgs {
  params: SearchSharableParams;
}

export type SearchSharableResult = {
  model: 'User';
  data: Atlas.SearchUser;
} | {
  model: 'Group';
  data: Atlas.SharableGroup;
};
export interface SearchSharableResponse {
  data: Array<SearchSharableResult>;
}

export const searchSharable = async (args: SearchSharableArgs): Promise<SearchSharableResponse> => {
  const { params } = args;

  const searchSharableUrl = [
    searchSharableRoute(),
    serializeSearchParams({
      query: params.query,
      exclude_user_ids: params.excludeUserIds,
      exclude_group_ids: params.excludeGroupIds,
      scope: params.scope,
      reflectionId: params.reflectionId,
    }),
  ].join('?');

  const response = await fetch(searchSharableUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'searchSharable');
  }

  const body = await response.json();
  const data = parseModelObjectArray(body.data) as Array<SearchSharableResult>;

  return { data };
};
