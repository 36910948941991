import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { findVideoComments, FindVideoCommentsParams, FindVideoCommentsResponse } from '../../api/videos/comments';
import { useCallback } from 'react';
import { VideoID } from '../../types/Atlas';

const buildKey = (
  videoId: VideoID,
) => {
  return ['videos', videoId, 'comments'];
};

export const useVideoCommentsInvalidator = () => {
  const queryClient = useQueryClient();

  return useCallback((...args: Parameters<typeof buildKey>) => {
    return queryClient.invalidateQueries(buildKey(...args));
  }, []);
};

const useVideoCommentsQuery = (
  params: FindVideoCommentsParams,
  options?: {
    staleTime?: number;
    refetchInterval?: number;
  }
): UseQueryResult<FindVideoCommentsResponse> => {
  const { videoId, ...otherParams } = params;

  return useQuery(
    [...buildKey(videoId), otherParams],
    () => findVideoComments({ params }),
    { staleTime: 60000, ...options, },
  );
};

export default useVideoCommentsQuery;
