/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import useUpdateImageMutation from '../../../../common/hooks/api/images/useUpdateImageMutation';
import useMouseEvent from '../../../../common/hooks/useMouseEvent';
import * as Atlas from '../../../../common/types/Atlas';
import { useBannerCropper } from './BannerImage';
import { defaultGroupBanner } from '../shared';

interface GroupEditableBannerProps {
  banner: Atlas.Image;
}

interface GroupEditableBannerState {
  status: 'idle' | 'dragging';
  start: number;
  end: number;
  diff: number;
  nextDiff: number;
}

const GroupEditableBanner = (props: GroupEditableBannerProps): JSX.Element => {
  const { banner } = props;

  const bannerRef = useRef<HTMLImageElement>(null);
  const updateBanner = useUpdateImageMutation();

  const [state, setState] = useState<GroupEditableBannerState>({
    status: 'idle',
    start: 0,
    end: 0,
    diff: 0,
    nextDiff: 0,
  });

  const bannerCropper = useBannerCropper(
    bannerRef,
    banner,
    state.nextDiff + state.diff + state.end - state.start,
  );

  const handleMouseMove = useCallback((event: MouseEvent) => {
    setState((s) => {
      if (s.status !== 'dragging') { return s; }

      return { ...s, end: event.clientY };
    });
  }, []);

  const handleMouseUp = useCallback(() => {
    setState((s) => {
      if (s.status !== 'dragging') { return s; }

      return {
        ...s,
        status: 'idle',
        start: 0,
        end: 0,
        diff: s.diff + s.end - s.start,
      };
    });
  }, []);

  useMouseEvent('mousemove', handleMouseMove, { enabled: state.status === 'dragging' });
  useMouseEvent('mouseup', handleMouseUp, { enabled: state.status === 'dragging' });

  const bannerOffsetY = banner.offset?.y;
  useEffect(() => {
    if (updateBanner.isLoading) { return; }
    if (state.status !== 'idle') { return; }
    if (state.diff === 0) { return; }
    if (bannerOffsetY === undefined) { return; }
    if (bannerOffsetY === bannerCropper.actualOffsetY) { return; }

    setState((s) => ({ ...s, nextDiff: state.diff, diff: s.diff - state.diff }));

    void updateBanner.mutateAsync({
      params: { imageId: banner.id },
      body: {
        offset: { y: bannerCropper.actualOffsetY, x: 0 },
      },
    }).then(() => {
      setState((s) => ({ ...s, nextDiff: 0 }));
    }, () => {
      setState((s) => ({ ...s, nextDiff: 0, diff: s.diff + s.nextDiff }));
    });
  }, [
    banner.id,
    bannerOffsetY,
    bannerCropper.actualOffsetY,
    state.diff,
    state.status,
    updateBanner,
  ]);

  const handleMouseDown = (event: React.MouseEvent<HTMLImageElement>) => {
    setState((s) => ({
      ...s,
      status: 'dragging',
      start: event.clientY,
      end: event.clientY,
    }));
  };

  return (
    <img
      ref={bannerRef}
      alt={__('Group banner')}
      draggable={false}
      src={banner?.viewable_s3uri?.url ?? defaultGroupBanner}
      className="tw-relative tw-object-cover tw-object-left tw-cursor-move tw-min-w-full tw-min-h-full"
      style={{ top: `${bannerCropper.offsetY}px` }}
      onMouseDown={handleMouseDown}
    />
  );
};

export default GroupEditableBanner;
