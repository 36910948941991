import clamp from 'lodash-es/clamp';
import React, { useLayoutEffect, useRef, useState } from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import { defaultGroupBanner } from '../shared';

interface BannerDimensions {
  height: number | null;
  width: number | null;
  naturalHeight: number | null;
  naturalWidth: number | null;
  parentHeight: number | null;
}

export const useBannerCropper = (
  bannerRef: React.RefObject<HTMLImageElement>,
  banner: Atlas.Image,
  additionalOffsetY = 0,
) => {
  const [bannerDimensions, setBannerDimensions] = useState<BannerDimensions>({
    height: null,
    width: null,
    naturalHeight: null,
    naturalWidth: null,
    parentHeight: null,
  });

  useLayoutEffect(() => {
    if (!bannerRef.current) { return undefined; }
    const bannerImg = bannerRef.current;

    const calculateOffsets = () => {
      setBannerDimensions({
        height: bannerImg.height ?? null,
        width: bannerImg.width ?? null,
        naturalHeight: bannerImg.naturalHeight ?? null,
        naturalWidth: bannerImg.naturalWidth ?? null,
        parentHeight: bannerImg.parentElement?.clientHeight ?? bannerImg.height,
      });
    };

    calculateOffsets();

    const observer = new ResizeObserver(calculateOffsets);
    observer.observe(bannerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [bannerRef]);

  const width = bannerDimensions.width ?? banner.cropped_dimensions?.width ?? 0;
  const naturalHeight = (
    bannerDimensions.naturalHeight ?? banner.original_dimensions?.height ?? 0
  );
  const naturalWidth = (
    bannerDimensions.naturalWidth ?? banner.original_dimensions?.width ?? 0
  );
  const aspectRatio = naturalWidth / naturalHeight;

  const offsetY = (banner?.offset?.y ?? 0) / -1000;
  const y = width * (offsetY / aspectRatio);

  const clampOffsetY = (offset: number) => {
    if (!bannerDimensions.height || !bannerDimensions.parentHeight) {
      return 0;
    }

    return clamp(
      offset,
      -(bannerDimensions.height - bannerDimensions.parentHeight),
      0,
    );
  };

  const bannerOffsetY = clampOffsetY(y + additionalOffsetY);
  const actualOffsetY = ((bannerOffsetY / width) * aspectRatio) * -1000;

  return {
    offsetY: bannerOffsetY,
    actualOffsetY,
  };
};

interface GroupBannerImageProps {
  banner: Atlas.Image;
}

const GroupBannerImage = (props: GroupBannerImageProps): JSX.Element => {
  const { banner } = props;

  const bannerRef = useRef<HTMLImageElement>(null);
  const bannerCropper = useBannerCropper(bannerRef, banner);

  return (
    <img
      ref={bannerRef}
      alt={__('Group banner')}
      draggable={false}
      src={banner.viewable_s3uri?.url ?? defaultGroupBanner}
      className="tw-relative tw-object-cover tw-object-left tw-min-w-full tw-min-h-full"
      style={{ top: `${bannerCropper.offsetY}px` }}
    />
  );
};

export default GroupBannerImage;
