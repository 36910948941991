import React from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Atlas from '../../../../common/types/Atlas';
import { getFormattedMilestoneLabel } from '../utils/utils';

interface MilestonesListItemProps {
  milestone: Atlas.Milestone;
  groupId: Atlas.GroupID;
  sorting?: boolean;
  onDeleteClick: (milestoneId: Atlas.MilestoneIdentifier) => void;
}

const MilestonesListItem = (props: MilestonesListItemProps): JSX.Element => {
  const params = useParams<{ containerId: string; }>();
  const containerId = Number(params.containerId);

  const handleDeleteMilestoneClick = () => {
    props.onDeleteClick(props.milestone.id);
  };

  return(
    <div className='tw-p-2 tw-mb-3 tw-flex tw-iris-element-box tw-items-start tw-justify-between'>
      <div className='tw-my-1 tw-mx-2'>
        <div className="tw-font-semibold tw-mb-2">
          {props.milestone.name}
        </div>
        <p className="tw-text-gray-500 tw-mb-3">
          {props.milestone.description}
        </p>
        <div>
          <div className="tw-iris-label-default">
            {getFormattedMilestoneLabel(props.milestone.type)}
          </div>
        </div>
      </div>
      {props.sorting ? null : (
        <div className='tw-p-2'>
          <div
            className="tw-dropdown tw-dropdown-left"
            >
            <label tabIndex={0} className="tw-p-1 tw-btn tw-btn-sm tw-btn-circle">
              <i className="fa fa-bars" />
            </label>
            <div className="tw-dropdown-content tw-iris-dropdown">
              <div className="tw-iris-dropdown-box tw-reflection-comment-dropdown">
                <ul tabIndex={0}>
                  <li>
                    <Link
                      to={`/pathways_management/${containerId}/templates/${props.groupId}/milestones/${props.milestone.id}/edit`}>
                        {__('Edit')}
                    </Link>
                  </li>
                </ul>
                  <ul tabIndex={0}>
                    <li>
                      <button
                        className="tw-block tw-w-full tw-px-4 tw-py-2 tw-text-left tw-text-sm tw-hover:bg-gray-100 tw-text-red-600 "
                        onClick={handleDeleteMilestoneClick}>
                          {__('Delete')}
                      </button>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MilestonesListItem;
