import React, { useCallback } from 'react';
import FormCounterElement from './elements/FormCounterElement';
import FormMultipleChoiceElement from './elements/FormMultipleChoiceElement';
import FormRatingElement from './elements/FormRatingElement';
import FormTimerElement from './elements/FormTimerElement';
import { allFormElementOptions } from './formElementOptions';
import * as Atlas from '../../../common/types/Atlas';
import AttachmentList from './AttachmentList';
import AttachmentUploaderButton from './AttachmentUploaderButton';
import { openAiFileTypes } from './ai-filetypes';
import { isEnum } from '../../../common/utils/utils';

interface FormElementProps {
  formElement: Atlas.FormElement;
  showRequiredOption: boolean;
  showAllowMultipleAnswersOption?: boolean;
  onMoveUpClick?: () => void;
  onMoveDownClick?: () => void;
  onRemoveClick?: () => void;
  onChange: (changes: { field: Partial<Atlas.FormField> }) => void;
  aiEnabled?: boolean;
  isEditingLimited?: boolean;
}

const FormElement = (props: FormElementProps): JSX.Element | null => {
  const {
    formElement,
    showRequiredOption,
    showAllowMultipleAnswersOption,
    onMoveUpClick: handleMoveUpClick,
    onMoveDownClick: handleMoveDownClick,
    onRemoveClick: handleRemoveClick,
    onChange,
    aiEnabled,
    isEditingLimited,
  } = props;

  if (!formElement) { return null; }

  const { field } = formElement;
  const type = Object.keys(field.type)[0];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (!onChange) { return; }

    const { name, value } = e.currentTarget;

    if (name === 'reasoning_level' && isEnum(value, Atlas.AIReasoningLevel)) {
      onChange({
        field: {
          ai_settings: {
            reasoning_level: value,
          }
        }
      });
    } else {
      // Convert the string value of the 'visible' field to a boolean
      const parsedValue = name === 'visible' ? value === 'true' : value;

      onChange({ field: { [name]: parsedValue } });
    }
  };

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) { return; }

    const { name, checked } = e.currentTarget;

    onChange({ field: { [name]: checked } });
  };

  const handleUploadAiFileComplete = useCallback((attachment: Atlas.Attachment) => {
    onChange({
      field: {
        ai_attachment_ids: [
          ...(field.ai_attachment_ids ?? []),
          attachment.id,
        ],
      },
    });
  }, [field]);

  const elementOption = (() => {
    switch (type) {
      case 'multiple_choice': {
        return {
          $el: <FormMultipleChoiceElement formElement={formElement} onChange={onChange} isEditingLimited={isEditingLimited} />,
          ...allFormElementOptions[type],
        };
      }
      case 'rating': {
        return {
          $el: <FormRatingElement formElement={formElement} onChange={onChange} isEditingLimited={isEditingLimited} />,
          ...allFormElementOptions[type],
        };
      }
      case 'counter': {
        return {
          $el: <FormCounterElement formElement={formElement} onChange={onChange} isEditingLimited={isEditingLimited} />,
          ...allFormElementOptions[type],
        };
      }
      case 'timer': {
        return {
          $el: <FormTimerElement formElement={formElement} onChange={onChange} isEditingLimited={isEditingLimited} />,
          ...allFormElementOptions[type],
        };
      }
      case 'attachment':
      case 'reflection':
      case 'url':
      case 'text': {
        return {
          $el: undefined,
          ...allFormElementOptions[type],
        };
      }

      default: {
        return undefined;
      }
    }
  })();
  if (!elementOption) { return null; }

  const { $el, name, icon } = elementOption;

  const handleAttachmentRemove = (attachmentId: Atlas.AttachmentID) => {
    if (!window.confirm(__('Remove this file?'))) { return; }

    onChange({
      field: { ai_attachment_ids: field.ai_attachment_ids?.filter((id) => id !== attachmentId) },
    });
  };

  return (
    <div className="tw-card tw-card-compact tw-bg-base-100 tw-shadow tw-mb-4">
      <div className="tw-card-body">
        <div className="tw-flex tw-justify-between tw-gap-3 tw-mb-3">
          <div className="tw-flex tw-gap-2">
            <button type="button" className="tw-btn tw-btn-xs" disabled={!handleMoveUpClick} onClick={handleMoveUpClick}>
              <i className="icon-arrow-up" />
            </button>
            <button type="button" className="tw-btn tw-btn-xs" disabled={!handleMoveDownClick} onClick={handleMoveDownClick}>
              <i className="icon-arrow-down" />
            </button>
          </div>

          <div>
            <h4 className="tw-font-bold tw-text-lg tw-flex tw-items-center tw-gap-2">
              <i className={icon} />
              <span>
                {name}
              </span>
            </h4>
          </div>

          <div>
            <button type="button" className="tw-btn tw-btn-xs tw-btn-error" disabled={isEditingLimited || !handleRemoveClick} onClick={handleRemoveClick}>
              <i className="icon-remove" />
            </button>
          </div>
        </div>

        <label className="tw-text-base">
          <div className="tw-mb-2 tw-font-semibold">
            {__('Question')}
          </div>
          <input required name="label" value={field.label || ''} className="tw-input tw-input-bordered tw-input-sm tw-w-full" onChange={handleInputChange} />
        </label>

        <label className="tw-text-base">
          <div className="tw-font-semibold">
            {__('Question Instructions')}
          </div>
          <div className="tw-mb-2 tw-text-sm tw-text-gray-600">
            {__('Instructions that will be displayed to the user')}
          </div>
          <textarea name="instructions" value={field.instructions || ''} className="tw-textarea tw-textarea-bordered tw-w-full" onChange={handleInputChange} />
        </label>

        {aiEnabled ? (
          <>
            <div className="tw-flex tw-flex-wrap tw-gap-3">
              <label className="tw-text-base">
                <div className="tw-mb-2 tw-font-semibold">
                  {__('AI Options')}
                </div>
                <select name="ai_options" value={field.ai_options || ''} className="tw-input tw-input-bordered tw-input-sm" onChange={handleInputChange}>
                  <option value={Atlas.FormFieldAIOptions.NoAi}>
                    {__('No AI')}
                  </option>
                  <option value={Atlas.FormFieldAIOptions.AiResponse}>
                    {__('AI response')}
                  </option>
                  <option value={Atlas.FormFieldAIOptions.AiResponseTimestamped} disabled>
                    {__('AI response with timestamps')}
                  </option>
                </select>
              </label>

              <label className="tw-text-base">
                <div className="tw-mb-2 tw-font-semibold">
                  {__('Reasoning Level')}
                </div>
                <select name="reasoning_level" value={field.ai_settings?.reasoning_level || Atlas.AIReasoningLevel.Low} className="tw-input tw-input-bordered tw-input-sm" onChange={handleInputChange}>
                  <option value={Atlas.AIReasoningLevel.Low}>
                    {__('Low')}
                  </option>
                  <option value={Atlas.AIReasoningLevel.Standard}>
                    {__('Standard')}
                  </option>
                  <option value={Atlas.AIReasoningLevel.Medium}>
                    {__('Medium')}
                  </option>
                  <option value={Atlas.AIReasoningLevel.High}>
                    {__('High')}
                  </option>
                </select>
              </label>
            </div>

            {field.ai_options && (field.ai_options !== Atlas.FormFieldAIOptions.NoAi) ? (
              <>
                <label className="tw-text-base">
                  <div className="tw-font-semibold">
                    {__('AI Instructions')}
                  </div>
                  <div className="tw-mb-2 tw-text-sm tw-text-gray-600">
                    {__('These instructions will not be displayed to the user, but will be sent to the AI')}
                  </div>
                  <textarea name="ai_instructions" value={field.ai_instructions || ''} className="tw-textarea tw-textarea-bordered tw-w-full" onChange={handleInputChange} />
                </label>

                <div className="tw-text-base">
                  <div className="tw-font-semibold">
                    {__('AI Files')}
                  </div>
                  <div className="tw-text-sm">
                    {__("Upload a file for the AI to read before answering this question.")}
                  </div>
                  <div className="tw-max-w-xs tw-mb-2">
                    <AttachmentList
                      attachmentIds={field.ai_attachment_ids ?? []}
                      onRemoveClick={handleAttachmentRemove}
                    />
                  </div>
                  <AttachmentUploaderButton
                    autoReset
                    acceptFile={openAiFileTypes.join(',')}
                    onUploadComplete={handleUploadAiFileComplete}
                  />
                </div>

                <label className="tw-text-base">
                  <div className="tw-font-semibold">
                    {__('Visible question')}
                  </div>
                  <div className="tw-mb-2 tw-text-sm tw-text-gray-600">
                    {__('You may want to hide certain questions to provide context for the AI without displaying them to users.')}
                  </div>
                  <select 
                    name="visible"
                    value={field.visible === false ? 'false' : 'true'}
                    className="tw-input tw-input-bordered tw-input-sm"
                    onChange={handleInputChange}
                  >
                    <option value={'true'}>
                      {__('Visible')}
                    </option>
                    <option value={'false'}>
                      {__('Hidden')}
                    </option>
                  </select>
                </label>
              </>
            ) : null}
          </>
        ) : null}

        <div className="tw-flex tw-flex-col tw-gap-2">
          {showRequiredOption || showAllowMultipleAnswersOption ? (
            <div>
              <div className="tw-text-base tw-font-semibold">
                {__('Options')}
              </div>

              <div className="tw-pl-1">
                {showRequiredOption ? (
                  <label className="tw-mb-0 tw-text-base tw-label tw-justify-start tw-gap-2">
                    <input type="checkbox" className="!tw-m-0 tw-checkbox !tw-w-6 !tw-h-6" name="required" checked={field.required} onChange={handleCheckChange} />
                    <span className="tw-label-text">
                      {__('Required field')}
                    </span>
                  </label>
                ) : null}

                {showAllowMultipleAnswersOption ? (
                  <label className="tw-mb-0 tw-text-base tw-label tw-justify-start tw-gap-2">
                    <input type="checkbox" className="!tw-m-0 tw-checkbox !tw-w-6 !tw-h-6" name="allow_multiple" checked={field.allow_multiple} onChange={handleCheckChange} />
                    <span className="tw-label-text">
                      {__('Allow multiple answers')}
                    </span>
                  </label>
                ) : null}
              </div>
            </div>
          ) : null}

          {$el}
        </div>
      </div>
    </div>
  );
};

export default FormElement;
