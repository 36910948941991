import React, { useCallback, useContext, useEffect, useState } from 'react';
import { cloneGroupAssignment, destroyGroupAssignment, findGroupAssignments } from '../../../../common/api/groups/assignments';
import GroupAssignments from '../../../../modules/group-assignment-list/GroupAssignmentList';
import Pagination from '../../../../common/components/Pagination';
import * as Atlas from '../../../../common/types/Atlas';
import { useQuery } from '../../../../common/hooks/useQuery';
import CurrentUserContext from '../../../../common/contexts/CurrentUserContext';
import { useNavigate, useParams } from 'react-router';
import useFindGroupQuery from '../../../../common/hooks/api/groups/useFindGroupQuery';
import { useLocation } from 'react-router-dom';
import { getAssignmentsBasePath } from '../../../../common/utils/getAssignmentsBasePath';

interface GroupAssignmentsPageState {
  assignmentsLoading: boolean;
  assignments: Atlas.ExpandedAssignment[];
  totalAssignments?: number;
  totalPages?: number;
}

const GroupAssignmentsPage = (): JSX.Element => {
  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);
  const params = useParams<{ groupId: string; }>();
  const groupId = Number(params.groupId);

  const { user } = useContext(CurrentUserContext),
    [getQuery, setQuery] = useQuery();

  const groupQuery = useFindGroupQuery(groupId);
  const group = groupQuery.data?.data;
  const groupLoading = groupQuery.isLoading;

  const page = Number(getQuery('page') ?? 1),
    perPage = Number(getQuery('per_page') ?? 20);

  const [state, setState] = useState<GroupAssignmentsPageState>({
    assignmentsLoading: true,
    assignments: []
  });

  const { totalPages } = state;

  useEffect(() => {
    if (typeof totalPages !== 'number') { return; }
    if (page <= totalPages) { return; }
    const nextPage = Math.max(totalPages, 1);
    setQuery({ page: String(nextPage) });
  }, [page, totalPages]);

  const fetchAssignments = useCallback(async () => {
    setState(s => ({ ...s, assignmentsLoading: true }));
    await findGroupAssignments({
      params: { groupId, page, per_page: perPage }
    }).then(({ data: assignments, metadata }) => {
      setState(s => ({
        ...s,
        assignmentsLoading: false,
        assignments,
        totalAssignments: metadata.total_entries,
        totalPages: metadata.total_pages
      }));
    });
  }, [groupId, page, perPage]);

  useEffect(() => {
    fetchAssignments();
  }, [fetchAssignments]);

  const navigate = useNavigate();

  const handleCloneAssignmentClick = (assignment: Atlas.Assignment) => {
    if (!confirm(__('Are you sure you want to clone this assignment?'))) { return; }

    setState(s => ({ ...s, assignmentsLoading: true }));
    cloneGroupAssignment({
      params: { groupId, assignmentId: assignment.id }
    }).then(({ data: newAssignment }) => {
      navigate(`/${basePath}/${newAssignment.group_id}/assignments/${newAssignment.id}/edit`);
    }, () => {
      fetchAssignments();
    });
  };

  const handleDeleteAssignmentClick = (assignment: Atlas.Assignment) => {
    if (!confirm(__('Are you sure you want to delete this assignment?'))) { return; }

    setState(s => ({ ...s, assignmentsLoading: true }));
    destroyGroupAssignment({
      params: { groupId, assignmentId: assignment.id }
    }).finally(() => {
      fetchAssignments();
    });
  };

  const handlePageChange = (page: number) => {
    setQuery({ page: String(page) });
  };

  return (
    <div>
      <GroupAssignments
        user={user}
        groupLoading={groupLoading}
        group={group}
        assignmentsLoading={state.assignmentsLoading}
        assignments={state.assignments}
        totalAssignments={state.totalAssignments}
        onCloneAssignmentClick={handleCloneAssignmentClick}
        onDeleteAssignmentClick={handleDeleteAssignmentClick} />

      {totalPages && totalPages > 1 ? (
        <Pagination
          page={page}
          totalPages={totalPages}
          onPageChange={handlePageChange} />
      ) : null}
    </div>
  );
};

export default GroupAssignmentsPage;
