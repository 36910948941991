/* eslint-disable react/jsx-props-no-spreading */
import { NuqsAdapter } from 'nuqs/adapters/react';
import React from 'react';

export default function withNuqsAdapter<P>(Component: React.ComponentType<P>) {
  const WithNuqsAdapter: React.FC<P & JSX.IntrinsicAttributes> = (props) => (
    <NuqsAdapter fullPageNavigationOnShallowFalseUpdates>
      <Component {...props} />
    </NuqsAdapter>
  );

  return WithNuqsAdapter;
}
