import React, { useState } from 'react';
import FormMultipleChoiceBarChart from './form-multiple-choice-charts/BarChart';
import FormMultipleChoicePieChart from './form-multiple-choice-charts/PieChart';
import FormMultipleChoiceLineChart from './form-multiple-choice-charts/LineChart';
import * as Atlas from '../../../common/types/Atlas';

enum Page {
  Pie,
  Bar,
  Line,
}

interface FormMultipleChoiceChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

const FormMultipleChoiceChart = (props: FormMultipleChoiceChartProps): JSX.Element => {
  const { formElement, formAnswers = [] } = props;

  const [state, setState] = useState({
    page: Page.Bar,
  });

  const handlePageClick = (page: Page) => {
    setState((s) => ({ ...s, page }));
  };

  return (
    <div>
      <div className="tw-flex tw-justify-end">
        <div className="tw-join tw-mb-2">
          <button
            type="button"
            className={`tw-btn tw-btn-xs tw-join-item ${state.page === Page.Bar ? 'tw-btn-info' : ''}`}
            onClick={() => handlePageClick(Page.Bar)}
          >
            <i className="fa fa-bar-chart" />
          </button>
          <button
            type="button"
            className={`tw-btn tw-btn-xs tw-join-item ${state.page === Page.Pie ? 'tw-btn-info' : ''}`}
            onClick={() => handlePageClick(Page.Pie)}
          >
            <i className="fa fa-pie-chart" />
          </button>
          <button
            type="button"
            className={`tw-btn tw-btn-xs tw-join-item ${state.page === Page.Line ? 'tw-btn-info' : ''}`}
            onClick={() => handlePageClick(Page.Line)}
          >
            <i className="fa fa-line-chart" />
          </button>
        </div>
      </div>

      {(() => {
        switch (state.page) {
          case Page.Pie: {
            return (
              <FormMultipleChoicePieChart formElement={formElement} formAnswers={formAnswers} />
            );
          }

          case Page.Bar: {
            return (
              <FormMultipleChoiceBarChart formElement={formElement} formAnswers={formAnswers} />
            );
          }

          case Page.Line: {
            return (
              <FormMultipleChoiceLineChart formElement={formElement} formAnswers={formAnswers} />
            );
          }

          default: return null;
        }
      })()}
    </div>
  );
};

export default FormMultipleChoiceChart;
