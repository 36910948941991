import { useQuery, UseQueryResult } from 'react-query';
import { findGroupReflections, FindGroupReflectionsParams, FindGroupReflectionsResponse } from '../../../api/groups/reflections';

const useFindGroupReflectionsQuery = (
  params: FindGroupReflectionsParams,
  options?: { enabled?: boolean; },
): UseQueryResult<FindGroupReflectionsResponse> => (
  useQuery(
    ['groups', params.groupId, 'reflections', ], 
    () => findGroupReflections({ params }),
    { ...options },
  )
);

export default useFindGroupReflectionsQuery;
