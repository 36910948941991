import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import { defaultGroupLogo } from '../shared';

interface GroupLogoImageProps {
  logo?: Atlas.Image;
}

const GroupLogoImage = (props: GroupLogoImageProps): JSX.Element => {
  const { logo } = props;

  return (
    <img
      alt={__('Group logo')}
      src={logo?.viewable_s3uri?.url ?? defaultGroupLogo}
      className="tw-object-cover tw-rounded-sm tw-bg-white"
    />
  );
};

export default GroupLogoImage;
