import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObjectArray } from '../../api-parser';
import ApiError from '../../error';
import { jsonHeaders, Metadata, PaginationParams, searchParams } from '../../routes';

export const reflectionVideosRoute = (args: {
  reflectionId: Atlas.ReflectionID
}): string => `/api/v0/reflections/${args.reflectionId}/videos`;

export type FindReflectionVideosParams = {
  reflectionId: Atlas.ReflectionID;
} & PaginationParams;

export interface FindReflectionVideosArguments {
  params: FindReflectionVideosParams;
}

export interface FindReflectionVideosResponse {
  data: Atlas.Video[];
  metadata: Metadata;
}

export const findReflectionVideos = async (
  args: FindReflectionVideosArguments
): Promise<FindReflectionVideosResponse> => {
  const { params } = args;

  const url = [
    reflectionVideosRoute({
      reflectionId: params.reflectionId,
    }),
    searchParams({
      page: params.page,
      per_page: params.per_page,
    }),
  ].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'findReflectionVideos');
  }

  const body = await response.json();
  const data = parseModelObjectArray(body.data) as Atlas.Video[];
  const metadata = body.metadata as Metadata;

  return { data, metadata };
};
