import React from 'react';
import ReactMarkdown from 'react-markdown';
import type { PluggableList, ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import { Link } from './link';

const sanitizeSchema = {
  ...defaultSchema,
  attributes: {
    ...defaultSchema.attributes,
    '*': [...(defaultSchema.attributes?.['*'] || []), 'className'],
  },
};

const remarkPlugins: PluggableList = [
  remarkGfm,
];

const rehypePlugins: PluggableList = [
  rehypeRaw,
  [rehypeSanitize, sanitizeSchema],
];

const components: ReactMarkdownOptions['components'] = {
  a: ({ node, ...props }) => <Link {...props} />,
};

const Markdown: React.FC<{
  children: string;
}> = ({
  children,
}) => (
    <ReactMarkdown
      remarkPlugins={remarkPlugins}
      rehypePlugins={rehypePlugins}
      components={components}
    >
      {children}
    </ReactMarkdown>
  );

export default Markdown;
