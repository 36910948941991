import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import GroupAssignmentSubmission from '../../../../../../../modules/group-assignment/submissions/AssignmentSubmission';
import AssignmentSubmissionLayout from '../layout';
import useGroupQuery from '../../../../../../../common/hooks/api/groups/useFindGroupQuery';
import useGroupAssignmentQuery from '../../../../../../../common/hooks/api/groups/useFindGroupAssignmentQuery';
import { getAssignmentsBasePath } from '../../../../../../../common/utils/getAssignmentsBasePath';
import useFindAssignmentSubmissionsQuery from '../../../../../../../common/hooks/api/assignments/useFindAssignmentSubmissionsQuery';
import * as Atlas from '../../../../../../../common/types/Atlas';
import Spinner from '../../../../../../../common/components/Spinner';

const NewGroupAssignmentSubmissionPage = (): JSX.Element | null => {
  const params = useParams<{
    groupId: string;
    assignmentId: string;
  }>();

  const groupId = Number(params.groupId);
  const assignmentId = Number(params.assignmentId);

  const draftSubmissionsQuery = useFindAssignmentSubmissionsQuery(
    assignmentId,
    Atlas.SubmissionComplete.Draft,
  )

  const location = useLocation();
  const basePath = getAssignmentsBasePath(location.pathname);

  const backTo = new URLSearchParams(location.search).get('back_to');

  const groupQuery = useGroupQuery(groupId);
  const group = groupQuery.data?.data;

  const groupAssignmentQuery = useGroupAssignmentQuery(groupId, assignmentId);
  const assignment = groupAssignmentQuery.data?.data;

  const navigate = useNavigate();

  const handleSubmitted = () => {
    if (!assignment) { return; }

    if (backTo && backTo.includes('pages')) {
      window.location.href = backTo;
    } else {
      navigate(`/${basePath}/${assignment.group_id}/assignments/${assignment.id}`);
    }
  };

  const handleCompleted = () => {
    if (!assignment) { return; }

    navigate(`/${basePath}/${assignment.group_id}/assignments/${assignment.id}`);
  };

  if (!group || !assignment) { return null; }

  if (draftSubmissionsQuery.isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-5">
      <Spinner color="info" />
      </div>
    )
  }

  return (
    <AssignmentSubmissionLayout>
      <GroupAssignmentSubmission
        group={group}
        assignment={assignment}
        initialSubmission={draftSubmissionsQuery.data?.data?.[0]}
        onSubmitted={handleSubmitted}
        onCompleted={handleCompleted} />

    </AssignmentSubmissionLayout>
  );
};

export default NewGroupAssignmentSubmissionPage;
