import React, { ComponentProps, useState } from 'react';
import { Modal, ModalBox, ModalHeader } from '../../../../common/components/TailwindModal';
import useFindReflectionQuery from '../../../../common/hooks/api/reflections/useFindReflectionQuery';
import useUpdateReflectionMutation from '../../../../common/hooks/api/reflections/useUpdateReflectionMutation';
import * as Atlas from '../../../../common/types/Atlas';
import ReflectionDetailsForm from './ReflectionDetailsForm';
import ReflectionThumbnailPicker from './ReflectionThumbnailPicker';
import Spinner from '../../../../common/components/Spinner';

interface ReflectionDetailsFormsProps {
  reflectionId: Atlas.ReflectionID;
  readOnly?: boolean;
}

const ReflectionDetailsForms = (props: ReflectionDetailsFormsProps) => {
  const { reflectionId, readOnly } = props;

  const reflectionQuery = useFindReflectionQuery(reflectionId);
  const updateReflection = useUpdateReflectionMutation();

  const [thumbnailModalOpen, setThumbnailModalOpen] = useState(false);

  switch (reflectionQuery.status) {
    case 'success': {
      const reflection = reflectionQuery.data.data;

      const handleSubmit = async (
        changes: Parameters<ComponentProps<typeof ReflectionDetailsForm>['onSubmit']>[0],
      ) => {
        await updateReflection.mutateAsync({
          params: { reflectionId },
          body: changes,
        });
      };

      const openThumbnailModal = () => {
        setThumbnailModalOpen(true);
      };

      const closeThumbnailModal = () => {
        setThumbnailModalOpen(false);
      };

      return (
        <section>
          <p className="tw-text-lg tw-font-bold tw-text-primary tw-mb-1">
            {__('Thumbnail')}
          </p>

          <div className="tw-mb-2">
            {reflection.thumbnail_s3uri?.url ? (
              <img
                alt={__('Reflection thumbnail')}
                className="tw-h-40 tw-rounded"
                src={reflection.thumbnail_s3uri?.url}
              />
            ) : null}

            {!readOnly ? (
              <div className="tw-flex tw-justify-end tw-mt-3">
                <button
                  type="button"
                  className="tw-btn tw-btn-sm tw-btn-success"
                  onClick={openThumbnailModal}
                >
                  {__('Update Thumbnail')}
                </button>

                <Modal isOpen={thumbnailModalOpen} onClose={closeThumbnailModal}>
                  <ModalBox>
                    <ModalHeader onClose={closeThumbnailModal}>
                      {__('Update Thumbnail')}
                    </ModalHeader>
                    <ReflectionThumbnailPicker
                      reflectionId={reflectionId}
                      onCancel={closeThumbnailModal}
                      onUpdated={closeThumbnailModal}
                    />
                  </ModalBox>
                </Modal>
              </div>
            ) : null}
          </div>

          <hr className="tw-my-3" />

          <ReflectionDetailsForm
            reflection={reflection}
            readOnly={readOnly}
            onSubmit={handleSubmit}
          />
        </section>
      );
    }

    case 'error': {
      return (
        <section className="tw-flex tw-justify-center tw-py-5">
          {__('Something went wrong.')}
        </section>
      );
    }

    case 'loading':
    default: {
      return (
        <section className="tw-flex tw-justify-center tw-py-5">
          <Spinner color="info" />
        </section>
      );
    }
  }
};

export default ReflectionDetailsForms;
