import React from 'react';
import useSplitVideoMutation from '../../../common/hooks/api/videos/useSplitVideoMutation';
import useSwapVideoMutation from '../../../common/hooks/api/videos/useSwapVideoMutation';
import * as Atlas from '../../../common/types/Atlas';

interface PlaylistItemAdvancedControlsProps {
  video: Atlas.Video;
}

const PlaylistItemAdvancedControls = (props: PlaylistItemAdvancedControlsProps): JSX.Element => {
  const { video } = props;

  const splitVideoMutation = useSplitVideoMutation();
  const swapVideoMutation = useSwapVideoMutation();

  const handleSplitLeftClick = () => {
    if (!video.reflection_id) { return; }

    splitVideoMutation.mutate({
      params: { videoId: video.id },
      body: {
        direction: 'left',
        reflectionId: video.reflection_id,
      },
    });
  };

  const handleSplitRightClick = () => {
    if (!video.reflection_id) { return; }

    splitVideoMutation.mutate({
      params: { videoId: video.id },
      body: {
        direction: 'right',
        reflectionId: video.reflection_id,
      },
    });
  };

  const handleSwapClick = () => {
    if (!video.reflection_id) { return; }

    swapVideoMutation.mutate({
      params: { videoId: video.id },
      body: { reflectionId: video.reflection_id },
    });
  };

  const canSplitAndSwap = (() => {
    if (video.override_channel) { return false; }
    if (video.mode === Atlas.VideoMode.Provisional) { return false; }
    if (video.mode === Atlas.VideoMode.Legacy) { return false; }
    return true;
  })();

  const leftChannelReady = (() => {
    const leftChannel = video.channels.find((channel) => (
      channel.type === Atlas.ChannelType.VideoLeft
    ));
    if (!leftChannel) { return false; }

    return leftChannel.prep_state === Atlas.VideoPrepState.Ready;
  })();

  const canSplitLeft = canSplitAndSwap && leftChannelReady;

  const rightChannelReady = (() => {
    const rightChannel = video.channels.find((channel) => (
      channel.type === Atlas.ChannelType.VideoRight
    ));
    if (!rightChannel) { return false; }

    return rightChannel.prep_state === Atlas.VideoPrepState.Ready;
  })();

  const canSplitRight = canSplitAndSwap && rightChannelReady;

  const canSwap = (() => {
    if (!canSplitAndSwap) { return false; }
    if (video.is_evicted === Atlas.VideoEvictionState.FullyEvicted) { return false; }

    return leftChannelReady && rightChannelReady;
  })();

  const tooltipClass = 'tw-tooltip after:tw-border-t-black after:tw-z-10 before:tw-bg-black before:tw-text-white before:tw-content-[attr(data-tip)] before:tw-z-10';
  const infoText = __('Use the first two buttons to create new videos capturing only a side of the recording, either the left or the right part. Use the last button to generate a new video with the left and right part inverted.');
  const disabledButtonText = __('You can only split or swap recent, non-edited dual views that have both parts.');

  return (
    <div className="tw-flex tw-flex-wrap tw-space-x-1">
      <div className={tooltipClass} data-tip={infoText}>
        <i className="fa fa-question-circle" />
      </div>
      <div className={canSplitLeft ? '' : tooltipClass} data-tip={canSplitLeft ? undefined : disabledButtonText}>
        <button
          type="button"
          className="tw-btn tw-btn-xs"
          disabled={!canSplitLeft}
          onClick={handleSplitLeftClick}
        >
          <i className="fa fa-chevron-left" />
        </button>
      </div>
      <div className={canSplitRight ? '' : tooltipClass} data-tip={canSplitRight ? undefined : disabledButtonText}>
        <button
          type="button"
          className="tw-btn tw-btn-xs"
          disabled={!canSplitRight}
          onClick={handleSplitRightClick}
        >
          <i className="fa fa-chevron-right" />
        </button>
      </div>
      <div className={canSwap ? '' : tooltipClass} data-tip={canSwap ? undefined : disabledButtonText}>
        <button
          type="button"
          className="tw-btn tw-btn-xs"
          disabled={!canSwap}
          onClick={handleSwapClick}
        >
          <i className="fa fa-exchange" />
        </button>
      </div>
    </div>
  );
};

export default PlaylistItemAdvancedControls;
