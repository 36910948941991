import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObject } from '../../api-parser';
import ApiError from '../../error';
import { generateFetchHeaders } from '../../routes';

export const updateGroupDiscussionsCudRolePath = (args: {
  groupId: Atlas.GroupID,
}): string => (
  `/api/v0/groups/${args.groupId}/update_discussions_cud_role`
);

export interface UpdateGroupDiscussionsCudRoleParams {
  groupId: Atlas.GroupID;
}

export interface UpdateGroupDiscussionsCudRoleBody {
  role: Atlas.MemberRole;
}

export interface UpdateGroupDiscussionsCudRoleArguments {
  params: UpdateGroupDiscussionsCudRoleParams;
  body: UpdateGroupDiscussionsCudRoleBody;
}

export interface UpdateGroupDiscussionsCudRoleResponse {
  data: Atlas.Group;
}

export const updateGroupDiscussionsCudRole = async (
  args: UpdateGroupDiscussionsCudRoleArguments,
): Promise<UpdateGroupDiscussionsCudRoleResponse> => {
  const { groupId } = args.params;
  const url = updateGroupDiscussionsCudRolePath({ groupId });

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: generateFetchHeaders(),
    body: JSON.stringify(args.body),
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'updateGroupDiscussionsCudRole');
  }

  const body = await response.json();
  const data = parseModelObject<Atlas.Group>(body.data);

  return { data };
};
