import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormTextChart from './charts/FormTextChart';
import FormCounterChart from './charts/FormCounterChart';
import FormMultipleChoiceChart from './charts/FormMultipleChoiceChart';
import FormRatingChart from './charts/FormRatingChart';
import FormTimerChart from './charts/FormTimerChart';

import { interpolate } from '../../common/utils/utils';

const chartMap = {
  text: FormTextChart,
  counter: FormCounterChart,
  multiple_choice: FormMultipleChoiceChart,
  rating: FormRatingChart,
  timer: FormTimerChart,
};

const FormElementChart = (props) => {
  const { formElement, formAnswers } = props;
  const { field } = formElement;
  const type = Object.keys(field.type)[0];

  const [state, setState] = useState({
    filter: {},
  });

  const handleFilterSelectChange = (event) => {
    const { value } = event.currentTarget;

    setState((s) => ({
      ...s, filter: { user_id: Number(value) },
    }));
  };

  const users = Object.values(formAnswers.reduce((acc, fa) => {
    if (fa._user) {
      acc[fa.user_id] = { value: fa.user_id, label: [fa._user.first_name, fa._user.last_name].join(' ') };
    } else {
      acc[fa.user_id] ||= { value: fa.user_id, label: __('Unknown') };
    }

    return acc;
  }, {}));

  const filteredFormAnswers = formAnswers.filter((fa) => !state.filter.user_id || (state.filter.user_id === fa.user_id));

  const $filter = (
    <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
      <button type="button" className="tw-btn tw-btn-sm" onClick={props.onBackClick}>
        <i className="fa fa-chevron-left tw-text-xs" />
        &nbsp;
        {__('Back')}
      </button>

      <div>
        <select className="tw-select tw-select-bordered tw-select-sm tw-mb-0" value={state.user_id} onChange={handleFilterSelectChange}>
          <option value="">{__('All')}</option>
          {users.map((user) => (
            <option key={user.value} value={user.value}>
              {user.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const Component = chartMap[type];
  if (!Component) {
    return (
      <>
        {$filter}
        <div className="tw-text-center tw-my-5">
          {interpolate(n__('%n response', '%n responses', filteredFormAnswers.length), filteredFormAnswers.length)}
        </div>
      </>
    );
  }

  return (
    <>
      {$filter}
      <Component formElement={formElement} formAnswers={filteredFormAnswers} />
    </>
  );
};

FormElementChart.propTypes = {
  formElement: PropTypes.object.isRequired,
  formAnswers: PropTypes.array.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default FormElementChart;
