import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import {
  Link, useLocation, useParams,
} from 'react-router-dom';
import { findForm } from '../../../../common/api/forms';
import * as Atlas from '../../../../common/types/Atlas';
import FormFilters from '../../../../modules/form-reporting/Filters';
import FormOverviews from '../../../../modules/form-reporting/Overviews';
import FormReporters from '../../../../modules/form-reporting/Reporters';
import FormResponses from '../../../../modules/form-reporting/Responses';
import FormRatings from '../../../../modules/form-reporting/Ratings';
import type { FormReportingFilter } from '../../../../modules/form-reporting/types';
import Spinner from '../../../../common/components/Spinner';

const generateFormReportingFilter = (name: string) => ({
  id: nanoid(),
  name,
});

const FormOverviewPage = (): JSX.Element => {
  const params = useParams<{ formId: string; }>();
  const formId = Number(params.formId);

  const formReportPath = `/forms/${formId}/overview`;

  const { hash, search } = useLocation();

  const [state, setState] = useState<{
    formLoading: boolean;
    form?: Atlas.Form;
  }>({
    formLoading: true,
  });

  useEffect(() => {
    setState((s) => ({ ...s, formLoading: true }));
    void findForm({ params: { formId } }).then(({ data }) => {
      setState((s) => ({ ...s, form: data, formLoading: false }));
    });
  }, [formId]);

  const [filters, setFilters] = useState<FormReportingFilter[]>(() => [generateFormReportingFilter('Filter 1')]);

  const handleFilterChange = (filterId: string) => (filter: FormReportingFilter) => {
    setFilters((f) => f.map((ff) => {
      if (ff.id !== filterId) { return ff; }
      return { ...ff, ...filter };
    }));
  };

  const handleCompareClick = () => {
    setFilters((f) => [...f, generateFormReportingFilter(`Filter ${f.length + 1}`)]);
  };

  const handleRemoveFilterClick = (filterId: string) => () => {
    setFilters((f) => f.filter((ff) => ff.id !== filterId));
  };

  const handleFilterNameChange = (filterId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setFilters((f) => f.map((ff) => {
      if (ff.id !== filterId) { return ff; }
      return { ...ff, name: value };
    }));
  };

  return (
    <div>
      <div className="tw-flex tw-gap-2 tw-items-center">
        <a href="/forms" className="tw-btn tw-btn-white">
          <i className="fa fa-chevron-left" />
          <span className="tw-ml-3">{__('Back')}</span>
        </a>
        <div className="tw-flex-1"></div>
        {state.formLoading ? (
          <Spinner size="sm" color="info" className="tw-m-4" />
        ) : (
          <div>
            <h4 className="tw-my-0 tw-font-bold tw-text-2xl">
              {state.form?.name}
            </h4>
          </div>
        )}
      </div>
      <div className="tw-divider">
      </div>

      <div className="tw-grid tw-grid-cols-3 tw-gap-4">
        <div className="tw-col-span-1">
          {filters.map((filter) => (
            <div key={filter.id} className="tw-mb-4 tw-p-4 tw-bg-base-100 tw-shadow tw-rounded-xl">
              {filters.length > 1 ? (
                <div className="tw-flex tw-items-center tw-justify-between tw-gap-1 tw-mb-4">
                  <input className="tw-input tw-input-bordered tw-input-sm tw-w-full" value={filter.name} onChange={handleFilterNameChange(filter.id)} />
                  <button type="button" className="tw-btn tw-btn-ghost tw-btn-xs" onClick={handleRemoveFilterClick(filter.id)}>
                    <i className="fa fa-times" />
                  </button>
                </div>
              ) : null}

              <FormFilters
                formId={formId}
                filter={filter}
                onChange={handleFilterChange(filter.id)}
              />
            </div>
          ))}

          {filters.length < 3 ? (
            <div className="tw-mb-4 tw-p-1 tw-bg-base-100 tw-shadow tw-rounded-xl">
              <button type="button" className="tw-btn tw-btn-ghost tw-btn-block tw-gap-1" onClick={handleCompareClick}>
                <i className="fa fa-plus-circle" />
                {__('Add filter')}
              </button>
            </div>
          ) : null}

          <div className="tw-mb-4">
            <FormReporters formId={formId} form={state.form} />
          </div>
        </div>

        <div className="tw-col-span-2">
          <div className="tw-mb-4 tw-bg-base-100 tw-shadow tw-rounded-xl tw-p-1 tw-flex tw-gap-2">
            <Link
              to={{
                pathname: formReportPath,
                search,
              }}
              className={`tw-btn tw-btn-sm ${hash === '' ? 'tw-btn-primary' : 'tw-btn-ghost'}`}
            >
              {__('Overview')}
            </Link>

            <Link
              to={{
                pathname: formReportPath,
                search,
                hash: '#responses',
              }}
              className={`tw-btn tw-btn-sm ${hash === '#responses' ? 'tw-btn-primary' : 'tw-btn-ghost'}`}
            >
              {__('Responses')}
            </Link>

            <Link
              to={{
                pathname: formReportPath,
                search,
                hash: '#ratings',
              }}
              className={`tw-btn tw-btn-sm ${hash === '#ratings' ? 'tw-btn-primary' : 'tw-btn-ghost'}`}
            >
              {__('Rating scale analysis')}
            </Link>
          </div>

          {(() => {
            switch (hash) {
              case '#responses': {
                return <FormResponses formId={formId} form={state.form} filters={filters} />;
              }

              case '#ratings': {
                return <FormRatings formId={formId} form={state.form} filters={filters} />;
              }

              default: {
                return <FormOverviews formId={formId} form={state.form} filters={filters} />;
              }
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default FormOverviewPage;
