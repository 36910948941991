import React, { useCallback, useState } from 'react';
import * as Atlas from '../../../common/types/Atlas';
import useFindFeatureToggleQuery from '../../../common/hooks/api/features/useFindFeatureToggleQuery';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import AiModelSelect from './AiModelSelect';
import { Modal, ModalBox, ModalHeader } from '../../../common/components/TailwindModal';
import AdvancedSettingsForm, { AdvancedSettingsFormChanges } from './AdvancedSettingsForm';
import { isEnum } from '../../../common/utils/utils';
import AttachmentList from './AttachmentList';
import AttachmentUploaderButton from './AttachmentUploaderButton';
import { openAiFileTypes } from './ai-filetypes';

type PartialForm = Omit<DeepPartial<Atlas.Form>, 'ai_options'> & { ai_options?: Atlas.FormAIOptions | null; };

interface FormDetailsProps {
  loading: boolean;
  form: PartialForm;
  isNewForm?: boolean;
  onChange: (changes: PartialForm) => void;
}

const FormDetails = (props: FormDetailsProps): JSX.Element => {
  const {
    loading,
    form = {},
    onChange,
    isNewForm,
  } = props;

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const aiFormsEnabled = useFindFeatureToggleQuery({ featureToggle: 'ai_forms' }).data?.enabled ?? false;
  const advancedOptionsEnabled = useFindFeatureToggleQuery({ featureToggle: 'advanced_forms_options' }).data?.enabled ?? false;
  const isSuperAdmin = useCurrentUserQuery().data?.admin === Atlas.AdministratorType.Super;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (!onChange) { return; }

    const { name, value } = e.currentTarget;

    // Convert the string value of the 'clone_allowed' field to a boolean
    const parsedValue = name === 'clone_allowed' ? value === 'true' : value;

    onChange({ [name]: parsedValue });
  };

  const handleAdvancedSettingsFormSubmit = (changes: AdvancedSettingsFormChanges) => {
    onChange({
      notify_complete: {
        lock_on_complete: changes.lockOnComplete,
      },
    });
    setSettingsModalOpen(false);
  };

  const handleAiModelChange = (value: number | undefined) => {
    if (value) {
      const modelId = Number(value);

      onChange({
        ai_options: {
          context: '',
          attachment_ids: [],
          model_choice: Atlas.ModelChoice.Specified,
          ...form.ai_options,
          model_id: modelId,
        },
      });
    } else {
      onChange({
        ai_options: null,
      });
    }
  };

  const handleModelChoiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    if (!isEnum(value, Atlas.ModelChoice)) { return; }
    if (!form.ai_options) { return; }

    onChange({
      ai_options: {
        ...form.ai_options,
        model_choice: value,
      }
    })
  }

  const handleAiContextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) { return; }
    if (!form.ai_options) { return; }

    const { value } = event.currentTarget;

    onChange({
      ai_options: {
        ...form.ai_options,
        context: value,
      },
    });
  };

  const handleAdvancedSettingsClick = () => {
    setSettingsModalOpen(true);
  };

  const handleAttachmentRemove = (attachmentId: Atlas.AttachmentID) => {
    if (!form.ai_options) { return; }
    if (!window.confirm(__('Remove this file?'))) { return; }

    onChange({
      ai_options: {
        ...form.ai_options,
        attachment_ids: form.ai_options.attachment_ids.filter((id) => id !== attachmentId),
      },
    });
  };

  const handleUploadAiFileComplete = useCallback((attachment: Atlas.Attachment) => {
    if (!form.ai_options) { return; }

    onChange({
      ai_options: {
        ...form.ai_options,
        attachment_ids: [
          ...(form.ai_options?.attachment_ids ?? []),
          attachment.id,
        ],
      },
    });
  }, [form.ai_options]);

  return (
    <div className="tw-card tw-card-compact tw-bg-base-100">
      <div className="tw-card-body tw-block">
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-[auto] lg:tw-grid-flow-col tw-gap-x-4 tw-gap-y-3">
          <div>
            <label className="tw-block tw-mb-0">
              <div className="tw-font-semibold tw-mb-1">
                {__('Name')}
              </div>
              <input
                autoFocus={!form.name}
                required
                className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
                name="name"
                value={form.name ?? ''}
                onChange={handleInputChange}
                disabled={loading}
              />
            </label>

            <label className="tw-block tw-mb-0 tw-mt-3">
              <div className="tw-font-semibold tw-mb-1">
                {__('Copyright')}
              </div>
              <input
                className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
                name="copyright"
                value={form.copyright ?? ''}
                onChange={handleInputChange}
                disabled={loading}
              />
            </label>

            <label className="tw-block tw-mb-0 tw-mt-3">
              <div className="tw-font-semibold tw-mb-1">
                {__('Cloning')}
              </div>
              <select 
                name="clone_allowed"
                value={form.clone_allowed === true ? 'true' : 'false'}
                className="tw-input tw-input-bordered tw-input-sm tw-block tw-w-full"
                onChange={handleInputChange}
                disabled={loading}
              >
                <option value={'true'}>
                  {__('Enabled')}
                </option>
                <option value={'false'}>
                  {__('Disabled')}
                </option>
              </select>
            </label>

            {advancedOptionsEnabled && (
              <div className="tw-mt-4">
                <button type="button" className="tw-btn tw-btn-sm tw-gap-2" onClick={handleAdvancedSettingsClick}>
                  <i className="fa fa-cog" />
                  {__('Advanced settings')}
                </button>

                <Modal isOpen={settingsModalOpen} onClose={() => setSettingsModalOpen(false)}>
                  <ModalBox>
                    <ModalHeader onClose={() => setSettingsModalOpen(false)}>
                      {__('Advanced settings')}
                    </ModalHeader>

                    <AdvancedSettingsForm
                      lockOnComplete={form.notify_complete?.lock_on_complete}
                      onSubmit={handleAdvancedSettingsFormSubmit}
                    />
                  </ModalBox>
                </Modal>
              </div>
            )}
          </div>


          <label className="tw-flex tw-flex-col tw-mb-0">
            <div className="tw-font-semibold tw-mb-1">
              {__('Instructions')}
            </div>
            <textarea
              name="instructions"
              value={form.instructions ?? ''}
              onChange={handleInputChange}
              className="tw-textarea tw-textarea-bordered tw-block tw-w-full tw-flex-1 tw-resize-none"
              disabled={loading}
            />
          </label>

          {aiFormsEnabled ? (
            <div className="tw-flex tw-flex-col tw-gap-3">
              <label className="tw-block tw-mb-0">
                <div className="tw-font-semibold tw-mb-1">
                  {isSuperAdmin ? (
                    __('AI Model')
                  ) : (
                    __('AI')
                  )}
                </div>

                <AiModelSelect
                  className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
                  name="ai_options.model_id"
                  value={form.ai_options?.model_id ?? ''}
                  onChange={handleAiModelChange}
                  autoSelect={isNewForm}
                  defaultOnly={!isSuperAdmin}
                />
              </label>
                
              {isSuperAdmin && 
                <label className="tw-block tw-mb-0">
                  <div className="tw-font-semibold tw-mb-1">
                    {__('Model choice')}
                  </div>
                  <select 
                    className="tw-input tw-input-sm tw-input-bordered tw-block tw-w-full"
                    name="ai_options.model_choice"
                    onChange={handleModelChoiceChange}
                    value={form.ai_options?.model_choice ?? Atlas.ModelChoice.Specified}
                  >
                    <option value={Atlas.ModelChoice.Specified}>{Atlas.ModelChoice.Specified}</option>
                    <option value={Atlas.ModelChoice.Unspecified}>{Atlas.ModelChoice.Unspecified}</option>
                  </select>
                </label> 
              }

              <label className="tw-flex tw-flex-col tw-mb-0 tw-flex-1">
                <div className="tw-font-semibold tw-mb-1">
                  {__('AI Context')}
                </div>
                <textarea
                  name="ai_options.context"
                  value={form.ai_options?.context ?? ''}
                  onChange={handleAiContextChange}
                  placeholder={__("Add any additional information that will help the AI")}
                  className="tw-textarea tw-textarea-bordered tw-block tw-w-full tw-flex-1 tw-resize-none"
                  disabled={loading || !form.ai_options}
                />
              </label>

              <div className="tw-text-base">
                <div className="tw-font-semibold tw-mb-1">
                  {__('AI Files')}
                </div>
                <div className="tw-text-sm">
                  {__("Upload a file for the AI to read before answering all questions.")}
                </div>

                <div className="tw-max-w-xs tw-mb-2">
                  <AttachmentList
                    attachmentIds={form.ai_options?.attachment_ids ?? []}
                    onRemoveClick={handleAttachmentRemove}
                  />
                </div>

                <AttachmentUploaderButton
                  autoReset
                  acceptFile={openAiFileTypes.join(',')}
                  disabled={!form.ai_options}
                  onUploadComplete={handleUploadAiFileComplete}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FormDetails;
