import styled from '@emotion/styled';

export const ListGroup = styled.ul`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
`;

export const ListGroupItem = styled.li`
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);

  &:first-of-type {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-of-type {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  & + & {
    border-top-width: 0;
  }

  box-sizing: border-box;
`;

export const ListGroupItemAction = styled(ListGroupItem)`
  display: flex;
  width: 100%;
  color: #495057;
  text-align: inherit;

  &:focus,
  &:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
  }
`;

export const ListGroupFlush = styled(ListGroup)`
  ${ListGroupItem},
  ${ListGroupItemAction} {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;

    &:first-of-type {
      border-top-width: 0;
    }
  }

  &:last-of-type {
    ${ListGroupItem}:last-of-type,
    ${ListGroupItemAction}:last-of-type {
      border-bottom-width: 0;
    }
  }
`;
