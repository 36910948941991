import { useQuery, UseQueryResult } from 'react-query';
import { parseModelObject } from '../../api/api-parser';
import { currentUserRoute } from '../../api/routes';
import * as Atlas from '../../types/Atlas';

const findCurrentUser = (): Promise<Atlas.MyUser> => (
  fetch(currentUserRoute(), {
    credentials: 'same-origin',
  }).then(async (r) => {
    if (r.status !== 200) {
      throw new Error('failed-current-user-load');
    }

    const data = await r.json();

    return parseModelObject<Atlas.MyUser>(data);
  })
);

const useCurrentUserQuery = (): UseQueryResult<Atlas.MyUser> => (
  useQuery(
    ['users', 'me'],
    findCurrentUser,
    { staleTime: 60000 },
  )
);

export default useCurrentUserQuery;
