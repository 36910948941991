import { useQuery, UseQueryResult } from 'react-query';
import { findReflectionVideos, FindReflectionVideosParams, FindReflectionVideosResponse } from '../../api/reflections/videos';

const useReflectionVideosQuery = (
  params: FindReflectionVideosParams,
): UseQueryResult<FindReflectionVideosResponse> => {
  const { reflectionId, ...otherParams } = params;

  return useQuery(
    ['reflections', reflectionId, 'videos', otherParams],
    () => findReflectionVideos({ params }),
    { staleTime: 60000 },
  );
};

export default useReflectionVideosQuery;
