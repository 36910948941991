import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, {
  useState, useMemo, useEffect, useRef, ComponentProps,
} from 'react';
import Linkify from 'linkify-react';
import CopyButton from '../../../common/components/CopyButton';
import { renderTimestamp } from '../../../common/components/timerTimestamp';
import useDestroyCommentMutation from '../../../common/hooks/api/comments/useDestroyCommentMutation';
import useUpdateCommentMutation from '../../../common/hooks/api/comments/useUpdateCommentMutation';
import useCurrentUserQuery from '../../../common/hooks/api/useCurrentUserQuery';
import * as Atlas from '../../../common/types/Atlas';
import { portraitPlaceholderUrl } from '../../../common/utils/placeholders';
import useVideoPlayer from '../../jwplayer/useVideoPlayer';
import CommentInput from './CommentInput';
import EditTimestampModal from './EditTimestampModal';
import VideoReplyInput from './VideoReplyInput';
import AttachedVideo from './AttachedVideo';
import { Modal, ModalBox, ModalHeader } from '../../../common/components/TailwindModal';
import MediaCommentSettings from './MediaCommentSettings';
import CommentResourceLink from './comment-resource-links/comment-resource-link';
import CommentResourceLinkForm from './comment-resource-links/CommentResourceLinkForm';
import CommentPrivacySettings from './CommentPrivacySettings';

dayjs.extend(relativeTime);

interface VideoCommentProps {
  comment: Atlas.ExpandedComment;
  replies?: Atlas.ExpandedComment[];
  readOnly?: boolean;
  ownerId?: Atlas.UserID;
  isEmbedded?: boolean;
}

const smoothFocus = (input: HTMLInputElement) => {
  input.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  input.focus({ preventScroll: true });
};

const VideoComment = (props: VideoCommentProps) => {
  const { comment, replies = [], readOnly, ownerId, isEmbedded } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [activeModal, setActiveModal] = useState<null | 'edit-media-settings' | 'link-resource' | 'privacy-settings'>(null);

  const [state, setState] = useState({
    activeVideo: false,
    activeTime: false,
  });

  const [editing, setEditing] = useState(false);
  const [editingTimestamp, setEditingTimestamp] = useState(false);

  const [autoPlay, setAutoPlay] = useState(false);

  useEffect(() => {
    setAutoPlay(state.activeVideo && state.activeTime);
  }, [state.activeVideo, state.activeTime]);

  const isReply = !!comment.parent_id;

  const inputRef = useRef<HTMLInputElement>(null);
  const [forceShowReplyInput, setForceShowReplyInput] = useState(false);
  useEffect(() => {
    if (forceShowReplyInput && inputRef.current) {
      smoothFocus(inputRef.current);
    }
  }, [forceShowReplyInput]);

  const onTime = useMemo(() => {
    if (!state.activeVideo) { return undefined; }

    const commentStart = comment.start;
    if (typeof commentStart !== 'number') { return undefined; }

    const commentDuration = comment.duration;
    if (typeof commentDuration !== 'number') { return undefined; }

    const commentEnd = commentStart + Math.max(commentDuration, 1000);

    return (position1: number) => {
      const position = position1 * 1000;

      setState((s) => ({
        ...s,
        activeTime: (commentStart <= position) && (position < commentEnd),
      }));
    };
  }, [state.activeVideo, comment.duration, comment.start]);

  const player = useVideoPlayer({
    onTime,
  });

  const activeVideo = typeof comment.video_id === 'number' && (comment.video_id === player?.videoId);
  if (activeVideo !== state.activeVideo) {
    setState((s) => ({ ...s, activeVideo }));
  }

  const scrollEmbeddedComment = (element: HTMLDivElement) => {
    const container = element.closest('.tw-comments-container');

      if (container) {
        const elementTop = element.offsetTop;
        const elementHeight = element.offsetHeight;
        const containerScrollTop = container.scrollTop;
        const containerHeight = container.clientHeight;
        const margin = 15

        // Check if the comment is outside the visible portion of the comments box
        if (
          elementTop < containerScrollTop || // Above visible area
          elementTop + elementHeight > containerScrollTop + containerHeight // Below visible area
        ) {
          container.scrollTo({
            top: elementTop - margin,
            behavior: 'smooth',
          });
        }
      }
  }

  const isPlaying = activeVideo && state.activeTime;

  useEffect(() => {
    if (!isPlaying) { return; }

    const element = ref.current;
    if (!element) { return; }

    if (isEmbedded) {
      scrollEmbeddedComment(element);
    } else {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isPlaying]);

  const currentUser = useCurrentUserQuery();
  const updateComment = useUpdateCommentMutation();
  const destroyComment = useDestroyCommentMutation();

  if (destroyComment.isLoading || destroyComment.isSuccess) {
    return null;
  }

  const isCommentOwner = currentUser.data?.id === comment.user_id;
  const isOwner = currentUser.data?.id === ownerId;

  const isEdited = comment.edit_log.length > 0;
  const replyInputVisible = (forceShowReplyInput || replies.length > 0) && !readOnly;

  return (
    <div ref={ref} className="tw-scroll-mt-[82px]">
      <div className="tw-flex tw-gap-2">
        <div className="tw-avatar tw-hidden xs:tw-block">
          <div className={`${isReply ? 'tw-w-6 tw-h-6 xs:tw-w-8 xs:tw-h-8 tw-shadow' : 'tw-w-8 tw-h-8 xs:tw-w-12 xs:tw-h-12 tw-shadow'} ${isPlaying ? "tw-border-2 tw-border-secondary" : "tw-border-base-200" } tw-rounded-xl`}>
            <img
              alt={__('Profile picture')}
              src={comment.user.avatar.viewable_s3uri?.url ?? portraitPlaceholderUrl}
            />
          </div>
        </div>

        {editing && !readOnly ? (() => {
          const handleSubmit = async (body: string) => {
            await updateComment.mutateAsync({
              params: { commentId: comment.id },
              body: { body },
            });

            setEditing(false);
          };

          const handleCancelClick = () => {
            setEditing(false);
          };

          return (
            <div className="tw-flex-grow">
              <CommentInput
                autoFocus
                initialBody={comment.body}
                onSubmit={handleSubmit}
              />

              <div className="tw-mt-1 tw-flex tw-px-2 tw-py-1 tw-text-xs tw-leading-none">
                <button
                  type="button"
                  className="tw-link tw-link-hover tw-font-bold"
                  onClick={handleCancelClick}
                >
                  {__('Cancel')}
                </button>
              </div>
            </div>
          );
        })() : (() => {
          const { video_id: videoId, start } = comment;

          const play = (() => {
            if (!videoId) { return undefined; }
            if (typeof start !== 'number') { return undefined; }
            if (!player?.play) { return undefined; }

            return () => {
              player.play(videoId, start / 1000);
            };
          })();

          const canEditTimestamp = isCommentOwner && !readOnly && !isReply;

          const body = comment.body.trim();

          return (
            <div className="tw-flex tw-flex-col tw-flex-grow tw-gap-1 tw-min-w-0">
              <div className="tw-flex tw-gap-2 tw-items-center tw-justify-between tw-px-1">
                <div className="tw-font-semibold tw-truncate tw-text-xs xs:tw-text-sm">
                  {comment.user.first_name}
                  {' '}
                  {comment.user.last_name}
                </div>

                <div className="tw-text-xs tw-text-slate-400 tw-whitespace-nowrap">
                  <div className="tw-inline-block first-letter:tw-uppercase">
                    {dayjs(comment.created_at).fromNow()}

                    {typeof comment.start === 'number' ? (
                      <>
                        {' '}
                        {__('at')}
                        {' '}
                        {renderTimestamp(comment.start)}

                        {comment.duration ? (
                          <>
                            {' '}
                            {__('for')}
                            {' '}
                            {renderTimestamp(comment.duration)}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>

                  {comment.privacy === Atlas.CommentPrivacy.Private ? (
                    <>
                      <div className="tw-inline-block tw-mx-1">
                        &middot;
                      </div>

                      <div className="tw-inline-block">
                        {__('Private')}
                      </div>
                    </>
                  ) : null}

                  {isEdited ? (
                    <>
                      <div className="tw-inline-block tw-mx-1">
                        &middot;
                      </div>

                      <div className="tw-inline-block">
                        {__('Edited')}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <div className={`tw-bg-base-100 tw-shadow tw-p-2 tw-rounded-xl tw-flex tw-gap-1 tw-transition ${isPlaying ? 'tw-shadow tw-bg-secondary' : ''}`}>
                <div className="tw-w-0 tw-flex-grow tw-flex tw-flex-col tw-gap-2">
                  {body ? (
                    <>
                      {play ? (
                        <button
                          type="button"
                          className="tw-whitespace-pre-wrap tw-break-words tw-overflow-hidden tw-px-2 lg:tw-py-2 tw-text-left tw-text-xs xs:tw-text-sm"
                          onClick={play}
                        >
                          <Linkify options={{ className: '!tw-link !tw-link-primary', target: '_blank', rel: 'noreferrer' }}>
                            {body}
                          </Linkify>
                        </button>
                      ) : (
                        <p
                          className="tw-whitespace-pre-wrap tw-break-words tw-overflow-hidden tw-px-2 lg:tw-py-2 tw-text-xs xs:tw-text-sm"
                        >
                          <Linkify options={{ className: '!tw-link !tw-link-primary', target: '_blank', rel: 'noreferrer' }}>
                            {body}
                          </Linkify>
                        </p>
                      )}
                    </>
                  ) : null}

                  {comment.resource_links_details.map((resourceLink, i) => (
                    <CommentResourceLink key={i} resourceLink={resourceLink} />
                  ))}

                  {comment.type === Atlas.CommentType.Media && !comment.video_media ? (
                    <div className="tw-badge tw-gap-1 tw-py-3">
                      <div>
                        <i className="fa fa-circle-o-notch fa-spin" />
                      </div>

                      <div>
                        {__('Waiting for upload')}
                      </div>
                    </div>
                  ) : null}

                  {comment.video_media ? (() => {
                    const handleCommentMediaAutoPlay = () => {
                      setAutoPlay(false);
                      if (comment.synchronicity === Atlas.CommentSynchronicity.Pause) {
                        player?.pause();
                      }
                    };

                    const handleCommentMediaComplete = () => {
                      if (comment.synchronicity === Atlas.CommentSynchronicity.Pause) {
                        player?.play();
                      }
                    };

                    return (
                      <AttachedVideo
                        autoPlay={autoPlay}
                        videoComment={comment.video_media}
                        onAutoPlay={handleCommentMediaAutoPlay}
                        onComplete={handleCommentMediaComplete}
                      />
                    );
                  })() : null}
                </div>

                {(() => {
                  const items: JSX.Element[] = [];

                  if (play) {
                    const handlePlayClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                      event.currentTarget.blur();

                      play();
                    };

                    items.push(
                      <li key="play">
                        <button type="button" onClick={handlePlayClick}>
                          {__('Play')}
                        </button>
                      </li>,
                    );
                  }

                  if (comment.video_id && typeof comment.start === 'number') {
                    const startAt = Math.floor(comment.start / 1000);
                    const { protocol, host, pathname } = location;
                    const link = `${protocol}//${host}${pathname}?video_id=${comment.video_id}&start_at=${startAt}`;

                    items.push(
                      <li key="copyLink">
                        <CopyButton content={link}>
                          {__('Copy Link')}
                        </CopyButton>
                      </li>,
                    );
                  }

                  if (isCommentOwner && !readOnly) {
                    if (comment.resource_links.length === 0) {
                      const isOpen = activeModal === 'link-resource';
                      const handleOpen = () => { setActiveModal('link-resource'); };
                      const handleClose = () => { setActiveModal(null); };

                      items.push(
                        <React.Fragment key="link-resource">
                          <li>
                            <button type="button" onClick={handleOpen}>
                              {__('Link Resource')}
                            </button>
                          </li>

                          <Modal isOpen={isOpen} onClose={handleClose}>
                            <ModalBox className="tw-overflow-visible">
                              <ModalHeader onClose={handleClose}>
                                {__('Link Resource')}
                              </ModalHeader>

                              <CommentResourceLinkForm
                                hideBody
                                onCancel={handleClose}
                                onSave={async ({ body, resource_links }) => {
                                  await updateComment.mutateAsync({
                                    params: { commentId: comment.id },
                                    body: {
                                      body,
                                      resource_links,
                                    },
                                  });

                                  handleClose();
                                }}
                              />
                            </ModalBox>
                          </Modal>
                        </React.Fragment>,
                      );
                    } else {
                      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                        event.currentTarget.blur();
                        if (!window.confirm(__('Are you sure you want to remove the linked resource?'))) { return; }

                        void updateComment.mutateAsync({
                          params: { commentId: comment.id },
                          body: { resource_links: [] },
                        });
                      };

                      items.push(
                        <React.Fragment key="link-resource">
                          <li>
                            <button type="button" onClick={handleClick}>
                              {__('Unlink Resource')}
                            </button>
                          </li>
                        </React.Fragment>,
                      );
                    }
                  }

                  if (isCommentOwner && !readOnly) {
                    const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                      event.currentTarget.blur();
                      setEditing(true);
                    };

                    items.push(
                      <li key="edit">
                        <button type="button" onClick={handleEditClick}>
                          {__('Edit Comment')}
                        </button>
                      </li>,
                    );
                  }

                  if (canEditTimestamp && typeof comment.video_id === 'number') {
                    const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                      event.currentTarget.blur();
                      setEditingTimestamp(true);
                    };

                    const handleCancel = () => {
                      setEditingTimestamp(false);
                    };

                    const handleSave: ComponentProps<typeof EditTimestampModal>['onSave'] = async (args) => {
                      await updateComment.mutateAsync({
                        params: { commentId: comment.id },
                        body: args,
                      });
                      setEditingTimestamp(false);
                    };

                    items.push(
                      <li key="editTimestamp">
                        <button type="button" onClick={handleEditClick}>
                          {__('Edit Timestamp')}
                        </button>

                        <EditTimestampModal
                          isOpen={editingTimestamp}
                          videoId={comment.video_id}
                          initialStart={comment.start}
                          initialDuration={comment.duration}
                          onCancel={handleCancel}
                          onSave={handleSave}
                        />
                      </li>,
                    );
                  }

                  if (isCommentOwner && !readOnly) {
                    const isOpen = activeModal === 'privacy-settings';
                    const handleOpen = () => { setActiveModal('privacy-settings'); };
                    const handleClose = () => { setActiveModal(null); };

                    items.push(
                      <React.Fragment key="privacy-settings">
                        <li>
                          <button type="button" onClick={handleOpen}>
                            {__('Change Privacy')}
                          </button>

                          <Modal isOpen={isOpen} onClose={handleClose}>
                            <ModalBox>
                              <ModalHeader onClose={handleClose}>
                                {__('Privacy Settings')}
                              </ModalHeader>
                              <CommentPrivacySettings commentId={comment.id} />
                            </ModalBox>
                          </Modal>
                        </li>
                      </React.Fragment>,
                    );
                  }

                  if (isCommentOwner && !readOnly && comment.type === Atlas.CommentType.Media) {
                    const isOpen = activeModal === 'edit-media-settings';
                    const handleOpen = () => { setActiveModal('edit-media-settings'); };
                    const handleClose = () => { setActiveModal(null); };

                    items.push(
                      <React.Fragment key="edit-media-comment">
                        <li>
                          <button type="button" onClick={handleOpen}>
                            {__('Edit Media Settings')}
                          </button>
                        </li>

                        <Modal isOpen={isOpen} onClose={handleClose}>
                          <ModalBox>
                            <ModalHeader onClose={handleClose}>
                              {__('Media Comment Settings')}
                            </ModalHeader>
                            <MediaCommentSettings commentId={comment.id} />
                          </ModalBox>
                        </Modal>
                      </React.Fragment>
                    );
                  }

                  if ((isOwner || isCommentOwner) && !readOnly) {
                    const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                      event.currentTarget.blur();
                      if (!window.confirm(__('Are you sure you want to delete this comment?'))) { return; }

                      destroyComment.mutate({ params: { commentId: comment.id } });
                    };

                    items.push(
                      <li key="delete">
                        <button type="button" onClick={handleDeleteClick}>
                          {__('Delete')}
                        </button>
                      </li>,
                    );
                  }

                  if (items.length === 0) { return null; }

                  return (
                    <div className="tw-flex tw-flex-col">
                      <div className="tw-dropdown tw-dropdown-left">
                        <label tabIndex={0} className="tw-btn tw-btn-xs xs:tw-btn-sm tw-btn-ghost tw-mb-0">
                          <i className="fa fa-ellipsis-v" />
                        </label>
                        <div className="tw-dropdown-content tw-iris-dropdown">
                          <div className="tw-iris-dropdown-box tw-reflection-comment-dropdown">
                            <ul tabIndex={0}>
                              {items}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div onClick={play} className="tw-cursor-pointer tw-flex-1" />
                    </div>
                  );
                })()}
              </div>

              {!isReply && !readOnly ? (() => {
                const handleReplyClick = () => {
                  if (replyInputVisible && inputRef.current) {
                    smoothFocus(inputRef.current);
                  } else {
                    setForceShowReplyInput(true);
                  }
                };

                return (
                  <div className="tw-flex tw-px-2 tw-py-1 lg:tw-px-4 tw-text-xs tw-leading-none">
                    <button
                      type="button"
                      className="tw-link tw-link-hover tw-font-bold"
                      onClick={handleReplyClick}
                    >
                      {__('Reply')}
                    </button>
                  </div>
                );
              })() : null}
            </div>
          );
        })()}
      </div>

      <div className="tw-pl-4 xs:tw-pl-14">
        {replies.length ? (
          <div className="tw-flex tw-flex-col tw-gap-2 tw-mt-1 tw-pl-2">
            {replies.map((reply) => (
              <VideoComment
                key={reply.id}
                comment={reply}
                readOnly={readOnly}
              />
            ))}
          </div>
        ) : null}

        {replyInputVisible && comment.video_id ? (
          <div className="tw-mt-2 tw-pl-2">
            <VideoReplyInput
              inputRef={inputRef}
              videoId={comment.video_id}
              parentId={comment.id}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VideoComment;
