import { useMutation, UseMutationResult } from 'react-query';
import { destroyDiscussion, DestroyDiscussionParams, DestroyDiscussionResponse } from '../../../api/discussions/[discussionId]';
import { queryClient } from '../../withQueryClient';

const useDestroyDiscussionMutation = (
  params: DestroyDiscussionParams
): UseMutationResult<DestroyDiscussionResponse, unknown, void, unknown> => {
  const mutation = useMutation(() => {
    return destroyDiscussion({ params });
  }, {
    onSuccess: (result) => {
      const discussion = result.data;
      queryClient.resetQueries(['discussions', discussion.id]);
      queryClient.invalidateQueries(['groups', discussion.group_id, 'discussions']);
    },
  });

  return mutation;
};

export default useDestroyDiscussionMutation;
