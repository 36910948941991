import { useQuery, UseQueryResult } from 'react-query';
import { searchSharable, SearchSharableParams, SearchSharableResponse } from '../../api/search/legacy';

const useSearchSharableQuery = (
  params: SearchSharableParams,
): UseQueryResult<SearchSharableResponse> => (
  useQuery(
    ['search', 'sharable', params],
    () => searchSharable({ params }),
    { staleTime: 60000 },
  )
);

export default useSearchSharableQuery;
