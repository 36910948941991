/*global $,__,alert,console,enums,module*/

module.exports = function () {
    // "use strict";
    var selector = $(".reflection-filters");
    // // Find DOM elements and data attributes
    var organization_filter = $(selector).find(".organization-filter input");
    var user_filter = $(selector).find(".user-filter input");
    var tag_filter = $(selector).find(".tag-filter input");
    var context_filter = $(".context-filter input").val();

    function baseConfig(type) {
        return {
            placeholder: resolvePlaceholder(type),
            dropdownCss: { "z-index": 100000 },
            tags: true,
            tokenSeparators: [","],
            ajax: {
                url: "/" + context_filter + "/custom_filter_options/?type=" + type,
                dataType: "json",
                cache: true,
                quietMillis: 250,
                data: formatQuery,
                results: formatResults
            }
        };
    }

    // returns the correct placeholder string
    function resolvePlaceholder(type) {
        switch(type) {
            case "user":
                return __("Filter by user..");
                break;
            case "organization":
                return __("Filter by organization..");
                break;
            case "tag":
                return __("Filter by tag..");
                break;
        }
    }

    // Formats results from our backend into a format select2 expects
    function formatResults(data) {
        return { results: data };
    }

    // Formats query from select2 into something our backend expects
    function formatQuery(term) {
        return { q: term };
    }

    // Set up organization select
    organization_filter.select2(baseConfig("organization"));

    // Set up user select
    user_filter.select2(baseConfig("user"));

    // Set up user select
    tag_filter.select2(baseConfig("tag"));

    // show when we're ready
    selector.fadeIn()

};
