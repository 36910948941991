import React from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import useFindReflectionsShareQuery from '../../../../common/hooks/api/reflections/useFindReflectionSharesQuery';
import ShareListItem from './ShareListItem';
import useDestroyReflectionShareMutation from '../../../../common/hooks/api/reflections/useDestroyReflectionShareMutation';
import ReflectionShareButtons from '../../show/reflection-forms/ReflectionShareButtons';

interface ShareReflectionIndexProps {
  reflectionId: Atlas.ReflectionID;
}

const ShareReflectionIndex = (props: ShareReflectionIndexProps): JSX.Element => {
  const { reflectionId } = props;

  const findReflectionShares = useFindReflectionsShareQuery({ reflectionId });
  const destroyReflectionShare = useDestroyReflectionShareMutation();

  switch (findReflectionShares.status) {
    case 'loading': {
      return (
        <div className="tw-text-gray-600 tw-text-center tw-my-16">
          <i className="fa fa-circle-o-notch fa-spin tw-text-2xl tw-text-info" />
        </div>
      );
    }

    case 'success': {
      const shares = findReflectionShares.data.data;

      if (shares.length === 0) {
        return (
          <div className="tw-text-gray-400 tw-text-center tw-my-16 tw-text-lg">
            {__('This reflection has not been shared to anyone')}
          </div>
        );
      }

      return (
        <>
          <div className="tw-my-6">
            <p className="tw-text-gray-600 tw-font-bold tw-mb-2">
              {__('Reflection shared to:')}
            </p>

            <div className="tw-flex tw-flex-col tw-gap-2">
              {shares.map((share) => {
                const handleDestroy = async () => {
                  await destroyReflectionShare.mutateAsync({
                    params: {
                      reflectionId,
                      shareId: share.id,
                    },
                  });
                };

                return (
                  <ShareListItem key={share.id} share={share} onDestroy={handleDestroy} />
                );
              })}
            </div>
          </div>

          <ReflectionShareButtons reflectionId={reflectionId} />
        </>
      );
    }

    default: {
      return (
        <div className="tw-text-gray-600 tw-text-center tw-my-16">
          {__('Oops! Something went wrong.')}
        </div>
      );
    }
  }
};

export default ShareReflectionIndex;
