import React from 'react';
import * as Atlas from '../../../common/types/Atlas';
import FormElement from './FormElement';

interface GenericBuilderFormElementsProps {
  formElements: Atlas.FormElement[];
  showRequiredOption?: boolean;
  showAllowMultipleAnswersOption?: boolean;
  onChange: (elements: Atlas.FormElement[]) => void;
  aiEnabled?: boolean;
  isEditingLimited?: boolean;
}

const GenericBuilderFormElements = (props: GenericBuilderFormElementsProps): JSX.Element => {
  const { formElements = [], onChange, showRequiredOption, showAllowMultipleAnswersOption, aiEnabled, isEditingLimited } = props;

  const handleMoveUpClick = (i: number) => () => {
    const nextFormElements = [...formElements];
    nextFormElements.splice(i - 1, 0, nextFormElements.splice(i, 1)[0]);

    onChange(nextFormElements);
  };

  const handleMoveDownClick = (i: number) => () => {
    const nextFormElements = [...formElements];
    nextFormElements.splice(i + 1, 0, nextFormElements.splice(i, 1)[0]);

    onChange(nextFormElements);
  };

  const handleRemoveClick = (i: number) => () => {
    const nextFormElements = [...formElements];
    nextFormElements.splice(i, 1);

    onChange(nextFormElements);
  };

  const handleElementChange = (i: number) => (changes: { field: Partial<Atlas.FormField>; }) => {
    const nextFormElements = [...formElements];
    nextFormElements[i] = {
      field: {
        ...nextFormElements[i].field,
        ...changes.field,
      },
    };

    onChange(nextFormElements);
  };

  return (
    <>
      {formElements.map((fe, i, arr) => (
        <FormElement
          key={fe.field.name}
          showRequiredOption={!!showRequiredOption}
          showAllowMultipleAnswersOption={!!showAllowMultipleAnswersOption}
          formElement={fe}
          onMoveUpClick={i !== 0 ? handleMoveUpClick(i) : undefined}
          onMoveDownClick={i !== (arr.length - 1) ? handleMoveDownClick(i) : undefined}
          onRemoveClick={handleRemoveClick(i)}
          onChange={handleElementChange(i)}
          aiEnabled={aiEnabled}
          isEditingLimited={isEditingLimited}
        />
      ))}
    </>
  );
};

export default GenericBuilderFormElements;
