import { Group, GroupID } from '../../types/Atlas';
import { jsonHeaders } from '../routes';

export const groupRoute = ({ groupId }: { groupId: GroupID }): string => `/groups/${groupId}`;

interface FindGroupArgs {
  params: { groupId: GroupID };
}

export interface FindGroupResponse {
  data: Group;
}

export const findGroup = (args: FindGroupArgs): Promise<FindGroupResponse> => {
  const { params } = args || {};

  const groupUrl = groupRoute({ groupId: params.groupId });

  return fetch(groupUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders
  }).then(r => {
    if (r.status !== 200) { throw `[${r.status}] findGroup failed`; }
    return r.json();
  });
};
