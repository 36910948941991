import React, { useState } from 'react';
import * as Atlas from '../../../../common/types/Atlas';
import MilestoneListItem from './MilestoneListItem';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  UniqueIdentifier,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  arrayMove,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import useReorderMilestonesMutation from '../hooks/api/useReorderMilestonesMutation';

interface SortableMilestonesListItemProps {
  isActive?: boolean;
  isDragging?: boolean;
  milestone: Atlas.Milestone;
  groupId: Atlas.GroupID;
  onDeleteClick: (milestoneId: Atlas.MilestoneIdentifier) => void;
}

const SortableMilestonesListItem = (props: SortableMilestonesListItemProps) => {
  const { milestone, groupId, onDeleteClick } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: milestone.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <MilestoneListItem
        milestone={milestone}
        groupId={groupId}
        sorting={true}
        onDeleteClick={onDeleteClick}
      />
    </div>
  );
}

interface MilestonesListProps {
  groupId: Atlas.GroupID;
  milestones: Atlas.Milestone[];
  onDeleteClick: (milestoneId: Atlas.MilestoneIdentifier) => void;
}

const MilestonesListSortable = (props: MilestonesListProps): JSX.Element => {
  const { groupId, milestones, onDeleteClick } = props;
  
  const [sortedIds, setSortedIds] = useState(milestones.map((milestone) => milestone.id));
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  
  const reorderMilestones = useReorderMilestonesMutation({ groupId });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id); 
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = sortedIds.findIndex((milestoneId) => milestoneId === active.id);
      const newIndex = sortedIds.findIndex((milestoneId) => milestoneId === over.id);
      const milestoneIds = arrayMove(sortedIds, oldIndex, newIndex);
      
      setSortedIds(milestoneIds);

      reorderMilestones.mutateAsync({
        milestoneIds,
      }).catch(() => {
        setSortedIds(milestones.map((milestone) => milestone.id));
      });
    }

    setActiveId(null);
  };

  return(
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={sortedIds}
        strategy={verticalListSortingStrategy}
      >
        {sortedIds.map((milestoneId) => {
          const milestone = milestones.find(({ id }) => id === milestoneId);
          if (!milestone) { return null; }

          return (
            <SortableMilestonesListItem 
              key={milestone.id}
              milestone={milestone}
              groupId={groupId}
              onDeleteClick={onDeleteClick} 
            />
          )
        })}
      </SortableContext>

      <DragOverlay>
        {activeId ? (
          (() => {
            const activeMilestone = milestones.find(({ id }) => id === activeId);
            if (!activeMilestone) { return null; }

            return (
              <SortableMilestonesListItem 
                milestone={activeMilestone}
                groupId={groupId}
                onDeleteClick={onDeleteClick} 
            />
            );
          })()
        ): null}
      </DragOverlay>
    </DndContext>
  );
};

export default MilestonesListSortable;
