import * as Atlas from '../../../common/types/Atlas';

type HeadingWithPages = {
  heading?: Atlas.Page;
  pages: Atlas.Page[];
};

export const groupByHeadings = (pages: Atlas.Page[]) => {
  const result: HeadingWithPages[] = [];
  let currentHeading: HeadingWithPages = { pages: [] };

  pages.forEach((page) => {
    switch (page.type) {
      case Atlas.PageType.Heading: {
        if (currentHeading.heading || currentHeading.pages.length > 0) {
          result.push(currentHeading);
        }
        currentHeading = { heading: page, pages: [] };
        return;
      }

      default: {
        currentHeading.pages.push(page);
        return;
      }
    }
  });

  if (currentHeading.heading || currentHeading.pages.length > 0) {
    result.push(currentHeading);
  }

  return result;
}
